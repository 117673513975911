export const ethereum = [
    {
      address: "0xd212046f89680ac9f106b9c63f314cc9808e18d5",
      symbol: "SUKI",
      name: "Sukiyaki Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://sukiyaki.finance/static/media/sakilogo.619dd10d4b76a696bf92.png",
      coingeckoId: "fsw-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0000000000000000000000000000000000000000",
      symbol: "ETH",
      name: "ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/eth/935ae4e4d1d12d59a99717a24f2540b5.png",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
    {
      address: "0xfffffffFf15AbF397dA76f1dcc1A1604F45126DB",
      symbol: "FSW",
      name: "FalconSwap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfffffffff15abf397da76f1dcc1a1604f45126db/7898195d03d70b74c55af72e2363520b.png",
      coingeckoId: "fsw-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xff56Cc6b1E6dEd347aA0B7676C85AB0B3D08B0FA",
      symbol: "ORBS",
      name: "Orbs",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa/fa20bb1cf2854aec5097fbc25f5c3c7b.png",
      coingeckoId: "orbs",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfeF4185594457050cC9c23980d301908FE057Bb1",
      symbol: "VIDT",
      name: "VIDT Datalink",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfef4185594457050cc9c23980d301908fe057bb1/ee4b0984c9dec9cbe27782e2c704c5b8.png",
      coingeckoId: "v-id-blockchain",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfe9A29aB92522D14Fc65880d817214261D8479AE",
      symbol: "SNOW",
      name: "SnowSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfe9a29ab92522d14fc65880d817214261d8479ae/492f4208d99e54915a1c9f6eddb04224.png",
      coingeckoId: "snowswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfe5F141Bf94fE84bC28deD0AB966c16B17490657",
      symbol: "LBA",
      name: "LibraToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfe5f141bf94fe84bc28ded0ab966c16b17490657/7f64ee77c3eb82246dd7908b69e307b9.png",
      coingeckoId: "libra-credit",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xfd30C9BEA1A952FEeEd2eF2C6B2Ff8A8FC4aAD07",
      symbol: "KALLY",
      name: "Polkally",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfd30c9bea1a952feeed2ef2c6b2ff8a8fc4aad07/4bd918f603e95186c347f1753bd803e9.png",
      coingeckoId: "polkally",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xfc82bb4ba86045Af6F327323a46E80412b91b27d",
      symbol: "PROM",
      name: "Token Prometeus Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc82bb4ba86045af6f327323a46e80412b91b27d/50a2b9fc7874f7fac731a89493e16d1c.png",
      coingeckoId: "prometeus",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xfc05987bd2be489ACCF0f509E44B0145d68240f7",
      symbol: "ESS",
      name: "ESSENTIA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc05987bd2be489accf0f509e44b0145d68240f7/e58efc8d735ef947fd5103d53f8567e9.png",
      coingeckoId: "essentia",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x517abf1fcdbd76bc75b532683ada9113e313a128",
      symbol: "DOKE",
      name: "Doke Inu",
      decimals: 9,
      chainId: "1",
      logoURI: "https://doke.wtf/_next/static/media/logo_header.bfa555eb.png",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
    {
      address: "0xdadb4ae5b5d3099dd1f586f990b845f2404a1c4c",
      symbol: "( ͡° ͜ʖ ͡°)",
      name: "Lenny Face",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.wixstatic.com/media/23184a_e9aa7f7a03ba4153a895fed8becfbd25~mv2.png/v1/fill/w_798,h_301,al_c,lg_1,q_85,enc_auto/face_edited.png",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
    {
      address: "0x5A5389bA78F04D783E9F30e5A8C45727c5E018bE",
      symbol: "CHIP",
      name: "Ask Chip",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/29041/large/Ask_Chip.png?1676103028",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
    {
      address: "0xcf4C68Db4C2FA0bf58dF07B14f45cE7709a716AC",
      symbol: "SHIELD",
      name: "Dejitaru Shirudo    ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://www.dextools.io/resources/tokens/logos/ether/0xcf4c68db4c2fa0bf58df07b14f45ce7709a716ac.png?1670803446469",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
  
    {
      address: "0x0f304557d7F410385B45f3ce104B8f215126396D",
      symbol: "MC",
      name: "Moon Chan",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://www.dextools.io/resources/tokens/logos/ether/0x0f304557d7f410385b45f3ce104b8f215126396d.jpeg?1675719005149",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
  
    {
      address: "0x9eb6299e4Bb6669E42cb295a254c8492F67AE2C6",
      symbol: "BET",
      name: "The BET",
      decimals: 18,
      chainId: "1",
      logoURI: "https://etherscan.io/token/images/thebet_32.png",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
  
    {
      address: "0x953e27772954e0cd9772cb05844fe3064f697eef",
      symbol: "TUNE",
      name: "Neptune",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://www.dextools.io/resources/tokens/logos/ether/0x953e27772954e0cd9772cb05844fe3064f697eef.jpg?1676752777144",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
  
    {
      address: "0xbe00734799a67a62af2819825580318ac1b1e4ec",
      symbol: "ORD",
      name: "ordinex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://www.dextools.io/resources/tokens/logos/ether/0xbe00734799a67a62af2819825580318ac1b1e4ec.jpg?1676107434870",
      coingeckoId: null,
      listedIn: ["optimism", "rubic", "lifinance"],
    },
  
    {
      address: "0xfb5453340C03db5aDe474b27E68B6a9c6b2823Eb",
      symbol: "ROBOT",
      name: "MetaFactory",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xfb5453340c03db5ade474b27e68b6a9c6b2823eb/88cecfc33cad33cca233183291b20b1c.png",
      coingeckoId: "robot",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfb40e79E56cc7D406707B66C4Fd175E07EB2Ae3C",
      symbol: "ROTTS",
      name: "ROTTSCHILD.com",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xfa6de2697D59E88Ed7Fc4dFE5A33daC43565ea41",
      symbol: "DEFI5",
      name: "DEFI Top 5 Tokens Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41/f6f0ad2b635daa1de702ceb7ae546214.png",
      coingeckoId: null,
      listedIn: ["openocean", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xfFffFffF2ba8F66D4e51811C5190992176930278",
      symbol: "COMBO",
      name: "Furucombo",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xffffffff2ba8f66d4e51811c5190992176930278/88bd6be1dc8cae608bfbf94e97a8019f.png",
      coingeckoId: "furucombo",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xfFbF315f70E458e49229654DeA4cE192d26f9b25",
      symbol: "VOLT",
      name: "VOLTAGE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xffbf315f70e458e49229654dea4ce192d26f9b25/ca3228a5a3966e4704a390c476193c80.png",
      coingeckoId: "voltage",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xfF20817765cB7f73d4bde2e66e067E58D11095C2",
      symbol: "AMP",
      name: "Amp",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xfF20817765cB7f73d4bde2e66e067E58D11095C2/logo.png",
      coingeckoId: "amp-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6",
      symbol: "sBTC",
      name: "Synth sBTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6/3e0d9a14a6f727b7bec8586c40d7b4c3.png",
      coingeckoId: "sbtc",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xfDC4a3FC36df16a78edCAf1B837d3ACAaeDB2CB4",
      symbol: "SCIFI",
      name: "ScifiToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfdc4a3fc36df16a78edcaf1b837d3acaaedb2cb4/d1a181dfa7eccafe03777fb6483e4335.png",
      coingeckoId: "scifi-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfC98e825A2264D890F9a1e68ed50E1526abCcacD",
      symbol: "MCO2",
      name: "Moss Carbon Credit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc98e825a2264d890f9a1e68ed50e1526abccacd/36fb8eb55a51b589742e97612c35558e.png",
      coingeckoId: "moss-carbon-credit",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d",
      symbol: "aDAI",
      name: "Aave Interest bearing DAI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d/24f33411d2269cb7d73cef6a0fda9ec0.png",
      coingeckoId: "aave-dai-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xfB7B4564402E5500dB5bB6d63Ae671302777C75a",
      symbol: "DEXT",
      name: "DEXTools",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfb7b4564402e5500db5bb6d63ae671302777c75a/373995147e4ca4464d7f44560bb09497.png",
      coingeckoId: "dextools",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xfAd45E47083e4607302aa43c65fB3106F1cd7607",
      symbol: "HOGE",
      name: "hoge.finance",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfad45e47083e4607302aa43c65fb3106f1cd7607/abb209b910fa0aa917e68b42de767631.png",
      coingeckoId: "hoge-finance",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a",
      symbol: "ROOK",
      name: "ROOK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a/f0504f21cecf99ecf954df4483a87f71.png",
      coingeckoId: "rook",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xf9FBE825BFB2bF3E387af0Dc18caC8d87F29DEa8",
      symbol: "BOTS",
      name: "Bot Ocean",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8/3e63da6dc172567ce76c9f2fcbdecd7e.png",
      coingeckoId: "radar",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf99d58e463A2E07e5692127302C20A191861b4D6",
      symbol: "ANY",
      name: "Anyswap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf99d58e463a2e07e5692127302c20a191861b4d6/6ce2f5d90629213c1669f76edfd706ec.png",
      coingeckoId: "anyswap",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xf91AC30E9b517f6D57e99446ee44894e6C22C032",
      symbol: "LOL",
      name: "LOL",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf91ac30e9b517f6d57e99446ee44894e6c22c032/a652b5f3801eba975baf1d49ef0dd172.png",
      coingeckoId: "lol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf911a7ec46a2c6fa49193212fe4a2a9B95851c27",
      symbol: "XAMP",
      name: "Antiample",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27/581dafdb8e16274145f0047179a6e4ce.png",
      coingeckoId: "antiample",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xf8e386EDa857484f5a12e4B5DAa9984E06E73705",
      symbol: "IND",
      name: "Indorse Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf8e386eda857484f5a12e4b5daa9984e06e73705/9a1b760e86422b56643e0f1904fd0c45.png",
      coingeckoId: "indorse",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xf8E9F10c22840b613cdA05A0c5Fdb59A4d6cd7eF",
      symbol: "DOE",
      name: "Dogs Of Elon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef/344b67ae049ef314c7afd56f066bf9d4.png",
      coingeckoId: "dogsofelon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf8C3527CC04340b208C854E985240c02F7B7793f",
      symbol: "FRONT",
      name: "Frontier Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf8c3527cc04340b208c854e985240c02f7b7793f/f6c4446e8d818a56217e56ba2a01db4b.png",
      coingeckoId: "frontier-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0xf83ae621A52737e3Ef9307af91df834Ed8431aC3",
      symbol: "uvwFi",
      name: "uvwFi.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xf83ae621a52737e3ef9307af91df834ed8431ac3.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xf7413489c474ca4399eeE604716c72879Eea3615",
      symbol: "APYS",
      name: "APYSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf7413489c474ca4399eee604716c72879eea3615/7f65b0a34b54be9e42c5e09f4ae8d821.png",
      coingeckoId: "apyswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf6e06B54855eFf198a2d9A8686113665499a6134",
      symbol: "Celt",
      name: "Celestial",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "celestial",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf680429328caaaCabee69b7A9FdB21a71419c063",
      symbol: "BFLY",
      name: "Butterfly Protocol Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf680429328caaacabee69b7a9fdb21a71419c063/7b0137968c5c1179e5e59358290b4f9b.png",
      coingeckoId: "butterfly-protocol-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf65B5C5104c4faFD4b709d9D60a185eAE063276c",
      symbol: "TRU",
      name: "Truebit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf65b5c5104c4fafd4b709d9d60a185eae063276c/c01f397719a7ef63a6754ee0f5f887b7.png",
      coingeckoId: "truebit-protocol",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9",
      symbol: "cUSDT",
      name: "Compound USDT",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9/b42a044c8b1a70338f8edfc7f6d96095.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xf55BbE0255f7f4E70f63837Ff72A577fbDDbE924",
      symbol: "crBOND",
      name: "Cream BarnBridge Governance Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xf55bbe0255f7f4e70f63837ff72a577fbddbe924.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xf552b656022c218C26dAd43ad88881Fc04116F76",
      symbol: "MORK",
      name: "Mork",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf552b656022c218c26dad43ad88881fc04116f76/a705e2b3cc45c1112c32e038d40916b4.png",
      coingeckoId: "mork",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf54B304e2e4b28c7E46619D1A340F9b2B72383D7",
      symbol: "KRZ",
      name: "Kranz Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf54b304e2e4b28c7e46619d1a340f9b2b72383d7/74040931245d60584cdb29485d45568c.png",
      coingeckoId: "kranz-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf50a07e4FF052A14f3f608DA8936D8AE0Ed5bE50",
      symbol: "FLOKIPUP",
      name: "Floki Pup",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
      symbol: "LOOKS",
      name: "LOOKS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/22173/small/circle-black-256.png?1641173160",
      coingeckoId: "looksrare",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xf4CD3d3Fda8d7Fd6C5a500203e38640A70Bf9577",
      symbol: "Yf-DAI",
      name: "YfDAI.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577/1082862d454c21b3a105c7bdc45611af.png",
      coingeckoId: "yfdai-finance",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xf418588522d5dd018b425E472991E52EBBeEEEEE",
      symbol: "PUSH",
      name: "Ethereum Push Notification Service",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf418588522d5dd018b425e472991e52ebbeeeeee/7dbc750e0ed6a3c80dcb28311d4fcdba.png",
      coingeckoId: "ethereum-push-notification-service",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf3dcbc6D72a4E1892f7917b7C43b74131Df8480e",
      symbol: "BDP",
      name: "BDPToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e/59979d781195dfceba56ea130e4ac06a.png",
      coingeckoId: "big-data-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf3b9569F82B18aEf890De263B84189bd33EBe452",
      symbol: "CAW",
      name: "A Hunters Dream",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf3b9569f82b18aef890de263b84189bd33ebe452/22a720be1fea977d7e3776bb1d4c5d1b.png",
      coingeckoId: "a-hunters-dream",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf3Db5Fa2C66B7aF3Eb0C0b782510816cbe4813b8",
      symbol: "EVX",
      name: "Everex",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8/06ae8f6076efc206932b7c505d170c3d.png",
      coingeckoId: "everex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf3AE5d769e153Ef72b4e3591aC004E89F48107a1",
      symbol: "DPR",
      name: "Deeper Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf3ae5d769e153ef72b4e3591ac004e89f48107a1/f4bf3e41ed4af8c1ee90ca3f014ded1f.png",
      coingeckoId: "deeper-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf2cee90309418353a57717ECa26C4f8754F0d84e",
      symbol: "BTCB",
      name: "BitcoinBrand",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "bitcoinbrand",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf29e46887FFAE92f1ff87DfE39713875Da541373",
      symbol: "UNC",
      name: "UniCrypt",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xf29e46887ffae92f1ff87dfe39713875da541373/c6f82a953ea461c2627d743f355625fe.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4",
      symbol: "SYLO",
      name: "Sylo",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4/46647c0ffe8e53b25648ffdf1717cbee.png",
      coingeckoId: "sylo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf278c1CA969095ffddDED020290cf8B5C424AcE2",
      symbol: "RUFF",
      name: "RUFF",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf278c1ca969095ffddded020290cf8b5c424ace2/74df4651a70e35a693d48c72d7788c99.png",
      coingeckoId: "ruff",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf203Ca1769ca8e9e8FE1DA9D147DB68B6c919817",
      symbol: "WNCG",
      name: "Wrapped NCG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf203ca1769ca8e9e8fe1da9d147db68b6c919817/bd655010ec3fabadfb61fcc9356c5a35.png",
      coingeckoId: "wrapped-ncg",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b",
      symbol: "RLY",
      name: "RLY",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12843/small/image.png?1611212077",
      coingeckoId: "rally-2",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance", "xyfinance"],
    },
    {
      address: "0xf1a91C7d44768070F711c68f33A7CA25c8D30268",
      symbol: "C3",
      name: "CHARLI3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf1a91c7d44768070f711c68f33a7ca25c8d30268/acab0deedaf185d67d11203bb361fca0.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xf16e81dce15B08F326220742020379B855B87DF9",
      symbol: "ICE",
      name: "IceToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf16e81dce15b08f326220742020379b855b87df9/e7a766dfa717182ab11805d4582638d5.png",
      coingeckoId: "ice-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0xf14b9ADF84812bA463799357f4dc716b4384010B",
      symbol: "PP",
      name: "Pension Plan",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf14b9adf84812ba463799357f4dc716b4384010b/11c4f29c5ac1848540a1bce51a6ea0f5.png",
      coingeckoId: "pension-plan",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf0f9D895aCa5c8678f706FB8216fa22957685A13",
      symbol: "CULT",
      name: "Cult DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf0f9d895aca5c8678f706fb8216fa22957685a13/f69135ebfc8acda74a72ccf493c19dfe.png",
      coingeckoId: "cult-dao",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xf04a8ac553FceDB5BA99A64799155826C136b0Be",
      symbol: "FLIXX",
      name: "Flixx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf04a8ac553fcedb5ba99a64799155826c136b0be/84c3a2bcd1cc48d34c0eb68e6e53dd42.png",
      coingeckoId: "flixxo",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xf009f5531dE69067435e32c4b9D36077F4C4A673",
      symbol: "UNV",
      name: "Unvest",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf009f5531de69067435e32c4b9d36077f4c4a673/4e61ac169c982d78a016ec029b07f68c.png",
      coingeckoId: "unvest",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xef58b2d5A1b8D3cDE67b8aB054dC5C831E9Bc025",
      symbol: "crSRM",
      name: "Cream Serum",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xef58b2d5a1b8d3cde67b8ab054dc5c831e9bc025.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d",
      symbol: "FIS",
      name: "StaFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d/1f6799fffd88b4da445347aafa4bf017.png",
      coingeckoId: "stafi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xee573a945B01B788B9287CE062A0CFC15bE9fd86",
      symbol: "XED",
      name: "Exeedme",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xee573a945b01b788b9287ce062a0cfc15be9fd86/6ee00e70a89705db9eb091af40912b3a.png",
      coingeckoId: "exeedme",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xed0889F7E1c7C7267407222Be277e1f1Ef4d4892",
      symbol: "MEL",
      name: "Melalie",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed0889f7e1c7c7267407222be277e1f1ef4d4892/5b62cbfdeeaf1d12403a8a60d852f65e.png",
      coingeckoId: "melalie",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xeca82185adCE47f39c684352B0439f030f860318",
      symbol: "PERL",
      name: "PERL.eco",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeca82185adce47f39c684352b0439f030f860318/50dc1b40996482a6bc6d3d219c849889.png",
      coingeckoId: "perlin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892",
      symbol: "MLN",
      name: "Melon Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xec67005c4e498ec7f55e092bd1d35cbc47c91892/facd5b949338962b7e84c36dfe6d5d81.png",
      coingeckoId: "melon",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0xebF2096E01455108bAdCbAF86cE30b6e5A72aa52",
      symbol: "XIDR",
      name: "XIDR",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xebf2096e01455108badcbaf86ce30b6e5a72aa52/49b012c3ea2ee2f7487b365def8b043d.png",
      coingeckoId: "straitsx-indonesia-rupiah",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xeb494890465f49C2b94457d9b61811392E5B1Fea",
      symbol: "SLAB",
      name: "SLINK LABS",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeb494890465f49c2b94457d9b61811392e5b1fea/a3d6b89ee3d4ccab4d344a926b2d4d2d.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xeb269732ab75A6fD61Ea60b06fE994cD32a83549",
      symbol: "USDx",
      name: "dForce",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeb269732ab75a6fd61ea60b06fe994cd32a83549/b1f65bf55f2b8086b4643eead5409969.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xea7Cc765eBC94C4805e3BFf28D7E4aE48D06468A",
      symbol: "PAD",
      name: "NearPad",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xea7Cc765eBC94C4805e3BFf28D7E4aE48D06468A/logo.png",
      coingeckoId: "nearpad",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xea5f88E54d982Cbb0c441cde4E79bC305e5b43Bc",
      symbol: "PARETO",
      name: "Pareto Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xeFF039C3c1D668f408d09dD7B63008622a77532C",
      symbol: "crWNXM",
      name: "Cream Wrapped NXM",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xeff039c3c1d668f408d09dd7b63008622a77532c.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xeF9Cd7882c067686691B6fF49e650b43AFBBCC6B",
      symbol: "FNX",
      name: "FinNexus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xef9cd7882c067686691b6ff49e650b43afbbcc6b/ec3708fcf0fcfb4b47e365ac90a8227f.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xeF497B970f50286a9805b91809b8615E6d0eF19B",
      symbol: "HARU",
      name: "HARU",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xef497b970f50286a9805b91809b8615e6d0ef19b/bee5ebd8df26f68efbd57b96443c20c8.png",
      coingeckoId: "haru",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
      symbol: "ELK",
      name: "Elk",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png",
      coingeckoId: "elk-finance",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0xeEEE2a622330E6d2036691e983DEe87330588603",
      symbol: "ASKO",
      name: "Askobar Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeeee2a622330e6d2036691e983dee87330588603/87d182034befa0f1ba0f5c456525c4f5.png",
      coingeckoId: "askobar-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xeEAA40B28A2d1b0B08f6f97bB1DD4B75316c6107",
      symbol: "GOVI",
      name: "CVI Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107/ed702729f956fb703ef496ab343f2c79.png",
      coingeckoId: "govi",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xeDADeB5fAa413e6c8623461849DFD0B7C3790c32",
      symbol: "OBOT",
      name: "OBORTECH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xedadeb5faa413e6c8623461849dfd0b7c3790c32/ed4dace7d1dfbb6f84e0568d43ffd689.png",
      coingeckoId: "obortech",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xeD35af169aF46a02eE13b9d79Eb57d6D68C1749e",
      symbol: "OMI",
      name: "OMI Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed35af169af46a02ee13b9d79eb57d6d68c1749e/df1c18da4855b81d80914dbfec38e3f3.png",
      coingeckoId: "ecomi",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xeCe8531550Fc599ccB2c7734494FBc358d0a9483",
      symbol: "PP",
      name: "PP",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "phoenix-protocol-b7a9513c-36e9-4a6b-b6ae-6a1a76bb913e",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD",
      symbol: "RAINI",
      name: "Rainicorn",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed/28c182d359264f551c158371f4f99867.png",
      coingeckoId: "rainicorn",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xeB66ACc3d011056B00ea521F8203580C2E5d3991",
      symbol: "IDLEUSDC",
      name: "IdleUSDC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xeb66acc3d011056b00ea521f8203580c2e5d3991.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xe9F84dE264E91529aF07Fa2C746e934397810334",
      symbol: "SAK3",
      name: "Sake",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe9F84dE264E91529aF07Fa2C746e934397810334/logo.png",
      coingeckoId: "sak3",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xe89a6D0509faF730BD707bf868d9A2A744a363C7",
      symbol: "crUNI",
      name: "Cream Uniswap",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xe89a6d0509faf730bd707bf868d9a2a744a363c7.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xe88f8313e61A97cEc1871EE37fBbe2a8bf3ed1E4",
      symbol: "VAL",
      name: "Sora Validator Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4/0d9f1d9af9fa441fbc7316d8001364e3.png",
      coingeckoId: "sora-validator-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8",
      symbol: "HOKK",
      name: "Hokkaido Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8/09e510841bb72c807eabf20b1cceed5d.png",
      coingeckoId: "hokkaidu-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D",
      symbol: "RAIL",
      name: "Rail",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe76c6c83af64e4c60245d8c7de953df673a7a33d/c5c0c36b99b1a58aa89e9723bd749b78.png",
      coingeckoId: "railgun",
      listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0xe6fd75ff38Adca4B97FBCD938c86b98772431867",
      symbol: "ELA",
      name: "ELA on Ethereum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe6fd75ff38adca4b97fbcd938c86b98772431867/b148a2f763a157171740eafbcab9527e.png",
      coingeckoId: "elastos",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe6602B34D8510B033E000975B3322537c7172441",
      symbol: "FRR",
      name: "Frontrow",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe6602b34d8510b033e000975b3322537c7172441/6af7e0c04f37fd55d9b344a3cdc866e4.png",
      coingeckoId: "front-row",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xe63d6B308BCe0F6193AeC6b7E6eBa005f41e36AB",
      symbol: "STN",
      name: "STN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe63d6b308bce0f6193aec6b7e6eba005f41e36ab/508eda239006b6dfbdf5026a0ae1bafa.png",
      coingeckoId: "stone-token",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xe5feeaC09D36B18b3FA757E5Cf3F8dA6B8e27F4C",
      symbol: "NFTI",
      name: "NFT INDEX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c/e9ad4f356eb0e72a5f59774b2bc801b4.png",
      coingeckoId: "nft-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55",
      symbol: "SUPER",
      name: "SUPER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14040/small/6YPdWn6.png?1613975899",
      coingeckoId: "superfarm",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xe4815AE53B124e7263F08dcDBBB757d41Ed658c6",
      symbol: "ZKS",
      name: "Zks",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe4815ae53b124e7263f08dcdbbb757d41ed658c6/4aefdfe267679c7a24dfb7c92721d8e4.png",
      coingeckoId: "zkspace",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe3c408BD53c31C085a1746AF401A4042954ff740",
      symbol: "GMT",
      name: "GreenMetaverseToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe3c408bd53c31c085a1746af401a4042954ff740/6af4d505187c6146f2aa44d9c5d217d5.png",
      coingeckoId: "stepn",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe3a4a881d18F219Aedb42EFD58a76C6786640515",
      symbol: "FM",
      name: "Follow Me Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xe3818504c1B32bF1557b16C238B2E01Fd3149C17",
      symbol: "PLR",
      name: "PILLAR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe3818504c1b32bf1557b16c238b2e01fd3149c17/e76d4b770d616695c53cbee64ad4fe68.png",
      coingeckoId: "pillar",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5",
      symbol: "MUSD",
      name: "mStable USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe2f2a5c287993345a840db3b0845fbc70f5935a5/357120f7f862a9144a5c80757460c72e.png",
      coingeckoId: "musd",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xe2D8F9557fD46AD134C8fb5Eabf6f4DA3E0d3f68",
      symbol: "RATIOMOON",
      name: "ETHBTC 2x Long",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe2D8F9557fD46AD134C8fb5Eabf6f4DA3E0d3f68/logo.png",
      coingeckoId: "ethbtc-2x-long-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30",
      symbol: "INJ",
      name: "Injective",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe28b3b32b6c345a34ff64674606124dd5aceca30/f254a215e85a46b97a0dcc9f036097d4.png",
      coingeckoId: "injective-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xe25bCec5D3801cE3a794079BF94adF1B8cCD802D",
      symbol: "MAN",
      name: "MATRIX AI Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe25bcec5d3801ce3a794079bf94adf1b8ccd802d/321bd807170818be0001110aa60baa83.png",
      coingeckoId: "matrix-ai-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe25b0BBA01Dc5630312B6A21927E578061A13f55",
      symbol: "SHIP",
      name: "ShipChain SHIP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe25b0bba01dc5630312b6a21927e578061a13f55/d77bb89e45b4912f1ef05798e80a102c.png",
      coingeckoId: "shipchain",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xe1aFe1Fd76Fd88f78cBf599ea1846231B8bA3B6B",
      symbol: "sDEFI",
      name: "Synth sDEFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b/0b60c8b61d57ded15de4001c3da43c2f.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xe1030B48b2033314979143766d7dC1F40ef8CE11",
      symbol: "PEEPS",
      name: "The People’s Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe1030b48b2033314979143766d7dc1f40ef8ce11/4a6be5f6206f97e3b0f0259954c9f91c.png",
      coingeckoId: "the-people-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe0cCa86B254005889aC3a81e737f56a14f4A38F5",
      symbol: "ALTA",
      name: "Alta Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe0cca86b254005889ac3a81e737f56a14f4a38f5/cec21c3f56d978832222dc6c61383145.png",
      coingeckoId: "alta-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe0B9a2C3E9f40CF74B2C7F591B2b0CCa055c3112",
      symbol: "GS",
      name: "Gen Shards",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112/aa1241ec72662aa5fe25bfa5587afd76.png",
      coingeckoId: "genesis-shards",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xe047705117Eb07e712C3d684f5B18E74577e83aC",
      symbol: "BCP",
      name: "BitcashPay",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe047705117eb07e712c3d684f5b18e74577e83ac/280079476e202ce99614fe660a693cbd.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xdfe691F37b6264a90Ff507EB359C45d55037951C",
      symbol: "KARMA",
      name: "Karma",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdfe691f37b6264a90ff507eb359c45d55037951c/e657af1b6538fd6457db90bae9e80775.png",
      coingeckoId: "karma-dao",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xdf09a216Fac5ADC3e640Db418C0b956076509503",
      symbol: "PKN",
      name: "Poken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdf09a216fac5adc3e640db418c0b956076509503/26354236a6f46f4e9199f4daf4fe6946.png",
      coingeckoId: "poken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202",
      symbol: "KNC",
      name: "Kyber Network Crystal v2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202/1d25e188deb06e642ea6f4f4f8eb0a0c.png",
      coingeckoId: "kyber-network-crystal",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xde4EE8057785A7e8e800Db58F9784845A5C2Cbd6",
      symbol: "DEXE",
      name: "Dexe",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde4ee8057785a7e8e800db58f9784845a5c2cbd6/58df2569b7e811eeb45f5206a7d3a796.png",
      coingeckoId: "dexe",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xde16Ce60804a881e9F8c4eBB3824646EDecd478D",
      symbol: "MCRT",
      name: "MagicCraft",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde16ce60804a881e9f8c4ebb3824646edecd478d/e2613af677c84d86c2df3b3359e10f7c.png",
      coingeckoId: "magiccraft",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xddaC9C604BA6Bc4ACEc0FBB485B83f390ECF2f31",
      symbol: "CyOp",
      name: "CyOp | Protocol",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xddac9c604ba6bc4acec0fbb485b83f390ecf2f31/9e3a09aef0c5bec1dc7b5faa661343b0.png",
      coingeckoId: "cyop-protocol",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
      symbol: "KNC",
      name: "KyberNetwork",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png",
      coingeckoId: "kyber-network",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xdc9Ac3C20D1ed0B540dF9b1feDC10039Df13F99c",
      symbol: "UTK",
      name: "Utrust Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c/71262b1a4984b468f87d817613ec2514.png",
      coingeckoId: "utrust",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xdc8aF07A7861bedD104B8093Ae3e9376fc8596D2",
      symbol: "RVF",
      name: "Rocket Vault",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc8af07a7861bedd104b8093ae3e9376fc8596d2/4cca51c0f827a9a057b3722cc312abc5.png",
      coingeckoId: "rocketx",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xdbDD6F355A37b94e6C7D32fef548e98A280B8Df5",
      symbol: "UWL",
      name: "UniWhales",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdbDD6F355A37b94e6C7D32fef548e98A280B8Df5/logo.png",
      coingeckoId: "uniwhales",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xdb726152680eCe3c9291f1016f1d36f3995f6941",
      symbol: "MEDIA",
      name: "Media Network Token",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdb726152680eCe3c9291f1016f1d36f3995f6941/logo.png",
      coingeckoId: "media-network",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xdb0aCC14396D108b3C5574483aCB817855C9dc8d",
      symbol: "EMB",
      name: "Emblem",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdb0acc14396d108b3c5574483acb817855c9dc8d/f7702e8ad5a0f688cb55fe806524dd3c.png",
      coingeckoId: "overline-emblem",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xdacD69347dE42baBfAEcD09dC88958378780FB62",
      symbol: "ATRI",
      name: "AtariToken",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdacd69347de42babfaecd09dc88958378780fb62/8a239ffa3c0a89fe22c08b521b65bff2.png",
      coingeckoId: "atari",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b",
      symbol: "GFI",
      name: "Goldfinch",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b/075955e47605d6075da9a5f9d6566684.png",
      coingeckoId: "goldfinch",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0xdFC3829b127761a3218bFceE7fc92e1232c9D116",
      symbol: "WPRCY",
      name: "Wrapped PRCY",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdfc3829b127761a3218bfcee7fc92e1232c9d116/2204a23f1760fd5e93606005c37e61b7.png",
      coingeckoId: "prcy-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xdF96bDE075d59e9143b325C75aF38e208c986E6F",
      symbol: "YETIC",
      name: "YetiCoin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdf96bde075d59e9143b325c75af38e208c986e6f/eef7aabf41aa172480d1c78ec3aebea4.png",
      coingeckoId: "yeticoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xdF9307DFf0a1B57660F60f9457D32027a55ca0B2",
      symbol: "mETH",
      name: "DMM: ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xdf9307dff0a1b57660f60f9457d32027a55ca0b2.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8",
      symbol: "yCurve",
      name: "Curve.fi iearn pool token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8/2c248cc2c78ef7a55e99c1b18673879a.png",
      coingeckoId: "curve-fi-ydai-yusdc-yusdt-ytusd",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xdF574c24545E5FfEcb9a659c229253D4111d87e1",
      symbol: "HUSD",
      name: "HUSD",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/9567/small/HUSD.jpg?1568889385",
      coingeckoId: "husd",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0xdEaA665aB71785Ccf576dc76e5fcB5a2283ea0C6",
      symbol: "YNI",
      name: "YNI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1634706286119_5747107054114409.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF",
      symbol: "ALCX",
      name: "ALCX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14113/small/Alchemix.png?1614409874",
      coingeckoId: "alchemix",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xdB25f211AB05b1c97D595516F45794528a807ad8",
      symbol: "EURS",
      name: "STASIS EURS Token",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdb25f211ab05b1c97d595516f45794528a807ad8/ecd03f58c8dc82302a364901ea58e1ac.png",
      coingeckoId: "stasis-eurs",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
      coingeckoId: "tether",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xdA1E53E088023Fe4D1DC5a418581748f52CBd1b8",
      symbol: "AIDI",
      name: "Aidi Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xda1e53e088023fe4d1dc5a418581748f52cbd1b8/57de27954176dbebc205956e3d7dee21.png",
      coingeckoId: "aidi-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd9b312D77Bc7BEd9B9CecB56636300bED4Fe5Ce9",
      symbol: "GAINS",
      name: "Gains",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9/f1326a1138a0492ad1ac1866887cbc5a.png",
      coingeckoId: "gains",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b",
      symbol: "EUL",
      name: "Euler Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd9fcd98c322942075a5c3860693e9f4f03aae07b/191aed63c8673713509cda0e00c3ce0b.png",
      coingeckoId: "euler",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd98F75b1A3261dab9eEd4956c93F33749027a964",
      symbol: "SHR",
      name: "ShareToken",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd98f75b1a3261dab9eed4956c93f33749027a964/52b8f953a4a00eb8d5817936b112ec24.png",
      coingeckoId: "sharering",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd945d2031b4C63C0E363304FB771F709b502DC0a",
      symbol: "BMC",
      name: "BountyMarketCap",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "bountymarketcap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd8C4ef2D99c9F962180008418a5665Fcc4b99ecD",
      symbol: "RDX",
      name: "Redux Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd8c4ef2d99c9f962180008418a5665fcc4b99ecd/d6ec2c4b6d0c048eca004f335768d471.png",
      coingeckoId: "redux-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd83C569268930fadAd4cdE6D0cB64450fef32b65",
      symbol: "ICAP",
      name: "Invictus Capital Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd83c569268930fadad4cde6d0cb64450fef32b65/1bc8039bee145a5a0bcfbc0d31013c6f.png",
      coingeckoId: "invictus-capital-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd7f0cC50AD69408aE58be033F4f85D2367C2e468",
      symbol: "VERA",
      name: "Vera",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7f0cc50ad69408ae58be033f4f85d2367c2e468/5d0187c9b569d067c0aa22d87c6e63e2.png",
      coingeckoId: "vera",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd7c302fc3ac829C7E896a32c4Bd126f3e8Bd0a1f",
      symbol: "B2M",
      name: "Bit2Me",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7c302fc3ac829c7e896a32c4bd126f3e8bd0a1f/85cf78a2b7bffeb79a876683dd7e0504.png",
      coingeckoId: "bit2me",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd794DD1CAda4cf79C9EebaAb8327a1B0507ef7d4",
      symbol: "HYVE",
      name: "HYVE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd794dd1cada4cf79c9eebaab8327a1b0507ef7d4/76002a1c404bbb165f49e5f3a68b1aac.png",
      coingeckoId: "hyve",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd779eEA9936B4e323cDdff2529eb6F13d0A4d66e",
      symbol: "ENTR",
      name: "ENTER Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd779eea9936b4e323cddff2529eb6f13d0a4d66e/7376b21480de1c3dfc8209f4bef89611.png",
      coingeckoId: "enterdao",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xd7631787B4dCc87b1254cfd1e5cE48e96823dEe8",
      symbol: "SCL",
      name: "Sociall",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7631787b4dcc87b1254cfd1e5ce48e96823dee8/2b735de975959527302d6647f1706fc1.png",
      coingeckoId: "sociall",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xd6aD7a6750A7593E092a9B218d66C0A814a3436e",
      symbol: "yUSDCv2",
      name: "iearn USDC v2",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xd6ad7a6750a7593e092a9b218d66c0a814a3436e/a151c8cd48494726f2b090d013449c57.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xd6327ce1fb9D6020E8C2c0E124A1eC23DCAb7536",
      symbol: "CUMINU",
      name: "Cum Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xd6327ce1fb9d6020e8c2c0e124a1ec23dcab7536/logo.png",
      coingeckoId: "cuminu",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xd559f20296FF4895da39b5bd9ADd54b442596a61",
      symbol: "FTX",
      name: "FintruX Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd559f20296ff4895da39b5bd9add54b442596a61/5d0a87daba596a686f4374b41d58b16a.png",
      coingeckoId: "fintrux",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xd55236D48606c295adEbF129dAD04Fc74BFaA708",
      symbol: "CW",
      name: "CardWallet",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd55236d48606c295adebf129dad04fc74bfaa708/7f944e4f39b505e4d1db5c8f473d6ec5.png",
      coingeckoId: "cardwallet",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd5281BB2d1eE94866B03A0fcCDd4e900c8Cb5091",
      symbol: "HUSKY",
      name: "HuskyToken",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xd5281bb2d1ee94866b03a0fccdd4e900c8cb5091/logo.png",
      coingeckoId: "husky",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xd4fa1460F537bb9085d22C7bcCB5DD450Ef28e3a",
      symbol: "PPT",
      name: "Populous Platform",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a/e0214c63ef3498adaf92a7cb0d63a2b0.png",
      coingeckoId: "populous",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd4cA5c2AFf1eeFb0BeA9e9Eab16f88DB2990C183",
      symbol: "XRPC",
      name: "XRP CLASSIC",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: "xrp-classic",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd4c435F5B09F855C3317c8524Cb1F586E42795fa",
      symbol: "CND",
      name: "Cindicator",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd4c435f5b09f855c3317c8524cb1f586e42795fa/6f01e4dd2b9b62d982ca3d516a710391.png",
      coingeckoId: "cindicator",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xd3E4Ba569045546D09CF021ECC5dFe42b1d7f6E4",
      symbol: "MNW",
      name: "Morpheus.Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4/ef0db186cd4ce50b73176e1b14b43f59.png",
      coingeckoId: "morpheus-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd38BB40815d2B0c2d2c866e0c72c5728ffC76dd9",
      symbol: "SIS",
      name: "Symbiosis",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9/f2a59df196e9e3933fd33f76abbfeb19.png",
      coingeckoId: "symbiosis-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xd341d1680Eeee3255b8C4c75bCCE7EB57f144dAe",
      symbol: "ONG",
      name: "onG.social",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd341d1680eeee3255b8c4c75bcce7eb57f144dae/9fc39ae273e9fa2e4e3921d691e21fe4.png",
      coingeckoId: "somee-social-old",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9",
      symbol: "LUNA",
      name: "Wrapped LUNA Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9/c7ef0b4fd76f65eb25a740aff6b33551.png",
      coingeckoId: "wrapped-terra",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0xd26a9C3437f7D121098c8C05C7413F5Cc70BB070",
      symbol: "AZUM",
      name: "Azuma coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/10483/large/WDncHJ5.png",
      coingeckoId: "azuma-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
      symbol: "OMG",
      name: "OMG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/776/small/OMG_Network.jpg?1591167168",
      coingeckoId: "omisego",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xd1ba9BAC957322D6e8c07a160a3A8dA11A0d2867",
      symbol: "HMT",
      name: "Human Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd1ba9bac957322d6e8c07a160a3a8da11a0d2867/7d63c0dfe3b3fd78cce72c8f219a7205.png",
      coingeckoId: "human-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd15eCDCF5Ea68e3995b2D0527A0aE0a3258302F8",
      symbol: "MCX",
      name: "MachiX Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8.png",
      coingeckoId: "machix",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xd123575D94A7aD9Bff3ad037aE9d4d52f41a7518",
      symbol: "WWish",
      name: "Wrapped Wish",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd123575d94a7ad9bff3ad037ae9d4d52f41a7518/47ae82c3dc4a23dff9d1b158b257c8ea.png",
      coingeckoId: "mywish",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd109b2A304587569c84308c55465cd9fF0317bFB",
      symbol: "aAmmBptBALWETH",
      name: "Aave AMM Market BptBALWETH",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "aave-amm-bptbalweth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd0983dD6a0334c71d0478255D1F794508026b888",
      symbol: "EGG",
      name: "EGG Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd0983dd6a0334c71d0478255d1f794508026b888/ef0befdce165e5a48fa970dca1cf7bba.png",
      coingeckoId: "egg-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988",
      symbol: "TVK",
      name: "Terra Virtua Kolect",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988/6c5e2d879c95934444b506af91cbd819.png",
      coingeckoId: "the-virtua-kolect",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xd084944d3c05CD115C09d072B9F44bA3E0E45921",
      symbol: "FOLD",
      name: "Manifold Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd084944d3c05cd115c09d072b9f44ba3e0e45921/a2474fbab49a06aea9c549a6e14aba15.png",
      coingeckoId: "manifold-finance",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xd075e95423C5c4BA1E122CaE0f4CdFA19b82881b",
      symbol: "wPE",
      name: "OPES Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd075e95423c5c4ba1e122cae0f4cdfa19b82881b/44c790d9a1813b478c05a8f4ca8681b8.png",
      coingeckoId: "opes-wrapped-pe",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A",
      symbol: "ADS",
      name: "Adshares",
      decimals: 11,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a/ac7f437f47b961c42b3aca2701a17631.png",
      coingeckoId: "adshares",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E",
      symbol: "FLOKI",
      name: "FLOKI",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcf0c122c6b73ff809c693db761e7baebe62b6a2e/4dba60d2f00756d3a811f74931bcf73d.png",
      coingeckoId: "floki-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xceb286C9604c542d3cc08b41AA6C9675B078A832",
      symbol: "VTX",
      name: "Vortex DeFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xceb286c9604c542d3cc08b41aa6c9675b078a832/12a69cfc9a9a3471c921cf0494f221b3.png",
      coingeckoId: "vortex-defi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xcca0c9c383076649604eE31b20248BC04FdF61cA",
      symbol: "BTMX",
      name: "BitMax token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xcca0c9c383076649604ee31b20248bc04fdf61ca/604f3414285ba69d8da455bf9cb9b1e3.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97",
      symbol: "GODS",
      name: "Gods Unchained",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xccc8cb5229b0ac8069c51fd58367fd1e622afd97/6bc55b861e3ecb7a5bedefaf723cb09a.png",
      coingeckoId: "gods-unchained",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0xcbd55D4fFc43467142761A764763652b48b969ff",
      symbol: "ASTRO",
      name: "AstroTools.io",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcbd55d4ffc43467142761a764763652b48b969ff/a2bd295f3ca0217b2ce47fdeb2c6499a.png",
      coingeckoId: "astrotools",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xcb46C550539ac3DB72dc7aF7c89B11c306C727c2",
      symbol: "pONT",
      name: "Poly Ontology Toke",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcb46c550539ac3db72dc7af7c89b11c306c727c2/95e13c7dcab0aeea8566d9a833aace50.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xcafE34BAE6F1b23A6B575303EdCc0578d2188131",
      symbol: "BIPX",
      name: "Minter BIP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcafe34bae6f1b23a6b575303edcc0578d2188131/05bbdc3e942060459524844d547a98d4.png",
      coingeckoId: "minter-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcaDC0acd4B445166f12d2C07EAc6E2544FbE2Eef",
      symbol: "CADC",
      name: "CAD Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef/9e7a7b38b9a357ccdd21f4c61e638ca7.png",
      coingeckoId: "cad-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xca1207647Ff814039530D7d35df0e1Dd2e91Fa84",
      symbol: "DHT",
      name: "dHedge DAO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xca1207647ff814039530d7d35df0e1dd2e91fa84/0f08e26a6166f8b39b59f64ed9fef096.png",
      coingeckoId: "dhedge-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xcFbf70e33d5163E25B0dad73955c1BD9E8cd8BA2",
      symbol: "WNL",
      name: "WINS LIVE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcfbf70e33d5163e25b0dad73955c1bd9e8cd8ba2/b4a77068a52c06b37d5e4f4a05804bda.png",
      coingeckoId: "winstars",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcFEB09C3c5F0f78aD72166D55f9e6E9A60e96eEC",
      symbol: "VEMP",
      name: "vEmpire Gamer Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec/34c7a17e3cb5e03300ff72e6a46c2c46.png",
      coingeckoId: "vempire-ddao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcF78C7dD70d6F30F6E3609e905e78305Da98c863",
      symbol: "ONX",
      name: "Ownix",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcf78c7dd70d6f30f6e3609e905e78305da98c863/7a1dd9df63e95ebd7300525b679adcbd.png",
      coingeckoId: "ownix",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcE4Fe9b4b8Ff61949DCfeB7e03bc9FAca59D2Eb3",
      symbol: "crBAL",
      name: "Cream Balancer",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xce4fe9b4b8ff61949dcfeb7e03bc9faca59d2eb3.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xcD91538B91B4ba7797D39a2f66E63810b50A33d0",
      symbol: "STABLEx",
      name: "ARC STABLEx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xcd91538b91b4ba7797d39a2f66e63810b50a33d0.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xcD62b1C403fa761BAadFC74C525ce2B51780b184",
      symbol: "ANJ",
      name: "Aragon Network Juror",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcd62b1c403fa761baadfc74c525ce2b51780b184/9d3c059e199f8dccfef739220ee140a1.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd",
      symbol: "CRETH2",
      name: "Cream ETH 2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd/logo.png",
      coingeckoId: "cream-eth2",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xcB8fb2438A805664cD8c3e640b85AC473DA5BE87",
      symbol: "CTI",
      name: "ClinTex CTI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcb8fb2438a805664cd8c3e640b85ac473da5be87/28f36597ab7744e9523e495d67804f87.png",
      coingeckoId: "clintex-cti",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcB84d72e61e383767C4DFEb2d8ff7f4FB89abc6e",
      symbol: "VEGA",
      name: "Vega",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xcB84d72e61e383767C4DFEb2d8ff7f4FB89abc6e/logo.png",
      coingeckoId: "vega-protocol",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5",
      symbol: "PSP",
      name: "ParaSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcafe001067cdef266afb7eb5a286dcfd277f3de5/142412ec7069ec0a4806f2bfc2855022.png",
      coingeckoId: "paraswap",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xcAaa93712BDAc37f736C323C93D4D5fDEFCc31CC",
      symbol: "CRD",
      name: "CryptalDash",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcaaa93712bdac37f736c323c93d4d5fdefcc31cc/d93d8f504fb2c513efd2c423785ad714.png",
      coingeckoId: "crd-network",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xcA75C43f8c9AfD356c585ce7AA4490B48A95C466",
      symbol: "inari",
      name: "Inari",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xca75c43f8c9afd356c585ce7aa4490b48a95c466/logo.png",
      coingeckoId: "inari",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xc98D64DA73a6616c42117b582e832812e7B8D57F",
      symbol: "RSS3",
      name: "RSS3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc98d64da73a6616c42117b582e832812e7b8d57f/e9b934acebbf74479da30eed99d514bd.png",
      coingeckoId: "rss3",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
      symbol: "GRT",
      name: "The Graph",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xc944E90C64B2c07662A292be6244BDf05Cda44a7/logo.png",
      coingeckoId: "the-graph",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xc87f0a3F3671F7d01278625561dc6e284B62daE9",
      symbol: "iEUROS",
      name: "Inflation Adjusted Spice EUR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc87f0a3f3671f7d01278625561dc6e284b62dae9/5cafd61f0e429172db50ce479514b144.png",
      coingeckoId: "inflation-adjusted-euro",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc834Fa996fA3BeC7aAD3693af486ae53D8aA8B50",
      symbol: "CONV",
      name: "Convergence",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc834fa996fa3bec7aad3693af486ae53d8aa8b50/d91f93fc6e003a6a59dc781082c3f2db.png",
      coingeckoId: "convergence",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc813EA5e3b48BEbeedb796ab42A30C5599b01740",
      symbol: "NIOX",
      name: "Autonio",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc813ea5e3b48bebeedb796ab42a30c5599b01740/ae3e4ee9d30aa9d9c1793e8176f008e8.png",
      coingeckoId: "autonio",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xc7fF1e126cC81e816915FF48c940ED9d4E6d05d6",
      symbol: "IJC",
      name: "Ijas Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc7ff1e126cc81e816915ff48c940ed9d4e6d05d6/55c1b854764ed8fb5bc848a1916327d2.png",
      coingeckoId: "ijascoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc7Fd8Dcee4697ceef5a2fd4608a7BD6A94C77480",
      symbol: "crCRV",
      name: "Cream CRV",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xc7fd8dcee4697ceef5a2fd4608a7bd6a94c77480.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d",
      symbol: "FOX",
      name: "ShapeShift FOX Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc770eefad204b5180df6a14ee197d99d808ee52d/a3ba70c14f1dfe0cd7bff6c759686b33.png",
      coingeckoId: "shapeshift-fox-token",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xc75F15AdA581219c95485c578E124df3985e4CE0",
      symbol: "ZZZ",
      name: "zzz.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xc75f15ada581219c95485c578e124df3985e4ce0.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xc73C167E7a4Ba109e4052f70D5466D0C312A344D",
      symbol: "SANSHU",
      name: "Sanshu Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc73c167e7a4ba109e4052f70d5466d0c312a344d/2e00721e2a289a85975dbe1b712a87a0.png",
      coingeckoId: "sanshu-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B",
      symbol: "TRIBE",
      name: "Tribe",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b/581e3adf3afc2fddcb086a04f575ed23.png",
      coingeckoId: "tribe-2",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0xc7230BADF274995F1933598c249c824fDE26F426",
      symbol: "SUTEKU",
      name: "Suteku",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc7230badf274995f1933598c249c824fde26f426/53b1edb8147de71c81a671a72b7727a0.png",
      coingeckoId: "suteku",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc719d010B63E5bbF2C0551872CD5316ED26AcD83",
      symbol: "DIP",
      name: "Decentralized Insurance Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc719d010b63e5bbf2c0551872cd5316ed26acd83/4e8cb76f2eea7d73a13e1bc65c00f561.png",
      coingeckoId: "etherisc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc713e5E149D5D0715DcD1c156a020976e7E56B88",
      symbol: "aMKR",
      name: "Aave interest bearing MKR",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aMKR.svg",
      coingeckoId: "aave-mkr",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc6e145421FD494B26dCF2BFeB1b02b7c5721978f",
      symbol: "CRPX",
      name: "Crypto Perx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xc6e145421FD494B26dCF2BFeB1b02b7c5721978f/logo.png",
      coingeckoId: "crypto-perx",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b",
      symbol: "URUS",
      name: "Aurox Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b/4816f703bcf33ec16f8a8306516d2be1.png",
      coingeckoId: "urus-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc690F7C7FcfFA6a82b79faB7508c466FEfdfc8c5",
      symbol: "LYM",
      name: "Lympo tokens",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5/8de3747afb3b1570ec21e9d9de1166c4.png",
      coingeckoId: "lympo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc68251421eDDa00a10815E273fA4b1191fAC651b",
      symbol: "crPICKLE",
      name: "Cream PickleToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xc68251421edda00a10815e273fa4b1191fac651b.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xc666081073E8DfF8D3d1c2292A29aE1A2153eC09",
      symbol: "DGTX",
      name: "DigitexFutures",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc666081073e8dff8d3d1c2292a29ae1a2153ec09/3708141d151d8730c11bcbf28dc9c8ec.png",
      coingeckoId: "digitex-futures-exchange",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc5fB36dd2fb59d3B98dEfF88425a3F425Ee469eD",
      symbol: "TSUKA",
      name: "\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000 ",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5fb36dd2fb59d3b98deff88425a3f425ee469ed/8de85b42da78142494b7c3277f63eaa4.png",
      coingeckoId: "dejitaru-tsuka",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc5bDdf9843308380375a611c18B50Fb9341f502A",
      symbol: "yveCRV-DAO",
      name: "veCRV-DAO yVault",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5bddf9843308380375a611c18b50fb9341f502a/13271ba216ffae9c0832c90f59bd36d5.png",
      coingeckoId: "vecrv-dao-yvault",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC",
      symbol: "HOP",
      name: "Hop",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc/5563c83acbf831e5ca1a16e44c43eb35.png",
      coingeckoId: "hop-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc50EF449171a51FbeAFd7c562b064B6471C36caA",
      symbol: "ZINU",
      name: "Zombie Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc50ef449171a51fbeafd7c562b064b6471c36caa/a18689645b17522e9a0a44c9dee9573b.png",
      coingeckoId: "zombie-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc5019E129b75D380d3d837B8e609dEc6c8f5d044",
      symbol: "GN",
      name: "GN",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5019e129b75d380d3d837b8e609dec6c8f5d044/9909aa2372aadeb8413dda21c8a157c4.png",
      coingeckoId: "gn",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xc4c7Ea4FAB34BD9fb9a5e1B1a98Df76E26E6407c",
      symbol: "COCOS",
      name: "CocosTokenV2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c/393f5ad504e17b56ee474d8bd9b75783.png",
      coingeckoId: "cocos-bcx",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F",
      symbol: "ibBTC",
      name: "Interest-Bearing BTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc4e15973e6ff2a35cc804c2cf9d2a1b817a8b40f/435e73e3f4114f36ad9664fbb3628889.png",
      coingeckoId: "interest-bearing-bitcoin",
      listedIn: ["coingecko", "sushiswap", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xc4De189Abf94c57f396bD4c52ab13b954FebEfD8",
      symbol: "B20",
      name: "B.20",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc4de189abf94c57f396bd4c52ab13b954febefd8/9fb3ce5c5b1c2491d42bbdf0c4492cfa.png",
      coingeckoId: "b20",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc4BB7277A74678f053259CB1F96140347efbfd46",
      symbol: "COC",
      name: "Coin of the champions",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc4bb7277a74678f053259cb1f96140347efbfd46/2f4c66e101cdd1c94781ed1cf898a2a1.png",
      coingeckoId: "coin-of-the-champions",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc40F23A3E9613E012944f7957edCe97899Fa920d",
      symbol: "DHP",
      name: "dHealth",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc40f23a3e9613e012944f7957edce97899fa920d/1eed4ab0c4b26c96de524a1722f09f37.png",
      coingeckoId: "dhealth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc3dD23A0a854b4f9aE80670f528094E9Eb607CCb",
      symbol: "TRND",
      name: "Trendering",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb/fcd877d2103cab8c23c9a71403368fcb.png",
      coingeckoId: "trendering",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xc3D088842DcF02C13699F936BB83DFBBc6f721Ab",
      symbol: "vETH",
      name: "Voucher Ethereum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc3d088842dcf02c13699f936bb83dfbbc6f721ab/0e7dd378e5f5d59b5e004114d2b8445b.png",
      coingeckoId: "voucher-eth",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc36b4311B21Fc0c2eAd46f1eA6ce97c9C4D98D3d",
      symbol: "CRE8",
      name: "Creaticles",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc36b4311b21fc0c2ead46f1ea6ce97c9c4d98d3d/0a628bab2f4774f2f6db4c74680c1ed7.png",
      coingeckoId: "creaticles",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xc27A2F05fa577a83BA0fDb4c38443c0718356501",
      symbol: "TAU",
      name: "Lamden Tau",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xc27a2f05fa577a83ba0fdb4c38443c0718356501/f733b45e23dc475afc290c784d5cc10b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xc221b7E65FfC80DE234bbB6667aBDd46593D34F0",
      symbol: "wCFG",
      name: "Wrapped Centrifuge",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc221b7e65ffc80de234bbb6667abdd46593d34f0/2bf095c348d946d0e4ea32e950f73942.png",
      coingeckoId: "wrapped-centrifuge",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc20464e0C373486d2B3335576e83a218b1618A5E",
      symbol: "DTRC",
      name: "Datarius Credit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xc20464e0c373486d2b3335576e83a218b1618a5e.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xc1D204d77861dEf49b6E769347a883B15EC397Ff",
      symbol: "PAX",
      name: "PayperEx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc1d204d77861def49b6e769347a883b15ec397ff/afc57c6dc757cf6714ceda0f18395f8b.png",
      coingeckoId: null,
      listedIn: ["uniswap", "lifinance"],
    },
    {
      address: "0xc17fbe1d709dDf6C0B6665Dd0591046815AC7554",
      symbol: "POL",
      name: "Polars",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc17fbe1d709ddf6c0b6665dd0591046815ac7554/66cacf5eb9e7b714950f250e9b897e17.png",
      coingeckoId: "polars",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc12eCeE46ed65D970EE5C899FCC7AE133AfF9b03",
      symbol: "TRY",
      name: "TRYfinance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/try.jpg",
      coingeckoId: "try-finance",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xc12d099be31567add4e4e4d0D45691C3F58f5663",
      symbol: "AUC",
      name: "Auctus Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc12d099be31567add4e4e4d0d45691c3f58f5663/bfea4fa97d7dc06771bee4a91cf633f8.png",
      coingeckoId: "auctus",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xc0a4Df35568F116C370E6a6A6022Ceb908eedDaC",
      symbol: "UMEE",
      name: "UMEE",
      decimals: 6,
      chainId: "1",
      logoURI: null,
      coingeckoId: "umee",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc00e94Cb662C3520282E6f5717214004A7f26888",
      symbol: "COMP",
      name: "Compound",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png",
      coingeckoId: "compound-governance-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xbf2179859fc6D5BEE9Bf9158632Dc51678a4100e",
      symbol: "ELF",
      name: "Aelf",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbf2179859fc6d5bee9bf9158632dc51678a4100e/e05bfdc568335ce133617eb511972679.png",
      coingeckoId: "aelf",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xbdEB4b83251Fb146687fa19D1C660F99411eefe3",
      symbol: "SVD",
      name: "Savedroid",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xbdeb4b83251fb146687fa19d1c660f99411eefe3.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xbc4171f45EF0EF66E76F979dF021a34B46DCc81d",
      symbol: "DORA",
      name: "Dorayaki",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc4171f45ef0ef66e76f979df021a34b46dcc81d/9f874f93c988cee237423359edd651e8.png",
      coingeckoId: "dora-factory",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xbc30049ADC73dE06D7a98A5189203aAC66B2c830",
      symbol: "GMX",
      name: "GMX",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xbc194e6f748a222754C3E8b9946922c09E7d4e91",
      symbol: "LEV",
      name: "LEV",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc194e6f748a222754c3e8b9946922c09e7d4e91/a651de268d4cf3d727d93d19809c5c29.png",
      coingeckoId: "lever-network",
      listedIn: ["coingecko", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xbc138bD20C98186CC0342C8e380953aF0cb48BA8",
      symbol: "CNDL",
      name: "Candle",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc138bd20c98186cc0342c8e380953af0cb48ba8/e9d1773a4453ca6646909bd2a3c56517.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xbb1EE07d6c7BAeB702949904080eb61f5D5e7732",
      symbol: "DINU",
      name: "Dogey-Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbb1ee07d6c7baeb702949904080eb61f5d5e7732/381aa13da96bfdf3e631d4d2f7b898f8.png",
      coingeckoId: "dogey-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xba5BDe662c17e2aDFF1075610382B9B691296350",
      symbol: "RARE",
      name: "SuperRare",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xba5bde662c17e2adff1075610382b9b691296350/0aaf263f3cf30f1fc47f4079967e30db.png",
      coingeckoId: "superrare",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0xba3D9687Cf50fE253cd2e1cFeEdE1d6787344Ed5",
      symbol: "aAAVE",
      name: "Aave Interest bearing Aave Token",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aAAVE.svg",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xba100000625a3754423978a60c9317c58a424e3D",
      symbol: "BAL",
      name: "Balancer",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
      coingeckoId: "balancer",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xbEa98c05eEAe2f3bC8c3565Db7551Eb738c8CCAb",
      symbol: "GYSR",
      name: "Geyser",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab/a76a603fef182352b885e44edc4effb5.png",
      coingeckoId: "geyser",
      listedIn: ["coingecko", "1inch", "optimism", "lifinance"],
    },
    {
      address: "0xbE9375C6a420D2eEB258962efB95551A5b722803",
      symbol: "STMX",
      name: "StormX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbe9375c6a420d2eeb258962efb95551a5b722803/b61edcafcee2475fb0e3e72f8341e0ce.png",
      coingeckoId: "storm",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xbDbf245c690d54b67C6e610A28486A2C6dE08bE6",
      symbol: "Sunder",
      name: "Sunder Goverance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xbDbf245c690d54b67C6e610A28486A2C6dE08bE6/logo.png",
      coingeckoId: "sunder-goverance-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xbD0a4bf098261673d5E6e600Fd87ddcd756e6764",
      symbol: "HINA",
      name: "Hina Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbd0a4bf098261673d5e6e600fd87ddcd756e6764/8e42e69d7b277b1e4fcb8596a62e13cd.png",
      coingeckoId: "hina-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xbC396689893D065F41bc2C6EcbeE5e0085233447",
      symbol: "PERP",
      name: "Perpetual Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc396689893d065f41bc2c6ecbee5e0085233447/ccb9a7014a682d32c7fe19ce6c66070d.png",
      coingeckoId: "perpetual-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xbA8A621b4a54e61C442F5Ec623687e2a942225ef",
      symbol: "QUARTZ",
      name: "Sandclock",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xba8a621b4a54e61c442f5ec623687e2a942225ef/a3347f28aa1309da95e17edc5af1e7a8.png",
      coingeckoId: "sandclock",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xb9EF770B6A5e12E45983C5D80545258aA38F3B78",
      symbol: "ZCN",
      name: "0chain",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb9ef770b6a5e12e45983c5d80545258aa38f3b78/1a42a71df573e8208d70e9a76e18822c.png",
      coingeckoId: "0chain",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xb986F3a2d91d3704Dc974A24FB735dCc5E3C1E70",
      symbol: "EUX",
      name: "dForce EUR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xb986f3a2d91d3704dc974a24fb735dcc5e3c1e70/b5db640b44846812951922e2f8e24b2c.png",
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xb78B3320493a4EFaa1028130C5Ba26f0B6085Ef8",
      symbol: "DRC_2",
      name: "Dracula Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb78b3320493a4efaa1028130c5ba26f0b6085ef8/73453850abb291276bd0eddbf3ef60ff.png",
      coingeckoId: "dracula",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xb753428af26E81097e7fD17f40c88aaA3E04902c",
      symbol: "SFI",
      name: "Spice",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xb753428af26E81097e7fD17f40c88aaA3E04902c/logo.png",
      coingeckoId: "saffron-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xb6c4267C4877BB0D6b1685Cfd85b0FBe82F105ec",
      symbol: "REL",
      name: "Relevant",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec/be76826a18a57d150bff34e7a7a1c497.png",
      coingeckoId: "relevant",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xb683D83a532e2Cb7DFa5275eED3698436371cc9f",
      symbol: "BTU",
      name: "BTU Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb683d83a532e2cb7dfa5275eed3698436371cc9f/ae627a29800916d51d2c03277337ba63.png",
      coingeckoId: "btu-protocol",
      listedIn: ["coingecko", "openocean", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xb60Fde5D798236fBF1e2697B2A0645380921FccF",
      symbol: "STONK",
      name: "STONK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xb60fde5d798236fbf1e2697b2a0645380921fccf.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xb5A73f5Fc8BbdbcE59bfD01CA8d35062e0dad801",
      symbol: "PERL",
      name: "Perlin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xb5a73f5fc8bbdbce59bfd01ca8d35062e0dad801.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723",
      symbol: "LIT",
      name: "Litentry",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb59490ab09a0f526cc7305822ac65f2ab12f9723/785a3800736002fdd13f6d7ec32d7656.png",
      coingeckoId: "litentry",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xb5588C411ba0bb7D38865fdC51D082d004e519F7",
      symbol: "WAIT",
      name: "Wait",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb5588c411ba0bb7d38865fdc51d082d004e519f7/995e12d61401b69add26680a7f151109.png",
      coingeckoId: "wait",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb4bebD34f6DaaFd808f73De0d10235a92Fbb6c3D",
      symbol: "YETI",
      name: "Yearn Ecosystem Token Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d/ecc2945344dcc9e66a3c6efd280e8939.png",
      coingeckoId: "yearn-ecosystem-token-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xb3ED706B564BBa9CAB64042f4e1b391bE7BEbCe5",
      symbol: "AGF",
      name: "Augmented Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb3ed706b564bba9cab64042f4e1b391be7bebce5/9c8ac6d062760cf1fc58cd6972e97f34.png",
      coingeckoId: "augmented-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb31eF9e52d94D4120eb44Fe1ddfDe5B4654A6515",
      symbol: "DOSE",
      name: "DOSE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb31ef9e52d94d4120eb44fe1ddfde5b4654a6515/cd7876f3c54614779cc003c16ef511c3.png",
      coingeckoId: "dose-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb29663Aa4E2e81e425294193616c1B102B70a158",
      symbol: "LDN",
      name: "Ludena Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb29663Aa4E2e81e425294193616c1B102B70a158/logo.png",
      coingeckoId: "ludena-protocol",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xb20043F149817bff5322F1b928e89aBFC65A9925",
      symbol: "EXRT",
      name: "EXRT",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb20043f149817bff5322f1b928e89abfc65a9925/4f5766c6d717f3767b68ccbdaf9ff370.png",
      coingeckoId: "exrt-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xb1f871Ae9462F1b2C6826E88A7827e76f86751d4",
      symbol: "GNYerc20",
      name: "GNYerc20",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1f871ae9462f1b2c6826e88a7827e76f86751d4/dfd6f855077f76039a0360fae4e65cae.png",
      coingeckoId: "gny",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xb12494C8824fc069757F47d177E666c571Cd49aE",
      symbol: "IMPACTXP",
      name: "ImpactXP",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb12494c8824fc069757f47d177e666c571cd49ae/e633c335bfcb06424926de20bb169f06.png",
      coingeckoId: "impactxp",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb092b4601850E23903A42EaCBc9D8A0EeC26A4d5",
      symbol: "crFRAX",
      name: "Cream Frax",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xb092b4601850e23903a42eacbc9d8a0eec26a4d5.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xb056c38f6b7Dc4064367403E26424CD2c60655e1",
      symbol: "CEEK",
      name: "CEEK VR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb056c38f6b7dc4064367403e26424cd2c60655e1/85ee91f0b84a2e4bbebb79146ddb5bd2.png",
      coingeckoId: "ceek",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9",
      symbol: "FLOAT",
      name: "Float FLOAT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9/logo.png",
      coingeckoId: "float-protocol-float",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xb0280743b44bF7db4B6bE482b2Ba7b75E5dA096C",
      symbol: "TNS",
      name: "Transcodium",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb0280743b44bf7db4b6be482b2ba7b75e5da096c/1a51b8ef7dafba155ad5368d4ad2e085.png",
      coingeckoId: "transcodium",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5",
      symbol: "RFuel",
      name: "Rio Fuel Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaf9f549774ecedbd0966c52f250acc548d3f36e5/f3efd9ee5a38e028fcdd48618012905c.png",
      coingeckoId: "rio-defi",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85",
      symbol: "FET",
      name: "Fetch ai",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaea46a60368a7bd060eec7df8cba43b7ef41ad85/cf1daa32221b4666b098ac269f06ff04.png",
      coingeckoId: "fetch-ai",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xaeF4F02E31CdbF007f8D98da4aE365188A0E9eCC",
      symbol: "TFT",
      name: "The Famous Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xaef4f02e31cdbf007f8d98da4ae365188a0e9ecc.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02",
      symbol: "TOON",
      name: "PontoonToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaee433adebe0fbb88daa47ef0c1a513caa52ef02/b3b07c0da2807d2ec85aa6daa86afc82.png",
      coingeckoId: "pontoon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
      symbol: "STETH",
      name: "STETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13442/small/steth_logo.png?1608607546",
      coingeckoId: "staked-ether",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xae78736Cd615f374D3085123A210448E74Fc6393",
      symbol: "rETH",
      name: "Rocket Pool ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xae78736cd615f374d3085123a210448e74fc6393/0a56aa87c04449332f88702b2bd5f45c.png",
      coingeckoId: "rocket-pool-eth",
      listedIn: ["coingecko", "1inch", "optimism", "lifinance"],
    },
    {
      address: "0xadd5E881984783dD432F80381Fb52F45B53f3e70",
      symbol: "VITE",
      name: "Vite",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xadd5E881984783dD432F80381Fb52F45B53f3e70/logo.png",
      coingeckoId: "vite",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xad32A8e6220741182940c5aBF610bDE99E737b2D",
      symbol: "DOUGH",
      name: "PieDAO DOUGH v2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xad32a8e6220741182940c5abf610bde99e737b2d/442f0c56bf2fc38b1497a06186345143.png",
      coingeckoId: "piedao-dough-v2",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xac3211a5025414Af2866FF09c23FC18bc97e79b1",
      symbol: "DOV",
      name: "DOVU",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac3211a5025414af2866ff09c23fc18bc97e79b1/10fd22429749668d6e985af9ced7e036.png",
      coingeckoId: "dovu",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xab167E816E4d76089119900e941BEfdfA37d6b32",
      symbol: "SHINJA",
      name: "Shibnobi",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xab167e816e4d76089119900e941befdfa37d6b32/39b420c9d261ce9ef01167cdb96a2468.png",
      coingeckoId: "shibnobi",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xaac41EC512808d64625576EDdd580e7Ea40ef8B2",
      symbol: "GSWAP",
      name: "gameswap.org",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaac41ec512808d64625576eddd580e7ea40ef8b2/a029150639888e8b9b407d3e6355110c.png",
      coingeckoId: "gameswap-org",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d",
      symbol: "CEL",
      name: "CEL",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/3263/small/CEL_logo.png?1609598753",
      coingeckoId: "celsius-degree-token",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xaa61D5dec73971CD4a026ef2820bB87b4a4Ed8d6",
      symbol: "CRE8R",
      name: "CRE8R DAO ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaa61d5dec73971cd4a026ef2820bb87b4a4ed8d6/1ba78d49ab9d14fcad3562203a0fb8a7.png",
      coingeckoId: "cre8r-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xaF4DcE16Da2877f8c9e00544c93B62Ac40631F16",
      symbol: "MTH",
      name: "Monetha",
      decimals: 5,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaf4dce16da2877f8c9e00544c93b62ac40631f16/266eda491d6472f64a8594e27394e4c9.png",
      coingeckoId: "monetha",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xaE697F994Fc5eBC000F8e22EbFfeE04612f98A0d",
      symbol: "LGCY",
      name: "LGCY Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xae697f994fc5ebc000f8e22ebffee04612f98a0d/dd6b60d2c9f13da18c029e531af6cf21.png",
      coingeckoId: "lgcy-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xaDB2437e6F65682B85F814fBc12FeC0508A7B1D0",
      symbol: "UNCX",
      name: "UniCrypt",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xadb2437e6f65682b85f814fbc12fec0508a7b1d0/8466837ada2fcdf0ab19e0d60bf5d056.png",
      coingeckoId: "unicrypt-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xaD996A45fd2373ed0B10Efa4A8eCB9de445A4302",
      symbol: "SHI",
      name: "Shirtum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xad996a45fd2373ed0b10efa4a8ecb9de445a4302/3f96cd6c8b1f3a9d4a5641be5ba34fe9.png",
      coingeckoId: "shirtum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xaD6A626aE2B43DCb1B39430Ce496d2FA0365BA9C",
      symbol: "DEFI+S",
      name: "PieDAO DEFI Small Cap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c/a82e1c4f48fbc492b40a5b6afa81330e.png",
      coingeckoId: "piedao-defi-small-cap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xaD5Fe5B0B8eC8fF4565204990E4405B2Da117d8e",
      symbol: "TRXC",
      name: "TRONCLASSIC",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xad5fe5b0b8ec8ff4565204990e4405b2da117d8e/7ed648e3fc5b7620ccd2f2d814374336.png",
      coingeckoId: "tronclassic",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef",
      symbol: "aENJ",
      name: "Aave interest bearing ENJ",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aENJ.svg",
      coingeckoId: "aave-enj",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xaC0104Cca91D167873B8601d2e71EB3D4D8c33e0",
      symbol: "CWS",
      name: "Crowns",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac0104cca91d167873b8601d2e71eb3d4d8c33e0/8ad867e1a9a5d72ac64f29c305a3aa82.png",
      coingeckoId: "crowns",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xaB456bDb0A373BBaC6C4A76176E9f159cAcD5752",
      symbol: "SGE",
      name: "SOCIETY OF GALACTIC EXPLORATION",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xab456bdb0a373bbac6c4a76176e9f159cacd5752/logo.png",
      coingeckoId: "society-of-galactic-exploration",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a",
      symbol: "TKN",
      name: "Monolith",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaaaf91d9b90df800df4f55c205fd6989c977e73a/9ecfbb97d6d14c41906c0b88e1c070d1.png",
      coingeckoId: "tokencard",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xaA8330FB2B4D5D07ABFE7A72262752a8505C6B37",
      symbol: "POLC",
      name: "Polka City",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37/04638216849af17023148d7be6b798d8.png",
      coingeckoId: "polka-city",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F",
      symbol: "TRAC",
      name: "OriginTrail",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f/8f53aa0c59ac7308cc6e5cd329d5c4dd.png",
      coingeckoId: "origintrail",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0xaA2C0cf54cB418eB24E7e09053B82C875C68bb88",
      symbol: "SOON",
      name: "RealT SOON Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xaa2c0cf54cb418eb24e7e09053b82c875c68bb88.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xa960d2bA7000d58773E7fa5754DeC3Bb40A069D5",
      symbol: "ODEX",
      name: "One DEX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa960d2ba7000d58773e7fa5754dec3bb40a069d5/1cc867d5ecd14e6a6e49e3b11cf1c6a2.png",
      coingeckoId: "one-dex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa92E7c82B11d10716aB534051B271D2f6aEf7Df5",
      symbol: "ARA",
      name: "Ara Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa92e7c82b11d10716ab534051b271d2f6aef7df5/68a53a350db0b4e19ed4256474645c39.png",
      coingeckoId: "ara-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa8c8CfB141A3bB59FEA1E2ea6B79b5ECBCD7b6ca",
      symbol: "NOIA",
      name: "NOIA Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca/5f143c312acd461cdd311ff260b962ce.png",
      coingeckoId: "noia-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa8B61CfF52564758A204F841E636265bEBC8db9B",
      symbol: "YIELD",
      name: "Yield Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa8b61cff52564758a204f841e636265bebc8db9b/edbd5c4e205cdfa1ee271fac2245abac.png",
      coingeckoId: "yield-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa7DE087329BFcda5639247F96140f9DAbe3DeED1",
      symbol: "STA",
      name: "Statera",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa7de087329bfcda5639247f96140f9dabe3deed1/6b6afc2590ba462938f81cb945270bd7.png",
      coingeckoId: "statera",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xa704fCe7b309Ec09DF16e2F5Ab8cAf6Fe8A4BAA9",
      symbol: "AGRI",
      name: "AgriChain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xa693B19d2931d498c5B318dF961919BB4aee87a5",
      symbol: "UST",
      name: "UST (Wormhole)",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa693b19d2931d498c5b318df961919bb4aee87a5/1030a2b27ba6f383e50705f11632a735.png",
      coingeckoId: "terrausd-wormhole",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "optimism",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xa685a61171bb30d4072B338c80Cb7b2c865c873E",
      symbol: "aMANA",
      name: "Aave interest bearing MANA",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aMANA.svg",
      coingeckoId: "aave-mana",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa665FED1b0C9dA00e91ca582f77dF36E325048c5",
      symbol: "YFM",
      name: "yfarm.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xa665fed1b0c9da00e91ca582f77df36e325048c5.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xa5f2211B9b8170F694421f2046281775E8468044",
      symbol: "THOR",
      name: "THORSwap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa5f2211b9b8170f694421f2046281775e8468044/98f509f9df50b924dd7512497f92e083.png",
      coingeckoId: "thorswap",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xa5DEf515cFd373D17830E7c1de1639cB3530a112",
      symbol: "DEPO",
      name: "DePo Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa5def515cfd373d17830e7c1de1639cb3530a112/c60bdca1796e9787849aac348584b42c.png",
      coingeckoId: "depo",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xa58a4f5c4Bb043d2CC1E170613B74e767c94189B",
      symbol: "UTU",
      name: "UTU Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa58a4f5c4bb043d2cc1e170613b74e767c94189b/07f6e06ea327a814b82f14dd10d8e9c6.png",
      coingeckoId: "utu-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa52bfFAD02B1FE3f86A543a4e81962d3B3bB01A7",
      symbol: "DUCKER",
      name: "Duckereum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7/e6c9a042471231f3ddef7b54779caf03.png",
      coingeckoId: "duckereum",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa4eF4b0B23C1fc81d3f9ecF93510e64f58A4A016",
      symbol: "1MIL",
      name: "1MILNFT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016/110a72d68ecb94a424684804da96dd84.png",
      coingeckoId: "1million-nfts",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa4Cf2aFD3B165975afFFBf7e487CDd40C894Ab6B",
      symbol: "SHIBAKEN",
      name: "ShibaKen.Finance",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b/7f7bcd9ab994c1dbe9192ddfdebb2cf3.png",
      coingeckoId: "shibaken-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa47c8bf37f92aBed4A126BDA807A7b7498661acD",
      symbol: "UST",
      name: "Wrapped UST Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa47c8bf37f92abed4a126bda807a7b7498661acd/57ae3a082518049e3dcf26e142c0d699.png",
      coingeckoId: "wrapped-ust",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xa462d0E6Bb788c7807B1B1C96992CE1f7069E195",
      symbol: "EQMT",
      name: "EQUUSMiningToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xa462d0e6bb788c7807b1b1c96992ce1f7069e195.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xa42F266684ac2aD6ecb00df95b1c76EFbb6f136c",
      symbol: "CATE",
      name: "Cash Tech",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c/0e02dd36e05a7cb53c8ab9a0c1c1246a.png",
      coingeckoId: "cash-tech",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa3d58c4E56fedCae3a7c43A725aeE9A71F0ece4e",
      symbol: "MET",
      name: "Metronome",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e/af04ca9723867c9ac7eba628fa8439ae.png",
      coingeckoId: "metronome",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xa3C22370de5f9544f0c4De126b1e46cEadF0A51B",
      symbol: "WSTRAX",
      name: "WrappedStrax",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa3c22370de5f9544f0c4de126b1e46ceadf0a51b/acbbcbec32e5d479833c7ec9c82cbbe2.png",
      coingeckoId: "stratis",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2",
      symbol: "MTA",
      name: "Meta",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2/17557a59ad21559db3e23e16ec1a16bd.png",
      coingeckoId: "meta",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xa393473d64d2F9F026B60b6Df7859A689715d092",
      symbol: "LTX",
      name: "Lattice Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa393473d64d2f9f026b60b6df7859a689715d092/5d3c1c3b811b8161770cdcb3fe995cc4.png",
      coingeckoId: "lattice-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa350DA05405cc204e551C4eeD19C3039646528d5",
      symbol: "BSPT",
      name: "Blocksport Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa350da05405cc204e551c4eed19c3039646528d5/6e168c0d22bbb61ba0ab2f0881b2f212.png",
      coingeckoId: "blocksport",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa2B0fDe6D710e201d0d608e924A484d1A5fEd57c",
      symbol: "sXRP",
      name: "Synth sXRP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa2b0fde6d710e201d0d608e924a484d1a5fed57c/165b52f746d2340b3eda099ddf465e47.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xa283aA7CfBB27EF0cfBcb2493dD9F4330E0fd304",
      symbol: "MM",
      name: "MMToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xa283aA7CfBB27EF0cfBcb2493dD9F4330E0fd304/logo.png",
      coingeckoId: "mm-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
      symbol: "ALPHA",
      name: "AlphaToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa1faa113cbe53436df28ff0aee54275c13b40975/9dc667552b819bc58dea419c8cb4765e.png",
      coingeckoId: "alpha-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xa1d6Df714F91DeBF4e0802A542E13067f31b8262",
      symbol: "RFOX",
      name: "RFOX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa1d6df714f91debf4e0802a542e13067f31b8262/f84e3b609ed61a736206c57763806cc0.png",
      coingeckoId: "redfox-labs-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa1d65E8fB6e87b60FECCBc582F7f97804B725521",
      symbol: "DXD",
      name: "DXdao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa1d65e8fb6e87b60feccbc582f7f97804b725521/9188591f4bddd7c0dc28bd901edf852e.png",
      coingeckoId: "dxdao",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83",
      symbol: "YFII",
      name: "DFI money",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83/0fad5e9166f89f9ae7701b6aa09ea9a0.png",
      coingeckoId: "yfii-finance",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0xa1817B6d8D890F3943b61648992730373B71f156",
      symbol: "MONGOOSE",
      name: "Mongoose",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xa1817b6d8d890f3943b61648992730373b71f156/logo.png",
      coingeckoId: "mongoose",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xa150Db9b1Fa65b44799d4dD949D922c0a33Ee606",
      symbol: "DRC",
      name: "Digital Reserve Currency",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa150db9b1fa65b44799d4dd949d922c0a33ee606/f4b6e670a60515f2b1619274d7ffb62b.png",
      coingeckoId: "digital-reserve-currency",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa124ff1e97e7f3e4a796f6a2d3Bf5d0e2D41973D",
      symbol: "kUSDC",
      name: "KAKI USDC",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xa117000000f279D81A1D3cc75430fAA017FA5A2e",
      symbol: "ANT",
      name: "Aragon Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa117000000f279d81a1d3cc75430faa017fa5a2e/39163cbdb3f8cf4c24180c99ed1bfb95.png",
      coingeckoId: "aragon",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xa0bEd124a09ac2Bd941b10349d8d224fe3c955eb",
      symbol: "DEPAY",
      name: "DePay",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa0bed124a09ac2bd941b10349d8d224fe3c955eb/6e6f36f01654b8625bd45648e231a3fc.png",
      coingeckoId: "depay",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0",
      symbol: "aLINK",
      name: "Aave interest bearing LINK",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aLINK.svg",
      coingeckoId: "aave-link",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xa0246c9032bC3A600820415aE600c6388619A14D",
      symbol: "FARM",
      name: "FARM Reward Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa0246c9032bc3a600820415ae600c6388619a14d/7a228260176f03872f0d1b554283a513.png",
      coingeckoId: "harvest-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0xa02120696c7B8fE16C09C749E4598819b2B0E915",
      symbol: "WXT",
      name: "Wirex Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa02120696c7b8fe16c09c749e4598819b2b0e915/bff444f9bddd83ba69a4565e05529924.png",
      coingeckoId: "wirex",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFeea0bDd3D07eb6FE305938878C0caDBFa169042",
      symbol: "8PAY",
      name: "8PAY Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042/cad33d92fa5197b4dc404b7594f7693a.png",
      coingeckoId: "8pay",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xFe4BEb9217cdDf2422d4bd65449b76d807b30fe1",
      symbol: "WHITE",
      name: "White Ethereum",
      decimals: 9,
      chainId: "1",
      logoURI: null,
      coingeckoId: "white-ethereum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xFe459828c90c0BA4bC8b42F5C5D44F316700B430",
      symbol: "BBS",
      name: "BBS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfe459828c90c0ba4bc8b42f5c5d44f316700b430/976012cc2c1bf83393835dc91babc703.png",
      coingeckoId: "bbs-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFe2e637202056d30016725477c5da089Ab0A043A",
      symbol: "SETH2",
      name: "SETH2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/16569/small/emerald256.png?1624494960",
      coingeckoId: "seth2",
      listedIn: ["coingecko", "openocean", "lifinance", "xyfinance"],
    },
    {
      address: "0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF",
      symbol: "RARI",
      name: "Rarible",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfca59cd816ab1ead66534d82bc21e7515ce441cf/d08e292a0cb79f29c7f084bf0978f6a0.png",
      coingeckoId: "rarible",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0xFc979087305A826c2B2a0056cFAbA50aad3E6439",
      symbol: "DAFI",
      name: "DAFI Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc979087305a826c2b2a0056cfaba50aad3e6439/daa22d65d50156087ba8cbee56e4f685.png",
      coingeckoId: "dafi-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFc2C4D8f95002C14eD0a7aA65102Cac9e5953b5E",
      symbol: "RBLX",
      name: "Rublix",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc2c4d8f95002c14ed0a7aa65102cac9e5953b5e/8217b9eb3097de057c918ab36557b535.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A",
      symbol: "NTVRK",
      name: "NETVRK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a/16c4b0de925c89ae69113fc506a5b6d0.png",
      coingeckoId: "netvrk",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xFbbE9b1142C699512545f47937Ee6fae0e4B0aA9",
      symbol: "EDDA",
      name: "EDDA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9/73a78f6d7e094765874a57a915b9700a.png",
      coingeckoId: "eddaswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20",
      symbol: "DDIM",
      name: "DuckDaoDime",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20/bcf7b6ca41bdda4568d6eda7d9b613d2.png",
      coingeckoId: "duckdaodime",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xFb782396c9b20E564A64896181c7AC8d8979d5F4",
      symbol: "DIVER",
      name: "DivergenceProtocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfb782396c9b20e564a64896181c7ac8d8979d5f4/1b4e250c9ec105b9ed165c17bb6c40dd.png",
      coingeckoId: "divergence-protocol",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xFab46E002BbF0b4509813474841E0716E6730136",
      symbol: "FAU",
      name: "FaucetToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xfab46e002bbf0b4509813474841e0716e6730136.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xFa14Fa6958401314851A17d6C5360cA29f74B57B",
      symbol: "SAITO",
      name: "SAITO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfa14fa6958401314851a17d6c5360ca29f74b57b/1e8e5db9aae8dde8de9f83c9ce58eb4c.png",
      coingeckoId: "saito",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B",
      symbol: "aAAVE",
      name: "Aave interest bearing AAVE",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aAAVE.svg",
      coingeckoId: "aave-aave",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFF75CEd57419bcaEBe5F05254983b013B0646eF5",
      symbol: "COOK",
      name: "Cook Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xff75ced57419bcaebe5f05254983b013b0646ef5/17451b8f4241c2e00ec50f9051d86c92.png",
      coingeckoId: "cook",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xFE3E6a25e6b192A42a44ecDDCd13796471735ACf",
      symbol: "REEF",
      name: "Reef Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfe3e6a25e6b192a42a44ecddcd13796471735acf/604f0f37106ec3789cc0873cb4f5ccd8.png",
      coingeckoId: "reef",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xFD957F21bd95E723645C07C48a2d8ACB8Ffb3794",
      symbol: "ETHM",
      name: "Ethereum Meta",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xfd957f21bd95e723645c07c48a2d8acb8ffb3794/5d0aa2350554a7b0dbf3e2cb3b5931bd.png",
      coingeckoId: "ethereum-meta",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xFC4B8ED459e00e5400be803A9BB3954234FD50e3",
      symbol: "aWBTC",
      name: "Aave Interest bearing WBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xfc4b8ed459e00e5400be803a9bb3954234fd50e3/ee9ac82aac33064e80ba0dafdd08a9e4.png",
      coingeckoId: "aave-wbtc-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xFB1e5F5e984C28Ad7E228CDaA1F8A0919BB6a09B",
      symbol: "GES",
      name: "Galaxy eSolutions",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xfb1e5f5e984c28ad7e228cdaa1f8a0919bb6a09b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xF9C2B386FF5Df088AC717ab0010587bad3bC1ab1",
      symbol: "iUSDS",
      name: "Inflation Adjusted Spice USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf9c2b386ff5df088ac717ab0010587bad3bc1ab1/89c3457b0b3d7bd755d5ea338d2170e3.png",
      coingeckoId: "inflation-adjusted-usds",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF970b8E36e23F7fC3FD752EeA86f8Be8D83375A6",
      symbol: "RCN",
      name: "Ripio",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6/a5f4f7d1830066bc07397bb9cbcb5ad9.png",
      coingeckoId: "ripio-credit-network",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xF94b5C5651c888d928439aB6514B93944eEE6F48",
      symbol: "YLD_APP",
      name: "Yield",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf94b5c5651c888d928439ab6514b93944eee6f48/1fc8cd24305c1a0f3e56f7981688cbe3.png",
      coingeckoId: "yield-app",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xF938424F7210f31dF2Aee3011291b658f872e91e",
      symbol: "VISR",
      name: "VISOR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf938424f7210f31df2aee3011291b658f872e91e/72dce53e16bfd1ac89838eb73af9a179.png",
      coingeckoId: "visor",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xF921ae2DAC5fa128DC0F6168Bf153ea0943d2D43",
      symbol: "FIRE",
      name: "Fire Protocol",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43/0b29c9e6dbab86c72e30dd8f39345841.png",
      coingeckoId: "fire-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF6B55acBBC49f4524Aa48D19281A9A77c54DE10f",
      symbol: "WLK",
      name: "Wolk",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xf6b55acbbc49f4524aa48d19281a9a77c54de10f.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xF6650117017FFD48B725B4EC5A00B414097108A7",
      symbol: "XIDO",
      name: "XIDOFINANCE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf6650117017ffd48b725b4ec5a00b414097108a7/8044a5ba08e4a164c1a44462eb9207ac.png",
      coingeckoId: "xido-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
      symbol: "ENJ",
      name: "Enjin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c/logo.png",
      coingeckoId: "enjincoin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xF5cFBC74057C610c8EF151A439252680AC68c6DC",
      symbol: "OCT",
      name: "Octopus Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf5cfbc74057c610c8ef151a439252680ac68c6dc/90b1013f50a36e2de0900a4c1b81b07c.png",
      coingeckoId: "octopus-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC",
      symbol: "cSAI",
      name: "Compound Sai",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xf5dce57282a584d2746faf1593d3121fcac444dc/8493212296ec80b74e62cef24cabfd16.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF",
      symbol: "IMX",
      name: "IMX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17233/small/imx.png?1636691817",
      coingeckoId: "immutable-x",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xF5717f5DF41eA67Ef67DFD3c1d02F9940bcF5d08",
      symbol: "SNN",
      name: "SeChain",
      decimals: 3,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf5717f5df41ea67ef67dfd3c1d02f9940bcf5d08/032979c4c0b55a73f07d37c8e4fea21c.png",
      coingeckoId: "sechain",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF5581dFeFD8Fb0e4aeC526bE659CFaB1f8c781dA",
      symbol: "HOPR",
      name: "HOPR Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf5581dfefd8fb0e4aec526be659cfab1f8c781da/875f1e3fee066eacf48612e4a419aec3.png",
      coingeckoId: "hopr",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF5238462E7235c7B62811567E63Dd17d12C2EAA0",
      symbol: "CGT",
      name: "CACHE Gold",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf5238462e7235c7b62811567e63dd17d12c2eaa0/dd3a2bdf3a3d93474f2a0cf812cbf4ff.png",
      coingeckoId: "cache-gold",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF4d861575ecC9493420A3f5a14F85B13f0b50EB3",
      symbol: "FCL",
      name: "Fractal Protocol Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3/1935928f09c190eeaee361063f8b2659.png",
      coingeckoId: "fractal",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF4b5470523cCD314C6B9dA041076e7D79E0Df267",
      symbol: "BBANK",
      name: "BlockBank",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf4b5470523ccd314c6b9da041076e7d79e0df267/6bdd40d34e44292db8d84dc6f66b4b1e.png",
      coingeckoId: "blockbank",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xF433089366899D83a9f26A773D59ec7eCF30355e",
      symbol: "MTL",
      name: "Metal",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf433089366899d83a9f26a773d59ec7ecf30355e/aee3d5bfc710c6118bbf18e62187b69f.png",
      coingeckoId: "metal",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xF411903cbC70a74d22900a5DE66A2dda66507255",
      symbol: "VRA",
      name: "VERA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf411903cbc70a74d22900a5de66a2dda66507255/63d4c97f2609330ebe212fd534e613d3.png",
      coingeckoId: "verasity",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xF406F7A9046793267bc276908778B29563323996",
      symbol: "VISION",
      name: "Vision Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf406f7a9046793267bc276908778b29563323996/63e4e8a168ef975c40aa844ca83b9724.png",
      coingeckoId: "apy-vision",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF32aa187d5Bc16A2C02A6aFb7Df1459d0D107574",
      symbol: "Inu",
      name: "Hachiko Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf32aa187d5bc16a2c02a6afb7df1459d0d107574/c897b95bc180851767a716bedb2dcac5.png",
      coingeckoId: "hachikoinu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF2f9A7e93f845b3ce154EfbeB64fB9346FCCE509",
      symbol: "POWER",
      name: "UniPower",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509/036270fd5e535235d8fa0cae468fe54a.png",
      coingeckoId: "unipower",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xF29992D7b589A0A6bD2de7Be29a97A6EB73EaF85",
      symbol: "DMST",
      name: "DMScript",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85/6021c2d5b515acb9946706556486c608.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xF1cA9cb74685755965c7458528A36934Df52A3EF",
      symbol: "AVINOC",
      name: "AVINOC Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf1ca9cb74685755965c7458528a36934df52a3ef/8e5b8032b6c08e0c80d4f557e1bca642.png",
      coingeckoId: "avinoc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF17e65822b568B3903685a7c9F496CF7656Cc6C2",
      symbol: "BICO",
      name: "Biconomy Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf17e65822b568b3903685a7c9f496cf7656cc6c2/69060a56ebfc5a4cecd43b64c8910d22.png",
      coingeckoId: "biconomy",
      listedIn: ["coingecko", "uniswap", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xF1290473E210b2108A85237fbCd7b6eb42Cc654F",
      symbol: "HEDG",
      name: "Hedge Trade",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf1290473e210b2108a85237fbcd7b6eb42cc654f/f5d4f3f0d52c9c37e299bf0fcd4209d1.png",
      coingeckoId: "hedgetrade",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xF0d33BeDa4d734C72684b5f9abBEbf715D0a7935",
      symbol: "NTX",
      name: "NuNet Utility Token",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf0d33beda4d734c72684b5f9abbebf715d0a7935/bae6cdf692c16657e772658d8c625855.png",
      coingeckoId: "nunet",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xF0FAC7104aAC544e4a7CE1A55ADF2B5a25c65bD1",
      symbol: "PAMP",
      name: "Pamp Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1/eec778b06ad11a3a277bc596614d4038.png",
      coingeckoId: "pamp-network",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xF0939011a9bb95c3B791f0cb546377Ed2693a574",
      symbol: "ZERO",
      name: "Zero.Exchange Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf0939011a9bb95c3b791f0cb546377ed2693a574/b1adb40970ea758cd87b834714a75897.png",
      coingeckoId: "zero-exchange",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xF063fE1aB7a291c5d06a86e14730b00BF24cB589",
      symbol: "SALE",
      name: "DxSale.Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xf063fe1ab7a291c5d06a86e14730b00bf24cb589/adba4309a0a0cbf58afc0b6dcd18760b.png",
      coingeckoId: "dxsale-network",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xF047d4bE569FB770dB143A6A90Ef203FC1295922",
      symbol: "crTBTC",
      name: "Cream tBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xf047d4be569fb770db143a6a90ef203fc1295922.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xF013406A0B1d544238083DF0B93ad0d2cBE0f65f",
      symbol: "fiUSDC",
      name: "bZx USDC iToken",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xf013406a0b1d544238083df0b93ad0d2cbe0f65f.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xEfc1C73A3D8728Dc4Cf2A18ac5705FE93E5914AC",
      symbol: "METRIC",
      name: "Metric.exchange",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xefc1c73a3d8728dc4cf2a18ac5705fe93e5914ac/241ef8390f1020d3a81ae1fd538d3d0b.png",
      coingeckoId: "metric-exchange",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xEec2bE5c91ae7f8a338e1e5f3b5DE49d07AfdC81",
      symbol: "DPX",
      name: "Dopex Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81/19a73b131b94cc91d3ebe7b27c141387.png",
      coingeckoId: "dopex",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xEeEeeeeEe2aF8D0e1940679860398308e0eF24d6",
      symbol: "ETHV",
      name: "Ethverse Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeeeeeeeee2af8d0e1940679860398308e0ef24d6/fc2a22ff79659c071c6390ff8bb298be.png",
      coingeckoId: "ethverse",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xEe9E5eFF401ee921b138490d00CA8D1F13f67A72",
      symbol: "Afin",
      name: "Asian Fintech",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xee9e5eff401ee921b138490d00ca8d1f13f67a72/2a412ee8cde9b46d594e0c4d5b423a79.png",
      coingeckoId: "afin-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xEe9801669C6138E84bD50dEB500827b776777d28",
      symbol: "O3",
      name: "O3 Swap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xee9801669c6138e84bd50deb500827b776777d28/98e28b4dbf26999a2c8ec90401eff245.png",
      coingeckoId: "o3-swap",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xEd91879919B71bB6905f23af0A68d231EcF87b14",
      symbol: "DMG",
      name: "DMM: Governance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed91879919b71bb6905f23af0a68d231ecf87b14/117200e1cd8ae3c552e72bae0b116ce6.png",
      coingeckoId: "dmm-governance",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xEd40834A13129509A89be39a9bE9C0E96A0DDd71",
      symbol: "WARP",
      name: "Warp Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed40834a13129509a89be39a9be9c0e96a0ddd71/10e4920dc90b97cfad4d2b730b7ecfdc.png",
      coingeckoId: "warp-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xEd1199093b1aBd07a368Dd1C0Cdc77D8517BA2A0",
      symbol: "HEX2T",
      name: "HEX2T",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xed1199093b1abd07a368dd1c0cdc77d8517ba2a0.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xEd04915c23f00A313a544955524EB7DBD823143d",
      symbol: "ACH",
      name: "Alchemy Pay",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed04915c23f00a313a544955524eb7dbd823143d/be74b438e5f87a170363d65deaf6c3ca.png",
      coingeckoId: "alchemy-pay",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0xEd0439EACf4c4965AE4613D77a5C2Efe10e5f183",
      symbol: "SHROOM",
      name: "shroom.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed0439eacf4c4965ae4613d77a5c2efe10e5f183/12a0b46c13b89189e3a7f808edf85174.png",
      coingeckoId: "shroom-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xEa319e87Cf06203DAe107Dd8E5672175e3Ee976c",
      symbol: "SURF",
      name: "SURF.Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xea319e87cf06203dae107dd8e5672175e3ee976c/faf6af081503d29f13a06a087e11e495.png",
      coingeckoId: "surf-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xEEF9f339514298C6A857EfCfC1A762aF84438dEE",
      symbol: "HEZ",
      name: "Hermez Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeef9f339514298c6a857efcfc1a762af84438dee/c492d861ae73635a93364e336705b9ee.png",
      coingeckoId: "hermez-network-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xEE06A81a695750E71a662B51066F2c74CF4478a0",
      symbol: "DG",
      name: "decentral.games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xEE06A81a695750E71a662B51066F2c74CF4478a0/logo.png",
      coingeckoId: "decentral-games-old",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xEDD7c94FD7B4971b916d15067Bc454b9E1bAD980",
      symbol: "ZIPT",
      name: "Zippie",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xedd7c94fd7b4971b916d15067bc454b9e1bad980/2737c2eeecd114934f755f0550878ca5.png",
      coingeckoId: "zippie",
      listedIn: ["coingecko", "openocean", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xEDB171C18cE90B633DB442f2A6F72874093b49Ef",
      symbol: "WAMPL",
      name: "Wrapped Ampleforth",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xedb171c18ce90b633db442f2a6f72874093b49ef/60204bc28b7d18732faa55afcdd29196.png",
      coingeckoId: "wrapped-ampleforth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xED30Dd7E50EdF3581AD970eFC5D9379Ce2614AdB",
      symbol: "ARCX_OLD",
      name: "ARC Governance Token (Old)",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xed30dd7e50edf3581ad970efc5d9379ce2614adb/ee84ad376e6611e91f02f4530d33d751.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xECbF566944250ddE88322581024E611419715f7A",
      symbol: "xBTC",
      name: "xBTC",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xecbf566944250dde88322581024e611419715f7a/633173cd451e1316706995229475c401.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xEC681F28f4561c2a9534799AA38E0d36A83Cf478",
      symbol: "YVS",
      name: "YVS.Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xec681f28f4561c2a9534799aa38e0d36a83cf478/f1a86d00a2f093b5f606265227886c28.png",
      coingeckoId: "yvs-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xEC213F83defB583af3A000B1c0ada660b1902A0F",
      symbol: "PRE",
      name: "Presearch",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xec213f83defb583af3a000b1c0ada660b1902a0f/fad3197af68771c7fb213371e76f6ca3.png",
      coingeckoId: "presearch",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0xEBd9D99A3982d547C5Bb4DB7E3b1F9F14b67Eb83",
      symbol: "ID",
      name: "Everest ID",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83/f23436c0323ba0493e2900d5b5da1e25.png",
      coingeckoId: "everid",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xEB58343b36C7528F23CAAe63a150240241310049",
      symbol: "NBU",
      name: "Nimbus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeb58343b36c7528f23caae63a150240241310049/8747823984e43ed62d839249c2916d6c.png",
      coingeckoId: "nimbus",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
      symbol: "RENBTC",
      name: "RENBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/11370/small/Bitcoin.jpg?1628072791",
      coingeckoId: "renbtc",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xEABB8996eA1662cAd2f7fB715127852cd3262Ae9",
      symbol: "CNFI",
      name: "Connect Financial",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xeabb8996ea1662cad2f7fb715127852cd3262ae9/879d6247fc25a9f9703347635cdfd5c4.png",
      coingeckoId: "connect-financial",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xEA26c4aC16D4a5A106820BC8AEE85fd0b7b2b664",
      symbol: "QKC",
      name: "QuarkChain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664/90ebdf4c3a60b5b11cd48cb9ad966215.png",
      coingeckoId: "quark-chain",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xEA1ea0972fa092dd463f2968F9bB51Cc4c981D71",
      symbol: "MOD",
      name: "Modefi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xea1ea0972fa092dd463f2968f9bb51cc4c981d71/44b44df34234bc3aac8f0bc5e6e69ff1.png",
      coingeckoId: "modefi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE9cB6838902CCF711f16a9EA5A1170F8e9853C02",
      symbol: "STRIP",
      name: "StripCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe9cb6838902ccf711f16a9ea5a1170f8e9853c02/14ad35ab792b2548bd11c8e8a12ef99d.png",
      coingeckoId: "stripcoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE9A95d175a5f4C9369f3b74222402eB1b837693b",
      symbol: "NOW",
      name: "ChangeNOW",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe9a95d175a5f4c9369f3b74222402eb1b837693b/6a720efc9c9a5c2d4ffd92e29ed041cb.png",
      coingeckoId: "changenow",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE95A203B1a91a908F9B9CE46459d101078c2c3cb",
      symbol: "aETHc",
      name: "Ankr Eth2 Reward Bearing Bond",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe95a203b1a91a908f9b9ce46459d101078c2c3cb/943011b2bba8d1d0a9c815caee4baf84.png",
      coingeckoId: "ankreth",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xE94B97b6b43639E238c851A7e693F50033EfD75C",
      symbol: "RNBW",
      name: "Rainbow Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe94b97b6b43639e238c851a7e693f50033efd75c/492a53db63291bf9bee64c31dc518c23.png",
      coingeckoId: "rainbow-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xE80C0cd204D654CEbe8dd64A4857cAb6Be8345a3",
      symbol: "JPEG",
      name: "JPEG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/24025/small/et_43CNi_400x400.jpg?1646100264",
      coingeckoId: "jpeg-d",
      listedIn: ["coingecko", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0xE7f58A92476056627f9FdB92286778aBd83b285F",
      symbol: "DWEB",
      name: "DecentraWeb",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe7f58a92476056627f9fdb92286778abd83b285f/76421f9fc0b9068f6a0b3990b68e3add.png",
      coingeckoId: "decentraweb",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE796d6ca1ceb1b022EcE5296226BF784110031Cd",
      symbol: "BLES",
      name: "Blind Boxes Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe796d6ca1ceb1b022ece5296226bf784110031cd/f542becbd68d364fbf2e443d2336cda9.png",
      coingeckoId: "blind-boxes",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE6C3502997f97F9BDe34CB165fBce191065E068f",
      symbol: "KBTC",
      name: "KBTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xe6c3502997f97f9bde34cb165fbce191065e068f/45c446951b18129d13c3e817f1aa1b18.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xE66b3AA360bB78468c00Bebe163630269DB3324F",
      symbol: "MTO",
      name: "Merchant Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe66b3aa360bb78468c00bebe163630269db3324f/439358137be473854a6c23049d71b1aa.png",
      coingeckoId: "merchant-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE6354ed5bC4b393a5Aad09f21c46E101e692d447",
      symbol: "yUSDTv3",
      name: "iearn USDT v3",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xe6354ed5bc4b393a5aad09f21c46e101e692d447.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xE5a3229CCb22b6484594973A03a3851dCd948756",
      symbol: "RAE",
      name: "Receive Access Ecosystem",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe5a3229ccb22b6484594973a03a3851dcd948756/e09cab476a8fb2d6807eeb3d1c890820.png",
      coingeckoId: "rae-token",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xE5CAeF4Af8780E59Df925470b050Fb23C43CA68C",
      symbol: "FRM",
      name: "Ferrum Network Token",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe5caef4af8780e59df925470b050fb23c43ca68c/f610cb2893844f8b6c22656021e9d134.png",
      coingeckoId: "ferrum-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE5B826Ca2Ca02F09c1725e9bd98d9a8874C30532",
      symbol: "ZEON",
      name: "ZEON",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532/132ae9ab3cb6f2e332d748726ea96ec2.png",
      coingeckoId: "zeon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE4f726Adc8e89C6a6017F01eadA77865dB22dA14",
      symbol: "BCP",
      name: "PieDAO Balance Crypto Pie",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE4f726Adc8e89C6a6017F01eadA77865dB22dA14/logo.png",
      coingeckoId: "piedao-balanced-crypto-pie",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xE4CFE9eAa8Cdb0942A80B7bC68fD8Ab0F6D44903",
      symbol: "XEND",
      name: "XEND",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903/69eded21d7d86c23fcf96c26ad357634.png",
      coingeckoId: "xend-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE4AE84448DB5CFE1DaF1e6fb172b469c161CB85F",
      symbol: "UOP",
      name: "Utopia Genesis Foundation",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE4AE84448DB5CFE1DaF1e6fb172b469c161CB85F/logo.png",
      coingeckoId: "utopia-genesis-foundation",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xE48972fCd82a274411c01834e2f031D4377Fa2c0",
      symbol: "2KEY",
      name: "2key.network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe48972fcd82a274411c01834e2f031d4377fa2c0/f45d0ebabd4e15e4fe5f98d0b0dd97fe.png",
      coingeckoId: "2key",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
      symbol: "ZRX",
      name: "0xProtocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png",
      coingeckoId: "0x",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xE2dc070524A6e305ddB64d8513DC444B6a1ec845",
      symbol: "NEX",
      name: "Nash Exchange Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe2dc070524a6e305ddb64d8513dc444b6a1ec845/c34674f11dc6064e831e0cb4124c9407.png",
      coingeckoId: "neon-exchange",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE276056f924E15BEac26F7b10B6D2805F9c64Bb5",
      symbol: "FIRU",
      name: "Firulais",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe276056f924e15beac26f7b10b6d2805f9c64bb5/b44d2b2a786319350224a03918c8b338.png",
      coingeckoId: "firulais",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE1c7E30C42C24582888C758984f6e382096786bd",
      symbol: "XCUR",
      name: "Curate",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe1c7e30c42c24582888c758984f6e382096786bd/489e12b230be7366909dfece24396758.png",
      coingeckoId: "curate",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE1Fc4455f62a6E89476f1072530C20CF1A0622dA",
      symbol: "PHTR",
      name: "Phuture",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE1Fc4455f62a6E89476f1072530C20CF1A0622dA/logo.png",
      coingeckoId: "phuture",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xE1D7C7a4596B038CEd2A84bF65B8647271C53208",
      symbol: "NFTY",
      name: "NFTY Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe1d7c7a4596b038ced2a84bf65b8647271c53208/66538e9712357ccd12d4c3bdeed37405.png",
      coingeckoId: "nfty-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE1BA0FB44CCb0D11b80F92f4f8Ed94CA3fF51D00",
      symbol: "aBAT",
      name: "Aave Interest bearing BAT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00/15d14b1735ad6158ab5bb67d7490971f.png",
      coingeckoId: "aave-bat-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xE17f017475a709De58E976081eB916081ff4c9d5",
      symbol: "RMPL",
      name: "RMPL",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe17f017475a709de58e976081eb916081ff4c9d5/b8535756cf84be6b66e910ae3d862980.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xE0b7e882C194881C690924cb46154B8241F9145E",
      symbol: "CNX",
      name: "CoFinex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe0b7e882c194881c690924cb46154b8241f9145e/4588027d5f9509b39849ddf8d6fc3bbe.png",
      coingeckoId: "cofinex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE0aD1806Fd3E7edF6FF52Fdb822432e847411033",
      symbol: "ONX",
      name: "OnX.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xe0ad1806fd3e7edf6ff52fdb822432e847411033/9ae0736d58d219fcfca8c1829c93f089.png",
      coingeckoId: "onx-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xE081b71Ed098FBe1108EA48e235b74F122272E68",
      symbol: "GOLD",
      name: "GOLD",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: "gold",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDfDd3459D4F87234751696840092ee20C970Fb07",
      symbol: "MYOBU",
      name: "Myōbu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdfdd3459d4f87234751696840092ee20c970fb07/25a0d7ef4c1f6ff7ebcff8914b59744c.png",
      coingeckoId: "myobu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDf801468a808a32656D2eD2D2d80B72A129739f4",
      symbol: "CUBE",
      name: "Somnium Space CUBEs",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdf801468a808a32656d2ed2d2d80b72a129739f4/f89cd0443dc0d9d9724bf0cf3819504f.png",
      coingeckoId: "somnium-space-cubes",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e",
      symbol: "aZRX",
      name: "Aave interest bearing ZRX",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aZRX.svg",
      coingeckoId: "aave-zrx",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xDf49C9f599A0A9049D97CFF34D0C30E468987389",
      symbol: "SATT",
      name: "Smart Advertising Transaction Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdf49c9f599a0a9049d97cff34d0c30e468987389/16f78da93e47dffc540e6b605c8fe457.png",
      coingeckoId: "satt",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDeeB6091A5ADc78fA0332BEE5A38a8908b6b566e",
      symbol: "TAC",
      name: "Taekwondo Access Credit",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xDecade1c6Bf2cD9fb89aFad73e4a519C867adcF5",
      symbol: "WIS",
      name: "Experty Wisdom Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdecade1c6bf2cd9fb89afad73e4a519c867adcf5/8cd7ad8753bdcd8a2a841a1ceed0d384.png",
      coingeckoId: "experty-wisdom-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDea67845A51E24461D5fED8084E69B426AF3D5Db",
      symbol: "HTRE",
      name: "HodlTree",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xdea67845a51e24461d5fed8084e69b426af3d5db.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xDe5eA375FFBDc8b25a80fe13D631E8Ba0AB4BB02",
      symbol: "GERA",
      name: "Gera",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde5ea375ffbdc8b25a80fe13d631e8ba0ab4bb02/87d1ddbf243544ce7f2554d19ad5df12.png",
      coingeckoId: "gera-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F",
      symbol: "GTC",
      name: "Gitcoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f/18bdb51197eaa8954b3467dcf0a5c5e9.png",
      coingeckoId: "gitcoin",
      listedIn: ["coingecko", "1inch", "uniswap", "optimism", "lifinance"],
    },
    {
      address: "0xDd1Ad9A21Ce722C151A836373baBe42c868cE9a4",
      symbol: "UBI",
      name: "Universal Basic Income",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdd1ad9a21ce722c151a836373babe42c868ce9a4/756e8b82a00e246c26780614027f333f.png",
      coingeckoId: "universal-basic-income",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xDcB01cc464238396E213a6fDd933E36796eAfF9f",
      symbol: "YLD",
      name: "Yield",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdcb01cc464238396e213a6fdd933e36796eaff9f/f9990790433a259b058a2661fc809f1e.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xDc5864eDe28BD4405aa04d93E05A0531797D9D59",
      symbol: "FNT",
      name: "Falcon",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc5864ede28bd4405aa04d93e05a0531797d9d59/372d9a707466e7205409901eccfc2f9e.png",
      coingeckoId: "falcon-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xDc49108ce5C57bc3408c3A5E95F3d864eC386Ed3",
      symbol: "FOXy",
      name: "FOX Yieldy",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xdc49108ce5c57bc3408c3a5e95f3d864ec386ed3.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xDc0804eD9ABA9202E2b976C031d3144BB63a7990",
      symbol: "KISHUTAMA",
      name: "KishuTama",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc0804ed9aba9202e2b976c031d3144bb63a7990/96cbf1278d91b6381720fa6dcf22fdf8.png",
      coingeckoId: "kishutama",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDc0327D50E6C73db2F8117760592C8BBf1CDCF38",
      symbol: "STRNGR",
      name: "Stronger",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc0327d50e6c73db2f8117760592c8bbf1cdcf38/5b93fe8fda3c6fd58eba24c38fdf6c35.png",
      coingeckoId: "stronger",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xDb0f18081b505A7DE20B18ac41856BCB4Ba86A1a",
      symbol: "pWING",
      name: "Poly Ontology Wing Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdb0f18081b505a7de20b18ac41856bcb4ba86a1a/456f8e0797e635197cd3ee1be0e3c294.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xDb05EA0877A2622883941b939f0bb11d1ac7c400",
      symbol: "OPCT",
      name: "Opacity",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdb05ea0877a2622883941b939f0bb11d1ac7c400/87039f689f4cb15148403d4b5e1797be.png",
      coingeckoId: "opacity",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xDFDb7f72c1F195C5951a234e8DB9806EB0635346",
      symbol: "NFD",
      name: "Feisty Doge NFT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdfdb7f72c1f195c5951a234e8db9806eb0635346/d8bc7c2cbf30bb2ef43afcfee634ad83.png",
      coingeckoId: "feisty-doge-nft",
      listedIn: ["coingecko", "sushiswap", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xDF2C7238198Ad8B389666574f2d8bc411A4b7428",
      symbol: "MFT",
      name: "Mainframe Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdf2c7238198ad8b389666574f2d8bc411a4b7428/4a98f6f34248006e43c5b425ac3a6db8.png",
      coingeckoId: "mainframe",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB",
      symbol: "COW",
      name: "CoW Protocol Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab/8a6b21d3e55e30c3fa049cc7152fc736.png",
      coingeckoId: "cow-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xDE621989216A655f0fa604f077203323Fa64788F",
      symbol: "MOZZA",
      name: "MozzaToken",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde621989216a655f0fa604f077203323fa64788f/b5ea3e86f29c9a1d83ebc2aa081941ee.png",
      coingeckoId: "mozza",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44",
      symbol: "DEUS",
      name: "DEUS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xde5ed76e7c05ec5e4572cfc88d1acea165109e44/a64e57f7263e7b56deaced07d2e66443.png",
      coingeckoId: "deus-finance-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xDDdddd4301A082e62E84e43F474f044423921918",
      symbol: "DVF",
      name: "DeversiFi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdddddd4301a082e62e84e43f474f044423921918/a4c024a9573f69b575e6b4ac2a38b836.png",
      coingeckoId: "rhinofi",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xDDF825aA40aba689E040fd9bE863573999314773",
      symbol: "SHITZUINU",
      name: "Shitzu Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xddf825aa40aba689e040fd9be863573999314773/5ab2e816c727bae3f3cce85b2f5cb433.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xDDD6A0ECc3c6F6C102E5eA3d8Af7B801d1a77aC8",
      symbol: "UNIX",
      name: "UniX Gaming",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8/aa052bc70e9d7319d15f3be088b6108f.png",
      coingeckoId: "unix",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xDDB3422497E61e13543BeA06989C0789117555c5",
      symbol: "COTI",
      name: "COTI Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xddb3422497e61e13543bea06989c0789117555c5/dd468d84a7dfb2ec15fe70aa67b2198a.png",
      coingeckoId: "coti",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xDD2a36AE937bc134ea694d77fC7e2E36f5d86dE0",
      symbol: "WELD",
      name: "Weld.Money",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdd2a36ae937bc134ea694d77fc7e2e36f5d86de0/47693ba45e2bf64642662f5ce1650136.png",
      coingeckoId: "weld",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDC349913d53b446485E98b76800b6254f43Df695",
      symbol: "BEZOGE",
      name: "Bezoge Earth",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdc349913d53b446485e98b76800b6254f43df695/7e7ddd566ff2280039acaf1b5edf8600.png",
      coingeckoId: "bezoge-earth",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xDB61354E9cf2217a29770E9811832B360a8DAad3",
      symbol: "LTCBULL",
      name: "3X Long Litecoin Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "3x-long-litecoin-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDB5C3C46E28B53a39C255AA39A411dD64e5fed9c",
      symbol: "NCR",
      name: "Neos Credits",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c/0a9ef1a151cd51d5ae57179553fe999b.png",
      coingeckoId: "neos-credits",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54",
      symbol: "HAPI",
      name: "HAPI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54/9fb1e194fdd7fedb74e788f69fcb90fa.png",
      coingeckoId: "hapi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD9016A907Dc0ECfA3ca425ab20B6b785B42F2373",
      symbol: "GMEE",
      name: "GAMEE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373/061189ca4afb01f352b243daf05ecae2.png",
      coingeckoId: "gamee",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xD8E3FB3b08eBA982F2754988d70D57eDc0055ae6",
      symbol: "ZORA",
      name: "Zoracles",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd8e3fb3b08eba982f2754988d70d57edc0055ae6/a892797fc67ef429e80e32d19e530266.png",
      coingeckoId: "zoracles",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD8C1232FcD219286E341271385bd70601503B3D7",
      symbol: "DOGIRA",
      name: "Dogira",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd8c1232fcd219286e341271385bd70601503b3d7/c469f64b6f176a873a4ce80aedbd9a30.png",
      coingeckoId: "dogira",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD8912C10681D8B21Fd3742244f44658dBA12264E",
      symbol: "PLU",
      name: "Pluton",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd8912c10681d8b21fd3742244f44658dba12264e/fc37b12bc2f274e91007ed41d84c689c.png",
      coingeckoId: "pluton",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0xD85AD783cc94bd04196a13DC042A3054a9B52210",
      symbol: "HAKA",
      name: "TribeOne",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd85ad783cc94bd04196a13dc042a3054a9b52210/3cf5211699f128bfe7c6f4bcf0f984da.png",
      coingeckoId: "tribeone",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD7d6ab522fA41caae78F6edeb46A14954aE0c772",
      symbol: "CHPZ",
      name: "Chipz",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7d6ab522fa41caae78f6edeb46a14954ae0c772/f7dfca3fbe93c022a4a9a4443b3611cf.png",
      coingeckoId: "chipz",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xD7EFB00d12C2c13131FD319336Fdf952525dA2af",
      symbol: "XPR",
      name: "Proton",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7efb00d12c2c13131fd319336fdf952525da2af/84268070565aa2c134df15a953897701.png",
      coingeckoId: "proton",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0xD7B7d3C0bdA57723Fb54ab95Fd8F9EA033AF37f2",
      symbol: "PYLON",
      name: "PYLON",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd7b7d3c0bda57723fb54ab95fd8f9ea033af37f2/1702040bd20a749ac63b6f2f74a5381e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xD6a55C63865AffD67E2FB9f284F87b7a9E5FF3bD",
      symbol: "ESH",
      name: "Switch",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd/8c878b8a67d95acc6c334b087599dffb.png",
      coingeckoId: "switch",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xD6F0Bb2A45110f819e908a915237D652Ac7c5AA8",
      symbol: "BUIDL_2",
      name: "DFOHub",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xd6f0bb2a45110f819e908a915237d652ac7c5aa8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xD692ac3245bb82319A31068D6B8412796eE85d2c",
      symbol: "crHUSD",
      name: "Cream HUSD",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xd692ac3245bb82319a31068d6b8412796ee85d2c.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
      symbol: "WRLD",
      name: "NFT Worlds",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9/7e8c2ceca870a77f8910d33d85163f0d.png",
      coingeckoId: "nft-worlds",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD5930C307d7395Ff807F2921F12C5EB82131a789",
      symbol: "BOLT",
      name: "Bolt Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd5930c307d7395ff807f2921f12c5eb82131a789/bc394545263f10cc4316c7e99bfbf939.png",
      coingeckoId: "bolt",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44",
      symbol: "PAN",
      name: "Panvala pan",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd56dac73a4d6766464b38ec6d91eb45ce7457c44/65aa4deed6504aabd6cc96ddf8a9eece.png",
      coingeckoId: "panvala-pan",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xD5525D397898e5502075Ea5E830d8914f6F0affe",
      symbol: "MEME",
      name: "MEME",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd5525d397898e5502075ea5e830d8914f6f0affe/8287113212c164da580eb326ccab717d.png",
      coingeckoId: "degenerator",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xD533a949740bb3306d119CC777fa900bA034cd52",
      symbol: "CRV",
      name: "CurveDAOToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png",
      coingeckoId: "curve-dao-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xD528cf2E081f72908e086F8800977df826B5a483",
      symbol: "PBX",
      name: "Paribus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd528cf2e081f72908e086f8800977df826b5a483/8d424778cff348ad6dd089658219cef0.png",
      coingeckoId: "paribus",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5",
      symbol: "renFIL",
      name: "renFIL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5/logo.png",
      coingeckoId: "renfil",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xD478161C952357F05f0292B56012Cd8457F1cfbF",
      symbol: "POLK",
      name: "Polkamarkets",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd478161c952357f05f0292b56012cd8457f1cfbf/f319a8c346a8055f48279c9882adf368.png",
      coingeckoId: "polkamarkets",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD46bA6D942050d489DBd938a2C909A5d5039A161",
      symbol: "AMPL",
      name: "Ampleforth",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd46ba6d942050d489dbd938a2c909a5d5039a161/9576ad5088bb9517bae3b081504a9813.png",
      coingeckoId: "ampleforth",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xD417144312DbF50465b1C641d016962017Ef6240",
      symbol: "CQT",
      name: "CQT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14168/small/covalent-cqt.png?1624545218",
      coingeckoId: "covalent",
      listedIn: ["coingecko", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0xD3c625F54dec647DB8780dBBe0E880eF21BA4329",
      symbol: "XHT",
      name: "HollaEx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd3c625f54dec647db8780dbbe0e880ef21ba4329/8d5ceae41b23bdbdea691be1763b9885.png",
      coingeckoId: "hollaex-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD33526068D116cE69F19A9ee46F0bd304F21A51f",
      symbol: "RPL",
      name: "Rocket Pool Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd33526068d116ce69f19a9ee46f0bd304f21a51f/0dac0c5e1dd543fb62581f0756e0b11f.png",
      coingeckoId: "rocket-pool",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD31a59c85aE9D8edEFeC411D448f90841571b89c",
      symbol: "SOL",
      name: "Wrapped SOL (Wormhole)",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd31a59c85ae9d8edefec411d448f90841571b89c/7c5db5c2eae571da837b65f5b9ae1a5c.png",
      coingeckoId: "sol-wormhole",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0xD291E7a03283640FDc51b121aC401383A46cC623",
      symbol: "RGT",
      name: "Rari Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd291e7a03283640fdc51b121ac401383a46cc623/b2d0952d782d2b5b5931ecbbaf8467ea.png",
      coingeckoId: "rari-governance-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xD23Ac27148aF6A2f339BD82D0e3CFF380b5093de",
      symbol: "SI",
      name: "SIREN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd23ac27148af6a2f339bd82d0e3cff380b5093de/b56049eb3073ced04c6c3e6bc7f930ff.png",
      coingeckoId: "siren",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0xD23367155B55d67492DFDC0FC7f8bB1dF7114fD9",
      symbol: "AMPLIFI",
      name: "Amplifi",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "amplifi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD13c7342e1ef687C5ad21b27c2b65D772cAb5C8c",
      symbol: "UOS",
      name: "Ultra Token",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c/4f7f95fffeaf7bc7411c3ca43f545cb0.png",
      coingeckoId: "ultra",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xD0Cd466b34A24fcB2f87676278AF2005Ca8A78c4",
      symbol: "POP",
      name: "Popcorn",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd0cd466b34a24fcb2f87676278af2005ca8a78c4/2e6c8c67f98c0b86811d35fae3525cc4.png",
      coingeckoId: "popcorn",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD0660cD418a64a1d44E9214ad8e459324D8157f1",
      symbol: "WOOFY",
      name: "Woofy",
      decimals: 12,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd0660cd418a64a1d44e9214ad8e459324d8157f1/35e3fc8fc8d696674dd987089901bac2.png",
      coingeckoId: "woofy",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xD06527D5e56A3495252A528C4987003b712860eE",
      symbol: "crETH",
      name: "Cream Ether",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xd06527d5e56a3495252a528c4987003b712860ee.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xD01ef7C0A5d8c432fc2d1a85c66cF2327362E5C6",
      symbol: "aETHb",
      name: "Ankr Reward-Earning Staked ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xd01ef7c0a5d8c432fc2d1a85c66cf2327362e5c6/5acf19e660e9a0c1fdebcf55ab81f1d7.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xCfa0885131F602D11D4DA248D2c65A62063567A9",
      symbol: "TORG",
      name: "TORG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcfa0885131f602d11d4da248d2c65a62063567a9/8fea949677a2342bcfdd4126220739bf.png",
      coingeckoId: "torg",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xCf8f9555D55CE45a3A33a81D6eF99a2a2E71Dee2",
      symbol: "CBIX7",
      name: "CBI Index 7",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xcf8f9555d55ce45a3a33a81d6ef99a2a2e71dee2.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xCdeee767beD58c5325f68500115d4B722b3724EE",
      symbol: "CRBN",
      name: "Carbon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xcdeee767bed58c5325f68500115d4b722b3724ee/logo.png",
      coingeckoId: "carbon",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xCdF7028ceAB81fA0C6971208e83fa7872994beE5",
      symbol: "T",
      name: "Threshold Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcdf7028ceab81fa0c6971208e83fa7872994bee5/415842bd4135e852704aea093ce139d0.png",
      coingeckoId: "threshold-network-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCd2828fc4D8E8a0eDe91bB38CF64B1a81De65Bf6",
      symbol: "ODDZ",
      name: "OddzToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6/95f0fb13572d34e83c6a57986dffec46.png",
      coingeckoId: "oddz",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCc80C051057B774cD75067Dc48f8987C4Eb97A5e",
      symbol: "NEC",
      name: "Ethfinex Nectar Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcc80c051057b774cd75067dc48f8987c4eb97a5e/eeb945bd0335a92c66712ddf65dc5490.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xCbfef8fdd706cde6F208460f2Bf39Aa9c785F05D",
      symbol: "KINE",
      name: "Kine Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d/0e4669a5116cc71400615c1ce6895bcd.png",
      coingeckoId: "kine-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCbaE0A83f4f9926997c8339545fb8eE32eDc6b76",
      symbol: "crYFI",
      name: "Cream YFI",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xcbae0a83f4f9926997c8339545fb8ee32edc6b76.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xCb5f72d37685C3D5aD0bB5F982443BC8FcdF570E",
      symbol: "ROOT",
      name: "RootKit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e/d4e086e824518e5d556314d1bffcd5fb.png",
      coingeckoId: "rootkit",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCae72A7A0Fd9046cf6b165CA54c9e3a3872109E0",
      symbol: "$ANRX",
      name: "AnRKey X",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0/c2f2b0daff01ac4d3aadf2499e685b9f.png",
      coingeckoId: "anrkey-x",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCa3FE04C7Ee111F0bbb02C328c699226aCf9Fd33",
      symbol: "SEEN",
      name: "seen.haus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xca3fe04c7ee111f0bbb02c328c699226acf9fd33/1563f35716762c851cfdf203e610a0bc.png",
      coingeckoId: "seen",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xCFEAead4947f0705A14ec42aC3D44129E1Ef3eD5",
      symbol: "NOTE",
      name: "Notional",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5/ebf0bbcfaf39b581a04aacbc502101a4.png",
      coingeckoId: "notional-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCF3C8Be2e2C42331Da80EF210e9B1b307C03d36A",
      symbol: "BEPRO",
      name: "BetProtocolToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a/fd01cf066dc7cb57dd202b6fbcdf5fd6.png",
      coingeckoId: "bepro-network",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xCE3f08e664693ca792caCE4af1364D5e220827B2",
      symbol: "SAITAMA",
      name: "SAITAMA",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xce3f08e664693ca792cace4af1364d5e220827b2/7a69647caac81a8de361248b64e7c01b.png",
      coingeckoId: "saitama-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25",
      symbol: "SLP",
      name: "Smooth Love Potion",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25/f8b2d4901c130001dcb209e48d9adb8e.png",
      coingeckoId: "smooth-love-potion",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0xCC4304A31d09258b0029eA7FE63d032f52e44EFe",
      symbol: "SWAP",
      name: "TrustSwap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcc4304a31d09258b0029ea7fe63d032f52e44efe/1066a68a74c7307b4f9215d57c151ef5.png",
      coingeckoId: "trustswap",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a",
      symbol: "aREN",
      name: "Aave interest bearing REN",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmUgE3UECZxZcCAiqd3V9otfFWLi5fxR8uHd94RxkT3iYb",
      coingeckoId: "aave-ren",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xCB56b52316041A62B6b5D0583DcE4A8AE7a3C629",
      symbol: "CIG",
      name: "Cigarette Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xCB56b52316041A62B6b5D0583DcE4A8AE7a3C629/logo.png",
      coingeckoId: "cigarette-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xCAEaf8381D4B20b43AFA42061D6f80319A8881F6",
      symbol: "R34P",
      name: "R34P",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xcaeaf8381d4b20b43afa42061d6f80319a8881f6/0533098eada8501ee3b68a9a2f4eadf0.png",
      coingeckoId: "r34p",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xCA0e7269600d353F70b14Ad118A49575455C0f2f",
      symbol: "AMLT",
      name: "AMLT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xca0e7269600d353f70b14ad118a49575455c0f2f/2320f4206d751d3bed7f153469516091.png",
      coingeckoId: "coinfirm-amlt",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC94f3e2aDE5E92389AF7E543657211EcCcAFf11E",
      symbol: "SPIRAL",
      name: "Spiral",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc94f3e2ade5e92389af7e543657211ecccaff11e/4728c45a76af1b224750054ec086355d.png",
      coingeckoId: "up-spiral",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC88F47067dB2E25851317A2FDaE73a22c0777c37",
      symbol: "oneBTC",
      name: "oneBTC",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc88f47067db2e25851317a2fdae73a22c0777c37/377a3fbd024fa4b1f6a347586fc6f7b5.png",
      coingeckoId: "onebtc",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xC86D054809623432210c107af2e3F619DcFbf652",
      symbol: "UPP",
      name: "Sentinel Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc86d054809623432210c107af2e3f619dcfbf652/f5fb5fca15fd118d8faa75a97d856600.png",
      coingeckoId: "sentinel-protocol",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xC86817249634ac209bc73fCa1712bBd75E37407d",
      symbol: "1-UP",
      name: "1-UP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc86817249634ac209bc73fca1712bbd75e37407d/dc93fd1d0e3d3a0e72d36e5ec0f57af6.png",
      coingeckoId: "1-up",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2",
      symbol: "ARC",
      name: "ARC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xc82e3db60a52cf7529253b4ec688f631aad9e7c2/logo.png",
      coingeckoId: "arc",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xC76FB75950536d98FA62ea968E1D6B45ffea2A55",
      symbol: "COL",
      name: "COL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xc76fb75950536d98fa62ea968e1d6b45ffea2a55/9459d9ae6928be421267e74a90f6b610.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xC741f06082AA47F93729070aD0dD95E223Bda091",
      symbol: "LEDU",
      name: "Education",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc741f06082aa47f93729070ad0dd95e223bda091/ced53584fac7e286bc1c5d82d3d1dafd.png",
      coingeckoId: "education-ecosystem",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC669928185DbCE49d2230CC9B0979BE6DC797957",
      symbol: "BTT",
      name: "BitTorrent",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc669928185dbce49d2230cc9b0979be6dc797957/314558e5bee32a7ea563e18c0b958fa6.png",
      coingeckoId: "bittorrent",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC5ec4f1FC86DA45AE788EC98756BA70B57676409",
      symbol: "OJE",
      name: "OJE Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5ec4f1fc86da45ae788ec98756ba70b57676409/930bc6b32956c6e04c4691abdded27a9.png",
      coingeckoId: "oje-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xC5bcc8BA3F33Ab0d64f3473e861BDC0685b19Ef5",
      symbol: "$MECHA",
      name: "Mechanium",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5bcc8ba3f33ab0d64f3473e861bdc0685b19ef5/e6c4b51db877ff2e46ddcfce4f887342.png",
      coingeckoId: "mechachain",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC5bBaE50781Be1669306b9e001EFF57a2957b09d",
      symbol: "GTO",
      name: "Gifto",
      decimals: 5,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc5bbae50781be1669306b9e001eff57a2957b09d/7a63701c76215402c20d3f073bd60ec3.png",
      coingeckoId: "gifto",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xC581b735A1688071A1746c968e0798D642EDE491",
      symbol: "EURT",
      name: "Euro Tether",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc581b735a1688071a1746c968e0798d642ede491/22529ae8d14f8e50aeb856add5c5ef7d.png",
      coingeckoId: "tether-eurt",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xC57d533c50bC22247d49a368880fb49a1caA39F7",
      symbol: "PTF",
      name: "PowerTrade Fuel Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc57d533c50bc22247d49a368880fb49a1caa39f7/c009e24e47147cf583a4717d27f7d5c5.png",
      coingeckoId: "powertrade-fuel",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC53342fd7575f572b0fF4569e31941A5B821aC76",
      symbol: "ETHV",
      name: "ETH Volatility Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xc53342fd7575f572b0ff4569e31941a5b821ac76/02a75a1bda72bef4a73de93031dcbabf.png",
      coingeckoId: "ethereum-volatility-index-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC52C326331E9Ce41F04484d3B5E5648158028804",
      symbol: "ZCX",
      name: "ZEN Exchange Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc52c326331e9ce41f04484d3b5e5648158028804/e5b7ed7ab6da8252da2bfa34c7777d23.png",
      coingeckoId: "unizen",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC4C2614E694cF534D407Ee49F8E44D125E4681c4",
      symbol: "CHAIN",
      name: "Chain Games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc4c2614e694cf534d407ee49f8e44d125e4681c4/8808f7ea54292fa1ac3b3add1e336b2f.png",
      coingeckoId: "chain-games",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xC48b4814fAEd1CCc885DD6fDe62A6474AeCbb19a",
      symbol: "CMERGE",
      name: "Coin Merge",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc48b4814faed1ccc885dd6fde62a6474aecbb19a/b76acb086f1061f959e978826fc59287.png",
      coingeckoId: "coinmerge",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC477D038d5420C6A9e0b031712f61c5120090de9",
      symbol: "BOSON",
      name: "Boson Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc477d038d5420c6a9e0b031712f61c5120090de9/deae3285daa9c1a833e1b21752661a1c.png",
      coingeckoId: "boson-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC40AF1E4fEcFA05Ce6BAb79DcD8B373d2E436c4E",
      symbol: "HOKK",
      name: "Hokkaidu Inu",
      decimals: 9,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xC36824905dfF2eAAEE7EcC09fCC63abc0af5Abc5",
      symbol: "BAB",
      name: "Basis Bond",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xC36824905dfF2eAAEE7EcC09fCC63abc0af5Abc5/logo.png",
      coingeckoId: "basis-bond",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xC2cB1040220768554cf699b0d863A3cd4324ce32",
      symbol: "yDAIv3",
      name: "iearn DAI v3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xc2cb1040220768554cf699b0d863a3cd4324ce32.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xC28e931814725BbEB9e670676FaBBCb694Fe7DF2",
      symbol: "eQUAD",
      name: "QuadrantProtocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc28e931814725bbeb9e670676fabbcb694fe7df2/1187b1acf17f146c5977f2bef7807ceb.png",
      coingeckoId: "quadrant-protocol",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xC28E27870558cF22ADD83540d2126da2e4b464c2",
      symbol: "SASHIMI",
      name: "SashimiToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc28e27870558cf22add83540d2126da2e4b464c2/5d8020b8d01093636dc63e28bbcc6d18.png",
      coingeckoId: "sashimi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC2708A3A4ba7F64bddc1A49F92f941Bc77Cad23a",
      symbol: "EGG",
      name: "Waves Ducks",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc2708a3a4ba7f64bddc1a49f92f941bc77cad23a/36852d0a36c54c3f1aee709024cbfa94.png",
      coingeckoId: "waves-ducks",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC25EAE724f189Ba9030B2556a1533E7c8A732E14",
      symbol: "crSNX",
      name: "Cream Synthetix Network Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xc25eae724f189ba9030b2556a1533e7c8a732e14.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xC229c69eB3BB51828D0cAA3509A05a51083898dd",
      symbol: "PTU",
      name: "Pintu Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc229c69eb3bb51828d0caa3509a05a51083898dd/e5dca2e40bdc225c77f96ea652944c3d.png",
      coingeckoId: "pintu-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC19B6A4Ac7C7Cc24459F08984Bbd09664af17bD1",
      symbol: "SENSO",
      name: "Sensorium",
      decimals: 0,
      chainId: "1",
      logoURI: "https://assets.coingecko.com/coins/images/11064/large/senso.png",
      coingeckoId: "senso",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
      symbol: "ENS",
      name: "ENS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/19785/small/acatxTm8_400x400.jpg?1635850140",
      coingeckoId: "ethereum-name-service",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xC17c30e98541188614dF99239cABD40280810cA3",
      symbol: "RISE",
      name: "EverRise",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc17c30e98541188614df99239cabd40280810ca3/38aa3e0824e40514a68317f4ccbc1adf.png",
      coingeckoId: "everrise",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC146B7CdBaff065090077151d391f4c96Aa09e0C",
      symbol: "MCC",
      name: "MultiChainCapital",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc146b7cdbaff065090077151d391f4c96aa09e0c/a425b6f7bebccbb2d40b4f2f34448629.png",
      coingeckoId: "multi-chain-capital-2",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xC11b1268C1A384e55C48c2391d8d480264A3A7F4",
      symbol: "cWBTC",
      name: "Compound Wrapped BTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc11b1268c1a384e55c48c2391d8d480264a3a7f4/4e6f00c35621ddf2ebdf9ec0249b5651.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xC0d4Ceb216B3BA9C3701B291766fDCbA977ceC3A",
      symbol: "BTRFLY",
      name: "BTRFLY",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc0d4ceb216b3ba9c3701b291766fdcba977cec3a/037388753fc088e354331f3c7194641c.png",
      coingeckoId: "butterflydao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC0bA369c8Db6eB3924965e5c4FD0b4C1B91e305F",
      symbol: "DUCK",
      name: "DLP Duck Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f/baf1228af5f0d5b183e212fafc685bc9.png",
      coingeckoId: "dlp-duck-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9",
      symbol: "DONUT",
      name: "DONUT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9/9ef459ccc719d92019c529a6b4cc34a0.png",
      coingeckoId: "donut",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xC08512927D12348F6620a698105e1BAac6EcD911",
      symbol: "GYEN",
      name: "GYEN",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xc08512927d12348f6620a698105e1baac6ecd911/c85ea1788f6f0d93406f2f94cebbf80a.png",
      coingeckoId: "gyen",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      symbol: "WETH",
      name: "Wrapped ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      coingeckoId: "weth",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xC0134b5B924c2FCA106eFB33C45446c466FBe03e",
      symbol: "ALEPH",
      name: "aleph.im",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xc0134b5b924c2fca106efb33c45446c466fbe03e/ec409ceb7a40801d2425b1a28470a4ec.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
      symbol: "SNX",
      name: "Synthetix",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png",
      coingeckoId: "havven",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xBe428c3867F05deA2A89Fc76a102b544eaC7f772",
      symbol: "CVT",
      name: "CVT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbe428c3867f05dea2a89fc76a102b544eac7f772/148a534df8bb85c16c629727ddd0267f.png",
      coingeckoId: "cybervein",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xBd56E9477Fc6997609Cf45F84795eFbDAC642Ff1",
      symbol: "fiREP",
      name: "bZx REP iToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xbd56e9477fc6997609cf45f84795efbdac642ff1.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xBd3de9a069648c84d27d74d701C9fa3253098B15",
      symbol: "EQX",
      name: "EQIFi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbd3de9a069648c84d27d74d701c9fa3253098b15/54ea6ec0ec5add6308ee764969e17c39.png",
      coingeckoId: "eqifi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xBd356a39BFf2cAda8E9248532DD879147221Cf76",
      symbol: "WOM",
      name: "WOM Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/wom.jpg",
      coingeckoId: "wom-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F",
      symbol: "eMTRG",
      name: "Meter Governance mapped by Meter.io",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f/d21e3de1637f45120824b3880b957a2a.png",
      coingeckoId: "meter-governance-mapped-by-meter-io",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xBcca60bB61934080951369a648Fb03DF4F96263C",
      symbol: "aUSDC",
      name: "Aave interest bearing USDC",
      decimals: 6,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUSDC.svg",
      coingeckoId: "aave-usdc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBc6669E7914a2b327Ae428184086d8Ac88d74EfC",
      symbol: "CCM",
      name: "Car Coin",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "car-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xBbff34E47E559ef680067a6B1c980639EEb64D24",
      symbol: "L2",
      name: "Leverj Gluon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbbff34e47e559ef680067a6b1c980639eeb64d24/df3e99d816dad287a0efb6f158ec32fd.png",
      coingeckoId: "leverj-gluon",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBae5F2D8a1299E5c4963eaff3312399253f27Ccb",
      symbol: "SOAR",
      name: "SOAR.FI",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbae5f2d8a1299e5c4963eaff3312399253f27ccb/891e81cea3178f8b36b16e9c839d744d.png",
      coingeckoId: "soar-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBa21Ef4c9f433Ede00badEFcC2754B8E74bd538A",
      symbol: "SWFL",
      name: "Swapfolio",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xba21ef4c9f433ede00badefcc2754b8e74bd538a/63bb7619ed6728e8def619deba271c91.png",
      coingeckoId: "swapfolio",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBEA0000029AD1c77D3d5D23Ba2D8893dB9d1Efab",
      symbol: "BEAN",
      name: "Bean",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbea0000029ad1c77d3d5d23ba2d8893db9d1efab/79bb9bd54ea26d9616a3c8d634aed08a.png",
      coingeckoId: "bean",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBD6467a31899590474cE1e84F70594c53D628e46",
      symbol: "KAI",
      name: "KardiaChain Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1634698755339_7648597495546063.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xBD2F0Cd039E0BFcf88901C98c0bFAc5ab27566e3",
      symbol: "DSD",
      name: "Dynamic Set Dollar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3/220e0b782cabf102876141d849c90be9.png",
      coingeckoId: "dynamic-set-dollar",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xBC86727E770de68B1060C91f6BB6945c73e10388",
      symbol: "XNK",
      name: "Ink Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc86727e770de68b1060c91f6bb6945c73e10388/1a455d35a0f82f7ce3bd0623c94b12ab.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0xBC7250C8c3eCA1DfC1728620aF835FCa489bFdf3",
      symbol: "GM",
      name: "GM",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc7250c8c3eca1dfc1728620af835fca489bfdf3/829af45150b5858f53ef6c169a9c678a.png",
      coingeckoId: "gm",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9",
      symbol: "alUSD",
      name: "Alchemix USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc6da0fe9ad5f3b0d58160288917aa56653660e9/b156bb5f7b191ff92f2c970ae2194329.png",
      coingeckoId: "alchemix-usd",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA",
      symbol: "BORING",
      name: "BoringDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbc19712feb3a26080ebf6f2f7849b417fdd792ca/93d296a772e841749f860470dfe09378.png",
      coingeckoId: "boringdao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xBBc2AE13b23d715c30720F079fcd9B4a74093505",
      symbol: "ERN",
      name: "Ethernity Chain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbbc2ae13b23d715c30720f079fcd9b4a74093505/b241d682a1a1b15fe4e62fe2b0cb2edd.png",
      coingeckoId: "ethernity-chain",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
      symbol: "LRC",
      name: "Loopring",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png",
      coingeckoId: "loopring",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xBBa39Fd2935d5769116ce38d46a71bde9cf03099",
      symbol: "CHO",
      name: "choise.com Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xbba39fd2935d5769116ce38d46a71bde9cf03099/logo.png",
      coingeckoId: "choise",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xBB1fA4FdEB3459733bF67EbC6f893003fA976a82",
      symbol: "XPAT",
      name: "Bitnation",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xbb1fa4fdeb3459733bf67ebc6f893003fa976a82.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b",
      symbol: "AXS",
      name: "AXS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13029/small/axie_infinity_logo.png?1604471082",
      coingeckoId: "axie-infinity",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xBAac2B4491727D78D2b78815144570b9f2Fe8899",
      symbol: "DOG",
      name: "The Doge NFT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xbaac2b4491727d78d2b78815144570b9f2fe8899/15b6ff9dcb0f15586af7c4a2698e94ca.png",
      coingeckoId: "the-doge-nft",
      listedIn: ["coingecko", "sushiswap", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xBA9262578EFef8b3aFf7F60Cd629d6CC8859C8b5",
      symbol: "fiWBTC",
      name: "bZx WBTC iToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xba9262578efef8b3aff7f60cd629d6cc8859c8b5.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xBA50933C268F567BDC86E1aC131BE072C6B0b71a",
      symbol: "ARPA",
      name: "ARPA Chain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xba50933c268f567bdc86e1ac131be072c6b0b71a/5ceb3585629a4635a59738c873976fc3.png",
      coingeckoId: "arpa-chain",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55",
      symbol: "BAND",
      name: "BAND",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/9545/small/Band_token_blue_violet_token.png?1625881431",
      coingeckoId: "band-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xB9d99C33eA2d86EC5eC6b8A4dD816EBBA64404AF",
      symbol: "K21",
      name: "k21.kanon.art",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af/bcedc54493496d4df2605a9237511833.png",
      coingeckoId: "k21",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB9EefC4b0d472A44be93970254Df4f4016569d27",
      symbol: "XDB",
      name: "digitalbits",
      decimals: 7,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb9eefc4b0d472a44be93970254df4f4016569d27/f33e3b66f6d78a3a8fd597903cfbcc15.png",
      coingeckoId: "digitalbits",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1",
      symbol: "aUNI",
      name: "Aave interest bearing UNI",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmYdpeez387RdMw6zEEa5rMXuayi748Uc15eFuoa3QhGEJ",
      coingeckoId: "aave-uni",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB98d4C97425d9908E66E53A6fDf673ACcA0BE986",
      symbol: "ABT",
      name: "ArcBlock",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb98d4c97425d9908e66e53a6fdf673acca0be986/2e5365ac199169591c05f6818e3e009d.png",
      coingeckoId: "arcblock",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB987D48Ed8f2C468D52D6405624EADBa5e76d723",
      symbol: "STBZ",
      name: "Stabilize Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb987d48ed8f2c468d52d6405624eadba5e76d723/09d39626ff97c5da7c3731ef95d237e8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xB97048628DB6B661D4C2aA833e95Dbe1A905B280",
      symbol: "PAY",
      name: "TenX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb97048628db6b661d4c2aa833e95dbe1a905b280/d106281f696d57453a39ba86917588fa.png",
      coingeckoId: "tenx",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xB8BAa0e4287890a5F79863aB62b7F175ceCbD433",
      symbol: "SWRV",
      name: "Swerve DAO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb8baa0e4287890a5f79863ab62b7f175cecbd433/dc772da1039e2ff1fc0782af23c284f8.png",
      coingeckoId: "swerve-dao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052",
      symbol: "KEYFI",
      name: "KeyFi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052/3a58685637e0e5bbfe84c791b13fff2c.png",
      coingeckoId: "keyfi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB705268213D593B8FD88d3FDEFF93AFF5CbDcfAE",
      symbol: "IDEX",
      name: "IDEX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb705268213d593b8fd88d3fdeff93aff5cbdcfae/5a4d8f631a8e3375b4f8e0f7e5b3e860.png",
      coingeckoId: "aurora-dao",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32",
      symbol: "SYNC",
      name: "SYNC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32/955f09f5331d7f717e0d825a30df9ac8.png",
      coingeckoId: "sync-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB6eD7644C69416d67B522e20bC294A9a9B405B31",
      symbol: "0xBTC",
      name: "0xBitcoin Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb6ed7644c69416d67b522e20bc294a9a9b405b31/d566bfbd74b32b9d0771dac844ff4140.png",
      coingeckoId: "oxbitcoin",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0xB6Ca7399B4F9CA56FC27cBfF44F4d2e4Eef1fc81",
      symbol: "MUSE",
      name: "Muse",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81/928c81705d0bae8ee068909769fea8a0.png",
      coingeckoId: "muse-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC",
      symbol: "STORJ",
      name: "Storj",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png",
      coingeckoId: "storj",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xB63B606Ac810a52cCa15e44bB630fd42D8d1d83d",
      symbol: "MCO",
      name: "Monaco",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb63b606ac810a52cca15e44bb630fd42d8d1d83d/9fa082352c323923cdc9fbce4e200225.png",
      coingeckoId: "monaco",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
      symbol: "NEXO",
      name: "NEXO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/3695/small/nexo.png?1548086057",
      coingeckoId: "nexo",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xB5e09e6Bf6a5E96934B3fd99a40F7EDACa1173Ed",
      symbol: "DIVINE",
      name: "divinedao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xB5e09e6Bf6a5E96934B3fd99a40F7EDACa1173Ed/logo.png",
      coingeckoId: "divine-dao",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xB5FE099475d3030DDe498c3BB6F3854F762A48Ad",
      symbol: "FNK",
      name: "Finiko",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb5fe099475d3030dde498c3bb6f3854f762a48ad/8b55a26bd155d6af9afad130eddd2694.png",
      coingeckoId: "fnkcom",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xB56A1f3310578f23120182Fb2e58c087EFE6e147",
      symbol: "ACYC",
      name: "AllCoinsYieldCapital",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb56a1f3310578f23120182fb2e58c087efe6e147/2dcd263567c393396a74dfa518c9e330.png",
      coingeckoId: "all-coins-yield-capital",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB4d930279552397bbA2ee473229f89Ec245bc365",
      symbol: "MAHA",
      name: "MahaDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb4d930279552397bba2ee473229f89ec245bc365/99948ee1cfc4ab1e5a88c50090e36206.png",
      coingeckoId: "mahadao",
      listedIn: ["coingecko", "1inch", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0xB4EFd85c19999D84251304bDA99E90B92300Bd93",
      symbol: "RPL",
      name: "Rocket Pool",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb4efd85c19999d84251304bda99e90b92300bd93/0dac0c5e1dd543fb62581f0756e0b11f.png",
      coingeckoId: null,
      listedIn: ["openocean", "rubic", "lifinance"],
    },
    {
      address: "0xB4A3B0Faf0Ab53df58001804DdA5Bfc6a3D59008",
      symbol: "SPA",
      name: "Sperax",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb4a3b0faf0ab53df58001804dda5bfc6a3d59008/7295eb4be6608118e780a89b745b701e.png",
      coingeckoId: "sperax",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB4272071eCAdd69d933AdcD19cA99fe80664fc08",
      symbol: "XCHF",
      name: "CryptoFranc",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb4272071ecadd69d933adcd19ca99fe80664fc08/ed88112f112b6a14ebc5c3004ce397a7.png",
      coingeckoId: "cryptofranc",
      listedIn: ["coingecko", "1inch", "openocean", "optimism", "lifinance"],
    },
    {
      address: "0xB37a769B37224449d92AAc57dE379E1267Cd3B00",
      symbol: "COVA",
      name: "Covalent Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb37a769b37224449d92aac57de379e1267cd3b00/d7a17c33f4579108fcad2a523c2c6a3a.png",
      coingeckoId: "covalent-cova",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB29130CBcC3F791f077eAdE0266168E808E5151e",
      symbol: "a1INCH",
      name: "Aave interest bearing 1INCH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xb29130cbcc3f791f077eade0266168e808e5151e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xB26631c6dda06aD89B93C71400D25692de89c068",
      symbol: "MINDS",
      name: "Minds",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb26631c6dda06ad89b93c71400d25692de89c068/68bf687adc2767b80f96df899f4a5fc0.png",
      coingeckoId: "minds",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB1f66997A5760428D3a87D68b90BfE0aE64121cC",
      symbol: "LUA",
      name: "LuaToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1f66997a5760428d3a87d68b90bfe0ae64121cc/65b00fbffe9f6b6e0eb84439c04edc27.png",
      coingeckoId: "lua-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB1e9157c2Fdcc5a856C8DA8b2d89b6C32b3c1229",
      symbol: "ZEFU",
      name: "Zenfuse Trading Platform Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229/a1a54fc87a17d58c0f86c3f044a40ad6.png",
      coingeckoId: "zenfuse",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB1Db366890EeB8f28C2813C6a6084353e0b90713",
      symbol: "UCD",
      name: "UniCandy",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1db366890eeb8f28c2813c6a6084353e0b90713/b55e4cd95dd59bc2bc3526e34bda4d5d.png",
      coingeckoId: "unicandy",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB1A88c33091490218965787919fcc9862C1798eE",
      symbol: "SHIBLI",
      name: "Studio Shibli",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1a88c33091490218965787919fcc9862c1798ee/995a203e8d9efe4bfb96662be231c8ae.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xB14eBF566511B9e6002bB286016AB2497B9b9c9D",
      symbol: "HID",
      name: "Hypersign Identity Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb14ebf566511b9e6002bb286016ab2497b9b9c9d/2be60238a287c649a2479b08ad0f8457.png",
      coingeckoId: "hypersign-identity-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB131F337C45D386cEeC234e194b2663D5c3d9dCF",
      symbol: "ICOM",
      name: "iCommunity",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "icommunity",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB124541127A0A657f056D9Dd06188c4F1b0e5aab",
      symbol: "aUNI",
      name: "Aave Interest bearing Uniswap",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmYdpeez387RdMw6zEEa5rMXuayi748Uc15eFuoa3QhGEJ",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xB1191F691A355b43542Bea9B8847bc73e7Abb137",
      symbol: "KIRO",
      name: "Kirobo",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb1191f691a355b43542bea9b8847bc73e7abb137/552e95d680ed709ec01981c38b61e7d0.png",
      coingeckoId: "kirobo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A",
      symbol: "MAGIC",
      name: "MAGIC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xb0c7a3ba49c7a6eaba6cd4a96c55a1391070ac9a/e50b983bc909e7c1002169d9f5488ced.png",
      coingeckoId: "magic",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6",
      symbol: "STG",
      name: "STG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/24413/small/STG_LOGO.png?1647654518",
      coingeckoId: "stargate-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xAeC65404DdC3af3C897AD89571d5772C1A695F22",
      symbol: "PHX",
      name: "Phoenix Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xaec65404ddc3af3c897ad89571d5772c1a695f22/logo.png",
      coingeckoId: "phoenix-token",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xAcfa209Fb73bF3Dd5bBfb1101B9Bc999C49062a5",
      symbol: "BCDT",
      name: "Blockchain Certified Data Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5/50c3e8dd12dee473d451811347ddbbe1.png",
      coingeckoId: "blockchain-certified-data-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xAc8cC32Fab2368a1A095722AAf760C45f578E17B",
      symbol: "CINU",
      name: "CHEEMSINU",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac8cc32fab2368a1a095722aaf760c45f578e17b/261018f051cbfb78329ac2fc99891e3d.png",
      coingeckoId: "cheems-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAaEf88cEa01475125522e117BFe45cF32044E238",
      symbol: "GF",
      name: "GuildFi Token ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaaef88cea01475125522e117bfe45cf32044e238/3890a31e7efc78a4b08d8596474850eb.png",
      coingeckoId: "guildfi",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xAaE3Cf9968D26925BDb73cE3864e0084a20f4687",
      symbol: "FAR",
      name: "Farmland Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13497/large/70059912.jpg",
      coingeckoId: "farmland-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAaAAAA20D9E0e2461697782ef11675f668207961",
      symbol: "AURORA",
      name: "AURORA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/20582/small/aurora.jpeg?1637250883",
      coingeckoId: "aurora-near",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "xyfinance"],
    },
    {
      address: "0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD",
      symbol: "ETH2x-FLI",
      name: "ETH 2x Flexible Leverage Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd/0f2101412cebd15d60e3b8301ca4d9ae.png",
      coingeckoId: "eth-2x-flexible-leverage-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xAa4e3edb11AFa93c41db59842b29de64b72E355B",
      symbol: "MFI",
      name: "MarginSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0xaa4e3edb11afa93c41db59842b29de64b72e355b.png",
      coingeckoId: "marginswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xAF691508BA57d416f895e32a1616dA1024e882D2",
      symbol: "PNODE",
      name: "Pinknode Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaf691508ba57d416f895e32a1616da1024e882d2/fe3d1a4907b2fa6fbbde2fadd32d5390.png",
      coingeckoId: "pinknode",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAEcc217a749c2405b5ebC9857a16d58Bdc1c367F",
      symbol: "PAWTH",
      name: "Pawthereum",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaecc217a749c2405b5ebc9857a16d58bdc1c367f/22779041864a51e41111ce14bbc836cf.png",
      coingeckoId: "pawthereum",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xAE1eaAE3F627AAca434127644371b67B18444051",
      symbol: "YOP",
      name: "YOP",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xae1eaae3f627aaca434127644371b67b18444051/93550d6bddcbfe7aa5eec96741831682.png",
      coingeckoId: "yield-optimization-platform",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xAE12C5930881c53715B369ceC7606B70d8EB229f",
      symbol: "C98",
      name: "Coin98",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xae12c5930881c53715b369cec7606b70d8eb229f/4a4482e5c882d9aa58365ea2c0247d14.png",
      coingeckoId: "coin98",
      listedIn: ["coingecko", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xADE00C28244d5CE17D72E40330B1c318cD12B7c3",
      symbol: "ADX",
      name: "AdEx Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xade00c28244d5ce17d72e40330b1c318cd12b7c3/78464d52d5c19b1e4c41c35b96f99361.png",
      coingeckoId: "adex",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xAC8E13ecC30Da7Ff04b842f21A62a1fb0f10eBd5",
      symbol: "BABYDOGE",
      name: "BabyDoge Coin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac8e13ecc30da7ff04b842f21a62a1fb0f10ebd5/61f67034d5a0a1854cc185b13e4e5e1c.png",
      coingeckoId: "babydoge-coin-eth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAC57De9C1A09FeC648E93EB98875B212DB0d460B",
      symbol: "BabyDoge",
      name: "Baby Doge Coin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac57de9c1a09fec648e93eb98875b212db0d460b/007ce20bc3d33f66e950bfd1466144cd.png",
      coingeckoId: "baby-doge-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      symbol: "ALICE",
      name: "ALICE",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xac51066d7bec65dc4589368da368b212745d63e8/950a3ab7f443a61580cfaf85a31d77ae.png",
      coingeckoId: "my-neighbor-alice",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0xABe580E7ee158dA464b51ee1a83Ac0289622e6be",
      symbol: "XFT",
      name: "Offshift",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xabe580e7ee158da464b51ee1a83ac0289622e6be/ee34c8bec4551ca91087ef18d8337273.png",
      coingeckoId: "offshift",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0xABDf43c835577f0983686a9851841D314385aafE",
      symbol: "FILE",
      name: "FILE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xabdf43c835577f0983686a9851841d314385aafe.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xAB2A7B5876D707e0126B3A75EF7781c77c8877EE",
      symbol: "QUAD",
      name: "Quadency Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xab2a7b5876d707e0126b3a75ef7781c77c8877ee/1c3d88313ea222ac8a22954b127f5f55.png",
      coingeckoId: "quadency",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xAACa86B876ca011844b5798ECA7a67591A9743C8",
      symbol: "BIOS",
      name: "BIOS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaaca86b876ca011844b5798eca7a67591a9743c8/6cab393de88b8f8b6d1f481194a99b99.png",
      coingeckoId: "bios",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xAAAaaaaBA2ea3daAB0A6c05F1b962c78c9836d99",
      symbol: "AZ",
      name: "Azbit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaaaaaaaba2ea3daab0a6c05f1b962c78c9836d99/27da896946973aa181793a473083eb41.png",
      coingeckoId: "azbit",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xAAA7A10a8ee237ea61E8AC46C50A8Db8bCC1baaa",
      symbol: "QANX",
      name: "QANX Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xaaa7a10a8ee237ea61e8ac46c50a8db8bcc1baaa/46f3c2fdae479c4a81ef0a2f6439a96b.png",
      coingeckoId: "qanplatform",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA9B1Eb5908CfC3cdf91F9B8B3a74108598009096",
      symbol: "AUCTION",
      name: "Bounce",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096/d615d97d905bfde376671f7f62681af5.png",
      coingeckoId: "auction",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0xA9536B9c75A9E0faE3B56a96AC8EdF76AbC91978",
      symbol: "DFI",
      name: "Amun DeFi Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978/6186d1fcd9f368b6afaa88af0a89e96c.png",
      coingeckoId: "amun-defi-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA8e7AD77C60eE6f30BaC54E2E7c0617Bd7B5A03E",
      symbol: "ZLOT",
      name: "zLot Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/zlot.jpg",
      coingeckoId: "zlot",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0xA8b919680258d369114910511cc87595aec0be6D",
      symbol: "LYXe",
      name: "LUKSO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa8b919680258d369114910511cc87595aec0be6d/13d774a188f34a655fc51f0c0b703383.png",
      coingeckoId: "lukso-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xA8b12Cc90AbF65191532a12bb5394A714A46d358",
      symbol: "pBTC35A",
      name: "POW BTC-35W/T",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa8b12cc90abf65191532a12bb5394a714a46d358/a5ccd70adb539ed03529b833b8f10b11.png",
      coingeckoId: "pbtc35a",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xA89ac6e529aCf391CfbBD377F3aC9D93eae9664e",
      symbol: "KP4R",
      name: "Keep4r",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e/114a7e3ac92132dd8f8b9de65c2c24f6.png",
      coingeckoId: "keep4r",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA849EaaE994fb86Afa73382e9Bd88c2B6b18Dc71",
      symbol: "MVL",
      name: "Mass Vehicle Ledger Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa849eaae994fb86afa73382e9bd88c2b6b18dc71/9cd4610317197123e91db5475b083c91.png",
      coingeckoId: "mass-vehicle-ledger",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D",
      symbol: "CTZN",
      name: "Totem Earth Systems",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa803778ab953d3ffe4fbd20cfa0042ecefe8319d/745e12a7ae35c0f14d1beb927588a9be.png",
      coingeckoId: "totem-earth-systems",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA7fC5D2453E3F68aF0cc1B78bcFEe94A1B293650",
      symbol: "SPIKE",
      name: "Spiking",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650/21b7d1dbf6e68a5633c09d1aa1bda456.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xA7Eb2bc82df18013ecC2A6C533fc29446442EDEe",
      symbol: "fiZRX",
      name: "bZx ZRX iToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0xa7eb2bc82df18013ecc2a6c533fc29446442edee.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xA68Dd8cB83097765263AdAD881Af6eeD479c4a33",
      symbol: "WTF",
      name: "fees.wtf",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa68dd8cb83097765263adad881af6eed479c4a33/58f4fe3a54fcbb1749330bda4916f581.png",
      coingeckoId: "wtf-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA67b8e40111A0EDD30C3210b77aadb86AD234c43",
      symbol: "Bandex",
      name: "Banana Index",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0xa67b8e40111a0edd30c3210b77aadb86ad234c43/logo.png",
      coingeckoId: "banana-index",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xA67E9F021B9d208F7e3365B2A155E3C55B27de71",
      symbol: "KLEE",
      name: "KleeKai",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa67e9f021b9d208f7e3365b2a155e3c55b27de71/afd3efa18e1ea73db5cd2301f36a7f6f.png",
      coingeckoId: "kleekai",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA64dFe8D86963151E6496BEe513E366F6e42ED79",
      symbol: "GOKU",
      name: "Goku Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa64dfe8d86963151e6496bee513e366f6e42ed79/94c51522869ea842db2eca3750bde8ed.png",
      coingeckoId: "goku",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84",
      symbol: "aLINK",
      name: "Aave Interest bearing LINK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84/001caff3d1f2758b20a01944f882590a.png",
      coingeckoId: "aave-link-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0",
      symbol: "LOOM",
      name: "Loom Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0/16c38911c14d5c631a4647734df59598.png",
      coingeckoId: "loom-network",
      listedIn: ["coingecko", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3",
      symbol: "RBC",
      name: "Rubic",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3/b1e9f2c1070c21234bb3fbd8bec7f01d.png",
      coingeckoId: "rubic",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe",
      symbol: "USDS",
      name: "StableUSD",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe/ad871b75879a715a1fe672ab11b4a7bb.png",
      coingeckoId: "stableusd",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0xA49d7499271aE71cd8aB9Ac515e6694C755d400c",
      symbol: "MUTE",
      name: "Mute.io",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa49d7499271ae71cd8ab9ac515e6694c755d400c/cd34c8329d8f0fed3ee3976937dc44db.png",
      coingeckoId: "mute",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA487bF43cF3b10dffc97A9A744cbB7036965d3b9",
      symbol: "DERI",
      name: "Deri",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9/ab55504e0eaba55d64c177fe3ff502b2.png",
      coingeckoId: "deri-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xA461258c192cB6057aD8729589B0d18B08CcACe8",
      symbol: "PLANETINU",
      name: "Planet Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa461258c192cb6057ad8729589b0d18b08ccace8/eb0b627a01d23acc57d7dd0940959881.png",
      coingeckoId: "planet-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xA3c4Dc4A9cE2a6B40B57F25F8b50DeCc2c64deC2",
      symbol: "SNFT",
      name: "SeedSwap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2/c3904600fed99280d27204419d30e19c.png",
      coingeckoId: "seedswap",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xA36FDBBAE3c9d55a1d67EE5821d53B50B63A1aB9",
      symbol: "TEMP",
      name: "Tempus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9/9d092f467bded810ccd2a1f8a7a341b3.png",
      coingeckoId: "tempus",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA361718326c15715591c299427c62086F69923D9",
      symbol: "aBUSD",
      name: "Aave interest bearing BUSD",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aBUSD.svg",
      coingeckoId: "aave-busd",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA31B1767e09f842ECFd4bc471Fe44F830E3891AA",
      symbol: "ROOBEE",
      name: "ROOBEE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa/0afe2de932cb2c4814bc4299ffbe57c2.png",
      coingeckoId: "roobee",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18",
      symbol: "XCN",
      name: "Chain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18/14706ee051f7c4056ea1149b617c05b2.png",
      coingeckoId: "chain-2",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D",
      symbol: "KISHU",
      name: "Kishu Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa2b4c0af19cc16a6cfacce81f192b024d625817d/e4d240fb3369e4ac8d9dd71e46278b56.png",
      coingeckoId: "kishu-inu",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0xA2120b9e674d3fC3875f415A7DF52e382F141225",
      symbol: "ATA",
      name: "Automata",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa2120b9e674d3fc3875f415a7df52e382f141225/0e28b779e61c729984f4e62ed1eaeda5.png",
      coingeckoId: "automata",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA2085073878152aC3090eA13D1e41bD69e60Dc99",
      symbol: "ELG",
      name: "EscoinToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa2085073878152ac3090ea13d1e41bd69e60dc99/cc98925eeccb1db98f9a502766cadfc6.png",
      coingeckoId: "escoin-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA1AFFfE3F4D611d252010E3EAf6f4D77088b0cd7",
      symbol: "RFI",
      name: "reflect.finance",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7/b3bbb93c0f9f5459f0e729f29dbd0e60.png",
      coingeckoId: "reflect-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA15C7Ebe1f07CaF6bFF097D8a589fb8AC49Ae5B3",
      symbol: "NPXS",
      name: "Pundi X",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3/0acca406fb305041b0a9f66cc5cbfa0f.png",
      coingeckoId: "pundi-x",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xA130E3a33a4d84b04c3918c4E5762223Ae252F80",
      symbol: "SWASH",
      name: "Swash Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa130e3a33a4d84b04c3918c4e5762223ae252f80/8725b5f407815fff037068d8d66759ab.png",
      coingeckoId: "swash",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      symbol: "USDC",
      name: "USD Coin",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
      coingeckoId: "usd-coin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b",
      symbol: "CRO",
      name: "CRO",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/7310/small/oCw2s3GI_400x400.jpeg?1645172042",
      coingeckoId: "crypto-com-chain",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xA0CF46eb152656C7090e769916eb44a138aaa406",
      symbol: "SPH",
      name: "Spheroid",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa0cf46eb152656c7090e769916eb44a138aaa406/6d7373b4498f512c4d1e0c5e80150652.png",
      coingeckoId: "spheroid-universe",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA0AFec7853385740C4431bEC4EaB36dffaF41f78",
      symbol: "GOPX2",
      name: "Game On Players",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0xa0afec7853385740c4431bec4eab36dffaf41f78/e3e4d469e83293d63b0c7b5e0274b386.png",
      coingeckoId: "game-on-players",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656",
      symbol: "aWBTC",
      name: "Aave interest bearing WBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656/5e5396b757caf2e0b10efe1a16139c84.png",
      coingeckoId: "aave-wbtc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9fa69536d1cda4A04cFB50688294de75B505a9aE",
      symbol: "DERC",
      name: "DeRace Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9fa69536d1cda4a04cfb50688294de75b505a9ae/0c33875205b5722809a5891614a9abd5.png",
      coingeckoId: "derace",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x9fB83c0635De2E815fd1c21b3a292277540C2e8d",
      symbol: "FEVR",
      name: "FevrToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9fb83c0635de2e815fd1c21b3a292277540c2e8d/2012baa15689ca4acf7e4b72bcbe24c9.png",
      coingeckoId: "realfevr",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
      symbol: "MKR",
      name: "Maker",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png",
      coingeckoId: "maker",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599",
      symbol: "stMATIC",
      name: "Staked MATIC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9ee91f9f426fa633d227f7a9b000e28b9dfd8599/8f7443e9c28581c4a108b8232d426332.png",
      coingeckoId: "lido-staked-matic",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9e70240d2A8a30a592d3F076441C4F303b26dE12",
      symbol: "OCT",
      name: "Wrapped OCT",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x9e70240d2a8a30a592d3f076441c4f303b26de12.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x9e5BD9D9fAd182ff0A93bA8085b664bcab00fA68",
      symbol: "DINGER",
      name: "Dinger Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68/638658bb6effa1eb7b746e97dc4bdb54.png",
      coingeckoId: "dinger-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9d409a0A012CFbA9B15F6D4B36Ac57A46966Ab9a",
      symbol: "yvBOOST",
      name: "Yearn Compounding veCRV yVault",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/yvboost.jpg",
      coingeckoId: "yvboost",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9d3EE6B64e69Ebe12a4bF0b01D031CB80F556eE4",
      symbol: "PECO",
      name: "Polygon Ecosystem Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9d3EE6B64e69Ebe12a4bF0b01D031CB80F556eE4/logo.png",
      coingeckoId: "polygon-ecosystem-index",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544",
      symbol: "DOKI",
      name: "DokiDokiFinance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544/e40da787ce0b817809ad57903c0a91c8.png",
      coingeckoId: "doki-doki-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9c354503C38481a7A7a51629142963F98eCC12D0",
      symbol: "OGV",
      name: "Origin Dollar Governance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9c354503c38481a7a7a51629142963f98ecc12d0/49f05103f6606935f4a7f0b98b257443.png",
      coingeckoId: "origin-dollar-governance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9c23D67AEA7B95D80942e3836BCDF7E708A747C2",
      symbol: "LOCI",
      name: "LOCIcoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x9c23d67aea7b95d80942e3836bcdf7e708a747c2.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x9baF8a5236d44AC410c0186Fe39178d5AAD0Bb87",
      symbol: "crYCRV",
      name: "Cream yCRV",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x9baf8a5236d44ac410c0186fe39178d5aad0bb87.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B",
      symbol: "YLA",
      name: "Yearn Lazy Ape Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B/logo.png",
      coingeckoId: "yearn-lazy-ape",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9bA00D6856a4eDF4665BcA2C2309936572473B7E",
      symbol: "aUSDC",
      name: "Aave Interest bearing USDC",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9ba00d6856a4edf4665bca2c2309936572473b7e/6841848cb7d6b39981cb76953af0f98a.png",
      coingeckoId: "aave-usdc-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x9b62Ec1453cEa5Dde760AAf662048cA6eEB66E7f",
      symbol: "ECELL",
      name: "ECELL",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9b62ec1453cea5dde760aaf662048ca6eeb66e7f/e54ea31f2965faa35477326741ec3f61.png",
      coingeckoId: "celletf",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x9b53E429B0baDd98ef7F01F03702986c516a5715",
      symbol: "HY",
      name: "hybrix hydra",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9b53e429b0badd98ef7f01f03702986c516a5715/9c671acb1c5410e159084f124f10ead3.png",
      coingeckoId: "hybrix",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9b17bAADf0f21F03e35249e0e59723F34994F806",
      symbol: "GEM",
      name: "NFTmall GEM Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png",
      coingeckoId: "nftmall",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9aeB50f542050172359A0e1a25a9933Bc8c01259",
      symbol: "OIN",
      name: "oinfinance",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9aeb50f542050172359a0e1a25a9933bc8c01259/d9cad2328107483fade99605973d30f6.png",
      coingeckoId: "oin-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9ad37205d608B8b219e6a2573f922094CEc5c200",
      symbol: "iZi",
      name: "Izumi Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9ad37205d608b8b219e6a2573f922094cec5c200/7b759cd6fd6ff6c1059272152e61a922.png",
      coingeckoId: "izumi-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9aF15D7B8776fa296019979E70a5BE53c714A7ec",
      symbol: "EVN",
      name: "Evn Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9af15d7b8776fa296019979e70a5be53c714a7ec/802d2b31a00bc513bc977e3c859456ab.png",
      coingeckoId: "evolution-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9a794Dc1939F1d78fa48613b89B8f9d0A20dA00E",
      symbol: "ABX",
      name: "Arbidex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e/9e372e63562efe6c76b3ebc8b5d49b3f.png",
      coingeckoId: "arbidex",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x9F9c8ec3534c3cE16F928381372BfbFBFb9F4D24",
      symbol: "GLQ",
      name: "GraphLinq",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24/93ceea3b80e0145240e69f54eb18204b.png",
      coingeckoId: "graphlinq-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9Ed8e7C9604790F7Ec589F99b94361d8AAB64E5E",
      symbol: "UNISTAKE",
      name: "Unistake",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e/6443af3dd031158e2c753da663d8b62c.png",
      coingeckoId: "unistake",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9EA3b5b4EC044b70375236A281986106457b20EF",
      symbol: "DELTA",
      name: "DELTA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9EA3b5b4EC044b70375236A281986106457b20EF/logo.png",
      coingeckoId: "delta-financial",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9E78b8274e1D6a76a0dBbf90418894DF27cBCEb5",
      symbol: "UniFi",
      name: "UniFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5/b25c5df02513a65518e52faff21e8cef.png",
      coingeckoId: "unifi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1",
      symbol: "NCT",
      name: "Nectar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9e46a38f5daabe8683e10793b06749eef7d733d1/22c954d7b64b2810e0bf7d71563a53aa.png",
      coingeckoId: "polyswarm",
      listedIn: ["coingecko", "uniswap", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
      symbol: "Metis",
      name: "Metis",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9e32b13ce7f2e80a01932b42553652e053d6ed8e/3b30ff79e54dab6fc535a4ad00859837.png",
      coingeckoId: "metis-token",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x9D91BE44C06d373a8a226E1f3b146956083803eB",
      symbol: "aKNC",
      name: "Aave Interest bearing KNC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9d91be44c06d373a8a226e1f3b146956083803eb/2d9164fa8a9aa1e484f5c77d7335a900.png",
      coingeckoId: "aave-knc-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x9D79d5B61De59D882ce90125b18F74af650acB93",
      symbol: "NSBT",
      name: "Neutrino System Base Token",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9D79d5B61De59D882ce90125b18F74af650acB93/logo.png",
      coingeckoId: "neutrino-system-base-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9D47894f8BECB68B9cF3428d256311Affe8B068B",
      symbol: "$ROPE",
      name: "$ROPE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9d47894f8becb68b9cf3428d256311affe8b068b/854ba4b960c5ec9cdce4b3f611c1284e.png",
      coingeckoId: "rope",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x9Cb2f26A23b8d89973F08c957C4d7cdf75CD341c",
      symbol: "DZAR",
      name: "Digital Rand",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c.png",
      coingeckoId: "digital-rand",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
      symbol: "MUST",
      name: "Must",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/logo.png",
      coingeckoId: "must",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9C4A4204B79dd291D6b6571C5BE8BbcD0622F050",
      symbol: "TCR",
      name: "Tracer",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050/9b533f8265c54ab60417e8e06767c30c.png",
      coingeckoId: "tracer-dao",
      listedIn: ["coingecko", "sushiswap", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x9C38688E5ACB9eD6049c8502650db5Ac8Ef96465",
      symbol: "LIF",
      name: "Lif",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9c38688e5acb9ed6049c8502650db5ac8ef96465/3d6ad1139156c8db7e08ce670c0c769a.png",
      coingeckoId: "winding-tree",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9C2dc0c3CC2BADdE84B0025Cf4df1c5aF288D835",
      symbol: "COR",
      name: "COR Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9c2dc0c3cc2badde84b0025cf4df1c5af288d835/3b9fa8371c80889823ed03d11153d9cb.png",
      coingeckoId: "coreto",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541",
      symbol: "BBTC",
      name: "Binance Wrapped BTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9be89d2a4cd102d8fecc6bf9da793be995c22541/af75e5c0b1c14169037248e7b9eeb754.png",
      coingeckoId: "binance-wrapped-btc",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x9B99CcA871Be05119B2012fd4474731dd653FEBe",
      symbol: "MATTER",
      name: "Antimatter.Finance Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9b99cca871be05119b2012fd4474731dd653febe/4a2d317d4f828cff0421d8580489e6e4.png",
      coingeckoId: "antimatter",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x9B85babc0cc89899cCd47e9226A0b1FaE577B19E",
      symbol: "PPB",
      name: "PPBToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x9b85babc0cc89899ccd47e9226a0b1fae577b19e.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x9B02dD390a603Add5c07f9fd9175b7DABE8D63B7",
      symbol: "SPI",
      name: "Shopping.io",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7/49083693ee74a6134f7b0ac10ef9123d.png",
      coingeckoId: "shopping-io",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9AF4f26941677C706cfEcf6D3379FF01bB85D5Ab",
      symbol: "DRT",
      name: "DomRaider",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9af4f26941677c706cfecf6d3379ff01bb85d5ab/799a0bebf23c817f16f05a80e1e968bb.png",
      coingeckoId: "domraider",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62",
      symbol: "GOG",
      name: "Guild of Guardians",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62/logo.png",
      coingeckoId: "guild-of-guardians",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x9A48BD0EC040ea4f1D3147C025cd4076A2e71e3e",
      symbol: "USD++",
      name: "PieDAO USD++ Pool",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e/6ad6a00910e2a760b7f42011c07cc729.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x9A257C90Fa239fBA07771ef7da2d554D148c2E89",
      symbol: "eYe",
      name: "eYe on Ethereum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9a257c90fa239fba07771ef7da2d554d148c2e89/90bf851397911a75430ceaa8baa30c2d.png",
      coingeckoId: "media-eye",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9A0aBA393aac4dFbFf4333B06c407458002C6183",
      symbol: "AC",
      name: "ACoconut",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9a0aba393aac4dfbff4333b06c407458002c6183/28e92d70d954314c1cbe41eccf8ae1e3.png",
      coingeckoId: "acoconut",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x99fE3B1391503A1bC1788051347A1324bff41452",
      symbol: "SX",
      name: "SportX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x99fe3b1391503a1bc1788051347a1324bff41452/276ef244ab0018b2a50a84f2227b22b4.png",
      coingeckoId: "sx-network",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d",
      symbol: "QSP",
      name: "Quantstamp",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d/e75067bcfb5c3dfc5154414ccfa5b90b.png",
      coingeckoId: "quantstamp",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3",
      symbol: "MIM",
      name: "Magic Internet Money",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3/7d0c0fb6eab1b7a8a9bfb7dcc04cb11e.png",
      coingeckoId: "magic-internet-money",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC",
      symbol: "POLY",
      name: "Polymath",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec/7466a19c7e6a499fc2722c575b1d5393.png",
      coingeckoId: "polymath",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x99534Ef705Df1FFf4e4bD7bbaAF9b0dFf038EbFe",
      symbol: "aMATICb",
      name: "Ankr MATIC Reward Earning Bond ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x99534ef705df1fff4e4bd7bbaaf9b0dff038ebfe/5acf19e660e9a0c1fdebcf55ab81f1d7.png",
      coingeckoId: "ankr-matic-reward-earning-bond",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x993864E43Caa7F7F12953AD6fEb1d1Ca635B875F",
      symbol: "SDAO",
      name: "Singularity Dao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x993864e43caa7f7f12953ad6feb1d1ca635b875f/6ccda7a005122611519cf9575918c7f9.png",
      coingeckoId: "singularitydao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x99295f1141d58A99e939F7bE6BBe734916a875B8",
      symbol: "LPL",
      name: "LinkPool",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x99295f1141d58a99e939f7be6bbe734916a875b8/5d6343b396adab5c3f0287a80f1e404f.png",
      coingeckoId: "linkpool",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x990f341946A3fdB507aE7e52d17851B87168017c",
      symbol: "STRONG",
      name: "Strong",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x990f341946a3fdb507ae7e52d17851b87168017c/c9ec62a95863c8358f3701b172821df4.png",
      coingeckoId: "strong",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96",
      symbol: "NEWO",
      name: "New Order",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96/0df341c210b988f99f146e1393de92d3.png",
      coingeckoId: "new-order",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x9813037ee2218799597d83D4a5B6F3b6778218d9",
      symbol: "BONE",
      name: "BONE SHIBASWAP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9813037ee2218799597d83d4a5b6f3b6778218d9/6799082a3c568fa1293bda3e02df836d.png",
      coingeckoId: "bone-shibaswap",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x97b65710D03E12775189F0D113202cc1443b0aa2",
      symbol: "ELONONE",
      name: "ASTROELON",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x97b65710d03e12775189f0d113202cc1443b0aa2/c293a57f15478e01925ee40346a08c57.png",
      coingeckoId: "astroelon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x97a9bac06f90940bCe9CAeC2b880ff17707519e4",
      symbol: "MNTO",
      name: "Minato",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x97a9bac06f90940bce9caec2b880ff17707519e4/82b312bf0e8c42c03148b2631ef8bf86.png",
      coingeckoId: "minato",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x97AbEe33Cd075c58BFdd174e0885e08E8f03556F",
      symbol: "SENT",
      name: "Sentiment Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x97abee33cd075c58bfdd174e0885e08e8f03556f/cb58f8bdf4bb05d61be5cbb6139bf2fe.png",
      coingeckoId: "sentiment-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x97A3BD8a445cC187c6A751F392e15C3B2134D695",
      symbol: "BXR",
      name: "Blockster",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x97a3bd8a445cc187c6a751f392e15c3b2134d695/c9e7dd1f14ff645fb48310540d86892e.png",
      coingeckoId: "blockster",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x97872EAfd79940C7b24f7BCc1EADb1457347ADc9",
      symbol: "STRP",
      name: "Strips Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x97872eafd79940c7b24f7bcc1eadb1457347adc9/3f79b7e206aac2dde8eead86d2d403fb.png",
      coingeckoId: "strips-finance",
      listedIn: ["coingecko", "sushiswap", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x974c98Bc2e82FA18de92B7e697A1D9BD25682e80",
      symbol: "ETCBULL",
      name: "3X Long Ethereum Classic Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "3x-long-ethereum-classic-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d",
      symbol: "FUSE",
      name: "Fuse Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d/db14dbdc589e1e7c9068ced60bb0f784.png",
      coingeckoId: "fuse-network-token",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x9695e0114e12C0d3A3636fAb5A18e6b737529023",
      symbol: "DFYN",
      name: "DFYN Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9695e0114e12c0d3a3636fab5a18e6b737529023/b64c678e56437a3e7299af0ea7e804d4.png",
      coingeckoId: "dfyn-network",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x967da4048cD07aB37855c090aAF366e4ce1b9F48",
      symbol: "OCEAN",
      name: "Ocean Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x967da4048cd07ab37855c090aaf366e4ce1b9f48/d525ff9a0f6cf40367e7d74ef403f86d.png",
      coingeckoId: "ocean-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x96610186F3ab8d73EBEe1CF950C750f3B1Fb79C2",
      symbol: "EJS",
      name: "Enjinstarter",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x96610186f3ab8d73ebee1cf950c750f3b1fb79c2/e1abf5c4cf738f2d2f04f81769a8e5d9.png",
      coingeckoId: "enjinstarter",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17",
      symbol: "DYP",
      name: "DeFiYieldProtocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17/b87f9fd3c479f29a98819dbe97edbef1.png",
      coingeckoId: "defi-yield-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x960b236A07cf122663c4303350609A66A7B288C0",
      symbol: "ANT",
      name: "Aragon Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x960b236A07cf122663c4303350609A66A7B288C0/logo.png",
      coingeckoId: null,
      listedIn: ["1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      symbol: "SHIB",
      name: "SHIB",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446",
      coingeckoId: "shiba-inu",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x95a4492F028aa1fd432Ea71146b433E7B4446611",
      symbol: "APY",
      name: "APY Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x95a4492f028aa1fd432ea71146b433e7b4446611/31f5a55b09524c2f39c8fbab8b9eb25d.png",
      coingeckoId: "apy-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x95A1796437BAd6502d1c1cce165Cd76E522409a9",
      symbol: "OCCT",
      name: "Official Crypto Cowboy Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x95a1796437bad6502d1c1cce165cd76e522409a9/9a2c55a46675dc3299d7451715b0e34d.png",
      coingeckoId: "official-crypto-cowboy-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x9590d8C06BA451bbaD0893F2eF0D2A8B5AcC67d3",
      symbol: "SCOR",
      name: "Scorcher",
      decimals: 6,
      chainId: "1",
      logoURI: null,
      coingeckoId: "scorcher",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
      symbol: "FEI",
      name: "FEI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14570/small/ZqsF51Re_400x400.png?1617082206",
      coingeckoId: "fei-usd",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x9534ad65fb398E27Ac8F4251dAe1780B989D136e",
      symbol: "PYR",
      name: "Vulcan Forged",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9534ad65fb398e27ac8f4251dae1780b989d136e/c16bac72715fbe4a89b879ed7637fca7.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x95172ccBe8344fecD73D0a30F54123652981BD6F",
      symbol: "LOCK",
      name: "Meridian Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x95172ccbe8344fecd73d0a30f54123652981bd6f/09c7d4c3af3e264b7d70f89b76156790.png",
      coingeckoId: "meridian-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x9506d37f70eB4C3d79C398d326C871aBBf10521d",
      symbol: "MLT",
      name: "Media Licensing Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9506d37f70eb4c3d79c398d326c871abbf10521d/76a7495f35c127e107a1b8b522cccc04.png",
      coingeckoId: "media-licensing-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x94E9EB8b5Ab9fd6B9ea3169D55FFAde62a01702e",
      symbol: "BREED",
      name: "BREED",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/25203/small/BreederDAO-Breed_Token-FINAL.png?1650638916",
      coingeckoId: "breederdao",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6",
      symbol: "MC",
      name: "MC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/19304/small/Db4XqML.png?1634972154",
      coingeckoId: "merit-circle",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x947AEb02304391f8fbE5B25D7D98D649b57b1788",
      symbol: "MDX",
      name: "MANDALA EXCHANGE TOKEN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x947aeb02304391f8fbe5b25d7d98d649b57b1788/73721d1ca138f2ddfbf08508555d1f01.png",
      coingeckoId: "mandala-exchange-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9469D013805bFfB7D3DEBe5E7839237e535ec483",
      symbol: "RING",
      name: "Darwinia Native Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9469d013805bffb7d3debe5e7839237e535ec483/3cc472748e2dca20a97098dce3853fbe.png",
      coingeckoId: "darwinia-network-native-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x945Facb997494CC2570096c74b5F66A3507330a1",
      symbol: "mBTC",
      name: "mStable BTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x945facb997494cc2570096c74b5f66a3507330a1/70a0003535c8f6bb688d42713f26777b.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x944eeE930933BE5E23b690c8589021Ec8619a301",
      symbol: "MUNCH",
      name: "MUNCH Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x944eee930933be5e23b690c8589021ec8619a301/2c056f898630ea6f117dbf77ec3d4304.png",
      coingeckoId: "munch-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x940a2dB1B7008B6C776d4faaCa729d6d4A4AA551",
      symbol: "DUSK",
      name: "Dusk Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551/a96ffb20298448651d73f2dbb7320684.png",
      coingeckoId: "dusk-network",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d",
      symbol: "PNK",
      name: "Pinakion",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d/c0a269f5324831b246c330511e44ba14.png",
      coingeckoId: "kleros",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x939A7A577D93ad29B64C1595B1284ce660A479B9",
      symbol: "JEJUDOGE",
      name: "Jejudoge",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x939a7a577d93ad29b64c1595b1284ce660a479b9/df9a624585a0fff079b4aa03a86c75c5.png",
      coingeckoId: "jejudoge",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x9393fdc77090F31c7db989390D43F454B1A6E7F3",
      symbol: "DEC",
      name: "DarkEnergyCrystals",
      decimals: 3,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9393fdc77090f31c7db989390d43f454b1a6e7f3/ce6ed8d447ced9fe287f847c4ace82b3.png",
      coingeckoId: "dark-energy-crystals",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x93581991f68DBaE1eA105233b67f7FA0D6BDeE7b",
      symbol: "WEVMOS",
      name: "Wrapped Evmos",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x93581991f68dbae1ea105233b67f7fa0d6bdee7b/043f177f2b977d621e3c656095e52377.png",
      coingeckoId: "evmos",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9355372396e3F6daF13359B7b607a3374cc638e0",
      symbol: "WHALE",
      name: "WHALE",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9355372396e3f6daf13359b7b607a3374cc638e0/343de49f1e1173e7b3001906bcb0d563.png",
      coingeckoId: "whale",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x92eF4FFBfe0Df030837b65d7FcCFE1ABd6549579",
      symbol: "SWG",
      name: "Swirge",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x92ef4ffbfe0df030837b65d7fccfe1abd6549579/370539e708faf7a6b489bcb137189eaf.png",
      coingeckoId: "swirge",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5",
      symbol: "TRDG",
      name: "Tardigrades.Finance",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x92a42db88ed0f02c71d439e55962ca7cab0168b5/133a60f375f66196213748af26ead480.png",
      coingeckoId: "tardigrades-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x92E187a03B6CD19CB6AF293ba17F2745Fd2357D5",
      symbol: "DUCK_UNIT",
      name: "Unit Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x92e187a03b6cd19cb6af293ba17f2745fd2357d5/61e967737c4713437b5066fe9b010941.png",
      coingeckoId: "unit-protocol-duck",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x92DF60c51C710a1b1C20E42D85e221f3A1bFc7f2",
      symbol: "BANANA",
      name: "ApeSwapFinance Banana",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x92df60c51c710a1b1c20e42d85e221f3a1bfc7f2/4f099f1c7676d31fbdc7a3642a0c9667.png",
      coingeckoId: "apeswap-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
      symbol: "DYDX",
      name: "DYDX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17500/small/hjnIm9bV.jpg?1628009360",
      coingeckoId: "dydx",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x92CfbEC26C206C90aeE3b7C66A9AE673754FaB7e",
      symbol: "OLE",
      name: "OpenLeverage",
      decimals: 18,
      chainId: "1",
      logoURI: "https://openleverage.finance/brandassets/logo256x256.png",
      coingeckoId: "openleverage",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x92B767185fB3B04F881e3aC8e5B0662a027A1D9f",
      symbol: "crDAI",
      name: "Cream Dai Stablecoin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x9288A57D6c5644B25569e9D1d5e9e328228D26c6",
      symbol: "mBTM",
      name: "mBTM",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1634705490428_8148326090310605.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x9248c485b0B80f76DA451f167A8db30F33C70907",
      symbol: "DEBASE",
      name: "Debase",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x9248c485b0b80f76da451f167a8db30f33c70907/1de8110ba254f09dc44a3304febe64a8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x9214eC02CB71CbA0ADA6896b8dA260736a67ab10",
      symbol: "REAL",
      name: "REAL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x9214ec02cb71cba0ada6896b8da260736a67ab10.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x91dFbEE3965baAEE32784c2d546B7a0C62F268c9",
      symbol: "BONDLY",
      name: "Bondly",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x91dfbee3965baaee32784c2d546b7a0c62f268c9/124b42b5e361e907c1c3f354df578f6e.png",
      coingeckoId: "bondly",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6",
      symbol: "AERGO",
      name: "Aergo",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x91af0fbb28aba7e31403cb457106ce79397fd4e6/d5be71fa80ee20169d7053f48b0c5781.png",
      coingeckoId: "aergo",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x9196E18Bc349B1F64Bc08784eaE259525329a1ad",
      symbol: "PUSSY",
      name: "PUSSY token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9196e18bc349b1f64bc08784eae259525329a1ad/ce14a36da0424a2d47d44d0385a6319e.png",
      coingeckoId: "pussy-financial",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x916c5DE09cF63f6602d1e1793FB41F6437814A62",
      symbol: "JACY",
      name: "JACY",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x916c5de09cf63f6602d1e1793fb41f6437814a62/4a20dfbe83542a071a6fd043dcb2f565.png",
      coingeckoId: "jacy",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x910Dfc18D6EA3D6a7124A6F8B5458F281060fa4c",
      symbol: "X8X",
      name: "x8currency",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c/bc859ba96b5c9ea58258edd4fc851f94.png",
      coingeckoId: "x8-project",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x910524678C0B1B23FFB9285a81f99C29C11CBaEd",
      symbol: "AZUKI",
      name: "DokiDokiAzuki",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x910524678c0b1b23ffb9285a81f99c29c11cbaed/817117457006f644cf1131d0d068c958.png",
      coingeckoId: "azuki",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x90F36d7BfBa633F17eCcc62Ec31C58a9a3C04C2a",
      symbol: "PLSF",
      name: "PulseFloki",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x90f36d7bfba633f17eccc62ec31c58a9a3c04c2a/7bae72c0081e676c84c1b9032a91c716.png",
      coingeckoId: "pulsefloki",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x90DE74265a416e1393A450752175AED98fe11517",
      symbol: "UDT",
      name: "Unlock Discount Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x90de74265a416e1393a450752175aed98fe11517/f8be839b8c93159326752279cb2e566d.png",
      coingeckoId: "unlock-protocol",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x90B831fa3Bebf58E9744A14D638E25B4eE06f9Bc",
      symbol: "MIMO",
      name: "MIMO Parallel Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x90b831fa3bebf58e9744a14d638e25b4ee06f9bc/72fe5c216bc97d2a3d70ac9b4ec5bb25.png",
      coingeckoId: "mimo-parallel-governance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x9041Fe5B3FDEA0f5e4afDC17e75180738D877A01",
      symbol: "PRO",
      name: "ProToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x9041fe5b3fdea0f5e4afdc17e75180738d877a01.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x9040e237C3bF18347bb00957Dc22167D0f2b999d",
      symbol: "STND",
      name: "Standard Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x9040e237c3bf18347bb00957dc22167d0f2b999d/d9e77db5a635b321a5d9fc51e2a63f53.png",
      coingeckoId: "standard-protocol",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x903bEF1736CDdf2A537176cf3C64579C3867A881",
      symbol: "ICHI",
      name: "ICHI",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13119/small/ICHI_%28Round%29.jpg?1614308761",
      coingeckoId: "legacy-ichi",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x903560b1CcE601794C584F58898dA8a8b789Fc5d",
      symbol: "crKP3R",
      name: "Cream Keep3rV1",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x903560b1cce601794c584f58898da8a8b789fc5d.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8fB00FDeBb4E83f2C58b3bcD6732AC1B6A7b7221",
      symbol: "oORN",
      name: "Old Orion Protocol",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x8fb00fdebb4e83f2c58b3bcd6732ac1b6a7b7221/ad5d9caf793ade8496903fcefdf3df45.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x8f8221aFbB33998d8584A2B05749bA73c37a938a",
      symbol: "REQ",
      name: "Request",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8f8221afbb33998d8584a2b05749ba73c37a938a/332d9168c95953276e7db6d4b2032c15.png",
      coingeckoId: "request-network",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x8f693ca8D21b157107184d29D398A8D082b38b76",
      symbol: "DATA",
      name: "Streamr",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8f693ca8d21b157107184d29d398a8d082b38b76/655df747b3daad9be338e711f268ea6f.png",
      coingeckoId: "streamr",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374",
      symbol: "VERI",
      name: "Veritaseum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374/f3d152d68a42c1c912afce4103aca38d.png",
      coingeckoId: "veritaseum",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41",
      symbol: "TXL",
      name: "Tixl Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41/579e5904f61e6c942455dad79802aaaa.png",
      coingeckoId: "autobahn-network",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8eB24319393716668D768dCEC29356ae9CfFe285",
      symbol: "AGI",
      name: "SingularityNET Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x8eb24319393716668d768dcec29356ae9cffe285/a5567aa121acf9632e42d7d001bf6f5e.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x8e2B4badaC15a4ec8c56020f4Ce60faa7558c052",
      symbol: "CNFT",
      name: "Communifty",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x8e2B4badaC15a4ec8c56020f4Ce60faa7558c052/logo.png",
      coingeckoId: "communifty",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x8dB253a1943DdDf1AF9bcF8706ac9A0Ce939d922",
      symbol: "UNB",
      name: "Unbound",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8db253a1943dddf1af9bcf8706ac9a0ce939d922/bbada11622ba88955a639bbd9caafe72.png",
      coingeckoId: "unbound-finance",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x8dB1D28Ee0d822367aF8d220C0dc7cB6fe9DC442",
      symbol: "ETHPAD",
      name: "ETHPAD.network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442/fa86c85b5391d16be134bf808c8cd987.png",
      coingeckoId: "ethpad",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa",
      symbol: "TBTC",
      name: "tBTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8daebade922df735c38c80c7ebd708af50815faa/c879b3842fc8ed5fe2afec2d4736b0cc.png",
      coingeckoId: "tbtc",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x8d610E20481F4C4f3aCB87bBa9c46BeF7795fdFe",
      symbol: "UNT",
      name: "Unity Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8d610e20481f4c4f3acb87bba9c46bef7795fdfe/ee60152849f8891b2835464904e7b51c.png",
      coingeckoId: "unity-network",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x8c15Ef5b4B21951d50E53E4fbdA8298FFAD25057",
      symbol: "FX",
      name: "Function X",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8c15ef5b4b21951d50e53e4fbda8298ffad25057/058fd7d088878da4f1d20a7253c41d59.png",
      coingeckoId: "fx-coin",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x8b3FF1ed4F36C2c2be675AFb13CC3AA5d73685a5",
      symbol: "crCEL",
      name: "Cream Celsius",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x8b3ff1ed4f36c2c2be675afb13cc3aa5d73685a5.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8b0E42F366bA502d787BB134478aDfAE966C8798",
      symbol: "LABS",
      name: "LABS Group",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8b0e42f366ba502d787bb134478adfae966c8798/9c6d33a0ab2870bbc32338e8683ddf58.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8a854288a5976036A725879164Ca3e91d30c6A1B",
      symbol: "GET",
      name: "GET",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a854288a5976036a725879164ca3e91d30c6a1b/97fdc91a07578cf676fec193033a7fd8.png",
      coingeckoId: "get-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8a6f3BF52A26a21531514E23016eEAe8Ba7e7018",
      symbol: "MXX",
      name: "Multiplier",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a6f3bf52a26a21531514e23016eeae8ba7e7018/e6fad8a6ae71d7468a610425d3ccd472.png",
      coingeckoId: "multiplier",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8a6D4C8735371EBAF8874fBd518b56Edd66024eB",
      symbol: "BLOCKS",
      name: "Blocks Dao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x8a6D4C8735371EBAF8874fBd518b56Edd66024eB/logo.png",
      coingeckoId: "blocks",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x8a6ACA71A218301c7081d4e96D64292D3B275ce0",
      symbol: "SFG",
      name: "Stable Finance Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a6aca71a218301c7081d4e96d64292d3b275ce0/10a2dd6cbd1274bda046dab4739c6f7e.png",
      coingeckoId: "s-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8a40c222996f9F3431f63Bf80244C36822060f12",
      symbol: "FXF",
      name: "Finxflo",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a40c222996f9f3431f63bf80244c36822060f12/15826c034d4bbbe91485a67f90b8c908.png",
      coingeckoId: "finxflo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8a3d77e9d6968b780564936d15B09805827C21fa",
      symbol: "UCO",
      name: "UnirisToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a3d77e9d6968b780564936d15b09805827c21fa/e7ca6756759c5a00a3a3e548b99ceb01.png",
      coingeckoId: "archethic",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x8F6A193C8B3c949E1046f1547C3A3f0836944E4b",
      symbol: "xINCHa",
      name: "xINCH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x8f6a193c8b3c949e1046f1547c3a3f0836944e4b/3627362268aa69888f5dfa535c3a796d.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8F041A3940a5e6FB580075C3774E15FcFA0E1618",
      symbol: "ONEWING",
      name: "oneWING",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1635752067919_7233413982445538.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x8EEdEFe828A0f16C8fc80e46a87Bc0f1De2d960c",
      symbol: "DGMV",
      name: "DigiMetaverse",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8eedefe828a0f16c8fc80e46a87bc0f1de2d960c/cba9d63eca21d1066713273c7f06c076.png",
      coingeckoId: "digimetaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8E870D67F660D95d5be530380D0eC0bd388289E1",
      symbol: "USDP",
      name: "USDP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/6013/small/Pax_Dollar.png?1629877204",
      coingeckoId: "paxos-standard",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x8E6cd950Ad6ba651F6DD608Dc70e5886B1AA6B24",
      symbol: "STARL",
      name: "STARL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/16824/small/ZxJbRWJ.png?1625177900",
      coingeckoId: "starlink",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x8E16bf47065Fe843A82f4399bAF5aBac4E0822B7",
      symbol: "BFIL",
      name: "Binance Wrapped FIL",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x8DD4228605e467671941Ffb4caE15cf7959C8D9D",
      symbol: "ZITI",
      name: "Ziticoin",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: "ziticoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8D75959f1E61EC2571aa72798237101F084DE63a",
      symbol: "SUB",
      name: "Substratum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8d75959f1e61ec2571aa72798237101f084de63a/8d2abfe8472aae4e90725c6b8ee501bf.png",
      coingeckoId: "substratum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8D3E855f3f55109D473735aB76F753218400fe96",
      symbol: "BUND",
      name: "Bundles",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8d3e855f3f55109d473735ab76f753218400fe96/8038780bc556359c655d667bc2441548.png",
      coingeckoId: "bundles",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9",
      symbol: "SXP",
      name: "Swipe",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9/85195ab6e5dfced6fd8e3acb19cc65df.png",
      coingeckoId: "swipe",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B",
      symbol: "ENVT",
      name: "NerveNetwork",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x8cd6e29d3686d24d3c2018cee54621ea0f89313b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x8CC0F052fff7eaD7f2EdCCcaC895502E884a8a71",
      symbol: "ARTH",
      name: "ARTH Valuecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8cc0f052fff7ead7f2edcccac895502e884a8a71/84a8456bc6522a69f80c2d7d245a5994.png",
      coingeckoId: "arth",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x8CB924583681cbFE487A62140a994A49F833c244",
      symbol: "SWAPP",
      name: "Swapp Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8cb924583681cbfe487a62140a994a49f833c244/1593f71afab9018fbf9a1927e41bb471.png",
      coingeckoId: "swapp",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x8C543AED163909142695f2d2aCd0D55791a9Edb9",
      symbol: "VLX",
      name: "VLX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8c543aed163909142695f2d2acd0d55791a9edb9/092b3a6751fab370133aa2478bb2251c.png",
      coingeckoId: "velas",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8B950f43fCAc4931D408F1fcdA55C6CB6cbF3096",
      symbol: "crBBADGER",
      name: "Cream Badger Sett Badger",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x8b950f43fcac4931d408f1fcda55c6cb6cbf3096.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8B86e0598616a8d4F1fdAE8b59E55FB5Bc33D0d6",
      symbol: "crLEND",
      name: "Cream EthLend Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x8b86e0598616a8d4f1fdae8b59e55fb5bc33d0d6.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8B660dA0F7C74d2464A19A3D67e202dcB6BCFDAF",
      symbol: "FREE",
      name: "Freedom",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8b660da0f7c74d2464a19a3d67e202dcb6bcfdaf/9ac3c732870508087aed877144ce489b.png",
      coingeckoId: "freedom-22-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8B39B70E39Aa811b69365398e0aACe9bee238AEb",
      symbol: "PKF",
      name: "PolkaFoundry",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8b39b70e39aa811b69365398e0aace9bee238aeb/558db9f6fd55442ee0cd8e07c12eaff9.png",
      coingeckoId: "polkafoundry",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8B3870Df408fF4D7C3A26DF852D41034eDa11d81",
      symbol: "IOI",
      name: "IOI Token ",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8b3870df408ff4d7c3a26df852d41034eda11d81/3b3547d922797db9f68f82294e82a0c6.png",
      coingeckoId: "ioi-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8B3192f5eEBD8579568A2Ed41E6FEB402f93f73F",
      symbol: "SAITAMA",
      name: "Saitama Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8b3192f5eebd8579568a2ed41e6feb402f93f73f/00902dc55a9e2accf47dc442e1214389.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x8Af38D3530bbd802ff2D132Ae361897Aca004ec1",
      symbol: "PLSA",
      name: "PulseApeCoin",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "pulseapecoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8Ab7404063Ec4DBcfd4598215992DC3F8EC853d7",
      symbol: "AKRO",
      name: "Akropolis",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7/46d71fd497d2e925c4a45ce2e85c9969.png",
      coingeckoId: "akropolis",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x8AF5FedC0f263841C18F31D9DbCC97A47e1aB462",
      symbol: "M87",
      name: "MESSIER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8af5fedc0f263841c18f31d9dbcc97a47e1ab462/f02b854ec6e80238a65ac42062ab176d.png",
      coingeckoId: "messier",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8A9c4dfe8b9D8962B31e4e16F8321C44d48e246E",
      symbol: "NCT",
      name: "NameChangeToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e/021c239577b25f89242ba95d801e1afc.png",
      coingeckoId: "name-changing-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8A9C67fee641579dEbA04928c4BC45F66e26343A",
      symbol: "JRT",
      name: "Jarvis Reward Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a9c67fee641579deba04928c4bc45f66e26343a/3862a982457e3bc9f44a8cf534b2c802.png",
      coingeckoId: "jarvis-reward-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5",
      symbol: "BLID",
      name: "Bolide",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a7adc1b690e81c758f1bd0f72dfe27ae6ec56a5/29d8c16759ad58e5017e8cc8266b69d7.png",
      coingeckoId: "bolide",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8A65B987D9813f0a97446eDa0dE918b2573Ae406",
      symbol: "FomoETH",
      name: "FomoETH",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a65b987d9813f0a97446eda0de918b2573ae406/74c350fe7bbf6538a6ccab82d481caeb.png",
      coingeckoId: "fomoeth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2",
      symbol: "CHR",
      name: "Chromia",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2/38616097a4bfae1990a2b0cfc3749211.png",
      coingeckoId: "chromaway",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x8A0cdfaB62eD35b836DC0633482798421C81b3Ec",
      symbol: "SPHRI",
      name: "Spherium Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8a0cdfab62ed35b836dc0633482798421c81b3ec/fe3bb40b0c4594513a68c331a44e66ec.png",
      coingeckoId: "spherium",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359",
      symbol: "SAI",
      name: "Sai Stablecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359/4b3369d1f8d2024c0f3dd46a3347d84d.png",
      coingeckoId: "sai",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x89bD2E7e388fAB44AE88BEf4e1AD12b4F1E0911c",
      symbol: "NUX",
      name: "NUX Peanut.trade",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c/f4ff8ff67e0bdcc25e8328e12960208d.png",
      coingeckoId: "peanut",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x89B69F2d1adffA9A253d40840B6Baa7fC903D697",
      symbol: "Dione",
      name: "Dione",
      decimals: 9,
      chainId: "1",
      logoURI: null,
      coingeckoId: "dione",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x89Ab32156e46F46D02ade3FEcbe5Fc4243B9AAeD",
      symbol: "PNT",
      name: "pNetwork Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed/fb2f4297a9d9d42e2889459bd54b640b.png",
      coingeckoId: "pnetwork",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x896e145568624a498c5a909187363AE947631503",
      symbol: "WASABI",
      name: "Wasabi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x896e145568624a498c5a909187363AE947631503/logo.png",
      coingeckoId: "wasabix",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x8947da500Eb47F82df21143D0C01A29862a8C3c5",
      symbol: "THALES",
      name: "Thales",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8947da500eb47f82df21143d0c01a29862a8c3c5/0cc85ecb36d87b46ab7ce957e9b1bdd2.png",
      coingeckoId: "thales",
      listedIn: ["coingecko", "optimism", "lifinance"],
    },
    {
      address: "0x893700A1a86EE68B92536bf6fd4d3200d7369F7d",
      symbol: "EMT",
      name: "Emanate",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x893700a1a86ee68b92536bf6fd4d3200d7369f7d/ef54daafb1ddf0db9e5ff4a7c54c21d5.png",
      coingeckoId: "emanate",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x892A6f9dF0147e5f079b0993F486F9acA3c87881",
      symbol: "xFUND",
      name: "unification.com/xfund",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x892a6f9df0147e5f079b0993f486f9aca3c87881/0b9ed9d350b21ddf6cfaf52f2e2cac93.png",
      coingeckoId: "xfund",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0",
      symbol: "TRB",
      name: "Tellor",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0/d0a6887fce34243d63aa3986e458aca6.png",
      coingeckoId: "tellor",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x88ACDd2a6425c3FaAE4Bc9650Fd7E27e0Bebb7aB",
      symbol: "⚗️",
      name: "Alchemist",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab/bd99a167b17de42b302a00d8ec78f32f.png",
      coingeckoId: "alchemist",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x88A9A52F944315D5B4e917b9689e65445C401E83",
      symbol: "FEAR",
      name: "FearNFTs",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x88a9a52f944315d5b4e917b9689e65445c401e83/78dba9cce97ba14ee9692dfb22400a04.png",
      coingeckoId: "fear",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x888888888889C00c67689029D7856AAC1065eC11",
      symbol: "OPIUM",
      name: "Opium Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x888888888889c00c67689029d7856aac1065ec11/db08913463e82dfa280fbe1e436c75af.png",
      coingeckoId: "opium",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x888888848B652B3E3a0f34c96E00EEC0F3a23F72",
      symbol: "TLM",
      name: "Alien Worlds Trilium",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x888888848b652b3e3a0f34c96e00eec0f3a23f72/1daf788bf002987965cce25d5bb04b53.png",
      coingeckoId: "alien-worlds",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x888888435FDe8e7d4c54cAb67f206e4199454c60",
      symbol: "DFX",
      name: "DFX Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x888888435fde8e7d4c54cab67f206e4199454c60/eaad30c2bb118c5cfee13eac513aa1a8.png",
      coingeckoId: "dfx-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8888801aF4d980682e47f1A9036e589479e835C5",
      symbol: "MPH",
      name: "88mph.app",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8888801af4d980682e47f1a9036e589479e835c5/32058e0e8684004754562c90f458e744.png",
      coingeckoId: "88mph",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x88536C9B2C4701b8dB824e6A16829D5B5Eb84440",
      symbol: "USV",
      name: "Universal Store of Value",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x88536c9b2c4701b8db824e6a16829d5b5eb84440/b79b4f5fff5665519190e8f3e44dba1f.png",
      coingeckoId: "atlas-usv",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x87eDfFDe3E14c7a66c9b9724747a1C5696b742e6",
      symbol: "SWAG",
      name: "Swag Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x87edffde3e14c7a66c9b9724747a1c5696b742e6/aaf608ad11e048bd8ce26d0552f9bb0f.png",
      coingeckoId: "swag-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x87d73E916D7057945c9BcD8cdd94e42A6F47f776",
      symbol: "NFTX",
      name: "NFTX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776/1109c8134c3ae06d51247229b913caeb.png",
      coingeckoId: "nftx",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x87DE305311D5788e8da38D19bb427645b09CB4e5",
      symbol: "VRX",
      name: "Verox",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x87de305311d5788e8da38d19bb427645b09cb4e5/6ea629614522209de642f88434946989.png",
      coingeckoId: "verox",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272",
      symbol: "XSUSHI",
      name: "XSUSHI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13725/small/xsushi.png?1612538526",
      coingeckoId: "xsushi",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x87761e886399EF8e1624cb0DB3230B075a322c88",
      symbol: "CBK",
      name: "CBK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x87761e886399ef8e1624cb0db3230b075a322c88/be7e699840b8946b2d4666a0596cd5cf.png",
      coingeckoId: "crossing-the-yellow-blocks",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8762db106B2c2A0bccB3A80d1Ed41273552616E8",
      symbol: "RSR",
      name: "Reserve Rights",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8762db106b2c2a0bccb3a80d1ed41273552616e8/cde6fabb74d850d70cc3cdcaa0bd2050.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x875773784Af8135eA0ef43b5a374AaD105c5D39e",
      symbol: "IDLE",
      name: "Idle",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x875773784af8135ea0ef43b5a374aad105c5d39e/73a948a4c2d8287403fde600aca15bfe.png",
      coingeckoId: "idle",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x86ed939B500E121C0C5f493F399084Db596dAd20",
      symbol: "SPC",
      name: "SpaceChainV2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x86ed939b500e121c0c5f493f399084db596dad20/fd719327a116eaba580436525480e14e.png",
      coingeckoId: "spacechain-erc-20",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x86D49fbD3B6f989d641E700a15599d3b165002AB",
      symbol: "HUH",
      name: "HUH Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x86d49fbd3b6f989d641e700a15599d3b165002ab/70e8e7fc7b3a9b9b1c820cb73bfb7005.png",
      coingeckoId: "huh",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x86772b1409b61c639EaAc9Ba0AcfBb6E238e5F83",
      symbol: "NDX",
      name: "Indexed",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x86772b1409b61c639eaac9ba0acfbb6e238e5f83/a8401dfb7adf3b7fe7852631657db43a.png",
      coingeckoId: "indexed-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x865377367054516e17014CcdED1e7d814EDC9ce4",
      symbol: "DOLA",
      name: "DolaUSDStablecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x865377367054516e17014ccded1e7d814edc9ce4/cf89c85a0a6b374247c072fe3131bacb.png",
      coingeckoId: "dola-usd",
      listedIn: ["coingecko", "sushiswap", "optimism", "lifinance"],
    },
    {
      address: "0x8642A849D0dcb7a15a974794668ADcfbe4794B56",
      symbol: "PROS",
      name: "Prosper",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8642a849d0dcb7a15a974794668adcfbe4794b56/7bc739c1d729a13444069cf0232d1592.png",
      coingeckoId: "prosper",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x85c4EdC43724e954e5849cAAab61A26a9CB65F1B",
      symbol: "BBCH",
      name: "Binance Wrapped BCH",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x85Ffb35957203dfD12061eAeCD708dB623Bd567C",
      symbol: "LTY",
      name: "Ledgity",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x85ffb35957203dfd12061eaecd708db623bd567c/7580f6330ff629247cf4cbee9d84084f.png",
      coingeckoId: "ledgity",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC",
      symbol: "KEEP",
      name: "KEEP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/3373/small/IuNzUb5b_400x400.jpg?1589526336",
      coingeckoId: "keep-network",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x85759961b116f1D36fD697855c57A6ae40793D9B",
      symbol: "cr1INCH",
      name: "Cream 1INCH Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x85759961b116f1d36fd697855c57a6ae40793d9b.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8564653879a18C560E7C0Ea0E084c516C62F5653",
      symbol: "UBXT",
      name: "UpBots",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8564653879a18c560e7c0ea0e084c516c62f5653/53209be1be725407b17421d66eafe5f8.png",
      coingeckoId: "upbots",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
      symbol: "FRAX",
      name: "FRAX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
      coingeckoId: "frax",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x853Bb55c1f469902F088A629db8C8803A9BE3857",
      symbol: "one1INCH",
      name: "Stable 1INCH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x853bb55c1f469902f088a629db8c8803a9be3857.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x850aAB69f0e0171A9a49dB8BE3E71351c8247Df4",
      symbol: "KONO",
      name: "Konomi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x850aab69f0e0171a9a49db8be3e71351c8247df4/8a059b3860189b1ffddd377d38ca34d1.png",
      coingeckoId: "konomi-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x84f20BF5bB4Be345D3ab37c565f732753435dBe3",
      symbol: "JCR",
      name: "JustCarbon Removal Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x84f20bf5bb4be345d3ab37c565f732753435dbe3/558bace14d4227b34ef4bbd895c4ddd5.png",
      coingeckoId: "justcarbon-removal",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x84d821F7FbDD595c4C4A50842913e6b1E07d7a53",
      symbol: "BNPL",
      name: "BNPL Pay",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x84d821F7FbDD595c4C4A50842913e6b1E07d7a53/logo.png",
      coingeckoId: "bnpl-pay",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419",
      symbol: "DIA",
      name: "DIA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419/ee123495208aa381707ced77731aee2b.png",
      coingeckoId: "dia-data",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x849bA2278cdAe7fA7006c0661FEA1c35D5Af3336",
      symbol: "TheCitadel",
      name: "The Citadel",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x849ba2278cdae7fa7006c0661fea1c35d5af3336/0ce850b12e6886fda961a4c6bc92ce1b.png",
      coingeckoId: "the-citadel",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x845576c64f9754CF09d87e45B720E82F3EeF522C",
      symbol: "AVT",
      name: "ArtVerse Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x845576c64f9754cf09d87e45b720e82f3eef522c/5650930565ee571bff5531e4401df860.png",
      coingeckoId: "artverse-token",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x841FB148863454A3b3570f515414759BE9091465",
      symbol: "SHIH",
      name: "Shih Tzu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x841fb148863454a3b3570f515414759be9091465/160c6cdb111e2a4f18f2201529c9bd49.png",
      coingeckoId: "shih-tzu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e",
      symbol: "UBT",
      name: "UniBright",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e/7870ecec05b6354d1cd165aa7c2c5357.png",
      coingeckoId: "unibright",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x83f798e925BcD4017Eb265844FDDAbb448f1707D",
      symbol: "yUSDTv2",
      name: "iearn USDT v2",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x83f798e925bcd4017eb265844fddabb448f1707d/a151c8cd48494726f2b090d013449c57.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa",
      symbol: "POLS",
      name: "Polkastarter",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa/62e5ecbec09c7b75df09c7f1140d1ee1.png",
      coingeckoId: "polkastarter",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x83cee9e086A77e492eE0bB93C2B0437aD6fdECCc",
      symbol: "MNTP",
      name: "Goldmint",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc/28450d730ab1eaecf472cfe5024ce4cf.png",
      coingeckoId: "goldmint",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x83aD87C988aC0c6277C0c6234Cc8108b20bB5d9B",
      symbol: "LINKBULL",
      name: "3X Long Chainlink Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "3x-long-chainlink-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x83E9F223e1edb3486F876EE888d76bFba26c475A",
      symbol: "GUILD",
      name: "GUILD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/21271/small/BednjMw.png?1638857799",
      coingeckoId: "blockchainspace",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x83984d6142934bb535793A82ADB0a46EF0F66B6d",
      symbol: "REM",
      name: "Remme",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x83984d6142934bb535793a82adb0a46ef0f66b6d/e67c493574d71e48788e0f689908e1d1.png",
      coingeckoId: "remme",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x83869DE76B9Ad8125e22b857f519F001588c0f62",
      symbol: "EXM",
      name: "EXMOCoin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x83869de76b9ad8125e22b857f519f001588c0f62/9e174a2c0d64b8da49851c19c1e17a60.png",
      coingeckoId: "exmo-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x833850BE8858722Cfc5E5e75f2Fe6275E055d888",
      symbol: "DBNB",
      name: "DBNB",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/26544/small/Screenshot_2022-07-22_at_18.16.11.png?1658735503",
      coingeckoId: "decentrabnb",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x83249c6794BCa5a77Eb8c0AF9F1A86E055459cea",
      symbol: "GIGA",
      name: "GigaSwap",
      decimals: 9,
      chainId: "1",
      logoURI: null,
      coingeckoId: "gigaswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x831091dA075665168E01898c6DAC004A867f1e1B",
      symbol: "GFARM2",
      name: "Gains Farm v2",
      decimals: 18,
      chainId: "1",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/8444.png",
      coingeckoId: "gains-farm",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4",
      symbol: "ANKR",
      name: "Ankr",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8290333cef9e6d528dd5618fb97a76f268f3edd4/a691e4d17894d78b5661798664c03712.png",
      coingeckoId: "ankr",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x8287C7b963b405b7B8D467DB9d79eEC40625b13A",
      symbol: "SWINGBY",
      name: "SWINGBY token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8287c7b963b405b7b8d467db9d79eec40625b13a/09fd8df17b299b743127b515bcbf5e1b.png",
      coingeckoId: "swingby",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8254e26e453EB5aBd29B3c37AC9E8Da32E5d3299",
      symbol: "RBX",
      name: "RBX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299/3aed4825210cc889170be83c65fff5ad.png",
      coingeckoId: "rbx-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8254C1C134436F74047F79eaAeA97E3324eF78B5",
      symbol: "Ichigo",
      name: "Ichigo Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8254c1c134436f74047f79eaaea97e3324ef78b5/404ba00480186024f638f291bdb37633.png",
      coingeckoId: "ichigo-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x824E35f7A75324f99300aFAC75ECF7354E17Ea26",
      symbol: "TIA",
      name: "Tia Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x824e35f7a75324f99300afac75ecf7354e17ea26/e991d925c900bf99ba092af9dc6f3b49.png",
      coingeckoId: "tia",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x823556202e86763853b40e9cDE725f412e294689",
      symbol: "ASTO",
      name: "Altered State Machine Utility Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x823556202e86763853b40e9cde725f412e294689/023b8022f27399eedd15e5fbdef8ee42.png",
      coingeckoId: "altered-state-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x821144518dfE9e7b44fCF4d0824e15e8390d4637",
      symbol: "ATIS",
      name: "ATIS Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x821144518dfe9e7b44fcf4d0824e15e8390d4637.png",
      coingeckoId: "atlantis-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26",
      symbol: "OGN",
      name: "OriginToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26/7ccd363b711aedd460177e742d18b25a.png",
      coingeckoId: "origin-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x81f8f0bb1cB2A06649E51913A151F0E7Ef6FA321",
      symbol: "VITA",
      name: "VitaDAO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x81f8f0bb1cb2a06649e51913a151f0e7ef6fa321/ed46cc2757ddd792be9ec3b7218ef336.png",
      coingeckoId: "vitadao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8185Bc4757572Da2a610f887561c32298f1A5748",
      symbol: "ALN",
      name: "Aluna",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8185bc4757572da2a610f887561c32298f1a5748/6dbfcefd91657ec5d6603859550cc894.png",
      coingeckoId: "aluna",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x817bbDbC3e8A1204f3691d14bB44992841e3dB35",
      symbol: "CUDOS",
      name: "CudosToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x817bbdbc3e8a1204f3691d14bb44992841e3db35/42c4a61a7b6fb94f7fd007dff9ca4ba0.png",
      coingeckoId: "cudos",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26",
      symbol: "MDT",
      name: "Measurable Data Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26/69c04afdddc8da4238b883eccc287289.png",
      coingeckoId: "measurable-data-token",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x80fB784B7eD66730e8b1DBd9820aFD29931aab03",
      symbol: "LEND",
      name: "EthLend Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x80fb784b7ed66730e8b1dbd9820afd29931aab03/b2ee8803bdf692142a7a1445e75fb366.png",
      coingeckoId: "ethlend",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x80c8C3dCfB854f9542567c8Dac3f44D709eBc1de",
      symbol: "MILK2",
      name: "MilkyWay Token by SpaceSwap v2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de/f053a085f2b25404766b48f203ed0067.png",
      coingeckoId: "spaceswap-milk2",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x80D55c03180349Fff4a229102F62328220A96444",
      symbol: "OPUL",
      name: "OpulousToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x80d55c03180349fff4a229102f62328220a96444/fa1e449202480b6cacdcad4f777876e3.png",
      coingeckoId: "opulous",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x80C62FE4487E1351b47Ba49809EBD60ED085bf52",
      symbol: "CLV",
      name: "Clover Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x80c62fe4487e1351b47ba49809ebd60ed085bf52/123520cb273bf8689b9124f7ae745254.png",
      coingeckoId: "clover-finance",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x808507121B80c02388fAd14726482e061B8da827",
      symbol: "PENDLE",
      name: "Pendle",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x808507121b80c02388fad14726482e061b8da827/b9351f830cd0a6457e489b8c685f29ad.png",
      coingeckoId: "pendle",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68",
      symbol: "oBTC",
      name: "BoringDAO BTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/obtc.jpg",
      coingeckoId: "boringdao-btc",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x803e78269f7F013b7D13ba13243Be10C66418a70",
      symbol: "$NODAC",
      name: "Node Aggregator Capital",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x803e78269f7f013b7d13ba13243be10c66418a70/1c4ada48a0cf75811eddc72f11c5f01f.png",
      coingeckoId: "node-aggregator-capital",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8037b1B69d6fA63a9cc053c25f7e168e6e6d857A",
      symbol: "P4C",
      name: "Parts of Four Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x8037b1b69d6fa63a9cc053c25f7e168e6e6d857a/de537fd4e42abd21eda5d16cfb37de76.png",
      coingeckoId: "parts-of-four-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7fC3eC3574d408F3b59CD88709baCb42575EBF2b",
      symbol: "POP!",
      name: "POP!",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7fc3ec3574d408f3b59cd88709bacb42575ebf2b/1aa6823582fe125f017291111a56a137.png",
      coingeckoId: "pop",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
      symbol: "wstETH",
      name: "Wrapped liquid staked Ether 2.0",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0/7e931af8cb34b6f5671ca2eb1b847849.png",
      coingeckoId: "wrapped-steth",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x7f3141c4D6b047fb930991b450f1eD996a51CB26",
      symbol: "X",
      name: "X",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/22334/small/x.PNG?1641519663",
      coingeckoId: "x-2",
      listedIn: ["coingecko", "sushiswap", "xyfinance"],
    },
    {
      address: "0x7f280daC515121DcdA3EaC69eB4C13a52392CACE",
      symbol: "FNC",
      name: "Fancy Games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7f280dac515121dcda3eac69eb4c13a52392cace/76f0715e74a90ad9761a4587058af3ec.png",
      coingeckoId: "fancy-games",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x7f1F2D3dFa99678675ECE1C243d3f7bC3746db5D",
      symbol: "TAP",
      name: "Tapmydata",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d/ed84a8cc0f5b9ce8ff05bfacd1f9187e.png",
      coingeckoId: "tapmydata",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7eaF9C89037e4814DC0d9952Ac7F888C784548DB",
      symbol: "ROYA",
      name: "Royale",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7eaf9c89037e4814dc0d9952ac7f888c784548db/a8a49ec7bf0ac5d857363be72d0a7aab.png",
      coingeckoId: "royale",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7ea9C63E216D5565c3940A2B3d150e59C2907Db3",
      symbol: "crBBTC",
      name: "Cream Binance Wrapped BTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x7ea9c63e216d5565c3940a2b3d150e59c2907db3.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x7e7E112A68d8D2E221E11047a72fFC1065c38e1a",
      symbol: "bDIGG",
      name: "Badger Sett Digg",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/bdigg.jpg",
      coingeckoId: "badger-sett-digg",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x7e794eD35788b698AE60cefC98eE48015C4876dA",
      symbol: "SHINTAMA",
      name: "Shintama",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7e794ed35788b698ae60cefc98ee48015c4876da/d521c8dfa8337dae7d6d76589f720d22.png",
      coingeckoId: "shintama",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7deB5e830be29F91E298ba5FF1356BB7f8146998",
      symbol: "aMKR",
      name: "Aave Interest bearing MKR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x7deb5e830be29f91e298ba5ff1356bb7f8146998/81ed7ed6c1a2f1d3fc46ceea225e9ea9.png",
      coingeckoId: "aave-mkr-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac",
      symbol: "VOLT",
      name: "Volt Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac/e4b5c0f4242972530978e93707134a42.png",
      coingeckoId: "volt-inu-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7dE91B204C1C737bcEe6F000AAA6569Cf7061cb7",
      symbol: "XRT",
      name: "Robonomics",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7de91b204c1c737bcee6f000aaa6569cf7061cb7/c09479c7e5670e0666fdcb997bb64c76.png",
      coingeckoId: "robonomics-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7d4B1d793239707445305D8d2456D2c735F6B25B",
      symbol: "cBSN",
      name: "BSNcommunitynet",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7d4b1d793239707445305d8d2456d2c735f6b25b/3a01f9dbe46e793f3be864eb2b2c8407.png",
      coingeckoId: "blockswap-network",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x7cA4408137eb639570F8E647d9bD7B7E8717514A",
      symbol: "ALPA",
      name: "AlpaToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7ca4408137eb639570f8e647d9bd7b7e8717514a/f30b18aefceaf30b35b1d9b9c2c6d476.png",
      coingeckoId: "alpaca",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x7c8155909cd385F120A56eF90728dD50F9CcbE52",
      symbol: "NII",
      name: "Nahmii",
      decimals: 15,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7c8155909cd385f120a56ef90728dd50f9ccbe52/be04c34085cbae7b2df6505c05579cbf.png",
      coingeckoId: "nahmii",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x7b3D36Eb606f873A75A6aB68f8c999848B04F935",
      symbol: "LOOT",
      name: "NFTLootBox.com",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7b3d36eb606f873a75a6ab68f8c999848b04f935/0d249af166b5b47af3c2c44b58563063.png",
      coingeckoId: "nftlootbox",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7b39917f9562C8Bc83c7a6c2950FF571375D505D",
      symbol: "LEAG",
      name: "LeagueDAO Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x7b39917f9562C8Bc83c7a6c2950FF571375D505D/logo.png",
      coingeckoId: "leaguedao-governance-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x7b35Ce522CB72e4077BaeB96Cb923A5529764a00",
      symbol: "IMX",
      name: "Impermax",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7b35ce522cb72e4077baeb96cb923a5529764a00/c67947913bb597c205d28706322dc74a.png",
      coingeckoId: "impermax",
      listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
    },
    {
      address: "0x7b123f53421b1bF8533339BFBdc7C98aA94163db",
      symbol: "buidl_1",
      name: "dfohub",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7b123f53421b1bf8533339bfbdc7c98aa94163db/d729a187f78cc6b00af52b1cd3a0982c.png",
      coingeckoId: "dfohub",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7aE7ACC3B3A9679EAe7e4F7495eAd1429ac7dE7E",
      symbol: "Archa",
      name: "Archangel",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7ae7acc3b3a9679eae7e4f7495ead1429ac7de7e/d2988a2908d0e5c8d69063ad6808eb6f.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x7aE1D57b58fA6411F32948314BadD83583eE0e8C",
      symbol: "PAPER",
      name: "Paper",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x7ae1d57b58fa6411f32948314badd83583ee0e8c/logo.png",
      coingeckoId: "dope-wars-paper",
      listedIn: ["coingecko", "optimism", "rubic"],
    },
    {
      address: "0x7aE0d42f23C33338dE15bFa89C7405c068d9dC0a",
      symbol: "VERSE",
      name: "Shibaverse",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7ae0d42f23c33338de15bfa89c7405c068d9dc0a/ae6a5f7e10521cc374849536024d4412.png",
      coingeckoId: "shibaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7a5d3A9Dcd33cb8D527f7b5F96EB4Fef43d55636",
      symbol: "RADIO",
      name: "Radio Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x7a5d3A9Dcd33cb8D527f7b5F96EB4Fef43d55636/logo.png",
      coingeckoId: "radioshack",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x7a5ce6abD131EA6B148a022CB76fc180ae3315A6",
      symbol: "bALPHA",
      name: "bAlpha",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6/b7f5668938abc310c6e95e3df517d545.png",
      coingeckoId: "balpha",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7a2Bc711E19ba6aff6cE8246C546E8c4B4944DFD",
      symbol: "WAXE",
      name: "WAX Economic Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd/6cb2cf9c342444e16eaeea224a2fbaa9.png",
      coingeckoId: "waxe",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
      symbol: "AAVE",
      name: "AaveToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9/logo.png",
      coingeckoId: "aave",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x7FF4169a6B5122b664c51c95727d87750eC07c84",
      symbol: "10SET",
      name: "10Set Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7ff4169a6b5122b664c51c95727d87750ec07c84/5a011d534b0a57109e80baa8c6557547.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x7F3EDcdD180Dbe4819Bd98FeE8929b5cEdB3AdEB",
      symbol: "XTK",
      name: "xToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb/455d06c2c5cd11323bb3d75abb9a1136.png",
      coingeckoId: "xtoken",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x7F0693074F8064cFbCf9fA6E5A3Fa0e4F58CcCCF",
      symbol: "gm",
      name: "we love gm",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x7F0693074F8064cFbCf9fA6E5A3Fa0e4F58CcCCF.jpg",
      coingeckoId: "we-love-gm",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x7Ef5ac1FE8B6832EbAddEE1856E4A21E46843565",
      symbol: "DOI",
      name: "DOI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x7ef5ac1fe8b6832ebaddee1856e4a21e46843565.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429",
      symbol: "GLM",
      name: "Golem",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429/bc52becc7685b632ee3ced3b46b5cff4.png",
      coingeckoId: "golem",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x7D2D3688Df45Ce7C552E19c27e007673da9204B8",
      symbol: "aLEND",
      name: "Aave Interest bearing LEND",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x7d2d3688df45ce7c552e19c27e007673da9204b8/edf8008dbbe6772b668f4b39102cc616.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
      coingeckoId: "matic-network",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x7C84e62859D0715eb77d1b1C4154Ecd6aBB21BEC",
      symbol: "SHPING",
      name: "Shping",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec/e6a7ecf8cc5eddb5d926258b1423af79.png",
      coingeckoId: "shping",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098",
      symbol: "SAN",
      name: "SANtiment network token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098/9f3aada4f10ce117773da5efd0100bdf.png",
      coingeckoId: "santiment-network-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7C07F7aBe10CE8e33DC6C5aD68FE033085256A84",
      symbol: "icETH",
      name: "Interest Compounding ETH Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84.png",
      coingeckoId: "interest-compounding-eth-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7BEF710a5759d197EC0Bf621c3Df802C2D60D848",
      symbol: "SHOPX",
      name: "SPLYT SHOPX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7bef710a5759d197ec0bf621c3df802c2d60d848/041ccaaddc6d942ff0fdae97b9a78e3e.png",
      coingeckoId: "splyt",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7BD82B320EbC28D8EB3C4F5Fa2af7B14dA5b90C3",
      symbol: "MOZ",
      name: "MOZ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7bd82b320ebc28d8eb3c4f5fa2af7b14da5b90c3/e2bfcf58946679bfa3ece0a97c80782a.png",
      coingeckoId: "mozik",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x7B0C06043468469967DBA22d1AF33d77d44056c8",
      symbol: "MRPH",
      name: "Morpheus Network",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x7b0c06043468469967dba22d1af33d77d44056c8/f838b8312c8873179566a81f62b5967f.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x7Aaa323D7e398be4128c7042d197a2545f0f1fea",
      symbol: "crOMG",
      name: "Cream OMGToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x7aaa323d7e398be4128c7042d197a2545f0f1fea.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71",
      symbol: "PEOPLE",
      name: "PEOPLE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/20612/small/GN_UVm3d_400x400.jpg?1637294355",
      coingeckoId: "constitutiondao",
      listedIn: ["coingecko", "openocean", "lifinance", "xyfinance"],
    },
    {
      address: "0x79C75E2e8720B39e258F41c37cC4f309E0b0fF80",
      symbol: "SOUL",
      name: "Phantasma Stake",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x79c75e2e8720b39e258f41c37cc4f309e0b0ff80/9b37e15b5e19f9b39f05b8b98753b9f8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x799ebfABE77a6E34311eeEe9825190B9ECe32824",
      symbol: "BTRST",
      name: "BTRST",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x799ebfabe77a6e34311eeee9825190b9ece32824/e6385202dd2bd0c1b0b3d28cb5bc459b.png",
      coingeckoId: "braintrust",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x798D1bE841a82a273720CE31c822C61a67a601C3",
      symbol: "DIGG",
      name: "Digg",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x798d1be841a82a273720ce31c822c61a67a601c3/7cabce8afe82a9041c1f1d2b0b49cfb2.png",
      coingeckoId: "digg",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x797de1Dc0b9fAf5E25c1f7Efe8dF9599138fA09d",
      symbol: "GPOOL",
      name: "Genesis Pool",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x797de1dc0b9faf5e25c1f7efe8df9599138fa09d/logo.png",
      coingeckoId: "genesis-pool",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x797AAB1ce7c01eB727ab980762bA88e7133d2157",
      symbol: "crUSDT",
      name: "Cream USDT",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x797aab1ce7c01eb727ab980762ba88e7133d2157.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x7968bc6a03017eA2de509AAA816F163Db0f35148",
      symbol: "HGET",
      name: "Hedget",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7968bc6a03017ea2de509aaa816f163db0f35148/79dc6e3f916526c451a20c08038b443c.png",
      coingeckoId: "hedget",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x794Baab6b878467F93EF17e2f2851ce04E3E34C8",
      symbol: "YIN",
      name: "YIN Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x794baab6b878467f93ef17e2f2851ce04e3e34c8/e8e8c4edf4a3930fe19ebc3bb0bb3df4.png",
      coingeckoId: "yin-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x78F225869c08d478c34e5f645d07A87d3fe8eb78",
      symbol: "DEFI+L",
      name: "PieDAO DEFI Large Cap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x78F225869c08d478c34e5f645d07A87d3fe8eb78/logo.png",
      coingeckoId: "piedao-defi-large-cap",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x7866E48C74CbFB8183cd1a929cd9b95a7a5CB4F4",
      symbol: "KIT",
      name: "DexKit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4/3e93e5cd250943ffe00554d20516e61f.png",
      coingeckoId: "dexkit",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7865af71cf0b288b4E7F654f4F7851EB46a2B7F8",
      symbol: "SNTVT",
      name: "Sentivate",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8/9a1fd7f1d2e41200aa30fbdb26ff038f.png",
      coingeckoId: "sentivate",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7825e833D495F3d1c28872415a4aee339D26AC88",
      symbol: "TLOS",
      name: "pTokens TLOS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7825e833d495f3d1c28872415a4aee339d26ac88/046a3ebcf5bee0e284e151f8e3a9efc7.png",
      coingeckoId: "wrapped-telos",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x7815bDa662050D84718B988735218CFfd32f75ea",
      symbol: "YEL",
      name: "YEL Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7815bda662050d84718b988735218cffd32f75ea/bdd34d4d180d012589ecde14100031c5.png",
      coingeckoId: "yel-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x780116D91E5592E58a3b3c76A351571b39abCEc6",
      symbol: "BOXX",
      name: "Blockparty",
      decimals: 15,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x780116d91e5592e58a3b3c76a351571b39abcec6.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x77f9CF0bd8C500CfFdF420e72343893aeCC2EC0b",
      symbol: "LAIKA",
      name: "LAIKA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x77f9cf0bd8c500cffdf420e72343893aecc2ec0b/c02d3bce3c2168bdce18e88fc1b1c098.png",
      coingeckoId: "laika",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x77f973FCaF871459aa58cd81881Ce453759281bC",
      symbol: "fiETH",
      name: "bZx ETH iToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x77f973fcaf871459aa58cd81881ce453759281bc.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x77FbA179C79De5B7653F68b5039Af940AdA60ce0",
      symbol: "FORTH",
      name: "Ampleforth Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x77fba179c79de5b7653f68b5039af940ada60ce0/a35c07393018dd6be9b1c141cf50da19.png",
      coingeckoId: "ampleforth-governance-token",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x7778360F035C589fCE2f4eA5786CbD8B36e5396B",
      symbol: "OOE",
      name: "OpenOcean",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7778360f035c589fce2f4ea5786cbd8b36e5396b/82124d68da604f900e236dd424e54343.png",
      coingeckoId: "openocean",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x77777FeDdddFfC19Ff86DB637967013e6C6A116C",
      symbol: "TORN",
      name: "TORN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13496/small/ZINt8NSB_400x400.jpg?1609193407",
      coingeckoId: "tornado-cash",
      listedIn: ["coingecko", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x7777777777697cFEECF846A76326dA79CC606517",
      symbol: "SIG",
      name: "xSigma",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7777777777697cfeecf846a76326da79cc606517/ad6888254ebbb795d260f6a4d8ac5e1a.png",
      coingeckoId: "xsigma",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x77777777772cf0455fB38eE0e75f38034dFa50DE",
      symbol: "XY",
      name: "XY",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622",
      coingeckoId: "xy-finance",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x7728cd70b3dD86210e2bd321437F448231B81733",
      symbol: "NIFTSY",
      name: "NIFTSY",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7728cd70b3dd86210e2bd321437f448231b81733/252f18e4322d55cab8971dde7e386a1d.png",
      coingeckoId: "niftsy",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x77252494C25444F8598A0c74Ffc90ADc535291a9",
      symbol: "NMS",
      name: "Nemesis",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x77252494c25444f8598a0c74ffc90adc535291a9/logo.png",
      coingeckoId: "nemesis",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a",
      symbol: "BT",
      name: "BT.Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a/034cf59bf06b0d186cf8d9f426f10e6f.png",
      coingeckoId: "bt-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7697B462A7c4Ff5F8b55BDBC2F4076c2aF9cF51A",
      symbol: "SARCO",
      name: "Sarcophagus",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a/95c186fba99854ffc99982cd945f30f3.png",
      coingeckoId: "sarcophagus",
      listedIn: ["coingecko", "1inch", "optimism", "lifinance"],
    },
    {
      address: "0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E",
      symbol: "ILV",
      name: "ILV",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14468/small/ILV.JPG?1617182121",
      coingeckoId: "illuvium",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x7659CE147D0e714454073a5dd7003544234b6Aa0",
      symbol: "XCAD",
      name: "XCAD Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7659ce147d0e714454073a5dd7003544234b6aa0/9b4466426dd0d8db02f9b419292b8d86.png",
      coingeckoId: "xcad-network",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x7645DdfEecedA57e41f92679c4aCd83c56A81D14",
      symbol: "FLUX",
      name: "Flux Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7645ddfeeceda57e41f92679c4acd83c56a81d14/b022bf3863f4c5175ebe22ea741164bc.png",
      coingeckoId: "flux-protocol",
      listedIn: ["coingecko", "openocean", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x76417E660DF3E5c90C0361674C192da152A806E4",
      symbol: "zUSD",
      name: "Zerogoki USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x76417e660df3e5c90c0361674c192da152a806e4.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3",
      symbol: "ELON",
      name: "Dogelon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3/d90f70a0da65834eab14a70f7423db19.png",
      coingeckoId: "dogelon-mars",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x75F0038B8fbfCCAFe2aB9a51431658871bA5182C",
      symbol: "ATOMBULL",
      name: "3X Long Cosmos Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "3x-long-cosmos-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x75Ecb52e403C617679FBd3e77A50f9d10A842387",
      symbol: "CSR",
      name: "CSR",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "csr",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x75D12E4F91Df721faFCae4c6cD1d5280381370AC",
      symbol: "MYOBU",
      name: "Myōbu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x75d12e4f91df721fafcae4c6cd1d5280381370ac.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x758B4684BE769E92eeFeA93f60DDA0181eA303Ec",
      symbol: "PHONON",
      name: "Phonon DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x758b4684be769e92eefea93f60dda0181ea303ec/3c793e2690e132792de0ced3538c63c6.png",
      coingeckoId: "phonon-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x75231F58b43240C9718Dd58B4967c5114342a86c",
      symbol: "OKB",
      name: "OKB",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x75231f58b43240c9718dd58b4967c5114342a86c/b58d6980429c56560d9241765bdf9c2b.png",
      coingeckoId: "okb",
      listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x74B988156925937bD4E082f0eD7429Da8eAea8Db",
      symbol: "MEME",
      name: "MEME Inu.",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x74b988156925937bd4e082f0ed7429da8eaea8db/b039d8c65a3649e35c106eae158269c3.png",
      coingeckoId: "meme-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x747f564d258612ec5c4E24742C5fd4110bCbe46B",
      symbol: "NDS",
      name: "Nodeseeds.com Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x747f564d258612ec5c4e24742c5fd4110bcbe46b/70e83a01dfe9e72da65629b643a55eeb.png",
      coingeckoId: "nodeseeds",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7475C42f8BF2c19F4EAF12fEaabaBA859fdC8914",
      symbol: "ACCEL",
      name: "ACCEL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7475c42f8bf2c19f4eaf12feaababa859fdc8914/343b31873989849ab48a61acb837671d.png",
      coingeckoId: "accel-defi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E",
      symbol: "SNT",
      name: "Status",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x744d70fdbe2ba4cf95131626614a1763df805b9e/37c6fcc8da4c168db37d189fdd0e4535.png",
      coingeckoId: "status",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC",
      symbol: "JASMY",
      name: "JasmyCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7420b4b9a0110cdc71fb720908340c03f9bc03ec/cb7bc7f945fa52e698ed4f283f73afe0.png",
      coingeckoId: "jasmycoin",
      listedIn: ["coingecko", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x7409856CAE628f5d578B285B45669b36E7005283",
      symbol: "jGBP",
      name: "Jarvis Synthetic British Pound",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/jgbp.jpg",
      coingeckoId: "jarvis-synthetic-british-pound",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x73d7c860998CA3c01Ce8c808F5577d94d545d1b4",
      symbol: "IXS",
      name: "Ixs Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x73d7c860998ca3c01ce8c808f5577d94d545d1b4/4420cb8e241eba6cddf9928849f770a8.png",
      coingeckoId: "ix-swap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef",
      symbol: "BTE",
      name: "Betero",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x73b708e84837ffccde2933e3a1531fe61d5e80ef/1b5d1a1363131af5b85e42049e8d7762.png",
      coingeckoId: "betero",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x73A83269b9bbAFC427E76Be0A2C1a1db2a26f4C2",
      symbol: "0NE",
      name: "Stone",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x73a83269b9bbafc427e76be0a2c1a1db2a26f4c2/4b81510943b1f779ad130ff4baa3d1d9.png",
      coingeckoId: "civfund-stone",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x739763a258640919981F9bA610AE65492455bE53",
      symbol: "NDR",
      name: "NodeRunners",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x739763a258640919981f9ba610ae65492455be53/13b0bb5eb36ce7df7dff0bb727a84ba2.png",
      coingeckoId: "noderunners",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x73968b9a57c6E53d41345FD57a6E6ae27d6CDB2F",
      symbol: "SDT",
      name: "Stake DAO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f/5ac1b916b988e5fb33379d09b44245bb.png",
      coingeckoId: "stake-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x738865301A9b7Dd80Dc3666dD48cF034ec42bdDa",
      symbol: "AGRS",
      name: "Agoras Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x738865301a9b7dd80dc3666dd48cf034ec42bdda/5affcc8d1a59db702452c5997068392a.png",
      coingeckoId: "agoras-currency-of-tau",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x737F98AC8cA59f2C68aD658E3C3d8C8963E40a4c",
      symbol: "AMN",
      name: "Amon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c/76b56b6a65e3d74756e4a96cefd184ce.png",
      coingeckoId: "amon",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x734C90044a0bA31B3F2e640c10dC5d3540499Bfd",
      symbol: "TSX",
      name: "TSX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x734c90044a0ba31b3f2e640c10dc5d3540499bfd/6664735a0195e7eef0d223dac880d7bc.png",
      coingeckoId: "tradestars",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x73484A262730d1d422610729e828346F9B2ff480",
      symbol: "BRCP",
      name: "BRCP",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "brcp-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x73374Ea518De7adDD4c2B624C0e8B113955ee041",
      symbol: "JGN",
      name: "Juggernaut DeFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x73374ea518de7addd4c2b624c0e8b113955ee041/a6a2f422625135833ca2c7489fae1dbc.png",
      coingeckoId: "juggernaut",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x72e9D9038cE484EE986FEa183f8d8Df93f9aDA13",
      symbol: "SMARTCREDIT",
      name: "SMARTCREDIT Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72e9d9038ce484ee986fea183f8d8df93f9ada13/67439b51e0f3d3650dedb6d46aeccaee.png",
      coingeckoId: "smartcredit-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x72e364F2ABdC788b7E918bc238B21f109Cd634D7",
      symbol: "MVI",
      name: "Metaverse Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72e364f2abdc788b7e918bc238b21f109cd634d7/26a3fde3e7a9d53db96e2c8daa6d67e3.png",
      coingeckoId: "metaverse-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x72F020f8f3E8fd9382705723Cd26380f8D0c66Bb",
      symbol: "PLOT",
      name: "PLOT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb/ec31b784ade2e7f620d01fa2917f57ed.png",
      coingeckoId: "plotx",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x72E5390EDb7727E3d4e3436451DADafF675dBCC0",
      symbol: "HANU",
      name: "Hanu Yokia",
      decimals: 12,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72e5390edb7727e3d4e3436451dadaff675dbcc0/e9ca33ca1545543c506c624d6d19904a.png",
      coingeckoId: "hanu-yokia",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x72B886d09C117654aB7dA13A14d603001dE0B777",
      symbol: "XDEFI",
      name: "XDEFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72b886d09c117654ab7da13a14d603001de0b777/8453cf22c41f66f58955156d79f6fa6f.png",
      coingeckoId: "xdefi",
      listedIn: ["coingecko", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x72630B1e3B42874bf335020Ba0249e3E9e47Bafc",
      symbol: "EPAN",
      name: "Paypolitan Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x72630b1e3b42874bf335020ba0249e3e9e47bafc/3fae44e4140ba9cdc3941d9294c51255.png",
      coingeckoId: "paypolitan-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x725C263e32c72dDC3A19bEa12C5a0479a81eE688",
      symbol: "BMI",
      name: "Bridge Mutual",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x725c263e32c72ddc3a19bea12c5a0479a81ee688/90ae6053c05094ade6eed8fccd6dbda1.png",
      coingeckoId: "bridge-mutual",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x724dD18Be5De3ed3D6aD7Bb46D7387d867cDBdcc",
      symbol: "AVAK",
      name: "AVAKUS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x724dd18be5de3ed3d6ad7bb46d7387d867cdbdcc/logo.png",
      coingeckoId: "avakus",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x7240aC91f01233BaAf8b064248E80feaA5912BA3",
      symbol: "OCTO",
      name: "Octo.fi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7240ac91f01233baaf8b064248e80feaa5912ba3/c6ca7cb27754d01c60efe7c676dab563.png",
      coingeckoId: "octofi",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x72377f31e30a405282b522d588AEbbea202b4f23",
      symbol: "VRN",
      name: "Varen",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x72377f31e30a405282b522d588AEbbea202b4f23/logo.png",
      coingeckoId: "varen",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x721A1B990699eE9D90b6327FaaD0A3E840aE8335",
      symbol: "LOOT",
      name: "LOOT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/22895/small/loot.png?1642850037",
      coingeckoId: "loot",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x71fc860F7D3A592A4a98740e39dB31d25db65ae8",
      symbol: "aUSDT",
      name: "Aave Interest bearing USDT",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x71fc860f7d3a592a4a98740e39db31d25db65ae8/57564da75601d8986fa807bb815f78c2.png",
      coingeckoId: "aave-usdt-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x71dc40668682a124231301414167E4CF7f55383C",
      symbol: "MIMIR",
      name: "Mimir Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x71dc40668682a124231301414167e4cf7f55383c/ac12ad4b2addfca328c41ead7ec624f5.png",
      coingeckoId: "mimir-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x71a28feAEe902966DC8D355e7B8Aa427D421e7e0",
      symbol: "LUNCH",
      name: "LunchDao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x71a28feaee902966dc8d355e7b8aa427d421e7e0/0906611635eb0689931c30ef87d758d0.png",
      coingeckoId: "lunchdao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x71Fc1F555a39E0B698653AB0b475488EC3c34D57",
      symbol: "RAIN",
      name: "Rainmaker Games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x71fc1f555a39e0b698653ab0b475488ec3c34d57/logo.png",
      coingeckoId: "rainmaker-games",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x71Ab77b7dbB4fa7e017BC15090b2163221420282",
      symbol: "HIGH",
      name: "Highstreet",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x71ab77b7dbb4fa7e017bc15090b2163221420282/15303468e57cdd60cb5d313685a7a2aa.png",
      coingeckoId: "highstreet",
      listedIn: ["coingecko", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x7162469321ae5880F077D250B626F3271b21b903",
      symbol: "KSW",
      name: "KillSwitchToken",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "killswitch",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x712DB54daA836B53Ef1EcBb9c6ba3b9Efb073F40",
      symbol: "aENJ",
      name: "Aave Interest bearing ENJ",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aENJ.svg",
      coingeckoId: "aave-enj-v1",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7121D00b4fA18F13Da6c2e30d19C04844E6AfDC8",
      symbol: "LUFFY",
      name: "LUFFY",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7121d00b4fa18f13da6c2e30d19c04844e6afdc8/72d556dd374906aef6310e6287f4a868.png",
      coingeckoId: "luffy-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7118057ff0F4Fd0994fb9d2D94de8231d5cca79E",
      symbol: "SOURCE",
      name: "Source",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7118057ff0f4fd0994fb9d2d94de8231d5cca79e/834489d742cc7da5bb387b464a4396cb.png",
      coingeckoId: "resource-protocol",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x71010A9D003445aC60C4e6A7017c1E89A477B438",
      symbol: "aREP",
      name: "Aave Interest bearing REP",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aREP.svg",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x70e8dE73cE538DA2bEEd35d14187F6959a8ecA96",
      symbol: "XSGD",
      name: "XSGD",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x70e8de73ce538da2beed35d14187f6959a8eca96/ff149cce106e05c9271f55866f905a29.png",
      coingeckoId: "xsgd",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x70da48f4B7e83c386ef983D4CEF4e58c2c09D8Ac",
      symbol: "XQC",
      name: "Quras Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x70da48f4b7e83c386ef983d4cef4e58c2c09d8ac/9c2109316e333c4e0b5bda286d0d24e1.png",
      coingeckoId: "quras-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x70D2b7C19352bB76e4409858FF5746e500f2B67c",
      symbol: "UPI",
      name: "Pawtocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x70d2b7c19352bb76e4409858ff5746e500f2b67c/ba4a972154261fb5169f3334f3027fa2.png",
      coingeckoId: "pawtocol",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x70Bef3bB2f001dA2fDDb207dAe696cD9FAFf3f5d",
      symbol: "NST",
      name: "Ninja Squad Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x70Bef3bB2f001dA2fDDb207dAe696cD9FAFf3f5d.jpg",
      coingeckoId: "ninja-squad",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x7051faED0775f664a0286Af4F75ef5ed74e02754",
      symbol: "CHANGE",
      name: "ChangeX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x7051faed0775f664a0286af4f75ef5ed74e02754/9818159f06723310dc2b62a1c481d48b.png",
      coingeckoId: "changex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x70401dFD142A16dC7031c56E862Fc88Cb9537Ce0",
      symbol: "BIRD",
      name: "Bird.Money",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x70401dfd142a16dc7031c56e862fc88cb9537ce0/1cf31eeabcfa2b98fd2b059dcfc20e67.png",
      coingeckoId: "bird-money",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x6fe56C0bcdD471359019FcBC48863d6c3e9d4F41",
      symbol: "PROPS",
      name: "Props Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41/551762f2c6e70640159cecbe0c8a33da.png",
      coingeckoId: "props",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x6fC13EACE26590B80cCCAB1ba5d51890577D83B2",
      symbol: "UMB",
      name: "Umbrella",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6fc13eace26590b80cccab1ba5d51890577d83b2/c93a6de95494ac8f110bc04a3075c40e.png",
      coingeckoId: "umbrella-network",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69",
      symbol: "IOTX",
      name: "IoTeX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6fb3e0a217407efff7ca062d46c26e5d60a14d69/da1ebfd88ed7f0c1e59c738c672c37d1.png",
      coingeckoId: null,
      listedIn: ["1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb",
      symbol: "INST",
      name: "Instadapp",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6f40d4a6237c257fff2db00fa0510deeecd303eb/dab656bf20db2351a9467d074b16a37e.png",
      coingeckoId: "instadapp",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6f259637dcD74C767781E37Bc6133cd6A68aa161",
      symbol: "HT",
      name: "HuobiToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6f259637dcd74c767781e37bc6133cd6a68aa161/da0c6de050a3f0ad903ff3b2475f56a7.png",
      coingeckoId: "huobi-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x6e9730EcFfBed43fD876A264C982e254ef05a0DE",
      symbol: "NORD",
      name: "Nord Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6e9730ecffbed43fd876a264c982e254ef05a0de/e386edc6f99d3988116f1eb7b5125917.png",
      coingeckoId: "nord-finance",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0x6e1A19F235bE7ED8E3369eF73b196C07257494DE",
      symbol: "WFIL",
      name: "Wrapped Filecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6e1a19f235be7ed8e3369ef73b196c07257494de/b5df8fb9729e06e574986295172b2ae9.png",
      coingeckoId: "wrapped-filecoin",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x6e0daDE58D2d89eBBe7aFc384e3E4f15b70b14D8",
      symbol: "QRX",
      name: "QuiverX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8/acf44e8bebbd11a4cfe0a5ef415b9705.png",
      coingeckoId: "quiverx",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6cAcDB97e3fC8136805a9E7c342d866ab77D0957",
      symbol: "SWPR",
      name: "Swapr",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6cacdb97e3fc8136805a9e7c342d866ab77d0957/e6debf3894e93abb3aee3a4efb2480a2.png",
      coingeckoId: "swapr",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x6c972b70c533E2E045F333Ee28b9fFb8D717bE69",
      symbol: "FRY",
      name: "Foundry Logistics Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x6c972b70c533e2e045f333ee28b9ffb8d717be69/ee46944ffa37bcc8fc54b25c7f39c851.png",
      coingeckoId: "foundrydao-logistics",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6c6EE5e31d828De241282B9606C8e98Ea48526E2",
      symbol: "HOT",
      name: "HoloToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c6ee5e31d828de241282b9606c8e98ea48526e2/4b92285f601767417001b1bd30cf90c2.png",
      coingeckoId: "holotoken",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x6c5bA91642F10282b576d91922Ae6448C9d52f4E",
      symbol: "PHA",
      name: "Phala",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c5ba91642f10282b576d91922ae6448c9d52f4e/6a240fa26e3306ec02bda1068b281437.png",
      coingeckoId: "pha",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490",
      symbol: "3Crv",
      name: "Curve.fi DAI/USDC/USDT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c3f90f043a72fa612cbac8115ee7e52bde6e490/ae73a75547c237febeeaee87f1c9627f.png",
      coingeckoId: "lp-3pool-curve",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6c37Bf4f042712C978A73e3fd56D1F5738dD7C43",
      symbol: "ELET",
      name: "Elementeum Games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43/9bdf83551b480a1d4ae0c73bb9d39d2d.png",
      coingeckoId: "ether-legends",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x6c28AeF8977c9B773996d0e8376d2EE379446F2f",
      symbol: "QUICK",
      name: "Quickswap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c28aef8977c9b773996d0e8376d2ee379446f2f/3f40a8915d99b9dd0e24d5205c89eb34.png",
      coingeckoId: "quick",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x6b785a0322126826d8226d77e173d75DAfb84d11",
      symbol: "VLT",
      name: "Bankroll Vault",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6b785a0322126826d8226d77e173d75dafb84d11/28a3f330957133a107c79cb65b475ede.png",
      coingeckoId: "bankroll-vault",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6a4FFAafa8DD400676Df8076AD6c724867b0e2e8",
      symbol: "bDAI",
      name: "BTU Incentivized DAI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x6a4ffaafa8dd400676df8076ad6c724867b0e2e8.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f",
      symbol: "aZRX",
      name: "Aave Interest bearing ZRX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f/d439d697efca3034d12c722fe26cf79c.png",
      coingeckoId: "aave-zrx-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6FCE4A401B6B80ACe52baAefE4421Bd188e76F6f",
      symbol: "aMANA",
      name: "Aave Interest bearing MANA",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aMANA.svg",
      coingeckoId: "aave-mana-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6F87D756DAf0503d08Eb8993686c7Fc01Dc44fB1",
      symbol: "TRADE",
      name: "UniTrade",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6f87d756daf0503d08eb8993686c7fc01dc44fb1/5ecdeb0662a9746b67886ec660ea2347.png",
      coingeckoId: "unitrade",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6F620EC89B8479e97A6985792d0c64F237566746",
      symbol: "WPC",
      name: "WePiggy Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6f620ec89b8479e97a6985792d0c64f237566746/a3ba1eaa1b461b8c027ae423c3d5d2da.png",
      coingeckoId: "wepiggy-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6F59e0461Ae5E2799F1fB3847f05a63B16d0DbF8",
      symbol: "ORCA",
      name: "ORCA Alliance",
      decimals: 18,
      chainId: "1",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/5183.png",
      coingeckoId: null,
      listedIn: ["uniswap", "lifinance"],
    },
    {
      address: "0x6Ee0f7BB50a54AB5253dA0667B0Dc2ee526C30a8",
      symbol: "aBUSD",
      name: "Aave Interest bearing Binance USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8/9395bfc99afb5ec2b946186c2938a899.png",
      coingeckoId: "aave-busd-v1",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6E98E5401ADCB0D76F4DEBfc3d794B3031F48790",
      symbol: "AUR",
      name: "Aurix Exchange",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14372/small/gold-brown-2.png",
      coingeckoId: "aurix",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6E765D26388A17A6e86c49A8E41DF3F58aBcd337",
      symbol: "KANGAL",
      name: "Kangal",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6e765d26388a17a6e86c49a8e41df3f58abcd337/ef953ae1c14c180e122d6d660067ed01.png",
      coingeckoId: "kangal",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24",
      symbol: "RNDR",
      name: "Render Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24/4b87d5a2c2012e350a19b6996ae4eac0.png",
      coingeckoId: "render-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D",
      symbol: "LQTY",
      name: "Liquity",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d/ec240e97a3cf4cc20ac6823a034edc03.png",
      coingeckoId: "liquity",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x6DC02164d75651758aC74435806093E421b64605",
      symbol: "WCHI",
      name: "Wrapped CHI",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6dc02164d75651758ac74435806093e421b64605/a5dc1fc4b76813cc619195af72d975a4.png",
      coingeckoId: "chimaera",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x6D0F5149c502faf215C89ab306ec3E50b15e2892",
      symbol: "PRT",
      name: "Portion Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6d0f5149c502faf215c89ab306ec3e50b15e2892/220d9d5833d3d3cff17b3be68fd4d63f.png",
      coingeckoId: "portion",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6C8c6b02E7b2BE14d4fA6022Dfd6d75921D90E4E",
      symbol: "cBAT",
      name: "Compound Basic Attention Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e/9cc3da00870c526634cb90793e63213c.png",
      coingeckoId: "compound-basic-attention-token",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x6C5024Cd4F8A59110119C56f8933403A539555EB",
      symbol: "aSUSD",
      name: "Aave interest bearing SUSD",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aSUSD.svg",
      coingeckoId: "aave-susd",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6BeA7CFEF803D1e3d5f7C0103f7ded065644e197",
      symbol: "GAMMA",
      name: "Gamma",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6bea7cfef803d1e3d5f7c0103f7ded065644e197/0d9bdc8e0d6ed24c24fc9cfe04804eb2.png",
      coingeckoId: "gamma-strategies",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6Bba316c48b49BD1eAc44573c5c871ff02958469",
      symbol: "GAS",
      name: "Gas DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6bba316c48b49bd1eac44573c5c871ff02958469/a92b2618a5acb6c9d8017451bd74ce13.png",
      coingeckoId: "gas-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x6Ba460AB75Cd2c56343b3517ffeBA60748654D26",
      symbol: "UP",
      name: "UpToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6ba460ab75cd2c56343b3517ffeba60748654d26/8653368ae8883657036c927ff45a6f3b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x6BFf2fE249601ed0Db3a87424a2E923118BB0312",
      symbol: "FYZ",
      name: "FYOOZ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6bff2fe249601ed0db3a87424a2e923118bb0312/ebf23927cc63267b88640c067dab14cd.png",
      coingeckoId: "fyooz",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6B9f031D718dDed0d681c20cB754F97b3BB81b78",
      symbol: "GEEQ",
      name: "Geeq",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6b9f031d718dded0d681c20cb754f97b3bb81b78/85842f83c3ee48ea181331e79efcb180.png",
      coingeckoId: "geeq",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6B4c7A5e3f0B99FCD83e9c089BDDD6c7FCe5c611",
      symbol: "MM",
      name: "Million",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611/52cb0f908d9449c8b2fda33887c9e7f7.png",
      coingeckoId: "million",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
      symbol: "SUSHI",
      name: "SUSHI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688",
      coingeckoId: "sushi",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      symbol: "DAI",
      name: "Dai Stablecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
      coingeckoId: "dai",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x6B0b3a982b4634aC68dD83a4DBF02311cE324181",
      symbol: "ALI",
      name: "Alethea Artificial Liquid Intelligence",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6b0b3a982b4634ac68dd83a4dbf02311ce324181/83aff65a741bccb549e7debbfe6be5be.png",
      coingeckoId: "alethea-artificial-liquid-intelligence-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6ADb2E268de2aA1aBF6578E4a8119b960E02928F",
      symbol: "ShibDoge",
      name: "ShibaDoge",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6adb2e268de2aa1abf6578e4a8119b960e02928f/da3fff778afafcfee8eff15be384a33e.png",
      coingeckoId: "shibadoge",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x6A7Ef4998eB9d0f706238756949F311a59E05745",
      symbol: "KEN",
      name: "Kenysians Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6a7ef4998eb9d0f706238756949f311a59e05745/5a94d3487e21961f364d29e158b2acde.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B",
      symbol: "IBY",
      name: "I Bet You",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B/logo.png",
      coingeckoId: "ibetyou",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51",
      symbol: "NRCH",
      name: "EnreachDAO",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51/08dd5c7add08307df23c28ee35838043.png",
      coingeckoId: "enreachdao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x69fa0feE221AD11012BAb0FdB45d444D3D2Ce71c",
      symbol: "XRUNE",
      name: "XRUNE Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x69fa0fee221ad11012bab0fdb45d444d3d2ce71c/6df552a7ef3a27e80e9e37889ec44217.png",
      coingeckoId: "thorstarter",
      listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x69e8b9528CABDA89fe846C67675B5D73d463a916",
      symbol: "OPEN",
      name: "BBB",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x69e8b9528cabda89fe846c67675b5d73d463a916/f0a00cf265cb47243528123e6a71d160.png",
      coingeckoId: "open-governance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074",
      symbol: "MASK",
      name: "MASK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14051/small/Mask_Network.jpg?1614050316",
      coingeckoId: "mask-network",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "optimism",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23",
      symbol: "POOLZ",
      name: "$Poolz Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x69a95185ee2a045cdc4bcd1b1df10710395e4e23/2ca046dab94e49a56ecac84a7fe868f3.png",
      coingeckoId: "poolz-finance",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x69948cC03f478B95283F7dbf1CE764d0fc7EC54C",
      symbol: "aREN",
      name: "Aave Interest bearing REN",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmUgE3UECZxZcCAiqd3V9otfFWLi5fxR8uHd94RxkT3iYb",
      coingeckoId: "aave-ren-v1",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x698C6aC9CA5f16cAbC5a636D3a619329c0958cBA",
      symbol: "Saja",
      name: "Saja",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x698c6ac9ca5f16cabc5a636d3a619329c0958cba/dd9d3a17498d2a96214a60c037a8e038.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x697256CAA3cCaFD62BB6d3Aa1C7C5671786A5fD9",
      symbol: "crLINK",
      name: "Cream ChainLink Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x697256caa3ccafd62bb6d3aa1c7c5671786a5fd9.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x69570f3E84f51Ea70b7B68055c8d667e77735a25",
      symbol: "BSGG",
      name: "Betswap.gg",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x69570f3e84f51ea70b7b68055c8d667e77735a25/c913f2408f06f47727596b2ac34aae93.png",
      coingeckoId: "betswap-gg",
      listedIn: ["coingecko", "sushiswap", "RouterProtocol", "lifinance"],
    },
    {
      address: "0x692eb773E0b5B7A79EFac5A015C8b36A2577F65c",
      symbol: "SWISS",
      name: "Swiss Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x692eb773e0b5b7a79efac5a015c8b36a2577f65c/ed8e36eaaf9ef7c8f3bb858a3af1f966.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d",
      symbol: "SNP",
      name: "Synapse Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d/5f75a4ecb6252f88e5c19f37597b2c6f.png",
      coingeckoId: "synapse-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x68d57c9a1C35f63E2c83eE8e49A64e9d70528D25",
      symbol: "SRN",
      name: "Sirin Labs",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25/c10ed63ce494f994728677de3347d444.png",
      coingeckoId: "sirin-labs-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x68a3637bA6E75c0f66B61A42639c4e9fCD3D4824",
      symbol: "MOON",
      name: "MoonToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824/a45c803105b3630e02c4c22aefb50d67.png",
      coingeckoId: "moonswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6810e776880C02933D47DB1b9fc05908e5386b96",
      symbol: "GNO",
      name: "GNO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/662/small/logo_square_simple_300px.png?1609402668",
      coingeckoId: "gnosis",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x68037790A0229e9Ce6EaA8A99ea92964106C4703",
      symbol: "PAR",
      name: "PAR Stablecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x68037790a0229e9ce6eaa8a99ea92964106c4703/3141498258ab923e325c2b7d0483bd31.png",
      coingeckoId: "par-stablecoin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x67c597624B17b16fb77959217360B7cD18284253",
      symbol: "MARK",
      name: "Benchmark",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x67c597624b17b16fb77959217360b7cd18284253/ddbb23aa58eedb6d807807af4fd9b5ba.png",
      coingeckoId: "benchmark-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x67B6D479c7bB412C54e03dCA8E1Bc6740ce6b99C",
      symbol: "KYL",
      name: "Kylin Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x67b6d479c7bb412c54e03dca8e1bc6740ce6b99c/6b220323d6a7a6d953cb4144435eacdd.png",
      coingeckoId: "kylin-network",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x6781a0F84c7E9e846DCb84A9a5bd49333067b104",
      symbol: "ZAP",
      name: "ZAP TOKEN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6781a0f84c7e9e846dcb84a9a5bd49333067b104/ff98c754ae3fbb8c3024711fa54187a9.png",
      coingeckoId: "zap",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x677ddbd918637E5F2c79e164D402454dE7dA8619",
      symbol: "VUSD",
      name: "VUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x677ddbd918637e5f2c79e164d402454de7da8619/7aabef038e8d5ae941f8b25f1420621a.png",
      coingeckoId: "vesper-vdollar",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x675BBC7514013E2073DB7a919F6e4cbeF576de37",
      symbol: "CLS",
      name: "Coldstack",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x675bbc7514013e2073db7a919f6e4cbef576de37/0cfb2093ee64729d4dfd1fc3c0c2dc39.png",
      coingeckoId: "coldstack",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6758B7d441a9739b98552B373703d8d3d14f9e62",
      symbol: "POA20",
      name: "POA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6758b7d441a9739b98552b373703d8d3d14f9e62/508ddd9bbdeb748a8ded80b5ac9e7465.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x674C6Ad92Fd080e4004b2312b45f796a192D27a0",
      symbol: "USDN",
      name: "Neutrino",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x674c6ad92fd080e4004b2312b45f796a192d27a0/beda07bd72e258be42261ba059d12b67.png",
      coingeckoId: "neutrino",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x6710c63432A2De02954fc0f851db07146a6c0312",
      symbol: "MFG",
      name: "SyncFab Smart Manufacturing Blockchain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6710c63432a2de02954fc0f851db07146a6c0312/5364606165e74b69f7fcfaa58666a233.png",
      coingeckoId: "smart-mfg",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x66fD97a78d8854fEc445cd1C80a07896B0b4851f",
      symbol: "LMY",
      name: "Lunch Money",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x66fd97a78d8854fec445cd1c80a07896b0b4851f/611a51b96e8e45bd3078603f24c77737.png",
      coingeckoId: "lunch-money",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x66d28cb58487a7609877550E1a34691810A6b9FC",
      symbol: "KOIN",
      name: "Koinos",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x66d28cb58487a7609877550e1a34691810a6b9fc/68398bf0e165d8b0205465a8a62c873e.png",
      coingeckoId: "koinos",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
      symbol: "WISE",
      name: "Wise Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6/0a485f060fe0e2040a01efbb63cd09e0.png",
      coingeckoId: "wise-token11",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x66F73D0fD4161cfad4302DC145Ff994375c13475",
      symbol: "DXGM",
      name: "DEXGame",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x66f73d0fd4161cfad4302dc145ff994375c13475/acf510ff33f8f6952ecea1d607dd8650.png",
      coingeckoId: "dex-game",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x66C0DDEd8433c9EA86C8cf91237B14e10b4d70B7",
      symbol: "Mars",
      name: "MarsToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7/0ea7c0d36b164136bc17e33b1e67b257.png",
      coingeckoId: "mars",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x667088b212ce3d06a1b553a7221E1fD19000d9aF",
      symbol: "WINGS",
      name: "Wings",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x667088b212ce3d06a1b553a7221e1fd19000d9af/f020211763ae30a8fc0043e8b6f8a470.png",
      coingeckoId: "wings",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x661Ab0Ed68000491d98C796146bcF28c20d7c559",
      symbol: "DOWS",
      name: "Shadows Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x661ab0ed68000491d98c796146bcf28c20d7c559/7573a51f8ee3d7b6b8d2ce9a667f1afe.png",
      coingeckoId: "shadows",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6619078Bdd8324E01E9a8D4b3d761b050E5ECF06",
      symbol: "MAL",
      name: "My Alpha Leaderboard",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x65d9bC970aA9B2413027fA339F7f179B3F3f2604",
      symbol: "KUN",
      name: "QIAN governance token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x65d9bc970aa9b2413027fa339f7f179b3f3f2604/3923d84f0ec86fd16f5295c59c38e5e8.png",
      coingeckoId: "chemix-ecology-governance-token",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x65cCD72c0813CE6f2703593B633202a0F3Ca6a0c",
      symbol: "EGG",
      name: "Nestree",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x65ccd72c0813ce6f2703593b633202a0f3ca6a0c/be20b289df5b6755e86bd23bdab88d5f.png",
      coingeckoId: "nestree",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4",
      symbol: "MULTI",
      name: "Multichain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x65ef703f5594d2573eb71aaf55bc0cb548492df4/bf6b4c0d299a4588f5af3a398b0a5171.png",
      coingeckoId: "multichain",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436",
      symbol: "KAE",
      name: "Kanpeki",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x65def5029a0e7591e46b38742bfedd1fb7b24436/90c80258ddef1352d809bc9b837da18e.png",
      coingeckoId: "kanpeki",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x6595b8fD9C920C81500dCa94e53Cdc712513Fb1f",
      symbol: "OLY",
      name: "Olyseum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6595b8fd9c920c81500dca94e53cdc712513fb1f/756fc5e5e503de6b4b5696114fa63623.png",
      coingeckoId: "olyverse",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x65883978aDA0e707c3b2BE2A6825b1C4BDF76A90",
      symbol: "crAKRO",
      name: "Cream Akropolis",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x65883978ada0e707c3b2be2a6825b1c4bdf76a90.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x656C00e1BcD96f256F224AD9112FF426Ef053733",
      symbol: "EFI",
      name: "Efinity Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x656c00e1bcd96f256f224ad9112ff426ef053733/48040fac60b2c0fe56272e8778bb0214.png",
      coingeckoId: "efinity",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x64aa3364F17a4D01c6f1751Fd97C2BD3D7e7f1D5",
      symbol: "OHM",
      name: "OHM",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14483/small/token_OHM_%281%29.png?1628311611",
      coingeckoId: "olympus",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x64D91f12Ece7362F91A6f8E7940Cd55F05060b92",
      symbol: "ASH",
      name: "ASH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x64d91f12ece7362f91a6f8e7940cd55f05060b92/7762dda273303fd90599884815ec2f52.png",
      coingeckoId: "ash",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x64A77277e37D44957FE5815d6FF442ab8b16cC29",
      symbol: "DAWGS",
      name: "SpaceDawgs",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17076/large/SPACEDAWGS_Token_Logo.png",
      coingeckoId: "spacedawgs",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x64A60493D888728Cf42616e034a0dfEAe38EFCF0",
      symbol: "OLT",
      name: "Oneledger Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "one-ledger",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6468e79A80C0eaB0F9A2B574c8d5bC374Af59414",
      symbol: "eXRD",
      name: "E-RADIX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6468e79a80c0eab0f9a2b574c8d5bc374af59414/2e02f4e196ecbfdc48156256092ea745.png",
      coingeckoId: "e-radix",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437",
      symbol: "GAME",
      name: "GAME Credits",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x63f88a2298a5c4aee3c216aa6d926b184a4b2437/9a57ddf175e976d2588ea7db0346979a.png",
      coingeckoId: "gamecredits",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4",
      symbol: "CFi",
      name: "CyberFi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4/cb97eb7aba4f3cd10cac18d88fa8fcd0.png",
      coingeckoId: "cyberfi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x6399C842dD2bE3dE30BF99Bc7D1bBF6Fa3650E70",
      symbol: "PREMIA",
      name: "Premia",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70/698b24cad50862710bb9802f0053f1ce.png",
      coingeckoId: "premia",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x638F24096fF33e047E05E59fabc76325207090dc",
      symbol: "SLIME",
      name: "SlimeCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x638f24096ff33e047e05e59fabc76325207090dc/e81341a18a0515020f89ef4cff203d1c.png",
      coingeckoId: "squishiverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x638155F4BD8F85d401Da32498D8866eE39A150B8",
      symbol: "JREX",
      name: "Jurasaur",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: "jurasaur",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x632806BF5c8f062932Dd121244c9fbe7becb8B48",
      symbol: "PDI",
      name: "Phuture DeFi Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x632806bf5c8f062932dd121244c9fbe7becb8b48/9db0c3a71ebce5807d815d00280d0fb3.png",
      coingeckoId: "phuture-defi-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78",
      symbol: "PEAK",
      name: "PEAKDEFI",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x630d98424efe0ea27fb1b3ab7741907dffeaad78/5041884fa975d5aa7e60caf9095d7388.png",
      coingeckoId: "marketpeak",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A",
      symbol: "DHV",
      name: "DeHive.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x62dc4817588d53a056cbbd18231d91ffccd34b2a/3dc5afb23cd358bfd56d33d7e7033c36.png",
      coingeckoId: "dehive",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7",
      symbol: "cvxCRV",
      name: "Convex CRV",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7/5e741769b495f4861fcc3a5fb0d7f630.png",
      coingeckoId: "convex-crv",
      listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x62A8C2818BD7034DC24CD22368C3E53E8eB47c18",
      symbol: "INNIT",
      name: "InnitForTheTECH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x62a8c2818bd7034dc24cd22368c3e53e8eb47c18/5f88be24ab88922b887720c3138cd6f4.png",
      coingeckoId: "innitforthetech",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x62959c699A52eC647622c91e79CE73344e4099f5",
      symbol: "DFA",
      name: "DeFine Art",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x62959c699a52ec647622c91e79ce73344e4099f5/eab42674315a907288a1aa0fc0ae4712.png",
      coingeckoId: "define",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x626E8036dEB333b408Be468F951bdB42433cBF18",
      symbol: "AIOZ",
      name: "AIOZ Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x626e8036deb333b408be468f951bdb42433cbf18/d15952f26829122575c8acd0fa79db19.png",
      coingeckoId: "aioz-network",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x625aE63000f46200499120B906716420bd059240",
      symbol: "aSUSD",
      name: "Aave Interest bearing SUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x625ae63000f46200499120b906716420bd059240/57315e003745fef3e3e58155455d104d.png",
      coingeckoId: "aave-susd-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6251E725CD45Fb1AF99354035a414A2C0890B929",
      symbol: "MXT",
      name: "MixTrust",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6251e725cd45fb1af99354035a414a2c0890b929/0d878c4e68abd5abf8653fe05cb7a145.png",
      coingeckoId: "mixtrust",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x6243d8CEA23066d098a15582d81a598b4e8391F4",
      symbol: "FLX",
      name: "Flex Ungovernance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6243d8cea23066d098a15582d81a598b4e8391f4/55fbf4bf0c28b54b35d6af34e00d8742.png",
      coingeckoId: "reflexer-ungovernance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x62359Ed7505Efc61FF1D56fEF82158CcaffA23D7",
      symbol: "CORE",
      name: "cVault.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x62359ed7505efc61ff1d56fef82158ccaffa23d7/01b02ffcce6595c057b99720fa71dcf7.png",
      coingeckoId: "cvault-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x6226e00bCAc68b0Fe55583B90A1d727C14fAB77f",
      symbol: "MTV",
      name: "MultiVAC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6226e00bcac68b0fe55583b90a1d727c14fab77f/65810154804271375340b91078e72ef2.png",
      coingeckoId: "multivac",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6226caA1857AFBc6DFB6ca66071Eb241228031A1",
      symbol: "LAR",
      name: "Linkart",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6226caa1857afbc6dfb6ca66071eb241228031a1/f872ffa745918877d30306064ce66315.png",
      coingeckoId: "linkart",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x618679dF9EfCd19694BB1daa8D00718Eacfa2883",
      symbol: "XYZ",
      name: "XYZ Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x618679df9efcd19694bb1daa8d00718eacfa2883/9701aa092cb557a6e22440e850e20cab.png",
      coingeckoId: "universe-xyz",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x616ef40D55C0D2c506f4d6873Bda8090b79BF8fC",
      symbol: "KTO",
      name: "Kounotori",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc/16039fd6503fd8125cfd3ea959551c78.png",
      coingeckoId: "kounotori",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x616e8BfA43F920657B3497DBf40D6b1A02D4608d",
      symbol: "auraBAL",
      name: "Aura BAL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x616e8bfa43f920657b3497dbf40d6b1a02d4608d/cdbb661394aa44b11b06983c4dda9bb1.png",
      coingeckoId: "aura-bal",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x614D7f40701132E25fe6fc17801Fbd34212d2Eda",
      symbol: "BLAST",
      name: "SafeBLAST",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x614d7f40701132e25fe6fc17801fbd34212d2eda/9aa0f0866a2bd417953f7f29500ec1c1.png",
      coingeckoId: "safeblast",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6149C26Cd2f7b5CCdb32029aF817123F6E37Df5B",
      symbol: "LPOOL",
      name: "Launchpool token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6149c26cd2f7b5ccdb32029af817123f6e37df5b/7425e3e30c90ac6ca7054f5efd4e3d0d.png",
      coingeckoId: "launchpool",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x612E1726435fE38dD49A0B35b4065B56f49c8F11",
      symbol: "CCv2",
      name: "CryptoCart V2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x612e1726435fe38dd49a0b35b4065b56f49c8f11/logo.png",
      coingeckoId: "cryptocart",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x6123B0049F904d730dB3C36a31167D9d4121fA6B",
      symbol: "RBN",
      name: "Ribbon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6123b0049f904d730db3c36a31167d9d4121fa6b/d01dd0342160eeee21906b1dba45a327.png",
      coingeckoId: "ribbon-finance",
      listedIn: ["coingecko", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x61107a409fFFe1965126aa456Af679719695C69C",
      symbol: "UMI",
      name: "UmiToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x61107a409fffe1965126aa456af679719695c69c/a2a0fd42de55ab08866f938aa77bf816.png",
      coingeckoId: "umi-digital",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x60F5672A271C7E39E787427A18353ba59A4A3578",
      symbol: "PIKA",
      name: "PIKA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x60f5672a271c7e39e787427a18353ba59a4a3578/f30cc82862f121b5d9f054d6d1ce4367.png",
      coingeckoId: "pikachu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x607F4C5BB672230e8672085532f7e901544a7375",
      symbol: "RLC",
      name: "iEx.ec Network Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x607f4c5bb672230e8672085532f7e901544a7375/54099aced506eb5068608991d497c67f.png",
      coingeckoId: "iexec-rlc",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x607C794cDa77efB21F8848B7910ecf27451Ae842",
      symbol: "PIE",
      name: "DeFiPIE Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x607c794cda77efb21f8848b7910ecf27451ae842/297ea570e609db6baabc2c959926f884.png",
      coingeckoId: "defipie",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x605D26FBd5be761089281d5cec2Ce86eeA667109",
      symbol: "DSU",
      name: "Digital Standard Unit",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x602f65BB8B8098Ad804E99DB6760Fd36208cD967",
      symbol: "MOPS",
      name: "Mops",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x602f65bb8b8098ad804e99db6760fd36208cd967/52ecc8ef538877c9c5efde2728bf8660.png",
      coingeckoId: "mops",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6006FC2a849fEdABa8330ce36F5133DE01F96189",
      symbol: "SHAKE",
      name: "SHAKE token by SpaceSwap v2 ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x6006fc2a849fedaba8330ce36f5133de01f96189/dedf25a89ffce14517275d4c44da30c2.png",
      coingeckoId: "spaceswap-shake",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5fAa989Af96Af85384b8a938c2EdE4A7378D9875",
      symbol: "GAL",
      name: "Project Galaxy",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5faa989af96af85384b8a938c2ede4a7378d9875/f95ad63af1fa73172e211b4993c9336c.png",
      coingeckoId: "project-galaxy",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
      symbol: "LUSD",
      name: "LUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14666/small/Group_3.png?1617631327",
      coingeckoId: "liquity-usd",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "optimism",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x5f944B0c4315Cb7c3a846b025AB4045da44abf6c",
      symbol: "GCAKE",
      name: "PANCAKE GAMES",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f944b0c4315cb7c3a846b025ab4045da44abf6c/a1a08fd6a5d8cd5e6b8c54c81aea2dfb.png",
      coingeckoId: "pancake-games",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5f6c5C2fB289dB2228d159C69621215e354218d7",
      symbol: "DMOD",
      name: "Demodyfi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f6c5c2fb289db2228d159c69621215e354218d7/a2eb1d602bfe12cffef9ed776a5572d7.png",
      coingeckoId: "demodyfi",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x5f4c148D17Effd165C2e2d46b46d2BD6e3eBDC3e",
      symbol: "RUN",
      name: "FiveKM RUN",
      decimals: 18,
      chainId: "1",
      logoURI: "https://files.5km.app/logo-200.png",
      coingeckoId: "5km-run",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5f018e73C185aB23647c82bD039e762813877f0e",
      symbol: "SHACK",
      name: "Shack Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f018e73c185ab23647c82bd039e762813877f0e/1d848e07530c3546eef9e3c564b1da88.png",
      coingeckoId: "shack",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5e9F35E8163c44cD7e606BdD716AbED32AD2F1C6",
      symbol: "SAITANOBI",
      name: "Saitanobi",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5e9f35e8163c44cd7e606bdd716abed32ad2f1c6/e0e3cac84505e37915933fa731e79aac.png",
      coingeckoId: "saitanobi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb",
      symbol: "sETH",
      name: "Synth sETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb/5a359c53d8d08132fbc6025c49ec5b19.png",
      coingeckoId: "seth",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5e3346444010135322268a4630d2ED5F8D09446c",
      symbol: "LOC",
      name: "LockTrip",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5e3346444010135322268a4630d2ed5f8d09446c/b6eb0051e96289c6e512cbb2f6ea3050.png",
      coingeckoId: "lockchain",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c",
      symbol: "yyDAI+yUSDC+yUSDT+yTUSD",
      name: "yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5dbcf33d8c2e976c6b560249878e6f1491bca25c/1ef3523ec90b78c1071ebb0967761d85.png",
      coingeckoId: "yvault-lp-ycurve",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x5d60d8d7eF6d37E16EBABc324de3bE57f135e0BC",
      symbol: "MYB",
      name: "MyBit",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc/3869bd698323dff8de2ecc094ddc8cdb.png",
      coingeckoId: "mybit-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
      symbol: "cDAI",
      name: "Compound Dai",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5d3a536e4d6dbd6114cc1ead35777bab948e3643/84bf28e184bade8688ed7dc7def5f221.png",
      coingeckoId: "cdai",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x5d30aD9C6374Bf925D0A75454fa327AACf778492",
      symbol: "PERI",
      name: "PeriFinanceToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5d30ad9c6374bf925d0a75454fa327aacf778492/12e5c9a0873ae69651b4ba7a89edca68.png",
      coingeckoId: "peri-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5d285F735998F36631F678FF41fb56A10A4d0429",
      symbol: "MIX",
      name: "MixMarvel Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5d285f735998f36631f678ff41fb56a10a4d0429/fbb4a144828b44bf6922835236b9a728.png",
      coingeckoId: "mixmarvel",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x5cAf454Ba92e6F2c929DF14667Ee360eD9fD5b26",
      symbol: "DEV",
      name: "Dev",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5caf454ba92e6f2c929df14667ee360ed9fd5b26/3b701232ad721c8b2358f00d01318474.png",
      coingeckoId: "dev-protocol",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x5c872500c00565505F3624AB435c222E558E9ff8",
      symbol: "COT",
      name: "CoTrader",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5c872500c00565505f3624ab435c222e558e9ff8/a4a019429bc3cec23728881ffca6ce64.png",
      coingeckoId: "cotrader",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b",
      symbol: "WFLOW",
      name: "Wrapped Flow",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5c147e74d63b1d31aa3fd78eb229b65161983b2b/0e63d23fdc256af93777fa507a14ae59.png",
      coingeckoId: "wrapped-flow",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5afFE41805a9E57fEd3657d0e64D96aeA0B77885",
      symbol: "OPIUM_LP_34b7",
      name: "Opium USDT Protection v2/USDC",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x5affe41805a9e57fed3657d0e64d96aea0b77885.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x5adc961D6AC3f7062D2eA45FEFB8D8167d44b190",
      symbol: "DTH",
      name: "Dether",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190/ecd689b879e30ee533f0b1a3017ab14f.png",
      coingeckoId: "dether",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5a75A093747b72a0e14056352751eDF03518031d",
      symbol: "ESW",
      name: "EmiSwap-EmiDaoToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5a75a093747b72a0e14056352751edf03518031d/13425399202b8965a11bfccb1e3b3f7f.png",
      coingeckoId: "emiswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37",
      symbol: "MARSH",
      name: "UnmarshalToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37/7b0d5742c1112ecfdfb0c8babc082754.png",
      coingeckoId: "unmarshal",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0",
      symbol: "DEXTF",
      name: "DEXTF Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0/1d36343c5cde98bcd6aaa3a385b9ba11.png",
      coingeckoId: "dextf",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x5F0bc16D50F72d10b719dBF6845DE2E599eb5624",
      symbol: "VENT",
      name: "VENT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f0bc16d50f72d10b719dbf6845de2e599eb5624/d87c56e4f9ce51dc0b92dbb0219ff1d9.png",
      coingeckoId: "vent-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5F0E628B693018f639D10e4A4F59BD4d8B2B6B44",
      symbol: "WHITE",
      name: "Whiteheart Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44/2083cd9059f5a5fd6aea9dc9edae6b04.png",
      coingeckoId: "whiteheart",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5EeAA2DCb23056F4E8654a349E57eBE5e76b5e6e",
      symbol: "VPP",
      name: "Virtue Player Points",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x5eeaa2dcb23056f4e8654a349e57ebe5e76b5e6e/logo.png",
      coingeckoId: "virtue-poker",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x5Dc02Ea99285E17656b8350722694c35154DB1E8",
      symbol: "BOND_finance",
      name: "bonded.finance",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x5dc02ea99285e17656b8350722694c35154db1e8.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x5DE7Cc4BcBCa31c473F6D2F27825Cfb09cc0Bb16",
      symbol: "XBE",
      name: "XBE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x5DE7Cc4BcBCa31c473F6D2F27825Cfb09cc0Bb16/logo.png",
      coingeckoId: "xbe-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x5D8d9F5b96f4438195BE9b99eee6118Ed4304286",
      symbol: "COVER_v1",
      name: "Cover Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x5d8d9f5b96f4438195be9b99eee6118ed4304286/33bdcad090d2e70f0f6af9e4d1256e72.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x5Cf04716BA20127F1E2297AdDCf4B5035000c9eb",
      symbol: "NKN",
      name: "NKN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5cf04716ba20127f1e2297addcf4b5035000c9eb/aeb69c0ba0c102b16f1968d6da85194e.png",
      coingeckoId: "nkn",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x5Ca381bBfb58f0092df149bD3D243b08B9a8386e",
      symbol: "MXC",
      name: "MXCToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5ca381bbfb58f0092df149bd3d243b08b9a8386e/d57b87652af3ef0af2949433f611ccb2.png",
      coingeckoId: "mxc",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5CAc718A3AE330d361e39244BF9e67AB17514CE8",
      symbol: "COT",
      name: "CosplayToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5cac718a3ae330d361e39244bf9e67ab17514ce8/dc958bddab46269f3600841e407c508f.png",
      coingeckoId: "cosplay-token-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5C406D99E04B8494dc253FCc52943Ef82bcA7D75",
      symbol: "CUSD",
      name: "CUSD",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x5c406d99e04b8494dc253fcc52943ef82bca7d75.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x5BD7EF7113a32b56127ac32272609c42c97849fF",
      symbol: "BARK",
      name: "BarkCoin",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x5BC25f649fc4e26069dDF4cF4010F9f706c23831",
      symbol: "DUSD",
      name: "DefiDollar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5bc25f649fc4e26069ddf4cf4010f9f706c23831/55ef4f6618ed4ad39cd061bb65594756.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x5B7533812759B45C2B44C19e320ba2cD2681b542",
      symbol: "AGIX",
      name: "SingularityNET Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5b7533812759b45c2b44c19e320ba2cd2681b542/a5567aa121acf9632e42d7d001bf6f5e.png",
      coingeckoId: "singularitynet",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5B09A0371C1DA44A8E24D36Bf5DEb1141a84d875",
      symbol: "MAD",
      name: "MADToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5b09a0371c1da44a8e24d36bf5deb1141a84d875/c18f0b14263dc25bf53b5d14cd1b0bc1.png",
      coingeckoId: "mad-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
      symbol: "LDO",
      name: "LDO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13573/small/Lido_DAO.png?1609873644",
      coingeckoId: "lido-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x5978708d6ccE1CC9640Eed47422D64c91BbD5171",
      symbol: "LOL",
      name: "LOLTOKEN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5978708d6cce1cc9640eed47422d64c91bbd5171/8e5e6d9249e6f9cbc74bbd9961da1cef.png",
      coingeckoId: "loltoken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x595832F8FC6BF59c85C527fEC3740A1b7a361269",
      symbol: "POWR",
      name: "PowerLedger",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x595832f8fc6bf59c85c527fec3740a1b7a361269/665564a05394a8dc550fd53bd4b61cb0.png",
      coingeckoId: "power-ledger",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x591127253E40d4f63bF29CcF3D81FD062A149C8c",
      symbol: "XMETA",
      name: "TTX METAVERSE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x591127253e40d4f63bf29ccf3d81fd062a149c8c/b7c805495c5ef20b212c66c94e3e99d0.png",
      coingeckoId: "ttx-metaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x59089279987DD76fC65Bf94Cb40E186b96e03cB3",
      symbol: "crOGN",
      name: "Cream OriginToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x59089279987dd76fc65bf94cb40e186b96e03cb3.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x58b6A8A3302369DAEc383334672404Ee733aB239",
      symbol: "LPT",
      name: "Livepeer Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x58b6a8a3302369daec383334672404ee733ab239/3bf454e30e5f4b18011ea5e31d2e0e59.png",
      coingeckoId: "livepeer",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x584bC13c7D411c00c01A62e8019472dE68768430",
      symbol: "HEGIC",
      name: "Hegic",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x584bc13c7d411c00c01a62e8019472de68768430/604e863d43ca2bfc3ec7f47985a2c139.png",
      coingeckoId: "hegic",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x582d872A1B094FC48F5DE31D3B73F2D9bE47def1",
      symbol: "TONCOIN",
      name: "Wrapped TON Coin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x582d872a1b094fc48f5de31d3b73f2d9be47def1/71f89c6c8cd13cc99d62a263027ef074.png",
      coingeckoId: "the-open-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x57b59f981730c6257dF57cF6F0D98283749A9Eeb",
      symbol: "BUILD",
      name: "Build Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x57b59f981730c6257df57cf6f0d98283749a9eeb/c579cd2cdf2a4548d4e3e24b6287c0ce.png",
      coingeckoId: "build",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x57B946008913B82E4dF85f501cbAeD910e58D26C",
      symbol: "POND",
      name: "Marlin POND",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x57b946008913b82e4df85f501cbaed910e58d26c/b239a422f87e1bb9096276aaceb7bc1f.png",
      coingeckoId: "marlin",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51",
      symbol: "sUSD",
      name: "Synth sUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x57ab1ec28d129707052df4df418d58a2d46d5f51/c699f829018dea55b6b49da32bc9a90d.png",
      coingeckoId: "nusd",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x579CEa1889991f68aCc35Ff5c3dd0621fF29b0C9",
      symbol: "IQ",
      name: "Everipedia IQ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x579cea1889991f68acc35ff5c3dd0621ff29b0c9/cb50421c7138c92edd68501625d9a074.png",
      coingeckoId: "everipedia",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x57700244B20f84799a31c6C96DadFF373ca9D6c5",
      symbol: "TRUST",
      name: "TRUST DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x57700244b20f84799a31c6c96dadff373ca9d6c5/6fcf4253aecf282cac7036a53e4eb543.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5732046A883704404F284Ce41FfADd5b007FD668",
      symbol: "BLZ",
      name: "Bluzelle Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5732046a883704404f284ce41ffadd5b007fd668/3a176cc5a2f5ae02716263c0b7b79058.png",
      coingeckoId: "bluzelle",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x56de8BC61346321D4F2211e3aC3c0A7F00dB9b76",
      symbol: "RENA",
      name: "Rena",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x56de8bc61346321d4f2211e3ac3c0a7f00db9b76/f704a38c9e5cb195536f222e5ea81651.png",
      coingeckoId: "rena-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x56d811088235F11C8920698a204A5010a788f4b3",
      symbol: "BZRX",
      name: "bZx Protocol Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x56d811088235f11c8920698a204a5010a788f4b3/af41efd6d3ac9a686da1c1bdaa7d2111.png",
      coingeckoId: "bzx-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x56A86d648c435DC707c8405B78e2Ae8eB4E60Ba4",
      symbol: "STACK",
      name: "StackOS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x56a86d648c435dc707c8405b78e2ae8eb4e60ba4/2457b1e67f592ef87edcaf09b41d0b1d.png",
      coingeckoId: "stackos",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x55d1d16fB42fCE47B899010c996a3a31f6db8fD6",
      symbol: "BINU",
      name: "BullyInu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x55d1d16fb42fce47b899010c996a3a31f6db8fd6/d5a13f0a43304ef5ad8cc8cf462f7266.png",
      coingeckoId: "bully-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x559eBC30b0E58a45Cc9fF573f77EF1e5eb1b3E18",
      symbol: "VOLT",
      name: "VOLT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x559ebc30b0e58a45cc9ff573f77ef1e5eb1b3e18/449b4c7506bc054b2d0514075510f7f9.png",
      coingeckoId: "volt-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5590e22f78441da30463B55c3db0b5eA80cAbccA",
      symbol: "RBC",
      name: "RUBY CURRENCY",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5590e22f78441da30463b55c3db0b5ea80cabcca/875b7e9cf13323a74be2d9b1c2f1ec02.png",
      coingeckoId: "ruby-currency",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x557B933a7C2c45672B610F8954A3deB39a51A8Ca",
      symbol: "REVV",
      name: "REVV",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x557b933a7c2c45672b610f8954a3deb39a51a8ca/71d7e56f8365d1d365ca7c3289cdc8be.png",
      coingeckoId: "revv",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x556148562d5DdeB72545D7EC4B3eC8edc8F55Ba7",
      symbol: "PRDX",
      name: "Predix Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x556148562d5ddeb72545d7ec4b3ec8edc8f55ba7/87bb5d2e415242599995f459741c62fd.png",
      coingeckoId: "predix-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x55296f69f40Ea6d20E478533C15A6B08B654E758",
      symbol: "XYO",
      name: "XYO Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x55296f69f40ea6d20e478533c15a6b08b654e758/5f3c64ea1e556cdd57264a114f9f7791.png",
      coingeckoId: "xyo-network",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x547b2F82ceCfAb9C2B1D36fddA96eF9F58C63B8C",
      symbol: "TXT",
      name: "Taxa Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x547b2f82cecfab9c2b1d36fdda96ef9f58c63b8c/5e84ab88ab6b211d773d46137cb7f8ef.png",
      coingeckoId: "taxa-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x544c42fBB96B39B21DF61cf322b5EDC285EE7429",
      symbol: "INSUR",
      name: "InsurAce",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x544c42fbb96b39b21df61cf322b5edc285ee7429/f4e8cd10b00b967bbb82d80ca9f53bb1.png",
      coingeckoId: "insurace",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf",
      symbol: "GEN",
      name: "DAOStack",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x543ff227f64aa17ea132bf9886cab5db55dcaddf/e020198b2879322e7818f5309a5bdfa4.png",
      coingeckoId: "daostack",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x53dfEa0A8CC2A2A2e425E1C174Bc162999723ea0",
      symbol: "jCHF",
      name: "Jarvis Synthetic Swiss Franc",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/jchf.jpg",
      coingeckoId: "jarvis-synthetic-swiss-franc",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x53C8395465A84955c95159814461466053DedEDE",
      symbol: "DG",
      name: "DeGate Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x53c8395465a84955c95159814461466053dedede/f5dcf34d94eac1a238617dfb8c92ecfe.png",
      coingeckoId: "degate",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x539F3615C1dBAfa0D008d87504667458acBd16Fa",
      symbol: "FERA",
      name: "FERA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x539f3615c1dbafa0d008d87504667458acbd16fa/bd11dda842d3a53df422e2429c32a4e1.png",
      coingeckoId: "fera",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x53884b61963351C283118a8E1Fc05BA464a11959",
      symbol: "MNS",
      name: "Monnos Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x53884b61963351c283118a8e1fc05ba464a11959/6d069c7bc01fee3bffdc3050ef70eba4.png",
      coingeckoId: "monnos",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x528B3e98c63cE21C6f680b713918E0F89DfaE555",
      symbol: "DXO",
      name: "DeepSpace",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x528b3e98c63ce21c6f680b713918e0f89dfae555/a3ae2ae295798d8840291e326b3d808f.png",
      coingeckoId: "deepspace-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x525eF76138Bf76118d786DbedeaE5F87aaBf4a81",
      symbol: "NFTI",
      name: "Scalara NFT Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x525ef76138bf76118d786dbedeae5f87aabf4a81.png",
      coingeckoId: "scalara-nft-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x524Ebc93BeEf838F70b4Ae54b675d3e971D5884E",
      symbol: "HERO",
      name: "Challenge Coin",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x524ebc93beef838f70b4ae54b675d3e971d5884e/53db579b28429dcaa56c9c0ec54b1ae6.png",
      coingeckoId: "challenge-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5228a22e72ccC52d415EcFd199F99D0665E7733b",
      symbol: "pBTC",
      name: "pTokens BTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5228a22e72ccc52d415ecfd199f99d0665e7733b/30118483b5e4d0ebd76f78f4fbbbad3a.png",
      coingeckoId: "ptokens-btc",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x5218E472cFCFE0b64A064F055B43b4cdC9EfD3A6",
      symbol: "eRSDL",
      name: "UnFederalReserveToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6/031a263f1a1c58d1832b23d111a2d34f.png",
      coingeckoId: "unfederalreserve",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x51B0bcBEFf204B39Ce792D1E16767Fe6F7631970",
      symbol: "BTCV",
      name: "BTC Volatility Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x51b0bcbeff204b39ce792d1e16767fe6f7631970/b78c363404dc94b622f1bc84e7c85655.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x519C1001D550C0a1DaE7d1fC220f7d14c2A521BB",
      symbol: "PSWAP",
      name: "Polkaswap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x519c1001d550c0a1dae7d1fc220f7d14c2a521bb/9599b317ce45cf368bffa5b1c0277b4a.png",
      coingeckoId: "polkaswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5166E09628b696285E3A151e84FB977736a83575",
      symbol: "VOL",
      name: "Volatility Protocol Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5166e09628b696285e3a151e84fb977736a83575/b08d301d1b1df9118f70335e95e0cb7f.png",
      coingeckoId: "volatility-protocol-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x5165d24277cD063F5ac44Efd447B27025e888f37",
      symbol: "aYFI",
      name: "Aave interest bearing YFI",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmauhqAKU8YLhDhT4M5ZcPMuqEfqkBrBaC31uWC9UXd1ik",
      coingeckoId: "aave-yfi",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc",
      symbol: "GDAO",
      name: "Governor",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x515d7e9d75e2b76db60f8a051cd890eba23286bc/a701ea9fc429eeb271121f766730cc81.png",
      coingeckoId: "governor-dao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5150956E082C748Ca837a5dFa0a7C10CA4697f9c",
      symbol: "ZDEX",
      name: "Zeedex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x5150956e082c748ca837a5dfa0a7c10ca4697f9c/649fa7d7ca685da8376490b8e94a2509.png",
      coingeckoId: "zeedex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x514cdb9cd8A2fb2BdCf7A3b8DDd098CaF466E548",
      symbol: "REDPANDA",
      name: "Red Panda",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x514cdb9cd8a2fb2bdcf7a3b8ddd098caf466e548/00125b0f6bba85d9bf39a4bec1074826.png",
      coingeckoId: "redpanda-earth",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      symbol: "LINK",
      name: "Chainlink",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
      coingeckoId: "chainlink",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x513C3200F227ebB62e3B3d00B7a83779643a71CF",
      symbol: "LIFT",
      name: "Uplift",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x513c3200f227ebb62e3b3d00b7a83779643a71cf/4101abcc5e53b80065fc824a14d9d27e.png",
      coingeckoId: "uplift",
      listedIn: ["coingecko", "lifinance"],
    },
    /* {
            "address": "0x5102791cA02FC3595398400BFE0e33d7B6C82267",
            "symbol": "LDC",
            "name": "Lead Coin",
            "decimals": 18,
            "chainId": "1",
            "logoURI": "https://ethapi.openocean.finance/logos/0x5102791ca02fc3595398400bfe0e33d7b6c82267.png",
            "coingeckoId": null,
            "listedIn": [
                "openocean",
                "lifinance"
            ]
        } */
    {
      address: "0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd",
      symbol: "RAZOR",
      name: "RAZOR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd/85936c44506b8575e8130fb7e33632ee.png",
      coingeckoId: "razor-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x50D1c9771902476076eCFc8B2A83Ad6b9355a4c9",
      symbol: "FTT",
      name: "FTT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/9026/small/F.png?1609051564",
      coingeckoId: "ftx-token",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x509A38b7a1cC0dcd83Aa9d06214663D9eC7c7F4a",
      symbol: "BST",
      name: "BlocksquareToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a/a564437a469d5d4367f53adc69e5347d.png",
      coingeckoId: "blocksquare",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x505B5eDa5E25a67E1c24A2BF1a527Ed9eb88Bf04",
      symbol: "CWEB",
      name: "Coinweb",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04/06f4df8b1c636a94845145bbc92cb2b5.png",
      coingeckoId: "coinweb",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x50026ad58b338Cf3eccC2b422dEB8Faa725F377F",
      symbol: "STEP",
      name: "1Step.finance",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x50026ad58b338cf3eccc2b422deb8faa725f377f.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x4faB740779C73aA3945a5CF6025bF1b0e7F6349C",
      symbol: "DIRTY",
      name: "dirty.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4fab740779c73aa3945a5cf6025bf1b0e7f6349c/de5cfd38900872b42c1e56bd4388b3a2.png",
      coingeckoId: "dirty-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4fE83213D56308330EC302a8BD641f1d0113A4Cc",
      symbol: "NU",
      name: "NU",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/3318/small/photo1198982838879365035.jpg?1547037916",
      coingeckoId: "nucypher",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4f640F2529ee0cF119A2881485845FA8e61A782A",
      symbol: "ORE",
      name: "pTokens ORE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x4f640f2529ee0cf119a2881485845fa8e61a782a.png",
      coingeckoId: "ptokens-ore",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4f5fa8f2d12e5eB780f6082Dd656C565C48E0f24",
      symbol: "GUM",
      name: "GourmetGalaxy",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24/da3657fc7a63b20c2455c7efa7ca4df5.png",
      coingeckoId: "gourmetgalaxy",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF",
      symbol: "DGX",
      name: "Digix Gold Token",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf/80b10d7f4421b0c526ee7d6bbdddd378.png",
      coingeckoId: "digix-gold",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF",
      symbol: "YAE",
      name: "Cryptonovae",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4ee438be38f8682abb089f2bfea48851c5e71eaf/6bad3d4728f61da17bea518460c41f95.png",
      coingeckoId: "cryptonovae",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4e4a47cAC6A28A62dcC20990ed2cdA9BC659469F",
      symbol: "SHIT",
      name: "ShitCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4e4a47cac6a28a62dcc20990ed2cda9bc659469f/86f46d050e3dad755838f33109da75a2.png",
      coingeckoId: "i-will-poop-it-nft",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
      symbol: "CVX",
      name: "CVX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/15585/small/convex.png?1621256328",
      coingeckoId: "convex-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42",
      symbol: "MCB",
      name: "MCDEX Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42/60bdacd5f4ca79bd62292fc9df937f83.png",
      coingeckoId: "mcdex",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x4de2573e27E648607B50e1Cfff921A33E4A34405",
      symbol: "LST",
      name: "Lendroid Support Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4de2573e27e648607b50e1cfff921a33e4a34405/01b2917f0a8a39a8a39d6efeb02ea89f.png",
      coingeckoId: "lendroid-support-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x4da08a1Bff50BE96bdeD5C7019227164b49C2bFc",
      symbol: "Mononoke-Inu",
      name: "Mononoke Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4da08a1bff50be96bded5c7019227164b49c2bfc/506292b67db62bcb8fda943aaede45c6.png",
      coingeckoId: "mononoke-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
      symbol: "APE",
      name: "APE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455",
      coingeckoId: "apecoin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4c327471C44B2dacD6E90525f9D629bd2e4f662C",
      symbol: "GHOST",
      name: "GHOST",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4c327471c44b2dacd6e90525f9d629bd2e4f662c/fcaa320aaae991ef978feeb3bbea912f.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x4c11249814f11b9346808179Cf06e71ac328c1b5",
      symbol: "ORAI",
      name: "Oraichain Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4c11249814f11b9346808179cf06e71ac328c1b5/7146b1817218ae98a7adb7fb017c7cab.png",
      coingeckoId: "oraichain-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4bceA5E4d0F6eD53cf45e7a28FebB2d3621D7438",
      symbol: "MODEX",
      name: "Modex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4bcea5e4d0f6ed53cf45e7a28febb2d3621d7438/db634dd13dee26dcb5821c9b711d5493.png",
      coingeckoId: "modex",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4b520c812E8430659FC9f12f6d0c39026C83588D",
      symbol: "DG",
      name: "Decentral Games",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b520c812e8430659fc9f12f6d0c39026c83588d/92d7908366830c111f6f979e83e59945.png",
      coingeckoId: "decentral-games",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4b4D2e899658FB59b1D518b68fe836B100ee8958",
      symbol: "MIS",
      name: "MIS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b4d2e899658fb59b1d518b68fe836b100ee8958/618cfcbce8710d43c87da05f673651ef.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x4b1D0b9F081468D780Ca1d5d79132b64301085d1",
      symbol: "LMR",
      name: "Lumerin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b1d0b9f081468d780ca1d5d79132b64301085d1/177bacf7fd0467798692c775fee0c73b.png",
      coingeckoId: "lumerin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4aa41bC1649C9C3177eD16CaaA11482295fC7441",
      symbol: "XFIT",
      name: "XFIT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x4aa41bc1649c9c3177ed16caaa11482295fc7441/logo.png",
      coingeckoId: "xfit",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496",
      symbol: "NAOS",
      name: "NAOSToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4a615bb7166210cce20e6642a6f8fb5d4d044496/9f785030338c2f4196d4592b2bf702ff.png",
      coingeckoId: "naos-finance",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x4a57E687b9126435a9B19E4A802113e266AdeBde",
      symbol: "FXC",
      name: "Flexacoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4a57e687b9126435a9b19e4a802113e266adebde/b36532cc3b223b0579e226dda90d1ad1.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x4a220E6096B25EADb88358cb44068A3248254675",
      symbol: "QNT",
      name: "Quant",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4a220e6096b25eadb88358cb44068a3248254675/0fa0af99171fcb3a9a4b3e3189f6791b.png",
      coingeckoId: "quant-network",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x4Fb721eF3Bf99e0f2c193847afA296b9257d3C30",
      symbol: "TOK",
      name: "TOK",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4fb721ef3bf99e0f2c193847afa296b9257d3c30/422ac4b6aa46fec890e309b0247278fc.png",
      coingeckoId: "tokenplace",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      symbol: "BUSD",
      name: "BUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766",
      coingeckoId: "binance-usd",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4FE5851C9af07df9e5AD8217aFAE1ea72737Ebda",
      symbol: "OPT",
      name: "Open Predict Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4fe5851c9af07df9e5ad8217afae1ea72737ebda/d5b910dbe3c0ff13b4fe1acf0daebf8c.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x4F9254C83EB525f9FCf346490bbb3ed28a81C667",
      symbol: "CELR",
      name: "CelerToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4f9254c83eb525f9fcf346490bbb3ed28a81c667/1eb9c8f251098ea22a2cb30a877da86a.png",
      coingeckoId: "celer-network",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x4EE15f44c6F0d8d1136c83EfD2e8E4AC768954c6",
      symbol: "crYYCRV",
      name: "Cream yyCRV",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x4ee15f44c6f0d8d1136c83efd2e8e4ac768954c6.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x4E15361FD6b4BB609Fa63C81A2be19d873717870",
      symbol: "FTM",
      name: "FTM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016",
      coingeckoId: "fantom",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4E0fCa55a6C3A94720ded91153A27F60E26B9AA8",
      symbol: "BOOST",
      name: "Boost",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4e0fca55a6c3a94720ded91153a27f60e26b9aa8/54cccdf173e4d948f71c314072fd73a6.png",
      coingeckoId: "boost",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5",
      symbol: "cETH",
      name: "Compound Ether",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5/3f3c6c06c46381f9386ecbeead8eb953.png",
      coingeckoId: "compound-ether",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x4DA9b813057D04BAef4e5800E36083717b4a0341",
      symbol: "aTUSD",
      name: "Aave Interest bearing TUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4da9b813057d04baef4e5800e36083717b4a0341/ffeb84730f5d68c8693648f4aad563c3.png",
      coingeckoId: "aave-tusd-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x4D953cf077c0C95Ba090226E59A18FcF97db44EC",
      symbol: "MINI",
      name: "MINISWAP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4d953cf077c0c95ba090226e59a18fcf97db44ec/3c14d5a0a385f41d2ab46684209b0619.png",
      coingeckoId: "mini",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x4D0F56d728c5232ab07fAA0BdcbA23670A35451f",
      symbol: "AGFI",
      name: "Aggregated Finance",
      decimals: 9,
      chainId: "1",
      logoURI: null,
      coingeckoId: "aggregated-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4Cf89ca06ad997bC732Dc876ed2A7F26a9E7f361",
      symbol: "MYST",
      name: "Mysterium",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361/a5888a02086ca3db8ce214d756e9d807.png",
      coingeckoId: "mysterium",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4CF488387F035FF08c371515562CBa712f9015d4",
      symbol: "WPR",
      name: "WePower Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4cf488387f035ff08c371515562cba712f9015d4/e6cfdc558bd22fcb40b8b5241466cc95.png",
      coingeckoId: "wepower",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4CC19356f2D37338b9802aa8E8fc58B0373296E7",
      symbol: "KEY",
      name: "SelfKey",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4cc19356f2d37338b9802aa8e8fc58b0373296e7/11f33006e0a5e433be8ca42d42df0838.png",
      coingeckoId: "selfkey",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042",
      symbol: "PAINT",
      name: "Paint",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042/cece0b06cb9faf290936ea1365dd91a3.png",
      coingeckoId: "paint",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4C3A8ECeB656Ec63eaE80a4ebD565E4887DB6160",
      symbol: "SOKU",
      name: "Soku",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4c3a8eceb656ec63eae80a4ebd565e4887db6160/530210247f330295aba6513cae9c634a.png",
      coingeckoId: "sokuswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4C2e59D098DF7b6cBaE0848d66DE2f8A4889b9C3",
      symbol: "FODL",
      name: "FODL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/19040/small/new.jpg?1634559024",
      coingeckoId: "fodl-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4C25Bdf026Ea05F32713F00f73Ca55857Fbf6342",
      symbol: "FONT",
      name: "Font",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4c25bdf026ea05f32713f00f73ca55857fbf6342/aa1bf2742f632f0eaba625a4ec132ba7.png",
      coingeckoId: "font",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784",
      symbol: "TRU",
      name: "TrueFi",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784/e71758928ab4de0f6abcc84dcaccfb09.png",
      coingeckoId: "truefi",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x4B7ee45f30767F36f06F79B32BF1FCa6f726DEda",
      symbol: "EFIL",
      name: "Wrapped Filecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b7ee45f30767f36f06f79b32bf1fca6f726deda/6bf5d1ca8ed293ff12c1b2689d0ef6c4.png",
      coingeckoId: "ethereum-wrapped-filecoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4B5f49487ea7B3609b1aD05459BE420548789f1f",
      symbol: "LEVER",
      name: "Lever",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b5f49487ea7b3609b1ad05459be420548789f1f/424b019d8e7f67a1a0307f25272e0c39.png",
      coingeckoId: "lever",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8",
      symbol: "JUP",
      name: "Jupiter",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8/c0fbdc5d05a731e23ba35c2005ce8556.png",
      coingeckoId: "jupiter",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4Af698B479D0098229DC715655c667Ceb6cd8433",
      symbol: "MAID",
      name: "MaidCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/maid.jpg",
      coingeckoId: "maidcoin",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x4AaC461C86aBfA71e9d00d9a2cde8d74E4E1aeEa",
      symbol: "ZINC",
      name: "ZINC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea/fb75cfa6d99ad9b1c3a30910faf79be6.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x4AF5ff1A60a6eF6C7c8f9C4E304cD9051fCa3Ec0",
      symbol: "RGP",
      name: "RigelToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4af5ff1a60a6ef6c7c8f9c4e304cd9051fca3ec0/7b8bf2d2103e44df4174cce14da91e90.png",
      coingeckoId: "rigel-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4ABB9cC67BD3da9Eb966d1159A71a0e68BD15432",
      symbol: "KEL",
      name: "KEL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4abb9cc67bd3da9eb966d1159a71a0e68bd15432/59d9153dc8814af6d6c4d584eff76de3.png",
      coingeckoId: "kelvpn",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x4A8f5F96D5436e43112c2fBC6a9f70DA9e4E16D4",
      symbol: "INXT",
      name: "Internxt",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4a8f5f96d5436e43112c2fbc6a9f70da9e4e16d4/59eb08021949104f8e0b0bea2690c599.png",
      coingeckoId: "internxt",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4A64515E5E1d1073e83f30cB97BEd20400b66E10",
      symbol: "WZEC",
      name: "Wrapped ZEC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x4A64515E5E1d1073e83f30cB97BEd20400b66E10/logo.png",
      coingeckoId: "wrapped-zcash",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x49849C98ae39Fff122806C06791Fa73784FB3675",
      symbol: "crvRenWBTC",
      name: "Curve.fi renBTC/wBTC",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "lp-renbtc-curve",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4954Db6391F4feB5468b6B943D4935353596aEC9",
      symbol: "USDQ",
      name: "USDQ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4954db6391f4feb5468b6b943d4935353596aec9/f9d26f1c7d6cfa7856cc4f4c83e4c27e.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x4946Fcea7C692606e8908002e55A582af44AC121",
      symbol: "FOAM",
      name: "FOAM Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4946fcea7c692606e8908002e55a582af44ac121/4b8be7f735fbb1b288f8e1a5a9087764.png",
      coingeckoId: "foam-protocol",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x49184E6dAe8C8ecD89d8Bdc1B950c597b8167c90",
      symbol: "LIBERTAS",
      name: "LIBERTAS",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90/9af81e92a5622aa61d62b78f9124b455.png",
      coingeckoId: "libertas-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D",
      symbol: "CTSI",
      name: "Cartesi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D/logo.png",
      coingeckoId: "cartesi",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x48f07301E9E29c3C38a80ae8d9ae771F224f1054",
      symbol: "XZAR",
      name: "South African Tether",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x48f07301e9e29c3c38a80ae8d9ae771f224f1054/9f75b8fcd7755ec78c71fb3449c8e1cb.png",
      coingeckoId: "south-african-tether",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x48Fb253446873234F2fEBbF9BdeAA72d9d387f94",
      symbol: "vBNT",
      name: "Bancor Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x48fb253446873234f2febbf9bdeaa72d9d387f94/0c77cb2cf19988a01adf8eff80cd9f95.png",
      coingeckoId: "bancor-governance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2",
      symbol: "SWISE",
      name: "StakeWise",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2/fb12b7e5e2102edf167fe73cc2b836a8.png",
      coingeckoId: "stakewise",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x48C276e8d03813224bb1e55F953adB6d02FD3E02",
      symbol: "KUMA",
      name: "Kuma Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x48c276e8d03813224bb1e55f953adb6d02fd3e02/f30bf17887775af68d31ea79b48fcda2.png",
      coingeckoId: "kuma-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x488E0369f9BC5C40C002eA7c1fe4fd01A198801c",
      symbol: "GOF",
      name: "Golff.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c/d511f0662aa3ba61c7d34facdf6dd756.png",
      coingeckoId: "golff",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x485d17A6f1B8780392d53D64751824253011A260",
      symbol: "TIME",
      name: "ChronoTech Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x485d17a6f1b8780392d53d64751824253011a260/e98d2048401d5e1c802bdd17ef25c20f.png",
      coingeckoId: "chronobank",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x48592de8CdED16f6Bb56c896Fe1Affc37630889C",
      symbol: "POLP",
      name: "PolkaParty",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x48592de8cded16f6bb56c896fe1affc37630889c/da0736c19d748944bcc076161a78b573.png",
      coingeckoId: "polkaparty",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4838903d6319E483AB82Ae3f09A1Ec36489A4193",
      symbol: "FAMOUS",
      name: "Famous Coin",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "famous-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x47b9F01B16E9C9cb99191DCA68c9cc5bF6403957",
      symbol: "ONSTON",
      name: "Onston",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x47b9f01b16e9c9cb99191dca68c9cc5bf6403957/c40a17cae0c5363bbacb757c3b62c067.png",
      coingeckoId: "onston",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x476c5E26a75bd202a9683ffD34359C0CC15be0fF",
      symbol: "SRM",
      name: "Serum",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff/6458883add3412a15a0ace2fb0e6c780.png",
      coingeckoId: "serum",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x4740735AA98Dc8aa232BD049f8F0210458E7fCa3",
      symbol: "RDT",
      name: "Ridotto",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4740735aa98dc8aa232bd049f8f0210458e7fca3/b06e988ecc73c9ba23f096ea872dca1d.png",
      coingeckoId: "ridotto",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x474021845C4643113458ea4414bdb7fB74A01A77",
      symbol: "UNO",
      name: "UnoRe",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x474021845c4643113458ea4414bdb7fb74a01a77/128f86f5ae5cb67caf1c684a5353d181.png",
      coingeckoId: "uno-re",
      listedIn: ["coingecko", "1inch", "RouterProtocol", "lifinance"],
    },
    {
      address: "0x4730fB1463A6F1F44AEB45F6c5c422427f37F4D0",
      symbol: "FOUR",
      name: "The 4th Pillar Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0/ff4b54e2c0cc9f9d2f32083d31e7f8d7.png",
      coingeckoId: "the-4th-pillar",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x473037de59cf9484632f4A27B509CFE8d4a31404",
      symbol: "GST",
      name: "GreenSatoshiToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x473037de59cf9484632f4a27b509cfe8d4a31404/2d919ee408aa3e2d18f0c40b775e11fe.png",
      coingeckoId: "green-satoshi-token-on-eth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x47252A63C723889814AeBcAC0683E615624ceC64",
      symbol: "NIL",
      name: "nil",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x47252a63c723889814aebcac0683e615624cec64/e035c5e23929a99135fe8b229dcdbb50.png",
      coingeckoId: "nil-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x471Ea49dd8E60E697f4cac262b5fafCc307506e4",
      symbol: "xcRMRK",
      name: "xcRMRK",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x471ea49dd8e60e697f4cac262b5fafcc307506e4/fd049786331b33bc6205de366961bb2d.png",
      coingeckoId: "rmrk",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x47110d43175f7f2C2425E7d15792acC5817EB44f",
      symbol: "GMI",
      name: "Bankless DeFi Innovation Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x47110d43175f7f2c2425e7d15792acc5817eb44f/e4d9cfcd4c7a57ec31ef929fe8a304aa.png",
      coingeckoId: "bankless-defi-innovation-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x470EBf5f030Ed85Fc1ed4C2d36B9DD02e77CF1b7",
      symbol: "TEMPLE",
      name: "Temple",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7/5e88fad758a4fd99011c1bb7221c5c1a.png",
      coingeckoId: "temple",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x46e98FFE40E408bA6412bEb670507e083C8B95ff",
      symbol: "PRIMATE",
      name: "PRIMATE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x46e98ffe40e408ba6412beb670507e083c8b95ff/0c918348d819aa34f722fa14a6ac58a7.png",
      coingeckoId: "primate",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x469eDA64aEd3A3Ad6f868c44564291aA415cB1d9",
      symbol: "FLUX",
      name: "FLUX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x469eda64aed3a3ad6f868c44564291aa415cb1d9/83885447d29f59e846fe539dccfe0b61.png",
      coingeckoId: "flux",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x4691937a7508860F876c9c0a2a617E7d9E945D4B",
      symbol: "WOO",
      name: "WOO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12921/small/w2UiemF__400x400.jpg?1603670367",
      coingeckoId: "woo-network",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x469084939d1c20Fae3C73704FE963941C51bE863",
      symbol: "VIS",
      name: "Envision",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x469084939d1c20fae3c73704fe963941c51be863/27a9646a46b55dee9c93185618ad89cf.png",
      coingeckoId: "envision",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4688a8b1F292FDaB17E9a90c8Bc379dC1DBd8713",
      symbol: "COVER",
      name: "Cover Protocol Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713/a051324b20ae8dadc6304fe61aeecd6c.png",
      coingeckoId: "cover-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x467Bccd9d29f223BcE8043b84E8C8B282827790F",
      symbol: "TEL",
      name: "Telcoin",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x467bccd9d29f223bce8043b84e8c8b282827790f/161a26f47e94d3a29876733cccefa868.png",
      coingeckoId: "telcoin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x465e07d6028830124BE2E4aA551fBe12805dB0f5",
      symbol: "WXMR",
      name: "Wrapped Monero",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x465e07d6028830124be2e4aa551fbe12805db0f5/971d44de45dcc723f7fcfcd5c7ef9413.png",
      coingeckoId: "wrapped-xmr-btse",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0",
      symbol: "KRL",
      name: "KRYLL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0/58dd3b8ca921d0f910babbc8aaede6ff.png",
      coingeckoId: "kryll",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x464FdB8AFFC9bac185A7393fd4298137866DCFB8",
      symbol: "REALM",
      name: "REALM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x464fdb8affc9bac185a7393fd4298137866dcfb8/181df78b491d08c942ae44f99e718772.png",
      coingeckoId: "realm",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x460ea730d204c822cE709f00A8E5959921715aDC",
      symbol: "crBAC",
      name: "Cream Basis Cash",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x460ea730d204c822ce709f00a8e5959921715adc.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x45fDb1b92a649fb6A64Ef1511D3Ba5Bf60044838",
      symbol: "USDS",
      name: "Spice USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x45fdb1b92a649fb6a64ef1511d3ba5bf60044838/86863a4bb111157ad56dd0f4941e2a14.png",
      coingeckoId: "spiceusd",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x45f24BaEef268BB6d63AEe5129015d69702BCDfa",
      symbol: "YFV",
      name: "YFValue",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x45f24baeef268bb6d63aee5129015d69702bcdfa/13a8d168ffda6998c68234f1ad322d8e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x45e007750Cc74B1D2b4DD7072230278d9602C499",
      symbol: "stkXPRT",
      name: "pSTAKE Staked XPRT",
      decimals: 6,
      chainId: "1",
      logoURI: null,
      coingeckoId: "persistence-staked-xprt",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x459086F2376525BdCebA5bDDA135e4E9d3FeF5bf",
      symbol: "renBCH",
      name: "renBCH",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x459086f2376525bdceba5bdda135e4e9d3fef5bf/3da3bcfcb4b8d53bee52f1bb7572362d.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
      symbol: "PAXG",
      name: "PaxosGold",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x45804880De22913dAFE09f4980848ECE6EcbAf78/logo.png",
      coingeckoId: "pax-gold",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb",
      symbol: "OXT",
      name: "Orchid",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png",
      coingeckoId: "orchid-protocol",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x456D8f0D25A4e787eE60c401F8B963a465148f70",
      symbol: "CAVA",
      name: "Cavapoo",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x456d8f0d25a4e787ee60c401f8b963a465148f70/d04ff5d937df5944763d6d9b973156d4.png",
      coingeckoId: "cavapoo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x456AE45c0CE901E2e7c99c0718031cEc0A7A59Ff",
      symbol: "VSN",
      name: "Vision Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x456ae45c0ce901e2e7c99c0718031cec0a7a59ff/e62d4f7c5b7b3ccbed72f58f53dc0848.png",
      coingeckoId: "vision-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F",
      symbol: "SANI",
      name: "Sanin Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4521c9ad6a3d4230803ab752ed238be11f8b342f/9de25bfd361b4624553325ee0c9f2214.png",
      coingeckoId: "sanin-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x44fbeBd2F576670a6C33f6Fc0B00aA8c5753b322",
      symbol: "crUSDC",
      name: "Cream USD Coin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x44fbebd2f576670a6c33f6fc0b00aa8c5753b322.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A",
      symbol: "ATTR",
      name: "Attrace",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A/logo.png",
      coingeckoId: "attrace",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x44709a920fCcF795fbC57BAA433cc3dd53C44DbE",
      symbol: "RADAR",
      name: "DappRadar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x44709a920fccf795fbc57baa433cc3dd53c44dbe/8c4a6605a790450928f8d3629675fe1c.png",
      coingeckoId: "dappradar",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x446F2A8A39Cc730ef378Be759A3c57f1a3fE824c",
      symbol: "NBT",
      name: "NanoByte Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x446f2a8a39cc730ef378be759a3c57f1a3fe824c/d8c316282bc14efc4430598189a8fecb.png",
      coingeckoId: "nanobyte",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x446C9033E7516D820cc9a2ce2d0B7328b579406F",
      symbol: "SOLVE",
      name: "Healthcare Administration Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x446c9033e7516d820cc9a2ce2d0b7328b579406f/4f9661528a05c9ff965c07aa2751f921.png",
      coingeckoId: "solve-care",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x445f51299Ef3307dBD75036dd896565F5B4BF7A5",
      symbol: "VIDT",
      name: "V-ID blockchain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x445f51299ef3307dbd75036dd896565f5b4bf7a5/d05ef138f9a1f6a524429c002d834fb7.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x44564d0bd94343f72E3C8a0D22308B7Fa71DB0Bb",
      symbol: "BASK",
      name: "BasketDAO Gov",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb/6a0990681aa56e27cfaead8c5546a5e7.png",
      coingeckoId: "basketdao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x441761326490cACF7aF299725B6292597EE822c2",
      symbol: "UNFI",
      name: "Unifi Protocol DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x441761326490cacf7af299725b6292597ee822c2/cbf4cae8b9093d38a0007f8abadceff1.png",
      coingeckoId: "unifi-protocol-dao",
      listedIn: ["coingecko", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x44017598f2AF1bD733F9D87b5017b4E7c1B28DDE",
      symbol: "stkATOM",
      name: "pSTAKE Staked ATOM",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x44017598f2af1bd733f9d87b5017b4e7c1b28dde/50f192f51a1e5316e69491b4b1aec361.png",
      coingeckoId: "pstake-staked-atom",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x43f11c02439e2736800433b4594994Bd43Cd066D",
      symbol: "FLOKI",
      name: "FLOKI",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x43f11c02439e2736800433b4594994bd43cd066d/2f26d7577fdf72edb99b19685f8c39b5.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd",
      symbol: "DODO",
      name: "DODO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd/ded0ec2273c0c46b3ca67e81b4f1b916.png",
      coingeckoId: "dodo",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x436dA116249044E8B4464F0Cf21Dd93311d88190",
      symbol: "ZEUM",
      name: "ZEUM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/24448/small/AB0cGpnx_400x400.jpg?1647681843",
      coingeckoId: "colizeum",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x4332f8A38f14BD3D8D1553aF27D7c7Ac6C27278D",
      symbol: "APEFI",
      name: "APEFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/25625/small/APEFI.png?1652857977",
      coingeckoId: "ape-finance",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0",
      symbol: "DF",
      name: "dForce",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0/02df44f03a3c1f24cf95915fcbdc57bb.png",
      coingeckoId: "dforce-token",
      listedIn: [
        "coingecko",
        "1inch",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x43044f861ec040DB59A7e324c40507adDb673142",
      symbol: "CAP",
      name: "Cap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x43044f861ec040db59a7e324c40507addb673142/257d2076a71265fe40af6e16c270f803.png",
      coingeckoId: "cap",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x42d6622deCe394b54999Fbd73D108123806f6a18",
      symbol: "SPANK",
      name: "SPANK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x42d6622dece394b54999fbd73d108123806f6a18/f646a07acc797e4fb5211f45aac10868.png",
      coingeckoId: "spankchain",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "optimism",
        "lifinance",
      ],
    },
    {
      address: "0x42bBFa2e77757C645eeaAd1655E0911a7553Efbc",
      symbol: "BOBA",
      name: "Boba Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x42bbfa2e77757c645eeaad1655e0911a7553efbc/402a10db53def2a3647ea3edfba20cb1.png",
      coingeckoId: "boba-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5",
      symbol: "PICKLE",
      name: "PickleToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x429881672b9ae42b8eba0e26cd9c73711b891ca5/2b7b9d9e339bbaac086bdaf663b0072a.png",
      coingeckoId: "pickle-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x429876c4a6f89FB470E92456B8313879DF98B63c",
      symbol: "CNT",
      name: "Cryption Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x429876c4a6f89fb470e92456b8313879df98b63c/9e9f6f8f7eaf106c983ca7f64c516f11.png",
      coingeckoId: "cryption-network",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x426CA1eA2406c07d75Db9585F22781c096e3d0E0",
      symbol: "MNE",
      name: "Minereum",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x426ca1ea2406c07d75db9585f22781c096e3d0e0/d6ee5fbf827b93dc546be4f2ff07fd40.png",
      coingeckoId: "minereum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x420a24c9c65bd44c48bfB1cC8D6cD1ea8B1Ac840",
      symbol: "JMPT",
      name: "JumpToken",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "jumptoken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x41e5560054824eA6B0732E656E3Ad64E20e94E45",
      symbol: "CVC",
      name: "Civic",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x41e5560054824ea6b0732e656e3ad64e20e94e45/f44e161ad5413bd61785905f6a724b61.png",
      coingeckoId: "civic",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68",
      symbol: "INV",
      name: "Inverse Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68/b95fe3266be734e29d0385cadc44681c.png",
      coingeckoId: "inverse-finance",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x41AB1b6fcbB2fA9DCEd81aCbdeC13Ea6315F2Bf2",
      symbol: "XDCE",
      name: "XinFin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2/f326b58385f538ef1caf200ecef3f48b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x41A3Dba3D677E573636BA691a70ff2D606c29666",
      symbol: "BLANK",
      name: "GoBlank Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x41a3dba3d677e573636ba691a70ff2d606c29666/08859de83ae37a50ea2f08945e2bc279.png",
      coingeckoId: "blank",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b",
      symbol: "FUN",
      name: "FunFair",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x419d0d8bdd9af5e606ae2232ed285aff190e711b/17e38a53283964d43bba3be951edcb26.png",
      coingeckoId: "funfair",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x418D75f65a02b3D53B2418FB8E1fe493759c7605",
      symbol: "WBNB",
      name: "Wrapped BNB (Wormhole)",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x418d75f65a02b3d53b2418fb8e1fe493759c7605/1177dec1b9b7f58a799c3f22d93b04e1.png",
      coingeckoId: "binance-coin-wormhole",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4156D3342D5c385a87D264F90653733592000581",
      symbol: "SALT",
      name: "Salt",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4156d3342d5c385a87d264f90653733592000581/d3787c38541e192eef847c7543767977.png",
      coingeckoId: "salt",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x4123a133ae3c521FD134D7b13A2dEC35b56c2463",
      symbol: "QRDO",
      name: "Qredo",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4123a133ae3c521fd134d7b13a2dec35b56c2463/d6fbf1aa1e2bb2cef37a1e86c89ea19b.png",
      coingeckoId: "qredo",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x4104b135DBC9609Fc1A9490E61369036497660c8",
      symbol: "APW",
      name: "APWine Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4104b135dbc9609fc1a9490e61369036497660c8/bd56d139bc4b99a25891a8c59d8c88ec.png",
      coingeckoId: "apwine",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x40FD72257597aA14C7231A7B1aaa29Fce868F677",
      symbol: "XOR",
      name: "Sora Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x40fd72257597aa14c7231a7b1aaa29fce868f677/b8dc57d4d9fcd83b2448a60a9a5a1066.png",
      coingeckoId: "sora",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x40E0a6eF9DbADfc83C5e0d15262FEB4638588D77",
      symbol: "APE",
      name: "APE",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x40e0a6ef9dbadfc83c5e0d15262feb4638588d77/5dda6478f6b1ead28603311deb2f8c0d.png",
      coingeckoId: "ape",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x40986a85B4cFCDb054A6CBFB1210194fee51af88",
      symbol: "UFARM",
      name: "UNIFARM Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x40986a85b4cfcdb054a6cbfb1210194fee51af88/2508d44484bd1ecb6ec0eef72e6bc0cc.png",
      coingeckoId: "unifarm",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x408e41876cCCDC0F92210600ef50372656052a38",
      symbol: "REN",
      name: "Republic",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png",
      coingeckoId: "republic-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x40615B82999b8aa46803F11493BeDAB0314EB5E7",
      symbol: "HONEYD",
      name: "Honey Deluxe Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x40615b82999b8aa46803f11493bedab0314eb5e7/dcbdcf70fc9973c23c8eaa2d72337025.png",
      coingeckoId: "honey-deluxe",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4057Db5bD9f67A566aA10E5587b1a964afFc6a16",
      symbol: "TFBX",
      name: "Truefeedback",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x4057db5bd9f67a566aa10e5587b1a964affc6a16/c18bcfaf9345d707e8293909d66d3057.png",
      coingeckoId: "truefeedbackchain",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3fa400483487A489EC9b1dB29C4129063EEC4654",
      symbol: "KEK",
      name: "Cryptokek.com",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x3fa400483487a489ec9b1db29c4129063eec4654/logo.png",
      coingeckoId: "cryptokek",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8",
      symbol: "dKUMA",
      name: "KumaDex Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3f5dd1a1538a4f9f82e543098f01f22480b0a3a8/772d30aec9d2c9a6abbf0921d7969897.png",
      coingeckoId: "kumadex-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3f5294DF68F871241c4B18fcF78ebD8Ac18aB654",
      symbol: "STZ",
      name: "99Starz",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3f5294df68f871241c4b18fcf78ebd8ac18ab654/b7d096480aa475d546d92447ff1577f3.png",
      coingeckoId: "99starz",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x3ebb4A4e91Ad83BE51F8d596533818b246F4bEe1",
      symbol: "SATA",
      name: "Signata",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3ebb4a4e91ad83be51f8d596533818b246f4bee1/bc26bb7ead286c26114791a75c7c07d9.png",
      coingeckoId: "signata",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x3eacbDC6C382ea22b78aCc158581A55aaF4ef3Cc",
      symbol: "AXL",
      name: "Axelar",
      decimals: 6,
      chainId: "1",
      logoURI: null,
      coingeckoId: "axelar-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3e780920601D61cEdb860fe9c4a90c9EA6A35E78",
      symbol: "BOOST",
      name: "Boosted Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3e780920601d61cedb860fe9c4a90c9ea6a35e78/28af69e5d59f44183570923886cd2288.png",
      coingeckoId: "boosted-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3d6F0DEa3AC3C607B3998e6Ce14b6350721752d9",
      symbol: "CARDS",
      name: "CARD.STARTER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9/b6d326bfd4fe004ce8e46056d2896767.png",
      coingeckoId: "cardstarter",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3d1BA9be9f66B8ee101911bC36D3fB562eaC2244",
      symbol: "RVT",
      name: "Rivetz",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244/c450689ddc0ac8d27d5e65ebdd5a0e8c.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9",
      symbol: "BOR",
      name: "BoringDao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/bor.jpg",
      coingeckoId: "boringdao-[old]",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x3c8D2FCE49906e11e71cB16Fa0fFeB2B16C29638",
      symbol: "NFTL",
      name: "Nifty League",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3c8D2FCE49906e11e71cB16Fa0fFeB2B16C29638/logo.png",
      coingeckoId: "nifty-league",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x3b4f7CB9e60362A49dD04EB0091A374d340E3EfD",
      symbol: "ITAM",
      name: "ITAM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x3b4f7cb9e60362a49dd04eb0091a374d340e3efd.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x3b484b82567a09e2588A13D54D032153f0c0aEe0",
      symbol: "SOS",
      name: "SOS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/21956/small/fo42wXI6_400x400.jpg?1640373810",
      coingeckoId: "opendao",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x3af33bEF05C2dCb3C7288b77fe1C8d2AeBA4d789",
      symbol: "KROM",
      name: "Kromatika",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789/eeaad610e3d4b724fa8ba49b17283802.png",
      coingeckoId: "kromatika",
      listedIn: ["coingecko", "optimism", "lifinance"],
    },
    {
      address: "0x3aadc3BCe49724ce299fD9F3850211211c399C8c",
      symbol: "CSX",
      name: "Coinstox Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3aadc3bce49724ce299fd9f3850211211c399c8c/cc7c771ed6a216e91a61119f77afbc11.png",
      coingeckoId: "coinstox",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74",
      symbol: "XMON",
      name: "XMON",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14008/small/xmon_logo.png?1613615094",
      coingeckoId: "xmon",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x3aFfCCa64c2A6f4e3B6Bd9c64CD2C969EFd1ECBe",
      symbol: "DSLA",
      name: "DSLA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe/920656e6a4fe6c869d9ad35551e08af8.png",
      coingeckoId: "stacktical",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430",
      symbol: "PLA",
      name: "PlayDapp",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430/84adb7b35416aa9229ec472c7ba42251.png",
      coingeckoId: "playdapp",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x3a3A65aAb0dd2A17E3F1947bA16138cd37d08c04",
      symbol: "aETH",
      name: "Aave Interest bearing ETH",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04/26eacac56415e2f1554f371456a1b2d9.png",
      coingeckoId: "aave-eth-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3a1311B8C404629E38f61D566cefEFed083B9670",
      symbol: "PINU",
      name: "Piccolo Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3a1311b8c404629e38f61d566cefefed083b9670/8c593f3338c6cd022aae92ca066b0623.png",
      coingeckoId: "piccolo-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3FA729B4548beCBAd4EaB6EF18413470e6D5324C",
      symbol: "HH",
      name: "Holyheld",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3fa729b4548becbad4eab6ef18413470e6d5324c/095d5e44b8cf6e62964691740c9d6531.png",
      coingeckoId: "holyheld-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3F382DbD960E3a9bbCeaE22651E88158d2791550",
      symbol: "GHST",
      name: "Aavegotchi GHST Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3f382dbd960e3a9bbceae22651e88158d2791550/70918cb9df86b2f332c9649077756581.png",
      coingeckoId: "aavegotchi",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x3F09400313e83d53366147e3ea0e4e2279D80850",
      symbol: "kSEED",
      name: "KUSH.FINANCE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3f09400313e83d53366147e3ea0e4e2279d80850/858e84775127a735311767b6828b80af.png",
      coingeckoId: "kush-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811",
      symbol: "aUSDT",
      name: "Aave interest bearing USDT",
      decimals: 6,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUSDT.svg",
      coingeckoId: "aave-usdt",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3EF389f264e07fFF3106A3926F2a166d1393086F",
      symbol: "SAO",
      name: "Sator (Wormhole)",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3ef389f264e07fff3106a3926f2a166d1393086f/d4d0558358f747e74ea3c00f7fbfcf67.png",
      coingeckoId: "sator",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3E9BC21C9b189C09dF3eF1B824798658d5011937",
      symbol: "LINA",
      name: "Linear Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3e9bc21c9b189c09df3ef1b824798658d5011937/39e07391d5b6fe3dbe303e9a046327ea.png",
      coingeckoId: "linear",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x3E5D9D8a63CC8a88748f229999CF59487e90721e",
      symbol: "XMT",
      name: "MetalSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3e5d9d8a63cc8a88748f229999cf59487e90721e/177bda1722a14409755695b054075261.png",
      coingeckoId: "metalswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3E362283B86C1b45097CC3FB02213b79CF6211Df",
      symbol: "CATCOIN",
      name: "CATCOIN",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3e362283b86c1b45097cc3fb02213b79cf6211df/8550e9def5803e08a239827055e3ab02.png",
      coingeckoId: "catcoin-cash",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3Da932456D082CBa208FEB0B096d49b202Bf89c8",
      symbol: "DEGOV2",
      name: "dego.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x3da932456d082cba208feb0b096d49b202bf89c8.png",
      coingeckoId: "dego-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3DB6Ba6ab6F95efed1a6E794caD492fAAabF294D",
      symbol: "LTO",
      name: "LTO Network Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d/5204b8b020bada374de2b2b992cad861.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x3D97EdB1c1D87C0cBf098a0D2230d7380d4b1432",
      symbol: "CNDL",
      name: "Candle",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d97edb1c1d87c0cbf098a0d2230d7380d4b1432/e9d1773a4453ca6646909bd2a3c56517.png",
      coingeckoId: "candle",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3D658390460295FB963f54dC0899cfb1c30776Df",
      symbol: "COVAL",
      name: "Circuits of Value",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d658390460295fb963f54dc0899cfb1c30776df/2548c6df7124ad6f77ec6f3fb0718c69.png",
      coingeckoId: "circuits-of-value",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x3D3D35bb9bEC23b06Ca00fe472b50E7A4c692C30",
      symbol: "VIDYA",
      name: "Vidya",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30/f8d65bdd3d960b5c0fc7467d9d9c3614.png",
      coingeckoId: "vidya",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3D382228C54736d831FAC2748F4734D9177c7332",
      symbol: "ANV",
      name: "ANIVERSE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d382228c54736d831fac2748f4734d9177c7332/fb491e5d4f733e243e92c99bd98068c8.png",
      coingeckoId: "aniverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3D0293f06daF4311B482564330D57C8Db6C10893",
      symbol: "YCO",
      name: "Y Coin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3d0293f06daf4311b482564330d57c8db6c10893/4679f7c7f0e329244e97cd7d3ff39611.png",
      coingeckoId: "y-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3CbF23c081fAA5419810ce0F6BC1ECb73006d848",
      symbol: "DIGI",
      name: "Digible",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x3cbf23c081faa5419810ce0f6bc1ecb73006d848/logo.png",
      coingeckoId: "digible",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x3C81D482172cC273c3b91dD9D8eb212023D00521",
      symbol: "PRY",
      name: "PRY",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3c81d482172cc273c3b91dd9d8eb212023d00521/bb218b2881b2c07900b44921d6c864ad.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x3C6ff50c9Ec362efa359317009428d52115fe643",
      symbol: "PERX",
      name: "PeerEx Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3c6ff50c9ec362efa359317009428d52115fe643/53376fb63c45eee69f3e8f8b3de7a89e.png",
      coingeckoId: "peerex-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3C6C553A95910F9FC81c98784736bd628636D296",
      symbol: "crESD",
      name: "Cream Empty Set Dollar",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x3c6c553a95910f9fc81c98784736bd628636d296.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d",
      symbol: "VGX",
      name: "Voyager Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d/67585db51871f594b08540bf580fd8bb.png",
      coingeckoId: "ethos",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x3C45B24359fB0E107a4eAA56Bd0F2cE66C99A0E5",
      symbol: "ANK",
      name: "Apple Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5/166574bd1fd3a7cd5d29eb2b7405650f.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x3C03b4EC9477809072FF9CC9292C9B25d4A8e6c6",
      symbol: "CVR",
      name: "PolkaCover",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6/4b4d4f66d4b876bc341c6eba44b8e02e.png",
      coingeckoId: "covercompared",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3B9BE07d622aCcAEd78f479BC0EDabFd6397E320",
      symbol: "LSS",
      name: "Lossless Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3b9be07d622accaed78f479bc0edabfd6397e320/ece888c8d850909a0bf67fecc1b15ada.png",
      coingeckoId: "lossless",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3A9FfF453d50D4Ac52A6890647b823379ba36B9E",
      symbol: "SHUF",
      name: "Shuffle.Monster V3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x3a9fff453d50d4ac52a6890647b823379ba36b9e.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3A92bD396aEf82af98EbC0Aa9030D25a23B11C6b",
      symbol: "TBX",
      name: "Tokenbox",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b/bf42da2b0f72493f745fe94eaa9c7bbe.png",
      coingeckoId: "tokenbox",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x3A880652F47bFaa771908C07Dd8673A787dAEd3A",
      symbol: "DDX",
      name: "DerivaDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3a880652f47bfaa771908c07dd8673a787daed3a/c8b511747322ef84786d03419e6c4da3.png",
      coingeckoId: "derivadao",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x3A707d56D538e85B783E8CE12B346e7fB6511F90",
      symbol: "iETHV",
      name: "Inverse ETH Volatility Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x3a707d56d538e85b783e8ce12b346e7fb6511f90/0a871f975e11b2c4e171a5f949badde1.png",
      coingeckoId: "inverse-ethereum-volatility-index-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x39fBBABf11738317a448031930706cd3e612e1B9",
      symbol: "WXRP",
      name: "Wrapped XRP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x39fbbabf11738317a448031930706cd3e612e1b9/634d6d3ec10330a7dd9a3b2b6b1f9e90.png",
      coingeckoId: "wrapped-xrp",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA",
      symbol: "aKNC",
      name: "Aave interest bearing KNC",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aKNC.svg",
      coingeckoId: "aave-knc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
      symbol: "cUSDC",
      name: "Compound USD Coin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x39aa39c021dfbae8fac545936693ac917d5e7563/fbf7e500b5ad6dd17b09b288df68e3b1.png",
      coingeckoId: "compound-usd-coin",
      listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x39795344CBCc76cC3Fb94B9D1b15C23c2070C66D",
      symbol: "SHARE",
      name: "Seigniorage Shares",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d/fb7c26a5fc297195632a89c21e919325.png",
      coingeckoId: "seigniorage-shares",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x38e4adB44ef08F22F5B5b76A8f0c2d0dCbE7DcA1",
      symbol: "CVP",
      name: "Concentrated Voting Power",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1/4c507a4241187c3e5a5e11cd7b3a3952.png",
      coingeckoId: "concentrated-voting-power",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x38A94e92A19E970c144DEd0B2DD47278CA11CC1F",
      symbol: "F9",
      name: "Falcon 9",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x38a94e92a19e970c144ded0b2dd47278ca11cc1f/2ccf4d11b69cecb35bced047a6f5f234.png",
      coingeckoId: "falcon-nine",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x38A2fDc11f526Ddd5a607C1F251C065f40fBF2f7",
      symbol: "PHNX",
      name: "PhoenixDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7/6b9203a391f9c431a7c2c097db85f79b.png",
      coingeckoId: "phoenixdao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x389999216860AB8E0175387A0c90E5c52522C945",
      symbol: "FEG",
      name: "FEGtoken",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x389999216860ab8e0175387a0c90e5c52522c945/d3232dd4dd8cfe2348d89f0c4c94bc54.png",
      coingeckoId: "feg-token",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
      symbol: "SAND",
      name: "Sandbox",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x3845badAde8e6dFF049820680d1F14bD3903a5d0/logo.png",
      coingeckoId: "the-sandbox",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x383518188C0C6d7730D91b2c03a03C837814a899",
      symbol: "OHM",
      name: "Olympus",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x383518188c0c6d7730d91b2c03a03c837814a899/03481ec19a78e07b560365cbe0028aa3.png",
      coingeckoId: "olympus-v1",
      listedIn: [
        "coingecko",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x3832d2F059E55934220881F831bE501D180671A7",
      symbol: "renDOGE",
      name: "renDOGE",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3832d2f059e55934220881f831be501d180671a7/c04ac04e1c61b8efcc1919770c3220f2.png",
      coingeckoId: "rendoge",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x3819f64f282bf135d62168C1e513280dAF905e06",
      symbol: "HDRN",
      name: "Hedron",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3819f64f282bf135d62168c1e513280daf905e06/3384512811655603d70b37623a454f10.png",
      coingeckoId: "hedron",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x37fE0f067FA808fFBDd12891C0858532CFE7361d",
      symbol: "CIV",
      name: "Civilization",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x37fe0f067fa808ffbdd12891c0858532cfe7361d/e83b25f2d97b6d5a79304340974cff0b.png",
      coingeckoId: "civilization",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x37E8789bB9996CaC9156cD5F5Fd32599E6b91289",
      symbol: "AID",
      name: "AidCoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x37e8789bb9996cac9156cd5f5fd32599e6b91289/aa69fcdacfea72281cd23a2e350b9f6a.png",
      coingeckoId: "aidcoin",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x37E83a94c6B1Bdb816B59aC71dd02CF154d8111F",
      symbol: "PHCR",
      name: "PhotoChromic",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x37e83a94c6b1bdb816b59ac71dd02cf154d8111f/73cbcc8fedfec0567928fd31ec698491.png",
      coingeckoId: "photochromic",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x37C997B35C619C21323F3518B9357914E8B99525",
      symbol: "PILOT",
      name: "Unipilot",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x37c997b35c619c21323f3518b9357914e8b99525/b99fd365d9bbe177b222dd41b60d9577.png",
      coingeckoId: "unipilot",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x3758e00b100876C854636eF8Db61988931BB8025",
      symbol: "UNIQ",
      name: "Uniqly",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3758e00b100876c854636ef8db61988931bb8025/b318e891c2eae32037471cdebca3f089.png",
      coingeckoId: "uniqly",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x374CB8C27130E2c9E04F44303f3c8351B9De61C1",
      symbol: "BAO",
      name: "BaoToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x374cb8c27130e2c9e04f44303f3c8351b9de61c1/31e006c2a222e974dcef3636beb8e662.png",
      coingeckoId: "bao-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x37129b96896891e56bc099540fe7D1841005A367",
      symbol: "EUROS",
      name: "Spice EUR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x37129b96896891e56bc099540fe7d1841005a367/3fbab749ddde5c7f5ec5ed3bf403d7dc.png",
      coingeckoId: "spiceeuro",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723",
      symbol: "ESD",
      name: "Empty Set Dollar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x36f3fd68e7325a35eb768f1aedaae9ea0689d723/065ed2d39db5e851ff073ab88c42dfd9.png",
      coingeckoId: "empty-set-dollar",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x36E43065e977bC72CB86Dbd8405fae7057CDC7fD",
      symbol: "ARCHA",
      name: "archangel",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x36e43065e977bc72cb86dbd8405fae7057cdc7fd/34ef779e774ec74ed220de81ec276d4b.png",
      coingeckoId: "archangel-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x368C5290b13cAA10284Db58B4ad4F3E9eE8bf4c9",
      symbol: "KKO",
      name: "Kineko",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x368c5290b13caa10284db58b4ad4f3e9ee8bf4c9/logo.png",
      coingeckoId: "kineko",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x368B3a58B5f49392e5C9E4C998cb0bB966752E51",
      symbol: "MIC",
      name: "MIC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x368b3a58b5f49392e5c9e4c998cb0bb966752e51/412cae9cf471bb06b8772061ee91014d.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5",
      symbol: "PL2",
      name: "Plenny",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x362bc847A3a9637d3af6624EeC853618a43ed7D2",
      symbol: "PRQ",
      name: "Parsiq Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x362bc847a3a9637d3af6624eec853618a43ed7d2/a02d84413c9ede3e474eec90e8726531.png",
      coingeckoId: "parsiq",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x3623387773010d9214B10C551d6e7fc375D31F58",
      symbol: "crMTA",
      name: "Cream Meta",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x3623387773010d9214b10c551d6e7fc375d31f58.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2",
      symbol: "aSNX",
      name: "Aave interest bearing SNX",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmXj52EGotmpyep84PBycmQnAgCF2sbqxdXFWP3GPZFbEz",
      coingeckoId: "aave-snx",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x35A18000230DA775CAc24873d00Ff85BccdeD550",
      symbol: "cUNI",
      name: "Compound Uniswap",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x35a18000230da775cac24873d00ff85bccded550.png",
      coingeckoId: "compound-uniswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3597bfD533a99c9aa083587B074434E61Eb0A258",
      symbol: "DENT",
      name: "DENT",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3597bfd533a99c9aa083587b074434e61eb0a258/5e61bb480eb9cc008d23edfd59861f43.png",
      coingeckoId: "dent",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3593D125a4f7849a1B059E64F4517A86Dd60c95d",
      symbol: "OMv2",
      name: "MANTRA DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3593d125a4f7849a1b059e64f4517a86dd60c95d/839caee8718be7b5683219d619c7756b.png",
      coingeckoId: "mantra-dao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x358AA737e033F34df7c54306960a38d09AaBd523",
      symbol: "ARES",
      name: "Ares Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x358aa737e033f34df7c54306960a38d09aabd523/8474ba7b549d5fc93ff35a4bc9ff2ec2.png",
      coingeckoId: "ares-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3541A5C1b04AdABA0B83F161747815cd7B1516bC",
      symbol: "KNIGHT",
      name: "CitaDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3541a5c1b04adaba0b83f161747815cd7b1516bc/bf0be94834b4039835fbaa1566a06a7c.png",
      coingeckoId: "citadao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x350D3f0f41b5B21F0e252fe2645AE9D55562150a",
      symbol: "BCT",
      name: "BananaClubToken",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x350d3f0f41b5b21f0e252fe2645ae9d55562150a/12e27bb000f43a341924327eac35f0cf.png",
      coingeckoId: "bananaclubtoken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3506424F91fD33084466F402d5D97f05F8e3b4AF",
      symbol: "CHZ",
      name: "Chiliz",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3506424f91fd33084466f402d5d97f05f8e3b4af/c3b47a24ccf2375d9e109488f144cb9a.png",
      coingeckoId: "chiliz",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x3505F494c3f0fed0B594E01Fa41Dd3967645ca39",
      symbol: "SWM",
      name: "SWARM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3505f494c3f0fed0b594e01fa41dd3967645ca39/662e8d79be9f2635e3fc680c2363dc61.png",
      coingeckoId: "swarm",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x34d31446a522252270b89b09016296ec4c98e23d",
      symbol: "SAUDISHIB",
      name: "SAUDI SHIBA INU",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x34d31446a522252270b89b09016296ec4c98e23d/9a2767a8699e74efe8f89ed8a8283c7e.png",
      coingeckoId: "saudi-shiba-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x34F0915a5f15a66Eba86F6a58bE1A471FB7836A7",
      symbol: " ",
      name: "PulseDogecoin",
      decimals: 12,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x34f0915a5f15a66eba86f6a58be1a471fb7836a7/65d703306f88e8e8f492b139be593b52.png",
      coingeckoId: "pulsedogecoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3496B523e5C00a4b4150D6721320CdDb234c3079",
      symbol: "NUM",
      name: "NUM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://storage.googleapis.com/xy-finance-images/token-list/Num.png",
      coingeckoId: "numbers-protocol",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x34950Ff2b487d9E5282c5aB342d08A2f712eb79F",
      symbol: "WOZX",
      name: "EFFORCE IEO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x34950ff2b487d9e5282c5ab342d08a2f712eb79f/962f53433e73aa0e8d62b227d29a47d8.png",
      coingeckoId: "wozx",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x3472A5A71965499acd81997a54BBA8D852C6E53d",
      symbol: "BADGER",
      name: "Badger",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3472a5a71965499acd81997a54bba8d852c6e53d/0e7643dba053739569d5b9f82225f4e5.png",
      coingeckoId: "badger-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x34612903Db071e888a4dADcaA416d3EE263a87b9",
      symbol: "arte",
      name: "ethart",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x34612903db071e888a4dadcaa416d3ee263a87b9/ce230289a5efcc47ed51f12a944654ac.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x3449FC1Cd036255BA1EB19d65fF4BA2b8903A69a",
      symbol: "BAC",
      name: "BAC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a/fe4e2d95a908fa420189499d6b8d2d15.png",
      coingeckoId: "basis-cash",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x343E59D9D835e35b07fe80f5Bb544f8ED1CD3B11",
      symbol: "MeM",
      name: "MagicEthereumMoney",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x343e59d9d835e35b07fe80f5bb544f8ed1cd3b11/5093a9992e7c91e4745ab9724e39a7ab.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
      symbol: "FXS",
      name: "FXS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/13423/small/frax_share.png?1608478989",
      coingeckoId: "frax-share",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x3431F91b3a388115F00C5Ba9FdB899851D005Fb5",
      symbol: "GERO",
      name: "GeroWallet",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3431f91b3a388115f00c5ba9fdb899851d005fb5/26e53dc69db6b8c2bf258e665db8367b.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x340D2bdE5Eb28c1eed91B2f790723E3B160613B7",
      symbol: "VEE",
      name: "BLOCKv",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x340d2bde5eb28c1eed91b2f790723e3b160613b7/1a37ac87fdf69fb81f0d0145be306f3c.png",
      coingeckoId: "blockv",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3405A1bd46B85c5C029483FbECf2F3E611026e45",
      symbol: "WOW",
      name: "WOWSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3405A1bd46B85c5C029483FbECf2F3E611026e45/logo.png",
      coingeckoId: "wowswap",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x33e2c129eA670031659AC8852c01D0Fe4B297954",
      symbol: "SKY",
      name: "SkyToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33e2c129ea670031659ac8852c01d0fe4b297954/62d24a4590d12bd3583cc551207edd4d.png",
      coingeckoId: "sky",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x33e18a092a93ff21aD04746c7Da12e35D34DC7C4",
      symbol: "PLAY",
      name: "Metaverse NFT Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33e18a092a93ff21ad04746c7da12e35d34dc7c4/7c652fd40a58eaa49b010f9fcd6fe485.png",
      coingeckoId: "metaverse-nft-index",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1",
      symbol: "DATA",
      name: "DATA Economy Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1/0db0a632f8e3dbaee68bc723ca85b5c7.png",
      coingeckoId: "data-economy-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x33D203FA03bb30b133De0fE2d6533C268bA286B6",
      symbol: "MANDOX",
      name: "Mandox",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33d203fa03bb30b133de0fe2d6533c268ba286b6/51f3e5abb84e87bf143be3cf8c090ddb.png",
      coingeckoId: "mandox-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f",
      symbol: "RAMP",
      name: "RAMP DEFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f/78a018d5460b7a51b53fdd32ad6ae754.png",
      coingeckoId: "ramp",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x33B4fE5e40E4903A0849cA97B3292eac3EB0aA36",
      symbol: "HONEY",
      name: "Honey",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33b4fe5e40e4903a0849ca97b3292eac3eb0aa36/f01266a368f0423977c5448acce20bde.png",
      coingeckoId: "honey-fancybearsmetaverse",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x33840024177A7DacA3468912363BeD8b425015c5",
      symbol: "EBOX",
      name: "ethbox Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33840024177a7daca3468912363bed8b425015c5/972842875ef25022bd52a9b3cdbf86f7.png",
      coingeckoId: "ebox",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3383c5a8969Dc413bfdDc9656Eb80A1408E4bA20",
      symbol: "wANATHA",
      name: "Wrapped ANATHA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x3383c5a8969dc413bfddc9656eb80a1408e4ba20/e9e0e7d7c22ac0547f1990d1f5f504ca.png",
      coingeckoId: "wrapped-anatha",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x338286C0BC081891A4Bda39C7667ae150bf5D206",
      symbol: "crSUSHI",
      name: "Cream SushiToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x338286c0bc081891a4bda39c7667ae150bf5d206.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x33349B282065b0284d756F0577FB39c158F935e6",
      symbol: "MPL",
      name: "Maple",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x33349b282065b0284d756f0577fb39c158f935e6/9370c27d5aa31c91e6da9d824e55a82f.png",
      coingeckoId: "maple",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x332E824e46FcEeB9E59ba9491B80d3e6d42B0B59",
      symbol: "CHEESE",
      name: "CheeseFry",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x332e824e46fceeb9e59ba9491b80d3e6d42b0b59/8805639520feeda65ac6e6e3c5922f17.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6",
      symbol: "AKITA",
      name: "Akita Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3301ee63fb29f863f2333bd4466acb46cd8323e6/6173e15b13a34a6e42bda133ac37a6ed.png",
      coingeckoId: "akita-inu",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x32a7C02e79c4ea1008dD6564b35F131428673c41",
      symbol: "CRU",
      name: "CRUST",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x32a7c02e79c4ea1008dd6564b35f131428673c41/5d4733f606a6155de8781755130b7625.png",
      coingeckoId: "crust-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE",
      symbol: "aSNX",
      name: "Aave Interest bearing SNX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x328c4c80bc7aca0834db37e6600a6c49e12da4de/b120aac18dd087de53003fe7dd979f7d.png",
      coingeckoId: "aave-snx-v1",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x327673aE6B33Bd3d90f0096870059994f30Dc8AF",
      symbol: "LMT",
      name: "LympoMarketToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x327673ae6b33bd3d90f0096870059994f30dc8af/4def6967351e2759ddabdde5a9a6bcb8.png",
      coingeckoId: "lympo-market-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3258cd8134b6b28e814772dD91D5EcceEa512818",
      symbol: "LAND",
      name: "Land",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x32353A6C91143bfd6C7d363B546e62a9A2489A20",
      symbol: "AGLD",
      name: "Adventure Gold",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x32353a6c91143bfd6c7d363b546e62a9a2489a20/a6c0dc128d515e2d32526075decae9ec.png",
      coingeckoId: "adventure-gold",
      listedIn: ["coingecko", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x3225E3C669B39C7c8B3e204a8614bB218c5e31BC",
      symbol: "crAAVE",
      name: "Cream AAVE Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x3225e3c669b39c7c8b3e204a8614bb218c5e31bc.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x321C2fE4446C7c963dc41Dd58879AF648838f98D",
      symbol: "CTX",
      name: "Cryptex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x321c2fe4446c7c963dc41dd58879af648838f98d/d0663dba9de34dd01b17359f9cab49c2.png",
      coingeckoId: "cryptex-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x3218A02F8F8B5c3894ce30EB255F10Bcba13E654",
      symbol: "MEGA",
      name: "MegaCryptoPolis $MEGA Token (MEGA)",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3218a02f8f8b5c3894ce30eb255f10bcba13e654/7da12c505ccc103a5e5b1e35d4c843bc.png",
      coingeckoId: "megacryptopolis",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3212b29E33587A00FB1C83346f5dBFA69A458923",
      symbol: "imBTC",
      name: "The Tokenized Bitcoin",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3212b29e33587a00fb1c83346f5dbfa69a458923/4134e12fb22863ef5186d6cd39b97737.png",
      coingeckoId: "the-tokenized-bitcoin",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3",
      symbol: "RAD",
      name: "RAD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14013/small/radicle.png?1614402918",
      coingeckoId: "radicle",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x31c63146a635EB7465e5853020b39713AC356991",
      symbol: "mUSO",
      name: "Wrapped Mirror USO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x31c63146a635eb7465e5853020b39713ac356991/80223b0c263a8b8c262a30378a5b78e9.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x3166C570935a7D8554c8f4eA792ff965D2EFe1f2",
      symbol: "QDAO",
      name: "QDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3166c570935a7d8554c8f4ea792ff965d2efe1f2/a69657962780771a9d9c5d9c3580fb23.png",
      coingeckoId: "q-dao-governance-token-v1-0",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x3155BA85D5F96b2d030a4966AF206230e46849cb",
      symbol: "RUNE",
      name: "THORChain ETH.RUNE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3155ba85d5f96b2d030a4966af206230e46849cb/f5b9ea0be464a0525395f416f042827e.png",
      coingeckoId: "thorchain-erc20",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x31429d1856aD1377A8A0079410B297e1a9e214c2",
      symbol: "ANGLE",
      name: "ANGLE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x31429d1856ad1377a8a0079410b297e1a9e214c2/950fda44a9f4598d2a7a6e9df24b7332.png",
      coingeckoId: "angle-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x30f271C9E86D2B7d00a6376Cd96A1cFBD5F0b9b3",
      symbol: "DEC",
      name: "Decentr",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3/b0d9bf8072f4b7c22dc23876bd2d7ff9.png",
      coingeckoId: "decentr",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x30D20208d987713f46DFD34EF128Bb16C404D10f",
      symbol: "SD",
      name: "Stader",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x30d20208d987713f46dfd34ef128bb16c404d10f/4fa0cf4070995d65eb86d01a2b68a893.png",
      coingeckoId: "stader",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x309627af60F0926daa6041B8279484312f2bf060",
      symbol: "USDB",
      name: "USDB",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x309627af60f0926daa6041b8279484312f2bf060/a5dab07030121cab0f835fdf6ee0d8dc.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x3085154623F51b00Dedfc6Ceeb5197277A66B17B",
      symbol: "NFTY",
      name: "NFTY Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x3085154623f51b00dedfc6ceeb5197277a66b17b/afa8729e81a7e9e2c401d3e8a8d39506.png",
      coingeckoId: "nifty-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x301C755bA0fcA00B1923768Fffb3Df7f4E63aF31",
      symbol: "GDC",
      name: "Global Digital Content",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x301c755ba0fca00b1923768fffb3df7f4e63af31/3c747fba1b847a3949a3ac41e746ddad.png",
      coingeckoId: "global-digital-content",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x2f4eb47A1b1F4488C71fc10e39a4aa56AF33Dd49",
      symbol: "UNCL",
      name: "UNCL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2f4eb47a1b1f4488c71fc10e39a4aa56af33dd49/9757dd823f405e8892dbabc564478405.png",
      coingeckoId: "uncl",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2eDf094dB69d6Dcd487f1B3dB9febE2eeC0dd4c5",
      symbol: "ZEE",
      name: "ZeroSwapToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5/7b08bd3f7b9ee31340ffadc3abab00af.png",
      coingeckoId: "zeroswap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2e9d63788249371f1DFC918a52f8d799F4a38C94",
      symbol: "TOKE",
      name: "TOKE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17495/small/tokemak-avatar-200px-black.png?1628131614",
      coingeckoId: "tokemak",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x2e7B0d4F9B2EaF782eD3D160e3a0a4b1a7930aDA",
      symbol: "CERES",
      name: "Ceres",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2e7b0d4f9b2eaf782ed3d160e3a0a4b1a7930ada/b6a3d7277309ac5ac870195e86037a4f.png",
      coingeckoId: "ceres",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2e2364966267B5D7D2cE6CD9A9B5bD19d9C7C6A9",
      symbol: "VOICE",
      name: "Voice Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9/0a23c6cdbf48c91854b78e7fa653410e.png",
      coingeckoId: "nix-bridge-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14",
      symbol: "MTLX",
      name: "Mettalex",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14/a7a08a44a5830957cba3879d30ab1962.png",
      coingeckoId: "mettalex",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2dA719DB753dFA10a62E140f436E1d67F2ddB0d6",
      symbol: "CERE",
      name: "CERE Network",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2da719db753dfa10a62e140f436e1d67f2ddb0d6/9f2184bc9484f8e2d64b67c7fa7ba73b.png",
      coingeckoId: "cere-network",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198",
      symbol: "BANK",
      name: "Bankless Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198/83da02d114aa95ca785cbd6a51635280.png",
      coingeckoId: "bankless-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x2baEcDf43734F22FD5c152DB08E3C27233F0c7d2",
      symbol: "OMv1",
      name: "OM Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x2baecdf43734f22fd5c152db08e3c27233f0c7d2/36483ee7d1330cda50b3a2aacc900a58.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2ba592F78dB6436527729929AAf6c908497cB200",
      symbol: "CREAM",
      name: "Cream",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2ba592f78db6436527729929aaf6c908497cb200/a5587c34049aadbc312d1847428235d9.png",
      coingeckoId: "cream-2",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x2b95A1Dcc3D405535f9ed33c219ab38E8d7e0884",
      symbol: "aCRV",
      name: "Aladdin cvxCRV",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2b95a1dcc3d405535f9ed33c219ab38e8d7e0884/6cf5b1bce4ec79f2cdf773b127c96e85.png",
      coingeckoId: "aladdin-cvxcrv",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2b915b505c017ABb1547aA5Ab355FbE69865cC6D",
      symbol: "MAPS",
      name: "Maps.me Token",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2b915b505c017abb1547aa5ab355fbe69865cc6d/9b3cdcc28be6d8849d9db4307db30c18.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2b867efD2dE4Ad2B583Ca0CB3dF9C4040Ef4D329",
      symbol: "LBlock",
      name: "LuckyBlock",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2b867efd2de4ad2b583ca0cb3df9c4040ef4d329/7809d8b98865bb3b21e98e2974c3130f.png",
      coingeckoId: "lucky-block",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
      symbol: "HEX",
      name: "HEX",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/10103/small/HEX-logo.png?1575942673",
      coingeckoId: "hex",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6",
      symbol: "BED",
      name: "Bankless BED Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6/915cb2de38c555c6ac912b3a17e734ff.png",
      coingeckoId: "bankless-bed-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2aECCB42482cc64E087b6D2e5Da39f5A7A7001f8",
      symbol: "RULER",
      name: "Ruler Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8/2198e07c2355b1bf1d0e20698ce28b77.png",
      coingeckoId: "ruler-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x2a95FE4c7e64e09856989F9eA0b57B9AB5f770CB",
      symbol: "wSPA",
      name: "Wrapped Sperax",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2a95fe4c7e64e09856989f9ea0b57b9ab5f770cb/244b9e834fd1c926010d85f6a46a4699.png",
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x2a3bFF78B79A009976EeA096a51A948a3dC00e34",
      symbol: "WILD",
      name: "Wilder",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x2a3bff78b79a009976eea096a51a948a3dc00e34/logo.png",
      coingeckoId: "wilder-world",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x2F9411088cEF82Fd9fB904Eb8092f28eB485C8F6",
      symbol: "ATH",
      name: "Athens",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2f9411088cef82fd9fb904eb8092f28eb485c8f6/a149497f1db35d7b5fc3df5e13c45ea4.png",
      coingeckoId: "athens",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2F109021aFe75B949429fe30523Ee7C0D5B27207",
      symbol: "OCC",
      name: "OCC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2f109021afe75b949429fe30523ee7c0d5b27207/1ed042d07ebce74bf503fc90df281b65.png",
      coingeckoId: "occamfi",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x2Db6c82CE72C8d7D770ba1b5F5Ed0b6E075066d6",
      symbol: "crAMP",
      name: "Cream Amp",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x2db6c82ce72c8d7d770ba1b5f5ed0b6e075066d6.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2C974B2d0BA1716E644c1FC59982a89DDD2fF724",
      symbol: "VIB",
      name: "Viberate",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724/0b3def7fa7f625ce55c5aec3a3dd0393.png",
      coingeckoId: "viberate",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x2C537E5624e4af88A7ae4060C022609376C8D0EB",
      symbol: "TRYB",
      name: "BiLira",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2c537e5624e4af88a7ae4060c022609376c8d0eb/75a023de93f6f4de0c2155cb0e1ef5ef.png",
      coingeckoId: "bilira",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x2C4e8f2D746113d0696cE89B35F0d8bF88E0AEcA",
      symbol: "OST",
      name: "Open Simple Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca/95a8f5bfe046efec4406061346b30466.png",
      coingeckoId: "simple-token",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x2BA8349123de45E931a8C8264c332E6e9CF593F9",
      symbol: "BCMC",
      name: "Blockchain Monster Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2ba8349123de45e931a8c8264c332e6e9cf593f9/37f9af3762afccfdaae3c86b38d8bb5b.png",
      coingeckoId: "blockchain-monster-hunt",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2B89bF8ba858cd2FCee1faDa378D5cd6936968Be",
      symbol: "WSCRT",
      name: "Wrapped SCRT",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/wscrt.jpg",
      coingeckoId: "secret-erc20",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x2B645A57e82123796c3EA7d78DE27c3e3ff7f016",
      symbol: "MINER",
      name: "Meta Miner",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2b645a57e82123796c3ea7d78de27c3e3ff7f016/e6478e1556291b8ee3a4fe9bfc60c248.png",
      coingeckoId: "meta-miner",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2B4200A8D373d484993C37d63eE14AeE0096cd12",
      symbol: "USDFL",
      name: "USDFreeLiquidity",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2b4200a8d373d484993c37d63ee14aee0096cd12/6647dac5e3e3487d6a7089cec0ecff03.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2Ab6Bb8408ca3199B8Fa6C92d5b455F820Af03c4",
      symbol: "TONE",
      name: "TE-FOOD/TustChain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4/5432c2ba2703ba5f27be243dcd76381f.png",
      coingeckoId: "te-food",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2AF5D2aD76741191D15Dfe7bF6aC92d4Bd912Ca3",
      symbol: "LEO",
      name: "Bitfinex LEO Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3/2d87fece1c43726c0190648a39c55697.png",
      coingeckoId: "leo-token",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x2A8e1E676Ec238d8A992307B495b45B3fEAa5e86",
      symbol: "OUSD",
      name: "Origin Dollar",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2a8e1e676ec238d8a992307b495b45b3feaa5e86/fca5001352a667ed2f7445d27748930b.png",
      coingeckoId: "origin-dollar",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x2A537Fa9FFaea8C1A41D3C2B68a9cb791529366D",
      symbol: "crDPI",
      name: "Cream DefiPulse Index",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x2a537fa9ffaea8c1a41d3c2b68a9cb791529366d.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2A039B1D9bBDccBb91be28691b730ca893e5e743",
      symbol: "RNB",
      name: "Rentible",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2a039b1d9bbdccbb91be28691b730ca893e5e743/a88dbde608a236725d9f0c1df51fb1f6.png",
      coingeckoId: "rentible",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x29CeDDCF0Da3c1D8068a7DFbD0FB06c2E438FF70",
      symbol: "FREL",
      name: "FREL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x29ceddcf0da3c1d8068a7dfbd0fb06c2e438ff70/f987aa24d085cef1d8f66c6c4b49b8ca.png",
      coingeckoId: "freela",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x29CbD0510EEc0327992CD6006e63F9Fa8E7f33B7",
      symbol: "TIDAL",
      name: "Tidal Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7/0a5b989cb894a17221b8974c666c3e05.png",
      coingeckoId: "tidal-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x299698B4B44BD6d023981A7317798dEe12860834",
      symbol: "NFP",
      name: "New Frontier Presents",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x299698b4b44bd6d023981a7317798dee12860834/45c146f9b07e4475964df2e15bad0262.png",
      coingeckoId: "new-frontier-presents",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x298d492e8c1d909D3F63Bc4A36C66c64ACB3d695",
      symbol: "PBR",
      name: "PolkaBridge",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x298d492e8c1d909d3f63bc4a36c66c64acb3d695/a6c988630ff31d379f7c231514323609.png",
      coingeckoId: "polkabridge",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x297D33e17e61C2Ddd812389C2105193f8348188a",
      symbol: "$TRDL",
      name: "Strudel Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x297d33e17e61c2ddd812389c2105193f8348188a/d4cb78e2d6952009211d2aff99372426.png",
      coingeckoId: "strudel-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x295B42684F90c77DA7ea46336001010F2791Ec8c",
      symbol: "XI",
      name: "Xi Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x295b42684f90c77da7ea46336001010f2791ec8c/517c969596b00868205364a69df792b8.png",
      coingeckoId: "xi-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x28dee01D53FED0Edf5f6E310BF8Ef9311513Ae40",
      symbol: "XBP",
      name: "BlitzPredict",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x28dee01d53fed0edf5f6e310bf8ef9311513ae40/bd829cada68585a203c572978fca0050.png",
      coingeckoId: "blitzpredict",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x28cb7e841ee97947a86B06fA4090C8451f64c0be",
      symbol: "YFL",
      name: "YFLink",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x28cb7e841ee97947a86b06fa4090c8451f64c0be/df942140382c5b492c7ffe3419010b49.png",
      coingeckoId: "yflink",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x28c5805B64d163588A909012a628b5a03c1041f9",
      symbol: "CHOPPER",
      name: "CHOPPER INU",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x28c5805b64d163588a909012a628b5a03c1041f9/19dde58082e7cddc1c1148bde1fe4fb7.png",
      coingeckoId: "chopper-inu",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x28b5E12CcE51f15594B0b91d5b5AdaA70F684a02",
      symbol: "NPX",
      name: "NapoleonX Token",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x28b5e12cce51f15594b0b91d5b5adaa70f684a02/5487cc4d6ad3dfc21df5614080629645.png",
      coingeckoId: "napoleon-x",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x28A06c02287e657ec3F8e151A13C36A1D43814b0",
      symbol: "BAG",
      name: "BAG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x28a06c02287e657ec3f8e151a13c36a1d43814b0/2ec6cbe71d96353ead07044bdf533788.png",
      coingeckoId: "bondappetit-gov-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x27f610BF36ecA0939093343ac28b1534a721DBB4",
      symbol: "WAND",
      name: "WandX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x27f610bf36eca0939093343ac28b1534a721dbb4.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x27C70Cd1946795B66be9d954418546998b546634",
      symbol: "LEASH",
      name: "DOGE KILLER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x27c70cd1946795b66be9d954418546998b546634/e2b22820fe3164795e5803e3ef64a9d0.png",
      coingeckoId: "leash",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x27C4af9A860c4caDc358005F8b48140b2E434A7B",
      symbol: "VALX",
      name: "Validator",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x2791BfD60D232150Bff86b39B7146c0eaAA2BA81",
      symbol: "BiFi",
      name: "BiFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2791bfd60d232150bff86b39b7146c0eaaa2ba81/26cddd98183532e6e2179fe3e1c3c38e.png",
      coingeckoId: "bifi",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x27702a26126e0B3702af63Ee09aC4d1A084EF628",
      symbol: "ALEPH",
      name: "aleph.im v2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x27702a26126e0b3702af63ee09ac4d1a084ef628/e3caf1342194c92f5c014869289a1a4e.png",
      coingeckoId: "aleph",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x275f5Ad03be0Fa221B4C6649B8AeE09a42D9412A",
      symbol: "MONA",
      name: "Monavale",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a/c23ca9e057842321ba16b3ed4c64c48e.png",
      coingeckoId: "monavale",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2730d6FdC86C95a74253BefFaA8306B40feDecbb",
      symbol: "UNI",
      name: "UNICORN",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2730d6fdc86c95a74253beffaa8306b40fedecbb/2742e8a24087c913abdbec439e3e8deb.png",
      coingeckoId: "unicorn-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x27054b13b1B798B345b591a4d22e6562d47eA75a",
      symbol: "AST",
      name: "AirSwap",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x27054b13b1b798b345b591a4d22e6562d47ea75a/8193d103d453bab7acd302740b9cd0a1.png",
      coingeckoId: "airswap",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x26dcFbFa8Bc267b250432c01C982Eaf81cC5480C",
      symbol: "aMATICc",
      name: "Ankr MATIC Reward Bearing Certificate",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1653739570902_2935634424552658.png",
      coingeckoId: "ankr-reward-earning-matic",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x26c8AFBBFE1EBaca03C2bB082E69D0476Bffe099",
      symbol: "CELL",
      name: "Cellframe Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099/4d8ee3523ddfca915232aa5295415f42.png",
      coingeckoId: "cellframe",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x26EA744E5B887E5205727f55dFBE8685e3b21951",
      symbol: "yUSDCv3",
      name: "iearn USDC v3",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x26ea744e5b887e5205727f55dfbe8685e3b21951.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x26CE25148832C04f3d7F26F32478a9fe55197166",
      symbol: "DEXT",
      name: "DEXTools",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x26ce25148832c04f3d7f26f32478a9fe55197166/3f0419a67cc7057c7577d0f5e6dcf273.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x26607aC599266b21d13c7aCF7942c7701a8b699c",
      symbol: "PIPT",
      name: "Power Index Pool Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x26607ac599266b21d13c7acf7942c7701a8b699c/853db6384187a2d36838b1a08b90617f.png",
      coingeckoId: "power-index-pool-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x2610F0bFC21EF389fe4D03CFB7De9ac1E6C99D6E",
      symbol: "SKYRIM",
      name: "Skyrim Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x2610F0bFC21EF389fe4D03CFB7De9ac1E6C99D6E/logo.png",
      coingeckoId: "skyrim-finance",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x260e63d91fCCC499606BAe3FE945c4ed1CF56A56",
      symbol: "MOONS",
      name: "MoonTools.io",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x260e63d91fccc499606bae3fe945c4ed1cf56a56/41144b74268553774c9d3410b08d9146.png",
      coingeckoId: "moontools",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2602278EE1882889B946eb11DC0E810075650983",
      symbol: "VADER",
      name: "Vader",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2602278ee1882889b946eb11dc0e810075650983/27b1f97f288f48322d3e02e37577b2fd.png",
      coingeckoId: "vader-protocol",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x25f8087EAD173b73D6e8B84329989A8eEA16CF73",
      symbol: "YGG",
      name: "YGG",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17358/small/le1nzlO6_400x400.jpg?1632465691",
      coingeckoId: "yield-guild-games",
      listedIn: [
        "coingecko",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x25e1474170c4c0aA64fa98123bdc8dB49D7802fa",
      symbol: "BID",
      name: "Bidao",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x25e1474170c4c0aa64fa98123bdc8db49d7802fa/c24ca2d3d29e062ee19cd5589d263465.png",
      coingeckoId: "bidao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x25B6325f5BB1c1E03cfbC3e53F470E1F1ca022E3",
      symbol: "LML",
      name: "LML",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x25b6325f5bb1c1e03cfbc3e53f470e1f1ca022e3/8d779eecad9e6c336f9ccc03b83a5e2b.png",
      coingeckoId: "link-machine-learning",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x259AB9b9EAB62b0fD98729B97BE121073D5B3479",
      symbol: "EST",
      name: "Erica Social Token",
      decimals: 18,
      chainId: "1",
      logoURI: "https://ethereum-optimism.github.io/data/EST/logo.png",
      coingeckoId: "erica-social-token",
      listedIn: ["coingecko", "optimism"],
    },
    {
      address: "0x2590F1fD14Ef8Bb0A46C7A889c4CBc146510f9C3",
      symbol: "iBTCV",
      name: "Inverse BTC Volatility Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x2590f1fd14ef8bb0a46c7a889c4cbc146510f9c3/6f429cdae97076e2baf491fd9377fb3e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x2565ae0385659badCada1031DB704442E1b69982",
      symbol: "ASM",
      name: "Assemble Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x2565ae0385659badcada1031db704442e1b69982/13f3edb7c875fbb5b1d3804c08d14e8d.png",
      coingeckoId: "assemble-protocol",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6",
      symbol: "RDN",
      name: "Raiden Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6/23539e1757c26a6d9bff55b9f3b117ee.png",
      coingeckoId: "raiden-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x25555933a8246Ab67cbf907CE3d1949884E82B55",
      symbol: "crSUSD",
      name: "Cream Synth sUSD",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x25555933a8246ab67cbf907ce3d1949884e82b55.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x25377ddb16c79C93B0CBf46809C8dE8765f03FCd",
      symbol: "SBREE",
      name: "Synthetic CBDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x25377ddb16c79c93b0cbf46809c8de8765f03fcd.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x24aE124c4CC33D6791F8E8B63520ed7107ac8b3e",
      symbol: "ESS",
      name: "Empty Set Share",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x24EC2Ca132abf8F6f8a6E24A1B97943e31f256a7",
      symbol: "MOOV",
      name: "dotmoovs",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x24ec2ca132abf8f6f8a6e24a1b97943e31f256a7/c2d15ea137df2aeff93988ab48f2bdb1.png",
      coingeckoId: "dotmoovs",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x24E89bDf2f65326b94E36978A7EDeAc63623DAFA",
      symbol: "TKING",
      name: "Tiger King",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x24e89bdf2f65326b94e36978a7edeac63623dafa/765eb458727ff062c515f7ce20226aaa.png",
      coingeckoId: "tiger-king",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x24A6A37576377F63f194Caa5F518a60f45b42921",
      symbol: "BANK",
      name: "Float Bank",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x24a6a37576377f63f194caa5f518a60f45b42921/8a2eaa4bbce8a8dc5018e98a1c84698e.png",
      coingeckoId: "float-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x249e38Ea4102D0cf8264d3701f1a0E39C4f2DC3B",
      symbol: "UFO",
      name: "UFO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/16801/small/ufo.png?1644048038",
      coingeckoId: "ufo-gaming",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x243cACb4D5fF6814AD668C3e225246efA886AD5a",
      symbol: "SHI",
      name: "Shina Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x243cacb4d5ff6814ad668c3e225246efa886ad5a/746f60ee390fc8193d706fc81b0068eb.png",
      coingeckoId: "shina-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x23B608675a2B2fB1890d3ABBd85c5775c51691d5",
      symbol: "SOCKS",
      name: "Unisocks Edition 0",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x23b608675a2b2fb1890d3abbd85c5775c51691d5/101fce3b8d1a266c1c9575ada3742ef7.png",
      coingeckoId: "unisocks",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x23894DC9da6c94ECb439911cAF7d337746575A72",
      symbol: "JAM",
      name: "Geojam",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x23894dc9da6c94ecb439911caf7d337746575a72/d59b49a392e725fefd2b7280922757f6.png",
      coingeckoId: "geojam",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2370f9d504c7a6E775bf6E14B3F12846b594cD53",
      symbol: "JPYC",
      name: "JPY Coin",
      decimals: 18,
      chainId: "1",
      logoURI: "https://jpyc.jp/static/media/jpyc.0d1e5d3f.png",
      coingeckoId: "jpyc",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x234D51eE02be808A0160b19b689660Fb7BFA871b",
      symbol: "SCAN",
      name: "CoinScan",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x234d51ee02be808a0160b19b689660fb7bfa871b/911062705a13441db28ae466d68832b8.png",
      coingeckoId: "coinscan",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x232FB065D9d24c34708eeDbF03724f2e95ABE768",
      symbol: "SHEESHA",
      name: "Sheesha Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x232fb065d9d24c34708eedbf03724f2e95abe768/a0032ae0901868f3a1713f4b46564994.png",
      coingeckoId: "sheesha-finance-erc20",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x22B48E1f20043D1Db5F2a11CbF1d520A4F20b198",
      symbol: "XOT",
      name: "Okuru Token",
      decimals: 18,
      chainId: "1",
      logoURI: "https://assets.coingecko.com/coins/images/25473/large/xot.png",
      coingeckoId: "okuru",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x22B243B96495C547598D9042B6f94B01C22B2e9E",
      symbol: "crSWAG",
      name: "Cream Swag Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x22b243b96495c547598d9042b6f94b01c22b2e9e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x228619CCa194Fbe3Ebeb2f835eC1eA5080DaFbb2",
      symbol: "crXSUSHI",
      name: "Cream SushiBar",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x228619cca194fbe3ebeb2f835ec1ea5080dafbb2.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x226f7b842E0F0120b7E194D05432b3fd14773a9D",
      symbol: "UNN",
      name: "UNION Protocol Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x226f7b842e0f0120b7e194d05432b3fd14773a9d/353c0f098023ed2b6984fad42e786f36.png",
      coingeckoId: "union-protocol-governance-token",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x226bb599a12C826476e3A771454697EA52E9E220",
      symbol: "PRO",
      name: "Propy",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x226bb599a12c826476e3a771454697ea52e9e220/b464df107445a0c10f01d709bae164de.png",
      coingeckoId: "propy",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      symbol: "WBTC",
      name: "WBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744",
      coingeckoId: "wrapped-bitcoin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x2216e873ea4282EbEf7A02aC5aeA220bE6391A7C",
      symbol: "SMOL",
      name: "smol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x2216e873ea4282ebef7a02ac5aea220be6391a7c.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x221657776846890989a759BA2973e427DfF5C9bB",
      symbol: "REPv2",
      name: "Reputation Augur v2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x221657776846890989a759ba2973e427dff5c9bb/bea16a7f49aabad64856eb8f63a4fb53.png",
      coingeckoId: "augur",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x220B71671b649c03714dA9c621285943f3cbcDC6",
      symbol: "DIS",
      name: "TosDis",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x220b71671b649c03714da9c621285943f3cbcdc6/7a64e1a4bdfae50706fa09202015f26b.png",
      coingeckoId: "tosdis",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x21BfBDa47A0B4B5b1248c767Ee49F7caA9B23697",
      symbol: "OVR",
      name: "OVR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697/84a37db9f8cdb97262e490dfd916eecc.png",
      coingeckoId: "ovr",
      listedIn: ["coingecko", "1inch", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x217ddEad61a42369A266F1Fb754EB5d3EBadc88a",
      symbol: "DON",
      name: "Donkey",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x217ddead61a42369a266f1fb754eb5d3ebadc88a/8903590b7b174a51fd7bc08065ec5002.png",
      coingeckoId: "don-key",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2163383C1F4E74fE36c50E6154C7F18d9Fd06d6f",
      symbol: "TIC",
      name: "ElasticSwap Tic Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x2163383c1f4e74fe36c50e6154c7f18d9fd06d6f.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x21413c119b0C11C5d96aE1bD328917bC5C8ED67E",
      symbol: "GENE",
      name: "GenomesDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x21413c119b0C11C5d96aE1bD328917bC5C8ED67E/logo.png",
      coingeckoId: "genomesdao",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x21011BC93d9E515B9511A817A1eD1D6d468f49Fc",
      symbol: "crCOVER",
      name: "Cream Cover Protocol Governance Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x21011bc93d9e515b9511a817a1ed1d6d468f49fc.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x20e7125677311Fca903A8897042b9983f22Ea295",
      symbol: "FWT",
      name: "FreewayToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x20e7125677311fca903a8897042b9983f22ea295/5eab5d0bea810142965d1a9488305070.png",
      coingeckoId: "freeway",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x20c36f062a31865bED8a5B1e512D9a1A20AA333A",
      symbol: "DFD",
      name: "DefiDollar DAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x20c36f062a31865bed8a5b1e512d9a1a20aa333a/edbff7cd76bcfcb32e7c61cbae342a57.png",
      coingeckoId: "defidollar-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x20F7A3DdF244dc9299975b4Da1C39F8D5D75f05A",
      symbol: "SPN",
      name: "Sapien Network",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a/b5491701502df83d767270739aac5b65.png",
      coingeckoId: "sapien",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x20BC832ca081b91433ff6c17f85701B6e92486c5",
      symbol: "rETH2",
      name: "StakeWise Reward ETH2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x20bc832ca081b91433ff6c17f85701b6e92486c5/44a8ed77ddeb8d5bf66c69440cde19e3.png",
      coingeckoId: "reth2",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x20945cA1df56D237fD40036d47E866C7DcCD2114",
      symbol: "Nsure",
      name: "Nsure Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x20945ca1df56d237fd40036d47e866c7dccd2114/fc990869225451d1acee14d8b044c42f.png",
      coingeckoId: "nsure-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1fE24F25b1Cf609B9c4e7E12D802e3640dFA5e43",
      symbol: "CGG",
      name: "ChainGuardians Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43/29057aa371159fdf85f3a4905d7d2114.png",
      coingeckoId: "chain-guardians",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x1fDaB294EDA5112B7d066ED8F2E4E562D5bCc664",
      symbol: "SPICE",
      name: "Spice",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1fdab294eda5112b7d066ed8f2e4e562d5bcc664/e7881a5a21f3cdd0cf7d892db3e71511.png",
      coingeckoId: "spice-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      symbol: "UNI",
      name: "Uniswap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png",
      coingeckoId: "uniswap",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x1dEa979ae76f26071870F824088dA78979eb91C8",
      symbol: "SPD",
      name: "Spindle",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1dea979ae76f26071870f824088da78979eb91c8/98d50ee61c509ff24c87c41dd2cd3363.png",
      coingeckoId: "spindle",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x1dD80016e3d4ae146Ee2EBB484e8edD92dacC4ce",
      symbol: "LEAD",
      name: "Lead Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce/e9faa05d5e7451f75f28823e561c4b3a.png",
      coingeckoId: "lead-token",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x1d462414fe14cf489c7A21CaC78509f4bF8CD7c0",
      symbol: "CAN",
      name: "CanYa",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x1d462414fe14cf489c7a21cac78509f4bf8cd7c0.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x1d37986F252d0e349522EA6C3B98Cb935495E63E",
      symbol: "CHART",
      name: "ChartEx",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1d37986f252d0e349522ea6c3b98cb935495e63e/1207443990fc7e98447de692b468b289.png",
      coingeckoId: "chartex",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1d0986Fb43985c88Ffa9aD959CC24e6a087C7e35",
      symbol: "crALPHA",
      name: "Cream AlphaToken",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x1d0986fb43985c88ffa9ad959cc24e6a087c7e35.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x1cF4592ebfFd730c7dc92c1bdFFDfc3B9EfCf29a",
      symbol: "WAVES",
      name: "WAVES",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x1cf4592ebffd730c7dc92c1bdffdfc3b9efcf29a/14bdc8ac39fd6dfd41fe00a411600da3.png",
      coingeckoId: "waves",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44",
      symbol: "KP3R",
      name: "KP3R",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12966/small/kp3r_logo.jpg?1607057458",
      coingeckoId: "keep3rv1",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x1cBb83EbcD552D5EBf8131eF8c9CD9d9BAB342bC",
      symbol: "NFY",
      name: "Non-Fungible Yearn",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc/806d289902dc204dbe67460d10c11ccf.png",
      coingeckoId: "non-fungible-yearn",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1c7E83f8C581a967940DBfa7984744646AE46b29",
      symbol: "RND",
      name: "random",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1c7e83f8c581a967940dbfa7984744646ae46b29/bff4caabe75d2e8a552763f5e5cff42a.png",
      coingeckoId: "the-randomdao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1c79ab32C66aCAa1e9E81952B8AAa581B43e54E7",
      symbol: "TEAM",
      name: "TEAM",
      decimals: 4,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x1c48f86ae57291F7686349F12601910BD8D470bb",
      symbol: "USDK",
      name: "USDK",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1c48f86ae57291f7686349f12601910bd8d470bb/77f813b8a26660659308d6a65967f210.png",
      coingeckoId: "usdk",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421",
      symbol: "VSP",
      name: "VesperToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1b40183efb4dd766f11bda7a7c3ad8982e998421/3050418a803e9a188324a853e214b648.png",
      coingeckoId: "vesper-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c",
      symbol: "EUROC",
      name: "Euro Coin",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1abaea1f7c830bd89acc67ec4af516284b1bc33c/08e1b4214f9f2c9a0744c74ff08761f1.png",
      coingeckoId: "euro-coin",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance"],
    },
    {
      address: "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
      symbol: "AGEUR",
      name: "AGEUR",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/19479/small/agEUR.png?1635283566",
      coingeckoId: "ageur",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x1FbD3dF007eB8A7477A1Eab2c63483dCc24EfFD6",
      symbol: "SCA",
      name: "ScaleSwapToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6/1971261459e7f9fc0ffc012f43ae280e.png",
      coingeckoId: "scaleswap-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1FF8CDB51219a8838b52E9cAc09b71e591BC998e",
      symbol: "crBUSD",
      name: "Cream Binance USD",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x1ff8cdb51219a8838b52e9cac09b71e591bc998e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x1FEE5588cb1De19c70B6aD5399152D8C643FAe7b",
      symbol: "PHTK",
      name: "PhunToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1fee5588cb1de19c70b6ad5399152d8c643fae7b/d96601d1118144c1906c0fca12d91c64.png",
      coingeckoId: "phuntoken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1FCdcE58959f536621d76f5b7FfB955baa5A672F",
      symbol: "FOR",
      name: "The Force Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1fcdce58959f536621d76f5b7ffb955baa5a672f/897b1a4e52f1e570a20f15618c74a7b7.png",
      coingeckoId: "force-protocol",
      listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
    },
    {
      address: "0x1F8A626883d7724DBd59eF51CBD4BF1Cf2016D13",
      symbol: "STAK",
      name: "Jigstack",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13/a368214eff1db278145795386f115ec0.png",
      coingeckoId: "jigstack",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
      symbol: "BNT",
      name: "Bancor",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png",
      coingeckoId: "bancor",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x1F3f9D3068568F8040775be2e8C03C103C61f3aF",
      symbol: "ARCH",
      name: "Archer DAO Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x1f3f9d3068568f8040775be2e8c03c103c61f3af/6d81502a2ad1d58351a75facb590edea.png",
      coingeckoId: "archer-dao-governance-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1F31DD60b84ea2a273cfC56876437E069aE80afd",
      symbol: "SHINJI",
      name: "Shinji Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1f31dd60b84ea2a273cfc56876437e069ae80afd/1fa52014197b3e48cb3ba4074a58285f.png",
      coingeckoId: "shinji-inu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1E4EDE388cbc9F4b5c79681B7f94d36a11ABEBC9",
      symbol: "X2Y2",
      name: "X2Y2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/23633/small/logo-60b81ff87b40b11739105acf5ad1e075.png?1644903256",
      coingeckoId: "x2y2",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "xyfinance"],
    },
    {
      address: "0x1E2F15302B90EddE696593607b6bD444B64e8F02",
      symbol: "Shiryo-Inu",
      name: "Shiryo-Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1e2f15302b90edde696593607b6bd444b64e8f02/b0b6f08eb58c1f8ae193ded0a68ecbab.png",
      coingeckoId: "shiryo-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9",
      symbol: "KOMPETE",
      name: "KOMPETE Token",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9/fece32e1ae651bb09ca10bedaf29fd97.png",
      coingeckoId: "kompete",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0",
      symbol: "EQZ",
      name: "Equalizer",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0/28d6953cd9638a625a115d0b397a6f72.png",
      coingeckoId: "equalizer",
      listedIn: ["coingecko", "1inch", "optimism", "lifinance"],
    },
    {
      address: "0x1DE5e000C41C8d35b9f1f4985C23988f05831057",
      symbol: "BNF",
      name: "BonFi",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1de5e000c41c8d35b9f1f4985c23988f05831057/5a83f1bab07f8f24492b9bddcbe4bde6.png",
      coingeckoId: "bonfi",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x1CDD2EaB61112697626F7b4bB0e23Da4FeBF7B7C",
      symbol: "USDT",
      name: "USDT (Wormhole)",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1cdd2eab61112697626f7b4bb0e23da4febf7b7c/8d38b2fe4d7cf49ebcc94effcba9fc75.png",
      coingeckoId: "tether-usd-wormhole",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1CCAA0F2a7210d76E1fDec740d5F323E2E1b1672",
      symbol: "FACE",
      name: "Faceter Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1ccaa0f2a7210d76e1fdec740d5f323e2e1b1672/6860951fb55575f0fa0f337b6e2f65fe.png",
      coingeckoId: "face",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1CC30e2EAc975416060Ec6FE682041408420d414",
      symbol: "KOL",
      name: "Kollect",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1cc30e2eac975416060ec6fe682041408420d414/5fc9f758bd27fdf38b6d75c7b8f13504.png",
      coingeckoId: "kollect",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x1C9922314ED1415c95b9FD453c3818fd41867d0B",
      symbol: "TOWER",
      name: "TOWER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1c9922314ed1415c95b9fd453c3818fd41867d0b/ef1f53cfc0238001c099cdc939f671e6.png",
      coingeckoId: "tower",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1C5db575E2Ff833E46a2E9864C22F4B22E0B37C2",
      symbol: "renZEC",
      name: "renZEC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2/53ee250886300a835e8f18a91e34aa30.png",
      coingeckoId: "renzec",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x1C4853Ec0d55e420002c5eFaBc7Ed8e0bA7A4121",
      symbol: "Okinami",
      name: "Kanagawa Nami",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1c4853ec0d55e420002c5efabc7ed8e0ba7a4121/7219bd8592f4ae49f8a4e7e5225c4f83.png",
      coingeckoId: "kanagawa-nami",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1BeEF31946fbbb40B877a72E4ae04a8D1A5Cee06",
      symbol: "PAR",
      name: "Parachute",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06/e660420e329c3f3d69ba724f0062e4b1.png",
      coingeckoId: "parachute",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x1BBf25e71EC48B84d773809B4bA55B6F4bE946Fb",
      symbol: "VOW",
      name: "Vow",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1bbf25e71ec48b84d773809b4ba55b6f4be946fb/9cc600ed29bb6c8ae5985fbc61314d35.png",
      coingeckoId: "vow",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1A5F9352Af8aF974bFC03399e3767DF6370d82e4",
      symbol: "OWL",
      name: "OWL Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1a5f9352af8af974bfc03399e3767df6370d82e4/f3316f30f8de8b62bdc250a6042a059a.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5",
      symbol: "BIT",
      name: "BitDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5/7fae71ae942c89a76868d832bc2ca5ef.png",
      coingeckoId: "bitdao",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x1A3496C18d558bd9C6C8f609E1B129f67AB08163",
      symbol: "DEP",
      name: "DEAPCOIN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1a3496c18d558bd9c6c8f609e1b129f67ab08163/7f36d5ad53cd19789a3a1236d45c5833.png",
      coingeckoId: "deapcoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x19de6b897Ed14A376Dda0Fe53a5420D2aC828a28",
      symbol: "BGB",
      name: "BitgetToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x19de6b897ed14a376dda0fe53a5420d2ac828a28/03c5da3777ac1764dccef5a8606f9eef.png",
      coingeckoId: "bitget-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x19E2a43Fbbc643C3B2D9667D858D49cAd17bc2b5",
      symbol: "BNS",
      name: "Bitbns Token",
      decimals: 8,
      chainId: "1",
      logoURI: null,
      coingeckoId: "bns-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x19D97D8fA813EE2f51aD4B4e04EA08bAf4DFfC28",
      symbol: "bBADGER",
      name: "Badger Sett Badger",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/bbadger.jpg",
      coingeckoId: "badger-sett-badger",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x19D1666f543D42ef17F66E376944A22aEa1a8E46",
      symbol: "crCOMP",
      name: "Cream Compound",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x19d1666f543d42ef17f66e376944a22aea1a8e46.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x1985365e9f78359a9B6AD760e32412f4a445E862",
      symbol: "REP",
      name: "Reputation Augur v1",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1985365e9f78359a9b6ad760e32412f4a445e862/c6cfb95f0d6e9058f55b660b0361b7fa.png",
      coingeckoId: null,
      listedIn: ["uniswap", "lifinance"],
    },
    {
      address: "0x197070723CE0D3810a0E47F06E935c30a480D4Fc",
      symbol: "crWBTC",
      name: "Cream Wrapped BTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x197070723ce0d3810a0e47f06e935c30a480d4fc.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x196f4727526eA7FB1e17b2071B3d8eAA38486988",
      symbol: "RSV",
      name: "Reserve",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x196f4727526ea7fb1e17b2071b3d8eaa38486988/80e37352cfd4a10b3d2cf392572c69c9.png",
      coingeckoId: "reserve",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x196c81385Bc536467433014042788Eb707703934",
      symbol: "CTASK",
      name: "CTASK Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x196c81385bc536467433014042788eb707703934/af18603eb31136834685e0f7fa4309df.png",
      coingeckoId: "cryptotask-2",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x191557728e4d8CAa4Ac94f86af842148c0FA8F7E",
      symbol: "ECO",
      name: "ECO TOKEN",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x191557728e4d8caa4ac94f86af842148c0fa8f7e/2014bdcafa20cb9d833c37a52bc7b6d2.png",
      coingeckoId: "ormeus-ecosystem",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x19042021329FDdcFBea5f934FB5b2670C91F7D20",
      symbol: "TMM",
      name: "Take My Muffin",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x19042021329fddcfbea5f934fb5b2670c91f7d20.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998",
      symbol: "AUDIO",
      name: "Audius",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x18aaa7115705e8be94bffebde57af9bfc265b998/8c4d2409af350ab696f37b9fde9d378d.png",
      coingeckoId: "audius",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x18a1Ea69A50A85752b7bC204a2c45A95Ce6E429d",
      symbol: "SPICE",
      name: "Spice",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x18a1ea69a50a85752b7bc204a2c45a95ce6e429d/e7d4e56dc3306e9d0f56566c5390ca90.png",
      coingeckoId: "spice-trade",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x182F4c4C97cd1c24E1Df8FC4c053E5C47bf53Bef",
      symbol: "TANGO",
      name: "keyTango Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef/de76806d559f3744d0dd325cc1f765bb.png",
      coingeckoId: "keytango",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x17aC188e09A7890a1844E5E65471fE8b0CcFadF3",
      symbol: "CC10",
      name: "Cryptocurrency Top 10 Tokens Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3/a8401dfb7adf3b7fe7852631657db43a.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x17EF75AA22dD5f6C2763b8304Ab24f40eE54D48a",
      symbol: "RVP",
      name: "RevolutionPopuli ERC20 Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x17ef75aa22dd5f6c2763b8304ab24f40ee54d48a/bd41e831868b7bf0e41d5c457eb492fc.png",
      coingeckoId: "revolution-populi",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2",
      symbol: "PMON",
      name: "Polkamon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1796ae0b0fa4862485106a0de9b654efe301d0b2/36161c9691d74da938f5f65b3b7f2e0b.png",
      coingeckoId: "polychain-monsters",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x178c820f862B14f316509ec36b13123DA19A6054",
      symbol: "EWTB",
      name: "Energy Web Token Bridged",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x178c820f862b14f316509ec36b13123da19a6054/210eb146d8a2c74ac237d00dbac402cf.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "rubic", "lifinance"],
    },
    {
      address: "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671",
      symbol: "NMR",
      name: "Numeraire",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1776e1f26f98b1a5df9cd347953a26dd3cb46671/e50ea0a58650087f92fd826eff9149e9.png",
      coingeckoId: "numeraire",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
      ],
    },
    {
      address: "0x17525E4f4Af59fbc29551bC4eCe6AB60Ed49CE31",
      symbol: "YPIE",
      name: "PieDAO Yearn Ecosystem Pie",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/ypie.jpg",
      coingeckoId: "piedao-yearn-ecosystem-pie",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x1735Db6AB5BAa19eA55d0AdcEeD7bcDc008B3136",
      symbol: "URQA",
      name: "UREEQA Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1735db6ab5baa19ea55d0adceed7bcdc008b3136/5b564b7e63849ee94f1d0a42e5e66551.png",
      coingeckoId: "ureeqa",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x17107f40d70f4470d20CB3f138a052cAE8EbD4bE",
      symbol: "crRENBTC",
      name: "Cream renBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x17107f40d70f4470d20cb3f138a052cae8ebd4be.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x16de59092dAE5CcF4A1E6439D611fd0653f0Bd01",
      symbol: "yDAIv2",
      name: "iearn DAIv2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x16de59092dae5ccf4a1e6439d611fd0653f0bd01/a151c8cd48494726f2b090d013449c57.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x16c52CeeCE2ed57dAd87319D91B5e3637d50aFa4",
      symbol: "TCAP",
      name: "TCAP Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16c52ceece2ed57dad87319d91b5e3637d50afa4/7761568711034da572b8ad1d0d304609.png",
      coingeckoId: "total-crypto-market-cap-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x16ba8Efe847EBDFef99d399902ec29397D403C30",
      symbol: "OH",
      name: "Oh!Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16ba8efe847ebdfef99d399902ec29397d403c30/201854dfd8f3c1988bfef4c950d1d6be.png",
      coingeckoId: "oh-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4",
      symbol: "ROUTE",
      name: "Route",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4/58c3023b574e336308d3c988e367ad22.png",
      coingeckoId: "route",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x16CDA4028e9E872a38AcB903176719299beAed87",
      symbol: "MARS4",
      name: "MARS4",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x16CDA4028e9E872a38AcB903176719299beAed87/logo.png",
      coingeckoId: "mars4",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x16CC8367055aE7e9157DBcB9d86Fd6CE82522b31",
      symbol: "VXL",
      name: "Voxel X Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31/50d03816cc35b4386b8049ed002cb0d3.png",
      coingeckoId: "voxel-x-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd",
      symbol: "SMBR",
      name: "SOMBRA",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd/f3e98be73d37a4b308160a2c3a84f9aa.png",
      coingeckoId: "sombra-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x16980b3B4a3f9D89E33311B5aa8f80303E5ca4F8",
      symbol: "KEX",
      name: "KIRA Network",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8/5908c1e4a281d351c0870005e700ed8d.png",
      coingeckoId: "kira-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1681bcB589b3cFCF0c0616B0cE9b19b240643dc1",
      symbol: "ISLE",
      name: "Island",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1/76785737f8fcde932e8584857128a5b5.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x1614F18Fc94f47967A3Fbe5FfcD46d4e7Da3D787",
      symbol: "PAID",
      name: "PAID Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787/5445bca1ac21b8332c456a506136509b.png",
      coingeckoId: "paid-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05",
      symbol: "GEL",
      name: "Gelato Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05/ef7fe2b990fe14e5bb3ea2c6db902f57.png",
      coingeckoId: "gelato",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x15Ee120fD69BEc86C1d38502299af7366a41D1a6",
      symbol: "BitANT",
      name: "BitANT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x15ee120fd69bec86c1d38502299af7366a41d1a6/15d57a40bf3905da967d8b39d67120db.png",
      coingeckoId: "bitant",
      listedIn: ["coingecko", "optimism", "lifinance"],
    },
    {
      address: "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
      symbol: "GALA",
      name: "GALA",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12493/small/GALA-COINGECKO.png?1600233435",
      coingeckoId: "gala",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x15B543e986b8c34074DFc9901136d9355a537e7E",
      symbol: "STC",
      name: "Student Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x15b543e986b8c34074dfc9901136d9355a537e7e/e95b1ddbfbf6e1b86147903ccca733e4.png",
      coingeckoId: "student-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x159751323A9E0415DD3d6D42a1212fe9F4a0848C",
      symbol: "INFI",
      name: "INFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x159751323a9e0415dd3d6d42a1212fe9f4a0848c/40dd4cfc2b0dcf8c100f309d27c7a1c6.png",
      coingeckoId: "insured-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x15874d65e649880c2614e7a480cb7c9A55787FF6",
      symbol: "eMax",
      name: "EthereumMax",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x15874d65e649880c2614e7a480cb7c9a55787ff6/07cc50616e621cbf131844a8a6c0b116.png",
      coingeckoId: "ethereummax",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x158079Ee67Fce2f58472A96584A73C7Ab9AC95c1",
      symbol: "cREP",
      name: "Compound Augur",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x158079ee67fce2f58472a96584a73c7ab9ac95c1/d339ce1f61158a84683219b253e664ec.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x1559FA1b8F28238FD5D76D9f434ad86FD20D1559",
      symbol: "EDEN",
      name: "EDEN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/17470/small/Iyc0XM5-_400x400.jpg?1628254655",
      coingeckoId: "eden",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409",
      symbol: "REVO",
      name: "REVO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409/f409ef2ab52a4c44429cfd9a5af9783c.png",
      coingeckoId: "revomon",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x14d10003807AC60d07BB0ba82cAeaC8d2087c157",
      symbol: "iDEFI",
      name: "Synth iDEFI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x14d10003807ac60d07bb0ba82caeac8d2087c157/427fa7846eb2262b99ae2cecf6ab767d.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x14a32f050FACF226Ec60882398A9BF36d91DBaC2",
      symbol: "KYOKO",
      name: "KYOKO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/24454/small/kyoko.png?1649576935",
      coingeckoId: "kyoko",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x14Da7b27b2E0FedEfe0a664118b0c9bc68e2E9AF",
      symbol: "BCUG",
      name: "Blockchain Cuties Universe Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af/489cf0cfdcad49186064bcfa366a6042.png",
      coingeckoId: "blockchain-cuties-universe-governance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b",
      symbol: "DPI",
      name: "DeFi Pulse Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b/feb43f922f3e344d42afbb598d0b2e7c.png",
      coingeckoId: "defipulse-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x147faF8De9d8D8DAAE129B187F0D02D819126750",
      symbol: "GEO",
      name: "GeoDB Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x147faf8de9d8d8daae129b187f0d02d819126750/021ab33a001561205dfdac00272951c4.png",
      coingeckoId: "geodb",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1456688345527bE1f37E9e627DA0837D6f08C925",
      symbol: "USDP_2",
      name: "USDP Stablecoin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1456688345527be1f37e9e627da0837d6f08c925/0e14801261e1537a7fa4d8f7ae2a8b19.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1453Dbb8A29551ADe11D89825CA812e05317EAEB",
      symbol: "TEND",
      name: "Tendies Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1453dbb8a29551ade11d89825ca812e05317eaeb/9d37d47e5f6b1f446dc84e05d630c6b9.png",
      coingeckoId: "tendies",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x1426cC6D52D1B14e2B3b1Cb04d57ea42B39c4c7c",
      symbol: "JSHIBA",
      name: "Jomon Shiba",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1426cc6d52d1b14e2b3b1cb04d57ea42b39c4c7c/2a9c0a9ea09e9e74cf8338f3b97ce5c2.png",
      coingeckoId: "jomon-shiba",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x138C2F1123cF3f82E4596d097c118eAc6684940B",
      symbol: "ALPHA_1",
      name: "Alpha",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x138c2f1123cf3f82e4596d097c118eac6684940b/690ab515949e823d63f0ea8e24193dd1.png",
      coingeckoId: "alphacoin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1353a77abD236207D0588bCbBb52Bc3087f85351",
      symbol: "NEC",
      name: "New era",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x1337DEF18C680aF1f9f45cBcab6309562975b1dD",
      symbol: "arNXM",
      name: "Armor NXM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1337def18c680af1f9f45cbcab6309562975b1dd/15025e5b43e1f22b031dc1fae6624f78.png",
      coingeckoId: "armor-nxm",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a",
      symbol: "ARMOR",
      name: "Armor",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1337def16f9b486faed0293eb623dc8395dfe46a/fc4461c55bb72169f32dd0cc50a0c330.png",
      coingeckoId: "armor",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x13339fD07934CD674269726EdF3B5ccEE9DD93de",
      symbol: "CUR",
      name: "CurToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x13339fd07934cd674269726edf3b5ccee9dd93de/c8d9915d01346a9cc4aa13223fdbef51.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x1321f1f1aa541A56C31682c57b80ECfCCd9bB288",
      symbol: "ARCX",
      name: "ARCx Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1321f1f1aa541a56c31682c57b80ecfccd9bb288/630bd2c6a6357a611441ff6998d126db.png",
      coingeckoId: "arc-governance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x131157c6760f78f7dDF877C0019Eba175BA4b6F6",
      symbol: "BigSB",
      name: "BigShortBets",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x131157c6760f78f7ddf877c0019eba175ba4b6f6/634ac15cb8fd1f5cac09d9fe3e90e0dc.png",
      coingeckoId: "bigshortbets",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x12f649A9E821F90BB143089a6e56846945892ffB",
      symbol: "uDOO",
      name: "uDOO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x12f649a9e821f90bb143089a6e56846945892ffb/2d61abc61f925be84e47540e399873fb.png",
      coingeckoId: "howdoo",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x12e51E77DAAA58aA0E9247db7510Ea4B46F9bEAd",
      symbol: "aYFI",
      name: "Aave Interest bearing YFI",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmauhqAKU8YLhDhT4M5ZcPMuqEfqkBrBaC31uWC9UXd1ik",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x12D102F06da35cC0111EB58017fd2Cd28537d0e1",
      symbol: "VOX",
      name: "Vox.Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x12d102f06da35cc0111eb58017fd2cd28537d0e1/a06a5be912bb08b46a125c821161c66f.png",
      coingeckoId: "vox-finance",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x12BB890508c125661E03b09EC06E404bc9289040",
      symbol: "RACA",
      name: "Radio Caca V2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x12bb890508c125661e03b09ec06e404bc9289040/262c72d5ea3fa285a3c98747fb9e0be3.png",
      coingeckoId: "radio-caca",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD",
      symbol: "GRID",
      name: "GRID",
      decimals: 12,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd/92fe4bc3f1f741b037124d36edca356c.png",
      coingeckoId: "grid",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x126c121f99e1E211dF2e5f8De2d96Fa36647c855",
      symbol: "DEGEN",
      name: "DEGEN Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x126c121f99e1e211df2e5f8de2d96fa36647c855/b804181fa47f9173d2f7c7e3dd711758.png",
      coingeckoId: "degen-index",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x1234567461d3f8Db7496581774Bd869C83D51c93",
      symbol: "CAT",
      name: "BitClave",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x1234567461d3f8db7496581774bd869c83d51c93.png",
      coingeckoId: "bitclave",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x123151402076fc819B7564510989e475c9cD93CA",
      symbol: "wDGLD",
      name: "wrapped-DGLD",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x123151402076fc819b7564510989e475c9cd93ca/774d0794c7b02fda36cc7bda4575f2d7.png",
      coingeckoId: "wrapped-dgld",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x120a3879da835A5aF037bB2d1456beBd6B54d4bA",
      symbol: "RVST",
      name: "Revest",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x120a3879da835a5af037bb2d1456bebd6b54d4ba/b03da9ea6e6739ef1f3af7906b2ecde9.png",
      coingeckoId: "revest-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x11C1a6B3Ed6Bb362954b29d3183cfA97A0c806Aa",
      symbol: "STR",
      name: "Starwire",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x11c1a6b3ed6bb362954b29d3183cfa97a0c806aa/be315e8b8faeb413e73b8f1d2e4c28c2.png",
      coingeckoId: "starwire",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1122B6a0E00DCe0563082b6e2953f3A943855c1F",
      symbol: "CENNZ",
      name: "Centrality Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1122b6a0e00dce0563082b6e2953f3a943855c1f/a800d62cedf0da61d45f75dc71c65e21.png",
      coingeckoId: "centrality",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x111111111117dC0aa78b770fA6A738034120C302",
      symbol: "1INCH",
      name: "1Inch",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x111111111117dC0aa78b770fA6A738034120C302/logo.png",
      coingeckoId: "1inch",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x10a3da2BB0Fae4D591476fd97D6636fd172923a8",
      symbol: "crHEGIC",
      name: "Cream FTX Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x10a3da2bb0fae4d591476fd97d6636fd172923a8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x10FDBD1e48eE2fD9336a482D746138AE19e649Db",
      symbol: "crFTT",
      name: "Cream FTX Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x10fdbd1e48ee2fd9336a482d746138ae19e649db.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x10Be9a8dAe441d276a5027936c3aADEd2d82bC15",
      symbol: "UMX",
      name: "unimex network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x10be9a8dae441d276a5027936c3aaded2d82bc15/8a432e19ad18ce6fe1eab817a62cf118.png",
      coingeckoId: "unimex-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x108a850856Db3f85d0269a2693D896B394C80325",
      symbol: "TGT",
      name: "THORWallet Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x108a850856Db3f85d0269a2693D896B394C80325/logo.png",
      coingeckoId: "thorwallet",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x106552C11272420aAd5d7e94f8AcAb9095A6c952",
      symbol: "KEANU",
      name: "Keanu Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x106552c11272420aad5d7e94f8acab9095a6c952/be82c861fc029a99542999487de5e4f9.png",
      coingeckoId: null,
      listedIn: ["1inch", "rubic", "lifinance"],
    },
    {
      address: "0x106538CC16F938776c7c180186975BCA23875287",
      symbol: "BASv2",
      name: "BASv2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x106538cc16f938776c7c180186975bca23875287/82f47d720f70e80f9001d32e786051cd.png",
      coingeckoId: "basis-share",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x10633216E7E8281e33c86F02Bf8e565a635D9770",
      symbol: "DVI",
      name: "Dvision",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x10633216e7e8281e33c86f02bf8e565a635d9770/c67df36b38ab1860160eb1c33381d4a7.png",
      coingeckoId: "dvision-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1045F5cCb01DAEA4f8eaB055F5FcBB7C0E7c89F0",
      symbol: "DFIAT",
      name: "DeFiato",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x1045f5ccb01daea4f8eab055f5fcbb7c0e7c89f0/ff42362256dadbcebc9ad9efcd94618d.png",
      coingeckoId: "defiato",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x101cc05f4A51C0319f570d5E146a8C625198e636",
      symbol: "aTUSD",
      name: "Aave interest bearing TUSD",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aTUSD.svg",
      coingeckoId: "aave-tusd",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x10010078a54396F62c96dF8532dc2B4847d47ED3",
      symbol: "HND",
      name: "Hundred Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x10010078a54396f62c96df8532dc2b4847d47ed3/cae168ae659748f245f0d57b76521952.png",
      coingeckoId: "hundred-finance",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x0ff5A8451A839f5F0BB3562689D9A44089738D11",
      symbol: "rDPX",
      name: "Dopex Rebate Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ff5a8451a839f5f0bb3562689d9a44089738d11/f9bb3682ad5e24f054d9d9f60150c10b.png",
      coingeckoId: "dopex-rebate-token",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x0fe629d1E84E171f8fF0C1Ded2Cc2221Caa48a3f",
      symbol: "MASK",
      name: "Mask",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0fe629d1e84e171f8ff0c1ded2cc2221caa48a3f/0376a33eb5ccc03b4a4f7d8f2a31ea1a.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x0fF6ffcFDa92c53F615a4A75D982f399C989366b",
      symbol: "LAYER",
      name: "Unilayer",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ff6ffcfda92c53f615a4a75d982f399c989366b/68471df37e1a17f70abef651fa0a9a63.png",
      coingeckoId: "unilayer",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704",
      symbol: "XIO",
      name: "XIO Network",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0f7f961648ae6db43c75663ac7e5414eb79b5704/5ba80229bc8f629a3b54d04f6d427353.png",
      coingeckoId: "xio",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x0f71B8De197A1C84d31de0F1fA7926c365F052B3",
      symbol: "ARCONA",
      name: "Arcona Distribution Contract",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0f71b8de197a1c84d31de0f1fa7926c365f052b3/9b67ce95f1c7e0267b42b17c598e6e10.png",
      coingeckoId: "arcona",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0f51bb10119727a7e5eA3538074fb341F56B09Ad",
      symbol: "DAO",
      name: "DAO Maker",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0f51bb10119727a7e5ea3538074fb341f56b09ad/30321597cfe10ed983947b5e26c43dea.png",
      coingeckoId: "dao-maker",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0f2D719407FdBeFF09D87557AbB7232601FD9F29",
      symbol: "SYN",
      name: "SYN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/18024/small/syn.png?1635002049",
      coingeckoId: "synapse-2",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x0f17BC9a994b87b5225cFb6a2Cd4D667ADb4F20B",
      symbol: "jEUR",
      name: "JarvisSyntheticEuro",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b/97467944e2a898221bd3855fed214876.png",
      coingeckoId: "jarvis-synthetic-euro",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da",
      symbol: "BPT",
      name: "BlackPool Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da/logo.png",
      coingeckoId: "blackpool-token",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x0dC7d0192c148d7d2D6fa32DC280f953c0AD6A34",
      symbol: "MetaCat",
      name: "MetaCat",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0dc7d0192c148d7d2d6fa32dc280f953c0ad6a34/ae41d61a9d37c3cc125892d19d5dd389.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x0d86EB9f43C57f6FF3BC9E23D8F9d82503f0e84b",
      symbol: "MAXI",
      name: "Maximus",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0d86eb9f43c57f6ff3bc9e23d8f9d82503f0e84b/ec1d5ee075a01d622bec43d11cc3e486.png",
      coingeckoId: "maximus-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0d438F3b5175Bebc262bF23753C1E53d03432bDE",
      symbol: "wNXM",
      name: "Wrapped NXM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0d438f3b5175bebc262bf23753c1e53d03432bde/78c3987eb85767c5bbc5308ed3af4b99.png",
      coingeckoId: "wrapped-nxm",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x0d02755a5700414B26FF040e1dE35D337DF56218",
      symbol: "BEND",
      name: "Bend Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0d02755a5700414b26ff040e1de35d337df56218/65c8d4590eaf5b2ce2ec4c628a7799c4.png",
      coingeckoId: "benddao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0cEC1A9154Ff802e7934Fc916Ed7Ca50bDE6844e",
      symbol: "POOL",
      name: "PoolTogether",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0cec1a9154ff802e7934fc916ed7ca50bde6844e/2484f4a3952ba986b8a7016cb697dccc.png",
      coingeckoId: "pooltogether",
      listedIn: ["coingecko", "1inch", "sushiswap", "optimism", "lifinance"],
    },
    {
      address: "0x0c7D5ae016f806603CB1782bEa29AC69471CAb9c",
      symbol: "BFC",
      name: "Bifrost",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0c7d5ae016f806603cb1782bea29ac69471cab9c/92038a50516a1c3162093a9a7e0dcb7e.png",
      coingeckoId: "bifrost",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0c572544a4Ee47904d54aaA6A970AF96B6f00E1b",
      symbol: "WAS",
      name: "Wasder Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0c572544a4ee47904d54aaa6a970af96b6f00e1b/caebc0ba4f07c368fd3c8278e76f5efa.png",
      coingeckoId: "wasder",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0c3685559Af6F3d20C501b1076A8056A0A14426a",
      symbol: "miniSAITAMA",
      name: "mini SAITAMA",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x0c3685559af6f3d20c501b1076a8056a0a14426a.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
      symbol: "YFI",
      name: "yearn.finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e/logo.png",
      coingeckoId: "yearn-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0b8057C3Cc676C329c25C1d0Cd27776efA73762d",
      symbol: "$WEAPON",
      name: "Megaweapon",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0b8057c3cc676c329c25c1d0cd27776efa73762d/9a98d1ab18be2680cffac5dc1f506708.png",
      coingeckoId: "megaweapon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0b38210ea11411557c13457D4dA7dC6ea731B88a",
      symbol: "API3",
      name: "API3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0b38210ea11411557c13457d4da7dc6ea731b88a/dbd371394b5d9d5d8341570f59e65332.png",
      coingeckoId: "api3",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x0b006E475620Af076915257C6A9E40635AbdBBAd",
      symbol: "AGVE",
      name: "Agave",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x0adA190c81b814548ddC2F6AdC4a689ce7C1FE73",
      symbol: "YAXIS",
      name: "yAxis V2",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73/e9ac370aa6889b7a8ef4c429a9285fed.png",
      coingeckoId: "yaxis",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0ab87046fBb341D058F17CBC4c1133F25a20a52f",
      symbol: "gOHM",
      name: "Governance OHM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ab87046fbb341d058f17cbc4c1133f25a20a52f/1d479f384902a7f68ec490f7a1601030.png",
      coingeckoId: "governance-ohm",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0aCe32f6E87Ac1457A5385f8eb0208F37263B415",
      symbol: "HBT",
      name: "Habitat Token",
      decimals: 10,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ace32f6e87ac1457a5385f8eb0208f37263b415/fb441eb33d1e4f8f9a1205fc6a02dda8.png",
      coingeckoId: "habitat",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0aA7eFE4945Db24d95cA6E117BBa65Ed326e291A",
      symbol: "OJA",
      name: "Ojamu Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0aa7efe4945db24d95ca6e117bba65ed326e291a/3d8173954decd8d7f1c0adcce91c61ae.png",
      coingeckoId: "ojamu",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8",
      symbol: "USX",
      name: "dForce USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8/09298f687c07bdcfab0137f654cd17e9.png",
      coingeckoId: null,
      listedIn: ["optimism", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x0a50C93c762fDD6E56D86215C24AaAD43aB629aa",
      symbol: "LGO",
      name: "LGO Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x0a50c93c762fdd6e56d86215c24aaad43ab629aa.png",
      coingeckoId: "legolas-exchange",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0a0e3bfD5a8cE610E735D4469Bc1b3b130402267",
      symbol: "ERP",
      name: "ERP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/19633/small/erp.PNG?1635715161",
      coingeckoId: "entropyfi",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x0FD10b9899882a6f2fcb5c371E17e70FdEe00C38",
      symbol: "PUNDIX",
      name: "Pundi X Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38/bed745265125036a27929474e77a434a.png",
      coingeckoId: "pundi-x-2",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
      symbol: "MANA",
      name: "Decentraland",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x0F5D2fB29fb7d3CFeE444a200298f468908cC942/logo.png",
      coingeckoId: "decentraland",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0E8d6b471e332F140e7d9dbB99E5E3822F728DA6",
      symbol: "ABYSS",
      name: "Abyss",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6/78248f32c0fdb02cd6d1fc848296c03e.png",
      coingeckoId: "the-abyss",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x0E5C8C387C5EBa2eCbc137aD012aeD5Fe729e251",
      symbol: "RPG",
      name: "Rangers Protocol Gas",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0e5c8c387c5eba2ecbc137ad012aed5fe729e251/80548f899d63297808b8a274217284c6.png",
      coingeckoId: "rangers-protocol-gas",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0E58ED58E150dba5fd8e5D4A49F54C7e1E880124",
      symbol: "RELI",
      name: "Relite",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0e58ed58e150dba5fd8e5d4a49f54c7e1e880124/1754ebb01ca58243c581474eb6fed1d2.png",
      coingeckoId: "relite-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd",
      symbol: "HAKKA",
      name: "Hakka Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd/5f9fa1ec47440398385fe2a9a1f17d6d.png",
      coingeckoId: "hakka-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0E22734e078d6e399BCeE40a549DB591C4EA46cB",
      symbol: "STM",
      name: "Streamity",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x0e22734e078d6e399bcee40a549db591c4ea46cb.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x0E192d382a36De7011F795Acc4391Cd302003606",
      symbol: "FST",
      name: "Futureswap Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0e192d382a36de7011f795acc4391cd302003606/a9733cc8d4fe536ef7233346283a9b71.png",
      coingeckoId: "futureswap",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x0De05F6447ab4D22c8827449EE4bA2D5C288379B",
      symbol: "OOKI",
      name: "Ooki",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0de05f6447ab4d22c8827449ee4ba2d5c288379b/c59431f526757f5fd98bc8858f139ebf.png",
      coingeckoId: "ooki",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0DDe6F6e345bfd23f3F419F0DFe04E93143b44FB",
      symbol: "SOTA",
      name: "SOTA",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0dde6f6e345bfd23f3f419f0dfe04e93143b44fb/65a3e8a5d282d61277c94b96b4a93ac0.png",
      coingeckoId: "sota-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0D97Fee619d955509e54B046c9992B6E9F5B0630",
      symbol: "PONY",
      name: "PONY Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0d97fee619d955509e54b046c9992b6e9f5b0630/b353d30345cc54fcb9a5eb385219e32c.png",
      coingeckoId: "pony-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      symbol: "BAT",
      name: "BasicAttentionToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x0D8775F648430679A709E98d2b0Cb6250d2887EF/logo.png",
      coingeckoId: "basic-attention-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0Cf0Ee63788A0849fE5297F3407f701E122cC023",
      symbol: "DATA",
      name: "Streamr",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x0cf0ee63788a0849fe5297f3407f701e122cc023/655df747b3daad9be338e711f268ea6f.png",
      coingeckoId: "streamr-xdata",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x0Ce6d5a093d4166237C7A9ff8E0553B0293214a1",
      symbol: "DCNT",
      name: "Decenturion Token",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "decenturion",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0CDF9acd87E940837ff21BB40c9fd55F68bba059",
      symbol: "MINT",
      name: "Public Mint",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0cdf9acd87e940837ff21bb40c9fd55f68bba059/b3612f6531a175f1b58038c5721599bb.png",
      coingeckoId: "public-mint",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0C75dD36aF9a59BA1d248a98Fe91b2384cfea9be",
      symbol: "WHIRL",
      name: "OmniWhirl",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0c75dd36af9a59ba1d248a98fe91b2384cfea9be/04f621925820f51b1bb02fb632cc3fd9.png",
      coingeckoId: "omniwhirl",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0C37Bcf456bC661C14D596683325623076D7e283",
      symbol: "ARNX",
      name: "Aeron",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0c37bcf456bc661c14d596683325623076d7e283/4548f8b50b098622a40531ed5e047f24.png",
      coingeckoId: "aeron",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6",
      symbol: "USDD",
      name: "Decentralized USD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6/70890e6172f62f4430bfeaff32680884.png",
      coingeckoId: "usdd",
      listedIn: ["coingecko", "1inch", "openocean", "optimism", "lifinance"],
    },
    {
      address: "0x0BE4447860DdF283884BBaa3702749706750b09e",
      symbol: "AGFI",
      name: "AggregatedFinance",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0be4447860ddf283884bbaa3702749706750b09e/e1d1380b9ed1c1cdde300b11189cb5a0.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0B6F3c17e1626a7cBfA4302CE4E3c45522d23A83",
      symbol: "WAD",
      name: "WardenSwap",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0b6f3c17e1626a7cbfa4302ce4e3c45522d23a83/6eec6237b46dbe5523845e73bc86fa0f.png",
      coingeckoId: "warden",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0B498ff89709d3838a063f1dFA463091F9801c2b",
      symbol: "BTC2x-FLI",
      name: "BTC 2x Flexible Leverage Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0b498ff89709d3838a063f1dfa463091f9801c2b/68d730e7d2fb2ed00083cedf6c30f009.png",
      coingeckoId: "btc-2x-flexible-leverage-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0B452278223D3954F4AC050949D7998e373e7E43",
      symbol: "SUZUME",
      name: "Shita-Kiri Suzume",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0b452278223d3954f4ac050949d7998e373e7e43/79263abd6f4611ba2eee004673590d03.png",
      coingeckoId: "shita-kiri-suzume",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0Ae055097C6d159879521C384F1D2123D1f195e6",
      symbol: "STAKE",
      name: "STAKE",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0ae055097c6d159879521c384f1d2123d1f195e6/40de992cef3fb71caa688d9b572280ce.png",
      coingeckoId: "xdai-stake",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x0AbdAce70D3790235af448C88547603b945604ea",
      symbol: "DNT",
      name: "district0x",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0abdace70d3790235af448c88547603b945604ea/abbac239c3e170c92bc5ec5ac9fb9c63.png",
      coingeckoId: "district0x",
      listedIn: ["coingecko", "1inch", "uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x0AaCfbeC6a24756c20D41914F2caba817C0d8521",
      symbol: "YAM",
      name: "YAM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0aacfbec6a24756c20d41914f2caba817c0d8521/3ae6e1c6c62aaba0ebb849d19ee2d319.png",
      coingeckoId: "yam-2",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0AF55d5fF28A3269d69B98680Fd034f115dd53Ac",
      symbol: "BSL",
      name: "BankSocial",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0af55d5ff28a3269d69b98680fd034f115dd53ac/f0b76dc425749bc7f1944d9b409b5e6c.png",
      coingeckoId: "banksocial",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0A913beaD80F321E7Ac35285Ee10d9d922659cB7",
      symbol: "DOS",
      name: "DOS Network Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0a913bead80f321e7ac35285ee10d9d922659cb7/0cb2cb4f4b22c5b9f8831b5e1dbbbac9.png",
      coingeckoId: "dos-network",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      symbol: "iUSD",
      name: "iZUMi Bond USD",
      decimals: 18,
      chainId: "1",
      logoURI: "https://get.celer.app/cbridge-icons/iUSD.png",
      coingeckoId: "izumi-bond-usd",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x09a3EcAFa817268f77BE1283176B946C4ff2E608",
      symbol: "MIR",
      name: "Mirror Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x09a3ecafa817268f77be1283176b946c4ff2e608/80223b0c263a8b8c262a30378a5b78e9.png",
      coingeckoId: "mirror-protocol",
      listedIn: ["coingecko", "1inch", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x09F098B155D561Fc9F7BcCc97038b7e3d20bAF74",
      symbol: "ZOO",
      name: "ZooDAO",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x09f098b155d561fc9f7bccc97038b7e3d20baf74/55a9d110e2bc58275de0f20088061558.png",
      coingeckoId: "zoodao",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x0954906da0Bf32d5479e25f46056d22f08464cab",
      symbol: "INDEX",
      name: "Index Cooperative",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0954906da0bf32d5479e25f46056d22f08464cab/61a79429844158482f05f2513aada41b.png",
      coingeckoId: "index-cooperative",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0913dDAE242839f8995c0375493f9a1A3Bddc977",
      symbol: "MRI",
      name: "Marshall Rogan Inu",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0913ddae242839f8995c0375493f9a1a3bddc977/e90e4e00e55e2d0cc652f0f3df8babe4.png",
      coingeckoId: "marshall-rogan-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x090185f2135308BaD17527004364eBcC2D37e5F6",
      symbol: "SPELL",
      name: "SPELL",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/15861/small/abracadabra-3.png?1622544862",
      coingeckoId: "spell-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "arbitrum_bridge",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x08d967bb0134F2d07f7cfb6E246680c53927DD30",
      symbol: "MATH",
      name: "MATH Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x08d967bb0134f2d07f7cfb6e246680c53927dd30/86c0cd3cf4f38d4d0105107acb170db2.png",
      coingeckoId: "math",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "arbitrum_bridge",
        "lifinance",
      ],
    },
    {
      address: "0x08d32b0da63e2C3bcF8019c9c5d849d7a9d791e6",
      symbol: "DCN",
      name: "Dentacoin",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6/4530de4f424719d73f9e38a1a081ede2.png",
      coingeckoId: "dentacoin",
      listedIn: ["coingecko", "1inch", "optimism", "lifinance"],
    },
    {
      address: "0x08c32b0726C5684024ea6e141C50aDe9690bBdcc",
      symbol: "STOS",
      name: "Stratos Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x08c32b0726c5684024ea6e141c50ade9690bbdcc/7fac19a0c4096c5958411fb5b19225b4.png",
      coingeckoId: "stratos",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x08389495D7456E1951ddF7c3a1314A4bfb646d8B",
      symbol: "CRPT",
      name: "CRPT",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x08389495d7456e1951ddf7c3a1314a4bfb646d8b/a4cd6f63a52f2b7408c784eaa0978922.png",
      coingeckoId: "crypterium",
      listedIn: ["coingecko", "uniswap", "lifinance"],
    },
    {
      address: "0x0829d2d5cC09d3d341E813c821B0cfAE272D9fb2",
      symbol: "ROCKS",
      name: "ROCKS",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2/956c2f98713642da7ecaee9faa3c4e84.png",
      coingeckoId: "social-rocket",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x081131434f93063751813C619Ecca9C4dC7862a3",
      symbol: "DAR",
      name: "Dalarnia",
      decimals: 6,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x081131434f93063751813c619ecca9c4dc7862a3/755625cbd002d2633030e561d055318e.png",
      coingeckoId: "mines-of-dalarnia",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x08037036451C768465369431Da5C671ad9B37dBc",
      symbol: "NFTS",
      name: "NFT STARS COIN",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://api.rubic.exchange/assets/ethereum/0x08037036451c768465369431da5c671ad9b37dbc/logo.png",
      coingeckoId: "nft-stars",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x07e3c70653548B04f0A75970C1F81B4CBbFB606f",
      symbol: "DLT",
      name: "Delta",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x07e3c70653548b04f0a75970c1f81b4cbbfb606f/f77c4761bdbf38d54968bf9d04153581.png",
      coingeckoId: "agrello",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x07baC35846e5eD502aA91AdF6A9e7aA210F2DcbE",
      symbol: "erowan",
      name: "erowan",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe/5a341408b560f555e3d13e902964d963.png",
      coingeckoId: "sifchain",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0763fdCCF1aE541A5961815C0872A8c5Bc6DE4d7",
      symbol: "SUKU",
      name: "SUKU",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7/145f5b71acc789b0bfb074bd9aa0c9b9.png",
      coingeckoId: "suku",
      listedIn: ["coingecko", "uniswap", "openocean", "optimism", "lifinance"],
    },
    {
      address: "0x07597255910a51509CA469568B048F2597E72504",
      symbol: "1UP",
      name: "Uptrennd",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x07597255910a51509ca469568b048f2597e72504/a777622a0ac7ef01fa87d09e3092d3c8.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x072987D5B36aD8d45552aEd98879a7101cCdd749",
      symbol: "BNV",
      name: "BunnyVerse",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x072987d5b36ad8d45552aed98879a7101ccdd749/4b9125b3a6a5d7548b600c6f22e562f4.png",
      coingeckoId: "bunnyverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x07150e919B4De5fD6a63DE1F9384828396f25fDC",
      symbol: "BASE",
      name: "Base Protocol",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x07150e919b4de5fd6a63de1f9384828396f25fdc/ce8d38bf2c5202cf3eb9e53f1f8fbb37.png",
      coingeckoId: "base-protocol",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x06ebc9c542357e2129D16717CD02c02FBC835d33",
      symbol: "CAGE",
      name: "Coinage",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/floshintoken/CoinageFinance/main/Coinage_v2_200x200_white.png",
      coingeckoId: "coinage-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x06F3C323f0238c72BF35011071f2b5B7F43A054c",
      symbol: "MASQ",
      name: "MASQ",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x06f3c323f0238c72bf35011071f2b5b7f43a054c/c90bd8b53b4a2d2af19e8af20257ebe0.png",
      coingeckoId: "masq",
      listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x06B884e60794Ce02AafAb13791B59A2e6A07442f",
      symbol: "UNBNK",
      name: "Unbanked",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x06b884e60794ce02aafab13791b59a2e6a07442f/70526a88319290cbf7a1c97d648e44c2.png",
      coingeckoId: "unbanked",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x06AF07097C9Eeb7fD685c692751D5C66dB49c215",
      symbol: "CHAI",
      name: "Chai",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x06af07097c9eeb7fd685c692751d5c66db49c215/74be199097619412062898ddac5db8e1.png",
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442",
      symbol: "Skey",
      name: "SmartKey",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x06a01a4d579479dd5d884ebf61a31727a3d8d442/4bc4994f67930e614885908105d1031c.png",
      coingeckoId: "skey-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x066798d9ef0833ccc719076Dab77199eCbd178b0",
      symbol: "SAKE",
      name: "SakeToken",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x066798d9ef0833ccc719076dab77199ecbd178b0/e3074ec42197eaaa4cfee861e9a67943.png",
      coingeckoId: "sake-token",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x06301057D77D54B6e14c7FafFB11Ffc7Cab4eaa7",
      symbol: "mDAI",
      name: "DMM: DAI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x06301057d77d54b6e14c7faffb11ffc7cab4eaa7.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x06147110022B768BA8F99A8f385df11a151A9cc8",
      symbol: "ACE",
      name: "ACE",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://ethapi.openocean.finance/logos/0x06147110022b768ba8f99a8f385df11a151a9cc8.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1",
      symbol: "aBAT",
      name: "Aave interest bearing BAT",
      decimals: 18,
      chainId: "1",
      logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aBAT.svg",
      coingeckoId: "aave-bat",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x05D3606d5c81EB9b7B18530995eC9B29da05FaBa",
      symbol: "TOMOE",
      name: "TomoChain",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x05d3606d5c81eb9b7b18530995ec9b29da05faba/df06a1fcc68dcc771b9a02604897e127.png",
      coingeckoId: "tomoe",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd",
      symbol: "GUSD",
      name: "GeminiDollar",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd/logo.png",
      coingeckoId: "gemini-dollar",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "openocean",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0563DCe613D559a47877fFD1593549fb9d3510D6",
      symbol: "SUPERBID",
      name: "SuperBid",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0563dce613d559a47877ffd1593549fb9d3510d6/85578021d40bf4c36e50e5614cc04da1.png",
      coingeckoId: "superbid",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x054f76beED60AB6dBEb23502178C52d6C5dEbE40",
      symbol: "FIN",
      name: "DeFiner",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x054f76beed60ab6dbeb23502178c52d6c5debe40/ce7fcbed3234e9c7a299c2d7e124f8b5.png",
      coingeckoId: "definer",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x054D64b73d3D8A21Af3D764eFd76bCaA774f3Bb2",
      symbol: "PPAY",
      name: "Plasma",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2/98daf4fce7365a8fae420a0abd773228.png",
      coingeckoId: "plasma-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x05079687D35b93538cbd59fe5596380cae9054A9",
      symbol: "BTSG",
      name: "BitSong",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x05079687d35b93538cbd59fe5596380cae9054a9/4dfd25f0f436e74065dd92f61526b19c.png",
      coingeckoId: "bitsong",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
      symbol: "UMA",
      name: "UMAVotingTokenv1",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
      coingeckoId: "uma",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x04Aa51bbcB46541455cCF1B8bef2ebc5d3787EC9",
      symbol: "yBTC",
      name: "iearn WBTC",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://tokens.1inch.io/0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x04969cD041C0cafB6AC462Bd65B536A5bDB3A670",
      symbol: "wOMI",
      name: "Wrapped OMI Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x04969cd041c0cafb6ac462bd65b536a5bdb3a670/a7b39b595917bc590d3cb1eb1fd55919.png",
      coingeckoId: "wrapped-ecomi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0488401c3F535193Fa8Df029d9fFe615A06E74E6",
      symbol: "SRK",
      name: "SparkPoint",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0488401c3f535193fa8df029d9ffe615a06e74e6/757431dfa0ed401f43d838a08f97004c.png",
      coingeckoId: "sparkpoint",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x044727e50ff30DB57fad06Ff4F5846eAb5eA52a2",
      symbol: "kitty",
      name: "Kitty Inu",
      decimals: 9,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x044727e50ff30db57fad06ff4f5846eab5ea52a2/1abda311ce75ae43299b41d12eabc9f8.png",
      coingeckoId: "kitty-inu",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x043C308BB8a5aE96D0093444be7f56459F1340b1",
      symbol: "SUM",
      name: "SUM",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x043c308bb8a5ae96d0093444be7f56459f1340b1/e4440712f5c5e9590efb39daa83bfc0b.png",
      coingeckoId: "sumswap",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x0417912b3a7AF768051765040A55BB0925D4DDcF",
      symbol: "LID",
      name: "Liquidity Dividends Protocol",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0417912b3a7af768051765040a55bb0925d4ddcf/90975cb3c16ebc69015cfdda3c05787b.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919",
      symbol: "RAI",
      name: "RAI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/14004/small/RAI-logo-coin.png?1613592334",
      coingeckoId: "rai",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x03Be5C903c727Ee2C8C4e9bc0AcC860Cca4715e2",
      symbol: "CAPS",
      name: "Capsule Coin",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2/7a78ef5b34f4e7db21d548216788f58e.png",
      coingeckoId: "coin-capsule",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x0391D2021f89DC339F60Fff84546EA23E337750f",
      symbol: "BOND",
      name: "BOND",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/12811/small/barnbridge.jpg?1602728853",
      coingeckoId: "barnbridge",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x038a68FF68c393373eC894015816e33Ad41BD564",
      symbol: "GLCH",
      name: "Glitch",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x038a68ff68c393373ec894015816e33ad41bd564/f4ba4174614971f177c220d931b0bf68.png",
      coingeckoId: "glitch-protocol",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x037A54AaB062628C9Bbae1FDB1583c195585fe41",
      symbol: "LCX",
      name: "LCX",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x037a54aab062628c9bbae1fdb1583c195585fe41/78eb9ef46be7e95befad3a330dfb5e74.png",
      coingeckoId: "lcx",
      listedIn: ["coingecko", "1inch", "uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x035dF12E0F3ac6671126525f1015E47D79dFEDDF",
      symbol: "0xMR",
      name: "0xMonero",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x035df12e0f3ac6671126525f1015e47d79dfeddf/962afe35c28a1360dd5b3619ccf90070.png",
      coingeckoId: "0xmonero",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0x0327112423F3A68efdF1fcF402F6c5CB9f7C33fd",
      symbol: "BTC++",
      name: "PieDAO BTC++",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd/967e21b248716a03c6d4cb5f35d0fc7d.png",
      coingeckoId: "piedao-btc",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x0316EB71485b0Ab14103307bf65a021042c6d380",
      symbol: "HBTC",
      name: "Huobi BTC",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0316eb71485b0ab14103307bf65a021042c6d380/eba7770c32a0498f645df4d9d62e3aa2.png",
      coingeckoId: "huobi-btc",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x030bA81f1c18d280636F32af80b9AAd02Cf0854e",
      symbol: "aWETH",
      name: "Aave interest bearing WETH",
      decimals: 18,
      chainId: "1",
      logoURI: null,
      coingeckoId: "aave-weth",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0309c98B1bffA350bcb3F9fB9780970CA32a5060",
      symbol: "BDI",
      name: "BasketDAO DeFi Index",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0309c98B1bffA350bcb3F9fB9780970CA32a5060/logo.png",
      coingeckoId: "basketdao-defi-index",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x028171bCA77440897B824Ca71D1c56caC55b68A3",
      symbol: "aDAI",
      name: "Aave interest bearing DAI",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x028171bca77440897b824ca71d1c56cac55b68a3/e5bb381200298b9f200177e23c3cfaed.png",
      coingeckoId: "aave-dai",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
      symbol: "ORN",
      name: "Orion Protocol",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a/f537440f8bcbf3144ea3416918097f96.png",
      coingeckoId: "orion-protocol",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
      ],
    },
    {
      address: "0x0202Be363B8a4820f3F4DE7FaF5224fF05943AB1",
      symbol: "UFT",
      name: "UniLend Finance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0202be363b8a4820f3f4de7faf5224ff05943ab1/ab271551c45c135244b049cdc12b6e5f.png",
      coingeckoId: "unlend-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x01e0E2e61f554eCAaeC0cC933E739Ad90f24a86d",
      symbol: "GTON",
      name: "Graviton",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x01e0e2e61f554ecaaec0cc933e739ad90f24a86d/e3dc0a16ad5cd9a9d75b371ad5b18307.png",
      coingeckoId: "gton-capital",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x01FB70eA8AcFDd5435c8e8bd1531d96D57cEd0ec",
      symbol: "SELECT",
      name: "SmartElectrum",
      decimals: 18,
      chainId: "1",
      logoURI: "https://coinpaprika.com/coin/select-smart-electrum/logo.png",
      coingeckoId: "smart-electrum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x01BA67AAC7f75f647D94220Cc98FB30FCc5105Bf",
      symbol: "LYRA",
      name: "Lyra Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x01ba67aac7f75f647d94220cc98fb30fcc5105bf/474a23cfed5652ad5cb1ddf7ddb84b17.png",
      coingeckoId: "lyra-finance",
      listedIn: ["coingecko", "sushiswap", "optimism", "lifinance"],
    },
    {
      address: "0x018fb5Af9d015Af25592a014C4266a84143De7a0",
      symbol: "MP3",
      name: "mp3",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x018fb5af9d015af25592a014c4266a84143de7a0/0c427bd665e7db56ddade377160872cb.png",
      coingeckoId: "mp3",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x01597E397605Bf280674Bf292623460b4204C375",
      symbol: "BENT",
      name: "Bent Finance",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x01597E397605Bf280674Bf292623460b4204C375.jpg",
      coingeckoId: "bent-finance",
      listedIn: ["coingecko", "sushiswap"],
    },
    {
      address: "0x012E0e6342308b247F36Ee500ECb14dc77a7a8C1",
      symbol: "SKT",
      name: "Sukhavati Network Token",
      decimals: 8,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x012e0e6342308b247f36ee500ecb14dc77a7a8c1/3ae51f23bf98b25f4894ec0ef908f9df.png",
      coingeckoId: "sukhavati-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7",
      symbol: "SKL",
      name: "Skale",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://elk.finance/tokens/logos/ethereum/0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7/logo.png",
      coingeckoId: "skale",
      listedIn: ["coingecko", "1inch", "uniswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0",
      symbol: "ALBT",
      name: "AllianceBlock Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x00a8b738e453ffd858a7edf03bccfe20412f0eb0/9867892d0aaa519c537d8189a0551750.png",
      coingeckoId: "allianceblock",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x00D1793D7C3aAE506257Ba985b34C76AaF642557",
      symbol: "TACO",
      name: "Tacos",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x00d1793d7c3aae506257ba985b34c76aaf642557/164ec4e3cc8fd68e4759a2666d52d624.png",
      coingeckoId: "tacos",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x009668A9691e456972c8ec4Cc84e99486308b84D",
      symbol: "TERA",
      name: "Terareum",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x009668a9691e456972c8ec4cc84e99486308b84d/6d8f18d0ebe0bc9238c7d293c0483b36.png",
      coingeckoId: "terareum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x006BeA43Baa3f7A6f765F14f10A1a1b08334EF45",
      symbol: "STX",
      name: "Stox",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x006bea43baa3f7a6f765f14f10a1a1b08334ef45/3436b0f859af5fc47fe673cc2ad9a58d.png",
      coingeckoId: "stox",
      listedIn: ["coingecko", "uniswap", "openocean", "lifinance"],
    },
    {
      address: "0x001A8Ffcb0f03e99141652eBCdecDb0384E3bd6c",
      symbol: "PKR",
      name: "POLKER",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x001a8ffcb0f03e99141652ebcdecdb0384e3bd6c/d0dcc680fbf505e129fd60c4015b459d.png",
      coingeckoId: "polker",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0000A1c00009A619684135B824Ba02f7FbF3A572",
      symbol: "ALCH",
      name: "Alchemy",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0000a1c00009a619684135b824ba02f7fbf3a572/7a8783c1a4b8f28b58a7a1de8dda207c.png",
      coingeckoId: "alchemy-dao",
      listedIn: ["coingecko", "arbitrum_bridge", "lifinance"],
    },
    {
      address: "0x00000000441378008EA67F4284A57932B1c000a5",
      symbol: "TGBP",
      name: "TrueGBP",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x00000000441378008ea67f4284a57932b1c000a5/d25902752917ae8c07c02611ff985606.png",
      coingeckoId: null,
      listedIn: ["1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x0000000000b3F879cb30FE243b4Dfee438691c04",
      symbol: "GST2",
      name: "Gastoken.io 2",
      decimals: 2,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/token/logo_url/0x0000000000b3f879cb30fe243b4dfee438691c04/628c8a560339f732c2bc869c19de3173.png",
      coingeckoId: "gastoken",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x0000000000095413afC295d19EDeb1Ad7B71c952",
      symbol: "LON",
      name: "Tokenlon",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0000000000095413afc295d19edeb1ad7b71c952/422ddbdc393c49f4d3b947f90bedbbd9.png",
      coingeckoId: "tokenlon",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x0000000000085d4780B73119b644AE5ecd22b376",
      symbol: "TUSD",
      name: "TUSD",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://assets.coingecko.com/coins/images/3449/small/tusd.png?1618395665",
      coingeckoId: "true-usd",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "optimism",
        "arbitrum_bridge",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x000000000000d0151E748d25b766e77efe2A6c83",
      symbol: "XDEX",
      name: "XDEFI Governance Token",
      decimals: 18,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x000000000000d0151e748d25b766e77efe2a6c83/a12559e8be449305040f93151b922462.png",
      coingeckoId: "xdefi-governance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0000000000004946c0e9F43F4Dee607b0eF1fA1c",
      symbol: "CHI",
      name: "Chi Gastoken by 1inch",
      decimals: 0,
      chainId: "1",
      logoURI:
        "https://static.debank.com/image/eth_token/logo_url/0x0000000000004946c0e9f43f4dee607b0ef1fa1c/5d763d01aae3f0ac9a373564026cb620.png",
      coingeckoId: "chi-gastoken",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
  ];