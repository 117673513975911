import React from "react";
import { Box, Button, Flex, HStack, Link } from "@chakra-ui/react";
import "../styles/util.css";

const NewLaunch = () => {
  return (
    <>
      <Flex
        color="white"
        w="100%"
        bg="blue.500"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
        className="parent-box-1"
        fontSize={{ base: "10px", md: "16px" }}
      >
        <Box>
          $GSW is live on Base with the contract address
          <Box as="span" fontWeight="700">
            {" "}
            0xA782984727c482Ed862eb40FF6A070F9Be764c3e
          </Box>
        </Box>

        <HStack display={{ base: "none", md: "flex" }}>
          <Link href="#" _hover={{ textDecoration: "none" }}>
            <Button color="white" borderRadius="12px">
              Buy on Uniswap
            </Button>
          </Link>

          <Link href="/swap" _hover={{ textDecoration: "none" }}>
            <Button color="white" borderRadius="12px">
              Buy on GhostDEX
            </Button>
          </Link>

          <Link
            href="https://flooz.trade/trade/0x0715f21e2d5f918522fF525471baa4C0f6788d42?refId=I66vYd&network=eth"
            _hover={{ textDecoration: "none" }}
          >
            <Button color="white" borderRadius="12px">
              Buy with card
            </Button>
          </Link>
        </HStack>
      </Flex>
    </>
  );
};

export default NewLaunch;
