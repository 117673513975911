import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useColorMode,
  Image,
  HStack,
  useToast,
  Badge,
  Spinner,
  Link,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { arbitrum } from "../utils/arbitrum";
import { ethereum } from "../utils/ethereum";
import { polygon } from "../utils/polygon";
import { bsc } from "../utils/bsc";
import "../styles/util.css";
import { contractAbi } from "../utils/contractAbi";
import { ethers } from "ethers";
import { BiEdit, BiWallet } from "react-icons/bi";
import axios from "axios";
import DarkModeButton from "../components/DarkModeButton";
import { handleSwaps } from "../utils/handleSwaps";
import { handleArbitSwap } from "../utils/handleArbitSwap";
import logo from "../images/sakilogo.jpg";
import logolight from "../images/sakilogo.jpg";

const HomePageDex = () => {
  const [netDispImg, setNetDispImg] = useState(
    "https://arbitrum.io/wp-content/uploads/2021/08/Arbitrum_Symbol-Full-color-White-background-937x1024.png"
  );
  const [netName, setNetName] = useState("Arbitrum");
  const { colorMode } = useColorMode();
  const [goodGas, setGoodGas] = useState(0);
  const toast = useToast();
  const [swapData, setSwapData] = useState("");
  const [focusColor, setFocusColor] = useState("");
  const [isApprovalTxMined, setIsApprovalTxMined] = useState(false);
  const [usersAddress, setUsersAddress] = useState(null);
  const [approvedData, setApprovedData] = useState(null);
  const [ens, setEns] = useState(null);
  const [name, setName] = useState(null);
  const [bal, setBal] = useState(null);
  const [loading, setloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [zeroxNetwork, setZeroxNetwork] = useState("arbitrum.");
  const [ooName, setOoName] = useState("arbitrum");
  const {
    isOpen: isToOpen,
    onOpen: onToOpen,
    onClose: onToClose,
  } = useDisclosure();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const {
    isOpen: isArbiOpen,
    onOpen: onArbiOpen,
    onClose: onArbiClose,
  } = useDisclosure();
  const {
    isOpen: isCustomTokenOpen,
    onOpen: onCustomTokenOpen,
    onClose: onCustomTokenClose,
  } = useDisclosure();

  const {
    isOpen: isNetworkOpen,
    onOpen: onNetworkOpen,
    onClose: onNetworkClose,
  } = useDisclosure();
  const [fromTokenAmount, setFromTokenAmount] = useState(1);
  const [hoverColor, setHoverColor] = useState("");
  const [ooparams, setOoParams] = useState(null);
  const [fromTokenSearch, setFromTokenSearch] = useState("");
  const [toTokenAmount, setToTokenAmount] = useState(0);
  const [tokenBal, setTokenBal] = useState(0);
  const [fromTokenAddress, setFromTokenAddress] = useState(
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  );
  const [fromTokenDecimals, setFromTokenDecimals] = useState(18);
  const [fromTokenSymbol, setFromTokenSymbol] = useState("WETH");
  const [slippage, setSlippage] = useState(0.1);
  const [fromTokenLogo, setFromTokenLogo] = useState(
    "https://elk.finance/tokens/logos/arbitrum/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/logo.png"
  );

  const [toTokenSymbol, setToTokenSymbol] = useState("USDC");
  const [toTokenSearch, setToTokenSearch] = useState("");
  const [setToTokenName] = useState("");
  const [toTokenLogo, setToTokenLogo] = useState(
    "https://elk.finance/tokens/logos/arbitrum/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8/logo.png"
  );
  const [toTokenAddress, setToTokenAddress] = useState(
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8"
  );
  const [toTokenDecimals, setToTokenDecimals] = useState(6);

  useEffect(() => {
    if (colorMode === "dark") {
      setHoverColor("black");
      setFocusColor("#0d0f15");
    } else if (colorMode === "light") {
      setHoverColor("#f7fafe");
      setFocusColor("#f7fafe");
    }
  }, [colorMode]);

  const fetchTokenBalance = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = signer.getAddress();
    if (
      String(fromTokenAddress) === "0x0000000000000000000000000000000000000000"
    ) {
      setTokenBal(Number(bal)?.toFixed(4));
    } else {
      const contract = new ethers.Contract(
        fromTokenAddress,
        contractAbi,
        provider
      );
      const balance = await contract.balanceOf(address);
      setTokenBal(balance);
    }
  };
  useEffect(() => {
    fetchTokenBalance();
  }, [fromTokenAddress, usersAddress]);

  const getGas = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const gasPrice = await provider.getGasPrice();
    setGoodGas(gasPrice);
  };

  useEffect(() => {
    getGas();
  }, []);

  const connect = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    let accounts = await provider?.send("eth_requestAccounts", []);
    let account = accounts[0];
    const provider2 = new ethers.providers.JsonRpcProvider(
      "https://eth.llamarpc.com"
    );
    const address = await provider2.lookupAddress(account);
    setEns(address);
    setUsersAddress(account);
  };

  const setterOfName = () => {
    setloading(true);
    if (ens !== null) {
      setloading(true);
      setName(ens);
      setloading(false);
    } else if (ens === null && usersAddress !== null) {
      setloading(true);
      setName(`${usersAddress?.slice(0, 10)}...`);
      setloading(false);
    } else {
      setName("Connect Wallet");
    }
    setloading(false);
  };

  useEffect(() => {
    setloading(true);
    setterOfName();
    setloading(false);
  }, [ens, usersAddress]);

  const getBal = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(usersAddress);

    setBal(balance);
  };

  /* `https://open-api.openocean.finance/v3/arbitrum/swap_quote?inTokenAddress=${fromTokenAddress}&outTokenAddress=${toTokenAddress}&amount=${fromTokenAmount}&gasPrice=${goodGas}&slippage=${slippage}&account=${usersAddress}&referrer=0x975c00de0C768932FbEb2f7b5D72186d9AC29c27&referrerFee=0.05` */

  useEffect(() => {
    getBal();
  }, [usersAddress]);

  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);

  const approveOpenOcean = async () => {
    setIsApproving(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const contract = new ethers.Contract(fromTokenAddress, contractAbi, signer);
    const amount = String(fromTokenAmount * 10 ** fromTokenDecimals);
    const gasPrice = await provider.getGasPrice();
    const gasPriceInWei = ethers.BigNumber.from(gasPrice.toString());
    const lowGasPrice = gasPriceInWei.mul(2);
    const gas = await contract.estimateGas.approve(ooparams?.to, amount, {
      gasPrice: lowGasPrice,
    });
    contract
      .approve(ooparams?.to, amount, { gasPrice: lowGasPrice, gasLimit: gas })
      .then((tx) => {
        setApprovedData(tx);
      })
      .catch((error) => {});
    setIsApproving(false);
  };

  const txnCheck = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider?.getTransaction(approvedData?.hash);
      if (txn_test) {
        if (txn_test.blockNumber) {
          setIsApprovalTxMined(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (approvedData !== null) {
      setInterval(() => {
        txnCheck();
      }, 1000);
    }
  }, [approvedData]);

  const [isSwapTxMined, setIsSwapTxMined] = useState(false);
  const swapTxnCheck = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider?.getTransaction(swapData?.hash);
      if (txn_test) {
        if (txn_test.blockNumber) {
          setIsSwapTxMined(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (swapData !== null) {
      setInterval(() => {
        swapTxnCheck();
      }, 1000);
    }
  }, [swapData]);
  const openOceanParams = {
    from: String(usersAddress),
    to: String(ooparams?.to),
    value: ooparams?.value,
    data: ooparams?.txData,
    gasLimit: ooparams?.gas,
  };

  const swapOpenOcean = async () => {
    setIsSwapping(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const txstat = await signer.sendTransaction(openOceanParams).then((tx) => {
      setSwapData(tx);
    });
    setIsSwapping(false);
  };

  useEffect(() => {
    if (isApprovalTxMined) {
      toast({
        title: "Approved successfully",
        position: "bottom-right",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
    }
  }, [isApprovalTxMined]);

  useEffect(() => {
    if (isSwapTxMined) {
      toast({
        title: "Swap completed",
        position: "bottom-right",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
    }
  }, [isSwapTxMined]);

  const [network, setNetwork] = useState(true);

  const [netId, setNetId] = useState(42161);

  const getChainId = async () => {
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    let network = await provider?.getNetwork();
    setNetId(network?.chainId);
    if (
      String(network?.chainId) === "42161" ||
      String(network?.chainId) === "1"
    ) {
      setNetwork(true);
    } else {
      setNetwork(false);
    }
  };

  /* hi thereee */
  useEffect(() => {
    if (String(netId) === "1") {
      setFromTokenAddress("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2");
      setToTokenAddress("0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48");
    } else if (String(netId) === "137") {
      setFromTokenAddress("0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270");
      setToTokenAddress("0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174");
      setFromTokenSymbol("WMATIC");
      setFromTokenLogo(
        "https://elk.finance/tokens/logos/matic/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png"
      );
    } else if (String(netId) === "56") {
      setFromTokenAddress("0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c");
      setToTokenAddress("0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56");
      setFromTokenSymbol("WBNB");
      setToTokenSymbol("BUSD");
      setFromTokenLogo(
        "https://elk.finance/tokens/logos/bsc/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"
      );
      setToTokenLogo(
        "https://elk.finance/tokens/logos/bsc/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png"
      );
    }
  }, [netId]);

  useEffect(() => {
    getChainId();
  }, [netName]);

  useEffect(() => {
    if (network === false) {
      onArbiOpen();
    }
  }, [network, usersAddress]);

  const switchNet = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(42161) }],
      });
      onArbiClose();
    } catch (err) {}
  };

  const switchNetEth = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(1) }],
      });
      onArbiClose();
    } catch (err) {}
  };

  const switchNetPoly = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(137) }],
      });
      onArbiClose();
    } catch (err) {}
  };

  const switchNetBSC = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(56) }],
      });
      onArbiClose();
    } catch (err) {}
  };

  const [prices, setPrices] = useState("");

  const getSwaps = async () => {
    const result = await handleSwaps(
      fromTokenAddress,
      toTokenAddress,
      fromTokenAmount,
      fromTokenDecimals,
      toTokenDecimals,
      netId,
      ooName,
      slippage,
      zeroxNetwork
    );
    setPrices(result);
  };

  useEffect(() => {
    getSwaps();
  }, [
    fromTokenAddress,
    toTokenAddress,
    fromTokenAmount,
    usersAddress,
    netName,
  ]);

  const [lowestPrice, setLowestPrice] = useState({});
  const [chosenDEX, setChosenDEX] = useState("");
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    setIsSorting(false);
    if (prices?.length !== 0) {
      const sortedData = prices.sort((a, b) => b?.price - a?.price);
      const maxPrice = sortedData[0]?.price;
      const highestPriceObject = sortedData[0];
      const lowestPriceObject = sortedData[3];
      setLowestPrice(lowestPriceObject);
      if (sortedData?.length >= 2) {
        setToTokenAmount(maxPrice);
        setChosenDEX(highestPriceObject?.exchange);
      } else if (sortedData?.length === 1) {
        setToTokenAmount(maxPrice);
        setChosenDEX(highestPriceObject?.exchange);
      }
    }
    setIsSorting(true);
  }, [prices]);

  const [actualKeyword, setActualKeyword] = useState("");

  useEffect(() => {
    if (chosenDEX == "OpenOcean") {
      setActualKeyword("openocean");
    } else if (chosenDEX == "paraswap") {
      setActualKeyword("paraswap");
    } else if (chosenDEX == "0x") {
      setActualKeyword("0x");
    } else if (chosenDEX == "1inch") {
      setActualKeyword("1inch");
    }
  }, [chosenDEX]);

  const [inchParams, setInchParams] = useState(null);
  const [paraswapParams, setParaswapParams] = useState(null);
  const [oxParams, setOxParams] = useState(null);

  const requestSwapParams = async () => {
    const reply = await handleArbitSwap(
      actualKeyword,
      fromTokenAddress,
      toTokenAddress,
      fromTokenDecimals,
      fromTokenAmount,
      netId,
      ooName,
      zeroxNetwork,
      slippage,
      usersAddress,
      toTokenDecimals,
      goodGas
    );

    /* hello I am Elias */

    setOoParams({
      txData: reply?.data?.data?.data,
      to: reply?.data?.data?.to,
      value: reply?.data?.data?.value,
      gas: reply?.data?.data?.estimatedGas,
    });

    setInchParams({
      txData: reply?.data?.tx?.data,
      to: reply?.data?.tx?.to,
      value: reply?.data?.tx?.value,
      gas: reply?.tx?.data?.gas,
    });
    setParaswapParams({
      priceRoute: reply?.data?.priceRoute,
    });
    setOxParams({
      txData: reply?.data?.data,
      to: reply?.data?.to,
      value: reply?.data?.value,
      gasLimit: reply?.data?.gas,
      gasPrice: reply?.data?.gasPrice,
      allowanceTarget: reply?.data?.allowanceTarget,
    });
  };

  useEffect(() => {
    requestSwapParams();
  }, [
    actualKeyword,
    fromTokenAddress,
    toTokenAddress,
    fromTokenAmount,
    toTokenAmount,
  ]);

  const paraswapParameters = {
    srcToken: fromTokenAddress,
    destToken: toTokenAddress,
    destDecimals: toTokenDecimals,
    srcAmount: String(fromTokenAmount * 10 ** fromTokenDecimals),
    priceRoute: paraswapParams?.priceRoute,
    slippage: slippage * 100,
    userAddress: usersAddress,
  };

  const [paraswapSwapParams, setParaswapSwapParams] = useState({});

  const getParaswapSwapData = async () => {
    if (String(actualKeyword) === "paraswap") {
      axios
        .post(
          `https://apiv5.paraswap.io/transactions/${netId}`,
          paraswapParameters
        )
        .then((data) => setParaswapSwapParams(data?.data));
    }
  };

  useEffect(() => {
    getParaswapSwapData();
  }, [paraswapParameters]);

  const approveParaswap = async () => {
    setIsApproving(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const contract = new ethers.Contract(fromTokenAddress, contractAbi, signer);
    const amount = String(fromTokenAmount * 10 ** fromTokenDecimals);
    const gasPrice = await provider.getGasPrice();
    const gasPriceInWei = ethers.BigNumber.from(gasPrice.toString());
    const lowGasPrice = gasPriceInWei.mul(2);
    const gas = await contract.estimateGas.approve(
      paraswapParams?.priceRoute?.tokenTransferProxy,
      amount,
      {
        gasPrice: lowGasPrice,
      }
    );
    contract
      .approve(paraswapParams?.priceRoute?.tokenTransferProxy, amount, {
        gasPrice: lowGasPrice,
        gasLimit: gas,
      })
      .then((tx) => {
        setApprovedData(tx);
      })
      .catch((error) => {});
    setIsApproving(false);
  };

  const paraswapActualSwapParams = {
    from: String(usersAddress),
    to: String(paraswapSwapParams?.to),
    value: paraswapSwapParams?.value,
    data: paraswapSwapParams?.data,
    gasLimit: paraswapSwapParams?.gas,
  };

  const swapParaSwap = async () => {
    setIsSwapping(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const txstat = await signer
      .sendTransaction(paraswapActualSwapParams)
      .then((tx) => {
        setSwapData(tx);
      });
    setIsSwapping(false);
  };

  /* -----------------------PARASWAP------------------------------------- */

  /* -----------------------------------0X------------------------------------- */
  const approve0x = async () => {
    setIsApproving(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const contract = new ethers.Contract(fromTokenAddress, contractAbi, signer);
    const amount = String(fromTokenAmount * 10 ** fromTokenDecimals);
    const gasPrice = await provider.getGasPrice();
    const gasPriceInWei = ethers.BigNumber.from(gasPrice.toString());
    const lowGasPrice = gasPriceInWei.mul(2);
    const gas = await contract.estimateGas.approve(
      oxParams?.allowanceTarget,
      amount,
      {
        gasPrice: lowGasPrice,
      }
    );
    contract
      .approve(oxParams?.allowanceTarget, amount, {
        gasPrice: lowGasPrice,
        gasLimit: gas,
      })
      .then((tx) => {
        setApprovedData(tx);
      })
      .catch((error) => {});
    setIsApproving(false);
  };

  const oxSwappingParams = {
    from: String(usersAddress),
    to: String(oxParams?.to),
    value: oxParams?.value,
    data: oxParams?.txData,
    gasLimit: oxParams?.gasLimit,
    gasPrice: oxParams?.gasPrice,
  };

  const send0xTransaction = async () => {
    setIsSwapping(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const txstat = await signer.sendTransaction(oxSwappingParams).then((tx) => {
      setSwapData(tx);
    });
    setIsSwapping(false);
  };

  /* -----------------------------------0X------------------------------------- */

  /* -----------------------------------1INCH------------------------------------- */
  const oneInchParams = {
    from: inchParams?.from,
    to: inchParams?.to,
    data: inchParams?.txData,
    value: inchParams?.value,
  };

  const approve1Inch = async () => {
    setIsApproving(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const contract = new ethers.Contract(fromTokenAddress, contractAbi, signer);
    const amount = String(fromTokenAmount * 10 ** fromTokenDecimals);
    const gasPrice = await provider.getGasPrice();
    const gasPriceInWei = ethers.BigNumber.from(gasPrice.toString());
    const lowGasPrice = gasPriceInWei.mul(2);
    const gas = await contract.estimateGas.approve(inchParams?.to, amount, {
      gasPrice: lowGasPrice,
    });
    contract
      .approve(inchParams?.to, amount, {
        gasPrice: lowGasPrice,
        gasLimit: gas,
      })
      .then((tx) => {
        setApprovedData(tx);
      })
      .catch((error) => {});
    setIsApproving(false);
  };

  const send1InchTx = async () => {
    setIsSwapping(true);
    const provider =
      window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider?.getSigner();
    const txstat = await signer.sendTransaction(oneInchParams).then((tx) => {
      setSwapData(tx);
    });
    setIsSwapping(false);
  };

  const approve = () => {
    if (actualKeyword === "paraswap") {
      approveParaswap();
    } else if (actualKeyword === "0x") {
      approve0x();
    } else if (actualKeyword === "1inch") {
      approve1Inch();
    } else if (actualKeyword === "openocean") {
      approveOpenOcean();
    }
  };

  /* -----------------------------------1INCH------------------------------------- */

  const swap = () => {
    if (actualKeyword === "paraswap") {
      swapParaSwap();
    } else if (actualKeyword === "0x") {
      send0xTransaction();
    } else if (actualKeyword === "1inch") {
      send1InchTx();
    } else if (actualKeyword === "openocean") {
      swapOpenOcean();
    }
  };

  const [customAddress, setCustomAddress] = useState("");
  const [customSymbol, setCustomSymbol] = useState("");
  const [customName, setCustomName] = useState("");
  const [customDecimals, setCustomDecimals] = useState("");

  async function addCustomToken() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const abi = contractAbi;
    const contract = new ethers.Contract(customAddress, abi, provider);
    const decimals = await contract.decimals();
    setCustomDecimals(decimals);
    const symbol = await contract.symbol();
    setCustomSymbol(symbol);
    const name = await contract.name();
    setCustomName(name);
    const newToken = {
      address: customAddress,
      decimals: decimals,
      symbol: symbol,
      name: name,
      flag: true,
    };
    /* arbitrum.push(newToken); */
    if (netId == "1") {
      ethereum.push(newToken);
    } else if (netId == "42161") {
      arbitrum.push(newToken);
    }
  }

  useEffect(() => {
    addCustomToken();
  }, [customAddress]);

  function saveCustomTokenToLocalStorage() {
    localStorage.setItem("arbitrum", JSON.stringify(arbitrum));
    localStorage.setItem("ethereum", JSON.stringify(ethereum));
  }

  function getCustomTokenFromLocalStorage() {
    try {
      const localArbitrum = JSON.parse(localStorage.getItem("arbitrum"));
      if (localArbitrum) {
        return localArbitrum;
      }
      const localEthereum = JSON.parse(localStorage.getItem("ethereum"));
      if (localEthereum) {
        return localEthereum;
      }
      return [];
    } catch (e) {}
  }

  function getCustomEthTokenFromLocalStorage() {
    try {
      const localEthereum = JSON.parse(localStorage.getItem("ethereum"));
      if (localEthereum) {
        return localEthereum;
      }
      return [];
    } catch (e) {}
  }
  function useCustomTokenFromLocalStorage() {
    const [arbitrum, setArbitrum] = useState(getCustomTokenFromLocalStorage());
    const [ethereum, setEthereum] = useState(
      getCustomEthTokenFromLocalStorage()
    );

    useEffect(() => {
      setArbitrum(getCustomTokenFromLocalStorage());
      setEthereum(getCustomEthTokenFromLocalStorage);
    });

    return [arbitrum];
  }

  function useCustomEthTokenFromLocalStorage() {
    const [ethereum, setEthereum] = useState(
      getCustomEthTokenFromLocalStorage()
    );

    useEffect(() => {
      setEthereum(getCustomEthTokenFromLocalStorage);
    });

    return [ethereum];
  }
  const customTokens = useCustomTokenFromLocalStorage();
  const customEthTokens = useCustomEthTokenFromLocalStorage();

  const [arrToMap, setArrToMap] = useState(arbitrum);
  useEffect(() => {
    if (customTokens?.length > 30 && String(netId) === "42161") {
      setArrToMap(customTokens[0]);
    } else if (customTokens?.length > 30 && String(netId) === "1") {
      setArrToMap(customEthTokens[0]);
    } /* else {
      setArrToMap(arbitrum);
    } */
  }, [customTokens]);

  const [logoSrc, setLogoSrc] = useState(logo);
  useEffect(() => {
    if (colorMode === "light") {
      setLogoSrc(logolight);
    } else if (colorMode === "dark") {
      setLogoSrc(logo);
    }
  }, [colorMode]);

  useEffect(() => {
    if (
      String(fromTokenAddress) === "0xd212046f89680ac9f106b9c63f314cc9808e18d5"
    ) {
      setActualKeyword("openocean");
    }
  }, [fromTokenAddress]);

  return (
    <>
      <Box p="20px">
        <Flex alignItems="center" justifyContent="center">
          <Flex
            w={{ base: "350px", md: "380px" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Button
                onClick={onNetworkOpen}
                _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
              >
                <HStack>
                  <Image src={netDispImg} borderRadius="300px" boxSize="30px" />
                  <Box>{netName}</Box>
                  <Box>
                    <ChevronDownIcon />
                  </Box>
                </HStack>
              </Button>
            </Box>
            <Box _hover={{ cursor: "pointer" }} onClick={onSettingsOpen}>
              <SettingsIcon />
            </Box>
          </Flex>
        </Flex>
        <Flex flexDir="column" alignItems="center" justifyContent="center">
          <Box p={{ base: "10px", md: "20px" }} borderRadius="15px">
            <Box
              bg="#f7fafe"
              _dark={{ bg: "#0d0f15" }}
              w={{ base: "350px", md: "380px" }}
              h={{ base: "120px" }}
              borderRadius="10px"
              p="10px"
            >
              <Flex
                flexDir="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  pl="10px"
                  pr="10px"
                >
                  <Box>You spend</Box>
                  <HStack>
                    <Box>
                      <BiWallet />
                    </Box>
                    <Box>{tokenBal / 10 ** fromTokenDecimals}</Box>
                  </HStack>
                </Flex>

                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  p="10px"
                >
                  <Box w={{ base: "40%", md: "50%" }}>
                    <Input
                      value={fromTokenAmount}
                      p="0px"
                      focusBorderColor={focusColor}
                      fontSize="20px"
                      type="number"
                      onChange={(e) => setFromTokenAmount(e?.target?.value)}
                      placeholder="Enter amount"
                      border="none"
                      fontWeight="500"
                      _placeholder={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    />
                  </Box>

                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    w={{ base: "60%", md: "50%" }}
                  >
                    <Flex
                      flexDir="column"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                    >
                      <Box>
                        <Button onClick={onOpen}>
                          {" "}
                          <HStack>
                            <Box>{fromTokenSymbol}</Box>
                            <Flex>
                              <Box>
                                <Image
                                  fallbackSrc="https://ninajohansson.se/wp-content/themes/koji/assets/images/default-fallback-image.png"
                                  src={fromTokenLogo}
                                  boxSize="25px"
                                  borderRadius="full"
                                />
                              </Box>
                            </Flex>
                          </HStack>
                        </Button>
                      </Box>
                      <Box mt="5px">
                        <HStack>
                          <Box
                            onClick={() =>
                              setFromTokenAmount(
                                (tokenBal * 0.25) / 10 ** fromTokenDecimals
                              )
                            }
                            _hover={{ cursor: "pointer" }}
                          >
                            <Badge colorScheme="yellow">25%</Badge>
                          </Box>
                          <Box
                            onClick={() =>
                              setFromTokenAmount(
                                (tokenBal * 0.5) / 10 ** fromTokenDecimals
                              )
                            }
                            _hover={{ cursor: "pointer" }}
                          >
                            <Badge colorScheme="blue">50%</Badge>
                          </Box>
                          <Box
                            onClick={() =>
                              setFromTokenAmount(
                                (tokenBal * 0.75) / 10 ** fromTokenDecimals
                              )
                            }
                            _hover={{ cursor: "pointer" }}
                          >
                            <Badge colorScheme="orange">75%</Badge>
                          </Box>
                          <Box
                            onClick={() =>
                              setFromTokenAmount(
                                tokenBal / 10 ** fromTokenDecimals
                              )
                            }
                            _hover={{ cursor: "pointer" }}
                          >
                            <Badge colorScheme="teal">MAX</Badge>
                          </Box>
                        </HStack>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Box>

          <Box borderRadius="15px " mt={{ base: "0px", md: "-10px" }}>
            <Box
              bg="#f7fafe"
              _dark={{ bg: "#0d0f15" }}
              w={{ base: "350px", md: "380px" }}
              h={{ base: "100px" }}
              borderRadius="10px"
              p="10px"
            >
              <Flex
                flexDir="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Box pl="10px" pr="10px">
                  You get
                </Box>
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  p="10px"
                >
                  <Box w={{ base: "40%", md: "50%" }}>
                    {isSorting === false ? (
                      <Spinner />
                    ) : (
                      <Input
                        readOnly
                        value={String(toTokenAmount)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          }
                        )}
                        p="0px"
                        focusBorderColor={focusColor}
                        fontSize="20px"
                        type="number"
                        border="none"
                        fontWeight="500"
                        _placeholder={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      />
                    )}
                  </Box>
                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    w={{ base: "60%", md: "50%" }}
                  >
                    <Box>
                      <Button onClick={onToOpen}>
                        {" "}
                        <HStack>
                          <Box>{toTokenSymbol}</Box>
                          <Flex>
                            <Box>
                              <Image
                                fallbackSrc="https://ninajohansson.se/wp-content/themes/koji/assets/images/default-fallback-image.png"
                                src={toTokenLogo}
                                boxSize="25px"
                                borderRadius="full"
                              />
                            </Box>
                          </Flex>
                        </HStack>
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Button
            _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
            display={isApprovalTxMined ? "none" : "yes"}
            mt="10px"
            w={{ base: "350px", md: "380px" }}
            borderRadius="10px"
            onClick={() => approve()}
            isLoading={isApproving}
          >
            Approve
          </Button>
          <Button
            _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
            display={isApprovalTxMined ? "yes" : "none"}
            mt="10px"
            w={{ base: "350px", md: "380px" }}
            borderRadius="10px"
            onClick={() => swap()}
            isLoading={isSwapping}
          >
            Swap
          </Button>
        </Flex>

        {/* ------------------------FROM MODAL-------------------------- */}
        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Select a token</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box position="sticky">
                <Input
                  position="sticky"
                  borderRadius="7px"
                  placeholder="Search for a token"
                  onChange={(e) => setFromTokenSearch(e?.target?.value)}
                />
              </Box>

              <Box
                className="parent-box-1"
                borderRadius="10px"
                border="1px solid #F2F2F2"
                _dark={{
                  border: "1px solid #4F5765",
                }}
                w="100%"
                maxH={{ base: "700px", md: "300px" }}
                mt="10px"
                p="10px"
              >
                <Flex flexDir="column" w="100%">
                  {arrToMap
                    ?.filter((val) => {
                      if (fromTokenSearch == "") {
                        return val;
                      } else if (
                        val /* val.symbol || val.name || */.address
                          .toLowerCase()
                          .includes(fromTokenSearch.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((e, i) => {
                      return (
                        <>
                          <Flex
                            key={i}
                            _hover={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              bg: hoverColor,
                            }}
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                            onClick={() => {
                              setFromTokenDecimals(e?.decimals);
                              setFromTokenAddress(e?.address);
                              setFromTokenSymbol(e?.symbol);
                              setFromTokenLogo(e?.logoURI);
                              onClose();
                            }}
                          >
                            <HStack p="10px">
                              <Image
                                fallbackSrc="https://ninajohansson.se/wp-content/themes/koji/assets/images/default-fallback-image.png"
                                src={e?.logoURI}
                                boxSize="40px"
                                borderRadius="300px"
                              />
                              <Flex flexDir="column" alignItems="flex-start">
                                <Box
                                  bgGradient="linear(to-l, #88C2F3, #C9AAF8)"
                                  bgClip="text"
                                  fontWeight="500"
                                >
                                  {e?.symbol}
                                </Box>
                              </Flex>
                            </HStack>
                          </Flex>
                        </>
                      );
                    })}
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Box onClick={onCustomTokenOpen} _hover={{ cursor: "pointer" }}>
                <HStack>
                  <Box>Add a custom token</Box>
                  <Box>
                    <BiEdit />
                  </Box>
                </HStack>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* -------------------TO TOKEN MODAL-------------- */}
        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isToOpen}
          onClose={onToClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Select a token</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box position="sticky">
                <Input
                  position="sticky"
                  borderRadius="7px"
                  placeholder="Search for a token"
                  onChange={(e) => setToTokenSearch(e?.target?.value)}
                />
              </Box>

              <Box
                className="parent-box-1"
                borderRadius="10px"
                border="1px solid #F2F2F2"
                _dark={{
                  border: "1px solid #4F5765",
                }}
                w="100%"
                maxH={{ base: "700px", md: "300px" }}
                mt="10px"
                p="10px"
              >
                <Flex flexDir="column" w="100%">
                  {arrToMap
                    ?.filter((val) => {
                      if (toTokenSearch === "") {
                        return val;
                      } else if (
                        val.address /* val.symbol || val.name */
                          .toLowerCase()
                          .includes(toTokenSearch.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((e, i) => {
                      return (
                        <>
                          <Flex
                            key={i}
                            _hover={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              bg: hoverColor,
                            }}
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                            onClick={() => {
                              setToTokenDecimals(e?.decimals);
                              setToTokenAddress(e?.address);
                              setToTokenSymbol(e?.symbol);
                              setToTokenLogo(e?.logoURI);
                              onToClose();
                            }}
                          >
                            <HStack p="10px">
                              <Image
                                fallbackSrc="https://ninajohansson.se/wp-content/themes/koji/assets/images/default-fallback-image.png"
                                src={e?.logoURI}
                                boxSize="40px"
                                borderRadius="300px"
                              />
                              <Flex flexDir="column" alignItems="flex-start">
                                <Box
                                  bgGradient="linear(to-l, #88C2F3, #C9AAF8)"
                                  bgClip="text"
                                  fontWeight="500"
                                >
                                  {e?.symbol}
                                </Box>
                              </Flex>
                            </HStack>
                          </Flex>
                        </>
                      );
                    })}
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isSettingsOpen}
          onClose={onSettingsClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Settings</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Box>Slippage</Box>
                <Box>
                  <HStack>
                    <Button
                      _hover={{
                        bg: "#E859A9",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      borderRadius="7px"
                      onClick={() => {
                        setSlippage(1);
                        onSettingsClose();
                      }}
                    >
                      1%
                    </Button>
                    <Button
                      _hover={{
                        bg: "#E859A9",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      borderRadius="7px"
                      onClick={() => {
                        setSlippage(2);
                        onSettingsClose();
                      }}
                    >
                      2%
                    </Button>
                    <Button
                      _hover={{
                        bg: "#E859A9",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      borderRadius="7px"
                      onClick={() => {
                        setSlippage(3);
                        onSettingsClose();
                      }}
                    >
                      3%
                    </Button>
                    <Box w="100px">
                      <Input
                        borderRadius="7px"
                        onChange={(e) => setSlippage(e?.target?.value)}
                        value={slippage}
                        placeholder="custom"
                      />
                    </Box>
                  </HStack>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
        {/* 
        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isArbiOpen}
          onClose={onArbiClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Please change your network!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                Sukiyaki only supports Arbitrum and Ethereum at this time!
                Please switch your network to Arbitrum or Ethereum for the best
                experience.
              </Box>
              <Button
                _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
                mt="10px"
                onClick={() => switchNet()}
                borderRadius="10px"
                w="100%"
              >
                Switch to Arbitrum
              </Button>
              <Button
                _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
                mt="10px"
                onClick={() => switchNetEth()}
                borderRadius="10px"
                w="100%"
              >
                Switch to Ethereum
              </Button>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal> */}

        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isCustomTokenOpen}
          onClose={onCustomTokenClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Add a custom token</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                onChange={(e) => setCustomAddress(e?.target?.value)}
                placeholder="Enter contract address"
              />
              <Input mt="10px" placeholder="name" value={customName} readOnly />
              <Input
                placeholder="symbol"
                mt="10px"
                value={customSymbol}
                readOnly
              />
              <Input
                placeholder="decimals"
                mt="10px"
                value={customDecimals}
                readOnly
              />
              <Button
                _hover={{ bg: "#E859A9", color: "white", borderRadius: "10px" }}
                onClick={() => {
                  saveCustomTokenToLocalStorage();
                  customName !== "" &&
                    toast({
                      title: "Token added successfully",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });
                  onCustomTokenClose();
                }}
                mt="10px"
                w="100%"
              >
                Add to your tokens
              </Button>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          size={{ base: "full", md: "md" }}
          isOpen={isNetworkOpen}
          onClose={onNetworkClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px" _dark={{ bg: "#0d0f15" }}>
            <ModalHeader>Switch Networks</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Button
                w="100%"
                onClick={() => {
                  setNetName("Ethereum");
                  setArrToMap(ethereum);
                  setZeroxNetwork("");
                  setOoName("eth");
                  setNetId("1");
                  setNetDispImg(
                    "https://user-images.githubusercontent.com/80636305/126576577-cb07ba84-a4fe-4d63-b43a-e7832c77483d.png"
                  );
                  switchNetEth();
                  onNetworkClose();
                }}
              >
                <HStack>
                  <Image
                    src="https://user-images.githubusercontent.com/80636305/126576577-cb07ba84-a4fe-4d63-b43a-e7832c77483d.png"
                    boxSize="30px"
                    borderRadius="10px"
                  />
                  <Box>Ethereum</Box>
                </HStack>
              </Button>
              <Button
                onClick={() => {
                  setNetName("Arbitrum");
                  setNetId("42161");
                  setOoName("arbitrum");
                  setArrToMap(arbitrum);
                  setZeroxNetwork("arbitrum.");
                  setNetDispImg(
                    "https://arbitrum.io/wp-content/uploads/2021/08/Arbitrum_Symbol-Full-color-White-background-937x1024.png"
                  );
                  switchNet();
                  onNetworkClose();
                }}
                mt="10px"
                w="100%"
              >
                <HStack>
                  <Image
                    src="https://arbitrum.io/wp-content/uploads/2021/08/Arbitrum_Symbol-Full-color-White-background-937x1024.png"
                    boxSize="30px"
                    borderRadius="10px"
                  />
                  <Box>Arbitrum</Box>
                </HStack>
              </Button>
              <Button
                onClick={() => {
                  setNetName("Polygon");
                  setNetId("137");
                  setOoName("polygon");
                  setArrToMap(polygon);
                  setZeroxNetwork("polygon.");
                  setNetDispImg(
                    "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
                  );
                  switchNetPoly();
                  onNetworkClose();
                }}
                mt="10px"
                w="100%"
              >
                <HStack>
                  <Image
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
                    boxSize="30px"
                    borderRadius="10px"
                  />
                  <Box>Polygon</Box>
                </HStack>
              </Button>

              <Button
                onClick={() => {
                  setNetName("BSC");
                  setNetId("56");
                  setOoName("bsc");
                  setArrToMap(bsc);
                  setZeroxNetwork("bsc.");
                  setNetDispImg(
                    "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                  );
                  switchNetBSC();
                  onNetworkClose();
                }}
                mt="10px"
                w="100%"
              >
                <HStack>
                  <Image
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                    boxSize="30px"
                    borderRadius="10px"
                  />
                  <Box>Binance SC</Box>
                </HStack>
              </Button>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default HomePageDex;
