import {
  Box,
  GridItem,
  Grid,
  Button,
  Link,
  Flex,
  HStack,
  VStack,
  Text,
  Container,
  Stack,
  Heading,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import NewHeader from "../components/NewHeader";
import "../styles/util.css";
import { HiReceiptTax } from "react-icons/hi";
import { GiPiggyBank } from "react-icons/gi";
import { BsMegaphone, BsCheckCircleFill } from "react-icons/bs";
import { MdOutlineBuild } from "react-icons/md";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import HomePageDex from "../components/HomePageDex";
import NewLaunch from "../components/NewLaunch";
import "animate.css";

const NewLanding = () => {
  const phase1 = [
    { text: "Formation of team" },
    { text: "Completion of app v1" },
    { text: "Token fair launch" },
    { text: "Community building" },
    { text: "Website v1" },
    { text: "Coingecko listing" },
    { text: "Coinmarketcap listing" },
    { text: "Whitepaper v1 release" },
  ];

  const phase2 = [
    { text: "Audit by Shellboxes" },
    { text: "Auto slippage" },
    { text: "Referral launch" },
    { text: "More networks support" },
    { text: "More partnerships" },
    { text: "$GSW Arbitrum launch" },
    { text: "Whitepaper v2" },
    { text: "Positive Slippage" },
    { text: "More marketing" },
  ];

  const phase3 = [
    { text: "Limit orders" },
    { text: "Addition of more networks" },
    { text: "Even more partnerships" },
    { text: "App v2 release" },
    { text: "Cross-chain bridge" },
    { text: "CEX listings" },
    { text: "Staking launch" },
    { text: "Even more marketing" },
  ];

  const phase4 = [
    { text: "Futures launch" },
    { text: "Addition of more networks" },
    { text: "Even more partnerships" },
    { text: "App v3 release" },
    { text: "Even more marketing" },
  ];
  return (
    <>
      <NewLaunch />
      <NewHeader />
      <Container className="animate__animated animate__slideInUp" maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            The world's first <br />
            <Text as={"span"} color={"blue.500"}>
              AI powered DEX aggregator.
            </Text>
          </Heading>
          <Text color={"gray.500"}>
            GhostSwap uses custom built AI to route for best prices on chain and
            cross chain for users, thereby gaining points ahead of the
            competition over 65% of the time on Ethereum, Binance Smart Chain,
            Arbitrum and Polygon.
          </Text>
          <Stack
            direction={"row"}
            spacing={{ base: 1, md: 3 }}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <Link _hover={{ textDecoration: "none" }} isExternal href="#">
              <Button
                mt="-20px"
                borderRadius="12px"
                size={{ base: "md", md: "lg" }}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Buy $GSW
              </Button>
            </Link>
            <Link _hover={{ textDecoration: "none" }} isExternal href="#">
              <Button
                mt="-20px"
                borderRadius="12px"
                size={{ base: "md", md: "lg" }}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Live Chart
              </Button>
            </Link>
            <Link _hover={{ textDecoration: "none" }} isExternal href="#">
              <Button
                mt="-20px"
                borderRadius="12px"
                size={{ base: "md", md: "lg" }}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Buy with Card
              </Button>
            </Link>
            <Link _hover={{ textDecoration: "none" }} isExternal href="#">
              <Button
                display={{ base: "none", md: "yes" }}
                mt="-20px"
                borderRadius="12px"
                size={{ base: "md", md: "lg" }}
                _hover={{
                  bg: "blue.500",
                }}
              >
                List on GhostDEX
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Container>

      <VStack mb="100px">
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignitems="center"
          justifyContent="space-between"
          w={{ base: "85%", md: "70%" }}
        >
          <HomePageDex />
          <Flex
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box
              mt={{ base: "30px", md: "0px" }}
              ml={{ base: "0px", md: "50px" }}
            >
              <Heading>
                Get the best rates.{" "}
                <Box color="blue.500" as="span">
                  Every time.
                </Box>{" "}
              </Heading>
            </Box>

            <Box
              fontWeight="500"
              mt={{ base: "20px", md: "20px" }}
              ml={{ base: "0px", md: "50px" }}
            >
              GhostDEX will return the best rates for your token swaps 65% of
              the time. And if your favorite tokens are not on our list of
              tracked tokens, you can manually add tokens yourself and GhostDEX
              will adapt and find routes for the new tokens!
            </Box>

            <Box
              fontWeight="500"
              mt={{ base: "0px", md: "20px" }}
              ml={{ base: "0px", md: "50px" }}
            >
              GhostDEX is able to return the best rate by searching for routes
              across 100 providers using an ultra-smart, custom built AI program
              that is capable of searching for routes cross-chain. It takes into
              account a lot of parameters before returning the best rate to you,
              all in fractions of a minute.
            </Box>
          </Flex>
        </Flex>
      </VStack>

      <VStack>
        {" "}
        <Box>
          <Heading fontSize={{ base: "3xl", md: "5xl" }}>Tokenomics</Heading>
        </Box>
      </VStack>

      <Flex
        mt="20px"
        id="tokenomics"
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          gap={3}
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(2,1fr)" }}
          templateRows={{ base: "repeat(4,1fr)", md: "repeat(2,1fr)" }}
        >
          <GridItem>
            <Box
              w={{ base: "360px", md: "490px" }}
              h={{ base: "170px", md: "200px" }}
              bg="#F7FAFE"
              _dark={{ bg: "#0D0F14" }}
              borderRadius={{ base: "10px", md: "20px" }}
              p="15px"
            >
              <HStack>
                <Box mt={{ base: "10px", md: "20px" }}>
                  <GiPiggyBank color="blue.500" size="80px" />
                </Box>
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  fontWeight="500"
                >
                  <Box
                    mt={{ base: "0px", md: "20px" }}
                    color="gray.500"
                    fontSize={{ base: "20px", md: "20px" }}
                  >
                    $GSW Supply
                  </Box>
                  <Box fontSize={{ base: "30px", md: "40px" }}>100M </Box>
                </Flex>
              </HStack>
            </Box>
          </GridItem>

          <GridItem>
            <Box
              w={{ base: "360px", md: "490px" }}
              h={{ base: "170px", md: "200px" }}
              bg="#F7FAFE"
              _dark={{ bg: "#0D0F14" }}
              borderRadius={{ base: "10px", md: "20px" }}
              p="15px"
            >
              <HStack>
                <Box mt={{ base: "10px", md: "20px" }}>
                  <HiReceiptTax color="blue.500" size="80px" />
                </Box>
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  fontWeight="500"
                >
                  <Box
                    mt={{ base: "0px", md: "20px" }}
                    color="gray.500"
                    fontSize={{ base: "20px", md: "20px" }}
                  >
                    Buy/Sell tax
                  </Box>
                  <Box fontSize={{ base: "30px", md: "40px" }}>2%</Box>
                </Flex>
              </HStack>
            </Box>
          </GridItem>

          <GridItem>
            <Box
              w={{ base: "360px", md: "490px" }}
              h={{ base: "170px", md: "200px" }}
              bg="#F7FAFE"
              _dark={{ bg: "#0D0F14" }}
              borderRadius={{ base: "10px", md: "20px" }}
              p="15px"
            >
              <HStack spacing="20px">
                <Box mt={{ base: "10px", md: "20px" }}>
                  <BsMegaphone color="blue.500" size="80px" />
                </Box>
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  fontWeight="500"
                >
                  <Box
                    mt={{ base: "0px", md: "20px" }}
                    color="gray.500"
                    fontSize={{ base: "20px", md: "20px" }}
                  >
                    Marketing
                  </Box>
                  <Box fontSize={{ base: "30px", md: "40px" }}>1% of tax</Box>
                </Flex>
              </HStack>
            </Box>
          </GridItem>

          <GridItem>
            <Box
              w={{ base: "360px", md: "490px" }}
              h={{ base: "170px", md: "200px" }}
              bg="#F7FAFE"
              _dark={{ bg: "#0D0F14" }}
              borderRadius={{ base: "10px", md: "20px" }}
              p="15px"
            >
              <HStack spacing="20px">
                <Box mt={{ base: "10px", md: "20px" }}>
                  <MdOutlineBuild color="blue.500" size="80px" />
                </Box>
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  fontWeight="500"
                >
                  <Box
                    mt={{ base: "0px", md: "20px" }}
                    color="gray.500"
                    fontSize={{ base: "20px", md: "20px" }}
                  >
                    Development
                  </Box>
                  <Box fontSize={{ base: "30px", md: "40px" }}>1% of tax</Box>
                </Flex>
              </HStack>
            </Box>
          </GridItem>
        </Grid>
      </Flex>

      <VStack mt="100px" id="roadmap">
        {" "}
        <Box>
          <Heading fontSize={{ base: "3xl", md: "5xl" }}>Roadmap</Heading>
        </Box>
        <VStack>
          <Box w="100%" h="500px" className="parent-box-1" mt="50px">
            <Stack
              direction={{ base: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                p="10px"
                bg="#F7FAFE"
                _dark={{ bg: "#0D0F14" }}
                w={{ base: "320px", md: "320px" }}
                h="400px"
                borderRadius="20px"
              >
                <Flex alignItems="flex-end" justifyContent="flex-end" mr="10px">
                  <Box>
                    <Badge colorScheme="orange">Pending</Badge>
                  </Box>
                </Flex>

                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  w="100%"
                  ml="10px"
                >
                  <Box>
                    <Heading>Phase 1</Heading>
                  </Box>

                  <Box>
                    {phase1?.map((e, i) => {
                      return (
                        <>
                          <HStack mt="10px">
                            <Box>
                              <BsCheckCircleFill color="blue.500" />
                            </Box>
                            <Box>{e?.text}</Box>
                          </HStack>
                        </>
                      );
                    })}
                  </Box>
                </Flex>
              </Box>

              <Box
                p="10px"
                bg="#F7FAFE"
                _dark={{ bg: "#0D0F14" }}
                w={{ base: "320px", md: "320px" }}
                h="400px"
                borderRadius="20px"
              >
                <Flex alignItems="flex-end" justifyContent="flex-end" mr="10px">
                  <Box>
                    <Badge colorScheme="orange">20% DONE</Badge>
                  </Box>
                </Flex>

                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  w="100%"
                  ml="10px"
                >
                  <Box>
                    <Heading>Phase 2</Heading>
                  </Box>

                  <Box>
                    {phase2?.map((e, i) => {
                      return (
                        <>
                          <HStack mt="10px">
                            <Box>
                              <BsCheckCircleFill color="blue.500" />
                            </Box>
                            <Box>{e?.text}</Box>
                          </HStack>
                        </>
                      );
                    })}
                  </Box>
                </Flex>
              </Box>

              <Box
                p="10px"
                bg="#F7FAFE"
                _dark={{ bg: "#0D0F14" }}
                w={{ base: "320px", md: "320px" }}
                h="400px"
                borderRadius="20px"
              >
                <Flex alignItems="flex-end" justifyContent="flex-end" mr="10px">
                  <Box>
                    <Badge colorScheme="yellow">PENDING</Badge>
                  </Box>
                </Flex>

                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  w="100%"
                  ml="10px"
                >
                  <Box>
                    <Heading>Phase 3</Heading>
                  </Box>

                  <Box>
                    {phase3?.map((e, i) => {
                      return (
                        <>
                          <HStack mt="10px">
                            <Box>
                              <BsCheckCircleFill color="blue.500" />
                            </Box>
                            <Box>{e?.text}</Box>
                          </HStack>
                        </>
                      );
                    })}
                  </Box>
                </Flex>
              </Box>

              <Box
                p="10px"
                bg="#F7FAFE"
                _dark={{ bg: "#0D0F14" }}
                w={{ base: "320px", md: "320px" }}
                h="400px"
                borderRadius="20px"
              >
                <Flex alignItems="flex-end" justifyContent="flex-end" mr="10px">
                  <Box>
                    <Badge colorScheme="yellow">PENDING</Badge>
                  </Box>
                </Flex>

                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  w="100%"
                  ml="10px"
                >
                  <Box>
                    <Heading>Phase 4</Heading>
                  </Box>

                  <Box>
                    {phase4?.map((e, i) => {
                      return (
                        <>
                          <HStack mt="10px">
                            <Box>
                              <BsCheckCircleFill color="blue.500" />
                            </Box>
                            <Box>{e?.text}</Box>
                          </HStack>
                        </>
                      );
                    })}
                  </Box>
                </Flex>
              </Box>
            </Stack>
          </Box>
        </VStack>
      </VStack>

      <VStack id="faq" mb="100px" mt="100px">
        <Box>
          <Heading fontSize={{ base: "3xl", md: "5xl" }}>FAQ</Heading>
        </Box>
        <FAQ />
      </VStack>

      <Footer />
    </>
  );
};

export default NewLanding;
