import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  useColorMode,
  Link,
  Button,
  HStack,
  Image,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import "../styles/util.css";
import logo from "../images/sakilogo.jpg";
import logolight from "../images/sakilogo.jpg";
import DarkModeButton from "./DarkModeButton";
import { HamburgerIcon } from "@chakra-ui/icons";

const NewHeader = () => {
  const [logoSrc, setLogoSrc] = useState(logo);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (colorMode === "light") {
      setLogoSrc(logolight);
    } else if (colorMode === "dark") {
      setLogoSrc(logo);
    }
  }, [colorMode]);
  return (
    <>
      <Flex
        mt="10px"
        w="100%"
        pl="20px"
        pr="20px"
        alignItems="center"
        justifyContent="space-between"
        display={{ base: "none", md: "flex" }}
      >
        <Box>
          <HStack>
            <Box>
              <Image src={logoSrc} boxSize="50px" />
            </Box>
            <HStack
              display={{ base: "none", md: "flex" }}
              fontSize="25px"
              spacing="0"
            >
              <Box fontWeight="700">GhostSwap</Box>
              {/*  <Box color="blue.500">yaki</Box> */}
            </HStack>
          </HStack>
        </Box>
        <HStack spacing={10}>
          <Link
            href="#tokenomics"
            _hover={{ textDecoration: "none", color: "blue.500" }}
          >
            <Box fontWeight="500">Tokenomics</Box>
          </Link>
          <Link
            href="#roadmap"
            _hover={{ textDecoration: "none", color: "blue.500" }}
          >
            <Box fontWeight="500">Roadmap</Box>
          </Link>
          <Link
            href="#faq"
            _hover={{ textDecoration: "none", color: "blue.500" }}
          >
            <Box fontWeight="500">FAQ</Box>
          </Link>
          <Link
            href="https://twitter.com/ghostswaponbase"
            _hover={{ textDecoration: "none", color: "#1c9bef" }}
          >
            <Box fontWeight="500">Twitter</Box>
          </Link>
          <Link
            href="https://t.me/ghostswapofficial"
            _hover={{ textDecoration: "none", color: "#1086d7" }}
          >
            <Box fontWeight="500">Telegram</Box>
          </Link>
          <HStack>
            <Box>
              <Link href="/swap" isExternal _hover={{ textDecoration: "none" }}>
                <Button
                  borderRadius="12px"
                  _hover={{ bg: "blue.500", color: "white" }}
                >
                  Launch app
                </Button>
              </Link>
            </Box>
            <DarkModeButton />
          </HStack>
        </HStack>
      </Flex>

      <Flex
        mt="10px"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        display={{ base: "flex", md: "none" }}
      >
        <Box>
          <HStack>
            <Box>
              <Image src={logoSrc} boxSize="50px" />
            </Box>
            <HStack fontSize="25px" spacing="0">
              <Box fontWeight="700">GhostSwap</Box>
            </HStack>
          </HStack>
        </Box>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent _dark={{ bg: "blue.500" }}>
            <DrawerCloseButton />

            <DrawerBody>
              <Flex flexDir="column" mt="30px">
                <Link
                  href="#tokenomics"
                  _hover={{ textDecoration: "none", color: "blue.500" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    {" "}
                    <Box fontWeight="500">Tokenomics</Box>
                  </Button>
                </Link>
                <Link
                  href="#roadmap"
                  _hover={{ textDecoration: "none", color: "blue.500" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    <Box fontWeight="500">Roadmap</Box>
                  </Button>
                </Link>
                <Link
                  href="#faq"
                  _hover={{ textDecoration: "none", color: "blue.500" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    <Box fontWeight="500">FAQ</Box>
                  </Button>
                </Link>
                <Link
                  href="https://twitter.com/ghostswaponbase"
                  _hover={{ textDecoration: "none", color: "#1c9bef" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    <Box fontWeight="500">Twitter</Box>
                  </Button>
                </Link>
                <Link
                  href="https://t.me/ghostswapofficial"
                  _hover={{ textDecoration: "none", color: "#1086d7" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    <Box fontWeight="500">Telegram</Box>
                  </Button>
                </Link>
                <Link
                  href="#"
                  _hover={{ textDecoration: "none", color: "blue.500" }}
                >
                  <Button onClick={onClose} mt="10px" w="100%">
                    {" "}
                    <Box fontWeight="500">List on GhostDEX</Box>
                  </Button>
                </Link>
              </Flex>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>

        <HStack>
          <Box>
            <Link href="/swap" isExternal _hover={{ textDecoration: "none" }}>
              <Button
                borderRadius="12px"
                _hover={{ bg: "blue.500", color: "white" }}
              >
                Launch
              </Button>
            </Link>
          </Box>

          <Box>
            <DarkModeButton />
          </Box>

          <Box>
            <IconButton
              borderRadius="12px"
              onClick={onOpen}
              icon={<HamburgerIcon />}
            />
          </Box>
        </HStack>
      </Flex>
    </>
  );
};

export default NewHeader;
