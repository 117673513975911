export const arbitrum = [
  {
    address: "0x0000000000000000000000000000000000000000",
    symbol: "ETH",
    name: "ETH",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/arb/d61441782d4a08a7479d54aea211679e.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    symbol: "GMX",
    name: "GMX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a/b4e362278f64a738b878e5393fa29837.png",
    coingeckoId: "gmx",
    listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
  },

  {
    address: "0xfaC38532829fDD744373fdcd4708Ab90fA0c4078",
    symbol: "tLPT",
    name: "tender LPT",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xfac38532829fdd744373fdcd4708ab90fa0c4078/logo.png",
    coingeckoId: "tlpt",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xfa42DA1bd08341537a44a4ca9D236D1c00A98b40",
    symbol: "CARBON",
    name: "Carbon",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xfa42da1bd08341537a44a4ca9d236d1c00a98b40/aab9217dad85f905bf0212dbfcdda317.png",
    coingeckoId: null,
    listedIn: ["openocean", "rubic", "lifinance"],
  },
  {
    address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
    symbol: "LINK",
    name: "LINK",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700",
    coingeckoId: "chainlink",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0xf4D48Ce3ee1Ac3651998971541bAdbb9A14D7234",
    symbol: "CREAM",
    name: "Cream",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xf4d48ce3ee1ac3651998971541badbb9a14d7234/8bf676844989a1aa9a5a37be55714fdf.png",
    coingeckoId: "cream-2",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xef888bcA6AB6B1d26dbeC977C455388ecd794794",
    symbol: "RGT",
    name: "Rari Governance Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/rgt.jpg",
    coingeckoId: "rari-governance-token",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xebf1F92D4384118bFb91B4496660a95931c92861",
    symbol: "aCRV",
    name: "Aladdin cvxCRV",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xebf1f92d4384118bfb91b4496660a95931c92861/ed74ca08b3410cc9bc9ac868505bbcc7.png",
    coingeckoId: "aladdin-cvxcrv",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xea986d33eF8a20A96120ecc44dBdD49830192043",
    symbol: "AUC",
    name: "Auctus Token",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "auctus",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
    symbol: "ELK",
    name: "Elk",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/xdai/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance", "elkfinance"],
  },
  {
    address: "0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC",
    symbol: "NYAN",
    name: "ArbiNYAN",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xed3fb761414da74b74f33e5c5a1f78104b188dfc/3b2bee906ddabd06e2cb28582ea86fa6.png",
    coingeckoId: "arbinyan",
    listedIn: ["coingecko", "sushiswap", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
    symbol: "aArbWETH",
    name: "Aave Arbitrum WETH",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8/logo.png",
    coingeckoId: "aave-weth",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xdeBa25AF35e4097146d7629055E0EC3C71706324",
    symbol: "DEFI5",
    name: "DEFI Top 5 Tokens Index",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xdc7179416c08c15f689d9214A3BeC2Dd003DeABc",
    symbol: "KSW",
    name: "KillSwitchToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xdc7179416c08c15f689d9214a3bec2dd003deabc/0f039a2d9c26bc082be376b2ff0c0993.png",
    coingeckoId: "killswitch",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xdb96f8efd6865644993505318cc08FF9C42fb9aC",
    symbol: "Z2O",
    name: "ZeroTwOhm",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xdb96f8efd6865644993505318cc08ff9c42fb9ac/2d72cf8474934a1832af89a6f298472c.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0xdE903E2712288A1dA82942DDdF2c20529565aC30",
    symbol: "SWPR",
    name: "Swapr",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xde903e2712288a1da82942dddf2c20529565ac30/c5fe714ac262295f6a37938f71a96cf9.png",
    coingeckoId: "swapr",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
    symbol: "SUSHI",
    name: "SUSHI",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688",
    coingeckoId: "sushi",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0xd3f1Da62CAFB7E7BC6531FF1ceF6F414291F03D3",
    symbol: "DBL",
    name: "Doubloon Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xd3f1da62cafb7e7bc6531ff1cef6f414291f03d3/dccf1fe2ba74d80dc80fe09410941d16.png",
    coingeckoId: "doubloon",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xcd14C3A2ba27819B352aae73414A26e2b366dC50",
    symbol: "USX",
    name: "dForce USD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1637831920597_5897580640643303.png",
    coingeckoId: null,
    listedIn: [
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xcFe3FBc98D80f7Eca0bC76cD1F406A19dD425896",
    symbol: "NFTI",
    name: "Scalara NFT Index",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xcfe3fbc98d80f7eca0bc76cd1f406a19dd425896/1b9471fb022b55053db4954502441596.png",
    coingeckoId: "scalara-nft-index",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b",
    symbol: "WOO",
    name: "WOO Network",
    decimals: 18,
    chainId: "42161",
    logoURI: "https://get.celer.app/cbridge-icons/WOO.png",
    coingeckoId: "woo-network",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xcAB86F6Fb6d1C2cBeeB97854A0C023446A075Fe3",
    symbol: "deETH",
    name: "deBridge Ether",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0xc9c2B86CD4cdbAB70cd65D22EB044574c3539F6c",
    symbol: "NFD",
    name: "Feisty Doge NFT",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/17834/large/doge-fractionalized.png",
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d",
    symbol: "CREDA",
    name: "CreDA Protocol Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xc136e6b376a9946b156db1ed3a34b08afdaed76d/d0fecafd3eea1dd1389f99b509fe3231.png",
    coingeckoId: "creda",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xbE00f3db78688d9704BCb4e0a827aea3a9Cc0D62",
    symbol: "USD24",
    name: "Fiat24 USD",
    decimals: 2,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xbe00f3db78688d9704bcb4e0a827aea3a9cc0d62/logo.png",
    coingeckoId: "fiat24-usd",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2",
    symbol: "RAI",
    name: "Rai Reflex Index",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xaef5bbcbfa438519a5ea80b4c7181b4e78d419f2/fb2628e21ed326fbdee7317904c8e622.png",
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0xaaA62D9584Cbe8e4D68A43ec91BfF4fF1fAdB202",
    symbol: "MATTER",
    name: "Antimatter.Finance Governance Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xaaa62d9584cbe8e4d68a43ec91bff4ff1fadb202/4a2d317d4f828cff0421d8580489e6e4.png",
    coingeckoId: "antimatter",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689",
    symbol: "DF",
    name: "dForce",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xae6aab43c4f3e0cea4ab83752c278f8debaba689/02df44f03a3c1f24cf95915fcbdc57bb.png",
    coingeckoId: "dforce-token",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xa943F863fA69ff4F6D9022843Fb861BBEe45B2ce",
    symbol: "TNGL",
    name: "Tangle",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xa943f863fa69ff4f6d9022843fb861bbee45b2ce/logo.png",
    coingeckoId: "tangle",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xa684cd057951541187f288294a1e1C2646aA2d24",
    symbol: "VSTA",
    name: "Vesta Finance ",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xa684cd057951541187f288294a1e1c2646aa2d24/03aa8c8b6b6817bb2518f8acf6b612ef.png",
    coingeckoId: "vesta-finance",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0xa4f595Ba35161c9fFE3db8c03991B9C2CBB26C6b",
    symbol: "LYS",
    name: "LYS",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xa4f595ba35161c9ffe3db8c03991b9c2cbb26c6b/logo.png",
    coingeckoId: "lys-capital",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1",
    symbol: "GNO",
    name: "Gnosis Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1/logo.png",
    coingeckoId: "gnosis",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    symbol: "USDT",
    name: "Tether USD",
    decimals: 6,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9/logo.png",
    coingeckoId: "tether",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
    symbol: "UNI",
    name: "Uniswap",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0/78f517acfa1f4f10d87eb1768f15888f.png",
    coingeckoId: "uniswap",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    symbol: "USDC",
    name: "USD Coin Arb1",
    decimals: 6,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8/logo.png",
    coingeckoId: "usd-coin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
    symbol: "MIM",
    name: "MIM",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png?1624979612",
    coingeckoId: "magic-internet-money",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0xF7428FFCb2581A2804998eFbB036A43255c8A8D3",
    symbol: "sSPELL",
    name: "Staked Spell Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xF7428FFCb2581A2804998eFbB036A43255c8A8D3.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0xF4fe727C855c2D395852ca43F645caB4b504Af23",
    symbol: "HDX",
    name: "Hydranet",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xf4fe727c855c2d395852ca43f645cab4b504af23/57a4f8aa6dc70a2a4c8bcbb75426ab50.png",
    coingeckoId: "hydranet",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xF0DFAD1817b5ba73726B02Ab34dd4B4B00bcD392",
    symbol: "MNTO",
    name: "Minato",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xf0dfad1817b5ba73726b02ab34dd4b4b00bcd392/logo.png",
    coingeckoId: "minato",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xF0B5cEeFc89684889e5F7e0A7775Bd100FcD3709",
    symbol: "DUSD",
    name: "DigitalDollar",
    decimals: 6,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xf0b5ceefc89684889e5f7e0a7775bd100fcd3709/61faf1a640efc3e2f146cb010451b606.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xF0A5717Ec0883eE56438932b0fe4A20822735fBa",
    symbol: "XTK",
    name: "xToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xf0a5717ec0883ee56438932b0fe4a20822735fba/f4dadb3fce1d34184d90ffca5876534a.png",
    coingeckoId: "xtoken",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xEe9801669C6138E84bD50dEB500827b776777d28",
    symbol: "O3",
    name: "O3 Swap Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xee9801669c6138e84bd50deb500827b776777d28/98e28b4dbf26999a2c8ec90401eff245.png",
    coingeckoId: "o3-swap",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B",
    symbol: "FOREX",
    name: "handleFOREX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xdb298285fe4c5410b05390ca80e8fbe9de1f259b/logo.png",
    coingeckoId: "handle-fi",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    symbol: "DAI",
    name: "Dai stable coin",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/optimism/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1/logo.png",
    coingeckoId: "dai",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xD74f5255D557944cf7Dd0E45FF521520002D5748",
    symbol: "USDs",
    name: "Sperax USD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xd74f5255d557944cf7dd0e45ff521520002d5748/logo.png",
    coingeckoId: "sperax-usd",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c",
    symbol: "ICE",
    name: "Ice Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0xC74fE4c715510Ec2F8C61d70D397B32043F55Abe",
    symbol: "MYC",
    name: "Mycelium",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xc74fe4c715510ec2f8c61d70d397b32043f55abe/logo.png",
    coingeckoId: "mycelium",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xC72633F995e98Ac3BB8a89e6a9C4Af335C3D6E44",
    symbol: "OSEA",
    name: "Omnisea",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xc72633f995e98ac3bb8a89e6a9c4af335c3d6e44/logo.png",
    coingeckoId: "omnisea",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xC3Ae0333F0F34aa734D5493276223d95B8F9Cb37",
    symbol: "DXD",
    name: "DXdao",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xc3ae0333f0f34aa734d5493276223d95b8f9cb37/adef3f7305db2dc73919389940d04f66.png",
    coingeckoId: "dxdao",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E",
    symbol: "BADGER",
    name: "Badger",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xbfa641051ba0a0ad1b0acf549a89536a0d76472e/2f3339bf59c5195ee68955b7c6b8fcbb.png",
    coingeckoId: "badger-dao",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xBDeF0E9ef12E689F366fe494A7A7D0dad25D9286",
    symbol: "FUSE",
    name: "Fuse Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xbdef0e9ef12e689f366fe494a7a7d0dad25d9286/fa8df2c91c8cdd7c39d8a7b5bb9b6c50.png",
    coingeckoId: null,
    listedIn: [
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xB965029343D55189c25a7f3e0c9394DC0F5D41b1",
    symbol: "NDX",
    name: "Indexed",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xb965029343d55189c25a7f3e0c9394dc0f5d41b1/d6b2c7d1cc3580f83d6202f48f33ad0e.png",
    coingeckoId: "indexed-finance",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xB5de3f06aF62D8428a8BF7b4400Ea42aD2E0bc53",
    symbol: "BRC",
    name: "BrincToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xb5de3f06af62d8428a8bf7b4400ea42ad2e0bc53/629d469b1cac0dba70483037261548e8.png",
    coingeckoId: "brinc-finance",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814",
    symbol: "IMO",
    name: "Ideamarket",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xb41bd4c99da73510d9e081c5fadbe7a27ac1f814/0bd6ded1d1beaffd33cdbd64835be6ae.png",
    coingeckoId: "ideamarket",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xAFD871f684F21Ab9D7137608C71808f83D75e6fc",
    symbol: "BUCK",
    name: "Arbucks",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xafd871f684f21ab9d7137608c71808f83d75e6fc/d3a0a845b015f3d5fa44699aeb69b7b8.png",
    coingeckoId: "arbucks",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xAE6e3540E97b0b9EA8797B157B510e133afb6282",
    symbol: "DEGEN",
    name: "DEGEN Index",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "degen-index",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xA970AF1a584579B618be4d69aD6F73459D112F95",
    symbol: "sUSD",
    name: "Synth sUSD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xa970af1a584579b618be4d69ad6f73459d112f95/9882b42b09b0931b300f85e395950f5b.png",
    coingeckoId: "nusd",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xA7e1d00F1DC94040A8AAd3aD993589f3E39a6B7A",
    symbol: "ArbiMATTER",
    name: "ArbiMATTER",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0xa7e1d00f1dc94040a8aad3ad993589f3e39a6b7a/logo.png",
    coingeckoId: "arbimatter",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xA7Aa2921618e3D63dA433829d448b58C9445A4c3",
    symbol: "DVF",
    name: "DeversiFi Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xa7aa2921618e3d63da433829d448b58c9445a4c3/eadd8c868b547b4dfe0901d72a2bb743.png",
    coingeckoId: "rhinofi",
    listedIn: [
      "coingecko",
      "sushiswap",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xA72159FC390f0E3C6D415e658264c7c4051E9b87",
    symbol: "TCR",
    name: "Tracer",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0xa72159fc390f0e3c6d415e658264c7c4051e9b87/68643a9952d9b0ed410b2cad0790542d.png",
    coingeckoId: "tracer-dao",
    listedIn: [
      "coingecko",
      "sushiswap",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xA64eCCe74F8CdB7a940766B71f1b108BAC69851a",
    symbol: "WCHI",
    name: "Wrapped CHI",
    decimals: 8,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04",
    symbol: "ARVAULT",
    name: "ArVault",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0xA5eC9d64b64b8B9E94FEaA7538c084b38117E7Ba",
    symbol: "BLANK",
    name: "GoBlank Token",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x9f20de1fc9b161b34089cbEAE888168B44b03461",
    symbol: "ARBIS",
    name: "Arbis",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x9f20de1fc9b161b34089cbeae888168b44b03461/9aceb2dc7ff00504f00a61697d84ff1c.png",
    coingeckoId: "arbis-finance",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x9eF758aC000a354479e538B8b2f01b917b8e89e7",
    symbol: "XDO",
    name: "xDollar",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1637831645816_6024340081244819.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x9e724698051DA34994F281bD81C3E7372d1960AE",
    symbol: "ASCEND",
    name: "Ascension Protocol",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x9e724698051da34994f281bd81c3e7372d1960ae/c817e24c471c05cbe19348b11fd9d104.png",
    coingeckoId: "ascension-protocol",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7",
    symbol: "FXS",
    name: "Frax Share",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7/606b85af55178ace1c229f9fdc0a5a35.png",
    coingeckoId: "frax-share",
    listedIn: ["coingecko", "sushiswap", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x9c67eE39e3C4954396b9142010653F17257dd39C",
    symbol: "IMX",
    name: "Impermax",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x9c67ee39e3c4954396b9142010653f17257dd39c/f5399ab3446263219512fe04394fb7d6.png",
    coingeckoId: "impermax",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x9b3fa2A7C3EB36d048A5d38d81E7fAFC6bc47B25",
    symbol: "ALN",
    name: "Aluna",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "aluna",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3",
    symbol: "MULTI",
    name: "Multichain",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3/256d339d2c8c1fb670bb38446e8cedd6.png",
    coingeckoId: "multichain",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x99F40b01BA9C469193B360f72740E416B17Ac332",
    symbol: "MATH",
    name: "MATH Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x99f40b01ba9c469193b360f72740e416b17ac332/e6d0daf22f428f613820500c47d4d2b1.png",
    coingeckoId: "math",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE",
    symbol: "BIFI",
    name: "Beefy Finance",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x99c409e5f62e4bd2ac142f17cafb6810b8f0baae/92134086c01a3eaca15b9fc20178db2c.png",
    coingeckoId: "beefy-finance",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x995C235521820f2637303Ca1970c7c044583df44",
    symbol: "VISR",
    name: "VISOR",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/visr.jpg",
    coingeckoId: "visor",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x969131D8ddC06C2Be11a13e6E7fACF22CF57d95e",
    symbol: "EUX",
    name: "dForce EUR",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/eux.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x965772e0E9c84b6f359c8597C891108DcF1c5B1A",
    symbol: "PICKLE",
    name: "PickleToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x965772e0e9c84b6f359c8597c891108dcf1c5b1a/4a595cf8371b831ef756c42a97998e93.png",
    coingeckoId: "pickle-finance",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x955b9fe60a5b5093df9Dc4B1B18ec8e934e77162",
    symbol: "SWPR",
    name: "Swapr",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x955b9fe60a5b5093df9dc4b1b18ec8e934e77162/4f9b9fc1c5859d825d47addc0d49eda1.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x8eD4191F81F1e1D24a8a1195267D024d9358c9d7",
    symbol: "MAGNET",
    name: "Magnethereum",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/Magnethereum/MyFiles/main/magnethereum.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x8Fbd420956FDD301f4493500fd0BCaAa80f2389C",
    symbol: "AMY",
    name: "Amy Finance Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x8fbd420956fdd301f4493500fd0bcaaa80f2389c/449deb21bab53f2edf51eee9914a2f94.png",
    coingeckoId: null,
    listedIn: ["openocean", "rubic", "lifinance"],
  },
  {
    address: "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1",
    symbol: "gOHM",
    name: "gOHM",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png?1638764900",
    coingeckoId: "governance-ohm",
    listedIn: ["coingecko", "sushiswap", "lifinance", "xyfinance"],
  },
  {
    address: "0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA",
    symbol: "FLUID",
    name: "FluidFi",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x876ec6be52486eeec06bc06434f3e629d695c6ba/7bf6ac1b7829afba6d71b2f98d18f384.png",
    coingeckoId: "fluidfi",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x86b3353387F560295a8Fa7902679735E5f076Bd5",
    symbol: "OMIC",
    name: "Omicron",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x86b3353387f560295a8fa7902679735e5f076bd5/b98bd353bb12ed028dc5c4f7b1299918.png",
    coingeckoId: "omicron",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x86A1012d437BBFf84fbDF62569D12d4FD3396F8c",
    symbol: "ARBYS",
    name: "Arbys",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x86a1012d437bbff84fbdf62569d12d4fd3396f8c/5b19a1394d43b1b09b6afde55342bbfc.png",
    coingeckoId: "arbys",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x8616E8EA83f048ab9A5eC513c9412Dd2993bcE3F",
    symbol: "fxUSD",
    name: "handleUSD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x8616e8ea83f048ab9a5ec513c9412dd2993bce3f/logo.png",
    coingeckoId: "handleusd",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x848e0BA28B637e8490D88BaE51fA99C87116409B",
    symbol: "AGVE",
    name: "Agave",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "agave-token",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x82e3A8F066a6989666b031d916c43672085b1582",
    symbol: "YFI",
    name: "yearn.finance",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x82e3a8f066a6989666b031d916c43672085b1582/3e3d25929681a65f6efc98608843bc03.png",
    coingeckoId: "yearn-finance",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    symbol: "WETH",
    name: "Wrapped Ether",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/logo.png",
    coingeckoId: "weth",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
    symbol: "aArbDAI",
    name: "Aave Arbitrum DAI",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee/logo.png",
    coingeckoId: "aave-dai",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x8038F3C971414FD1FC220bA727F2D4A0fC98cb65",
    symbol: "DHT",
    name: "dHedge DAO Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x8038f3c971414fd1fc220ba727f2d4a0fc98cb65/8d92dcdd52d7171c4badfeb7c2ca4581.png",
    coingeckoId: "dhedge-dao",
    listedIn: ["coingecko", "1inch", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x7cb16cb78ea464aD35c8a50ABF95dff3c9e09d5d",
    symbol: "0xBTC",
    name: "0xBitcoin Token",
    decimals: 8,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x7cb16cb78ea464ad35c8a50abf95dff3c9e09d5d/f5cad5d29a407cb3f3332f9e08c9d2d7.png",
    coingeckoId: "oxbitcoin",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x7bA4a00d54A07461D9DB2aEF539e91409943AdC9",
    symbol: "SDT",
    name: "Stake DAO Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/sdt.jpg",
    coingeckoId: "stake-dao",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22",
    symbol: "PPEGG",
    name: "Parrot Egg",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x76b44e0Cf9bD024dbEd09E1785DF295D59770138",
    symbol: "deUSDС+2P3CRV-f",
    name: "Curve.fi Factory USD Metapool: deBridge-USDC",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x76b44e0cf9bd024dbed09e1785df295d59770138/logo.png",
    coingeckoId: "deusdc",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x75C9bC761d88f70156DAf83aa010E84680baF131",
    symbol: "SDL",
    name: "Saddle DAO",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x75c9bc761d88f70156daf83aa010e84680baf131/logo.png",
    coingeckoId: "saddle-finance",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x753D224bCf9AAFaCD81558c32341416df61D3DAC",
    symbol: "PERP",
    name: "Perpetual Protocol",
    decimals: 18,
    chainId: "42161",
    logoURI: "https://get.celer.app/cbridge-icons/PERP.png",
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x750bfe8490175c2A9A9387b19Aa2AaE2d75dB638",
    symbol: "BSGG",
    name: "Betswap.gg",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/22496/large/betswap.jpg",
    coingeckoId: null,
    listedIn: ["RouterProtocol", "lifinance"],
  },
  {
    address: "0x739ca6D71365a08f584c8FC4e1029045Fa8ABC4B",
    symbol: "wsOHM",
    name: "Wrapped sOHM",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x739ca6d71365a08f584c8fc4e1029045fa8abc4b/c7b207d06b64e982e3bedfacb7b02a1a.png",
    coingeckoId: "wrapped-staked-olympus",
    listedIn: ["coingecko", "1inch", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x6b289CCeAA8639e3831095D75A3e43520faBf552",
    symbol: "CTSI",
    name: "Cartesi Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/avax/0x6b289CCeAA8639e3831095D75A3e43520faBf552/logo.png",
    coingeckoId: null,
    listedIn: ["lifinance", "elkfinance"],
  },
  {
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
    symbol: "aArbUSDT",
    name: "Aave Arbitrum USDT",
    decimals: 6,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x6ab707aca953edaefbc4fd23ba73294241490620/057271a3c3a18b2776a0bf6baf0cfe0b.png",
    coingeckoId: "aave-usdt",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6F5401c53e2769c858665621d22DDBF53D8d27c5",
    symbol: "CNFI",
    name: "Connect Financial",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x6f5401c53e2769c858665621d22ddbf53d8d27c5/4889978b3314adadc1a686d92573fe26.png",
    coingeckoId: "connect-financial",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6E6a3D8F1AfFAc703B1aEF1F43B8D2321bE40043",
    symbol: "OHM",
    name: "Olympus",
    decimals: 9,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55",
    symbol: "DPX",
    name: "DPX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/16652/small/DPX_%281%29.png?1624598630",
    coingeckoId: "dopex",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x6Ba4edd6dB54eD34d53D8d8883E599C4dba009fb",
    symbol: "L2PAD",
    name: "L2PAD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x6ba4edd6db54ed34d53d8d8883e599c4dba009fb/logo.png",
    coingeckoId: "l2pad",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581",
    symbol: "DODO",
    name: "DODO Bird",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x69eb4fa4a2fbd498c257c57ea8b7655a2559a581/ded0ec2273c0c46b3ca67e81b4f1b916.png",
    coingeckoId: "dodo",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0xcf4C68Db4C2FA0bf58dF07B14f45cE7709a716AC",
    symbol: "ASHIB",
    name: "Arbi Shib",
    decimals: 18,
    chainId: "1",
    logoURI:
      "https://api.moontok.io/storage/logos/66orXgyUJpW1OImIJ9zXy6930aIH08xWQueRmfv9.jpg",
    coingeckoId: null,
    listedIn: ["optimism", "rubic", "lifinance"],
  },
  {
    address: "0x68eAd55C258d6fa5e46D67fc90f53211Eab885BE",
    symbol: "POP",
    name: "Popcorn",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "popcorn",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x66ba30d5D7c66aFd4ed41c3498eC9e20a03a1368",
    symbol: "eFIL",
    name: "Ethereum FIL",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x66ba30d5d7c66afd4ed41c3498ec9e20a03a1368/logo.png",
    coingeckoId: "efil",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x6694340fc020c5E6B96567843da2df01b2CE1eb6",
    symbol: "STG",
    name: "StargateToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x6694340fc020c5e6b96567843da2df01b2ce1eb6/3e78cc5b8a3f077a619a7ce556b41b25.png",
    coingeckoId: "stargate-finance",
    listedIn: ["coingecko", "sushiswap", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17",
    symbol: "VST",
    name: "Vesta Stable",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x64343594ab9b56e99087bfa6f2335db24c2d1f17/d7e68c59cc68ededbbb78135541449e9.png",
    coingeckoId: "vesta-stable",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb",
    symbol: "USX",
    name: "dForce USD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x641441c631e2f909700d2f41fd87f0aa6a6b4edb/09298f687c07bdcfab0137f654cd17e9.png",
    coingeckoId: "token-dforce-usd",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
    symbol: "aArbUSDC",
    name: "Aave Arbitrum USDC",
    decimals: 6,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x625e7708f30ca75bfd92586e17077590c60eb4cd/edbff4857cf186c17bfdac67f2b0e6b1.png",
    coingeckoId: "aave-usdc",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x590020B1005b8b25f1a2C82c5f743c540dcfa24d",
    symbol: "GMX",
    name: "GMX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/gmx.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x5837d843D49ffdefC78Fe09B5F371427c917946F",
    symbol: "QSD",
    name: "QIAN second generation dollar",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x5837d843d49ffdefc78fe09b5f371427c917946f/logo.png",
    coingeckoId: "qian-second-generation-dollar",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x55fF62567f09906A85183b866dF84bf599a4bf70",
    symbol: "KROM",
    name: "Kromatika",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x55ff62567f09906a85183b866df84bf599a4bf70/eeaad610e3d4b724fa8ba49b17283802.png",
    coingeckoId: "kromatika",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5575552988A3A80504bBaeB1311674fCFd40aD4B",
    symbol: "SPA",
    name: "Sperax",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x5575552988a3a80504bbaeb1311674fcfd40ad4b/7295eb4be6608118e780a89b745b701e.png",
    coingeckoId: "sperax",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x55704A0e9E2eb59E176C5b69655DbD3DCDCFc0F0",
    symbol: "OVR",
    name: "OVR",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x539bdE0d7Dbd336b79148AA742883198BBF60342",
    symbol: "MAGIC",
    name: "MAGIC",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/18623/small/Magic.png?1635755672",
    coingeckoId: "magic",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x5298Ee77A8f9E226898403eBAC33e68a62F770A0",
    symbol: "MTA",
    name: "Meta",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x5298060A95205BE6Dd4aBc21910A4bB23D6DCD8b",
    symbol: "ROUTE",
    name: "Route",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x51fC0f6660482Ea73330E414eFd7808811a57Fa2",
    symbol: "PREMIA",
    name: "Premia",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x51fc0f6660482ea73330e414efd7808811a57fa2/43731d0cf39b5297d63e8fb6adb3cb49.png",
    coingeckoId: "premia",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x51318B7D00db7ACc4026C88c3952B66278B6A67F",
    symbol: "PLS",
    name: "PLS",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/25326/small/M6nUndNU_400x400.jpg?1651233987",
    coingeckoId: "plutusdao",
    listedIn: ["coingecko", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42",
    symbol: "MCB",
    name: "MCDEX Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42/60bdacd5f4ca79bd62292fc9df937f83.png",
    coingeckoId: "mcdex",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07",
    symbol: "TUSD",
    name: "TrueUSD",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x4d15a3a2286d883af0aa1b3f21367843fac63e07/27ee4a21b4710859ac742c37eb84a21f.png",
    coingeckoId: "true-usd",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x488cc08935458403a0458e45E20c0159c8AB2c92",
    symbol: "FST",
    name: "Futureswap Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x488cc08935458403a0458e45e20c0159c8ab2c92/logo.png",
    coingeckoId: "futureswap",
    listedIn: ["coingecko", "arbitrum_bridge", "rubic"],
  },
  {
    address: "0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE",
    symbol: "LRC",
    name: "LoopringCoin V2",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "loopring",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x46D06cf8052eA6FdbF71736AF33eD23686eA1452",
    symbol: "ETHRISE",
    name: "ETH Leverage Risedle",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x46d06cf8052ea6fdbf71736af33ed23686ea1452/logo.png",
    coingeckoId: "ethrise",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x4425742F1EC8D98779690b5A3A6276Db85Ddc01A",
    symbol: "DOG",
    name: "The Doge NFT",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x4425742f1ec8d98779690b5a3a6276db85ddc01a/15b6ff9dcb0f15586af7c4a2698e94ca.png",
    coingeckoId: "the-doge-nft",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x3a8B787f78D775AECFEEa15706D4221B40F345AB",
    symbol: "CELR",
    name: "CelerToken",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x3a8b787f78d775aecfeea15706d4221b40f345ab/85b7638372276dfddaeaf806ba81002e.png",
    coingeckoId: "celer-network",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
    symbol: "MAI",
    name: "miMatic",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/xdai/0x3F56e0c36d275367b8C502090EDF38289b3dEa0d/logo.png",
    coingeckoId: "mimatic",
    listedIn: [
      "coingecko",
      "RouterProtocol",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF",
    symbol: "SPELL",
    name: "SPELL",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/15861/small/abracadabra-3.png?1622544862",
    coingeckoId: "spell-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba",
    symbol: "LAND",
    name: "Land",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x3BD2dFd03BC7c3011ed7fb8c4d0949B382726cee",
    symbol: "ROOBEE",
    name: "ROOBEE",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/8791/large/Group_11.png",
    coingeckoId: "roobee",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x39A49bc5017Fc668299Cd32e734C9269aCc35295",
    symbol: "PHONON",
    name: "Phonon DAO",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x39a49bc5017fc668299cd32e734c9269acc35295/logo.png",
    coingeckoId: "phonon-dao",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4",
    symbol: "WZYX",
    name: "Wrapped ZYX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x377c6e37633e390aef9afb4f5e0b16689351eed4/logo.png",
    coingeckoId: "zyx",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5",
    symbol: "PL2",
    name: "Plenny",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/pl2.jpg",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x354A6dA3fcde098F8389cad84b0182725c6C91dE",
    symbol: "COMP",
    name: "Compound",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x354a6da3fcde098f8389cad84b0182725c6c91de/adc1acf557aaf7702620d640bc44c1aa.png",
    coingeckoId: "compound-governance-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x3509f19581aFEDEff07c53592bc0Ca84e4855475",
    symbol: "XUSD",
    name: "xDollar Stablecoin",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1637892796159_3482286577377456.png",
    coingeckoId: "xdollar-stablecoin",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x32Eb7902D4134bf98A28b963D26de779AF92A212",
    symbol: "RDPX",
    name: "RDPX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/16659/small/rDPX_200x200_Coingecko.png?1624614475",
    coingeckoId: "dopex-rebate-token",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x326c33FD1113c1F29B35B4407F3d6312a8518431",
    symbol: "STRP",
    name: "Strips Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x326c33fd1113c1f29b35b4407f3d6312a8518431/8a3b61d78da1408ec2353ae77b856ecf.png",
    coingeckoId: "strips-finance",
    listedIn: [
      "coingecko",
      "sushiswap",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x319f865b287fCC10b30d8cE6144e8b6D1b476999",
    symbol: "CTSI",
    name: "Cartesi Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0x319f865b287fCC10b30d8cE6144e8b6D1b476999/logo.png",
    coingeckoId: "cartesi",
    listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
  },
  {
    address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    symbol: "WBTC",
    name: "WBTC",
    decimals: 8,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744",
    coingeckoId: "wrapped-bitcoin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879",
    symbol: "MKR",
    name: "Maker",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/icons/master/token/mkr.jpg",
    coingeckoId: "maker",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x2aD62674A64E698C24831Faf824973C360430140",
    symbol: "UBT",
    name: "UniBright",
    decimals: 8,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x2CaB3abfC1670D1a452dF502e216a66883cDf079",
    symbol: "L2DAO",
    name: "Layer2DAO",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x2cab3abfc1670d1a452df502e216a66883cdf079/43e2583a84298bc0144c1d7ef6ec8985.png",
    coingeckoId: "layer2dao",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x2C110867CA90e43D372C1C2E92990B00EA32818b",
    symbol: "STBZ",
    name: "Stabilize Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x2c110867ca90e43d372c1c2e92990b00ea32818b/logo.png",
    coingeckoId: "stabilize",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x23A941036Ae778Ac51Ab04CEa08Ed6e2FE103614",
    symbol: "GRT",
    name: "Graph Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x23a941036ae778ac51ab04cea08ed6e2fe103614/4805b6c4448e95d355d9f71ac464a573.png",
    coingeckoId: "the-graph",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x2338a5d62E9A766289934e8d2e83a443e8065b83",
    symbol: "FLUX",
    name: "Flux Protocol",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0x2338a5d62E9A766289934e8d2e83a443e8065b83.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x21E60EE73F17AC0A411ae5D690f908c3ED66Fe12",
    symbol: "DERI",
    name: "Deri",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: "deri-protocol",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    symbol: "deUSDC",
    name: "deBridge USD Coin",
    decimals: 6,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0x1a7BD9EDC36Fb2b3c0852bcD7438c2A957Fd7Ad5",
    symbol: "aMoon",
    name: "ArbiMoon",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://raw.githubusercontent.com/ArbiMoonXyz/MyFiles/main/arbimoon.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
    symbol: "aArbLINK",
    name: "Aave Arbitrum LINK",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x191c10aa4af7c30e871e70c95db0e4eb77237530/048393d32b2941d475463a9f9a9c1596.png",
    coingeckoId: "aave-link",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
    symbol: "FRAX",
    name: "FRAX",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    coingeckoId: "frax",
    listedIn: ["coingecko", "sushiswap", "openocean", "lifinance", "xyfinance"],
  },
  {
    address: "0x1622bF67e6e5747b81866fE0b85178a93C7F86e3",
    symbol: "UMAMI",
    name: "Umami",
    decimals: 9,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x1622bf67e6e5747b81866fe0b85178a93c7f86e3/43c05307911ec6babe0c74acf18e94f2.png",
    coingeckoId: "umami-finance",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x155f0DD04424939368972f4e1838687d6a831151",
    symbol: "ADoge",
    name: "ArbiDoge",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x155f0dd04424939368972f4e1838687d6a831151/18a36539de91bfc7cac1561b77ad672d.png",
    coingeckoId: "arbidoge",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0x123389C2f0e9194d9bA98c21E63c375B67614108",
    symbol: "EMAX",
    name: "EthereumMax",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x123389c2f0e9194d9ba98c21e63c375b67614108/07cc50616e621cbf131844a8a6c0b116.png",
    coingeckoId: "ethereummax",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978",
    symbol: "CRV",
    name: "Curve DAO Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978/logo.png",
    coingeckoId: "curve-dao-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x115D8bF0a53e751f8A472F88D587944EC1C8CA6D",
    symbol: "pBTC",
    name: "pTokens BTC",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x115d8bf0a53e751f8a472f88d587944ec1c8ca6d/logo.png",
    coingeckoId: "ptokens-btc-2",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x10393c20975cF177a3513071bC110f7962CD67da",
    symbol: "JONES",
    name: "JONES",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://assets.coingecko.com/coins/images/23290/small/3c8c2ed8-afb3-4b67-9937-5493acd88b50.jpg?1648597625",
    coingeckoId: "jones-dao",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0x10010078a54396F62c96dF8532dc2B4847d47ED3",
    symbol: "HND",
    name: "Hundred Finance",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x10010078a54396f62c96df8532dc2b4847d47ed3/cae168ae659748f245f0d57b76521952.png",
    coingeckoId: "hundred-finance",
    listedIn: ["coingecko", "sushiswap", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x0f4676178b5c53Ae0a655f1B19A96387E4b8B5f2",
    symbol: "CUDL",
    name: "CUDL",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x0f4676178b5c53ae0a655f1b19a96387e4b8b5f2/7a374c8534c11961732cd30ac83b0499.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x0e15258734300290a651FdBAe8dEb039a8E7a2FA",
    symbol: "ALCH",
    name: "Alchemy",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x0e15258734300290a651fdbae8deb039a8e7a2fa/7a8783c1a4b8f28b58a7a1de8dda207c.png",
    coingeckoId: "alchemy-dao",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x0F61B24272AF65EACF6adFe507028957698e032F",
    symbol: "ZIPT",
    name: "Zippie",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://tokens.1inch.io/0xedd7c94fd7b4971b916d15067bc454b9e1bad980.png",
    coingeckoId: null,
    listedIn: ["1inch", "arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1",
    symbol: "BOND",
    name: "BarnBridge Governance Token",
    decimals: 18,
    chainId: "42161",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "rubic", "lifinance"],
  },
  {
    address: "0x0C4681e6C0235179ec3D4F4fc4DF3d14FDD96017",
    symbol: "RDNT",
    name: "Radiant",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017/5319757609084fe1bd63a46bcbd0b0d0.png",
    coingeckoId: "radiant-capital",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
    symbol: "iUSD",
    name: "iZUMi Bond USD",
    decimals: 18,
    chainId: "42161",
    logoURI: "https://get.celer.app/cbridge-icons/iUSD.png",
    coingeckoId: "izumi-bond-usd",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x09ad12552ec45f82bE90B38dFE7b06332A680864",
    symbol: "ARBY",
    name: "Adamant Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x09ad12552ec45f82be90b38dfe7b06332a680864/b6506e2b71dcc8a274cd5b60de2c2784.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x080F6AEd32Fc474DD5717105Dba5ea57268F46eb",
    symbol: "SYN",
    name: "Synapse",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x080f6aed32fc474dd5717105dba5ea57268f46eb/90e387bcd6f85a586e3ad7427576149b.png",
    coingeckoId: "synapse-2",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x07E49d5dE43DDA6162Fa28D24d5935C151875283",
    symbol: "GOVI",
    name: "CVI Finance",
    decimals: 18,
    chainId: "42161",
    logoURI: "https://get.celer.app/cbridge-icons/GOVI.png",
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "lifinance"],
  },
  {
    address: "0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2",
    symbol: "QSD",
    name: " QIAN second generation dollar",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1637832211908_8528681646600584.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
    symbol: "aArbWBTC",
    name: "Aave Arbitrum WBTC",
    decimals: 8,
    chainId: "42161",
    logoURI:
      "https://api.rubic.exchange/assets/arbitrum/0x078f358208685046a11c85e8ad32895ded33a249/logo.png",
    coingeckoId: "aave-wbtc",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x04f3636B31c1EA2E48e275aA992d99a7bdbBe738",
    symbol: "ARRB",
    name: "Arrb Token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x04f3636b31c1ea2e48e275aa992d99a7bdbbe738/684e68814cf7404542592026c3d20943.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x04cb2d263a7489f02d813eaaB9Ba1bb8466347F2",
    symbol: "KUN",
    name: "QIAN governance token",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1637892437310_4683331777430453.png",
    coingeckoId: "chemix-ecology-governance-token",
    listedIn: [
      "coingecko",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8",
    symbol: "BAL",
    name: "Balancer",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8/cb8f7f37cfe1ad827dc5977d841a1294.png",
    coingeckoId: "balancer",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F",
    symbol: "AGVE",
    name: "Agave",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://elk.finance/tokens/logos/arbitrum/0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F/logo.png",
    coingeckoId: null,
    listedIn: ["arbitrum_bridge", "elkfinance"],
  },
  {
    address: "0x031d35296154279DC1984dCD93E392b1f946737b",
    symbol: "CAP",
    name: "Cap",
    decimals: 18,
    chainId: "42161",
    logoURI:
      "https://static.debank.com/image/arb_token/logo_url/0x031d35296154279dc1984dcd93e392b1f946737b/5731c294f2e59f3cf7290ce55c03fa30.png",
    coingeckoId: "cap",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "arbitrum_bridge",
      "rubic",
      "lifinance",
    ],
  },
];
