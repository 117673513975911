export const bsc = [
  {
    address: "0x0000000000000000000000000000000000000000",
    symbol: "BNB",
    name: "BNB",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/bsc/8bfdeaa46fe9be8f5cd43a53b8d1eea1.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F",
    symbol: "BORING",
    name: "BoringDAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F/logo.png",
    coingeckoId: "boringdao",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b",
    symbol: "KNC",
    name: "Kyber Network Crystal",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b/1d25e188deb06e642ea6f4f4f8eb0a0c.png",
    coingeckoId: "kyber-network-crystal",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0xfe01c159ECdeE4377aBfc4cD1827089C47B806EA",
    symbol: "NODY",
    name: "NodyToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfe01c159ecdee4377abfc4cd1827089c47b806ea/f0c273d3f3fe436642f7f5ad4c5bcd1a.png",
    coingeckoId: "nody",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xfd5af95C12446b60d23e16A4EA95690cE942e5dC",
    symbol: "FREL",
    name: "FREL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfd5af95c12446b60d23e16a4ea95690ce942e5dc/f987aa24d085cef1d8f66c6c4b49b8ca.png",
    coingeckoId: "freela",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xfbcf80ed90856AF0d6d9655F746331763EfDb22c",
    symbol: "NT",
    name: "NEXTYPE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfbcf80ed90856af0d6d9655f746331763efdb22c/5415a2efdd05e09b665448a0dadd8904.png",
    coingeckoId: "nextype-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xfb9C339b4BacE4Fe63ccc1dd9a3c3C531441D5fE",
    symbol: "SHILL",
    name: "Shill",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb9c339b4bace4fe63ccc1dd9a3c3c531441d5fe/dc0709b08eb3f94cc8e6fdb872b1d889.png",
    coingeckoId: "shill-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xfb7400707dF3D76084FBEae0109f41b178f71c02",
    symbol: "DOWS",
    name: "Shadows",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb7400707df3d76084fbeae0109f41b178f71c02/7573a51f8ee3d7b6b8d2ce9a667f1afe.png",
    coingeckoId: "shadows",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xfb62AE373acA027177D1c18Ee0862817f9080d08",
    symbol: "DPET",
    name: "My DeFi Pet Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb62ae373aca027177d1c18ee0862817f9080d08/6ba82bc06d24d8fa513d0d14da96aaa6.png",
    coingeckoId: "my-defi-pet",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xfb6115445Bff7b52FeB98650C87f44907E58f802",
    symbol: "BAAVE",
    name: "Binance Pegged AAVE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xfb6115445Bff7b52FeB98650C87f44907E58f802/logo.png",
    coingeckoId: "aave",
    listedIn: ["coingecko", "lifinance", "elkfinance"],
  },
  {
    address: "0xfb5B838b6cfEEdC2873aB27866079AC55363D37E",
    symbol: "FLOKI",
    name: "FLOKI",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb5b838b6cfeedc2873ab27866079ac55363d37e/4dba60d2f00756d3a811f74931bcf73d.png",
    coingeckoId: "floki-inu",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xfEbe8C1eD424DbF688551D4E2267e7A53698F0aa",
    symbol: "VINU",
    name: "Vita Inu",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa/9656e03dda3cb0a2a72855c760bf871a.png",
    coingeckoId: "vita-inu",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba",
    symbol: "MIM",
    name: "Magic Internet Money",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba/7d0c0fb6eab1b7a8a9bfb7dcc04cb11e.png",
    coingeckoId: "magic-internet-money",
    listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
  },
  {
    address: "0xfDFD27aE39cebefDBaAc8615F18aa68DDD0F15f5",
    symbol: "GHD",
    name: "Giftedhands on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfdfd27ae39cebefdbaac8615f18aa68ddd0f15f5/72898a9afebda8575d897acca8b6ac86.png",
    coingeckoId: "giftedhands",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xfD799ddCCA8ab5c1ad8a3D64a58d4e907C9d0B71",
    symbol: "TAP",
    name: "TAP",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0xfd799ddcca8ab5c1ad8a3d64a58d4e907c9d0b71.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xfD5840Cd36d94D7229439859C0112a4185BC0255",
    symbol: "vUSDT",
    name: "Venus USDT",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfd5840cd36d94d7229439859c0112a4185bc0255/87d28aa3d4345191420dba57d962f0ba.png",
    coingeckoId: "venus-usdt",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c",
    symbol: "renBTC",
    name: "renBTC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c/logo.png",
    coingeckoId: "renbtc",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xfC4427aF0A329312fBa0851F2eF0C7Db08Aa100f",
    symbol: "FlokiV",
    name: "Floki Verse",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfc4427af0a329312fba0851f2ef0c7db08aa100f/3c21a02292ba179850ea86f243d4084f.png",
    coingeckoId: "flokiverse",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xfB28110038D251F14BE3BdC5CEB00116f8969629",
    symbol: "PANX",
    name: "Pandora X",
    decimals: 5,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xfAa3B1CaD63Acfd446b8bD0Fe6A157e4dC5b8A1a",
    symbol: "WINE",
    name: "Wine Protocol",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "wine-protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e",
    symbol: "SOL",
    name: "Wrapped SOL (Wormhole)",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfa54ff1a158b5189ebba6ae130ced6bbd3aea76e/66208e2c846bcae176be7e594eb1f25c.png",
    coingeckoId: "sol-wormhole",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE",
    symbol: "CHR",
    name: "Chromia",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE/logo.png",
    coingeckoId: "chromaway",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xf9A2d40589271Be17612A3F57A9028A568f56e3d",
    symbol: "DoKEN",
    name: "DoKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf9a2d40589271be17612a3f57a9028a568f56e3d/595823532a3bb31b13c86ef901758729.png",
    coingeckoId: "doken",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343",
    symbol: "vFIL",
    name: "Venus FIL",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf91d58b5ae142dacc749f58a49fcbac340cb0343/43245ae12bcff32da567ba8b526582a1.png",
    coingeckoId: "venus-fil",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xf8E026dC4C0860771f691EcFFBbdfe2fa51c77CF",
    symbol: "BGOV",
    name: "BGOV Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf8e026dc4c0860771f691ecffbbdfe2fa51c77cf/fc775c1a3aae5f1869f63640e7846b2c.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830",
    symbol: "BRY",
    name: "Berry",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830/logo.png",
    coingeckoId: "berry-data",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xf7fB08C187E6CD1f2149E6C818D0b6d4d4Ef1430",
    symbol: "STN",
    name: "STN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0xf7fb08c187e6cd1f2149e6c818d0b6d4d4ef1430.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4",
    symbol: "HARD",
    name: "HARD",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xf7722aA0714096f1FB5ef83e6041CEBB4d58a08e",
    symbol: "RUBY",
    name: "RUBY",
    decimals: 12,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf7722aa0714096f1fb5ef83e6041cebb4d58a08e/e10e241dcbc7e1fa0603fe106f8f3e87.png",
    coingeckoId: "ruby-play-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf7686F43591302Cd9B4b9c4FE1291473Fae7D9C9",
    symbol: "LSS",
    name: "Chainport.io-Peg Lossless Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf7686f43591302cd9b4b9c4fe1291473fae7d9c9/ece888c8d850909a0bf67fecc1b15ada.png",
    coingeckoId: "lossless",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xf50B0a35EfdF8F247625E2A0695D56a63b30B7ff",
    symbol: "M1Verse",
    name: "MetaOneVerse",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf50b0a35efdf8f247625e2a0695d56a63b30b7ff/67c8628893ccc80f1b5c71af34315466.png",
    coingeckoId: "metaoneverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf508fCD89b8bd15579dc79A6827cB4686A3592c8",
    symbol: "vETH",
    name: "Venus ETH",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf508fcd89b8bd15579dc79a6827cb4686a3592c8/1355a506809a33070c54666b5e78cc18.png",
    coingeckoId: "venus-eth",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xf4914E6D97a75f014AcFcF4072f11be5CfFc4cA6",
    symbol: "DEXShare",
    name: "DEXShare",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf4914e6d97a75f014acfcf4072f11be5cffc4ca6/dd0941927d7fdd1d4bb3b97c2a4efde6.png",
    coingeckoId: "dexshare",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf483af09917bA63F1E274056978036d266EB56e6",
    symbol: "BULL",
    name: "BULL COIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf483af09917ba63f1e274056978036d266eb56e6/65b59f29d0aa7bb647dfb519dbe18cb8.png",
    coingeckoId: "bull-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf3E1449DDB6b218dA2C9463D4594CEccC8934346",
    symbol: "CELL",
    name: "Cellframe Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf3e1449ddb6b218da2c9463d4594ceccc8934346/4d8ee3523ddfca915232aa5295415f42.png",
    coingeckoId: "cellframe",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xf307910A4c7bbc79691fD374889b36d8531B08e3",
    symbol: "ANKR",
    name: "Ankr",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf307910A4c7bbc79691fD374889b36d8531B08e3/logo.png",
    coingeckoId: "ankr",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xf2E00684457de1a3C87361bC4BfE2DE92342306C",
    symbol: "SHIELDNET",
    name: "Shield Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xf2e00684457de1a3c87361bc4bfe2de92342306c/logo.png",
    coingeckoId: "shield-network",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xf2572fDACf09bfAE08FF7D35423870B5a8aC26b7",
    symbol: "PLATO",
    name: "Plato Game",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf2572fdacf09bfae08ff7d35423870b5a8ac26b7/b5996d2ae1dc991e0e0edb1b90231e1e.png",
    coingeckoId: "plato-game",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf224AdE71c20f9823E34e0792F72437596b4e28c",
    symbol: "SHIBO",
    name: "ShiBonk",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf224ade71c20f9823e34e0792f72437596b4e28c/fc6463f7bdafd2921aca92c94d199ba1.png",
    coingeckoId: "shibonk",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xf209CE1960Fb7E750ff30Ba7794ea11C6Acdc1f3",
    symbol: "GFN",
    name: "Graphene",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xf209ce1960fb7e750ff30ba7794ea11c6acdc1f3/logo.png",
    coingeckoId: "graphene",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xf16e81dce15B08F326220742020379B855B87DF9",
    symbol: "ICE",
    name: "IceToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf16e81dce15b08f326220742020379b855b87df9/e7a766dfa717182ab11805d4582638d5.png",
    coingeckoId: "ice-token",
    listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
  },
  {
    address: "0xf0E406c49C63AbF358030A299C0E00118C4C6BA5",
    symbol: "NVT",
    name: "NerveNetwork",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf0E406c49C63AbF358030A299C0E00118C4C6BA5/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5",
    symbol: "WELL",
    name: "BitWell Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xf06419C638a1d809C024Aa693147dBDc42B60145",
    symbol: "BANKER",
    name: "BankerDoge",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf06419c638a1d809c024aa693147dbdc42b60145/c3d1faf2dd899f4fa3b6b35196add4f3.png",
    coingeckoId: "bankerdoge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c",
    symbol: "OG",
    name: "OG",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xf0443834B7b21104b7102Edbe8F9ec06204Cd395",
    symbol: "TAO",
    name: "Friction Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xf0443834b7b21104b7102edbe8f9ec06204cd395.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xee1ae38BE4Ce0074C4A4A8DC821CC784778f378c",
    symbol: "VYNC",
    name: "VYNK CHAIN",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xee1ae38be4ce0074c4a4a8dc821cc784778f378c/701c172d215904b54cb76c51e1afa013.png",
    coingeckoId: "vynk-chain",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xedeCfB4801C04F3EB394b89397c6Aafa4ADDa15B",
    symbol: "PYRAM",
    name: "Pyram Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xedecfb4801c04f3eb394b89397c6aafa4adda15b/e834556d744312a3e5f0c7ffd5bbc9fb.png",
    coingeckoId: "pyram-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8",
    symbol: "vUSDC",
    name: "Venus USDC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xeca88125a5adbe82614ffc12d0db554e2e2867c8/f1b0fc4eb29e9c2932baf0ebded19a51.png",
    coingeckoId: "venus-usdc",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xebAFFc2d2EA7c66fb848c48124b753F93A0A90ec",
    symbol: "ASIA",
    name: "ASIA COIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xebaffc2d2ea7c66fb848c48124b753f93a0a90ec/ff845be3170588bc55fe1b2aff06c539.png",
    coingeckoId: "asia-coin",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xea89199344a492853502a7A699Cc4230854451B8",
    symbol: "ONI",
    name: "Onino",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xea89199344a492853502a7a699cc4230854451b8/607f10ecdcbd9314d55a1f67bdef7fc9.png",
    coingeckoId: "oni-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xeEf6020B7720f4e000476b017Fc4e224dFC0aA36",
    symbol: "ENTITY",
    name: "Entity",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xeef6020b7720f4e000476b017fc4e224dfc0aa36/cdf88a7011916e02d59c3c9fa842b247.png",
    coingeckoId: "entity",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
    symbol: "ELK",
    name: "Elk",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance", "elkfinance"],
  },
  {
    address: "0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D",
    symbol: "HPS",
    name: "HappinessToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xeda21b525ac789eab1a08ef2404dd8505ffb973d/08de116e76c6d0579a365789ad2c5d56.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C",
    symbol: "pBTC",
    name: "pBTC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C/logo.png",
    coingeckoId: "ptokens-btc",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xeCEB87cF00DCBf2D4E2880223743Ff087a995aD9",
    symbol: "NUM",
    name: "NUM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://storage.googleapis.com/xy-finance-images/token-list/Num.png",
    coingeckoId: "numbers-protocol",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0xeC13736FFB2A50F3A4817E5797C8A8A777A147CB",
    symbol: "BLOOP",
    name: "Baby Loop",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: "baby-loop",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xeBd49b26169e1b52c04cFd19FCf289405dF55F80",
    symbol: "ORBS",
    name: "The Orbs Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xeBd49b26169e1b52c04cFd19FCf289405dF55F80/logo.png",
    coingeckoId: "orbs",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xeBC76079Da0c245faE7225b58a57A54809b40618",
    symbol: "BPAY",
    name: "BNBPay",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xebc76079da0c245fae7225b58a57a54809b40618/80be4b92b7146f8d66ac3685c2dec002.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xeBBAefF6217d22E7744394061D874015709b8141",
    symbol: "WAM",
    name: "WAM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xebbaeff6217d22e7744394061d874015709b8141/logo.png",
    coingeckoId: "wam",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD",
    symbol: "RAINI",
    name: "Rainicorn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed/logo.png",
    coingeckoId: "rainicorn",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    symbol: "BUSD",
    name: "Binance Pegged BUSD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
    coingeckoId: "binance-usd",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xe9c64384dEb0C2bF06D991A8D708c77eb545E3d5",
    symbol: "RDT",
    name: "Ridotto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe9c64384deb0c2bf06d991a8d708c77eb545e3d5/b06e988ecc73c9ba23f096ea872dca1d.png",
    coingeckoId: "ridotto",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe91a8D2c584Ca93C7405F15c22CdFE53C29896E3",
    symbol: "DEXT",
    name: "DEXTools",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe91a8d2c584ca93c7405f15c22cdfe53c29896e3/373995147e4ca4464d7f44560bb09497.png",
    coingeckoId: "dextools",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe90d1567ecEF9282CC1AB348D9e9E2ac95659B99",
    symbol: "CXPAD",
    name: "",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xe90d1567ecef9282cc1ab348d9e9e2ac95659b99/logo.png",
    coingeckoId: "coinxpad",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xe8A8db73f7fb1Ab5F05C619A8a631c8930c782Ae",
    symbol: "Future of Fintech",
    name: "FOF",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe8a8db73f7fb1ab5f05c619a8a631c8930c782ae/c8f2e96a8bc28b33b8200777b47c4801.png",
    coingeckoId: "future-of-fintech",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe898EDc43920F357A93083F1d4460437dE6dAeC2",
    symbol: "TITAN",
    name: "TitanSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xe898EDc43920F357A93083F1d4460437dE6dAeC2/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8",
    symbol: "HOKK",
    name: "Hokkaido Inu",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8/09e510841bb72c807eabf20b1cceed5d.png",
    coingeckoId: "hokkaidu-inu",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65",
    symbol: "USD+",
    name: "USD+",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe80772eaf6e2e18b651f160bc9158b2a5cafca65/58363c9095ed5998f48688b3c52c78c7.png",
    coingeckoId: "usd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe7c9C6bc87B86f9E5b57072F907EE6460B593924",
    symbol: "TOWER",
    name: "TOWER",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe7c9c6bc87b86f9e5b57072f907ee6460b593924/ef1f53cfc0238001c099cdc939f671e6.png",
    coingeckoId: "tower",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xe79a1163a95734ccFBd006cBAaba954f3e846BeB",
    symbol: "SHACK",
    name: "Shack Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe79a1163a95734ccfbd006cbaaba954f3e846beb/1d848e07530c3546eef9e3c564b1da88.png",
    coingeckoId: "shack",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B",
    symbol: "SWG",
    name: "SWGToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xe792f64C582698b8572AAF765bDC426AC3aEfb6B/logo.png",
    coingeckoId: "swgtoken",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xe77011ed703ed06927dBd78e60c549baBAbF913e",
    symbol: "mBTC",
    name: "Micro Bitcoin Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe77011ed703ed06927dbd78e60c549bababf913e/9de44e0e871632c36a88e634b1703b51.png",
    coingeckoId: "micro-bitcoin-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe6F3A0Cd28d9add9077E80b8e05cbE01B7532EC7",
    symbol: "ART",
    name: "Around Network",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "around-network-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe6DF05CE8C8301223373CF5B969AFCb1498c5528",
    symbol: "KOGE",
    name: "BNB48 Club Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe6df05ce8c8301223373cf5b969afcb1498c5528/12862f0752bdb8520a026b911c3aaff1.png",
    coingeckoId: "bnb48-club-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5",
    symbol: "DERI",
    name: "Deri",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5/logo.png",
    coingeckoId: "deri-protocol",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b",
    symbol: "KaiInu",
    name: "Kai Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe5a09784b16e1065c37df14c6e2f06fdce317a1b/5d0d9d9786b66eba893b90e686b4ffe9.png",
    coingeckoId: "kai-inu",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xe56a473043EaAB7947c0a2408cEA623074500EE3",
    symbol: "SWAP",
    name: "SafeSwap Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe56a473043eaab7947c0a2408cea623074500ee3/555b51de7d21ef81da9036785f1e5785.png",
    coingeckoId: "safeswap-online",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xe56842Ed550Ff2794F010738554db45E60730371",
    symbol: "BIN",
    name: "Binemon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe56842ed550ff2794f010738554db45e60730371/4757cd67be0bdab66cf8b691b56b7721.png",
    coingeckoId: "binemon",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xe552Fb52a4F19e44ef5A967632DBc320B0820639",
    symbol: "Metis",
    name: "Metis Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe552fb52a4f19e44ef5a967632dbc320b0820639/3b30ff79e54dab6fc535a4ad00859837.png",
    coingeckoId: "metis-token",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A",
    symbol: "tDOGE",
    name: "Tau DOGE",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A/logo.png",
    coingeckoId: "tdoge",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5",
    symbol: "GAL",
    name: "Project Galaxy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe4cc45bb5dbda06db6183e8bf016569f40497aa5/f95ad63af1fa73172e211b4993c9336c.png",
    coingeckoId: "project-galaxy",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xe4B22193d68f18f8E8eB3a26F4D64cb6D4573022",
    symbol: "MOZ",
    name: "MOZ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe4b22193d68f18f8e8eb3a26f4d64cb6d4573022/e2bfcf58946679bfa3ece0a97c80782a.png",
    coingeckoId: "mozik",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xe4A4Ad6E0B773f47D28f548742a23eFD73798332",
    symbol: "GNY",
    name: "GNY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe4a4ad6e0b773f47d28f548742a23efd73798332/dfd6f855077f76039a0360fae4e65cae.png",
    coingeckoId: "gny",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE",
    symbol: "FXS",
    name: "FXS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/13423/small/frax_share.png?1608478989",
    coingeckoId: "frax-share",
    listedIn: ["coingecko", "sushiswap", "openocean", "lifinance", "xyfinance"],
  },
  {
    address: "0xe486a69E432Fdc29622bF00315f6b34C99b45e80",
    symbol: "SPACE",
    name: "Space Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe486a69e432fdc29622bf00315f6b34c99b45e80/ef68e8d9c083e72d0fa2b75f5f8ca7c9.png",
    coingeckoId: "space-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe3E1FabEaBD48491bD6902B0c32FDEee8D2Ff12b",
    symbol: "UNICORN",
    name: "UNICORN Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xe3e1fabeabd48491bd6902b0c32fdeee8d2ff12b.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9",
    symbol: "FROYO",
    name: "Froyo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe369fec23380f9f14ffd07a1dc4b7c1a9fdd81c9/320c0d986b1e8a51c28bc3477ad2162c.png",
    coingeckoId: "froyo-games",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D",
    symbol: "UFI",
    name: "PureFi Token ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d/9783286b1f474c3dd154ca1664575c46.png",
    coingeckoId: "purefi",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xe2604C9561D490624AA35e156e65e590eB749519",
    symbol: "GM",
    name: "GoldMiner",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe2604c9561d490624aa35e156e65e590eb749519/262c1aa23a8c27530dbb2cc5b3c334a1.png",
    coingeckoId: "goldminer",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034",
    symbol: "wBAN",
    name: "Wrapped Banano",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034/ebce77f5bfef64cf5b252c77749bd8ee.png",
    coingeckoId: "banano",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xe1DB3d1eE5CfE5C6333BE96e6421f9Bd5b85c987",
    symbol: "SAFESPACE",
    name: "SAFESPACE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe1db3d1ee5cfe5c6333be96e6421f9bd5b85c987/8a53873d715ceb5ade14b594afb087fe.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xe138c66982Fd5c890c60b94FDBa1747faF092c20",
    symbol: "XFT",
    name: "Offshift ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe138c66982fd5c890c60b94fdba1747faf092c20/ee34c8bec4551ca91087ef18d8337273.png",
    coingeckoId: "offshift",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe0cCa86B254005889aC3a81e737f56a14f4A38F5",
    symbol: "ALTA",
    name: "Alta Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/18713/large/AFN-token-Altafin-200.png",
    coingeckoId: "alta-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66",
    symbol: "CEEK",
    name: "CEEK",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66/85ee91f0b84a2e4bbebb79146ddb5bd2.png",
    coingeckoId: "ceek",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xe0191fEfdd0D2B39b1a2E4E029cCDA8A481b7995",
    symbol: "CRUX",
    name: "CRUX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/24538/small/Webp.net-resizeimage_%281%29.png?1648072028",
    coingeckoId: "cryptomines-reborn",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0xdef1fac7Bf08f173D286BbBDcBeeADe695129840",
    symbol: "CERBY",
    name: "Cerby Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdef1fac7bf08f173d286bbbdcbeeade695129840/1ef478564ddbbd0440ef43af79437666.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0xde3dbBE30cfa9F437b293294d1fD64B26045C71A",
    symbol: "NFTB",
    name: "NFTB",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xde3dbbe30cfa9f437b293294d1fd64b26045c71a/80d2132666209193415b3f924c9f2922.png",
    coingeckoId: "nftb",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xde301D6a2569aEfcFe271B9d98f318BAee1D30a4",
    symbol: "LUS",
    name: "Luna Rush Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xde301d6a2569aefcfe271b9d98f318baee1d30a4/0099deeee69df6a620470c9b395f55d4.png",
    coingeckoId: "luna-rush",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92",
    symbol: "PNT",
    name: "PNT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92/logo.png",
    coingeckoId: "pnetwork",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xdaDf16BE87e745aa3efC78135d260eA4960b2F92",
    symbol: "EVM",
    name: "EverMOON",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdadf16be87e745aa3efc78135d260ea4960b2f92/4f6ab5b6d8a1d4379b6b908af3fb4b60.png",
    coingeckoId: "evermoon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xdFF8cb622790b7F92686c722b02CaB55592f152C",
    symbol: "TEN",
    name: "Tenet",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xdFF8cb622790b7F92686c722b02CaB55592f152C/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xdFE6891ce8E5a5c7Cf54fFdE406A6C2C54145F71",
    symbol: "$CINU",
    name: "CHEEMSINU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdfe6891ce8e5a5c7cf54ffde406a6c2c54145f71/261018f051cbfb78329ac2fc99891e3d.png",
    coingeckoId: "cheems-inu",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xdFC3829b127761a3218bFceE7fc92e1232c9D116",
    symbol: "BPRCY",
    name: "Wrapped PRCY",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdfc3829b127761a3218bfcee7fc92e1232c9d116/fecf2f559c956a4e53ebc98fadfaf6f1.png",
    coingeckoId: "prcy-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xdF9e1A85dB4f985D5BB5644aD07d9D7EE5673B5E",
    symbol: "MM72",
    name: "MM72",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdf9e1a85db4f985d5bb5644ad07d9d7ee5673b5e/15cc14cc67993b7e0ced33a6a5995828.png",
    coingeckoId: "mm72",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xdCE27D41b4Ff72B55a876c621f5A5Fef8537c99D",
    symbol: "Royal",
    name: "ROY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdce27d41b4ff72b55a876c621f5a5fef8537c99d/fbbc630481f20fb54eee20a6d35e22c1.png",
    coingeckoId: "royal-protocol",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xdBf3e320b9bAa9e2Bd2D3268D82DD99812B30662",
    symbol: "FM",
    name: "FOLLOW ME",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xdBB66Eb9f4D737B49aE5CD4De25E6C8da8B034f9",
    symbol: "CANU",
    name: "Cannumo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xdbb66eb9f4d737b49ae5cd4de25e6c8da8b034f9/logo.png",
    coingeckoId: "cannumo",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c",
    symbol: "BABYCAKE",
    name: "BabyCake",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c/logo.png",
    coingeckoId: "baby-cake",
    listedIn: ["coingecko", "pancake", "rubic", "lifinance", "elkfinance"],
  },
  {
    address: "0xdA20C8a5c3B1AB48e31ba6e43f0F2830E50218D8",
    symbol: "BINGUS",
    name: "Bingus Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8/7122d98a067dfbe8d113b87940ab421e.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xd9150910de9A4df5D734f4d9B5ebe82BCCe9095a",
    symbol: "CBT",
    name: "CryptoBattles",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd9150910de9a4df5d734f4d9b5ebe82bcce9095a/89bdf0886f82066567a34a5b7d19bf3e.png",
    coingeckoId: "cryptobattles",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xd9025e25Bb6cF39f8c926A704039D2DD51088063",
    symbol: "CYT",
    name: "Coinary Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd9025e25bb6cf39f8c926a704039d2dd51088063/7e6629dc81a9a47f5827833a8f829a9e.png",
    coingeckoId: "coinary-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd8a2aE43Fd061d24acd538e3866Ffc2c05151b53",
    symbol: "AIR",
    name: "AIR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd8a2ae43fd061d24acd538e3866ffc2c05151b53/eef48684a0a0c0ef595713d733f9b937.png",
    coingeckoId: "aircoin-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd8CB4C2369db13C94c90C7fD3bEbc9757900Ee6b",
    symbol: "NPXB",
    name: "NapoleonX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd8cb4c2369db13c94c90c7fd3bebc9757900ee6b/5487cc4d6ad3dfc21df5614080629645.png",
    coingeckoId: "napoleon-x",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd8A31016cD7da048ca21FFE04256C6d08C3A2251",
    symbol: "WENLAMBO",
    name: "WenLambo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd8a31016cd7da048ca21ffe04256c6d08c3a2251/9e279e1b99efa73fca9296a23fc8b949.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28",
    symbol: "XWIN",
    name: "xWIN Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28/37725606af3c5420c3c058ff745d34e0.png",
    coingeckoId: "xwin-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xd73F32833B6D5D9c8070c23e599e283a3039823C",
    symbol: "WTF",
    name: "Waterfall Governance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd73f32833b6d5d9c8070c23e599e283a3039823c/1939c256d375f6dec66e5d858f3ac676.png",
    coingeckoId: "waterfall-governance-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd72aA9e1cDDC2F6D6e0444580002170fbA1f8eED",
    symbol: "MDA",
    name: "Moeda Loyalty Points",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xd72aa9e1cddc2f6d6e0444580002170fba1f8eed.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xd6fDDe76B8C1C45B33790cc8751D5b88984c44ec",
    symbol: "STRX",
    name: "StrikeX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec/f4dbbaf630509714036683996b37f4df.png",
    coingeckoId: "strikecoin",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xd632Bd021a07AF70592CE1E18717Ab9aA126DECB",
    symbol: "bKANGAL",
    name: "Kangal",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd632bd021a07af70592ce1e18717ab9aa126decb/ef953ae1c14c180e122d6d660067ed01.png",
    coingeckoId: "kangal",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xd5d0322b6bAb6a762C79f8c81A0B674778E13aeD",
    symbol: "FIRO",
    name: "Firo",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd5d0322b6bab6a762c79f8c81a0b674778e13aed/2b26e3066167256a8eda80280c15b9f9.png",
    coingeckoId: "binance-peg-firo",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd52669712f253CD6b2Fe8A8638F66ed726cb770C",
    symbol: "XCUR",
    name: "Curate",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/13327/large/400x400_%281%29_%283%29_%282%29.png",
    coingeckoId: "curate",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd4fBc57B6233F268E7FbA3b66E62719D74deecBc",
    symbol: "MOD",
    name: "Modefi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd4fbc57b6233f268e7fba3b66e62719d74deecbc/44b44df34234bc3aac8f0bc5e6e69ff1.png",
    coingeckoId: "modefi",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888",
    symbol: "CREAM",
    name: "Cream",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xd3c9609B6CBC6eF02390F33C230590c38F9E5f9D",
    symbol: "PROT",
    name: "Protein",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd3c9609b6cbc6ef02390f33c230590c38f9e5f9d/f7547138739492f87320ddec848fb773.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xd32d01A43c869EdcD1117C640fBDcfCFD97d9d65",
    symbol: "NMX",
    name: "Nominex",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65/c8f7fcdd46b75b9d73652b42cdd3f1e6.png",
    coingeckoId: "nominex",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xd2DdFba7bb12f6e70c2AAB6B6bf9EdaEf42ed22F",
    symbol: "UBU",
    name: "UBUToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd2ddfba7bb12f6e70c2aab6b6bf9edaef42ed22f/833a8b547236edc531d7ff4e66019077.png",
    coingeckoId: "ubu-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xd21d29B38374528675C34936bf7d5Dd693D2a577",
    symbol: "PRQ",
    name: "Parsiq Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd21d29b38374528675c34936bf7d5dd693d2a577/a02d84413c9ede3e474eec90e8726531.png",
    coingeckoId: "parsiq",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xd17479997F34dd9156Deef8F95A52D81D265be9c",
    symbol: "USDD",
    name: "Decentralized USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd17479997f34dd9156deef8f95a52d81d265be9c/70890e6172f62f4430bfeaff32680884.png",
    coingeckoId: "usdd",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0xd10Fe9d371C22416df34340287cd5D9029842Fc3",
    symbol: "GHC",
    name: "GalaxyHeroes",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd10fe9d371c22416df34340287cd5d9029842fc3/2a9582a4ebd32ae68a53f4e0043d0d2f.png",
    coingeckoId: "galaxy-heroes",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24",
    symbol: "PRL",
    name: "Parallel Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd07e82440a395f3f3551b42da9210cd1ef4f8b24/981c7a9bbbd255e40be6da33952b883a.png",
    coingeckoId: "the-parallel",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A",
    symbol: "ADS",
    name: "ADS",
    decimals: 11,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/868/small/ads_tr_900.png?1549005693",
    coingeckoId: "adshares",
    listedIn: ["coingecko", "1inch", "lifinance", "xyfinance"],
  },
  {
    address: "0xcf87Ccf958d728f50d8ae5E4f15Bc4cA5733cDf5",
    symbol: "ATLAS",
    name: "Atlantis Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcf87ccf958d728f50d8ae5e4f15bc4ca5733cdf5/3240a017f94a74650b4f8a700423f987.png",
    coingeckoId: "atlantis",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xceE4579f115aC80731A2412121E119985020d9E0",
    symbol: "$STRANGER",
    name: "STRANGERCOIN",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xce5347fdd503f25f8428151A274544A5bD1Bd8cA",
    symbol: "UNIF",
    name: "Unified",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xce5347fdd503f25f8428151a274544a5bd1bd8ca.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xce42b6cdF5FAf84C6C3923a8c6c2324031f7692b",
    symbol: "VRBLOCKS",
    name: "VR Blocks",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xce42b6cdf5faf84c6c3923a8c6c2324031f7692b/4a3776c9d3ec6f0f55a542eea5282be0.png",
    coingeckoId: "vr-blocks",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xcaF5191fc480F43e4DF80106c7695ECA56E48B18",
    symbol: "DEP",
    name: "DEAPCOIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcaf5191fc480f43e4df80106c7695eca56e48b18/7f36d5ad53cd19789a3a1236d45c5833.png",
    coingeckoId: "deapcoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xca8a893a7464E82BDeE582017c749b92e5B45b48",
    symbol: "MW",
    name: "Metaworld",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca8a893a7464e82bdee582017c749b92e5b45b48/25fda392b8d417c469a52869a7b5dd01.png",
    coingeckoId: "metaworld",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xca830317146BfdDE71e7C0B880e2ec1f66E273EE",
    symbol: "GULL",
    name: "PolyGod",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca830317146bfdde71e7c0b880e2ec1f66e273ee/b68d11c74dc7b83af4e258b0e84bfe68.png",
    coingeckoId: "polygod",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xcFE087ed979244DB595E62a7bC1E01e6De6870e9",
    symbol: "Kdoge",
    name: "Koreadoge",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcfe087ed979244db595e62a7bc1e01e6de6870e9/3371f8bf275505349ea77ce43c049d86.png",
    coingeckoId: "koreadoge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
    symbol: "XVS",
    name: "Venus Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63/logo.png",
    coingeckoId: "venus",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xcE186ad6430E2Fe494a22C9eDbD4c68794a28B35",
    symbol: "LOOP",
    name: "LoopNetwork",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xce186ad6430e2fe494a22c9edbd4c68794a28b35/827a60d2d411e276139652606023b5c6.png",
    coingeckoId: "loopnetwork",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xcA14caF9E8dD2793e7010fC48dFE6c6AF8445136",
    symbol: "BNF",
    name: "BonFi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca14caf9e8dd2793e7010fc48dfe6c6af8445136/5a83f1bab07f8f24492b9bddcbe4bde6.png",
    coingeckoId: "bonfi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc9457161320210D22F0D0d5fC1309Acb383d4609",
    symbol: "DOV",
    name: "DOV",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc9457161320210d22f0d0d5fc1309acb383d4609/10fd22429749668d6e985af9ced7e036.png",
    coingeckoId: "dovu",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xc9132C76060F6b319764Ea075973a650A1a53bC9",
    symbol: "DDIM",
    name: "DuckDaoDime",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc9132c76060f6b319764ea075973a650a1a53bc9/bcf7b6ca41bdda4568d6eda7d9b613d2.png",
    coingeckoId: "duckdaodime",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xc8E8ecB2A5B5d1eCFf007BF74d15A86434aA0c5C",
    symbol: "DRS",
    name: "DragonSlayer",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc8e8ecb2a5b5d1ecff007bf74d15a86434aa0c5c/aa7d70a1f08290beba5cabbae63e9038.png",
    coingeckoId: "dragon-slayer",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc864019047B864B6ab609a968ae2725DFaee808A",
    symbol: "BIT",
    name: "BIT TOKEN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc864019047b864b6ab609a968ae2725dfaee808a/9e0be135de508dea4fdc0adc5a8213e9.png",
    coingeckoId: "biconomy-exchange-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xc7981767f644C7F8e483DAbDc413e8a371b83079",
    symbol: "LIQ",
    name: "Liquidus",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc7981767f644c7f8e483dabdc413e8a371b83079/0c283acd66b0e2dcfed28ff2140986ee.png",
    coingeckoId: "liquidus",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc74cD0042c837Ce59210857504eBb0859E06aA22",
    symbol: "SAFUYIELD",
    name: "SafuYield Protocol",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc74cd0042c837ce59210857504ebb0859e06aa22/624757a6fa7567b4aa28ed4f80a6792f.png",
    coingeckoId: "safuyield-protocol",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xc748673057861a797275CD8A068AbB95A902e8de",
    symbol: "BABYDOGE",
    name: "Baby Doge Coin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc748673057861a797275cd8a068abb95a902e8de/007ce20bc3d33f66e950bfd1466144cd.png",
    coingeckoId: "baby-doge-coin",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xc732B6586A93b6B7CF5FeD3470808Bc74998224D",
    symbol: "KMON",
    name: "KmonCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc732b6586a93b6b7cf5fed3470808bc74998224d/6fc6bf2c069a60bdc48cb8d1c7e792b9.png",
    coingeckoId: "kryptomon",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xc7091AA18598b87588e37501b6Ce865263CD67Ce",
    symbol: "CCAKE",
    name: "CheesecakeSwap Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc7091aa18598b87588e37501b6ce865263cd67ce/5cefc2781b526bce7e465b5ee4658a18.png",
    coingeckoId: "cheesecakeswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b",
    symbol: "URUS",
    name: "Aurox Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b/4816f703bcf33ec16f8a8306516d2be1.png",
    coingeckoId: "urus-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03",
    symbol: "ALPA",
    name: "AlpaToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03/logo.png",
    coingeckoId: "alpaca",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d",
    symbol: "SWAMP",
    name: "Swampy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/logo.png",
    coingeckoId: "swampy",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xc53708664b99DF348dd27C3Ac0759d2DA9c40462",
    symbol: "GUM",
    name: "Gourmet Galaxy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xc53708664b99DF348dd27C3Ac0759d2DA9c40462/logo.png",
    coingeckoId: "gourmetgalaxy",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xc4DaA5a9f2B832eD0f9Bc579662883cD53EA9d61",
    symbol: "BRICK",
    name: "BrickChain",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc4daa5a9f2b832ed0f9bc579662883cd53ea9d61/5abc9c3db01964ec17e78d00026f3af7.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xc4Caf585c2b7eAF3F61ac69b1529E79F90B15569",
    symbol: "MITHRIL",
    name: "Mithril",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc4caf585c2b7eaf3f61ac69b1529e79f90b15569/5323925a71d6d2a5afbeed2baf6282c0.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xc3ee300e716978F36CFDca73CbdB6Fb690bCb94e",
    symbol: "$LUNAPE",
    name: "Luna Ape Protocol",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "luna-ape-protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc342774492b54ce5F8ac662113ED702Fc1b34972",
    symbol: "BGEO",
    name: "BinanceGeoDBCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc342774492b54ce5f8ac662113ed702fc1b34972/021ab33a001561205dfdac00272951c4.png",
    coingeckoId: "geodb",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc2e1acef50aE55661855E8dcB72adB182A3cC259",
    symbol: "BTS",
    name: "Bolt Share",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc2e1acef50ae55661855e8dcb72adb182a3cc259/e3e28e2afdbcbf4a01e7ef68cd7c4b23.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16",
    symbol: "stkBNB",
    name: "Staked BNB",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16.png",
    coingeckoId: "pstake-staked-bnb",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xc28Ea768221f67B6A1fD33e6aa903d4e42f6b177",
    symbol: "PAPER",
    name: "Paper",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc28ea768221f67b6a1fd33e6aa903d4e42f6b177/90c0d2d958d2f87daf1df47bff8d642f.png",
    coingeckoId: "dope-wars-paper",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xc26EaFC627624baDf990f8d30116892eD204DB51",
    symbol: "SCC",
    name: "SiaCashCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc26eafc627624badf990f8d30116892ed204db51/79d652d6f827f920fc8d365426771a9a.png",
    coingeckoId: "siacashcoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc25D94fc3f8D7bD1d88f89802fe075338F71dEC7",
    symbol: "PNIX",
    name: "PhoenixDefi.Finance",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xc25d94fc3f8d7bd1d88f89802fe075338f71dec7/logo.png",
    coingeckoId: "phoenixdefi-finance",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xc2098a8938119A52B1F7661893c0153A6CB116d5",
    symbol: "RPG",
    name: "Rangers Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc2098a8938119a52b1f7661893c0153a6cb116d5/80548f899d63297808b8a274217284c6.png",
    coingeckoId: "rangers-protocol-gas",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xc15e89f2149bCC0cBd5FB204C9e77fe878f1e9b2",
    symbol: "HUH",
    name: "HUH Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc15e89f2149bcc0cbd5fb204c9e77fe878f1e9b2/70e8e7fc7b3a9b9b1c820cb73bfb7005.png",
    coingeckoId: "huh",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc10358f062663448a3489fC258139944534592ac",
    symbol: "BCMC",
    name: "Blockchain Monster Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc10358f062663448a3489fc258139944534592ac/37f9af3762afccfdaae3c86b38d8bb5b.png",
    coingeckoId: "blockchain-monster-hunt",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xc0924EDEFB2C0C303de2d0c21BfF07ab763163B5",
    symbol: "SteamX",
    name: "Steam Exchange",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc0924edefb2c0c303de2d0c21bff07ab763163b5/86f77cd5c126af60c343a21b498f2a06.png",
    coingeckoId: "steam-exchange",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xc04a23149efdF9A63697f3Eb60705147e9f07FfD",
    symbol: "GENI",
    name: "GemUni",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc04a23149efdf9a63697f3eb60705147e9f07ffd/1f890d1a5c4597b9e49bd563f0f9f229.png",
    coingeckoId: "gemuni",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbf675ed044f2092a4F004aA5709BfA858641cE8B",
    symbol: "JTS",
    name: "Jetset",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xbf675ed044f2092a4f004aa5709bfa858641ce8b/logo.png",
    coingeckoId: "jetset",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd",
    symbol: "pCWS",
    name: "PolyCrowns",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd/logo.png",
    coingeckoId: "crowns",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xbccf768576eb4d28Fe3dD21f9EBCf9784E4e092c",
    symbol: "DUT",
    name: "DAR UTILITIES TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbccf768576eb4d28fe3dd21f9ebcf9784e4e092c/af4f25f64fe0b858610116addd80c23c.png",
    coingeckoId: "dar-dex-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbcbdecf8e76A5C32Dba69De16985882ace1678c6",
    symbol: "RVC",
    name: "Revenue Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbcbdecf8e76a5c32dba69de16985882ace1678c6/cbdb6dcd50584ba439fbc88999e105d4.png",
    coingeckoId: "revenue-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
    symbol: "BSCS",
    name: "BSCS Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d/569662c5a4d1905b047a55257e195b3d.png",
    coingeckoId: "bsc-station",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xbcDE162a6f7a45Fbb6913b296E325fe1267A1E9F",
    symbol: "MONX",
    name: "Monster Of God",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbcde162a6f7a45fbb6913b296e325fe1267a1e9f/83262cc587f6e34ca19d4682af9860f2.png",
    coingeckoId: "monster-of-god",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbc194e6f748a222754C3E8b9946922c09E7d4e91",
    symbol: "LEV",
    name: "LEV",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbc194e6f748a222754c3e8b9946922c09e7d4e91/a651de268d4cf3d727d93d19809c5c29.png",
    coingeckoId: "lever-network",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    symbol: "WBNB",
    name: "WBNB Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png",
    coingeckoId: "wbnb",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xba049d49E6D73B463b6AE482ECF2334aFf84B83B",
    symbol: "BEEINU",
    name: "BEE INU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xba049d49e6d73b463b6ae482ecf2334aff84b83b/056ed75311be78d6213b5a16bc244170.png",
    coingeckoId: "bee-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbFa0841F7a90c4CE6643f651756EE340991F99D5",
    symbol: "NYA",
    name: "Nyanswop Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xbFa0841F7a90c4CE6643f651756EE340991F99D5/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe",
    symbol: "EGLD",
    name: "Elrond",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe/logo.png",
    coingeckoId: "wrapped-elrond",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91",
    symbol: "COC",
    name: "Coin of the champions",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbdc3b3639f7aa19e623a4d603a3fb7ab20115a91/351de78241eb1588828d6475bf5a737d.png",
    coingeckoId: "coin-of-the-champions",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbAc79E308BadA146DE29f56E066a021cfFA00375",
    symbol: "UP",
    name: "UP Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbac79e308bada146de29f56e066a021cffa00375/db7b5bc59aeba989ac1b0ecc58752d35.png",
    coingeckoId: "updefi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0",
    symbol: "SHAKE",
    name: "SHAKE token by SpaceSwap v2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0/dedf25a89ffce14517275d4c44da30c2.png",
    coingeckoId: "spaceswap-shake",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xbA5eAB68a7203C9FF72E07b708991F07f55eF40E",
    symbol: "KODI",
    name: "Kodi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xba5eab68a7203c9ff72e07b708991f07f55ef40e/ac605ff97ea96d6ea42937887d629b14.png",
    coingeckoId: "kodi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    symbol: "DOGE",
    name: "Dogecoin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xbA2aE424d960c26247Dd6c32edC70B295c744C43/logo.png",
    coingeckoId: "binance-peg-dogecoin",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xb9E05B4C168B56F73940980aE6EF366354357009",
    symbol: "AMES",
    name: "Amethyst",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb9e05b4c168b56f73940980ae6ef366354357009/204f752d6688af680f9432f14fa210ad.png",
    coingeckoId: "amethyst",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb93ba7DC61ECFced69067151FC00C41cA369A797",
    symbol: "WENMOON",
    name: "WenMoon Token",
    decimals: 7,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb93ba7dc61ecfced69067151fc00c41ca369a797/d7de80ad5d992a42cb44b6fa1deda8bc.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E",
    symbol: "QUSD",
    name: "QUSD Stablecoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787",
    symbol: "ZIL",
    name: "Zilliqa",
    decimals: 12,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787/logo.png",
    coingeckoId: "zilliqa",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xb7fda7374362F66a50665b991aA7Ee77b2c6abBE",
    symbol: "TCUB",
    name: "TCUB www.tiger-king.org",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb7fda7374362f66a50665b991aa7ee77b2c6abbe/452797e0786875af67442511a24a7d6b.png",
    coingeckoId: "tiger-cub",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xb7F8Cd00C5A06c0537E2aBfF0b58033d02e5E094",
    symbol: "PAX",
    name: "Paxos Standard",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094/e97fff67ca026e8037fce32198f335fd.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xb7CEF49d89321e22dd3F51a212d58398Ad542640",
    symbol: "MILK",
    name: "Milk",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb7cef49d89321e22dd3f51a212d58398ad542640/ddfef780aa0fc2152278e12f34a75de4.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xb6d48FCEf36E19681EE29896B19c1b6CbD1eAB1B",
    symbol: "FAN",
    name: "Fanadise",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb6d48fcef36e19681ee29896b19c1b6cbd1eab1b/cd27aa1bdfeb0c88412af07ca4d43b13.png",
    coingeckoId: "fanadise",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb6b91269413b6B99242B1c0Bc611031529999999",
    symbol: "CALO",
    name: "CALO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb6b91269413b6b99242b1c0bc611031529999999/cad06b62fa96da3839931aa5bb8e9e6b.png",
    coingeckoId: "calo-app",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb6C53431608E626AC81a9776ac3e999c5556717c",
    symbol: "TLOS",
    name: "Telos",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb6c53431608e626ac81a9776ac3e999c5556717c/046a3ebcf5bee0e284e151f8e3a9efc7.png",
    coingeckoId: "wrapped-telos",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xb698AC9bC82C718D8ebA9590564B9a5AA53D58e6",
    symbol: "LESS",
    name: "LessToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb698ac9bc82c718d8eba9590564b9a5aa53d58e6/369f860fbb720e752b37df5d33825ba9.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xb5Be8D87FcE6Ce87a24b90AbDB019458A8eC31F9",
    symbol: "OBOT",
    name: "OBORTECH",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb5be8d87fce6ce87a24b90abdb019458a8ec31f9/ed4dace7d1dfbb6f84e0568d43ffd689.png",
    coingeckoId: "obortech",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723",
    symbol: "LIT",
    name: "Litentry",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xb5389A679151C4b8621b1098C6E0961A3CFEe8d4",
    symbol: "LAUNCH",
    name: "Super Launcher",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb5389a679151c4b8621b1098c6e0961a3cfee8d4/a9c6820845f38796547506cd8a4dc255.png",
    coingeckoId: "superlauncher-dao",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xb4CD493f14eDAaE27FdaaB87072F3D55d9289A18",
    symbol: "CHD",
    name: "CharityDao Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb4cd493f14edaae27fdaab87072f3d55d9289a18/cebff9ae477fa7c076e2b74d8e912c6d.png",
    coingeckoId: "charitydao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb49B7e0742EcB4240ffE91661d2A580677460b6A",
    symbol: "PERI",
    name: "PeriFinanceToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb49b7e0742ecb4240ffe91661d2a580677460b6a/12e5c9a0873ae69651b4ba7a89edca68.png",
    coingeckoId: "peri-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05",
    symbol: "TRIVIA",
    name: "Trivian Token",
    decimals: 3,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb465f3cb6aba6ee375e12918387de1eac2301b05/2cf0e4cec3733a2754fb0cf21f47d3a8.png",
    coingeckoId: "trivian",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36",
    symbol: "GEAR",
    name: "MetaGear Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb4404dab7c0ec48b428cf37dec7fb628bcc41b36/b450e466528a73105b4a265d14d7ee51.png",
    coingeckoId: "metagear",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xb422DE11a237d0cbCE17D7d70f53F67ef84394D3",
    symbol: "MONKE",
    name: "Space Monkey",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb422de11a237d0cbce17d7d70f53f67ef84394d3/62244aebc1b1b0d34b0b53dda3a92439.png",
    coingeckoId: "space-monkey-token",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xb3a6381070B1a15169DEA646166EC0699fDAeA79",
    symbol: "GOLD",
    name: "CyberDragon Gold",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb3a6381070b1a15169dea646166ec0699fdaea79/ba83d8b058e72f4ecb1bd5ebdc2b931f.png",
    coingeckoId: "cyberdragon-gold",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xb3A95BdBe4AC65B0628db1E6600F71ed59b89255",
    symbol: "UV",
    name: "UnityVentures",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb3a95bdbe4ac65b0628db1e6600f71ed59b89255/9960d0d267932337ba5ba7bdc4e9bf10.png",
    coingeckoId: "unityventures",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xb38A2A58911372fdB12e2D4E4da8714C7f652261",
    symbol: "CFA",
    name: "Coin Fast Alert",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb38a2a58911372fdb12e2d4e4da8714c7f652261/1ea8e458d7d10d63e12a93a5becb6bf1.png",
    coingeckoId: "coin-fast-alert",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb2ea51BAa12C461327d12A2069d47b30e680b69D",
    symbol: "SMCW",
    name: "Space Crown",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb2ea51baa12c461327d12a2069d47b30e680b69d/9144a08b0f0b362b0e14a4f536efebe2.png",
    coingeckoId: "space-misfits",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897",
    symbol: "Skeleton",
    name: "Defiskeletons",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb0688e82d162df5288a0d986dffd4cf80afb7897/6bc3d884ddb7a5af033cd37274cb981b.png",
    coingeckoId: "defiskeletons",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xb001f1E7c8bda414aC7Cf7Ecba5469fE8d24B6de",
    symbol: "UCO",
    name: "UnirisToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb001f1e7c8bda414ac7cf7ecba5469fe8d24b6de/e7ca6756759c5a00a3a3e548b99ceb01.png",
    coingeckoId: "archethic",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xaf6162DC717CFC8818eFC8d6f46a41Cf7042fCBA",
    symbol: "USV",
    name: "Universal Store of Value",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaf6162dc717cfc8818efc8d6f46a41cf7042fcba/b79b4f5fff5665519190e8f3e44dba1f.png",
    coingeckoId: "atlas-usv",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaf2052d2933A117C5d21314662baCB53839aEDc9",
    symbol: "SONA",
    name: "Sona Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaf2052d2933a117c5d21314662bacb53839aedc9/635bcab05ca01bba0c3b3bcf1cad0f1f.png",
    coingeckoId: "sona-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaf0bd10E1b6B995E54F342C78DACd25291e0d3B2",
    symbol: "SPR",
    name: "Spring V2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaf0bd10e1b6b995e54f342c78dacd25291e0d3b2/86b3d0de576b5ec558845b68761fa060.png",
    coingeckoId: "spring-game",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99",
    symbol: "WIN",
    name: "WINk",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99/logo.png",
    coingeckoId: "winklink-bsc",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02",
    symbol: "TOON",
    name: "PontoonToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaee433adebe0fbb88daa47ef0c1a513caa52ef02/b3b07c0da2807d2ec85aa6daa86afc82.png",
    coingeckoId: "pontoon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xacb2d47827C9813AE26De80965845D80935afd0B",
    symbol: "MCRN",
    name: "MacaronSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xacb2d47827C9813AE26De80965845D80935afd0B/logo.png",
    coingeckoId: "macaronswap",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xacFC95585D80Ab62f67A14C566C1b7a49Fe91167",
    symbol: "FEG",
    name: "FEGtoken",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xacfc95585d80ab62f67a14c566c1b7a49fe91167/logo.png",
    coingeckoId: "feg-token-bsc",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xab167E816E4d76089119900e941BEfdfA37d6b32",
    symbol: "SHINJA",
    name: "Shibnobi",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xab167e816e4d76089119900e941befdfa37d6b32/39b420c9d261ce9ef01167cdb96a2468.png",
    coingeckoId: "shibnobi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaa3387B36a4aCd9D2c1326a7f10658d7051b73a6",
    symbol: "BSCB",
    name: "BSCBAY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaa3387b36a4acd9d2c1326a7f10658d7051b73a6/fc7c8b39bdc6d54af3cb198420ad983d.png",
    coingeckoId: "bscbay",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaFb64E73dEf6fAa8B6Ef9a6fb7312d5C4C15ebDB",
    symbol: "GVR",
    name: "Grove Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xafb64e73def6faa8b6ef9a6fb7312d5c4c15ebdb/0579d4c58a7ff91eff2387e092edc4de.png",
    coingeckoId: "grove",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaFF9084f2374585879e8B434C399E29E80ccE635",
    symbol: "FLUX",
    name: "Flux",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaff9084f2374585879e8b434c399e29e80cce635/ef87612e78930f4cdc86e40261950db3.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xaFE3321309A994831884fc1725F4c3236AC79f76",
    symbol: "$MFLATE",
    name: "Memeflate",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xafe3321309a994831884fc1725f4c3236ac79f76/85f0da11963faae1cf7fb6fda32e69b0.png",
    coingeckoId: "memeflate",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaF6Bd11A6F8f9c44b9D18f5FA116E403db599f8E",
    symbol: "ALIX",
    name: "AlinX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaf6bd11a6f8f9c44b9d18f5fa116e403db599f8e/a495117afc6c96910d72092527eb4b11.png",
    coingeckoId: "alinx",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5",
    symbol: "PROM",
    name: "Prometeus",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5/logo.png",
    coingeckoId: "prometeus",
    listedIn: ["coingecko", "sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6",
    symbol: "C98",
    name: "Coin98",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6/logo.png",
    coingeckoId: "coin98",
    listedIn: [
      "coingecko",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xaE936f3f89d2E8a86163f6fdbFEF8ae6b2BA6437",
    symbol: "VCE",
    name: "Vince",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xae936f3f89d2e8a86163f6fdbfef8ae6b2ba6437/019cd1eabb5a161ae700e2030b5969e1.png",
    coingeckoId: "vince-chain",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaE459484c895a335ceC08058290D94551DBf5fbB",
    symbol: "WEFIN",
    name: "Wrapped EFIN",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xae459484c895a335cec08058290d94551dbf5fbb/1dc5939af2c07ccd56d7b963c45c4862.png",
    coingeckoId: "efin-decentralized",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaDD8A06fd58761A5047426e160B2B88AD3B9D464",
    symbol: "CHS",
    name: "cheesemaker.farm",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xadd8a06fd58761a5047426e160b2b88ad3b9d464/df0cb7fbfde212d2b35bda974d6f7146.png",
    coingeckoId: "cheeseswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xaCE3574B8b054E074473a9Bd002e5dC6dd3dfF1B",
    symbol: "RBX",
    name: "RBX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xace3574b8b054e074473a9bd002e5dc6dd3dff1b/3aed4825210cc889170be83c65fff5ad.png",
    coingeckoId: "rbx-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xaC472D0EED2B8a2f57a6E304eA7eBD8E88D1d36f",
    symbol: "ANI",
    name: "Anime",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f/9b548b06b48eecd89a1f65cfde84f047.png",
    coingeckoId: "anime-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5",
    symbol: "pOPEN",
    name: "pTokens OPEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xaA9E582e5751d703F85912903bacADdFed26484C",
    symbol: "HAI",
    name: "Hacken",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xaA9E582e5751d703F85912903bacADdFed26484C/logo.png",
    coingeckoId: "hackenai",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xaA88C603d142C371eA0eAC8756123c5805EdeE03",
    symbol: "DOG",
    name: "The Doge NFT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaa88c603d142c371ea0eac8756123c5805edee03/15b6ff9dcb0f15586af7c4a2698e94ca.png",
    coingeckoId: "the-doge-nft",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90",
    symbol: "WEX",
    name: "WaultSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90/logo.png",
    coingeckoId: "waultswap",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xa96658cd0D04a8fdCDc30D1156CC65BbFC7591eD",
    symbol: "SUSHIBA",
    name: "Sushiba",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa96658cd0d04a8fdcdc30d1156cc65bbfc7591ed/43520ffeb5ba334ae2a56ab17bca77d3.png",
    coingeckoId: "sushiba",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xa86d305A36cDB815af991834B46aD3d7FbB38523",
    symbol: "BR34P",
    name: "BR34P",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa86d305a36cdb815af991834b46ad3d7fbb38523/e7f2260128f4ec0f59e9c20be3c0381e.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xa865197A84E780957422237B5D152772654341F3",
    symbol: "OLE",
    name: "OpenLeverage",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa865197a84e780957422237b5d152772654341f3/c0cd50d2dbad188dac9edbe640c21b00.png",
    coingeckoId: "openleverage",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xa5593837af2b99021fe657c80d70d2365F5CfD38",
    symbol: "ASGARD",
    name: "AsgardToken",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa5593837af2b99021fe657c80d70d2365f5cfd38/89d1bda5cae5e270c33d98107ed2ee28.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xa5342D72D04c133180f376753f90a4B2eEe29bB3",
    symbol: "DMC",
    name: "Decentralized Mining Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa5342d72d04c133180f376753f90a4b2eee29bb3/a8bea9cc68907d063163a41b393cad25.png",
    coingeckoId: "decentralized-mining-exchange",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa4b6E76bba7413B9B4bD83f4e3AA63cc181D869F",
    symbol: "FTM",
    name: "Fitmin Finance Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa4b6e76bba7413b9b4bd83f4e3aa63cc181d869f/5d67187081b5d305baaab1d65b21957f.png",
    coingeckoId: "fitmin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa4838122c683f732289805FC3C207Febd55BabDD",
    symbol: "TRIAS",
    name: "\bTrias Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xa4838122c683f732289805fc3c207febd55babdd/logo.png",
    coingeckoId: "trias-token",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xa477a79a118A84A0d371A53c8F46f8CE883EC1dD",
    symbol: "BBS",
    name: "BBS [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd/976012cc2c1bf83393835dc91babc703.png",
    coingeckoId: "bbs-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xa2f017966d967ec697C7A20Cf9D0b43CB8d4fF1D",
    symbol: "SPC",
    name: "Spectra",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa2f017966d967ec697c7a20cf9d0b43cb8d4ff1d/551ba30700a1853b1bf000d1bc1aff4e.png",
    coingeckoId: "spectra",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xa2B726B1145A4773F68593CF171187d8EBe4d495",
    symbol: "INJ",
    name: "Injective Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xa2B726B1145A4773F68593CF171187d8EBe4d495/logo.png",
    coingeckoId: "injective-protocol",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xa2A3Be47bEb973d48401010742D5E442177d037B",
    symbol: "CZbusd",
    name: "CZpegs BUSD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa2a3be47beb973d48401010742d5e442177d037b/83b5f55dd5cc3f2292c61a39f48b4e2e.png",
    coingeckoId: "czbusd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa296aD1C47FE6bDC133f39555C1D1177BD51fBc5",
    symbol: "iBFR",
    name: "iBufferToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa296ad1c47fe6bdc133f39555c1d1177bd51fbc5/eb278996b138b97f483032ddbbdc8dfd.png",
    coingeckoId: "ibuffer-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
    symbol: "ALPHA",
    name: "AlphaToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xa1faa113cbE53436Df28FF0aEe54275c13B40975/logo.png",
    coingeckoId: "alpha-finance",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    symbol: "AUTO",
    name: "AUTOv2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xa184088a740c695E156F91f5cC086a06bb78b827/logo.png",
    coingeckoId: "auto",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E",
    symbol: "PMLG",
    name: "Polychain Monsters Land Genesis Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa14c04dea16798aa8f25b1da583cd5fbbfd6579e/4c1d98ca0ea6a7b21a207bb37687528e.png",
    coingeckoId: "polychain-monsters-genesis",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa0F6C82bB4B09872dF3cc8369F9ED672AE8d3fAf",
    symbol: "$Apes",
    name: "Thunder Apes",
    decimals: 2,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc",
    symbol: "mNFLX",
    name: "Mirror NFLX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xa045E37a0D1dd3A45fefb8803D22457abc0A728a",
    symbol: "GHNY",
    name: "Grizzly Honey",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa045e37a0d1dd3a45fefb8803d22457abc0a728a/14c1622e8f5abb1de9bb969b74ffc523.png",
    coingeckoId: "grizzly-honey",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xa031d93DD0dC21Bb799D3f7780e9D29df5210114",
    symbol: "BEPIS",
    name: "BEPIS",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa031d93dd0dc21bb799d3f7780e9d29df5210114/eaa5f5356cdb4d91ed287037869f6680.png",
    coingeckoId: "bepis",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4",
    symbol: "CAPS",
    name: "Capsule Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xffba7529ac181c2ee1844548e6d7061c9a597df4/7a78ef5b34f4e7db21d548216788f58e.png",
    coingeckoId: "coin-capsule",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFeea0bDd3D07eb6FE305938878C0caDBFa169042",
    symbol: "8PAY",
    name: "8PAY Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042/cad33d92fa5197b4dc404b7594f7693a.png",
    coingeckoId: "8pay",
    listedIn: ["coingecko", "1inch", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xFeA292e5ea4510881bDB840E3CeC63aBd43f936f",
    symbol: "COPI",
    name: "Cornucopias [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfea292e5ea4510881bdb840e3cec63abd43f936f/86e51469dcc2b7f92b618f20eb68536e.png",
    coingeckoId: "cornucopias",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c",
    symbol: "DIBS",
    name: "dibs.money",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfd81ef21ea7cf1dc00e9c6dd261b4f3be0341d5c/bcad4992f6ef0f71b598bfb649e0b34e.png",
    coingeckoId: "dibs-money",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFd7B3A77848f1C2D67E05E54d78d174a0C850335",
    symbol: "ONT",
    name: "Ontology Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xFd7B3A77848f1C2D67E05E54d78d174a0C850335/logo.png",
    coingeckoId: "binance-peg-ontology",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xFa4b16b0f63F5A6D0651592620D585D308F749A4",
    symbol: "ASHARE",
    name: "ASHARE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfa4b16b0f63f5a6d0651592620d585d308f749a4/f5cb1c2005b2380c24e7da08d2432390.png",
    coingeckoId: "quartz-defi-ashare",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFa4A5C4ce029FD6872400545df44675219C2e037",
    symbol: "KPHI",
    name: "Kephi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xfa4a5c4ce029fd6872400545df44675219c2e037/logo.png",
    coingeckoId: "kephi-gallery",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4",
    symbol: "WZRD",
    name: "Wizardia Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfa40d8fc324bcdd6bbae0e086de886c571c225d4/70bad95ab9775d07d33f12c1f48792a1.png",
    coingeckoId: "wizardia",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5",
    symbol: "HONEY",
    name: "Honey token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfa363022816abf82f18a9c2809dcd2bb393f6ac5/80ff96433c39fdfcbaad3dbce67c1e98.png",
    coingeckoId: "honeyfarm-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFD9FacA15558eb243aC0012Cf7ACfBf480574668",
    symbol: "MVRS",
    name: "Meta MVRS ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfd9faca15558eb243ac0012cf7acfbf480574668/efc6a9576c9933b04a904babaaa5109b.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xFC3514474306e2D4aa8350fD8FA9c46c165fe8cD",
    symbol: "CNAME",
    name: "Cloudname",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfc3514474306e2d4aa8350fd8fa9c46c165fe8cd/15faa6d2cdbf830fc533219e3e9eb22c.png",
    coingeckoId: "cloudname",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFC206f429d55c71cb7294EfF40c6ADb20dC21508",
    symbol: "DeHub",
    name: "DeHub",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfc206f429d55c71cb7294eff40c6adb20dc21508/b2dbc4c84142788b9ed985b6bd5ae3d1.png",
    coingeckoId: "dehub",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFB981ED9A92377CA4d75D924b9CA06df163924fd",
    symbol: "SA",
    name: "Superalgos",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb981ed9a92377ca4d75d924b9ca06df163924fd/221efe84414460a0371eb4b966167638.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xFB52FC1f90Dd2B070B9Cf7ad68ac3d68905643fa",
    symbol: "SEA",
    name: "Sea Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfb52fc1f90dd2b070b9cf7ad68ac3d68905643fa/b0ff6d9894c1021a05007f95a44c107e.png",
    coingeckoId: "sea",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xFB32b987dd069361E3F54d5c09c9BfbC63FA0cEf",
    symbol: "DINKO",
    name: "Dinko Dating DeFi",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743",
    symbol: "WEYU",
    name: "WEYU",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfafd4cb703b25cb22f43d017e7e0d75febc26743/4150fc02ef9f3aea38c16be53a5d1168.png",
    coingeckoId: "weyu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xFAd8E46123D7b4e77496491769C167FF894d2ACB",
    symbol: "FOX",
    name: "Fox Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xfad8e46123d7b4e77496491769c167ff894d2acb/8f2b1f2171cfd72f6515627f30374ddb.png",
    coingeckoId: "fox-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xFA262F303Aa244f9CC66f312F0755d89C3793192",
    symbol: "RGP",
    name: "RIGEL PROTOCOL",
    decimals: "18",
    chainId: "56",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png",
    coingeckoId: "rigel-protocol",
    listedIn: ["coingecko", "RouterProtocol"],
  },
  {
    address: "0xF9d906a8dD25c4A4966bC075cdc946702219E62C",
    symbol: "YIELD",
    name: "Yield Protocol [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf9d906a8dd25c4a4966bc075cdc946702219e62c/65d1b8465f6a1eeef79cd33a6af8f924.png",
    coingeckoId: "yield-protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6",
    symbol: "EGG",
    name: "Goose Golden Egg",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf952fc3ca7325cc27d15885d37117676d25bfda6/3e22ba5da241379623703e6400bc501c.png",
    coingeckoId: "goose-finance",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0xF93f6b686f4A6557151455189a9173735D668154",
    symbol: "LFG",
    name: "LFG Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf93f6b686f4a6557151455189a9173735d668154/a48494d4a1a3f122deac118229614b5d.png",
    coingeckoId: "gamerse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF915fDDa4c882731C0456a4214548Cd13A822886",
    symbol: "UNV",
    name: "Unvest",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf915fdda4c882731c0456a4214548cd13a822886/4e61ac169c982d78a016ec029b07f68c.png",
    coingeckoId: "unvest",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF8fC63200e181439823251020d691312FDcF5090",
    symbol: "EXZO",
    name: "ExzoCoin 2.0",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf8fc63200e181439823251020d691312fdcf5090/0e69d755c99d450de15ba496a8c83610.png",
    coingeckoId: "exzocoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
    symbol: "WLINK",
    name: "Wrapped ChainLink Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD/logo.png",
    coingeckoId: "chainlink",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xF899E83E6c6dF1a0d4887CF0209193aA97236322",
    symbol: "MBD",
    name: "MBD Financials",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf899e83e6c6df1a0d4887cf0209193aa97236322/fb3dc9afd923e93ddd6d668519239799.png",
    coingeckoId: "mbd-financials",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF7b6d7E3434cB9441982F9534E6998C43eEF144a",
    symbol: "ASVA",
    name: "Asva Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf7b6d7e3434cb9441982f9534e6998c43eef144a/0511b67d24b4e9a5f84a6916d5d75f12.png",
    coingeckoId: "asva",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF7B5fb4607ABfe0Ecf332c23cBdCc9E425B443A8",
    symbol: "ACK",
    name: "AcknoLedger",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf7b5fb4607abfe0ecf332c23cbdcc9e425b443a8/55a25f702b6dc5a61998defa063d7158.png",
    coingeckoId: "acknoledger",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF78D2e7936F5Fe18308A3B2951A93b6c4a41F5e2",
    symbol: "OM",
    name: "MANTRA DAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2/839caee8718be7b5683219d619c7756b.png",
    coingeckoId: "mantra-dao",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xF7844CB890F4C339c497aeAb599aBDc3c874B67A",
    symbol: "NFTART",
    name: "NFTArt.Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf7844cb890f4c339c497aeab599abdc3c874b67a/285c660128890796515fac9344e346a2.png",
    coingeckoId: "nft-art-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D",
    symbol: "ROOBEE",
    name: "ROOBEE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf77351d8f4ee853135961a936bb8d2e4ffa75f9d/0afe2de932cb2c4814bc4299ffbe57c2.png",
    coingeckoId: "roobee",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF750A26EB0aCf95556e8529E72eD530f3b60f348",
    symbol: "GNT",
    name: "GreenTrust",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf750a26eb0acf95556e8529e72ed530f3b60f348/13343c406a3233d51b89774ed18e7f88.png",
    coingeckoId: "greentrust",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xF73d8276C15Ce56b2f4AeE5920e62F767A7f3aEA",
    symbol: "TCG2",
    name: "TCGCoin 2.0",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf73d8276c15ce56b2f4aee5920e62f767a7f3aea/43ac623516e0492ac87f37d3b9becced.png",
    coingeckoId: "tcgcoin-2-0",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF700D4c708C2be1463E355F337603183D20E0808",
    symbol: "GQ",
    name: "Galactic Quadrant",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf700d4c708c2be1463e355f337603183d20e0808/2cf379395d1cd25f36a3b43367ff5fe2.png",
    coingeckoId: "outer-ring",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF68C9Df95a18B2A5a5fa1124d79EEEffBaD0B6Fa",
    symbol: "ANY",
    name: "Anyswap-BEP20",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa/6ce2f5d90629213c1669f76edfd706ec.png",
    coingeckoId: "anyswap",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0xF6565A97Dc832d93DC83B75EE9aa5c7e8ecB0F9d",
    symbol: "HYVE",
    name: "HYVE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf6565a97dc832d93dc83b75ee9aa5c7e8ecb0f9d/76002a1c404bbb165f49e5f3a68b1aac.png",
    coingeckoId: "hyve",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF606bd19b1E61574ED625d9ea96C841D4E247A32",
    symbol: "GUARD",
    name: "Guardian",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf606bd19b1e61574ed625d9ea96c841d4e247a32/c97305f68492e3c18e1bbad4df4e79e4.png",
    coingeckoId: "guardian-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d",
    symbol: "HAPPY",
    name: "HappyFans",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf5d8a096cccb31b9d7bce5afe812be23e3d4690d/2eafefe6e1e0805a7316e614c1552b43.png",
    coingeckoId: "happyfans",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xF4b5470523cCD314C6B9dA041076e7D79E0Df267",
    symbol: "BBANK",
    name: "BlockBank",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf4b5470523ccd314c6b9da041076e7d79e0df267/6bdd40d34e44292db8d84dc6f66b4b1e.png",
    coingeckoId: "blockbank",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF4a9412831Db85A902c2f08BEC36e4B43648440b",
    symbol: "RNK",
    name: "RetroNeko",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1",
    symbol: "FARA",
    name: "FaraCrystal",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1/17d2346c1447823878cc898299e9c672.png",
    coingeckoId: "faraland",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xF35262a9d427F96d2437379eF090db986eaE5d42",
    symbol: "bOPEN",
    name: "OPEN Governance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xF35262a9d427F96d2437379eF090db986eaE5d42/logo.png",
    coingeckoId: "open-governance-token",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0xF2c96E402c9199682d5dED26D3771c6B192c01af",
    symbol: "SCLP",
    name: "Scallop",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf2c96e402c9199682d5ded26d3771c6b192c01af/836328b3eb39f96ec9107c8c17cd51ea.png",
    coingeckoId: "scallop",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF2DA079867785018E6d823DD0FF332f05AAfe485",
    symbol: "VEKTOR",
    name: "VEKTOR",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf2da079867785018e6d823dd0ff332f05aafe485/c890203204af98942da54b1e2b5d20be.png",
    coingeckoId: "vektor",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF218184Af829Cf2b0019F8E6F0b2423498a36983",
    symbol: "MATH",
    name: "Math",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xF218184Af829Cf2b0019F8E6F0b2423498a36983/logo.png",
    coingeckoId: "math",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e",
    symbol: "REEF",
    name: "Reef",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e/logo.png",
    coingeckoId: "reef",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xF215A127A196e3988C09d052e16BcFD365Cd7AA3",
    symbol: "mTSLA",
    name: "Mirror TSLA Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xF20753320F15C990B3Ca8f435705bB13F70357dA",
    symbol: "TINU",
    name: "Tsuyoki Inu",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300",
    symbol: "FLVR",
    name: "FlavorsBSC",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf1a45e52d6d11d0e591d4f18da9d88a4c266b300/aed16fb0601a5d8ae2d4ad66d36aadc9.png",
    coingeckoId: "flavors-bsc",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xF0dcF7aC48F8c745F2920D03dFf83f879b80d438",
    symbol: "GAMI",
    name: "Gami Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf0dcf7ac48f8c745f2920d03dff83f879b80d438/df3d24b759698b0f14f9df5d7bc5ee57.png",
    coingeckoId: "gami",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF0F88d49d3feA2EE07002b9565F47666BA7439EA",
    symbol: "INP",
    name: "INP",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf0f88d49d3fea2ee07002b9565f47666ba7439ea/0ac9b1d5e27ca6c8e93ee436e5ea9901.png",
    coingeckoId: "inpoker",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xF0A8EcBCE8caADB7A07d1FcD0f87Ae1Bd688dF43",
    symbol: "GODZ",
    name: "Cryptogodz",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf0a8ecbce8caadb7a07d1fcd0f87ae1bd688df43/f9723fb2370e80f904da8c246711d690.png",
    coingeckoId: "cryptogodz",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xF0902eB0049A4003793BAb33F3566A22D2834442",
    symbol: "GLCH",
    name: "Glitch",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xf0902eb0049a4003793bab33f3566a22d2834442/f4ba4174614971f177c220d931b0bf68.png",
    coingeckoId: "glitch-protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xEe9801669C6138E84bD50dEB500827b776777d28",
    symbol: "O3",
    name: "O3 Swap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xEe9801669C6138E84bD50dEB500827b776777d28/logo.png",
    coingeckoId: "o3-swap",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405",
    symbol: "PROS",
    name: "Prosper",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xed8c8aa8299c10f067496bb66f8cc7fb338a3405/7bc739c1d729a13444069cf0232d1592.png",
    coingeckoId: "prosper",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xEE738a9e5FB78c24D26ceCD30389ED977C38D0Ca",
    symbol: "FSAFE",
    name: "Fair Safe",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xee738a9e5fb78c24d26cecd30389ed977c38d0ca.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xEDE5020492Be8E265dB6141CB0a1D2dF9dBAE9BB",
    symbol: "DOUGH",
    name: "Dough",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xede5020492be8e265db6141cb0a1d2df9dbae9bb/0bb428db11568971e6104ba467297a75.png",
    coingeckoId: "dough",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xECa41281c24451168a37211F0bc2b8645AF45092",
    symbol: "TPT",
    name: "TokenPocket",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xECa41281c24451168a37211F0bc2b8645AF45092/logo.png",
    coingeckoId: "token-pocket",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xEC143f87E8C2166F0405158677b84A10C045479a",
    symbol: "PAL",
    name: "Palmare",
    decimals: 18,
    chainId: "56",
    logoURI: "https://palmare.io/other/logo-palmare-200w.png",
    coingeckoId: "palmare",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xEA5E9F39F4B4Bc2684e49A0E43127F7f6F6be3b8",
    symbol: "MINU",
    name: "MINU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/25620/small/lY8xHwF.jpg?1652857003",
    coingeckoId: "muu-inu",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0xE9db02A654b74ca04734B26ef3B2a79808d43404",
    symbol: "OKBOOMER",
    name: "OKBoomer",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe9db02a654b74ca04734b26ef3b2a79808d43404/93e517787055f62b09b888e972b9f663.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xE9D7023f2132D55cbd4Ee1f78273CB7a3e74F10A",
    symbol: "DEC",
    name: "DarkEnergyCrystals",
    decimals: 3,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a/ce6ed8d447ced9fe287f847c4ace82b3.png",
    coingeckoId: "dark-energy-crystals",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE9C803F48dFFE50180Bd5B01dC04DA939E3445Fc",
    symbol: "VLX",
    name: "Velas",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe9c803f48dffe50180bd5b01dc04da939e3445fc/092b3a6751fab370133aa2478bb2251c.png",
    coingeckoId: "velas",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A",
    symbol: "aBNBc",
    name: "Ankr BNB Reward Bearing Certificate",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A.png",
    coingeckoId: "ankr-reward-bearing-stake",
    listedIn: ["coingecko", "1inch", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xE8176d414560cFE1Bf82Fd73B986823B89E4F545",
    symbol: "HERO",
    name: "StepHero",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe8176d414560cfe1bf82fd73b986823b89e4f545/22e43e4c46584225f1816fba383c9b95.png",
    coingeckoId: "step-hero",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xE7Cb24F449973D5B3520E5b93D88B405903c75Fb",
    symbol: "BNBTC",
    name: "BNbitcoin Token - minable bitcoin on BSC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xe7cb24f449973d5b3520e5b93d88b405903c75fb.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xE6c78F31e481b144df5e6e35dF8Be83F58769670",
    symbol: "CHEE",
    name: "CHEEBS",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe6c78f31e481b144df5e6e35df8be83f58769670/31d2e791290b518fc070545d1832cab2.png",
    coingeckoId: "cheebs",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE69cAef10A488D7AF31Da46c89154d025546e990",
    symbol: "DPT",
    name: "Diviner Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe69caef10a488d7af31da46c89154d025546e990/ef6d949de607dad8fc91ae4627349848.png",
    coingeckoId: "diviner-protocol",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xE6906717f129427eEbADe5406De68CADD57AA0c0",
    symbol: "MIT",
    name: "Meta Interstellar Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe6906717f129427eebade5406de68cadd57aa0c0/df18c6b3757b37b01326924c22440682.png",
    coingeckoId: "galaxy-blitz",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe",
    symbol: "bROOBEE",
    name: "ROOBEE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xE5bbB701302322acb3504d4A260eec8Dc4a36263",
    symbol: "EUROS",
    name: "Spice EUR",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "spiceeuro",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE5bA47fD94CB645ba4119222e34fB33F59C7CD90",
    symbol: "SAFUU",
    name: "Safuu",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe5ba47fd94cb645ba4119222e34fb33f59c7cd90/d27743ee9be266ddb6fcb4a8307aaaab.png",
    coingeckoId: "safuu",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xE4c797d43631F4d660EC67B5CB0B78Ef5c902532",
    symbol: "MONS",
    name: "Monsters Clan Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xe4c797d43631f4d660ec67b5cb0b78ef5c902532/logo.png",
    coingeckoId: "monsters-clan",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xE4FAE3Faa8300810C835970b9187c268f55D998F",
    symbol: "CATE",
    name: "CateCoin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe4fae3faa8300810c835970b9187c268f55d998f/a947fe229cabeb840afdbfc020d35605.png",
    coingeckoId: "catecoin",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xE4Ae305ebE1AbE663f261Bc00534067C80ad677C",
    symbol: "SPARTA",
    name: "SPARTAN PROTOCOL TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE4Ae305ebE1AbE663f261Bc00534067C80ad677C/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0xE40255C5d7fa7ceEc5120408C78C787CECB4cfdb",
    symbol: "SWGb",
    name: "SWGb",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb/370539e708faf7a6b489bcb137189eaf.png",
    coingeckoId: "swirge",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xE336a772532650BC82828e9620Dd0d5a3b78bFE8",
    symbol: "DGMV",
    name: "DigiMetaverse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe336a772532650bc82828e9620dd0d5a3b78bfe8/cba9d63eca21d1066713273c7f06c076.png",
    coingeckoId: "digimetaverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE3233fdb23F1c27aB37Bd66A19a1f1762fCf5f3F",
    symbol: "ELMON",
    name: "Elemon Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe3233fdb23f1c27ab37bd66a19a1f1762fcf5f3f/c52ef75bc4ca8889a3cf5b00549a7d2a.png",
    coingeckoId: "elemon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE2e7329499E8DDb1f2b04EE4B35a8d7f6881e4ea",
    symbol: "$ANRX",
    name: "pTokens $ANRX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe2e7329499e8ddb1f2b04ee4b35a8d7f6881e4ea/c2f2b0daff01ac4d3aadf2499e685b9f.png",
    coingeckoId: "anrkey-x",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xE283D0e3B8c102BAdF5E8166B73E02D96d92F688",
    symbol: "ELEPHANT",
    name: "Elephant Money",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe283d0e3b8c102badf5e8166b73e02d96d92f688/38f979547cdb4602dd9ffec4938fe1fa.png",
    coingeckoId: "elephant-money",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE1d1F66215998786110Ba0102ef558b22224C016",
    symbol: "HOO",
    name: "Hoo",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE1d1F66215998786110Ba0102ef558b22224C016/logo.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C",
    symbol: "oELK",
    name: "Old Elk",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png",
    coingeckoId: "elk-finance",
    listedIn: ["coingecko", "lifinance", "elkfinance"],
  },
  {
    address: "0xE17fbDf671F3cCE0F354cAcBd27e03f4245A3fFe",
    symbol: "RIFI",
    name: "Rikkei Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xe17fbdf671f3cce0f354cacbd27e03f4245a3ffe/58e6f96dd0603ef0226a8d852a84c3a6.png",
    coingeckoId: "rikkei-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f",
    symbol: "BELT",
    name: "Belt",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f/logo.png",
    coingeckoId: "belt",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
    symbol: "BAKE",
    name: "Bakery Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5/logo.png",
    coingeckoId: "bakerytoken",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xDdC0dBd7dC799ae53A98a60b54999cb6eBb3Abf0",
    symbol: "BLAST",
    name: "SafeBLAST",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xddc0dbd7dc799ae53a98a60b54999cb6ebb3abf0/9aa0f0866a2bd417953f7f29500ec1c1.png",
    coingeckoId: "safeblast",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDc0f0a5719c39764b011eDd02811BD228296887C",
    symbol: "DOS",
    name: "DOS Network Token BEP20",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x0a913bead80f321e7ac35285ee10d9d922659cb7.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xDa5bE69074AFD12354173b4350ec9117E73e92e2",
    symbol: "AQT",
    name: "AQT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0xda5be69074afd12354173b4350ec9117e73e92e2.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xDFcF44e9a6D99717FC04AddD57Fb667286Bb7DC0",
    symbol: "INCOME",
    name: "Income",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdfcf44e9a6d99717fc04addd57fb667286bb7dc0/615a9c3c1c8f0156a77d85b76a4add41.png",
    coingeckoId: "income",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xDFAABAa57dEc10c049335bdAA2e949B4Ce2eAD30",
    symbol: "CABO",
    name: "CatBonk",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdfaabaa57dec10c049335bdaa2e949b4ce2ead30/5becc3d5651dff285b1aa064ba973fce.png",
    coingeckoId: "catbonk",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xDF757108102B4B1eFeC1FB71F4C8a11D2F291Ecb",
    symbol: "Tdg",
    name: "Teddy dog",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdf757108102b4b1efec1fb71f4c8a11d2f291ecb/fe795351828b919c138b32158d133fdb.png",
    coingeckoId: "teddy-dog",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26",
    symbol: "HVI",
    name: "Hungarian Vizsla Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xde619a9e0eeeaa9f8cd39522ed788234837f3b26/5c124a3278d89f32fd8a3913b2ba6db8.png",
    coingeckoId: "hungarian-vizsla-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDCEcf0664C33321CECA2effcE701E710A2D28A3F",
    symbol: "AUSD",
    name: "Alpaca USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdcecf0664c33321ceca2effce701e710a2d28a3f/5d23d896e914251939b15a7fa44ef95b.png",
    coingeckoId: "alpaca-usd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDBdC73B95cC0D5e7E99dC95523045Fc8d075Fb9e",
    symbol: "DOGS",
    name: "DOGS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdbdc73b95cc0d5e7e99dc95523045fc8d075fb9e/50bc0a2b3215384dafd59cb2b59495f2.png",
    coingeckoId: "dogs",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDB20F6A8665432CE895D724b417f77EcAC956550",
    symbol: "ESHARE",
    name: "ESHARE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdb20f6a8665432ce895d724b417f77ecac956550/5a7715a4c963e0866ec91b917623e185.png",
    coingeckoId: "emp-shares",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F",
    symbol: "AMPL",
    name: "Ampleforth",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F/logo.png",
    coingeckoId: "ampleforth",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xDAe6c2A48BFAA66b43815c5548b10800919c993E",
    symbol: "KTN",
    name: "Kattana",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xDAe6c2A48BFAA66b43815c5548b10800919c993E/logo.png",
    coingeckoId: "kattana",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63",
    symbol: "PLG",
    name: "Plunge",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xdad3abce6fb87fa0355203b692723a7ee8aa9d63/c837a4ecb600904e4ab3a5a8c0f41ec3.png",
    coingeckoId: "plunge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54",
    symbol: "HAPI",
    name: "HAPI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54/9fb1e194fdd7fedb74e788f69fcb90fa.png",
    coingeckoId: "hapi",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xD98560689C6e748DC37bc410B4d3096B1aA3D8C2",
    symbol: "DFY",
    name: "DeFi For You.",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd98560689c6e748dc37bc410b4d3096b1aa3d8c2/afad71888cfb0cd2ee3c7dd7f99f2b4a.png",
    coingeckoId: "defi-for-you",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xD883D21AF976Ec9fA409c9f2944A1E7D03D21946",
    symbol: "bCHX",
    name: "Binance-Peg CHX Token",
    decimals: 7,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd883d21af976ec9fa409c9f2944a1e7d03d21946/6ccfe97d1733dce75a5d0606be9f4444.png",
    coingeckoId: "chainium",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xD8047AFECB86e44eFf3aDd991B9F063eD4ca716B",
    symbol: "GGG",
    name: "Good Games Guild",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd8047afecb86e44eff3add991b9f063ed4ca716b/9524a7e0a20dc0b23272f69cf7fe9464.png",
    coingeckoId: "good-games-guild",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61",
    symbol: "DLAUNCH",
    name: "DefiLaunch Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd7d23d89c510f306e4037385ebbefc6dd6c41e61/099251bf564f0dbaf932c693df863a14.png",
    coingeckoId: "defi-launch",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xD7B729ef857Aa773f47D37088A1181bB3fbF0099",
    symbol: "BOG",
    name: "Bogged Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd7b729ef857aa773f47d37088a1181bb3fbf0099/bac1d4ff74e38cdca42fdcf3b79cc1bb.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xD7730681B1DC8f6F969166B29D8A5EA8568616a3",
    symbol: "NAFT",
    name: "Nafter",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd7730681b1dc8f6f969166b29d8a5ea8568616a3/9103682bf54fb9175f926361557ad62e.png",
    coingeckoId: "nafter",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xD74b782E05AA25c50e7330Af541d46E18f36661C",
    symbol: "QUACK",
    name: "RichQUACK.com",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd74b782e05aa25c50e7330af541d46e18f36661c/5dfc395cfa4970e2dd5e945df5ec36da.png",
    coingeckoId: "richquack",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xD52eFE1039D706722b3D696e74AD4095DC3d3860",
    symbol: "PWR",
    name: "Power",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd52efe1039d706722b3d696e74ad4095dc3d3860/74d06901fcbbc0057750aaa0435df507.png",
    coingeckoId: "crazyminer",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xD48474E7444727bF500a32D5AbE01943f3A59A64",
    symbol: "BBT",
    name: "BitBook",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd48474e7444727bf500a32d5abe01943f3a59a64/b22264e0691807e4ee79f157bd4b8a79.png",
    coingeckoId: "bitbook-token",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xD44FD09d74cd13838F137B590497595d6b3FEeA4",
    symbol: "ETERNAL",
    name: "CryptoMines Eternal",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd44fd09d74cd13838f137b590497595d6b3feea4/712313c1f38b107b92452ee7904ed02c.png",
    coingeckoId: "cryptomines-eternal",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb",
    symbol: "SFP",
    name: "SafePal Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb/logo.png",
    coingeckoId: "safepal",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13",
    symbol: "HERO",
    name: "MetaHero",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13/logo.png",
    coingeckoId: "metahero",
    listedIn: [
      "coingecko",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    symbol: "1ART",
    name: "ArtWallet ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd3c325848d7c6e29b574cb0789998b2ff901f17e/136963cf3a4bf10b92efe37bfb99ab8e.png",
    coingeckoId: "1art",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xD3b71117E6C1558c1553305b44988cd944e97300",
    symbol: "YEL",
    name: "YEL Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd3b71117e6c1558c1553305b44988cd944e97300/bdd34d4d180d012589ecde14100031c5.png",
    coingeckoId: "yel-finance",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa",
    symbol: "KNIGHT",
    name: "KnightSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1660734511740_5937332759492198.jpg",
    coingeckoId: "knightswap",
    listedIn: ["coingecko", "openocean"],
  },
  {
    address: "0xD22202d23fE7dE9E3DbE11a2a88F42f4CB9507cf",
    symbol: "MNEB",
    name: "Minereum BSC",
    decimals: 8,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xD1102332a213E21faF78B69C03572031F3552c33",
    symbol: "BTD",
    name: "Bolt Dollar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd1102332a213e21faf78b69c03572031f3552c33/b5e5e45fa294d347bf0df50856446453.png",
    coingeckoId: "bolt-true-dollar",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0xD0dff49De3E314FDFD3f93c5EEee7D5d2F5515cD",
    symbol: "ZBTC",
    name: "ZBTC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd0dff49de3e314fdfd3f93c5eeee7d5d2f5515cd/fed088e2c415595bf29cf57eb28cf136.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xD024Ac1195762F6F13f8CfDF3cdd2c97b33B248b",
    symbol: "MINIFOOTBALL",
    name: "MiniFootball",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xd024ac1195762f6f13f8cfdf3cdd2c97b33b248b/2045d6ab2773929de1ae9b065d92b3ff.png",
    coingeckoId: "minifootball",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xCed0CE92F4bdC3c2201E255FAF12f05cf8206dA8",
    symbol: "ORK",
    name: "Orakuru",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xced0ce92f4bdc3c2201e255faf12f05cf8206da8/logo.png",
    coingeckoId: "orakuru",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xCe5814eFfF15D53EFd8025B9F2006D4d7D640b9B",
    symbol: "MOONSTAR",
    name: "MoonStar",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xce5814efff15d53efd8025b9f2006d4d7d640b9b/eae467ed28326d63040875107b750dee.png",
    coingeckoId: null,
    listedIn: ["1inch", "rubic", "lifinance"],
  },
  {
    address: "0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe",
    symbol: "XTM",
    name: "Torum",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcd1faff6e578fa5cac469d2418c95671ba1a62fe/a6cda017df3d95fef0113fbe42985e51.png",
    coingeckoId: "torum",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xCcbf1C9E8b4f2cDF3Bfba1098b8f56f97d219D53",
    symbol: "ccCLO",
    name: "Callisto Network Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xccbf1c9e8b4f2cdf3bfba1098b8f56f97d219d53/cd265fa8e01bd691a16072286c0f6854.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xCb73918ac58D0c90d71c7992637c61094c15305b",
    symbol: "SFT",
    name: "Scorefam",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcb73918ac58d0c90d71c7992637c61094c15305b/0e2e8010a50cefe2e2709323aaa59ed5.png",
    coingeckoId: "scorefam",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xCa3F508B8e4Dd382eE878A314789373D80A5190A",
    symbol: "BIFI",
    name: "Beefy.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/logo.png",
    coingeckoId: "beefy-finance",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xCF87d3d50A98A7832f5CfdF99aE1B88C7cFbA4a7",
    symbol: "BIOS",
    name: "BIOS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xcf87d3d50a98a7832f5cfdf99ae1b88c7cfba4a7/0a59282c4fd879af91bc29a6979931af.png",
    coingeckoId: "bios",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xCD40F2670CF58720b694968698A5514e924F742d",
    symbol: "ODDZ",
    name: "Oddz",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xCD40F2670CF58720b694968698A5514e924F742d/logo.png",
    coingeckoId: "oddz",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    symbol: "MATIC",
    name: "MATIC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    coingeckoId: "matic-network",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08",
    symbol: "SISTA",
    name: "srnArtGallery Tokenized Arts",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca6d25c10dad43ae8be0bc2af4d3cd1114583c08/39885d985c070c7b1a3e59a315a0b87f.png",
    coingeckoId: "srnartgallery-tokenized-arts",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C",
    symbol: "ANTEX",
    name: "Antex",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca1acab14e85f30996ac83c64ff93ded7586977c/86e2ca228f088630969c1c6c6873990a.png",
    coingeckoId: "antex",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xCA0a9Df6a8cAD800046C1DDc5755810718b65C44",
    symbol: "TCT",
    name: "Token Club",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xca0a9df6a8cad800046c1ddc5755810718b65c44/bbb2859a1d01febf72b2d2cddf39ba98.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xC9882dEF23bc42D53895b8361D0b1EDC7570Bc6A",
    symbol: "FIST",
    name: "FistToken",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc9882def23bc42d53895b8361d0b1edc7570bc6a/4ba5858de82a6564b4b73320c9fc2f1c.png",
    coingeckoId: "fistbump",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51",
    symbol: "BUNNY",
    name: "Bunny Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51/logo.png",
    coingeckoId: "pancake-bunny",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xC91B4AA7e5C247CB506e112E7FEDF6af7077b90A",
    symbol: "DELO",
    name: "Decentra-Lotto",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc91b4aa7e5c247cb506e112e7fedf6af7077b90a/1b5ff34f76f2391c702c2f32876ccb72.png",
    coingeckoId: "decentra-lotto",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731",
    symbol: "HGET",
    name: "Hedget",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731/logo.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xC762043E211571eB34f1ef377e5e8e76914962f9",
    symbol: "APE",
    name: "ApeCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc762043e211571eb34f1ef377e5e8e76914962f9/97b912e4874df863f530cedb07bfbf1c.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0xC6d736123fa8e8a5D85803b5C22799e394245faB",
    symbol: "RYIU",
    name: "RYI Unity",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc6d736123fa8e8a5d85803b5c22799e394245fab/4f0c416c98b8219ba6ed5cc992909297.png",
    coingeckoId: "ryi-unity",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xC6759a4Fc56B3ce9734035a56B36e8637c45b77E",
    symbol: "Grimace",
    name: "GrimaceCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc6759a4fc56b3ce9734035a56b36e8637c45b77e/07d16a35853aaddd6a53b8647dea2b47.png",
    coingeckoId: "grimace-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC64c9B30C981fc2eE4e13d0CA3f08258e725fd24",
    symbol: "POLAR",
    name: "Polar Sync",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc64c9b30c981fc2ee4e13d0ca3f08258e725fd24/e211c503430383192ad7353e4056de9c.png",
    coingeckoId: "polar-sync",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC632F90affeC7121120275610BF17Df9963F181c",
    symbol: "DEBT",
    name: "DEBT",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc632f90affec7121120275610bf17df9963f181c/10539c887eb0ff16cce68c7364f56836.png",
    coingeckoId: "the-debt-box",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC62eF0d8e137499833AbB05Dee47007D2b334bA6",
    symbol: "GMX",
    name: "GAMEX",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc62ef0d8e137499833abb05dee47007d2b334ba6/557e4617bee106012b6ec9de2aa6c288.png",
    coingeckoId: "gamex",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC54A4640907044283e8E4885090E205992B9813e",
    symbol: "FORS",
    name: "FORUS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc54a4640907044283e8e4885090e205992b9813e/e95dfe9c2977dcd79c93973c528816a7.png",
    coingeckoId: "forus",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xC5326b32E8BaEF125AcD68f8bC646fD646104F1c",
    symbol: "ZAP",
    name: "ZAP TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc5326b32e8baef125acd68f8bc646fd646104f1c/ff98c754ae3fbb8c3024711fa54187a9.png",
    coingeckoId: "zap",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC40C9A843E1c6D01b7578284a9028854f6683b1B",
    symbol: "JUV",
    name: "Juventus",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC40C9A843E1c6D01b7578284a9028854f6683b1B/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xC3387E4285e9F80A7cFDf02B4ac6cdF2476A528A",
    symbol: "ROCK",
    name: "Bedrock",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a/648b29e9f24d6f57fbde6e44471171fb.png",
    coingeckoId: "bedrock",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xC3028FbC1742a16A5D69dE1B334cbce28f5d7EB3",
    symbol: "SSS",
    name: "SSS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/21667/small/FLjJKQd0_400x400.jpg?1639705037",
    coingeckoId: "starsharks",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0xC25AF3123d2420054c8fcd144c21113aa2853F39",
    symbol: "XGT",
    name: "Xion Global Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC25AF3123d2420054c8fcd144c21113aa2853F39/logo.png",
    coingeckoId: "xion-finance",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0xC19FE21B4ef356f2f65894392dDe4252AA083605",
    symbol: "WSO",
    name: "Widi Soul",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc19fe21b4ef356f2f65894392dde4252aa083605/8480cee9bbe452a042db2274b3b5dc34.png",
    coingeckoId: "widi-soul",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC17c30e98541188614dF99239cABD40280810cA3",
    symbol: "RISE",
    name: "EverRise",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc17c30e98541188614df99239cabd40280810ca3/38aa3e0824e40514a68317f4ccbc1adf.png",
    coingeckoId: "everrise",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75",
    symbol: "JGN",
    name: "Juggernaut Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75/logo.png",
    coingeckoId: "juggernaut",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xC1165227519FfD22Fdc77Ceb1037b9b284eeF068",
    symbol: "BNSD",
    name: "bns.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc1165227519ffd22fdc77ceb1037b9b284eef068/ffca35a970df1833a4e64cc6fc96d11d.png",
    coingeckoId: "bnsd-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xC0eFf7749b125444953ef89682201Fb8c6A917CD",
    symbol: "HZN",
    name: "Horizon Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xC0eFf7749b125444953ef89682201Fb8c6A917CD/logo.png",
    coingeckoId: "horizon-protocol",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468",
    symbol: "SWTH",
    name: "Carbon Protocol",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc0ecb8499d8da2771abcbf4091db7f65158f1468/5846a64b6f88900c3db093f20e54d391.png",
    coingeckoId: "switcheo",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xC0Cc1e5761bA5786916FD055562551798E50d573",
    symbol: "ASY",
    name: "ASYAGRO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xc0cc1e5761ba5786916fd055562551798e50d573/4976a7808d124b170f632823c2a011eb.png",
    coingeckoId: "asyagro",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xC001BBe2B87079294C63EcE98BdD0a88D761434e",
    symbol: "EGC",
    name: "",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xc001bbe2b87079294c63ece98bdd0a88d761434e/logo.png",
    coingeckoId: "evergrowcoin",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1",
    symbol: "UNI",
    name: "Uniswap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1/logo.png",
    coingeckoId: "uniswap",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28",
    symbol: "BETA",
    name: "Beta Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbe1a001fe942f96eea22ba08783140b9dcc09d28/c4fdd3cd408bd0bde76e16bef128f274.png",
    coingeckoId: "beta-finance",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F",
    symbol: "MTRG",
    name: "Meter",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F/logo.png",
    coingeckoId: "meter",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xBc7d6B50616989655AfD682fb42743507003056D",
    symbol: "ACH",
    name: "Alchemy Token",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbc7d6b50616989655afd682fb42743507003056d/be74b438e5f87a170363d65deaf6c3ca.png",
    coingeckoId: "alchemy-pay",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1",
    symbol: "PSG",
    name: "Paris Saint-Germain",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811",
    symbol: "UBXT",
    name: "UpBots",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811/logo.png",
    coingeckoId: "upbots",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xBb46693eBbEa1aC2070E59B4D043b47e2e095f86",
    symbol: "BFG",
    name: "BFG Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbb46693ebbea1ac2070e59b4d043b47e2e095f86/cf4f70db785c8cd2f1083daa786fad29.png",
    coingeckoId: "bfg-token",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0xBb238FcE6E2eE90781cD160C9C6eAf3a4CfAD801",
    symbol: "BAGEL",
    name: "BAGEL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbb238fce6e2ee90781cd160c9c6eaf3a4cfad801/8021560b9ec864cdb7089ee8623625a2.png",
    coingeckoId: "bagel",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xBb1Aa6e59E5163D8722a122cd66EBA614b59df0d",
    symbol: "aBNBb",
    name: "Ankr BNB Reward Earning Bond",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbb1aa6e59e5163d8722a122cd66eba614b59df0d/5acf19e660e9a0c1fdebcf55ab81f1d7.png",
    coingeckoId: "ankr-reward-earning-bnb",
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0xBaC1df744df160877cDc45E13d0394c06bC388Ff",
    symbol: "GEM",
    name: "NFTmall GEM Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbac1df744df160877cdc45e13d0394c06bc388ff/af1a1c8a6af5b1c8ffd130d8134a848d.png",
    coingeckoId: "nftmall",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4",
    symbol: "MM",
    name: "Million",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbf05279f9bf1ce69bbfed670813b7e431142afa4/52cb0f908d9449c8b2fda33887c9e7f7.png",
    coingeckoId: "million",
    listedIn: ["coingecko", "sushiswap", "lifinance"],
  },
  {
    address: "0xBBCA42c60b5290F2c48871A596492F93fF0Ddc82",
    symbol: "DOMI",
    name: "Domi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xbbca42c60b5290f2c48871a596492f93ff0ddc82/11fa1b215ffa3c0f653fb9534a63ee77.png",
    coingeckoId: "domi",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xB9d35811424600fa9E8cD62A0471fBd025131cb8",
    symbol: "YES",
    name: "YES WORLD",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "yes-world",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB9844A9Cb6aBD9F86bb0B3aD159e37EeccE08987",
    symbol: "DEXF",
    name: "Dexfolio",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb9844a9cb6abd9f86bb0b3ad159e37eecce08987/d7d42ec94e2791f3ec1a8b4f9860211f.png",
    coingeckoId: "dexfolio",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xB955B4cAb9Aa3B49E23AEB5204EBc5fF6678E86D",
    symbol: "Afin",
    name: "Asian Fintech",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb955b4cab9aa3b49e23aeb5204ebc5ff6678e86d/2a412ee8cde9b46d594e0c4d5b423a79.png",
    coingeckoId: "afin-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB72bA371c900aa68bb9Fa473e93CfbE212030fCb",
    symbol: "PSR",
    name: "Pandora Spirit",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb72ba371c900aa68bb9fa473e93cfbe212030fcb/46ccd067e4a3788d5ed9ed1f1918d791.png",
    coingeckoId: "pandora-spirit",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB72842D6F5feDf91D22d56202802Bb9A79C6322E",
    symbol: "MOMA",
    name: "MOchi MArket",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb72842d6f5fedf91d22d56202802bb9a79c6322e/ba1dc310e653c1f8b79154c6a221c753.png",
    coingeckoId: "mochi-market",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB67754f5b4C704A24d2db68e661b2875a4dDD197",
    symbol: "MIX",
    name: "Mix",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xB67754f5b4C704A24d2db68e661b2875a4dDD197/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xB64fde8f199F073F41c132B9eC7aD5b61De0B1B7",
    symbol: "PRV",
    name: "Incognito",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb64fde8f199f073f41c132b9ec7ad5b61de0b1b7/180ca3084d2f963dfcde0d2c980afd8b.png",
    coingeckoId: "incognito-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21",
    symbol: "WAULTx",
    name: "Wault",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xB5102CeE1528Ce2C760893034A4603663495fD72",
    symbol: "USX",
    name: "dForce USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb5102cee1528ce2c760893034a4603663495fd72/09298f687c07bdcfab0137f654cd17e9.png",
    coingeckoId: "token-dforce-usd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB44c63a09adF51f5E62CC7B63628b1B789941FA0",
    symbol: "RFX",
    name: "Reflex",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xb44c63a09adf51f5e62cc7b63628b1b789941fa0/logo.png",
    coingeckoId: "reflex",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xB2ebbFF04Bdcb3DED0Cb99C544521A17e940FF96",
    symbol: "XIX",
    name: "Million Pixel",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: "million-pixel",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C",
    symbol: "DUSK",
    name: "Dusk",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xB2AA453B56b495cb95BC9fa72E7706B84e41EE54",
    symbol: "WND",
    name: "WND",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xb2aa453b56b495cb95bc9fa72e7706b84e41ee54/logo.png",
    coingeckoId: "wonderhero",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xB248a295732e0225acd3337607cc01068e3b9c10",
    symbol: "vXRP",
    name: "Venus XRP",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb248a295732e0225acd3337607cc01068e3b9c10/a43d22b5fa791d3cc2efec321a7e8579.png",
    coingeckoId: "venus-xrp",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xB1CeD2e320E3f4C8e3511B1DC59203303493F382",
    symbol: "MOONLIGHT",
    name: "Moonlight Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb1ced2e320e3f4c8e3511b1dc59203303493f382/984379303a520781cd0065a24ce355fc.png",
    coingeckoId: "moonlight-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xB149B030CFA47880aF0BDE4Cd36539E4C928b3eB",
    symbol: "NUTGV2",
    name: "NUTGAIN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb149b030cfa47880af0bde4cd36539e4c928b3eb/c51c70a3aa1bb11b4199254b5ecc2763.png",
    coingeckoId: "nutgain",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xB0e1fc65C1a741b4662B813eB787d369b8614Af1",
    symbol: "IF",
    name: "Impossible Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xB0e1fc65C1a741b4662B813eB787d369b8614Af1/logo.png",
    coingeckoId: "impossible-finance",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xB0F2939A1c0e43683E5954c9Fe142F7df9F8D967",
    symbol: "GEN",
    name: "Gen Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xb0f2939a1c0e43683e5954c9fe142f7df9f8d967/122391f49192ddb6b868cddbc8abe7bb.png",
    coingeckoId: "evolution",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    symbol: "STG",
    name: "STG",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/24413/small/STG_LOGO.png?1647654518",
    coingeckoId: "stargate-finance",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0xAe98E63dB1c4646BF5b40B29c664Bc922f71Bc65",
    symbol: "EFT",
    name: "Energyfi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xae98e63db1c4646bf5b40b29c664bc922f71bc65/aa3340aaacd0cb0a9ea40e22d1f05565.png",
    coingeckoId: "energyfi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f",
    symbol: "BURGER",
    name: "Burger Swap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f/logo.png",
    coingeckoId: "burger-swap",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xAe2DF9F730c54400934c06a17462c41C08a06ED8",
    symbol: "DOBO",
    name: "DogeBonk.com",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xae2df9f730c54400934c06a17462c41c08a06ed8/b5bf5d0841260287ec01916447ea2e19.png",
    coingeckoId: "dogebonk",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0",
    symbol: "ELE",
    name: "Eleven.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0/367f6b44426958bd5a1944855e21e151.png",
    coingeckoId: "eleven-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xAcABD3f9b8F76fFd2724604185Fa5AFA5dF25aC6",
    symbol: "MSS",
    name: "Monster Slayer Share",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6/17c0a1ecffe68c2ee32a58c7a6e027dc.png",
    coingeckoId: "monster-cash-share",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xAa731Eb48f701C132D5FA69B69f5eb4064d9BE34",
    symbol: "BATS",
    name: "Batcoin",
    decimals: 0,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xAF44400A99a9693bF3c2e89b02652bABACc5cdb9",
    symbol: "MAFA",
    name: "MafaCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaf44400a99a9693bf3c2e89b02652babacc5cdb9/ecad679f4a75b41c168d7133575dc49e.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xAEf0a177c8c329CBC8508292Bb7E06c00786BBfC",
    symbol: "PULI",
    name: "PULI INU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaef0a177c8c329cbc8508292bb7e06c00786bbfc/b8930d8990fe2d6b82cce28fda20d887.png",
    coingeckoId: "puli-inu",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xAECf6d1afF214feF70042740054f0f6D0Caa98Ab",
    symbol: "BabyShibaInu",
    name: "Baby Shiba Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaecf6d1aff214fef70042740054f0f6d0caa98ab/c2b3382556699711c2b52e15d6638c77.png",
    coingeckoId: "baby-shiba-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xAE1107fC7CeF1294F09185aC475c9886527DcD8a",
    symbol: "ADAL",
    name: "ADAL Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xae1107fc7cef1294f09185ac475c9886527dcd8a/797d13fae0c1f5c5623cc7e0e9c6927b.png",
    coingeckoId: "adalend",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xADCa52302e0a6c2d5D68EDCdB4Ac75DeB5466884",
    symbol: "GMR",
    name: "GAMER",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xadca52302e0a6c2d5d68edcdb4ac75deb5466884/3fe6f86aad94f9f41ff387a071aaeba2.png",
    coingeckoId: "gamer",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C",
    symbol: "TPAD",
    name: "TrustPad",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xadcfc6bf853a0a8ad7f9ff4244140d10cf01363c/logo.png",
    coingeckoId: "trustpad",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xAD90c05BC51672eEdfeE36E58b3ff1A78bbC146d",
    symbol: "XSPACE",
    name: "XSPACE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xad90c05bc51672eedfee36e58b3ff1a78bbc146d.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xAD86d0E9764ba90DDD68747D64BFfBd79879a238",
    symbol: "PAID",
    name: "PAID Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xad86d0e9764ba90ddd68747d64bffbd79879a238/5445bca1ac21b8332c456a506136509b.png",
    coingeckoId: "paid-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18",
    symbol: "BAND",
    name: "BAND Protocol Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xAD29AbB318791D579433D831ed122aFeAf29dcfe",
    symbol: "FTM",
    name: "FTM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016",
    coingeckoId: "fantom",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1",
    symbol: "BP",
    name: "BunnyPark",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xacb8f52dc63bb752a51186d1c55868adbffee9c1/f954cb0ff2abc800945830544039a46a.png",
    coingeckoId: "bunnypark",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0xAC83271abB4ec95386f08aD2b904a46C61777cef",
    symbol: "NFTD",
    name: "NFTrade Token [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xac83271abb4ec95386f08ad2b904a46c61777cef/bdaa4ebaf93c1f2e73db6486aa2526ce.png",
    coingeckoId: "nftrade",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
    symbol: "ALICE",
    name: "My Neigbor Alice",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xAC51066d7bEC65Dc4589368da368b212745d63E8/logo.png",
    coingeckoId: "my-neighbor-alice",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xAB15B79880f11cFfb58dB25eC2bc39d28c4d80d2",
    symbol: "SMON",
    name: "StarMon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xab15b79880f11cffb58db25ec2bc39d28c4d80d2/a138119887867149382ef1a71a099437.png",
    coingeckoId: "starmon-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xAAA7A10a8ee237ea61E8AC46C50A8Db8bCC1baaa",
    symbol: "QANX",
    name: "QANX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xaaa7a10a8ee237ea61e8ac46c50a8db8bcc1baaa/46f3c2fdae479c4a81ef0a2f6439a96b.png",
    coingeckoId: "qanplatform",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xA9b038285F43cD6fE9E16B4C80B4B9bCcd3C161b",
    symbol: "AI",
    name: "Flourishing AI Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xa9b038285f43cd6fe9e16b4c80b4b9bccd3c161b/logo.png",
    coingeckoId: "flourishing-ai-token",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xA9776B590bfc2f956711b3419910A5Ec1F63153E",
    symbol: "RUNE",
    name: "Rune",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa9776b590bfc2f956711b3419910a5ec1f63153e/c86d2773ffeef00ae402b43519d129a1.png",
    coingeckoId: "rune",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929",
    symbol: "CTK",
    name: "CertiK Token",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xA83dEb06FC89c08C614aEd1fEC0490da854423D5",
    symbol: "UAPY",
    name: "UltimateAPY",
    decimals: 5,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D",
    symbol: "CTZN",
    name: "Totem Earth Systems",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa803778ab953d3ffe4fbd20cfa0042ecefe8319d/745e12a7ae35c0f14d1beb927588a9be.png",
    coingeckoId: "totem-earth-systems",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA7f552078dcC247C2684336020c03648500C6d9F",
    symbol: "EPS",
    name: "Ellipsis",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA7f552078dcC247C2684336020c03648500C6d9F/logo.png",
    coingeckoId: "ellipsis",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0xA77346760341460B42C230ca6D21d4c8E743Fa9c",
    symbol: "PETS",
    name: "",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xa77346760341460b42c230ca6d21d4c8e743fa9c/logo.png",
    coingeckoId: "micropets",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xA72a0564d0e887123112e6A4DC1abA7611Ad861d",
    symbol: "FEB",
    name: "FEB Token",
    decimals: 0,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0xa72a0564d0e887123112e6a4dc1aba7611ad861d.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc",
    symbol: "FRM",
    name: "Ferrum Network Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc/f610cb2893844f8b6c22656021e9d134.png",
    coingeckoId: "ferrum-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xA6c897CaaCA3Db7fD6e2D2cE1a00744f40aB87Bb",
    symbol: "DRACE",
    name: "DeathRoad Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa6c897caaca3db7fd6e2d2ce1a00744f40ab87bb/3c8fc9a3a5fe8f44fc314ade29669be4.png",
    coingeckoId: "deathroad",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA67a13c9283Da5AABB199Da54a9Cb4cD8B9b16bA",
    symbol: "NBL",
    name: "Nobility",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa67a13c9283da5aabb199da54a9cb4cd8b9b16ba/522851834ee09f74e3ff4f243497493c.png",
    coingeckoId: "nobility-old",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA64455a4553C9034236734FadDAddbb64aCE4Cc7",
    symbol: "SANTOS",
    name: "FC Santos Fan Token",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa64455a4553c9034236734faddaddbb64ace4cc7/2bd89165aed60173d2263f1203249b99.png",
    coingeckoId: "santos-fc-fan-token",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0xA5F249F401bA8931899a364d8E2699b5FA1D87a9",
    symbol: "MAIN",
    name: "MAIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa5f249f401ba8931899a364d8e2699b5fa1d87a9/00cf62e377aef07bc4feed552db5ad5a.png",
    coingeckoId: "main",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xA58950F05FeA2277d2608748412bf9F802eA4901",
    symbol: "WSG",
    name: "Wall Street Games",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA58950F05FeA2277d2608748412bf9F802eA4901/logo.png",
    coingeckoId: "wall-street-games",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50",
    symbol: "PIT",
    name: "Pitbull",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50/c412c5bcd3ac29066f67da5e3e14c54e.png",
    coingeckoId: "pitbull",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0xA4bf0BaeF9e8ea3B4a1cE694428d61546Fb4EBbb",
    symbol: "DLA",
    name: "Diolaunch",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa4bf0baef9e8ea3b4a1ce694428d61546fb4ebbb/2ba2029a5ee1688805dbaac3c1761686.png",
    coingeckoId: "diolaunch",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA4b6573c9AE09d81e4D1360e6402B81F52557098",
    symbol: "COR",
    name: "COR Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa4b6573c9ae09d81e4d1360e6402b81f52557098/3b9fa8371c80889823ed03d11153d9cb.png",
    coingeckoId: "coreto",
    listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
  },
  {
    address: "0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86",
    symbol: "LEPA",
    name: "Lepasa",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa4050aa9b76ccdae1a6a8b2f3e8627cdc1546d86/9119fabfd1293b21f68951cd70f1161b.png",
    coingeckoId: "lepasa",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA361C79783833524dc7838399a4862B5f47038b8",
    symbol: "WIDI",
    name: "WIDI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa361c79783833524dc7838399a4862b5f47038b8/0b61b3a265dab328c26eb9b55e9c8701.png",
    coingeckoId: "widiland",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA3499dd7dBBBD93CB0f8303f8a8AcE8D02508E73",
    symbol: "LACE",
    name: "Lovelace",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa3499dd7dbbbd93cb0f8303f8a8ace8d02508e73/890d01dec02533593b2e9484832d0fe7.png",
    coingeckoId: "lovelace-world",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0",
    symbol: "ORAI",
    name: "Oraichain Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa325ad6d9c92b55a3fc5ad7e412b1518f96441c0/7146b1817218ae98a7adb7fb017c7cab.png",
    coingeckoId: "oraichain-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA2a26349448ddAfAe34949a6Cc2cEcF78c0497aC",
    symbol: "TSC",
    name: "TrusterCoin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa2a26349448ddafae34949a6cc2cecf78c0497ac/f84a89cb45f9025779823fd6ab86b725.png",
    coingeckoId: "trustercoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA2120b9e674d3fC3875f415A7DF52e382F141225",
    symbol: "ATA",
    name: "Automata",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA2120b9e674d3fC3875f415A7DF52e382F141225/logo.png",
    coingeckoId: "automata",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xA1Fa5Df3a7C98cBD97D4c4f72a995150EE4a822b",
    symbol: "MUDRA",
    name: "Mudra",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0xa1fa5df3a7c98cbd97d4c4f72a995150ee4a822b/logo.png",
    coingeckoId: "mudra",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0xA1434F1FC3F437fa33F7a781E041961C0205B5Da",
    symbol: "QKC",
    name: "QuarkChain Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA1434F1FC3F437fa33F7a781E041961C0205B5Da/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0xA1303E6199b319a891b79685F0537D289af1FC83",
    symbol: "NAR",
    name: "NAR Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0xA1303E6199b319a891b79685F0537D289af1FC83/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4",
    symbol: "DPR",
    name: "Deeper Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4/f4bf3e41ed4af8c1ee90ca3f014ded1f.png",
    coingeckoId: "deeper-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0xA07c5b74C9B40447a954e1466938b865b6BBea36",
    symbol: "vBNB",
    name: "Venus BNB",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0xa07c5b74c9b40447a954e1466938b865b6bbea36/c15a0076f76ca8123971570cb4c3418c.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x9fD87aEfe02441B123c3c32466cD9dB4c578618f",
    symbol: "THG",
    name: "Thetan Gem",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9fd87aefe02441b123c3c32466cd9db4c578618f/6cb838920bb5f7f3c9084ae79eaf263d.png",
    coingeckoId: "thetan-arena",
    listedIn: ["coingecko", "openocean", "pancake", "rubic", "lifinance"],
  },
  {
    address: "0x9f589e3eabe42ebC94A44727b3f3531C0c877809",
    symbol: "TKO",
    name: "TokoCrypto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9f589e3eabe42ebC94A44727b3f3531C0c877809/logo.png",
    coingeckoId: "tokocrypto",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x9d9AFFAc2175Ef541642035aB66f5FE7Df813851",
    symbol: "SPACE",
    name: "SpaceToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9d9affac2175ef541642035ab66f5fe7df813851/934201c14d045663ba04b55929590888.png",
    coingeckoId: "space-token-bsc",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9d3E14B607b2f569CfAFe29AF71e811d7E575CFe",
    symbol: "FLOBO",
    name: "FlokiBonk",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9d3e14b607b2f569cfafe29af71e811d7e575cfe/81484a2aa8e8407b12868c1f5fb18943.png",
    coingeckoId: "flokibonk",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x9c67638c4Fa06FD47fB8900fC7F932f7EAB589De",
    symbol: "ARKER ",
    name: "ARKER ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9c67638c4fa06fd47fb8900fc7f932f7eab589de/04cd2a536b0be7c5d74a7218d61ee0ef.png",
    coingeckoId: "arker-2",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x9c2B1B3780A8B36B695f0b2781668664aC1Bf25A",
    symbol: "SPKY",
    name: "SpookyShiba",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9c2b1b3780a8b36b695f0b2781668664ac1bf25a/9cd58fe2c591f2d3ac1c23d98741346f.png",
    coingeckoId: "spookyshiba-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9aaB0A9B3A2F7cF669f1385c48e0a063B93834bB",
    symbol: "XMETA",
    name: "TTX METAVERSE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9aab0a9b3a2f7cf669f1385c48e0a063b93834bb/b7c805495c5ef20b212c66c94e3e99d0.png",
    coingeckoId: "ttx-metaverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9a319b959e33369C5eaA494a770117eE3e585318",
    symbol: "HYFI",
    name: "HYFI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9a319b959e33369C5eaA494a770117eE3e585318/logo.png",
    coingeckoId: "hyper-finance",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x9Fe28D11ce29E340B7124C493F59607cbAB9ce48",
    symbol: "SPELL",
    name: "Spell",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/logos/main/network/binance/0x9Fe28D11ce29E340B7124C493F59607cbAB9ce48.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19",
    symbol: "DG",
    name: "Decentral Games",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19/logo.png",
    coingeckoId: "decentral-games-old",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3",
    symbol: "MULTI",
    name: "Multichain",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3/bf6b4c0d299a4588f5af3a398b0a5171.png",
    coingeckoId: "multichain",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9F882567A62a5560d147d64871776EeA72Df41D3",
    symbol: "MX",
    name: "MX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9F882567A62a5560d147d64871776EeA72Df41D3/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x9D986A3f147212327Dd658F712d5264a73a1fdB0",
    symbol: "LAND",
    name: "Landshare Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9d986a3f147212327dd658f712d5264a73a1fdb0/9866a4265d7b81111b3e3218bdce88f9.png",
    coingeckoId: "landshare",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x9D4451151A8dE5B545a1bC6c8fdEB9d94a2868e1",
    symbol: "LNR",
    name: "Lunar",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9d4451151a8de5b545a1bc6c8fdeb9d94a2868e1/39e59bb72a87bf0aa3cb7d04d0b48e86.png",
    coingeckoId: "lunar",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x9D173E6c594f479B4d47001F8E6A95A7aDDa42bC",
    symbol: "ZOON",
    name: "CryptoZoon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9d173e6c594f479b4d47001f8e6a95a7adda42bc/ba0ffd1af15e212a8b3b553c737fd625.png",
    coingeckoId: "cryptozoon",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739",
    symbol: "MDX",
    name: "MDX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739/logo.png",
    coingeckoId: "mdex",
    listedIn: ["coingecko", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x9Ba6a67a6F3b21705a46B380A1B97373a33da311",
    symbol: "FEAR",
    name: "Chainport.io-PegFearNFTs",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9ba6a67a6f3b21705a46b380a1b97373a33da311/78dba9cce97ba14ee9692dfb22400a04.png",
    coingeckoId: "fear",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9B44Df3318972bE845d83f961735609137C4C23c",
    symbol: "PROPEL",
    name: "Propel",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9b44df3318972be845d83f961735609137c4c23c/a3c1145f0c03e212e20624a766904b85.png",
    coingeckoId: "payrue",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9B3a01F8b4aBD2E2a74597B21b7C269ABf4E9f41",
    symbol: "ALTB",
    name: "Altbase",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9b3a01f8b4abd2e2a74597b21b7c269abf4e9f41/af5d47b3534ef234fcb962cf60859a1e.png",
    coingeckoId: "altbase",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322",
    symbol: "WWY",
    name: "WeWay Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9ab70e92319f0b9127df78868fd3655fb9f1e322/03181b27c6590b1724f571e36912fadd.png",
    coingeckoId: "weway",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x9AA18A4E73E1016918fA360Eed950D9580C9551d",
    symbol: "GENX",
    name: "GenX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x9aa18a4e73e1016918fa360eed950d9580c9551d/logo.png",
    coingeckoId: "genx",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x9A78649501BbAAC285Ea4187299471B7ad4ABD35",
    symbol: "DSG",
    name: "DinosaurEggsToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9a78649501bbaac285ea4187299471b7ad4abd35/8230070f6e3e18d8fdd1e0a8dca879ad.png",
    coingeckoId: "dinosaureggs",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9A257C90Fa239fBA07771ef7da2d554D148c2E89",
    symbol: "eYe",
    name: "eYe on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9a257c90fa239fba07771ef7da2d554d148c2e89/90bf851397911a75430ceaa8baa30c2d.png",
    coingeckoId: "media-eye",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x99e92123eB77Bc8f999316f622e5222498438784",
    symbol: "GMT_1",
    name: "Gambit",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x99e92123eb77bc8f999316f622e5222498438784/3620c19d7a46e357897889d3fe9dd877.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x99c6e435eC259A7E8d65E1955C9423DB624bA54C",
    symbol: "FMT",
    name: "Finminity",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x99c6e435ec259a7e8d65e1955c9423db624ba54c/03bc99345e3e3b59adb7be35b9962f83.png",
    coingeckoId: "finminity",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x994517e000aa3F117E7Ad61B0e2336C76B4Fd94a",
    symbol: "MTDR",
    name: "Matador Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x994517e000aa3f117e7ad61b0e2336c76b4fd94a/3f53ba8e64a42ac839d7e4c92ff3f088.png",
    coingeckoId: "matador-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x990E7154bB999FAa9b2fa5Ed29E822703311eA85",
    symbol: "TT",
    name: "ThunderCore",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x990e7154bb999faa9b2fa5ed29e822703311ea85/ae414b9f298ce8c20b69dcbe3c0d177a.png",
    coingeckoId: "thunder-token",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff",
    symbol: "DFD",
    name: "DefiDollar DAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff/logo.png",
    coingeckoId: "defidollar-dao",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067",
    symbol: "ANN",
    name: "Annex",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x98936bde1cf1bff1e7a8012cee5e2583851f2067/c234e4e340a800156d5639506e14cec0.png",
    coingeckoId: "annex",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x988c11625472340b7B36FF1534893780E0d8d841",
    symbol: "wCCX",
    name: "WrappedConceal",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x988c11625472340b7b36ff1534893780e0d8d841/d6c46fcd3d7683a38ddef35a30431ddc.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x986854779804799C1d68867F5E03e601E781e41b",
    symbol: "LDO",
    name: "Lido DAO Token (Wormhole)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x986854779804799c1d68867f5e03e601e781e41b/3a1a90da5ccd4849de3e83755f1fd8b5.png",
    coingeckoId: "lido-dao-wormhole",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9806aec346064183b5cE441313231DFf89811f7A",
    symbol: "yPANDA",
    name: "YieldPanda.finance",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9806aec346064183b5ce441313231dff89811f7a/13e2d26ea0fb4c400dfffb42867b3284.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x97a30C692eCe9C317235d48287d23d358170FC40",
    symbol: "CRX",
    name: "CryptEx Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x97a30c692ece9c317235d48287d23d358170fc40/4a52acd30cf8420a358b1ae71751e1a8.png",
    coingeckoId: "cryptex",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x973616ff3b9d8F88411C5b4E6F928EE541e4d01f",
    symbol: "aETH",
    name: "Ankr Eth2 Reward Bearing Bond",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0x973616ff3b9d8f88411c5b4e6f928ee541e4d01f.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x972207A639CC1B374B893cc33Fa251b55CEB7c07",
    symbol: "vBETH",
    name: "Venus BETH",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x972207a639cc1b374b893cc33fa251b55ceb7c07/ac82c7c8d7f3a78b8923fe2f36a07099.png",
    coingeckoId: "venus-beth",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x9720CA160Bbd4E7f3dD4bb3f8BD4227ca0342e63",
    symbol: "GMPD",
    name: "GamesPad",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9720ca160bbd4e7f3dd4bb3f8bd4227ca0342e63/23c75bc0872d43fc54b36b8f126dfdce.png",
    coingeckoId: "gamespad",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x96Dd399F9c3AFda1F194182F71600F1B65946501",
    symbol: "COS",
    name: "Contentos",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x96Dd399F9c3AFda1F194182F71600F1B65946501/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x9678E42ceBEb63F23197D726B29b1CB20d0064E5",
    symbol: "IOTX",
    name: "IoTeX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9678E42ceBEb63F23197D726B29b1CB20d0064E5/logo.png",
    coingeckoId: "iotex",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x965b0Df5BDA0E7a0649324D78f03D5F7F2De086a",
    symbol: "COOK",
    name: "Poly-Peg COOK",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x965b0df5bda0e7a0649324d78f03d5f7f2de086a/3fb75331e334330682d06a459884491e.png",
    coingeckoId: "cook",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
    symbol: "BSW",
    name: "BSW",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/16845/small/biswap.png?1625388985",
    coingeckoId: "biswap",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x963FaC6d71cDdBd717D8D0c2FD06AAe5dd6072Fc",
    symbol: "WHIRL",
    name: "OmniWhirl",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x963fac6d71cddbd717d8d0c2fd06aae5dd6072fc/04f621925820f51b1bb02fb632cc3fd9.png",
    coingeckoId: "omniwhirl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9617857E191354dbEA0b714d78Bc59e57C411087",
    symbol: "LMT",
    name: "Lympo Market Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x9617857E191354dbEA0b714d78Bc59e57C411087/logo.png",
    coingeckoId: "lympo-market-token",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x96058f8C3e16576D9BD68766f3836d9A33158f89",
    symbol: "BONDLY",
    name: "Bondly Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0x96058f8c3e16576d9bd68766f3836d9a33158f89.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x95c78222B3D6e262426483D42CfA53685A67Ab9D",
    symbol: "vBUSD",
    name: "Venus BUSD",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x95c78222b3d6e262426483d42cfa53685a67ab9d/780529ad675a90e33ae9ef7b9eee157c.png",
    coingeckoId: "venus-busd",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x95a1199EBA84ac5f19546519e287d43D2F0E1b41",
    symbol: "RABBIT",
    name: "Rabbit Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x95a1199EBA84ac5f19546519e287d43D2F0E1b41/logo.png",
    coingeckoId: "rabbit-finance",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B",
    symbol: "DUET",
    name: "DUET",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/16164/small/Duet.jpg?1623143640",
    coingeckoId: "duet-protocol",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "xyfinance"],
  },
  {
    address: "0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8",
    symbol: "PHUB",
    name: "PegHub.com",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x95a6772a2272b9822d4b3dfeeaedf732f1d28db8/c6424c706baddec7e7c8067f51c3e470.png",
    coingeckoId: "peghub-com",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x9573c88aE3e37508f87649f87c4dd5373C9F31e0",
    symbol: "MONI",
    name: "Monsta Infinite Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9573c88ae3e37508f87649f87c4dd5373c9f31e0/3b692e39f24d37276f1e373329ec8413.png",
    coingeckoId: "monsta-infinite",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x95368f2c269F1f3394f21d72662BFB8b00F1A7fD",
    symbol: "HOR",
    name: "HORDE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x95368f2c269f1f3394f21d72662bfb8b00f1a7fd/26998edbd2aa2dbabe23446a02ef130b.png",
    coingeckoId: "horde",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50",
    symbol: "LOA",
    name: "League Of Ancients",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x94b69263fca20119ae817b6f783fc0f13b02ad50/7084b5fad0ab6b785904db5249802fc4.png",
    coingeckoId: "league-of-ancients",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x94F559aE621F1c810F31a6a620Ad7376776fe09E",
    symbol: "SOUP",
    name: "Soup",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x94f559ae621f1c810f31a6a620ad7376776fe09e.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x94BaBBE728D9411612Ee41b20241a6FA251b26Ce",
    symbol: "GFCE",
    name: "GFORCE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x94babbe728d9411612ee41b20241a6fa251b26ce.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6",
    symbol: "MC",
    name: "MC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/19304/small/Db4XqML.png?1634972154",
    coingeckoId: "merit-circle",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8",
    symbol: "Helmet",
    name: "Helmet.insure",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8/logo.png",
    coingeckoId: "helmet-insure",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4",
    symbol: "BSUSHI",
    name: "Sushi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x947950BcC74888a40Ffa2593C5798F11Fc9124C4/logo.png",
    coingeckoId: "sushi",
    listedIn: [
      "coingecko",
      "sushiswap",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x9400Aa8eb5126d20CDE45C7822836BFB70F19878",
    symbol: "DRF",
    name: "DRIFE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9400aa8eb5126d20cde45c7822836bfb70f19878/50ecd46d5f39f4896ac463bef2bf25b2.png",
    coingeckoId: "drife",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846",
    symbol: "GMI",
    name: "Gamifi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x93d8d25e3c9a847a5da79f79ecac89461feca846/f824f707437611dbb66bb487ef2656b5.png",
    coingeckoId: "gamifi",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x938ae80A71279086fEf47A4F9F0890cE729986F0",
    symbol: "Bankers",
    name: "BullBankers",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x938ae80a71279086fef47a4f9f0890ce729986f0/1c04053344a6f5f36239a3a814d2f340.png",
    coingeckoId: "bullbankers",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5",
    symbol: "TRDG",
    name: "Tardigrades.Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x92a42db88ed0f02c71d439e55962ca7cab0168b5/133a60f375f66196213748af26ead480.png",
    coingeckoId: "tardigrades-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x92DD5B17bDacbbE3868a09bE5a3df93032C29ddb",
    symbol: "KUBIC",
    name: "Kubic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x92dd5b17bdacbbe3868a09be5a3df93032c29ddb/7865e707ef0cbc0d2dd1e28fce94191b.png",
    coingeckoId: "kubic",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x92D7756c60dcfD4c689290E8A9F4d263b3b32241",
    symbol: "BOR",
    name: "BoringDAO Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x92d7756c60dcfd4c689290e8a9f4d263b3b32241/2810c6155204153d5a324b037f609180.png",
    coingeckoId: "boringdao-[old]",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
    symbol: "FRONT",
    name: "Frontier Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b/logo.png",
    coingeckoId: "frontier-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x92868A5255C628dA08F550a858A802f5351C5223",
    symbol: "BRIDGE",
    name: "Cross-Chain Bridge Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x92868a5255c628da08f550a858a802f5351c5223/55544ad7e956d9ca49533801c001fb23.png",
    coingeckoId: "cross-chain-bridge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x924d221c4Fd599e5894706aC2E8f6a35a3cC981E",
    symbol: "FRR",
    name: "Frontrow",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x924d221c4fd599e5894706ac2e8f6a35a3cc981e/6af7e0c04f37fd55d9b344a3cdc866e4.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x9133049Fb1FdDC110c92BF5b7Df635abB70C89DC",
    symbol: "PINK",
    name: "Pink Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9133049fb1fddc110c92bf5b7df635abb70c89dc/f3e6dc0d09570c95b564ff794fc973df.png",
    coingeckoId: "dot-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x90e767A68a7d707B74D569C8E79f9bBb79b98a8b",
    symbol: "FAT",
    name: "Fatfi Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x90e767a68a7d707b74d569c8e79f9bbb79b98a8b.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x90a1E4BBADE88366dC44436535f1571d95e666c7",
    symbol: "FWT",
    name: "Freeway",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x90a1e4bbade88366dc44436535f1571d95e666c7/5eab5d0bea810142965d1a9488305070.png",
    coingeckoId: "freeway",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240",
    symbol: "SDAO",
    name: "Singularity Dao",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240/6ccda7a005122611519cf9575918c7f9.png",
    coingeckoId: "singularitydao",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x90DF11a8ccE420675e73922419e3f4f3Fe13CCCb",
    symbol: "STM",
    name: "Streamity",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x90df11a8cce420675e73922419e3f4f3fe13cccb/f4e1d517ab8d0ef6e7b72aab45cfac67.png",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "lifinance"],
  },
  {
    address: "0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40",
    symbol: "FRAX",
    name: "FRAX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    coingeckoId: "frax",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x9096B4309224d751FCB43d7eB178dcFFc122aD15",
    symbol: "LGX",
    name: "Legion Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9096b4309224d751fcb43d7eb178dcffc122ad15/d782def1d1ad9d6e4d09c66ab42d8b99.png",
    coingeckoId: "legion-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x907b283aB2d25DC8bd196a764cB9B5B69A3be0c4",
    symbol: "REFLEX",
    name: "Reflex BITCOIN",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x9029FdFAe9A03135846381c7cE16595C3554e10A",
    symbol: "OOE",
    name: "OpenOcean",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9029fdfae9a03135846381c7ce16595c3554e10a/82124d68da604f900e236dd424e54343.png",
    coingeckoId: "openocean",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x9001eE054F1692feF3A48330cB543b6FEc6287eb",
    symbol: "STEEL",
    name: "IRON Share V2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9001ee054f1692fef3a48330cb543b6fec6287eb/820f6043970979b99e93acd40ab5ca20.png",
    coingeckoId: "steel",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x9000Cac49C3841926Baac5b2E13c87D43e51B6a4",
    symbol: "POR",
    name: "Portuma",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x9000cac49c3841926baac5b2e13c87d43e51b6a4/abc88e6f7b76c5b07a80a125fd344d44.png",
    coingeckoId: "portuma",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
    symbol: "BCH",
    name: "Bitcoin Cash Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf/logo.png",
    coingeckoId: "binance-peg-bitcoin-cash",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x8f1fE4e6707CD4236b704759d2ee15166C68183a",
    symbol: "TMC",
    name: "Tom coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8f1fe4e6707cd4236b704759d2ee15166c68183a/f759d81c1407ed379bc851ab274429a2.png",
    coingeckoId: "tom-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8f0FB159380176D324542b3a7933F0C2Fd0c2bbf",
    symbol: "TFT",
    name: "TFT on BSC",
    decimals: 7,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf/7044922d80b4231decfc82f1610f37c8.png",
    coingeckoId: "threefold-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8dA443F84fEA710266C8eB6bC34B71702d033EF2",
    symbol: "CTSI",
    name: "Cartesi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8dA443F84fEA710266C8eB6bC34B71702d033EF2/logo.png",
    coingeckoId: "cartesi",
    listedIn: ["coingecko", "lifinance", "elkfinance"],
  },
  {
    address: "0x8d55eCa212a56e9D3A0C337950F910e3397E1E68",
    symbol: "M7V2",
    name: "M7V2",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8d55eca212a56e9d3a0c337950f910e3397e1e68/4bfe1658ab76224fe981508b1590d658.png",
    coingeckoId: "m7v2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8d2aa7b23aBE16E7eCa845F64C65A314d4ABa35A",
    symbol: "USDibs",
    name: "Dibs USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8d2aa7b23abe16e7eca845f64c65a314d4aba35a/bb9b1d015d3975bdc242890c6a402ec3.png",
    coingeckoId: "dibs-usd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8c18ffD66d943C9B0AD3DC40E2D64638F1e6e1ab",
    symbol: "HER",
    name: "HerityNetwork",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8c18ffd66d943c9b0ad3dc40e2d64638f1e6e1ab/4ee4b96fbedc0a0f969ff28b4bd4414f.png",
    coingeckoId: "herity-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00",
    symbol: "SPH",
    name: "Sphynx Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00/65964ce3fd41951ccc0d6f0d8cbeca5a.png",
    coingeckoId: "sphynx-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8b303d5BbfBbf46F1a4d9741E491e06986894e18",
    symbol: "WOOP",
    name: "Woonkly Power",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8b303d5bbfbbf46f1a4d9741e491e06986894e18/a461b245b333f4ef60d71b594627fb70.png",
    coingeckoId: "woonkly-power",
    listedIn: ["coingecko", "1inch", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x8ad8e9B85787ddd0D31b32ECF655E93bfc0747eF",
    symbol: "SMBR",
    name: "SOMBRA",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8ad8e9b85787ddd0d31b32ecf655e93bfc0747ef/f3e98be73d37a4b308160a2c3a84f9aa.png",
    coingeckoId: "sombra-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8aa688AB789d1848d131C65D98CEAA8875D97eF1",
    symbol: "MTV",
    name: "MultiVAC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8aa688ab789d1848d131c65d98ceaa8875d97ef1/65810154804271375340b91078e72ef2.png",
    coingeckoId: "multivac",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x8a74BC8c372bC7f0E9cA3f6Ac0df51BE15aEC47A",
    symbol: "PLSPAD",
    name: "PULSEPAD.io",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8a74bc8c372bc7f0e9ca3f6ac0df51be15aec47a/88f4a9419bfef6667a8482c245862a91.png",
    coingeckoId: "pulsepad",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83",
    symbol: "BRISE",
    name: "Bitrise Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8fff93e810a2edaafc326edee51071da9d398e83/bd30cb83570faa51a3952fcdde92887a.png",
    coingeckoId: "bitrise-token",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x8F49733210700D38098d7375C221c7d02F700cc8",
    symbol: "PALLA",
    name: "Pallapay",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8f49733210700d38098d7375c221c7d02f700cc8/3fdb63f69c4148d54385e02d9c8b7904.png",
    coingeckoId: "pallapay",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
    symbol: "ALPACA",
    name: "Alpaca",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F/logo.png",
    coingeckoId: "alpaca-finance",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x8E3BCC334657560253B83f08331d85267316e08a",
    symbol: "BRBC",
    name: "Rubic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8e3bcc334657560253b83f08331d85267316e08a/b1e9f2c1070c21234bb3fbd8bec7f01d.png",
    coingeckoId: "rubic",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x8D3E3A57c5F140B5f9Feb0d43D37A347ee01c851",
    symbol: "CMERGE",
    name: "Coin Merge",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8d3e3a57c5f140b5f9feb0d43d37a347ee01c851/b76acb086f1061f959e978826fc59287.png",
    coingeckoId: "coinmerge-bsc",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
    symbol: "KEX",
    name: "KIRA Network",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e/5908c1e4a281d351c0870005e700ed8d.png",
    coingeckoId: "kira-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8D047F4F57A190C96c8b9704B39A1379E999D82B",
    symbol: "ECC",
    name: "Etherconnect Coin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8d047f4f57a190c96c8b9704b39a1379e999d82b/204309abd35d0e19f8f8a52d5b0a4cbf.png",
    coingeckoId: "etherconnect",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B",
    symbol: "NULS",
    name: "Nuls",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x8C88699ef5adA1E5bEDFf7a4590aA346Abd3536d",
    symbol: "PAPPAY",
    name: "PAPPAY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8c88699ef5ada1e5bedff7a4590aa346abd3536d/d27e2256c3cb699c1a517f1a5fadc15b.png",
    coingeckoId: "pappay",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97",
    symbol: "BNX",
    name: "BinaryX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8c851d1a123ff703bd1f9dabe631b69902df5f97/18609dd606d7431cddf153eff04c5c05.png",
    coingeckoId: "binaryx",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x8C784C49097Dcc637b93232e15810D53871992BF",
    symbol: "MSC",
    name: "Monster Slayer Cash",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8c784c49097dcc637b93232e15810d53871992bf/5500a0fdc649a11dcbdceb9e551ef521.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10",
    symbol: "KART",
    name: "Dragon Kart",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8bdd8dbcbdf0c066ca5f3286d33673aa7a553c10/fd4d27bf6f7754e898cf2bc73612f469.png",
    coingeckoId: "dragon-kart-token",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    symbol: "USDC",
    name: "Binance Pegged USD Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/logo.png",
    coingeckoId: "usd-coin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x8A65B987D9813f0a97446eDa0dE918b2573Ae406",
    symbol: "FomoETH",
    name: "FomoETH",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8a65b987d9813f0a97446eda0de918b2573ae406/74c350fe7bbf6538a6ccab82d481caeb.png",
    coingeckoId: "fomoeth",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f",
    symbol: "MONSTA",
    name: "Cake Monster",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8a5d7fcd4c90421d21d30fcc4435948ac3618b2f/6cc9415f05918b8971907dab3e9316b8.png",
    coingeckoId: "cake-monster",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e",
    symbol: "YFI",
    name: "yearn.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x88D7e9B65dC24Cf54f5eDEF929225FC3E1580C25",
    symbol: "JMPT",
    name: "JumpToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x88d7e9b65dc24cf54f5edef929225fc3e1580c25/714c635ea5c381052955fb54e73c501c.png",
    coingeckoId: "jumptoken",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x889eFCe29fa0BB9B26be9fda17A8003F4e8dA4dE",
    symbol: "EGG",
    name: "Waves Ducks",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x889efce29fa0bb9b26be9fda17a8003f4e8da4de/36852d0a36c54c3f1aee709024cbfa94.png",
    coingeckoId: "waves-ducks",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556",
    symbol: "NUTS",
    name: "NUTS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556/logo.png",
    coingeckoId: "squirrel-finance",
    listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
  },
  {
    address: "0x887D9c01FFf04022da9c6D64a65a481a9e5D1FCa",
    symbol: "LEDU",
    name: "Education",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x887d9c01fff04022da9c6d64a65a481a9e5d1fca/ced53584fac7e286bc1c5d82d3d1dafd.png",
    coingeckoId: "education-ecosystem",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x885C5Fb8f0e67b2b0Cf3a437e6Cc6EBC0F9f9014",
    symbol: "GEMG",
    name: "GemGuardian",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x885c5fb8f0e67b2b0cf3a437e6cc6ebc0f9f9014/f6ebbbfd911a9183cf0a58100793188a.png",
    coingeckoId: "gemguardian",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8850D2c68c632E3B258e612abAA8FadA7E6958E5",
    symbol: "PIG",
    name: "Pig Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8850d2c68c632e3b258e612abaa8fada7e6958e5/b97ba2f7d7bb755505dcea1a3eff0fdc.png",
    coingeckoId: "pig-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x88479186BAC914E4313389a64881F5ed0153C765",
    symbol: "SquidGrow",
    name: "SquidGrow",
    decimals: 19,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x88479186bac914e4313389a64881f5ed0153c765/2d2f3a4769fe7992a45617b7b0a15100.png",
    coingeckoId: "squidgrow",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B",
    symbol: "vBTC",
    name: "Venus BTC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b/b3f312c1ea56829b96a6af050dd5efa9.png",
    coingeckoId: "venus-btc",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3",
    symbol: "MOR",
    name: "Mor Stablecoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x87bade473ea0513d4aa7085484aeaa6cb6ebe7e3/8f5e38900e20fb38ba32f6235fbd16d1.png",
    coingeckoId: "mor-stablecoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8789337a176e6e7223Ff115F1CD85C993D42C25c",
    symbol: "COP",
    name: "Copiosa Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8789337a176e6e7223ff115f1cd85c993d42c25c/9265aecc4c5f50cdea6749b3fd82cd45.png",
    coingeckoId: "copiosa",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8780fEA4C6b242677D4A397fE1110AC09Ce99ad2",
    symbol: "BIRD",
    name: "Bird",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8780fea4c6b242677d4a397fe1110ac09ce99ad2/1cf31eeabcfa2b98fd2b059dcfc20e67.png",
    coingeckoId: "bird-money",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
    symbol: "QI",
    name: "BENQI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance"],
  },
  {
    address: "0x87230146E138d3F296a9a77e497A2A83012e9Bc5",
    symbol: "SQUID",
    name: "Squid Game",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x87230146e138d3f296a9a77e497a2a83012e9bc5/191d3486e5affeecbf8d6a1e9fa43848.png",
    coingeckoId: "squid-game",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x86c3E4FfAcdB3AF628ef985a518cd6ee22A22b28",
    symbol: "OCTA",
    name: "Octans",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28/e388cfcfbc622032bb78469f483b2f85.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x86a53fcd199212FEa44FA7e16EB1f28812be911D",
    symbol: "IHC",
    name: "Inflation Hedging Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x86a53fcd199212fea44fa7e16eb1f28812be911d/a92ba96d74532000d35b7345f2a933a5.png",
    coingeckoId: "inflation-hedging-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x86B3F23B6e90F5bbfac59b5b2661134Ef8Ffd255",
    symbol: "DON",
    name: "Donkey",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x86b3f23b6e90f5bbfac59b5b2661134ef8ffd255/logo.png",
    coingeckoId: "don-key",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x864078171D77b9c3faaD3fEBe98954279BF56E78",
    symbol: "KTI",
    name: "Kermit Inu",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x864078171d77b9c3faad3febe98954279bf56e78/348580c91ac3fb1360eee473d70165b0.png",
    coingeckoId: "kermit-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x86296279C147bd40cBe5b353F83cea9e9cC9b7bB",
    symbol: "KTY",
    name: "Krypto Kitty",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x86296279c147bd40cbe5b353f83cea9e9cc9b7bb/3cc322bc1145789da0ba39364d96c797.png",
    coingeckoId: "krypto-kitty",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x85daB10c3BA20148cA60C2eb955e1F8ffE9eAa79",
    symbol: "ARTH.bsc",
    name: "ARTH Valuecoin (BSC)",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "arth",
    listedIn: ["coingecko", "dfyn", "lifinance"],
  },
  {
    address: "0x85c128eE1feEb39A59490c720A9C563554B51D33",
    symbol: "KEY",
    name: "MoMo KEY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x85c128ee1feeb39a59490c720a9c563554b51d33/717296d886f1c98723ad8f0259b58c4b.png",
    coingeckoId: "momo-key",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B",
    symbol: "TRX",
    name: "Tron",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B/logo.png",
    coingeckoId: "tron-bsc",
    listedIn: [
      "coingecko",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x8597ba143AC509189E89aaB3BA28d661A5dD9830",
    symbol: "VANCAT",
    name: "VANCAT Token",
    decimals: 0,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8597ba143ac509189e89aab3ba28d661a5dd9830/8880776cf890966b1b6d81347131e79c.png",
    coingeckoId: "vancat",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8595F9dA7b868b1822194fAEd312235E43007b49",
    symbol: "BTT",
    name: "Bittorrent",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8595F9dA7b868b1822194fAEd312235E43007b49/logo.png",
    coingeckoId: "bittorrent-old",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd",
    symbol: "LTO",
    name: "LTO Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd/logo.png",
    coingeckoId: "lto-network",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x854A63B35B70A7becbED508ff0b6Ff5038D0C917",
    symbol: "MNTO",
    name: "Minato",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x854a63b35b70a7becbed508ff0b6ff5038d0c917/82b312bf0e8c42c03148b2631ef8bf86.png",
    coingeckoId: "minato",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x853A8Ab1c365ea54719EB13A54d6B22F1fBe7FEb",
    symbol: "INVEST",
    name: "Investdex",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x853a8ab1c365ea54719eb13a54d6b22f1fbe7feb/a92dc92758a96f4b5e997a3ccb47b342.png",
    coingeckoId: "investdex",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x851F7a700c5d67DB59612b871338a85526752c25",
    symbol: "ARGON",
    name: "ArgonToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x851f7a700c5d67db59612b871338a85526752c25/227f8567a1889cecee8eb66ee471c330.png",
    coingeckoId: "argon",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x8519EA49c997f50cefFa444d240fB655e89248Aa",
    symbol: "RAMP",
    name: "Ramp DEFI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8519EA49c997f50cefFa444d240fB655e89248Aa/logo.png",
    coingeckoId: "ramp",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x8506560320826e459F356CB56cCF721Da8875414",
    symbol: "nICE",
    name: "NICE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://raw.githubusercontent.com/sushiswap/logos/main/network/binance/0x8506560320826e459F356CB56cCF721Da8875414.jpg",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x84e9a6F9D240FdD33801f7135908BfA16866939A",
    symbol: "GMEE",
    name: "GAMEE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x84e9a6f9d240fdd33801f7135908bfa16866939a/061189ca4afb01f352b243daf05ecae2.png",
    coingeckoId: "gamee",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0x84b174628911896a3b87Fa6980D05Dbc2eE74836",
    symbol: "wZNN",
    name: "Wrapped ZNN",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x84b174628911896a3b87fa6980d05dbc2ee74836/fca72e4f2bdeb134ccfbaf379a2e15c1.png",
    coingeckoId: "zenon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x84952dd90cdC57170b13D2291A8afCEdC8F97c71",
    symbol: "SAFTI",
    name: "SafuTitano",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x84952dd90cdc57170b13d2291a8afcedc8f97c71/da18440d15723c768a5a870625cae9b1.png",
    coingeckoId: "safutitano",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x846F52020749715F02AEf25b5d1d65e48945649D",
    symbol: "UMB",
    name: "Umbrella",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x846f52020749715f02aef25b5d1d65e48945649d/c93a6de95494ac8f110bc04a3075c40e.png",
    coingeckoId: "umbrella-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8468292f02BEF940f3FB0CedA6607DaD625d8C0B",
    symbol: "99DEFI",
    name: "99DEFI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8468292f02bef940f3fb0ceda6607dad625d8c0b/60dbc56efbcd67fe929c6d85c3a320bc.png",
    coingeckoId: "99defi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x844FA82f1E54824655470970F7004Dd90546bB28",
    symbol: "DOP",
    name: "Dopple Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x844FA82f1E54824655470970F7004Dd90546bB28/logo.png",
    coingeckoId: "dopple-finance",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x8443f091997f06a61670B735ED92734F5628692F",
    symbol: "BEL",
    name: "Bella Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8443f091997f06a61670B735ED92734F5628692F/logo.png",
    coingeckoId: "bella-protocol",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x843D4a358471547f51534e3e51fae91cb4Dc3F28",
    symbol: "lowb",
    name: "loser coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x843d4a358471547f51534e3e51fae91cb4dc3f28/3e969141080421ed2e39b427f354be8d.png",
    coingeckoId: "loser-coin",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822",
    symbol: "BUILD",
    name: "Build Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x83b27de2fca046fa63a11c7ce7743de33ec58822/c579cd2cdf2a4548d4e3e24b6287c0ce.png",
    coingeckoId: "build",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x83A86adf1a7c56e77d36d585B808052e0a2aAD0e",
    symbol: "SYA",
    name: "SaveYourAssets",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x83a86adf1a7c56e77d36d585b808052e0a2aad0e/bfe4bd2b16642f7f69979023672db7e7.png",
    coingeckoId: "sya-x-flooz",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x833F307aC507D47309fD8CDD1F835BeF8D702a93",
    symbol: "REVV",
    name: "REVV",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x833F307aC507D47309fD8CDD1F835BeF8D702a93/logo.png",
    coingeckoId: "revv",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x82C19905B036bf4E329740989DCF6aE441AE26c1",
    symbol: "CP",
    name: "CP",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x82c19905b036bf4e329740989dcf6ae441ae26c1/4def0c8820c68cb7d41ddc5a88993203.png",
    coingeckoId: "copuppy",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x829c09fCc46D9fd31967272ABA245BEF9f727F93",
    symbol: "USDEX",
    name: "USDEX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x829c09fcc46d9fd31967272aba245bef9f727f93/1835f73dce169c7e251795a66b50575a.png",
    coingeckoId: "usdex-stablecoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8263CD1601FE73C066bf49cc09841f35348e3be0",
    symbol: "ALU",
    name: "Altura",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8263cd1601fe73c066bf49cc09841f35348e3be0/7b60a68995f04fa9735ae73ff5a79ed1.png",
    coingeckoId: "altura",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x824a50dF33AC1B41Afc52f4194E2e8356C17C3aC",
    symbol: "KICK",
    name: "KickToken",
    decimals: 10,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x824a50df33ac1b41afc52f4194e2e8356c17c3ac/9f8af2f8910c31aa2709c2e6b13a28e6.png",
    coingeckoId: "kick",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc",
    symbol: "FEVR",
    name: "FevrToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x82030cdbd9e4b7c5bb0b811a61da6360d69449cc/2012baa15689ca4acf7e4b72bcbe24c9.png",
    coingeckoId: "realfevr",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x81e07CfF8a9331eF2A837B15a3560fb186bF5E8D",
    symbol: "APP",
    name: "DAPPSY",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x81e07cff8a9331ef2a837b15a3560fb186bf5e8d/51d4040dcd05b71a2ba598cbba5eef44.png",
    coingeckoId: "dappsy",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x81E4d494b85A24a58a6BA45c9B418b32a4E039de",
    symbol: "MOONTOKEN",
    name: "Moon Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x81e4d494b85a24a58a6ba45c9b418b32a4e039de/f4fa74ff96c0e7b3c78b658a1ad5515a.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x81859801b01764D4f0Fa5E64729f5a6C3b91435b",
    symbol: "BFI",
    name: "b.earnfi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x81859801b01764D4f0Fa5E64729f5a6C3b91435b/logo.png",
    coingeckoId: "bearn-fi",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x810EE35443639348aDbbC467b33310d2AB43c168",
    symbol: "CYC",
    name: "Cyclone",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x810EE35443639348aDbbC467b33310d2AB43c168/logo.png",
    coingeckoId: "cyclone-protocol",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x80d04E44955AA9c3F24041B2A824A20A88E735a8",
    symbol: "MVC",
    name: "Multiverse Capital (MVC.finance)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x80d04e44955aa9c3f24041b2a824a20a88e735a8/587c298069874a5621f75d6a45d3b8a2.png",
    coingeckoId: "multiverse-capital",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x80D5f92C2c8C682070C95495313dDB680B267320",
    symbol: "ASR",
    name: "AS Roma",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x80D5f92C2c8C682070C95495313dDB680B267320/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x80CD73baDb406eA36B9a7CDeb8df06AeFa7E12d9",
    symbol: "SLEEPEE",
    name: "Sleep Future",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x80cd73badb406ea36b9a7cdeb8df06aefa7e12d9/386bda10609f6c125ed376c91150806c.png",
    coingeckoId: "sleepfuture",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x8094e772fA4A60bdEb1DfEC56AB040e17DD608D5",
    symbol: "KODA",
    name: "Koda Cryptocurrency",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8094e772fa4a60bdeb1dfec56ab040e17dd608d5/7999366de87ff61600812c6cc150b837.png",
    coingeckoId: "koda-finance",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3",
    symbol: "SAFEMOON",
    name: "SafeMoon",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3/logo.png",
    coingeckoId: "safemoon",
    listedIn: ["coingecko", "lifinance", "elkfinance"],
  },
  {
    address: "0x8038B1F3EB4f70436569618530Ac96b439D67bAe",
    symbol: "MCT",
    name: "Micro Tuber",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x8038b1f3eb4f70436569618530ac96b439d67bae/3d47eb8137b8605f43a0b83686ded250.png",
    coingeckoId: "microtuber",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7faAf8d4C411218415d9d3f82D56214658349dbb",
    symbol: "BTE",
    name: "Betero",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7faaf8d4c411218415d9d3f82d56214658349dbb/93f1d4d2a3b624fc88d39ce52797d8fa.png",
    coingeckoId: "betero",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7fFC1243232da3Ac001994208E2002816b57c669",
    symbol: "ZOO",
    name: "CryptoZoo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7ffc1243232da3ac001994208e2002816b57c669/995fd28a48195776a9399324446ebf2c.png",
    coingeckoId: "cryptozoo",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x7f9528b913A99989B88104b633D531241591A358",
    symbol: "CCF",
    name: "Cross Chain Farming",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7f9528b913a99989b88104b633d531241591a358/769387f3b57a914456487cd2a37e2cf6.png",
    coingeckoId: "cross-chain-farming",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7f479d78380Ad00341fdD7322fE8AEf766e29e5A",
    symbol: "WHIRL",
    name: "Whirl Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7f479d78380ad00341fdd7322fe8aef766e29e5a/d60174f4b20d5930f18d902e8df0f796.png",
    coingeckoId: "whirl-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7f12A37B6921FFac11FAB16338B3ae67EE0c462b",
    symbol: "TNODE",
    name: "Trusted Node",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7f12a37b6921ffac11fab16338b3ae67ee0c462b/8182af9d7f0c9f6917b96c371c4885d0.png",
    coingeckoId: "trusted-node",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7eDC0eC89F987ECd85617b891c44fE462a325869",
    symbol: "GST",
    name: "Gunstar Metaverse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7edc0ec89f987ecd85617b891c44fe462a325869/d510933b51a639b0ada7f4daf525f85a.png",
    coingeckoId: "gunstar-metaverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7e624FA0E1c4AbFD309cC15719b7E2580887f570",
    symbol: "POLS",
    name: "PolkastarterToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7e624fa0e1c4abfd309cc15719b7e2580887f570/62e5ecbec09c7b75df09c7f1140d1ee1.png",
    coingeckoId: "polkastarter",
    listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x7e52a123Ed6DB6Ac872A875552935fBbD2544c86",
    symbol: "SYL",
    name: "SYL",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7e52a123ed6db6ac872a875552935fbbd2544c86/19efdc1cda1a747c55a50ac919f4abd1.png",
    coingeckoId: "xsl-labs",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7e396BfC8a2f84748701167c2d622F041A1D7a17",
    symbol: "WMASS",
    name: "Wrapped MASS",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7e396BfC8a2f84748701167c2d622F041A1D7a17/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac",
    symbol: "VOLT",
    name: "Volt Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac/e4b5c0f4242972530978e93707134a42.png",
    coingeckoId: "volt-inu-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983",
    symbol: "Static",
    name: "Static",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7deb9906bd1d77b410a56e5c23c36340bd60c983/b9f086fc64a76621b589cb23be37d606.png",
    coingeckoId: "chargedefi-static",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7dDEE176F665cD201F93eEDE625770E2fD911990",
    symbol: "GALA",
    name: "GALA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/12493/small/GALA-COINGECKO.png?1600233435",
    coingeckoId: "gala",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0x7d99eda556388Ad7743A1B658b9C4FC67D7A9d74",
    symbol: "DEPO",
    name: "DePocket Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x7d99eda556388ad7743a1b658b9c4fc67d7a9d74/logo.png",
    coingeckoId: "depocket",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x7d95c863cfc17c640C5800c0B72a999B4ecBC8da",
    symbol: "BSC",
    name: "BABYSOCCER",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x7d8D4599d6b4066ebA50544DB2068465C8A14809",
    symbol: "BETHER",
    name: "Baby Ether",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x7d6c3F7aAAF01A91574c82B82E57B1F64fF48039",
    symbol: "INUB",
    name: "Inu Base",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7d6c3f7aaaf01a91574c82b82e57b1f64ff48039/79ae62649aadd37e2485d4fb0961aaf3.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x7c63F96fEAFACD84e75a594C00faC3693386FBf0",
    symbol: "ASS",
    name: "Australian Shepherd Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7c63f96feafacd84e75a594c00fac3693386fbf0/89944063399e2d4d97f089aeecd9b545.png",
    coingeckoId: "australian-safe-shepherd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7c1608C004F20c3520f70b924E2BfeF092dA0043",
    symbol: "CZF",
    name: "CZFarm",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7c1608c004f20c3520f70b924e2bfef092da0043/ddddb0277071507066cc3185f92dc90d.png",
    coingeckoId: "czfarm",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7be9E596896b64c88E39b7e88F8dceDDa79845C0",
    symbol: "BR",
    name: "BOHR",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7be9e596896b64c88e39b7e88f8dcedda79845c0/ec57ae4ad0a3409e9e43baaedf46951e.png",
    coingeckoId: "bohr-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7bd6FaBD64813c48545C9c0e312A0099d9be2540",
    symbol: "ELON",
    name: "Dogelon Mars",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x7bd6FaBD64813c48545C9c0e312A0099d9be2540.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd",
    symbol: "LIME",
    name: "iMe Lab",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7bc75e291e656e8658d66be1cc8154a3769a35dd/66c6d4ff4ad2c7b98a87da123569824e.png",
    coingeckoId: "ime-lab",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8",
    symbol: "IRON",
    name: "IRON Stablecoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7b65b489fe53fce1f6548db886c08ad73111ddd8/6929282297e16b9fc7af85f86c156d6c.png",
    coingeckoId: "iron-bsc",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7af173F350D916358AF3e218Bdf2178494Beb748",
    symbol: "TRADE",
    name: "Unitrade",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7af173F350D916358AF3e218Bdf2178494Beb748/logo.png",
    coingeckoId: "unitrade",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x7ad7242A99F21aa543F9650A56D141C57e4F6081",
    symbol: "JADE ",
    name: "JADE ",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7ad7242a99f21aa543f9650a56d141c57e4f6081/16433264d093ec9d1b53a84d0880fadd.png",
    coingeckoId: "jade-protocol",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x7FE378c5e0B5C32AF2ecc8829beDF02245A0E4eF",
    symbol: "STZ",
    name: "99Starz",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7fe378c5e0b5c32af2ecc8829bedf02245a0e4ef/b7d096480aa475d546d92447ff1577f3.png",
    coingeckoId: "99starz",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7F70642d88cf1C4a3a7abb072B53B929b653edA5",
    symbol: "YFII",
    name: "YFII.finance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7F70642d88cf1C4a3a7abb072B53B929b653edA5/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x7Ee7F14427cC41D6dB17829eb57Dc74A26796b9D",
    symbol: "MOONRISE",
    name: "MoonRise",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7ee7f14427cc41d6db17829eb57dc74a26796b9d/17845c7b63a7ce143e340971c9f4d886.png",
    coingeckoId: "moonrise",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7E839641381701b8b13671155FB774B1290fbd30",
    symbol: "PETH",
    name: "PetsHelp Coin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7e839641381701b8b13671155fb774b1290fbd30/5ac0a44b07d943b5dd6db6d38635e29a.png",
    coingeckoId: "petshelp",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7E35D0e9180bF3A1fc47b0d110bE7a21A10B41Fe",
    symbol: "OVR",
    name: "OVR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7e35d0e9180bf3a1fc47b0d110be7a21a10b41fe/84a37db9f8cdb97262e490dfd916eecc.png",
    coingeckoId: "ovr",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x7DFF85Eeb321957A429FA220Ec2664774240b766",
    symbol: "MEO",
    name: "Meo.tools",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7dff85eeb321957a429fa220ec2664774240b766/4f7c8589a103a978d3d367764d35f745.png",
    coingeckoId: "meo-tools",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7D89c67d3c4E72E8c5c64BE201dC225F99d16aCa",
    symbol: "RVZ",
    name: "Revoluzion",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7d89c67d3c4e72e8c5c64be201dc225f99d16aca/772ce290ba21ae528b90c181a67872fd.png",
    coingeckoId: "revoluzion",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7",
    symbol: "DKT",
    name: "Duelist King",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7ceb519718a80dd78a8545ad8e7f401de4f2faa7/9cd0b4fa8c918f565cdd1ea92a844ea7.png",
    coingeckoId: "duelist-king",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x7Cb2f28505E733F60C0db208AfaA321c792F6Cf4",
    symbol: "OPERAND",
    name: "Operand",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x7cb2f28505e733f60c0db208afaa321c792f6cf4.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x7B8779e01d117ec7e220f8299a6f93672E8eae23",
    symbol: "IMT",
    name: "IMOV",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7b8779e01d117ec7e220f8299a6f93672e8eae23/8d58ba94ec700ea2111eff22bbd791b7.png",
    coingeckoId: "imov",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7B86b0836f3454e50C6F6a190cd692bB17da1928",
    symbol: "BEM",
    name: "Bemil",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7b86b0836f3454e50c6f6a190cd692bb17da1928/b54ff19646ad2fcd2b62a50d3cebdb48.png",
    coingeckoId: "bemil-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7B0409A3A3f79bAa284035d48E1DFd581d7d7654",
    symbol: "RUPEE",
    name: "Rupee Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7b0409a3a3f79baa284035d48e1dfd581d7d7654/2e63e95fe23bb7e599abffeaa985fb30.png",
    coingeckoId: "hyruleswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x7AE5709c585cCFB3e61fF312EC632C21A5F03F70",
    symbol: "DogeDash",
    name: "DogeDash",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7ae5709c585ccfb3e61ff312ec632c21a5f03f70/596e61387145acdb9c83b6ea51f5ceb5.png",
    coingeckoId: "doge-dash",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0",
    symbol: "WATCH",
    name: "Yieldwatch",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0/logo.png",
    coingeckoId: "yieldwatch",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x7A565284572d03EC50c35396F7d6001252eb43B6",
    symbol: "DogeZilla",
    name: "DogeZilla",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7a565284572d03ec50c35396f7d6001252eb43b6/f78c8c60864221be44518250d6ad7a5d.png",
    coingeckoId: "dogezilla",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x79eBC9A2ce02277A4b5b3A768b1C0A4ed75Bd936",
    symbol: "CATGIRL",
    name: "CatGirl",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936/218cea79aeac0c5cb8a6e84b6b7fcb6e.png",
    coingeckoId: "catgirl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2",
    symbol: "KEBAB",
    name: "Kebab Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7979f6c54eba05e18ded44c4f986f49a5de551c2/b208f248ad7130ed698577136469a660.png",
    coingeckoId: "kebab-token",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x7978e2Cc23944478CbAe2ada925C79637860A218",
    symbol: "ODL",
    name: "One Dollar",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed",
    symbol: "QUIDD",
    name: "Quidd Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7961ade0a767c0e5b67dd1a1f78ba44f727642ed/12a669d9b0709e455f0e38300fec4f70.png",
    coingeckoId: "quidd",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x794Baab6b878467F93EF17e2f2851ce04E3E34C8",
    symbol: "YIN",
    name: "YIN Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x794baab6b878467f93ef17e2f2851ce04e3e34c8/e8e8c4edf4a3930fe19ebc3bb0bb3df4.png",
    coingeckoId: "yin-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x790CFDc6aB2e0eE45a433aAC5434F183BE1f6A20",
    symbol: "PLR",
    name: "PILLAR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x790cfdc6ab2e0ee45a433aac5434f183be1f6a20/c78d39ed72b44fdf49c972682e415574.png",
    coingeckoId: "pillar",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x790Be81C3cA0e53974bE2688cDb954732C9862e1",
    symbol: "BREW",
    name: "CafeSwap Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x790be81c3ca0e53974be2688cdb954732c9862e1/9ebdd8ed3f09d7d6d7b747f103fef51a.png",
    coingeckoId: "cafeswap-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x78e1936f065Fd4082387622878C7d11c9f05ECF4",
    symbol: "JNTR/b",
    name: "JNTR/b",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x78e1936f065fd4082387622878c7d11c9f05ecf4/f1164f6ba82ffd88dba258f5e958b5d1.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3",
    symbol: "APX",
    name: "ApolloX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x78f5d389f5cdccfc41594abab4b0ed02f31398b3/e338efac715bc29f277b48dd4ca83b84.png",
    coingeckoId: "apollox-2",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x78650B139471520656b9E7aA7A5e9276814a38e9",
    symbol: "BTCST",
    name: "Standard BTC Hashrate Token",
    decimals: 17,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x78650B139471520656b9E7aA7A5e9276814a38e9/logo.png",
    coingeckoId: "btc-standard-hashrate-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x7859B01BbF675d67Da8cD128a50D155cd881B576",
    symbol: "XMS",
    name: "Mars Ecosystem Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7859b01bbf675d67da8cd128a50d155cd881b576/3922b8d0678fe588d111abcc66fcef5a.png",
    coingeckoId: "mars-ecosystem-token",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x7837fd820bA38f95c54D6dAC4ca3751b81511357",
    symbol: "DOSE",
    name: "DOSE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7837fd820ba38f95c54d6dac4ca3751b81511357/cd7876f3c54614779cc003c16ef511c3.png",
    coingeckoId: "dose-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x77edFaE59a7948d66E9911A30cC787d2172343d4",
    symbol: "LBL",
    name: "LABEL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x77edfae59a7948d66e9911a30cc787d2172343d4/eff2aa368516b3cecda7aea9fc2340ed.png",
    coingeckoId: "label-foundation",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x77d547256A2cD95F32F67aE0313E450Ac200648d",
    symbol: "LAZIO",
    name: "FC Lazio Fan Token",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x77d547256a2cd95f32f67ae0313e450ac200648d/f510acba389bcbd1286b83939dfd172c.png",
    coingeckoId: "lazio-fan-token",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x7769d930BC6B087f960C5D21e34A4449576cf22a",
    symbol: "OCEANS",
    name: "Oceans Finance",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7769d930bc6b087f960c5d21e34a4449576cf22a/618b198f19490d9ebd2d66cc851cdf17.png",
    coingeckoId: "oceans-finance",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x7730ac665114c0e343B141dAaaeB097197191F8a",
    symbol: "ElonHype",
    name: "ElonHype",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7730ac665114c0e343b141daaaeb097197191f8a/a10a5ef180af28851440ba853ff629c3.png",
    coingeckoId: "elonhype",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7728cd70b3dD86210e2bd321437F448231B81733",
    symbol: "NIFTSY",
    name: "NIFTSY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7728cd70b3dd86210e2bd321437f448231b81733/252f18e4322d55cab8971dde7e386a1d.png",
    coingeckoId: "niftsy",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7700Edc3DBb30cBB7603212E061c804220c3cA54",
    symbol: "Quantic",
    name: "Quantic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7700edc3dbb30cbb7603212e061c804220c3ca54/fbd96ac6f91fdd99adb587cf8bdd45a4.png",
    coingeckoId: "quantic",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x76A797A59Ba2C17726896976B7B3747BfD1d220f",
    symbol: "TONCOIN",
    name: "Wrapped TON Coin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x76a797a59ba2c17726896976b7b3747bfd1d220f/71f89c6c8cd13cc99d62a263027ef074.png",
    coingeckoId: "the-open-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x767B28a30E3a15DCEcE7bFF7a020ADfdE9D19cf8",
    symbol: "MRXb",
    name: "Wrapped Metrix",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x767b28a30e3a15dcece7bff7a020adfde9d19cf8/a1a9d63998434ff6c1631df7e530390a.png",
    coingeckoId: "wrapped-metrix",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x766AFcf83Fd5eaf884B3d529b432CA27A6d84617",
    symbol: "BLID",
    name: "Bolide",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x766afcf83fd5eaf884b3d529b432ca27a6d84617/29d8c16759ad58e5017e8cc8266b69d7.png",
    coingeckoId: "bolide",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x762539b45A1dCcE3D36d080F74d1AED37844b878",
    symbol: "LINA",
    name: "Linear Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x762539b45A1dCcE3D36d080F74d1AED37844b878/logo.png",
    coingeckoId: "linear",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x75f53011f6D51C60e6DCbf54A8B1bCB54f07F0C9",
    symbol: "GLMS",
    name: "Glimpse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x75f53011f6d51c60e6dcbf54a8b1bcb54f07f0c9/d867f3f237316376577fb88672cf48c6.png",
    coingeckoId: "glimpse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x75aFA9915B2210Cd6329E820af0365e932bC1dd5",
    symbol: "SMURF",
    name: "Smurfs INU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x75afa9915b2210cd6329e820af0365e932bc1dd5/d014a317b22fbe5b9a7a10b11e09414f.png",
    coingeckoId: "smurfsinu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x75D107De2217FFe2cD574A1b3297C70C8FAfD159",
    symbol: "TRY",
    name: "TryHards",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x75d107de2217ffe2cd574a1b3297c70c8fafd159/4f2cc5eebb941e8f63def04d0d12eb0e.png",
    coingeckoId: "tryhards",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x758d08864fB6cCE3062667225ca10b8F00496cc2",
    symbol: "NAOS",
    name: "NAOS Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x758d08864fb6cce3062667225ca10b8f00496cc2/9f785030338c2f4196d4592b2bf702ff.png",
    coingeckoId: "naos-finance",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x758FB037A375F17c7e195CC634D77dA4F554255B",
    symbol: "DVI",
    name: "Dvision Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x758FB037A375F17c7e195CC634D77dA4F554255B/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x755f34709E369D37C6Fa52808aE84A32007d1155",
    symbol: "NABOX",
    name: "Nabox Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x755f34709e369d37c6fa52808ae84a32007d1155/7ffa31033261210608fd9cf2cc80424e.png",
    coingeckoId: "nabox",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x7509DB061C45e8EcEb01739D104f78F85eF22Dbf",
    symbol: "COSMIK",
    name: "Cosmik Finance",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7509db061c45e8eceb01739d104f78f85ef22dbf/3681d382732ed3bb7e7a38064f4a6444.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x74B3abB94e9e1ECc25Bd77d6872949B4a9B2aACF",
    symbol: "DFX",
    name: "DeFireX on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x74A6E371F95073005b3a5fAF4A9E25aE30290F94",
    symbol: "CATBREAD",
    name: "CatBread",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x74a6e371f95073005b3a5faf4a9e25ae30290f94/9483d9ec40b774f0926cd1133b17d09d.png",
    coingeckoId: "catbread",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x74926B3d118a63F6958922d3DC05eB9C6E6E00c6",
    symbol: "DOGGY",
    name: "DOGGY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6/f33b37b353c82efe138bf812839982cb.png",
    coingeckoId: "doggy",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x748AD98b14C814B28812eB42ad219C8672909879",
    symbol: "DICE",
    name: "Dice.finance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x748ad98b14c814b28812eb42ad219c8672909879/165cba44bc51b19e3dea67abcb69e5bf.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x747D74dB20cc422F39ab54EDB2A3Ce21f3C98AF1",
    symbol: "CGU",
    name: "Crypto Gaming United",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x747d74db20cc422f39ab54edb2a3ce21f3c98af1/fefbd6ddce860baee2a6ff8342c57d6c.png",
    coingeckoId: "crypto-gaming-united",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x747B1223B23E53070c54Df355FAC2E198aA54708",
    symbol: "WSYS",
    name: "Wrapped SYS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x747b1223b23e53070c54df355fac2e198aa54708/0947f1dd12fe853613cd8552ccc18fd5.png",
    coingeckoId: "wrapped-syscoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x739408E1dEc3b60Cd03Fa76948c079f94f422366",
    symbol: "METAXA",
    name: "Metaxa",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x739408e1dec3b60cd03fa76948c079f94f422366/6119abbfaa85bd053732458acfeab4bb.png",
    coingeckoId: "metaxa",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C",
    symbol: "aMATICc",
    name: "Ankr MATIC Reward Bearing Certificate",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1653739507057_6345922838275662.png",
    coingeckoId: "ankr-reward-earning-matic",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x734D66f635523D7ddb7d2373C128333DA313041b",
    symbol: "USDZ",
    name: "ZEDXION",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x734d66f635523d7ddb7d2373c128333da313041b/3c182e640d8eabb39f9ea2c8f3b72f3d.png",
    coingeckoId: "zedxion-usdz",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB",
    symbol: "PEEL",
    name: "Meta Apes Peel",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x734548a9e43d2d564600b1b2ed5be9c2b911c6ab/7a2244d7140cff76fe139fb50fb94e1e.png",
    coingeckoId: "meta-apes-peel",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x73419De8E3E26A17e0E6b0436e29dd04A25B061D",
    symbol: "MIKU",
    name: "MIKU",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x73419de8e3e26a17e0e6b0436e29dd04a25b061d/8b47e635a940b03e42f11f949b6caffb.png",
    coingeckoId: "miku",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b",
    symbol: "XCN",
    name: "Chain",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b/14706ee051f7c4056ea1149b617c05b2.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x72fAa679E1008Ad8382959FF48E392042A8b06f7",
    symbol: "bALBT",
    name: "AllianceBlock",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x72fAa679E1008Ad8382959FF48E392042A8b06f7/logo.png",
    coingeckoId: null,
    listedIn: [
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x72f28c09be1342447fa01Ebc76eF508473d08c5c",
    symbol: "DGN",
    name: "https://degen.trade/",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x72f28c09be1342447fa01ebc76ef508473d08c5c/a5528d0d9e4528cef477e099198a23ee.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
    symbol: "UNFI",
    name: "Unifi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B/logo.png",
    coingeckoId: "unifi-protocol-dao",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x7269d98Af4aA705e0B1A5D8512FadB4d45817d5a",
    symbol: "SHI",
    name: "Shirtum",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7269d98af4aa705e0b1a5d8512fadb4d45817d5a/3f96cd6c8b1f3a9d4a5641be5ba34fe9.png",
    coingeckoId: "shirtum",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x723eF00502e76E8501101De1b25ef146C4b571CA",
    symbol: "LOCO",
    name: "LocoMeta",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x723ef00502e76e8501101de1b25ef146c4b571ca/edc5b935174afafdb8991039fa5195d8.png",
    coingeckoId: "locometa",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x71c300E7B6D16cf3Cdc4695fa5Ba713aE636eD9e",
    symbol: "P3D",
    name: "PoWH3D",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739",
    symbol: "SWINGBY",
    name: "Swingby",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739/logo.png",
    coingeckoId: "swingby",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0",
    symbol: "AXS",
    name: "Axie Infinity Shard",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0/logo.png",
    coingeckoId: "axie-infinity",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    symbol: "BTCB",
    name: "Binance Pegged Bitcoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
    coingeckoId: "binance-bitcoin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x7120f43220D1C77cb821DDE0420293491d03F854",
    symbol: "ALT",
    name: "AltCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x7120f43220d1c77cb821dde0420293491d03f854/5619885bd7e03f84f03b4e34d2ca26b6.png",
    coingeckoId: "alt-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x70905594023Cb2f37F3103fDc70315ad1601D6EF",
    symbol: "POLARv3",
    name: "Polaris",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x70905594023cb2f37f3103fdc70315ad1601d6ef/21d512ef8b46691d12310feb2a707965.png",
    coingeckoId: "polaris",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    symbol: "DOT",
    name: "Polkadot Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402/logo.png",
    coingeckoId: "binance-peg-polkadot",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4",
    symbol: "BHC",
    name: "Billion Happiness",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4/0d9a82af09a54a392b3c7219f55b03ab.png",
    coingeckoId: "billionhappiness",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9",
    symbol: "ERA",
    name: "Era Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6f9f0c4ad9af7ebd61ac5a1d4e0f2227f7b0e5f9/555a88e25dd69c48ce37077db1e78d95.png",
    coingeckoId: "era7",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x6ef238E9E8CD2A96740897761C18894Fc086B9d0",
    symbol: "MYRA",
    name: "Mytheria",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6ef238e9e8cd2a96740897761c18894fc086b9d0/a3a603a7e14a92547d434571cbd8c026.png",
    coingeckoId: "mytheria",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6e8Ff72962750F0Fa57A906F7833d1C657614abE",
    symbol: "CSR",
    name: "Cashera",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6e8ff72962750f0fa57a906f7833d1c657614abe/b48dd21b669409ec7d38c30ce289747e.png",
    coingeckoId: "cashera",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6e2a5EA25B161Befa6A8444C71ae3A89C39933c6",
    symbol: "B2M",
    name: "Bit2Me",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6e2a5ea25b161befa6a8444c71ae3a89c39933c6/85cf78a2b7bffeb79a876683dd7e0504.png",
    coingeckoId: "bit2me",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6e0BEF56b648b3eEBaE3808B8cBe7EFE8755AD9C",
    symbol: "GABECOIN",
    name: "gabecoin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6e0bef56b648b3eebae3808b8cbe7efe8755ad9c/bfe69535edecc7b05e94f4758a0c2247.png",
    coingeckoId: "gabecoin",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6dc3d0D6EC970BF5522611D8eFF127145D02b675",
    symbol: "RVL",
    name: "Revolotto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6dc3d0d6ec970bf5522611d8eff127145d02b675/c46608ac103058737df46dcb244ccaca.png",
    coingeckoId: "revolotto",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e",
    symbol: "DRIVENx",
    name: "DVX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6db3972c6a5535708e7a4f7ad52f24d178d9a93e/9cfb1f5fb1e83524e5c96b40f52b5936.png",
    coingeckoId: "drivenx",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x6cf271270662be1C4fc1b7BB7D7D7Fc60Cc19125",
    symbol: "KUNCI",
    name: "Kunci Coin",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6cf271270662be1c4fc1b7bb7d7d7fc60cc19125/87737ecae819e7b73d825e3edb921b52.png",
    coingeckoId: "kunci-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819",
    symbol: "ADX",
    name: "AdEx Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819/logo.png",
    coingeckoId: "adex",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x6b51231c43B1604815313801dB5E9E614914d6e4",
    symbol: "SAFEGALAXY",
    name: "SafeGalaxy",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x6b51231c43b1604815313801db5e9e614914d6e4.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc",
    symbol: "XWG",
    name: "X World Games",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6b23c89196deb721e6fd9726e6c76e4810a464bc/20307dfdf67e8119c15d0cec9945c304.png",
    coingeckoId: "x-world-games",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x6b1C8765C7EFf0b60706b0ae489EB9bb9667465A",
    symbol: "SATA",
    name: "Signata",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6b1c8765c7eff0b60706b0ae489eb9bb9667465a/bc26bb7ead286c26114791a75c7c07d9.png",
    coingeckoId: "signata",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6a59e047Bd8AFa31a175B2056b44D1235AD4D852",
    symbol: "ECON",
    name: "Recession Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6a59e047bd8afa31a175b2056b44d1235ad4d852/1e7d8d60dc13af9298dd11057466d6b2.png",
    coingeckoId: "recession-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6a0b66710567b6beb81A71F7e9466450a91a384b",
    symbol: "PEX",
    name: "PearDAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6a0b66710567b6beb81a71f7e9466450a91a384b/bb3a010fb87b9eb39e76d2be5676808c.png",
    coingeckoId: "peardao",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x6Ff2d9e5891a7a7c554b80e0D1B791483C78BcE9",
    symbol: "WAULT",
    name: "Wault Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9/7160ca66610e89f20702880bbbc667df.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888",
    symbol: "VET",
    name: "VeChain",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6fdcdfef7c496407ccb0cec90f9c5aaa1cc8d888/87f9a87d37427bd854237d402257ec22.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x6F817a0cE8F7640Add3bC0c1C2298635043c2423",
    symbol: "anyETH",
    name: "ANY Ethereum",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6f817a0ce8f7640add3bc0c1c2298635043c2423/f105817560ecac555ca806b714133093.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e",
    symbol: "ARPA",
    name: "ARPA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e/logo.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x6Ec90334d89dBdc89E08A133271be3d104128Edb",
    symbol: "WKC",
    name: "WIKI CAT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6ec90334d89dbdc89e08a133271be3d104128edb/8146f30e5618bb70c274b2fc1bc5ba8f.png",
    coingeckoId: "wiki-cat",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6E3bF2fFf13e18413D3780f93753D6CFf5AEE3e1",
    symbol: "POLP",
    name: "PolkaParty",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6e3bf2fff13e18413d3780f93753d6cff5aee3e1/da0736c19d748944bcc076161a78b573.png",
    coingeckoId: "polkaparty",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6Da5BFe611853036B507fad9E9D1717CD5c34915",
    symbol: "ORCAS",
    name: "Orcinus",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x6D949f9297A522c0f97C232CC209a67Bd7CfA471",
    symbol: "MRAT",
    name: "Moon Rat Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x6d949f9297a522c0f97c232cc209a67bd7cfa471.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x6D3a160B86eDcD46D8F9bBa25c2F88ccCADe19fc",
    symbol: "Fwc",
    name: "QATAR 2022 TOKEN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6d3a160b86edcd46d8f9bba25c2f88cccade19fc/92311b28f11a3393efe0ef685f96a776.png",
    coingeckoId: "qatar-2022",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6C5FF116bFFc50Ff59d0334d5643e15b71C6414A",
    symbol: "THS",
    name: "THEOSCOIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6c5ff116bffc50ff59d0334d5643e15b71c6414a/c41234788a4faea52292b3e26176cc25.png",
    coingeckoId: "theoscoin",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6B9481FB5465ef9Ab9347b332058D894aB09B2f6",
    symbol: "DNL",
    name: "Dinoland Metaverse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6b9481fb5465ef9ab9347b332058d894ab09b2f6/8404637bdf4fc6b3bbd0bbda084eed1f.png",
    coingeckoId: "dinoland",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6Ae9701B9c423F40d54556C9a443409D79cE170a",
    symbol: "POLC",
    name: "Polka City",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6ae9701b9c423f40d54556c9a443409d79ce170a/04638216849af17023148d7be6b798d8.png",
    coingeckoId: "polka-city",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x6AA217312960A21aDbde1478DC8cBCf828110A67",
    symbol: "SPIN",
    name: "Spintop",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6aa217312960a21adbde1478dc8cbcf828110a67/2a4f96b097717c6e9b5e86e5775c4ec9.png",
    coingeckoId: "spintop",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51",
    symbol: "NRCH",
    name: "EnreachDAO",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51/08dd5c7add08307df23c28ee35838043.png",
    coingeckoId: "enreachdao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x69b14e8D3CEBfDD8196Bfe530954A0C226E5008E",
    symbol: "SpacePi",
    name: "SpacePi Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x69b14e8d3cebfdd8196bfe530954a0c226e5008e/c139bb4b8e865f18e8e603c403efcb54.png",
    coingeckoId: "spacepi",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x69B0af16FDd2E80968eb505cd41DC26efb2B80BF",
    symbol: "BBL",
    name: "BlockBlend",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x69b0af16fdd2e80968eb505cd41dc26efb2b80bf/b78c3d2fa886de45d700fff2815269d4.png",
    coingeckoId: "blockblend",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d",
    symbol: "SNP",
    name: "SynapseNetwork",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d/5f75a4ecb6252f88e5c19f37597b2c6f.png",
    coingeckoId: "synapse-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x69083b64988933E8B4783E8302b9bBf90163280E",
    symbol: "PFY",
    name: "Portify",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x69083b64988933e8b4783e8302b9bbf90163280e/69578634011dd5bdb76539b8d16bd5ee.png",
    coingeckoId: "portify",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x68b5edb385b59E30a7A7Db1E681a449E94DF0213",
    symbol: "SILVA",
    name: "Silva Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x68b5edb385b59e30a7a7db1e681a449e94df0213/logo.png",
    coingeckoId: "silva-token",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x68E374F856bF25468D365E539b700b648Bf94B67",
    symbol: "MIST",
    name: "Mist",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x68e374f856bf25468d365e539b700b648bf94b67/39c75532e149a99140363b2148dea2bc.png",
    coingeckoId: "mist",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x68590a47578E5060a29fd99654f4556dBfa05D10",
    symbol: "SMRAT",
    name: "Secured MoonRat Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x68590a47578e5060a29fd99654f4556dbfa05d10/b0d666d659804b87a12640b517ec993a.png",
    coingeckoId: "secured-moonrat-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6855f7bb6287F94ddcC8915E37e73a3c9fEe5CF3",
    symbol: "STACK",
    name: "StackOS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3/2457b1e67f592ef87edcaf09b41d0b1d.png",
    coingeckoId: "stackos",
    listedIn: ["coingecko", "1inch", "openocean", "dfyn", "lifinance"],
  },
  {
    address: "0x683fae4411249Ca05243dfb919c20920f3f5bfE0",
    symbol: "GHC",
    name: "Galaxy Heroes Coin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x683fae4411249ca05243dfb919c20920f3f5bfe0/21422aa29e7c8df1092a954570e2999f.png",
    coingeckoId: "galaxy-heroes-coin",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x682cA13182eCA9e5230d8654215037815288B556",
    symbol: "POOR",
    name: "PoorQUACK.com",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x682ca13182eca9e5230d8654215037815288b556/55b09cae33a389551986831c3b55610b.png",
    coingeckoId: "poor-quack",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x681fd3e49a6188Fc526784eE70AA1C269ee2B887",
    symbol: "FLy",
    name: "Franklin",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x681fd3e49a6188fc526784ee70aa1c269ee2b887/901d023f49cadd60b028895e1c039912.png",
    coingeckoId: "franklin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2",
    symbol: "DODO",
    name: "Dodo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2/logo.png",
    coingeckoId: "dodo",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493",
    symbol: "FEED",
    name: "Feeder.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493/ad5dfcce9cafe1da06c83cae71784e09.png",
    coingeckoId: "feeder-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x67d012F731c23F0313CEA1186d0121779c77fcFE",
    symbol: "SOUL",
    name: "APOyield SOULS",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x67d012f731c23f0313cea1186d0121779c77fcfe/a316479aa7af626a6f02c6e546fc0db2.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x67b725d7e342d7B611fa85e859Df9697D9378B2e",
    symbol: "SAND",
    name: "The Sandbox",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x67b725d7e342d7B611fa85e859Df9697D9378B2e.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x67Fe403A75d871b992143fa15474f542a7892A6e",
    symbol: "PEECOIN",
    name: "PeeCoin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x67fe403a75d871b992143fa15474f542a7892a6e/ba40cce03869194fc6df42750fceca73.png",
    coingeckoId: "peecoin-charts",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x679D5b2d94f454c950d683D159b87aa8eae37C9e",
    symbol: "HAM",
    name: "Hamster",
    decimals: 7,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x679d5b2d94f454c950d683d159b87aa8eae37c9e/3c1cc3d4ee149253724e715879e931a7.png",
    coingeckoId: "hamster",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x678C109Bc4A812A80550707a4062442C0Ca0D2Ca",
    symbol: "BUSDM",
    name: "BUSDMeta",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x67678538f1dC3AB1da94ca8D048B5670B3961cef",
    symbol: "AGEN",
    name: "Alpha Genesis",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x67678538f1dc3ab1da94ca8d048b5670b3961cef/c8da5e9e118dad49eba81b9b814a1d7d.png",
    coingeckoId: "alpha-genesis",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x66eFF5221ca926636224650Fd3B9c497FF828F7D",
    symbol: "sSPELL",
    name: "Staked Spell Tokens",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x66E1e0EA96aBB155932aF031d41Bf1F21b235958",
    symbol: "RD2E",
    name: "Ride To Earn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x66e1e0ea96abb155932af031d41bf1f21b235958/53aaafe448f2ee75f8aea9ff16d69bda.png",
    coingeckoId: "ride-to-earn",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x66Cafcf6C32315623C7fFd3f2FF690aa36EBeD38",
    symbol: "BRKL",
    name: "Brokoli Token [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x66cafcf6c32315623c7ffd3f2ff690aa36ebed38/9b0470604cc4957402d0fd86fe90a6ae.png",
    coingeckoId: "brokoli",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6679eB24F59dFe111864AEc72B443d1Da666B360",
    symbol: "ARV",
    name: "ARIVA",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6679eb24f59dfe111864aec72b443d1da666b360/b07a74488f5900e8d539a72d5979b6c1.png",
    coingeckoId: "ariva",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0x666666661f9B6D8c581602AAa2f76cbead06C401",
    symbol: "XY",
    name: "XY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622",
    coingeckoId: "xy-finance",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0x66109633715d2110ddA791E64a7B2afadb517aBB",
    symbol: "GAME",
    name: "Game Coin [via ChainPort.io]",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x66109633715d2110dda791e64a7b2afadb517abb/c3331ba34d19017f150805eccff602c4.png",
    coingeckoId: "gamestarter",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC",
    symbol: "GFX",
    name: "GamyFi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x65ad6a2288b2dd23e466226397c8f5d1794e58fc/60d542fc7be5b9c54b59fa8b298b5f76.png",
    coingeckoId: "gamyfi-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x658E64FFcF40D240A43D52CA9342140316Ae44fA",
    symbol: "OIN",
    name: "Oin Finance",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x658E64FFcF40D240A43D52CA9342140316Ae44fA/logo.png",
    coingeckoId: "oin-finance",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x658A109C5900BC6d2357c87549B651670E5b0539",
    symbol: "FOR",
    name: "ForTube",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x658A109C5900BC6d2357c87549B651670E5b0539/logo.png",
    coingeckoId: "force-protocol",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x650b940a1033B8A1b1873f78730FcFC73ec11f1f",
    symbol: "vLINK",
    name: "Venus LINK",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x650b940a1033b8a1b1873f78730fcfc73ec11f1f/4b8697aefb57d69a32ec6fa1289be53e.png",
    coingeckoId: "venus-link",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x64c5F47e530841684a4EeF408e9a4366585B88F9",
    symbol: "IMV",
    name: "ImminentVerse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x64c5f47e530841684a4eef408e9a4366585b88f9/6cf479ba4c06409b31c7e58c6e0f43db.png",
    coingeckoId: "imminentverse",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x64D5BaF5ac030e2b7c435aDD967f787ae94D0205",
    symbol: "GTON",
    name: "Graviton",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x64d5baf5ac030e2b7c435add967f787ae94d0205/e3dc0a16ad5cd9a9d75b371ad5b18307.png",
    coingeckoId: "gton-capital",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x64619f611248256F7F4b72fE83872F89d5d60d64",
    symbol: "QUINT",
    name: "QUINT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x64619f611248256f7f4b72fe83872f89d5d60d64/c40fbf224da202294e6c9e8c0a441892.png",
    coingeckoId: "quint",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6458df5d764284346c19D88A104Fd3D692471499",
    symbol: "iUSDS",
    name: "Inflation Adjusted Spice USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6458df5d764284346c19d88a104fd3d692471499/89c3457b0b3d7bd755d5ea338d2170e3.png",
    coingeckoId: "inflation-adjusted-euro",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6451C6484D23889003C20BE51819D6Aa7dbd2b35",
    symbol: "PS1",
    name: "PolysportsToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6451c6484d23889003c20be51819d6aa7dbd2b35/0fa2c3793fd3efd56648baded43b19b7.png",
    coingeckoId: "polysports",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6421531AF54C7B14Ea805719035EBf1e3661c44A",
    symbol: "bLEO",
    name: "BEP20 LEO",
    decimals: 3,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6421531af54c7b14ea805719035ebf1e3661c44a/4b6bfd899f4251325c1a68cb653cab2c.png",
    coingeckoId: "bep20-leo",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x63a18BC38D1101DB7F0efCbCBdCbe927A5879039",
    symbol: "EARN",
    name: "Yearn Classic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x63a18bc38d1101db7f0efcbcbdcbe927a5879039/e1c4b35135086faa591665721116c665.png",
    coingeckoId: "yearn-classic-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6397de0F9aEDc0F7A8Fa8B438DDE883B9c201010",
    symbol: "SIN",
    name: "SinCity Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x6397de0f9aedc0f7a8fa8b438dde883b9c201010/logo.png",
    coingeckoId: "sin-city",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x638EEBe886B0e9e7C6929E69490064a6C94d204d",
    symbol: "HEC",
    name: "Hector",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x638eebe886b0e9e7c6929e69490064a6c94d204d/08244535303774ed72b563dfab418926.png",
    coingeckoId: "hector-dao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F",
    symbol: "BLK",
    name: "BLINk",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x63290fC683d11ea077abA09596Ff7387D49dF912",
    symbol: "RAM",
    name: "Ramifi Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x63290fc683d11ea077aba09596ff7387d49df912/cc90399b7f8c933a2f754377191065e1.png",
    coingeckoId: "ramifi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78",
    symbol: "PEAK",
    name: "PEAKDEFI",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x630d98424efe0ea27fb1b3ab7741907dffeaad78/5041884fa975d5aa7e60caf9095d7388.png",
    coingeckoId: "marketpeak",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f",
    symbol: "mGOOGL",
    name: "Mirror GOOGL Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x62858686119135cc00C4A3102b436a0eB314D402",
    symbol: "METAV",
    name: "METAVPAD.com",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x62858686119135cc00c4a3102b436a0eb314d402/b82ffb0ad17ac939df31d46348876dca.png",
    coingeckoId: "metavpad",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x62823659d09F9F9D2222058878f89437425eB261",
    symbol: "ERTHA",
    name: "Ertha Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x62823659d09f9f9d2222058878f89437425eb261/cfe565d2e8e1f245fe879257f64f77d0.png",
    coingeckoId: "ertha",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x627E86E9eC832b59018Bf91456599e752288Aa97",
    symbol: "GALE",
    name: "Gale Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x627e86e9ec832b59018bf91456599e752288aa97/a1e24e118dcfceaf44a4efdf3931cd67.png",
    coingeckoId: "gale-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x6266a18F1605DA94e8317232ffa634C74646ac40",
    symbol: "MFX",
    name: "METFX Watch-To-Earn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6266a18f1605da94e8317232ffa634c74646ac40/7b44ec1ef2401b6ff008f9ff53399362.png",
    coingeckoId: "metfx-watch-to-earn",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x623974FA31D79d12dC8a2EC8DFEa9BCDF8938889",
    symbol: "AngeL",
    name: "Angel Nodes",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x623974fa31d79d12dc8a2ec8dfea9bcdf8938889/07db346ce72e89e4ed4719587c1dd025.png",
    coingeckoId: "angel-nodes",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x622A1297057ea233287ce77bdBF2AB4E63609F23",
    symbol: "$SUPDOG",
    name: "SUPERDOGE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x622a1297057ea233287ce77bdbf2ab4e63609f23/491b1852eb20b264bb36f1429d1bfafe.png",
    coingeckoId: "superdoge",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x61fA01129aC0bB124D1C60Dc9f735C6C579A858B",
    symbol: "KTE",
    name: "KyteOne",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x61fa01129ac0bb124d1c60dc9f735c6c579a858b/020fdc2d788402552e328e98238836f4.png",
    coingeckoId: "kyte-one",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x61D5822DD7b3Ed495108733e6550d4529480C8F6",
    symbol: "GCAKE",
    name: "Pancake GAMES",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x61d5822dd7b3ed495108733e6550d4529480c8f6/a1a08fd6a5d8cd5e6b8c54c81aea2dfb.png",
    coingeckoId: "pancake-games",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x617724974218A18769020A70162165A539c07E8a",
    symbol: "OLIVE",
    name: "OLIVE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x617724974218A18769020A70162165A539c07E8a/logo.png",
    coingeckoId: "olivecash",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x6169b3b23e57dE79a6146A2170980cEb1F83b9e0",
    symbol: "VETTER",
    name: "Vetter Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x6169b3b23e57de79a6146a2170980ceb1f83b9e0/f64427d1833c5c4cb5683257c3737ddf.png",
    coingeckoId: "vetter-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x612E1726435fE38dD49A0B35b4065B56f49c8F11",
    symbol: "CCV2",
    name: "CryptoCart V2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x612e1726435fe38dd49a0b35b4065b56f49c8f11/logo.png",
    coingeckoId: "cryptocart",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95",
    symbol: "BANANA",
    name: "Apeswap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95/logo.png",
    coingeckoId: "apeswap-finance",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x60322971a672B81BccE5947706D22c19dAeCf6Fb",
    symbol: "MDAO",
    name: "MarsDAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x60322971a672b81bcce5947706d22c19daecf6fb/cb4419dca369d47f5c43957600224d88.png",
    coingeckoId: "marsdao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5fb4968fC85868DF3aD2d6e59883a10570f01D18",
    symbol: "SHR",
    name: "ShareToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/3609/large/74586729_2443914875881351_2785018663453851648_n.png",
    coingeckoId: "sharering",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5fE80d2CD054645b9419657d3d10d26391780A7B",
    symbol: "MCB",
    name: "MCDEX Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5fe80d2cd054645b9419657d3d10d26391780a7b/60bdacd5f4ca79bd62292fc9df937f83.png",
    coingeckoId: "mcdex",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b",
    symbol: "TSA",
    name: "TEAsWAP.ART",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5f99acf13caff815dd9cb4a415c0fb34e9f4545b/a6de8fb2be3bd4cf7c575f4de1a92777.png",
    coingeckoId: "teaswap-art",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5f588EfAf8eB57e3837486e834fC5a4E07768D98",
    symbol: "bMVL",
    name: "Binance Wrapped MVL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5f588efaf8eb57e3837486e834fc5a4e07768d98/9cd4610317197123e91db5475b083c91.png",
    coingeckoId: "mass-vehicle-ledger",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63",
    symbol: "HIGH",
    name: "Highstreet Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63/15303468e57cdd60cb5d313685a7a2aa.png",
    coingeckoId: "highstreet",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x5f2Caa99Fc378248Ac02CbbAaC27e3Fa155Ed2C4",
    symbol: "JNTR",
    name: "Jointer",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5f2caa99fc378248ac02cbbaac27e3fa155ed2c4/bcdfc779b069706035ef41e43c5b1ef6.png",
    coingeckoId: "jointer",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd",
    symbol: "ALLOY",
    name: "HyperAlloy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd/bb3afe88ee421255b3c8be0aab3aff46.png",
    coingeckoId: "hyperalloy",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x5de3939b2F811A61d830E6F52d13B066881412ab",
    symbol: "XPR",
    name: "Proton",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x5de3939b2f811a61d830e6f52d13b066881412ab/logo.png",
    coingeckoId: "proton",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3",
    symbol: "LIEN",
    name: "Lien",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3/logo.png",
    coingeckoId: "lien",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5cd50Aae14E14B3fdF3fF13c7A40e8cf5ae8b0A5",
    symbol: "zSEED",
    name: "zSeedToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0x5c865352F9012308378B1c74fE01D899f28e8FD9",
    symbol: "OCCT",
    name: "Official Crypto Cowboy Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/20302/large/OCCT-SPROCCT_200x200.PNG",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x5c6Fb802F173Dba15E2CAada433032B1368aF59f",
    symbol: "BSCGIRL",
    name: "Binance Smart Chain Girl",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x5c6fb802f173dba15e2caada433032b1368af59f/logo.png",
    coingeckoId: "binance-smart-chain-girl",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389",
    symbol: "ACSI",
    name: "ACryptoS(I)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389/7b468711632a9f32097b3267aabd54b6.png",
    coingeckoId: "acryptosi",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x5a4fb10e7C4Cbb9a2b9d9A942f9a875EbD3489ea",
    symbol: "CRBN",
    name: "Carbon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5a4fb10e7c4cbb9a2b9d9a942f9a875ebd3489ea/b1cae7cf6ea2ee95a5ffb61890928e38.png",
    coingeckoId: "carbon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3",
    symbol: "BTR",
    name: "Bittrue",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5F88AB06e8dfe89DF127B2430Bba4Af600866035",
    symbol: "KAVA",
    name: "KAVA",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5F88AB06e8dfe89DF127B2430Bba4Af600866035/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5F84ce30DC3cF7909101C69086c50De191895883",
    symbol: "VRT",
    name: "Venus Reward Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5F84ce30DC3cF7909101C69086c50De191895883/logo.png",
    coingeckoId: "venus-reward-token",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5F0388EBc2B94FA8E123F404b79cCF5f40b29176",
    symbol: "vBCH",
    name: "Venus BCH",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176/743b9a15bffae5ecc521041737276349.png",
    coingeckoId: "venus-bch",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x5EcFcced226bA9fCcD8663e7b3263cBd8C84eDB5",
    symbol: "XTR",
    name: "Xtremcoin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5ecfcced226ba9fccd8663e7b3263cbd8c84edb5/f551fbf66c05312476a57a728b4e6932.png",
    coingeckoId: "xtremcoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E",
    symbol: "CVP",
    name: "Concentrated Voting Power",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x5EbA288F8833071bA7Cd5C2B356754811c0CE8Fb",
    symbol: "DUSD",
    name: "DUSD Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5eba288f8833071ba7cd5c2b356754811c0ce8fb/bdcf4773285c6f14f374fb6769edbd4c.png",
    coingeckoId: "dusd-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f",
    symbol: "HTD",
    name: "HeroesTD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5e2689412fae5c29bd575fbe1d5c1cd1e0622a8f/c3c6a22692d26f4d8886291c21cab85a.png",
    coingeckoId: "heroes-td",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x5E0691Ba91E21f3FDc88a0550Aa4f7304eD89b5C",
    symbol: "DGCL",
    name: "DigiCol Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1636266071547_15035687284769095.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x5D186E28934c6B0fF5Fc2feCE15D1F34f78cBd87",
    symbol: "DUCK",
    name: "DLP Duck Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5d186e28934c6b0ff5fc2fece15d1f34f78cbd87/baf1228af5f0d5b183e212fafc685bc9.png",
    coingeckoId: "dlp-duck-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5D0158A5c3ddF47d4Ea4517d8DB0D76aA2e87563",
    symbol: "BONDLY",
    name: "Bondly",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563/124b42b5e361e907c1c3f354df578f6e.png",
    coingeckoId: "bondly",
    listedIn: ["coingecko", "1inch", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x5CA42204cDaa70d5c773946e69dE942b85CA6706",
    symbol: "POSI",
    name: "Position Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5ca42204cdaa70d5c773946e69de942b85ca6706/f4f312ffdbfe5605653ce7f6e1171e75.png",
    coingeckoId: "position-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e",
    symbol: "CART",
    name: "CryptoArt.ai",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x5C4Bcc4DbaEAbc7659f6435bCE4E659314ebad87",
    symbol: "NTX",
    name: "NuNet Utility Token",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5c4bcc4dbaeabc7659f6435bce4e659314ebad87/bae6cdf692c16657e772658d8c625855.png",
    coingeckoId: "nunet",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5BCcFbd33873A5498F8406146868eDdd5E998962",
    symbol: "PDO",
    name: "pDollar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x5bccfbd33873a5498f8406146868eddd5e998962.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x5B6ebB33eea2D12eeFd4a9b2aEaf733231169684",
    symbol: "WELD",
    name: "Weld.Money",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5b6ebb33eea2d12eefd4a9b2aeaf733231169684/47693ba45e2bf64642662f5ce1650136.png",
    coingeckoId: "weld",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9",
    symbol: "MIR",
    name: "Mirror Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9/logo.png",
    coingeckoId: "mirror-protocol",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95",
    symbol: "ANATION",
    name: "A-NATION",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5ae89b52f6b5d25eba50f0441f91a81968689d95/d48f3650ea474b2dbb324d0347f60d81.png",
    coingeckoId: "a-nation",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587",
    symbol: "BSCX",
    name: "BSCEX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587/logo.png",
    coingeckoId: "bscex",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x5A68431398A6DE785994441e206259702e259C5E",
    symbol: "REUM",
    name: "Rewardeum",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5a68431398a6de785994441e206259702e259c5e/e72b8fed3d5b44fbc1c5829ddf0438ef.png",
    coingeckoId: "rewardeum",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea",
    symbol: "JulD",
    name: "JulSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea/47ea782853ae1758dfe1007f5b2cf3f0.png",
    coingeckoId: "julswap",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x5A341DCF49e161CC73591f02e5f8CDE8A29733fb",
    symbol: "RTO",
    name: "Reflecto",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5a341dcf49e161cc73591f02e5f8cde8a29733fb/6b5d46d216d857c5dc06371d731bdaaf.png",
    coingeckoId: "reflecto",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700",
    symbol: "BSCPAD",
    name: "BSCPad",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700/logo.png",
    coingeckoId: "bscpad",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x59A99b55C6E12d026CfD02Ae3E38f94f102fB46b",
    symbol: "MASTER",
    name: "Master Floki",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x59a99b55c6e12d026cfd02ae3e38f94f102fb46b/659223f28430fb003a06f855720d082e.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA",
    symbol: "bDIGG",
    name: "bDIGG",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5921DEE8556c4593EeFCFad3CA5e2f618606483b",
    symbol: "anyMTLX",
    name: "anyMTLX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5921DEE8556c4593EeFCFad3CA5e2f618606483b/logo.png",
    coingeckoId: "mettalex",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x590D11c0696b0023176f5D7587d6b2f502a08047",
    symbol: "LOOKS",
    name: "LooksRare",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x590D11c0696b0023176f5D7587d6b2f502a08047.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x58d372A8db7696C0C066F25c9eAF2AF6F147B726",
    symbol: "TSUGA",
    name: "Tsukiverse: Galactic Adventures",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x58d372a8db7696c0c066f25c9eaf2af6f147b726/fab234438d7e224f0d9c1197bff9a9e8.png",
    coingeckoId: "tsukiverse-galactic-adventures",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x58759Dd469ae5631C42cf8a473992335575b58D7",
    symbol: "DHV",
    name: "DeHive.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x58759dd469ae5631c42cf8a473992335575b58d7/3dc5afb23cd358bfd56d33d7e7033c36.png",
    coingeckoId: "dehive",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x58730ae0FAA10d73b0cDdb5e7b87C3594f7a20CB",
    symbol: "ERC20",
    name: "ERC20",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x58730ae0faa10d73b0cddb5e7b87c3594f7a20cb/dcd62cf47feb6bbd9ea24ab2eaeb100b.png",
    coingeckoId: "erc20",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3",
    symbol: "FUSE",
    name: "Fuse Token on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5857c96dae9cf8511b08cb07f85753c472d36ea3/db14dbdc589e1e7c9068ced60bb0f784.png",
    coingeckoId: "fuse-network-token",
    listedIn: ["coingecko", "1inch", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x582C12b30F85162Fa393e5DBe2573f9F601f9D91",
    symbol: "XMT",
    name: "MetalSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x582c12b30f85162fa393e5dbe2573f9f601f9d91/177bda1722a14409755695b054075261.png",
    coingeckoId: "metalswap",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x580655E2C6D4fC125C0B2D37B2a3e56219bF9f78",
    symbol: "GAL",
    name: "Gallant",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x580655e2c6d4fc125c0b2d37b2a3e56219bf9f78/56c1af308fe49d995c3f81e907dbc091.png",
    coingeckoId: "gallant",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x57c56665B2bcdf3CB86E40A9D3DC21f5b0AeD7Ad",
    symbol: "EVM",
    name: "EverMars",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x57c56665b2bcdf3cb86e40a9d3dc21f5b0aed7ad/0e0b917e8fcfcfd8b2efc687d5ddb5df.png",
    coingeckoId: "evermars",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x57bb0f40479D7Dd0caa67f2A579273A8e9c038Ee",
    symbol: "RUGBUST",
    name: "Rug Busters ",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x57bb0f40479d7dd0caa67f2a579273a8e9c038ee/9e218fde1eb280b96285de64fc8d5608.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54",
    symbol: "BTH",
    name: "Bit Hotel Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x57bc18f6177cdaffb34ace048745bc913a1b1b54/cb7ba38123823dc8e2a4458354b1fdfd.png",
    coingeckoId: "bit-hotel",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B",
    symbol: "vLTC",
    name: "Venus LTC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b/71acd5f84dc06f87598a446be9c9f138.png",
    coingeckoId: "venus-ltc",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x5774B2fc3e91aF89f89141EacF76545e74265982",
    symbol: "NFTY",
    name: "NFTY Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5774b2fc3e91af89f89141eacf76545e74265982/66538e9712357ccd12d4c3bdeed37405.png",
    coingeckoId: "nfty-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x57457B5D725D85A70a3625D6a71818304e773618",
    symbol: "PETN",
    name: "Pylon Eco Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x57457b5d725d85a70a3625d6a71818304e773618/dfe1fe851f48cb3f5f2a45a861b8c912.png",
    coingeckoId: "pylon-eco-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x570B17ADA7CfaF5c9dEb08b923125982BF817c63",
    symbol: "3.7C",
    name: "3.7Protocol",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "37protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
    symbol: "SOL",
    name: "SOLANA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x570a5d26f7765ecb712c0924e4de545b89fd43df/7c5db5c2eae571da837b65f5b9ae1a5c.png",
    coingeckoId: "wrapped-solana",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6",
    symbol: "EOS",
    name: "EOS Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6/logo.png",
    coingeckoId: "binance-peg-eos",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x56AA0237244C67B9A854B4Efe8479cCa0B105289",
    symbol: "WNOW",
    name: "WalletNow",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x56aa0237244c67b9a854b4efe8479cca0b105289/e551e069c4b5deb87370429a94edc52a.png",
    coingeckoId: "walletnow",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x566cedD201F67E542A6851A2959c1a449a041945",
    symbol: "pOPIUM",
    name: "pTokens OPIUM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x566cedd201f67e542a6851a2959c1a449a041945.png",
    coingeckoId: "opium",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x565b72163f17849832A692A3c5928cc502f46D69",
    symbol: "HUNNY",
    name: "Hunny Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x565b72163f17849832a692a3c5928cc502f46d69/9a6df416862be6f5682cfa572319d597.png",
    coingeckoId: "pancake-hunny",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f",
    symbol: "XED",
    name: "Exeedme",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f/logo.png",
    coingeckoId: "exeedme",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x56156Fb7860d7EB0B4b1a5356C5354b295194A45",
    symbol: "SPRT",
    name: "Sportium Token (Wormhole)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x56156fb7860d7eb0b4b1a5356c5354b295194a45/61f52919d29230a938a63cfbb5a35906.png",
    coingeckoId: "sportium",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x55d398326f99059fF775485246999027B3197955",
    symbol: "USDT",
    name: "Binance Pegged USDT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x55d398326f99059fF775485246999027B3197955/logo.png",
    coingeckoId: "tether",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x559100c4C697Ad514340943625bCeeFF8605cDC3",
    symbol: "LOCK",
    name: "Contracto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x559100c4c697ad514340943625bceeff8605cdc3/25ed0da2da27364441b3f94a006f107f.png",
    coingeckoId: "contracto",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x55671114d774ee99D653D6C12460c780a67f1D18",
    symbol: "PACOCA",
    name: "Pacoca",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x55671114d774ee99d653d6c12460c780a67f1d18/41525cf663255f99a25d89fd49da31cf.png",
    coingeckoId: "pacoca",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x5546600f77EdA1DCF2e8817eF4D617382E7f71F5",
    symbol: "PING",
    name: "PING",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x5546600f77eda1dcf2e8817ef4d617382e7f71f5/logo.png",
    coingeckoId: "sonar",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83",
    symbol: "EZ",
    name: "Easy V2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83/logo.png",
    coingeckoId: "easyfi",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x5492Ef6aEebA1A3896357359eF039a8B11621b45",
    symbol: "CHMB",
    name: "Chumbi Valley",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5492ef6aeeba1a3896357359ef039a8b11621b45/fda85ca5fc243fbd3bcc2b1ebb647983.png",
    coingeckoId: "chumbai-valley",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x547CBE0f0c25085e7015Aa6939b28402EB0CcDAC",
    symbol: "XBN",
    name: "Elastic BNB",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x547cbe0f0c25085e7015aa6939b28402eb0ccdac/bff6845a2ae44f7810f555f314993dac.png",
    coingeckoId: "xbn",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x54523D5fB56803baC758E8B10b321748A77ae9e9",
    symbol: "DREAMS",
    name: "Dreams Quest",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x54523d5fb56803bac758e8b10b321748a77ae9e9/0efd203a3558b22226502ad174ec7033.png",
    coingeckoId: "dreams-quest",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x54283eb77CC33182c06B311248c48C73AdF4E2eF",
    symbol: "Ape",
    name: "ApeLab",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x54283eb77cc33182c06b311248c48c73adf4e2ef/bea31cbbcdb0bda54e3d6b32432382ba.png",
    coingeckoId: "apelab",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x54261774905f3e6E9718f2ABb10ed6555cae308a",
    symbol: "anyBTC",
    name: "ANY Bitcoin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x54261774905f3e6e9718f2abb10ed6555cae308a/fd3445418c260a9bd68beaeba5c35074.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x541E619858737031A1244A5d0Cd47E5ef480342c",
    symbol: "wSOTE",
    name: "Soteria",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x541E619858737031A1244A5d0Cd47E5ef480342c/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657",
    symbol: "BABY",
    name: "BabySwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://cloudstorage.openocean.finance/images/1660734656084_05897697921487688.png",
    coingeckoId: "babyswap",
    listedIn: ["coingecko", "openocean"],
  },
  {
    address: "0x5325BaA42dC7604f427A8bDE6732F83C19028942",
    symbol: "4CH5",
    name: "4CHAN500",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x531780FAcE85306877D7e1F05d713D1B50a37F7A",
    symbol: "BSHARE",
    name: "BSHARE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x531780face85306877d7e1f05d713d1b50a37f7a/82f5fdc97b8f52bed7659e58d17ccd3a.png",
    coingeckoId: "bomb-money-bshare",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x52d86850bc8207b520340B7E39cDaF22561b9E56",
    symbol: "SWIRL",
    name: "Swirl.Cash",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x52d86850bc8207b520340b7e39cdaf22561b9e56/7eea515551f13a5ed76e19e287624693.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x52EC25E58a9e144ff002625BB2AA58Cc6DA24Cb2",
    symbol: "ACK",
    name: "ArcadeKingdoms",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "arcade-kingdoms",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8",
    symbol: "COMP",
    name: "Compound Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8/logo.png",
    coingeckoId: "compound-governance-token",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x524dF384BFFB18C0C8f3f43d012011F8F9795579",
    symbol: "YAY",
    name: "YAY Games",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x524df384bffb18c0c8f3f43d012011f8f9795579/323124fcf50a2f2778f5e94085d2855e.png",
    coingeckoId: "yay-games",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x52419258E3fa44DEAc7E670eaDD4c892B480A805",
    symbol: "STARSHIP",
    name: "StarShip",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x52419258e3fa44deac7e670eadd4c892b480a805/5a0f21c34818cf074f5e37ff811ac78f.png",
    coingeckoId: "starship",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x522348779DCb2911539e76A1042aA922F9C47Ee3",
    symbol: "BOMB",
    name: "bomb.money",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x522348779dcb2911539e76a1042aa922f9c47ee3/ab03ec6136a808388286afcd94fbf232.png",
    coingeckoId: "bomb-money",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x521ef54063148E5F15F18B9631426175ceE23DE2",
    symbol: "RING",
    name: "RING",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x521ef54063148e5f15f18b9631426175cee23de2/logo.png",
    coingeckoId: "ring-financial",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D",
    symbol: "SUPER",
    name: "SUPER",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/14040/small/6YPdWn6.png?1613975899",
    coingeckoId: "superfarm",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance", "xyfinance"],
  },
  {
    address: "0x518445F0dB93863E5e93A7F70617c05AFa8048f1",
    symbol: "BITT",
    name: "BITTOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x518445f0db93863e5e93a7f70617c05afa8048f1/d975e245158b1ac10a9397566fd0ed7c.png",
    coingeckoId: "bittoken",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x5175334d05FEb677b0910fb902454aeE10Fe96fd",
    symbol: "KFC",
    name: "Kentucky Fried Crypto",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x5175334d05feb677b0910fb902454aee10fe96fd/49d2a92e80dd3dc7e2769b6f2ade0448.png",
    coingeckoId: "kentucky-fried-crypto",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
    symbol: "WARS",
    name: "MetaWars",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x50e756a22ff5cee3559d18b9d9576bc38f09fa7c/4e27658022322b5466f88915aac45bd8.png",
    coingeckoId: "metawars",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x50D809c74e0B8e49e7B4c65BB3109AbE3Ff4C1C1",
    symbol: "CUB",
    name: "Cub Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1/cae074dd50c691ee6e072d29b490b7c6.png",
    coingeckoId: "cub-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x509a51394CC4D6bb474FeFB2994b8975A55A6e79",
    symbol: "FUFU",
    name: "FUFU",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x509a51394cc4d6bb474fefb2994b8975a55a6e79/0e35ca88c720919d87fe91e21415d189.png",
    coingeckoId: "fufu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x50332bdca94673F33401776365b66CC4e81aC81d",
    symbol: "CCAR",
    name: "CryptoCars",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x50332bdca94673f33401776365b66cc4e81ac81d/f9d9daf94eb35a7c8da21f216898674b.png",
    coingeckoId: "cryptocars",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x4fD6b4DA1773C25D8904FC27f91085f235b4Ed0A",
    symbol: "LINKIES",
    name: "Sergey's BigMac Fund",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x4f39c3319188A723003670c3F9B9e7EF991E52F3",
    symbol: "FIGHT",
    name: "Fight Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4f39c3319188a723003670c3f9b9e7ef991e52f3/c4ab1fa8245fa5e11498ae9d98e23063.png",
    coingeckoId: "crypto-fight-club",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x4f0ed527e8A95ecAA132Af214dFd41F30b361600",
    symbol: "vBSWAP",
    name: "vSWAP.fi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4f0ed527e8a95ecaa132af214dfd41f30b361600/8d59b4c246af3ff85aa4644fb68d0ad1.png",
    coingeckoId: "vbswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4ef29F3B804C316bA8bA464A765C601Fc092a2e9",
    symbol: "QA",
    name: "Quantum Assets Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4ef29f3b804c316ba8ba464a765c601fc092a2e9/bfc8591e391e0abce20efb10489d29f3.png",
    coingeckoId: "quantum-assets",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF",
    symbol: "YAE",
    name: "Cryptonovae",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4ee438be38f8682abb089f2bfea48851c5e71eaf/6bad3d4728f61da17bea518460c41f95.png",
    coingeckoId: "cryptonovae",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4e8a9D0BF525d78fd9E0c88710099f227F6924cf",
    symbol: "LUNAR",
    name: "LunarHighway",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4e8a9d0bf525d78fd9e0c88710099f227f6924cf/7ef8c2822db52d88154d6bbb2f81f740.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x4e840AADD28DA189B9906674B4Afcb77C128d9ea",
    symbol: "HTB",
    name: "Hotbit",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4e840AADD28DA189B9906674B4Afcb77C128d9ea/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x4e6415a5727ea08aAE4580057187923aeC331227",
    symbol: "FINE",
    name: "Refinable",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4e6415a5727ea08aAE4580057187923aeC331227/logo.png",
    coingeckoId: "refinable",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x4e3cABD3AD77420FF9031d19899594041C420aeE",
    symbol: "TITANO",
    name: "Titano",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4e3cabd3ad77420ff9031d19899594041c420aee/ff412355c9f07cc2bc3d5bb3f72a71f3.png",
    coingeckoId: "titano",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4e1b16Ef22935A575a6811D4616f98C4077E4408",
    symbol: "KEL",
    name: "KEL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4e1b16ef22935a575a6811d4616f98c4077e4408/59d9153dc8814af6d6c4d584eff76de3.png",
    coingeckoId: "kelvpn",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x4ddBa7c715072D797e28f79fd8Afc0942498E9A3",
    symbol: "DOGEX",
    name: "Doge X",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4ddba7c715072d797e28f79fd8afc0942498e9a3/1008e77aed081553ac978f8f1e411957.png",
    coingeckoId: "doge-x",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x4d8164892df2d5cf4754799cb2a001E3D5832B30",
    symbol: "META",
    name: "Meta Musk",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4d8164892df2d5cf4754799cb2a001e3d5832b30/548d2a48e60c67e2cf11669470066924.png",
    coingeckoId: "meta-musk",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x4d4e595d643dc61EA7FCbF12e4b1AAA39f9975B8",
    symbol: "PET",
    name: "Pet Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8/d3cbb3b85eabb747688768dc3f92ce9d.png",
    coingeckoId: "battle-pets",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x4c97c901B5147F8C1C7Ce3c5cF3eB83B44F244fE",
    symbol: "MND",
    name: "Mound Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4c97c901b5147f8c1c7ce3c5cf3eb83b44f244fe/89c424b03be36300a7f99de83b11587f.png",
    coingeckoId: "mound-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4c4da68D45F23E38ec8407272ee4f38F280263c0",
    symbol: "PGIRL",
    name: "Panda Girl",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4c4da68d45f23e38ec8407272ee4f38f280263c0/09baadd7891895e81396f696f10ab2e7.png",
    coingeckoId: "panda-girl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4c403b1879aa6A79BA9C599A393ccc5D9fD2E788",
    symbol: "AI",
    name: "Artificial Intelligence",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4c403b1879aa6a79ba9c599a393ccc5d9fd2e788/3fe0d1ea3878e6652b9a3bc6417fa9b9.png",
    coingeckoId: "artificial-intelligence",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8",
    symbol: "XCV",
    name: "XCarnival",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4be63a9b26ee89b9a3a13fd0aa1d0b2427c135f8/612f93ab5a7a6faad82fb1b98a3ca72b.png",
    coingeckoId: "xcarnival",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x4b8285aB433D8f69CB48d5Ad62b415ed1a221e4f",
    symbol: "MCRT",
    name: "MagicCraft",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f/e2613af677c84d86c2df3b3359e10f7c.png",
    coingeckoId: "magiccraft",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x4b6000F9163de2E3f0a01eC37E06e1469DBbcE9d",
    symbol: "KEYFI",
    name: "KeyFi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d/3a58685637e0e5bbfe84c791b13fff2c.png",
    coingeckoId: "keyfi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4a68C250486a116DC8D6A0C5B0677dE07cc09C5D",
    symbol: "POODL",
    name: "Poodl",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4a68c250486a116dc8d6a0c5b0677de07cc09c5d/5b233bc30b28ce79e7e3d22d68683b98.png",
    coingeckoId: "poodle",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4a2c860cEC6471b9F5F5a336eB4F38bb21683c98",
    symbol: "GST-BSC",
    name: "Green Satoshi Token on BSC",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4a2c860cec6471b9f5f5a336eb4f38bb21683c98/2d919ee408aa3e2d18f0c40b775e11fe.png",
    coingeckoId: "green-satoshi-token-bsc",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x4a080377f83D669D7bB83B3184a8A5E61B500608",
    symbol: "XEND",
    name: "XEND",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4a080377f83D669D7bB83B3184a8A5E61B500608/logo.png",
    coingeckoId: "xend-finance",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x4FA7163E153419E0E1064e418dd7A99314Ed27b6",
    symbol: "HOTCROSS",
    name: "Hot Cross Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4FA7163E153419E0E1064e418dd7A99314Ed27b6/logo.png",
    coingeckoId: "hot-cross",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B",
    symbol: "Ramen",
    name: "Ramen Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b/0e29055a4a0d8aa8374ae86c0e5e9542.png",
    coingeckoId: "ramenswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4Ef06d1A55962f29D362c53c864a4f0c791DcB9E",
    symbol: "MYOBU",
    name: "Myōbu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4ef06d1a55962f29d362c53c864a4f0c791dcb9e/25a0d7ef4c1f6ff7ebcff8914b59744c.png",
    coingeckoId: "myobu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4EF285c8cbe52267c022c39da98b97ca4b7e2fF9",
    symbol: "ORE",
    name: "pTokens ORE [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9.png",
    coingeckoId: "ptokens-ore",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4E7Ae924FD9a5D60b56BE486b2900efE0c6a9CA7",
    symbol: "LOT",
    name: "Lottery Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4e7ae924fd9a5d60b56be486b2900efe0c6a9ca7/8091cc7534b849b58acf68aa30a9d9b9.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x4Dad73719c4F4D5197b89c19460b1171d800097d",
    symbol: "MJACK",
    name: "MJACKSWAP",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x4dad73719c4f4d5197b89c19460b1171d800097d/logo.png",
    coingeckoId: "mjackswap",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x4DCd4700B38ce6562730c27Da557F6de819b347B",
    symbol: "CIFI",
    name: "citizen finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4dcd4700b38ce6562730c27da557f6de819b347b/e3e8d3bcfacfa66d568cf644ffa51277.png",
    coingeckoId: "citizen-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA",
    symbol: "WETH",
    name: "Wrapped Ether (Wormhole)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea/61844453e63cf81301f845d7864236f6.png",
    coingeckoId: "ethereum-wormhole",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A",
    symbol: "WOW",
    name: "WOWSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4da996c5fe84755c80e108cf96fe705174c5e36a/a35da1e28ab13f40d29c009cc3137921.png",
    coingeckoId: "wowswap",
    listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
  },
  {
    address: "0x4D61577d8Fd2208A0afb814ea089fDeAe19ed202",
    symbol: "VFOX",
    name: "VFOX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4d61577d8fd2208a0afb814ea089fdeae19ed202/6a142308607cb359467c5ba6a8237eb3.png",
    coingeckoId: "vfox",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055",
    symbol: "SUTER",
    name: "Suterusu",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055/logo.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x4CbdeB687443E2e4Dcea1A955186B86C365A2e20",
    symbol: "SOUND",
    name: "Sound",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4cbdeb687443e2e4dcea1a955186b86c365a2e20/1d2c7164537448848e28dd1a85b7931c.png",
    coingeckoId: "sound-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4C882ec256823eE773B25b414d36F92ef58a7c0C",
    symbol: "PSTAKE",
    name: "pStake Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4c882ec256823ee773b25b414d36f92ef58a7c0c/5163ad5ac41051712717c90c05c4720a.png",
    coingeckoId: "pstake-finance",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x4C769928971548eb71A3392EAf66BeDC8bef4B80",
    symbol: "BITCOIN",
    name: "HarryPotterObamaSonic10Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4c769928971548eb71a3392eaf66bedc8bef4b80/a334f75b4532a463e4edfdf605035de2.png",
    coingeckoId: "harrypotterobamasonic10inu",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
    symbol: "VAI",
    name: "VAI Stablecoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7/logo.png",
    coingeckoId: "vai",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x4BA0057f784858a48fe351445C672FF2a3d43515",
    symbol: "KALM",
    name: "Kalmar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4BA0057f784858a48fe351445C672FF2a3d43515/logo.png",
    coingeckoId: "kalmar",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x4B5DeCb9327B4D511A58137A1aDE61434AacdD43",
    symbol: "PKN",
    name: "Poken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4b5decb9327b4d511a58137a1ade61434aacdd43/26354236a6f46f4e9199f4daf4fe6946.png",
    coingeckoId: "poken",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
    symbol: "TWT",
    name: "Trust Wallet",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4B0F1812e5Df2A09796481Ff14017e6005508003/logo.png",
    coingeckoId: "trust-wallet-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623",
    symbol: "DF",
    name: "dForce",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623/02df44f03a3c1f24cf95915fcbdc57bb.png",
    coingeckoId: "dforce-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5",
    symbol: "MILK2",
    name: "MilkyWay Token by SpaceSwap v2",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5/f053a085f2b25404766b48f203ed0067.png",
    coingeckoId: "spaceswap-milk2",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x4A2940263acfd179dBc1C6B69A3392671ACCaf5B",
    symbol: "SHWA",
    name: "ShibaWallet",
    decimals: 18,
    chainId: "56",
    logoURI: "https://shibawallet.pro/assets/favicon32x32.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x4A251d4fDcbbbc0A3d6Aa44F14B96480C4933C9C",
    symbol: "DBT",
    name: "Disco Burn Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4a251d4fdcbbbc0a3d6aa44f14b96480c4933c9c/1c62a62cd6627506fe3d085957fb2af9.png",
    coingeckoId: "disco-burn-token",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x49f2145d6366099e13B10FbF80646C0F377eE7f6",
    symbol: "PORTO",
    name: "FC Porto Fan Token",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x49f2145d6366099e13b10fbf80646c0f377ee7f6/cf88bbad177c842c29555c1e81185882.png",
    coingeckoId: "fc-porto",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x49A516BD4406b2D4074C738a58De6DB397D0ABC9",
    symbol: "IKOLF",
    name: "ikolF",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x49a516bd4406b2d4074c738a58de6db397d0abc9/1d09365f272e59ff1f3e82ee53abf1f4.png",
    coingeckoId: "ikolf",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x496E80Cad8712025dAfc5b2C96FC36c395af684a",
    symbol: "BSGG",
    name: "Betswap.gg",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/22496/large/betswap.jpg",
    coingeckoId: null,
    listedIn: ["RouterProtocol", "lifinance"],
  },
  {
    address: "0x495205d4c6307A73595C5C11B44Bee9B3418Ac69",
    symbol: "GAFA",
    name: "Gafa",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x495205d4c6307a73595c5c11b44bee9b3418ac69/8f373828ae3c56031c63d903f7736fb8.png",
    coingeckoId: "gafa",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x49277cC5be56b519901E561096bfD416277b4F6d",
    symbol: "OCT",
    name: "Octree Finance",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x49277cc5be56b519901e561096bfd416277b4f6d/4e474b218345cbad0bfd04f442ac70f7.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f",
    symbol: "mCOIN",
    name: "Mirror COIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x48DC0190dF5ece990c649A7A07bA19D3650a9572",
    symbol: "AUCTION",
    name: "Bounce Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x48dc0190df5ece990c649a7a07ba19d3650a9572/d615d97d905bfde376671f7f62681af5.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x489580eB70a50515296eF31E8179fF3e77E24965",
    symbol: "RADAR",
    name: "DappRadar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x489580eb70a50515296ef31e8179ff3e77e24965/a441a3145879e5cc68d89000642f69b6.png",
    coingeckoId: "dappradar",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x48378891d6E459ca9a56B88b406E8F4eAB2e39bF",
    symbol: "$FUR",
    name: "Furio",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x48378891d6e459ca9a56b88b406e8f4eab2e39bf/a56bfb9eb5724632ee021c2e7b10a61d.png",
    coingeckoId: "furio",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x482e6BD0a178f985818c5DFB9AC77918E8412FBA",
    symbol: "ZEUM",
    name: "ZEUM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/24448/small/AB0cGpnx_400x400.jpg?1647681843",
    coingeckoId: "colizeum",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0x4803Ac6b79F9582F69c4fa23c72cb76dD1E46d8d",
    symbol: "TMT",
    name: "TopManager Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4803ac6b79f9582f69c4fa23c72cb76dd1e46d8d/c5124b708d19476e27feb65eb316fccf.png",
    coingeckoId: "topmanager",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C",
    symbol: "EARN",
    name: "EverEarn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x47d42e0e864ceff8c4270f7b6e1f6f91da45882c/d173a95e0c3419c1c4de9c0099bcf80a.png",
    coingeckoId: "everearn",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x47c1C7B9D7941A7265D123DCfb100D8FB5348213",
    symbol: "YVS",
    name: "YVS.Finance on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x47c1c7b9d7941a7265d123dcfb100d8fb5348213/f1a86d00a2f093b5f606265227886c28.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
    symbol: "SXP",
    name: "Swipe",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A/logo.png",
    coingeckoId: "swipe",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x477bC8d23c634C154061869478bce96BE6045D12",
    symbol: "SFUND",
    name: "SeedifyFund",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x477bc8d23c634c154061869478bce96be6045d12/303f14a088807aa3dd9deebb97fe9e71.png",
    coingeckoId: "seedify-fund",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
    ],
  },
  {
    address: "0x475bFaa1848591ae0E6aB69600f48d828f61a80E",
    symbol: "DOME",
    name: "DOME",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/23267/small/Ix-ms0fq_400x400.jpg?1643414048",
    coingeckoId: "everdome",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x475DB18D764DF7FaDfbD3e73fcCbBC1e14342aB3",
    symbol: "APO",
    name: "ApolloFi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x475db18d764df7fadfbd3e73fccbbc1e14342ab3/5afc395ee8ed4cd9b9368c2ae6202a8f.png",
    coingeckoId: "apollofi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x474021845C4643113458ea4414bdb7fB74A01A77",
    symbol: "UNO",
    name: "UnoRe",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x474021845c4643113458ea4414bdb7fb74a01a77/128f86f5ae5cb67caf1c684a5353d181.png",
    coingeckoId: "uno-re",
    listedIn: ["coingecko", "RouterProtocol", "lifinance"],
  },
  {
    address: "0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F",
    symbol: "WSPP",
    name: "WolfSafePoorPeople",
    decimals: 0,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f/cadc0fce8a2c5e1481782eabf5cc3205.png",
    coingeckoId: "wolfsafepoorpeople",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x4691937a7508860F876c9c0a2a617E7d9E945D4B",
    symbol: "WOO",
    name: "Wootrade",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4691937a7508860F876c9c0a2a617E7d9E945D4B/logo.png",
    coingeckoId: "woo-network",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3",
    symbol: "FCF",
    name: "French connection finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3/logo.png",
    coingeckoId: "french-connection-finance",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x464FdB8AFFC9bac185A7393fd4298137866DCFB8",
    symbol: "REALM",
    name: "REALM",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x464fdb8affc9bac185a7393fd4298137866dcfb8/181df78b491d08c942ae44f99e718772.png",
    coingeckoId: "realm",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x461daB902f38210b42b7D8B4bfc71296E0629006",
    symbol: "DRIV",
    name: "Drivez Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x461dab902f38210b42b7d8b4bfc71296e0629006/aa2e88d4ca280ece703a38cd51195b21.png",
    coingeckoId: "drivez",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x45d0f429790Bec7AD4b68330B1051c7b8fE9d8aF",
    symbol: "BSCAKE",
    name: "BUNSCAKE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x45d0f429790bec7ad4b68330b1051c7b8fe9d8af/a0253fa7a2613d43a3b7e70aeb0f45cf.png",
    coingeckoId: "bunscake",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x45B239Cc0a760D1AFd276B749141c7E404844Ee6",
    symbol: "COMT",
    name: "Community Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x45b239cc0a760d1afd276b749141c7e404844ee6/a029399557fdbca3dd29e7f7399afe03.png",
    coingeckoId: "community-metaverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4518231a8FDF6ac553B9BBD51Bbb86825B583263",
    symbol: "MLT",
    name: "Media Licensing Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4518231a8fdf6ac553b9bbd51bbb86825b583263/76a7495f35c127e107a1b8b522cccc04.png",
    coingeckoId: "media-licensing-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x44c663476eCb54487c95e82C5bD05B9694253A9F",
    symbol: "LGO",
    name: "LEGIO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x44c663476ecb54487c95e82c5bd05b9694253a9f/1870b23dc30d4604adc4b17184f6dc84.png",
    coingeckoId: "legiodao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x44C99Ca267C2b2646cEEc72e898273085aB87ca5",
    symbol: "RPTR",
    name: "Raptor v3",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x44c99ca267c2b2646ceec72e898273085ab87ca5/8137709672d139275801cd61328cb381.png",
    coingeckoId: "raptor-finance-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4477b28E8b797eBaebd2539bb24290Fdfcc27807",
    symbol: "RFG",
    name: "Refugees Token",
    decimals: 10,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4477b28E8b797eBaebd2539bb24290Fdfcc27807/logo.png",
    coingeckoId: "refugees-token",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x44754455564474A89358B2C2265883DF993b12F0",
    symbol: "ZEE",
    name: "ZeroSwapToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x44754455564474A89358B2C2265883DF993b12F0/logo.png",
    coingeckoId: "zeroswap",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x444A0E0c139Cac67e8f9be945C6Dfe01A2766ED1",
    symbol: "GST",
    name: "Gemstone Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x444a0e0c139cac67e8f9be945c6dfe01a2766ed1.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x43f5b29D63ceDC5a7c1724dbB1D698FDe05Ada21",
    symbol: "FODL",
    name: "Fodl",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x43f5b29d63cedc5a7c1724dbb1d698fde05ada21/6eb6c46136076bf7b8febc544ec7e9f8.png",
    coingeckoId: "fodl-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x43ce64A4FBa3c30C0164E935B0095862a67dc0c9",
    symbol: "NEKOS",
    name: "Nekocoin",
    decimals: 0,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x43ce64a4fba3c30c0164e935b0095862a67dc0c9/d2087dc874eb87fbfabcb6f79a59a31f.png",
    coingeckoId: "nekocoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x43a172c44dC55c2B45BF9436cF672850FC8bA046",
    symbol: "MetaWar",
    name: "MetaWar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x43a172c44dc55c2b45bf9436cf672850fc8ba046/59714fa4c06b91f6502582acc0c2845a.png",
    coingeckoId: "metawar",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4374F26F0148a6331905eDf4cD33B89d8Eed78d1",
    symbol: "YOSHI",
    name: "Yoshi.exchange",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4374f26f0148a6331905edf4cd33b89d8eed78d1/2935276c50b87dfdb23b9f34e192a29b.png",
    coingeckoId: "yoshi-exchange",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x436C52A8ceE41D5e9c5E6f4Cb146e66D552Fb700",
    symbol: "EQX",
    name: "EQIFi Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x436c52a8cee41d5e9c5e6f4cb146e66d552fb700/54ea6ec0ec5add6308ee764969e17c39.png",
    coingeckoId: "eqifi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
    symbol: "LTC",
    name: "Litecoin Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4338665CBB7B2485A8855A139b75D5e34AB0DB94/logo.png",
    coingeckoId: "binance-peg-litecoin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x431e0cD023a32532BF3969CddFc002c00E98429d",
    symbol: "XCAD",
    name: "Xcademy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x431e0cD023a32532BF3969CddFc002c00E98429d/logo.png",
    coingeckoId: "xcad-network",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x42f3008F6945f052C31e7680F7f78c512099b904",
    symbol: "WALBT",
    name: "Wrapped AllianceBlock Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x42f3008f6945f052c31e7680f7f78c512099b904/9867892d0aaa519c537d8189a0551750.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x42bfa18f3f7D82BD7240d8Ce5935d51679C5115d",
    symbol: "S2K",
    name: "Sports 2K75",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x42bfa18f3f7d82bd7240d8ce5935d51679c5115d/74cf49b97ee6cf6977582aa48325f055.png",
    coingeckoId: "sports-2k75",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096",
    symbol: "NRV",
    name: "Nerve Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096/logo.png",
    coingeckoId: "nerve-finance",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x42C1613dD2236CFd60B5cbEc846b5EAD71be99Df",
    symbol: "$WINU",
    name: "WALTERINU",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x42c1613dd2236cfd60b5cbec846b5ead71be99df/b4fda16e54ad369115f6b3002ebc697b.png",
    coingeckoId: "walter-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x42981d0bfbAf196529376EE702F2a9Eb9092fcB5",
    symbol: "SFM",
    name: "SafeMoon",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x42981d0bfbaf196529376ee702f2a9eb9092fcb5/168650f9cf76d12d194519f41910df37.png",
    coingeckoId: "safemoon-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x427FEbb891BF5144B36125C6704168324dC9c6DF",
    symbol: "$Pulsar",
    name: "PulsarToken",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x427febb891bf5144b36125c6704168324dc9c6df/dfc6cb3c384365c8afbb4a173e1960fc.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x42712dF5009c20fee340B245b510c0395896cF6e",
    symbol: "DFT",
    name: "DFuture",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x42712dF5009c20fee340B245b510c0395896cF6e/logo.png",
    coingeckoId: "dfuture",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x426c72701833fdDBdFc06c944737C6031645c708",
    symbol: "FINA",
    name: "Defina Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x426c72701833fddbdfc06c944737c6031645c708/6380ec22025d6a0167fc75901afcbc2a.png",
    coingeckoId: "defina-finance",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730",
    symbol: "COW",
    name: "COW",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x422e3af98bc1de5a1838be31a56f75db4ad43730/b80474a0b1484b404fd59a9e8a52facb.png",
    coingeckoId: "coinwind",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x4206931337dc273a630d328dA6441786BfaD668f",
    symbol: "DOGE",
    name: "Dogecoin",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4206931337dc273a630d328da6441786bfad668f/f8d3519773c4ac932c0bd10e6b4bcefe.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x4197C6EF3879a08cD51e5560da5064B773aa1d29",
    symbol: "ACS",
    name: "ACryptoS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4197c6ef3879a08cd51e5560da5064b773aa1d29/50cba9bf96a836a53eaee311ab678d0a.png",
    coingeckoId: "acryptos",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x4165084A6e5388ce53c9D9892f904a2712Dd943A",
    symbol: "BLR",
    name: "Blur Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4165084a6e5388ce53c9d9892f904a2712dd943a/02e9714021d258648559a9eacfee7128.png",
    coingeckoId: "blur-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4131b87F74415190425ccD873048C708F8005823",
    symbol: "bMXX",
    name: "Multiplier",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x4131b87F74415190425ccD873048C708F8005823/logo.png",
    coingeckoId: "multiplier-bsc",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x411Ec510c85C9e56271bF4E10364Ffa909E685D9",
    symbol: "MOWA",
    name: "Moniwar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x411ec510c85c9e56271bf4e10364ffa909e685d9/4d0063cec1a39734652fb7b846ab7d14.png",
    coingeckoId: "moniwar",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x40eD092304dBae1bcf1858EB24e1B14141126AcB",
    symbol: "EverSAFU",
    name: "EverSAFU",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x40ed092304dbae1bcf1858eb24e1b14141126acb/0051aa0755fee236164b197af3da3416.png",
    coingeckoId: "eversafu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x40E51e0eC04283e300F12f6bB98DA157Bb22036E",
    symbol: "BLXM",
    name: "bloXmove Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x40e51e0ec04283e300f12f6bb98da157bb22036e/e283dfb28747fa30f6f7903e9fb09846.png",
    coingeckoId: "bloxmove-erc20",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3",
    symbol: "BSCDEFI",
    name: "BSC Ecosystem Defi blue chips",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x40C8225329Bd3e28A043B029E0D07a5344d2C27C",
    symbol: "AOG",
    name: "AgeOfGods",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x40c8225329bd3e28a043b029e0d07a5344d2c27c/5af0a950a865ff7d59a6a4c52303295a.png",
    coingeckoId: "ageofgods",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x409e215738E31d8aB252016369c2dd9c2008Fee0",
    symbol: "PAWTH",
    name: "Pawthereum",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x409e215738e31d8ab252016369c2dd9c2008fee0/22779041864a51e41111ce14bbc836cf.png",
    coingeckoId: "pawthereum",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x4090e535F2e251F5F88518998B18b54d26B3b07c",
    symbol: "TYPH",
    name: "Typhoon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x4090e535f2e251f5f88518998b18b54d26b3b07c/a163d2a866f0a864078dce9b892b24a7.png",
    coingeckoId: "typhoon-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3f6B2D68980Db7371D3D0470117393c9262621ea",
    symbol: "LUFFY",
    name: "LUFFY",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3f6b2d68980db7371d3d0470117393c9262621ea/72d556dd374906aef6310e6287f4a868.png",
    coingeckoId: "luffy-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3f515f0a8e93F2E2f891ceeB3Db4e62e202d7110",
    symbol: "VIDT",
    name: "VIDT Datalink",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3f515f0a8e93f2e2f891ceeb3db4e62e202d7110/ee4b0984c9dec9cbe27782e2c704c5b8.png",
    coingeckoId: "v-id-blockchain",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3e9d6430144485873248251fCB92bD856E95D1CD",
    symbol: "QUBE",
    name: "QUBE TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e9d6430144485873248251fcb92bd856e95d1cd/59dcbb3311f530623764043956554afb.png",
    coingeckoId: "qube-2",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x3e63e9C8F2297E3C027f8444b4591E2583d8780B",
    symbol: "PLOT",
    name: "PLOT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e63e9c8f2297e3c027f8444b4591e2583d8780b/7b8fcfce7b5337f9e283e087b7a4116b.png",
    coingeckoId: "earncraft",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3e24BBb2c9a0f2fAEcFDBDCa20BBa6F35b73C4CB",
    symbol: "DELOT",
    name: "DELOT.IO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e24bbb2c9a0f2faecfdbdca20bba6f35b73c4cb/c9774910eb49190714c0364b8a2f5aaa.png",
    coingeckoId: "delot-io",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3e0a7C7dB7bB21bDA290A80c9811DE6d47781671",
    symbol: "BETS",
    name: "BetSwirl Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e0a7c7db7bb21bda290a80c9811de6d47781671/acfb6fa23b55720388e5b59526672f22.png",
    coingeckoId: "betswirl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3d6545b08693daE087E957cb1180ee38B9e3c25E",
    symbol: "ETC",
    name: "Ethereum Classic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3d6545b08693dae087e957cb1180ee38b9e3c25e/cdf3269440b55479f40609301537500a.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a",
    symbol: "UST",
    name: "UST (Wormhole)",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a/2643c8028832e8b7c407ba36df892d38.png",
    coingeckoId: "terrausd-wormhole",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3c037C4c2296f280bB318D725D0b454B76c199b9",
    symbol: "JNTR/b",
    name: "JNTR/b",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/12830/small/jntr_logo.jpg?1602837322",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance"],
  },
  {
    address: "0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58",
    symbol: "EMP",
    name: "emp.money",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3b248cefa87f836a4e6f6d6c9b42991b88dc1d58/5a7715a4c963e0866ec91b917623e185.png",
    coingeckoId: "emp-money",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3b198e26E473b8faB2085b37978e36c9DE5D7f68",
    symbol: "TIME",
    name: "ChronoTech Token",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x3b198e26e473b8fab2085b37978e36c9de5d7f68/logo.png",
    coingeckoId: "chronobank",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x3aabCf53A1930A42E18D938C019E83Ebee50a849",
    symbol: "SPG",
    name: "SPONGE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x3aabcf53a1930a42e18d938c019e83ebee50a849.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x3aD9594151886Ce8538C1ff615EFa2385a8C3A88",
    symbol: "SAFEMARS",
    name: "SafeMars",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3ad9594151886ce8538c1ff615efa2385a8c3a88/57ce6b782800ff2fc15c10700a1225fa.png",
    coingeckoId: "safemars",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3a6e8B36645D6c252714dadDD28eC0673535a109",
    symbol: "2CRZ",
    name: "2CrazyToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3a6e8b36645d6c252714daddd28ec0673535a109/5877cbcd72eb5e48515073500f5ca303.png",
    coingeckoId: "2crazynft",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3a5325F0E5Ee4da06a285E988f052D4e45Aa64b4",
    symbol: "POLAR",
    name: "Polaris",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x1c545e9943cfd1b41e60a7917465911fa00fc28c.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC",
    symbol: "DEGO",
    name: "Dego.Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC/logo.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x3Fcca8648651E5b974DD6d3e50F61567779772A8",
    symbol: "POTS",
    name: "Moonpot",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3Fcca8648651E5b974DD6d3e50F61567779772A8/logo.png",
    coingeckoId: "moonpot",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
    symbol: "MAI",
    name: "miMatic",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3F56e0c36d275367b8C502090EDF38289b3dEa0d/logo.png",
    coingeckoId: "mimatic",
    listedIn: ["coingecko", "RouterProtocol", "lifinance", "elkfinance"],
  },
  {
    address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    symbol: "ADA",
    name: "Cardano Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
    coingeckoId: "binance-peg-cardano",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x3E362283B86C1b45097CC3FB02213b79CF6211Df",
    symbol: "CATCOIN",
    name: "CATCOIN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e362283b86c1b45097cc3fb02213b79cf6211df/8550e9def5803e08a239827055e3ab02.png",
    coingeckoId: "catcoin-cash",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3E2ed75F718251Fd011312b9E33Bf10A4dbfda54",
    symbol: "MANY",
    name: "Many Worlds",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3e2ed75f718251fd011312b9e33bf10a4dbfda54/0d645ca3b9c9560e72176e89a1057314.png",
    coingeckoId: "many-worlds",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3Da932456D082CBa208FEB0B096d49b202Bf89c8",
    symbol: "DEGOV2",
    name: "dego.finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x3da932456d082cba208feb0b096d49b202bf89c8.png",
    coingeckoId: "dego-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3C45A24d36Ab6fc1925533C1F57bC7e1b6fbA8a4",
    symbol: "ROOM",
    name: "OptionRoom Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3c45a24d36ab6fc1925533c1f57bc7e1b6fba8a4/2ced79651bf1e9da2c257817e39bca0e.png",
    coingeckoId: "option-room",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3C00F8FCc8791fa78DAA4A480095Ec7D475781e2",
    symbol: "SAFESTAR",
    name: "SafeStar",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3c00f8fcc8791fa78daa4a480095ec7d475781e2/b494fa70e9d9f1f8ee50e18adc3e0b26.png",
    coingeckoId: "safestar",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3A50d6daacc82f17A2434184fE904fC45542A734",
    symbol: "FUSII",
    name: "Fusible | Fusible.io",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3a50d6daacc82f17a2434184fe904fc45542a734/1d8b03648cd7330b93de5a635a1a318c.png",
    coingeckoId: "fusible",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x3A2927E68749Dd6ad0A568d7c05b587863C0bC10",
    symbol: "NNT",
    name: "NunuToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3a2927e68749dd6ad0a568d7c05b587863c0bc10/4a026fb7de200989cc9462eb5388f5ab.png",
    coingeckoId: "nunu-spirits",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x39bca4d597062378b5669CD31a3bBFECCDd36B3c",
    symbol: "LLVERSE",
    name: "LESLARVERSE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x39bca4d597062378b5669cd31a3bbfeccdd36b3c/d8041121fb82d9ac618a159fe128d3eb.png",
    coingeckoId: "leslar-metaverse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x39C3F75B837EAE0d6d0eA87425Ac99bD646a7B1E",
    symbol: "CATBOY",
    name: "CatBoy",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x39c3f75b837eae0d6d0ea87425ac99bd646a7b1e/f7a1c11de634d9d9b84f1bbf4aeda710.png",
    coingeckoId: "catboy-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x398f7827DcCbeFe6990478876bBF3612D93baF05",
    symbol: "MIX",
    name: "MixMarvel Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x398f7827dccbefe6990478876bbf3612d93baf05/fbb4a144828b44bf6922835236b9a728.png",
    coingeckoId: "mixmarvel",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x394bBA8F309f3462b31238B3fd04b83F71A98848",
    symbol: "POCO",
    name: "Poco Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x394bba8f309f3462b31238b3fd04b83f71a98848/faf3bc4f1f41a8e496fbad6b0a44adc9.png",
    coingeckoId: "pocoland",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3947B992DC0147D2D89dF0392213781b04B25075",
    symbol: "mAMZN",
    name: "Mirror AMZN Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3947B992DC0147D2D89dF0392213781b04B25075/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x393d87E44C7b1F5Ba521b351532C24ECE253B849",
    symbol: "BLES",
    name: "BLES",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x393d87e44c7b1f5ba521b351532c24ece253b849/f542becbd68d364fbf2e443d2336cda9.png",
    coingeckoId: "blind-boxes",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x393B312C01048b3ed2720bF1B090084C09e408A1",
    symbol: "FRIES",
    name: "fry.world",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x393B312C01048b3ed2720bF1B090084C09e408A1/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0x3920123482070C1a2dff73AaD695c60e7c6F6862",
    symbol: "EULER",
    name: "Euler.Tools",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3920123482070c1a2dff73aad695c60e7c6f6862/545c92d92f23f7ece3200df3b80f24c2.png",
    coingeckoId: "euler-tools",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3910db0600eA925F63C36DdB1351aB6E2c6eb102",
    symbol: "SPARTA",
    name: "SPARTAN PROTOCOL TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3910db0600ea925f63c36ddb1351ab6e2c6eb102/e368fbb86b325d6e1b16f3987832642d.png",
    coingeckoId: "spartan-protocol-token",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0x38fFA52C7628f5cCf871472e40c462E4483215C9",
    symbol: "SWIRL",
    name: "SwirlToken",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x38ffa52c7628f5ccf871472e40c462e4483215c9/71b028d9f67a0584d1eaaab481aa1569.png",
    coingeckoId: "swirltoken",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x380624A4a7e69dB1cA07deEcF764025FC224D056",
    symbol: "SAFEBTC",
    name: "SafeBTC",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x380624a4a7e69db1ca07deecf764025fc224d056/3a97080f477fae848d296585608d15a3.png",
    coingeckoId: "safebitcoin",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7",
    symbol: "APYS",
    name: "APYSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7/logo.png",
    coingeckoId: null,
    listedIn: ["openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x37Ac4D6140e54304D77437A5c11924f61a2D976f",
    symbol: "SFUEL",
    name: "SparkPoint Fuel",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x37ac4d6140e54304d77437a5c11924f61a2d976f/402535a637c7ff87d2e6663c43e5869a.png",
    coingeckoId: "sparkpoint-fuel",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x3764Bc0de9B6a68c67929130aaEC16b6060cAb8c",
    symbol: "XIDO",
    name: "XIDOFINANCE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3764bc0de9b6a68c67929130aaec16b6060cab8c/8044a5ba08e4a164c1a44462eb9207ac.png",
    coingeckoId: "xido-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x375483CfA7Fc18F6b455E005D835A8335FbdbB1f",
    symbol: "ECP",
    name: "Eclipse",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x375483cfa7fc18f6b455e005d835a8335fbdbb1f/ffd4ccda04fed31591290c909a671fd2.png",
    coingeckoId: "eclipse-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x373E768f79c820aA441540d254dCA6d045c6d25b",
    symbol: "DERC",
    name: "DeRace Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x373e768f79c820aa441540d254dca6d045c6d25b/0c33875205b5722809a5891614a9abd5.png",
    coingeckoId: "derace",
    listedIn: ["coingecko", "1inch", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x36953B5Ec00A13eDcEceB3aF258D034913D2A79D",
    symbol: "MNFT",
    name: "MANUFACTORY",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x36953b5ec00a13edceceb3af258d034913d2a79d/00c3c90d5ae4703e0b9080d5eabb46f2.png",
    coingeckoId: "manufactory-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x368ce786Ea190f32439074e8d22e12ecb718B44c",
    symbol: "EPIK",
    name: "",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x368ce786ea190f32439074e8d22e12ecb718b44c/logo.png",
    coingeckoId: "epik-prime",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x35e869B7456462b81cdB5e6e42434bD27f3F788c",
    symbol: "MDO",
    name: "Midas Dollar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x35e869b7456462b81cdb5e6e42434bd27f3f788c/cccde6b7b44b13c8c067a13fcaebacb1.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0x3581a7B7BE2eD2EDf98710910fD05b0e8545f1DB",
    symbol: "COR",
    name: "CorgiToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3581a7b7be2ed2edf98710910fd05b0e8545f1db/36e1d767b079f2b7c1b57928b904951b.png",
    coingeckoId: "corginftgame",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x352Cb5E19b12FC216548a2677bD0fce83BaE434B",
    symbol: "BTT",
    name: "Bittorrent",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x352cb5e19b12fc216548a2677bd0fce83bae434b/314558e5bee32a7ea563e18c0b958fa6.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x347E430b7Cd1235E216be58ffA13394e5009E6e2",
    symbol: "GAIA",
    name: "GAIA Everworld",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x347e430b7cd1235e216be58ffa13394e5009e6e2/6fcc8e67c86261940248158fb2993e2c.png",
    coingeckoId: "gaia-everworld",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x340724464cf51a551106cC6657606Ee7d87B28b9",
    symbol: "STC",
    name: "Satoshi Island Coin",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "satoshi-island",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x33aa7797ac6CB8B4652B68E33e5ADd8Ad1218A8D",
    symbol: "AQDC",
    name: "AQDC",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "aquanee",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x339C72829AB7DD45C3C52f965E7ABe358dd8761E",
    symbol: "WANA",
    name: "Wanaka Farm",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x339c72829ab7dd45c3c52f965e7abe358dd8761e/ccb8cb05427ef4f8689f2353c921af6a.png",
    coingeckoId: "wanaka-farm",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x338d44d19c8d45ed9771b8D2B95D988F0e42187F",
    symbol: "SOCCER",
    name: "SOCCER",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://ethapi.openocean.finance/logos/bsc/0x338d44d19c8d45ed9771b8d2b95d988f0e42187f.png",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x336f5A68fD46A25056a6C1D9c06072c691486acC",
    symbol: "MIMIR",
    name: "Mimir Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x336f5a68fd46a25056a6c1d9c06072c691486acc/ac12ad4b2addfca328c41ead7ec624f5.png",
    coingeckoId: "mimir-token",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1",
    symbol: "vDAI",
    name: "Venus DAI",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1/62f636c9df97ebcb59cea3da29fa6991.png",
    coingeckoId: "venus-dai",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x333FD139cAeF6Aa31056cC905987b77B1044d259",
    symbol: "WEB3",
    name: "The Web3 Project",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x333fd139caef6aa31056cc905987b77b1044d259/74480df1ca49a835fef093e80e1f2259.png",
    coingeckoId: "the-web3-project",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x33333ee26a7d02e41c33828B42Fb1E0889143477",
    symbol: "LIQR",
    name: "LIQR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x33333ee26a7d02e41c33828b42fb1e0889143477/28d9f55d047c9af26d257fb8d52b935c.png",
    coingeckoId: "topshelf-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3314d28F86CC1BC7424B66b4b4C23621Eba8D925",
    symbol: "SRIRACHA",
    name: "Sriracha Inu",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3314d28f86cc1bc7424b66b4b4c23621eba8d925/0614d0bd3f70b3e35e94191e49d5da5e.png",
    coingeckoId: "sriracha-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x330F4fe5ef44B4d0742fE8BED8ca5E29359870DF",
    symbol: "JADE",
    name: "Jade Currency",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x330f4fe5ef44b4d0742fe8bed8ca5e29359870df/337ddba028c1c13675ce734c6b537c33.png",
    coingeckoId: "jade-currency",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
    symbol: "DESU",
    name: "Dexsport Protocol Native Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x32f1518baace69e85b9e5ff844ebd617c52573ac/c2ee185eac70c39bdc611401476e0ac5.png",
    coingeckoId: "dexsport",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x32dFFc3fE8E3EF3571bF8a72c0d0015C5373f41D",
    symbol: "JULb",
    name: "JULb",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d/9fb00938f0b303273a86889195dc12fa.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0x32b86D0Fd22426955C3bD4A9FbB14142fcb60355",
    symbol: "SPACE",
    name: "SPACE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x32b86d0fd22426955c3bd4a9fbb14142fcb60355/db67c38888018dec78933b2941464565.png",
    coingeckoId: "boomspace",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3279510E89600ee1767a037DBD0aD49c974063AE",
    symbol: "NFTBS",
    name: "NFTBOOKS",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3279510e89600ee1767a037dbd0ad49c974063ae/1d676761b84fcfa4f4abaa56bbd1224c.png",
    coingeckoId: "nftbooks",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3271D12D5ba36B6582fafA029598FEe0F5F6Db35",
    symbol: "lilfloki",
    name: "Lil Floki",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3271d12d5ba36b6582fafa029598fee0f5f6db35/24ca549203f025924596469e5384ab4f.png",
    coingeckoId: "lil-floki",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x324E8E649A6A3dF817F97CdDBED2b746b62553dD",
    symbol: "XUSD",
    name: "XUSD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x324e8e649a6a3df817f97cddbed2b746b62553dd/159f6dbe55976c4e48b9dc338d1f6923.png",
    coingeckoId: "xusd-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3249fA9E11EfeCe7cb03b4Ad2994F46e54a35843",
    symbol: "CLCT",
    name: "CollectCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3249fa9e11efece7cb03b4ad2994f46e54a35843/c2f6f7dda6df721a21b455b07d6a9b44.png",
    coingeckoId: "collectcoin-2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377",
    symbol: "MBOX",
    name: "Mobox",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377/logo.png",
    coingeckoId: "mobox",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x31b9773f225408129a90788EF013Bd449e283865",
    symbol: "PORN",
    name: "Porn",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x31b9773f225408129a90788ef013bd449e283865.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x31F0Bc450C12eb62B4c617d4C876f7a66470Fcb3",
    symbol: "FREEMOON",
    name: "FREEMOON",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x31f0bc450c12eb62b4c617d4c876f7a66470fcb3/6794a5846efd600f6bc77d2d4a4e01f7.png",
    coingeckoId: "freemoon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x31D0a7AdA4d4c131Eb612DB48861211F63e57610",
    symbol: "START",
    name: "BSCstarter",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x31d0a7ada4d4c131eb612db48861211f63e57610/b96ac4728bd2e2ad0b2ff622cba676fa.png",
    coingeckoId: "bscstarter",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30",
    symbol: "INSUR",
    name: "Bsc-Peg INSUR Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30/f4e8cd10b00b967bbb82d80ca9f53bb1.png",
    coingeckoId: "insurace",
    listedIn: ["coingecko", "1inch", "pancake", "rubic", "lifinance"],
  },
  {
    address: "0x317eb4ad9cfaC6232f0046831322E895507bcBeb",
    symbol: "TDX",
    name: "Tidex Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x317eb4ad9cfac6232f0046831322e895507bcbeb/e9320836dba57c94fafbd689e6952a86.png",
    coingeckoId: "tidex-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x31471E0791fCdbE82fbF4C44943255e923F1b794",
    symbol: "PVU",
    name: "Plant vs Undead Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x31471e0791fcdbe82fbf4c44943255e923f1b794/c68a4607a7ea486ecbe3c0bbb568d523.png",
    coingeckoId: "plant-vs-undead-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x311E015E2e255Dd14edDc4a2107e39649dc81c5D",
    symbol: "FLASH",
    name: "Flash Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x311e015e2e255dd14eddc4a2107e39649dc81c5d/logo.png",
    coingeckoId: "flash-token-2",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x30DCf96a8a0c742AA1F534FAC79e99D320c97901",
    symbol: "STR",
    name: "SourceLess",
    decimals: 13,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x30dcf96a8a0c742aa1f534fac79e99d320c97901/6554243cd7dcd36b3eaf1d2ce44e0797.png",
    coingeckoId: "sourceless",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x30807D3b851A31d62415B8bb7Af7dCa59390434a",
    symbol: "RADIO",
    name: "Radio Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x30807D3b851A31d62415B8bb7Af7dCa59390434a/logo.png",
    coingeckoId: "radioshack",
    listedIn: ["coingecko", "lifinance", "elkfinance"],
  },
  {
    address: "0x3045d1A840364c3657b8Df6c6F86a4359c23472B",
    symbol: "ORI",
    name: "Orica",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x3045d1a840364c3657b8df6c6f86a4359c23472b/072b73ef6cc1b30c80544a0c8ec4a987.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x301AF3Eff0c904Dc5DdD06FAa808f653474F7FcC",
    symbol: "UNB",
    name: "Unbound",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x301af3eff0c904dc5ddd06faa808f653474f7fcc/bbada11622ba88955a639bbd9caafe72.png",
    coingeckoId: "unbound-finance",
    listedIn: ["coingecko", "dfyn", "lifinance"],
  },
  {
    address: "0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1",
    symbol: "GMT",
    name: "GMT",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/23597/small/gmt.png?1644658792",
    coingeckoId: "stepn",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0",
    symbol: "vSXP",
    name: "Venus SXP",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0/5ae66141c85e78fc7326bdb95a96eaba.png",
    coingeckoId: "venus-sxp",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x2fF0B946A6782190C4Fe5D4971CFE79F0b6E4df2",
    symbol: "MYST",
    name: "Mysterium",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2ff0b946a6782190c4fe5d4971cfe79f0b6e4df2/a5888a02086ca3db8ce214d756e9d807.png",
    coingeckoId: "mysterium",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x2f7b4C618Dc8E0bBA648E54cDADce3D8361f9816",
    symbol: "NFTL",
    name: "NFTL Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x2f7b4c618dc8e0bba648e54cdadce3d8361f9816.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x2f53C0E79646c414FcF5aACAf7C8310b074C40A3",
    symbol: "EXMOC",
    name: "EXMOC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2f53c0e79646c414fcf5aacaf7c8310b074c40a3/44c1c34fa4661726cff5aaa58e7b5190.png",
    coingeckoId: "exmoc",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66",
    symbol: "LEAD",
    name: "Lead Token on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2ed9e96edd11a1ff5163599a66fb6f1c77fa9c66/e9faa05d5e7451f75f28823e561c4b3a.png",
    coingeckoId: "lead-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2ec79904C2aB4F8b6e8e89c743CB7F7a88DFc0fE",
    symbol: "ALTS",
    name: "AltSwitch",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x2ec79904c2ab4f8b6e8e89c743cb7f7a88dfc0fe/logo.png",
    coingeckoId: "altswitch",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3",
    symbol: "MASK",
    name: "Mask Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3/logo.png",
    coingeckoId: "mask-network",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x2d871631058827b703535228fb9Ab5F35cf19E76",
    symbol: "deFRAX",
    name: "deBridge Frax",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x2d871631058827b703535228fb9ab5f35cf19e76.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x2d6F3Dc5b202ccd91Db114B592872BCA32a7e292",
    symbol: "SAUDISHIB",
    name: "SAUDI SHIBA INU",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2d6f3dc5b202ccd91db114b592872bca32a7e292/9a2767a8699e74efe8f89ed8a8283c7e.png",
    coingeckoId: "saudi-shiba-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2d69c55baEcefC6ec815239DA0a985747B50Db6E",
    symbol: "TFF",
    name: "Tutti Frutti",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2d69c55baecefc6ec815239da0a985747b50db6e/f7b8f809267b4f2172c4b166b63f9101.png",
    coingeckoId: "tutti-frutti-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x2d537cc7a9aA092ca3dD1Ee542c0B73560cB14f0",
    symbol: "LooBr",
    name: "LooBr",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2d537cc7a9aa092ca3dd1ee542c0b73560cb14f0/9ef625f8144683095f41336512a0ab5b.png",
    coingeckoId: "loobr",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2cd2664Ce5639e46c6a3125257361e01d0213657",
    symbol: "USELESS",
    name: "Useless",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2cd2664ce5639e46c6a3125257361e01d0213657/0e72881c5755abae723ab4559390eb05.png",
    coingeckoId: "useless",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2caCe984Dab08bd192A7fD044276060CB955dD9C",
    symbol: "ACCEL",
    name: "ACCEL",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2cace984dab08bd192a7fd044276060cb955dd9c/343b31873989849ab48a61acb837671d.png",
    coingeckoId: "accel-defi",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x2cD96e8C3FF6b5E01169F6E3b61D28204E7810Bb",
    symbol: "LBlock",
    name: "LuckyBlock",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2cd96e8c3ff6b5e01169f6e3b61d28204e7810bb/7809d8b98865bb3b21e98e2974c3130f.png",
    coingeckoId: "lucky-block",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c",
    symbol: "tBTC",
    name: "Tau Bitcoin",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c/logo.png",
    coingeckoId: "t-bitcoin",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x2cA25319E2E63719F87221D8bF3646F8F5dE5DED",
    symbol: "EGT",
    name: "ElonGoat",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2ca25319e2e63719f87221d8bf3646f8f5de5ded/80554c55bcec90b813c5b97b3cfb4510.png",
    coingeckoId: "elon-goat",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2c44b726ADF1963cA47Af88B284C06f30380fC78",
    symbol: "PEOPLE",
    name: "ConstitutionDAO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x2c44b726ADF1963cA47Af88B284C06f30380fC78.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x2c094F5A7D1146BB93850f629501eB749f6Ed491",
    symbol: "CROX",
    name: "Crox Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2c094f5a7d1146bb93850f629501eb749f6ed491/bf3fed912b54e817baeb685894c97c19.png",
    coingeckoId: "croxswap",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256",
    symbol: "MARSH",
    name: "Unmarshal",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256/logo.png",
    coingeckoId: "unmarshal",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x2C717059b366714d267039aF8F59125CAdce6D8c",
    symbol: "MHUNT",
    name: "MetaShooter",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2c717059b366714d267039af8f59125cadce6d8c/9984d8a66ebbce79ac1c5f84166c272d.png",
    coingeckoId: "metashooter",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x2B3F34e9D4b127797CE6244Ea341a83733ddd6E4",
    symbol: "FLOKI",
    name: "FLOKI",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2b3f34e9d4b127797ce6244ea341a83733ddd6e4/2f26d7577fdf72edb99b19685f8c39b5.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x2A9718defF471f3Bb91FA0ECEAB14154F150a385",
    symbol: "ElonGate",
    name: "ElonGate",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2a9718deff471f3bb91fa0eceab14154f150a385/a6aa03bf318fa9c738d8f755616e335a.png",
    coingeckoId: "elongate",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x2A48eCe377b87ce941406657B9278b4459595E06",
    symbol: "LunaT",
    name: "Lunatics",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2a48ece377b87ce941406657b9278b4459595e06/7d886fa98b9b3b003102129e5363ae67.png",
    coingeckoId: "lunatics",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x2A1d286ed5edAD78BeFD6E0d8BEb38791e8cD69d",
    symbol: "CLIMB",
    name: "Climb Token Finance",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2a1d286ed5edad78befd6e0d8beb38791e8cd69d/4cf83cc3794e24714d0993828e2539bd.png",
    coingeckoId: "climb-token-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x29a63F4B209C29B4DC47f06FFA896F32667DAD2C",
    symbol: "PURSE",
    name: "PURSE TOKEN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x29a63f4b209c29b4dc47f06ffa896f32667dad2c/e4a75b08c8da0d3eda33cd7dec320d40.png",
    coingeckoId: "pundi-x-purse",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x29A75Ec2d2B8a57fDc45094dC51feFD147c908d8",
    symbol: "TUR",
    name: "Turex",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x29a75ec2d2b8a57fdc45094dc51fefd147c908d8/2997efa293a5dc31ad05cd93158af995.png",
    coingeckoId: "turex",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x298Eff8af1ecEbbB2c034eaA3b9a5d0Cc56c59CD",
    symbol: "SOUL",
    name: "Phantasma Stake",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x298eff8af1ecebbb2c034eaa3b9a5d0cc56c59cd/9b37e15b5e19f9b39f05b8b98753b9f8.png",
    coingeckoId: "phantasma",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x298499f46FbbD468cA1Ec7DE1e29ADfde2E5b373",
    symbol: "TDG",
    name: "ToyDOGE",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x298499f46fbbd468ca1ec7de1e29adfde2e5b373/94e754c395e282a2a5932e2d45ca0ff2.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x293C3Ee9ABaCb08BB8ceD107987F00EfD1539288",
    symbol: "EMPIRE",
    name: "Empire Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x293c3ee9abacb08bb8ced107987f00efd1539288/f94792a541b8ea290337635e7bcca81a.png",
    coingeckoId: "empire-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x291C4e4277F8717e0552D108dBd7f795a9fEF016",
    symbol: "LZN",
    name: "Luzion Protocol",
    decimals: 5,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x291c4e4277f8717e0552d108dbd7f795a9fef016/d8500245788a92d9ee4f3b09b59ba937.png",
    coingeckoId: "luzion-protocol",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    symbol: "SHIB",
    name: "SHIBA INU",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2859e4544c4bb03966803b044a93563bd2d0dd4d/4a32c50cc0a9df0a0e94dff6ce0955f6.png",
    coingeckoId: null,
    listedIn: ["openocean", "rubic", "lifinance"],
  },
  {
    address: "0x284AC5aF363BDe6eF5296036aF8fb0e9Cc347B41",
    symbol: "HUSL",
    name: "The HUSL [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x284ac5af363bde6ef5296036af8fb0e9cc347b41/c7dbc06ea7d513f6dfdc1baeb8d99964.png",
    coingeckoId: "the-husl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2849b1aE7E04A3D9Bc288673A92477CF63F28aF4",
    symbol: "SALT",
    name: "Salt Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4/d6e8cf7baa5e9c0fa03a073c8f5f720f.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x27d51bcA0B145b3F701178c3Da5b66cDF66bD04E",
    symbol: "SUNEKU",
    name: "Suneku",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x27d51bca0b145b3f701178c3da5b66cdf66bd04e/c8805421fdf946ac7c3df8c7cf6050d8.png",
    coingeckoId: "suneku",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x27a339d9B59b21390d7209b78a839868E319301B",
    symbol: "OPEN",
    name: "Openworld",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x27a339d9b59b21390d7209b78a839868e319301b/d864e7ee84c671b3b5f4b78e45a8f911.png",
    coingeckoId: "cryptowar-xblade",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d",
    symbol: "CUMMIES",
    name: "CumRocket",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x27ae27110350b98d564b9a3eed31baebc82d878d/b5e1773bc7968ebcb20f7bcf2a4db752.png",
    coingeckoId: "cumrocket",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x2794DAD4077602eD25A88d03781528D1637898B4",
    symbol: "VITE",
    name: "Vite",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2794dad4077602ed25a88d03781528d1637898b4/dfafe0572948a5ed7a8a112157c4c25a.png",
    coingeckoId: "vite",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2789604Fe261ADC1aED3927f41277D8bFfe33C36",
    symbol: "CATCHY",
    name: "Catchy",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2789604fe261adc1aed3927f41277d8bffe33c36/3c5615454892edfa0c43e48a7d2b547b.png",
    coingeckoId: "catchy",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x276B440fdB4C54631C882caC9e4317929e751FF8",
    symbol: "REV",
    name: "Revolution",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x276b440fdb4c54631c882cac9e4317929e751ff8/5d3c2f1f7992e2e92ba3e1bef54235b6.png",
    coingeckoId: "revolution",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x275b686A5c7312E50299b1c64507c90eE8a381A0",
    symbol: "CHT",
    name: "Charlie Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x275b686a5c7312e50299b1c64507c90ee8a381a0/logo.png",
    coingeckoId: "charlie-finance",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x273a4fFcEb31B8473D51051Ad2a2EdbB7Ac8Ce02",
    symbol: "POL",
    name: "Polars",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x273a4ffceb31b8473d51051ad2a2edbb7ac8ce02/66cacf5eb9e7b714950f250e9b897e17.png",
    coingeckoId: "polars",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x273971059E0082AAc306A78acC67Ac01551B565F",
    symbol: "MEMELON",
    name: "MEME ELON DOGE FLOKI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x273971059e0082aac306a78acc67ac01551b565f/ce6d2f888de256839370bea19c0516e7.png",
    coingeckoId: "meme-elon-doge-floki",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x270388e0CA29CFd7C7E73903D9d933a23D1BAB39",
    symbol: "TSX",
    name: "TSX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x270388e0ca29cfd7c7e73903d9d933a23d1bab39/6664735a0195e7eef0d223dac880d7bc.png",
    coingeckoId: "tradestars",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x26e0AcB5C0f17cB13658820a7457De91d9439F94",
    symbol: "3QT",
    name: "3QT",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "3qt",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x26d3163b165BE95137CEe97241E716b2791a7572",
    symbol: "DSHARE",
    name: "DSHARE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x26d3163b165be95137cee97241e716b2791a7572/48bf5f99f1e5e6896fba61be09e4a12c.png",
    coingeckoId: "dibs-share",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x26A5dFab467d4f58fB266648CAe769503CEC9580",
    symbol: "xMARK",
    name: "xMARK",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x26A5dFab467d4f58fB266648CAe769503CEC9580/logo.png",
    coingeckoId: "xmark",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x2668BAbeAB11780c516B1d3aD02011668AFF8aa0",
    symbol: "PRM",
    name: "Primal",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x2668babeab11780c516b1d3ad02011668aff8aa0/logo.png",
    coingeckoId: "primal",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x264387Ad73d19408e34b5d5E13A93174a35CEA33",
    symbol: "FND",
    name: "Rare Fnd",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x264387ad73d19408e34b5d5e13a93174a35cea33/594c1c5bfc30a78d1a449e9cdd7289a7.png",
    coingeckoId: "rare-fnd",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x26433c8127d9b4e9B71Eaa15111DF99Ea2EeB2f8",
    symbol: "MANA",
    name: "Decentraland",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x2629EB2eDEF0F45B9f250F4Ec934BbC8628dbaca",
    symbol: "SHIBEV",
    name: "Shiba Elon Verse",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x2629eb2edef0f45b9f250f4ec934bbc8628dbaca/logo.png",
    coingeckoId: "shibaelonverse",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x26193C7fa4354AE49eC53eA2cEBC513dc39A10aa",
    symbol: "SEA",
    name: "SEA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/21711/small/12432.png?1639784107",
    coingeckoId: "starsharks-sea",
    listedIn: ["coingecko", "openocean", "lifinance", "xyfinance"],
  },
  {
    address: "0x26165a5a3Dd21FA528bECf3Ff7F114D00a517344",
    symbol: "META",
    name: "META",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x26165a5a3dd21fa528becf3ff7f114d00a517344/ffec751f7de82a50510656a1b47724df.png",
    coingeckoId: "meta-bsc",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x25b0c0D8dcEE3051337ba9382eAD9040aB3D5531",
    symbol: "SHIBAX",
    name: "Shiba X",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x25b0c0d8dcee3051337ba9382ead9040ab3d5531/2a275e305c425ee4e4404a864e9fa584.png",
    coingeckoId: "shiba-x",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x25E9d05365c867E59C1904E7463Af9F312296f9E",
    symbol: "ATM",
    name: "Atletico de Madrid",
    decimals: 2,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x25E9d05365c867E59C1904E7463Af9F312296f9E/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x25B9d4b9535920194c359d2879dB6a1382c2ff26",
    symbol: "TEN",
    name: "Teneo",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x25b9d4b9535920194c359d2879db6a1382c2ff26/e2699191d2b5c1d9e01a59c9e8791e82.png",
    coingeckoId: "teneo",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x25A528af62e56512A19ce8c3cAB427807c28CC19",
    symbol: "FORM",
    name: "Formation Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x25A528af62e56512A19ce8c3cAB427807c28CC19/logo.png",
    coingeckoId: "formation-fi",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x257a8d1E03D17B8535a182301f15290F11674b53",
    symbol: "KWT",
    name: "Kawaii Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x257a8d1e03d17b8535a182301f15290f11674b53/94d2c47afbbc50fb7eb42064594c55bb.png",
    coingeckoId: "kawaii-islands",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C",
    symbol: "SWTH",
    name: "Switcheo",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B",
    symbol: "BETH",
    name: "Beacon ETH",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B/logo.png",
    coingeckoId: "binance-eth",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x24cE3d571fBcFD9D81dc0e1a560504636a4D046d",
    symbol: "MetaPets",
    name: "MetaPets",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x24ce3d571fbcfd9d81dc0e1a560504636a4d046d/24d9e06e6ce513e693173d161ddd5b3a.png",
    coingeckoId: "metapets",
    listedIn: ["coingecko", "openocean", "rubic", "lifinance"],
  },
  {
    address: "0x24802247bD157d771b7EFFA205237D8e9269BA8A",
    symbol: "THC",
    name: "Thetan Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x24802247bd157d771b7effa205237d8e9269ba8a/e412469ad6355e2000cbff3a53e7e3b2.png",
    coingeckoId: "thetan-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x246475dF8703BE0C2bA2f8d0fb7248D95Cc1Ba26",
    symbol: "PAPR",
    name: "PAPR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x246475df8703be0c2ba2f8d0fb7248d95cc1ba26/8d60351e7d5edf389178d5fd96630a69.png",
    coingeckoId: "paprprintr-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5",
    symbol: "CDS",
    name: "Crypto Development Services",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/21304/large/JhUZ3Rk.png",
    coingeckoId: "crypto-development-services",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e",
    symbol: "lUSD",
    name: "lUSD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e/logo.png",
    coingeckoId: "lusd",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x23e2efA091112B85aF95Cf9195E505FD58a3DC8F",
    symbol: "MASTER",
    name: "Beast Masters Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x23e2efa091112b85af95cf9195e505fd58a3dc8f/f5a249732f6f779eb3782f11facfc78f.png",
    coingeckoId: "beast-masters",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8",
    symbol: "jEUR",
    name: "Jarvis Synthetic Euro",
    decimals: 18,
    chainId: "56",
    logoURI: "https://assets.coingecko.com/coins/images/15725/large/jEUR.png",
    coingeckoId: "jarvis-synthetic-euro",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x23EC58e45ac5313BCB6681F4f7827B8a8453AC45",
    symbol: "ZEFU",
    name: "Zenfuse Trading Platform Token (BSC)",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x23ec58e45ac5313bcb6681f4f7827b8a8453ac45/a1a54fc87a17d58c0f86c3f044a40ad6.png",
    coingeckoId: "zenfuse",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978",
    symbol: "DAR",
    name: "Dalarnia",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x23ce9e926048273ef83be0a3a8ba9cb6d45cd978/755625cbd002d2633030e561d055318e.png",
    coingeckoId: "mines-of-dalarnia",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x238D02eE3F80FBf5E381F049616025c186889B68",
    symbol: "MRS",
    name: "Metars",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "metars-genesis",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x233d91A0713155003fc4DcE0AFa871b508B3B715",
    symbol: "DITTO",
    name: "Ditto",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x233d91A0713155003fc4DcE0AFa871b508B3B715/logo.png",
    coingeckoId: "ditto",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
    symbol: "UST",
    name: "UST Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/logo.png",
    coingeckoId: "wrapped-ust",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x232FB065D9d24c34708eeDbF03724f2e95ABE768",
    symbol: "SHEESHA",
    name: "Sheesha Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x232fb065d9d24c34708eedbf03724f2e95abe768/e23051e5e22b3d1ec61a7f4c780c7fd3.png",
    coingeckoId: "sheesha-finance",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance"],
  },
  {
    address: "0x2326C7395d02A8C89a9d7A0b0C1Cf159D49cE51c",
    symbol: "TBAC",
    name: "BlockAura BEP",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2326c7395d02a8c89a9d7a0b0c1cf159d49ce51c/d7b63964d110b5a53ef81820f9f5a090.png",
    coingeckoId: "blockaura",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x231cF6F78620e42Fe00D0c5C3088b427F355d01c",
    symbol: "MOVE",
    name: "MarketMove",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x231cf6f78620e42fe00d0c5c3088b427f355d01c/974e44273356353e88ad719714db8c05.png",
    coingeckoId: "marketmove",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x22f3997A5DF5a80e29871FEd24fE3E85741F5E82",
    symbol: "DAO",
    name: "DAO Maker [via ChainPort.io]",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x22f3997a5df5a80e29871fed24fe3e85741f5e82/56e35857b43d615343d1a0f08d13392b.png",
    coingeckoId: "dao-maker",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x22A213852Cee93EB6d41601133414d180c5684C2",
    symbol: "0xMR",
    name: "0xMonero on xDai from xDai",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "0xmonero",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x227a3EF4d41d0215123f3197Faa087Bf71d2236a",
    symbol: "SCK",
    name: "Space Corsair Key",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x227a3ef4d41d0215123f3197faa087bf71d2236a/4b97337083b55e040a2a3239f6037089.png",
    coingeckoId: "space-corsair-key",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x22648C12acD87912EA1710357B1302c6a4154Ebc",
    symbol: "WCHI",
    name: "WrappedCHI",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x22648c12acd87912ea1710357b1302c6a4154ebc/a5dc1fc4b76813cc619195af72d975a4.png",
    coingeckoId: "chimaera",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x222cF80A8514f8ce551C06D1b8d01DB3678688AD",
    symbol: "DAWGS",
    name: "SpaceDawgs",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x222cf80a8514f8ce551c06d1b8d01db3678688ad/16760e413020322664ea0fb1c10bce58.png",
    coingeckoId: "spacedawgs",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2222227E22102Fe3322098e4CBfE18cFebD57c95",
    symbol: "TLM",
    name: "Alien Worlds",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2222227E22102Fe3322098e4CBfE18cFebD57c95/logo.png",
    coingeckoId: "alien-worlds",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x22168882276e5D5e1da694343b41DD7726eeb288",
    symbol: "WSB",
    name: "WSB Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x22168882276e5d5e1da694343b41dd7726eeb288/73fd841ee1ab178b0070f62da437a05a.png",
    coingeckoId: "wall-street-bets-dapp",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x21F9B5b2626603e3F40bfc13d01AfB8c431D382F",
    symbol: "ZINU",
    name: "Zombie Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x21f9b5b2626603e3f40bfc13d01afb8c431d382f/a18689645b17522e9a0a44c9dee9573b.png",
    coingeckoId: "zombie-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x2198B69B36B86F250549d26D69C5957912A34Ec2",
    symbol: "MATE",
    name: "Mate",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x2198b69b36b86f250549d26d69c5957912a34ec2/4dbe7d8d379ff7b932bdfa417d05b71d.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    symbol: "WETH",
    name: "Binance Pegged ETH",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/logo.png",
    coingeckoId: "weth",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x211FfbE424b90e25a15531ca322adF1559779E45",
    symbol: "BUX",
    name: "BUX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x211FfbE424b90e25a15531ca322adF1559779E45/logo.png",
    coingeckoId: "blockport",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x211Fa9E7E390c29B0AB1a9248949A0aB716c4154",
    symbol: "MOM",
    name: "Mother of Memes",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x211fa9e7e390c29b0ab1a9248949a0ab716c4154/logo.png",
    coingeckoId: "mother-of-memes",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x21004b11939359E7E962Db6675d56f50353dF29C",
    symbol: "EFT",
    name: "EternalFlow",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x21004b11939359e7e962db6675d56f50353df29c/947d06700ed773e76303555d59833fe7.png",
    coingeckoId: "eternalflow",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333",
    symbol: "DRIP",
    name: "DRIP Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x20f663cea80face82acdfa3aae6862d246ce0333/6e4fc51b32f7e95bcb6f253f96bbf6a6.png",
    coingeckoId: "drip-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x20eE7B720f4E4c4FFcB00C4065cdae55271aECCa",
    symbol: "NFT",
    name: "APENFT",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x20ee7b720f4e4c4ffcb00c4065cdae55271aecca/db2485ea59f024e554ea3d40ee09ac33.png",
    coingeckoId: "apenft",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6",
    symbol: "CHESS",
    name: "Tranchess",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6/logo.png",
    coingeckoId: "tranchess",
    listedIn: [
      "coingecko",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A",
    symbol: "FUEL",
    name: "Fuel",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A/logo.png",
    coingeckoId: "fuel-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x208cfEc94d2BA8B8537da7A9BB361c6baAD77272",
    symbol: "SHELL",
    name: "Meta Apes Shell",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x208cfec94d2ba8b8537da7a9bb361c6baad77272/c7f9a5cb729f8bda0cb93dc60abd1883.png",
    coingeckoId: "meta-apes-shell",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x205AFD08ceFe438377a0abC5a20Cb4462E1a8C5C",
    symbol: "OBROK",
    name: "OBRok",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x205afd08cefe438377a0abc5a20cb4462e1a8c5c/048e8b2a8953c56d8e5efa1a5a93d977.png",
    coingeckoId: "obrok",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271",
    symbol: "ATL",
    name: "Atlantis",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1fd991fb6c3102873ba68a4e6e6a87b3a5c10271/9bf38ef31c4b33619627d6d5e18b7008.png",
    coingeckoId: "atlantis-loans",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x1f8a1eFbf6247139fB9cBdb9d4deA34E3D18C20A",
    symbol: "FREN",
    name: "FrenCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1f8a1efbf6247139fb9cbdb9d4dea34e3d18c20a/b081e0a59cfc4c245e3475944ca7d1ee.png",
    coingeckoId: "frencoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1f3Af095CDa17d63cad238358837321e95FC5915",
    symbol: "MINT",
    name: "Mint.club",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1f3af095cda17d63cad238358837321e95fc5915/055dbef6bd151c8034eb3ee99d6821b4.png",
    coingeckoId: "mint-club",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    symbol: "deUSDC",
    name: "deBridge USD Coin",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "lifinance"],
  },
  {
    address: "0x1d9a278a0f84cb13CebF154FaF766a98F63B7b12",
    symbol: "CORNX",
    name: "ChainCorn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1d9a278a0f84cb13cebf154faf766a98f63b7b12/d8fa4187745ed540f2619ff09803e9c9.png",
    coingeckoId: "chaincorn",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1d6Cbdc6b29C6afBae65444a1f65bA9252b8CA83",
    symbol: "TOR",
    name: "TOR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1d6cbdc6b29c6afbae65444a1f65ba9252b8ca83/01fdc5a58de24d4941538e89722656ca.png",
    coingeckoId: "tor",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1c3C3941aCB8A9Be35e50F086fAE6a481f7d9DF7",
    symbol: "SQUID",
    name: "SQUID",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1c3c3941acb8a9be35e50f086fae6a481f7d9df7/ceaa4bcbe9339a7cc492b8daa337f08b.png",
    coingeckoId: "squid-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x1c213179C2c08906fB759878860652a61727Ed14",
    symbol: "ZD",
    name: "ZD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x1c213179c2c08906fb759878860652a61727ed14.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb",
    symbol: "SACT",
    name: "srnArtGallery",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1ba8c21c623c843cd4c60438d70e7ad50f363fbb/dce1596025164345430b411c2204373b.png",
    coingeckoId: "srnartgallery",
    listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
  },
  {
    address: "0x1bA8D3C4c219B124d351F603060663BD1bcd9bbF",
    symbol: "TORN",
    name: "Tornado Cash",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1ba8d3c4c219b124d351f603060663bd1bcd9bbf/96db8959e809c03100dbcd56fbb38b5f.png",
    coingeckoId: "tornado-cash",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1b19c6bB5ea3290dc8b4Cb722Dee9EEa7BC7B164",
    symbol: "BOJI",
    name: "BOJI",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1b19c6bb5ea3290dc8b4cb722dee9eea7bc7b164/2ebdd943f62e52652380b4d3b0a98418.png",
    coingeckoId: "boji",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1aB6478B47270fF05Af11A012Ac17b098758e193",
    symbol: "bcFLUX",
    name: "Flux Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1ab6478b47270ff05af11a012ac17b098758e193/1e891ec40b8586653952940aa905a3ea.png",
    coingeckoId: "flux-protocol",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x1a9b49E9f075c37Fe5f86C916bac9DEb33556D7E",
    symbol: "ASPO",
    name: "ASPOToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1a9b49e9f075c37fe5f86c916bac9deb33556d7e/6b6331b1c6f665ffecc939f8f07288e2.png",
    coingeckoId: "aspo-world",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1a38F34dF3E4E480BFc57b789F605ce87B807848",
    symbol: "NOE",
    name: "NOE CRYPTO BANK",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1a38f34df3e4e480bfc57b789f605ce87b807848/51fe0f831d11fd538f161982da91a338.png",
    coingeckoId: "noe-crypto-bank",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63",
    symbol: "NEAR",
    name: "NEAR Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1fa4a73a3f0133f0025378af00236f3abdee5d63/5ef0e25b6c32a1918e9f0dc6081bc1cd.png",
    coingeckoId: "wrapped-near",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5",
    symbol: "TXL",
    name: "Tixl",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5/logo.png",
    coingeckoId: "autobahn-network",
    listedIn: ["coingecko", "1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1FDB2c3851D067502Ce2122bE80a41ea212949E2",
    symbol: "PINU",
    name: "Pige Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1fdb2c3851d067502ce2122be80a41ea212949e2/e25e33b4a1183bc88ce4e62e93b20eff.png",
    coingeckoId: "pige-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1F98BD9CB8Db314Ced46Dc43C0a91a1F9aDAD4F5",
    symbol: "LEGO",
    name: "Lego coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1f98bd9cb8db314ced46dc43c0a91a1f9adad4f5/6834242b1139747e5739962915d205ad.png",
    coingeckoId: "lego-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1F7216fdB338247512Ec99715587bb97BBf96eae",
    symbol: "bBADGER",
    name: "bBADGER",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1F7216fdB338247512Ec99715587bb97BBf96eae/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1F6D33DadFFB37f1BA4582B2694a67a14AD587e5",
    symbol: "LAZYSHIBA",
    name: "Lazy Shiba",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1f6d33dadffb37f1ba4582b2694a67a14ad587e5/29f1aec9c58ea1256f318421e1ccdefc.png",
    coingeckoId: "lazy-shiba",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1F39Dd2Bf5A27e2D4ED691DCF933077371777CB0",
    symbol: "Nora",
    name: "SnowCrash Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1f39dd2bf5a27e2d4ed691dcf933077371777cb0/4aefa7499918069f05596940601ed541.png",
    coingeckoId: "snowcrash-token",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x1E34fcc9a67E05D4bEED8C6a70F98C3E4D1e1e96",
    symbol: "LAND",
    name: "Outlanders Token",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x1E19f5f52527700186339da4409bb19fbC622F2C",
    symbol: "LCDP",
    name: "LA CASA DE PAPEL TOKEN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1e19f5f52527700186339da4409bb19fbc622f2c/f6cc0e088582eefe11c1a3f5ea4740c6.png",
    coingeckoId: "la-casa-de-papel",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1D3437E570e93581Bd94b2fd8Fbf202d4a65654A",
    symbol: "NBT",
    name: "NanoByte Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1d3437e570e93581bd94b2fd8fbf202d4a65654a/d8c316282bc14efc4430598189a8fecb.png",
    coingeckoId: "nanobyte",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
    symbol: "XRP",
    name: "XRP Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE/logo.png",
    coingeckoId: "binance-peg-xrp",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x1D229B958D5DDFca92146585a8711aECbE56F095",
    symbol: "ZOO",
    name: "ZOO Crypto World",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1d229b958d5ddfca92146585a8711aecbe56f095/3e9e53a2fd06e1696e31ed9076af605e.png",
    coingeckoId: "zoo-crypto-world",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC",
    symbol: "HAKKA",
    name: "Hakka Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1D1cB8997570e73949930c01Fe5796C88d7336c6",
    symbol: "PBR",
    name: "PolkaBridge",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1d1cb8997570e73949930c01fe5796c88d7336c6/a6c988630ff31d379f7c231514323609.png",
    coingeckoId: "polkabridge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
    symbol: "AVAX",
    name: "Avalanche",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1ce0c2827e2ef14d5c4f29a091d735a204794041/bd8f9f89cdbc0a488114d9a9d5d16b3c.png",
    coingeckoId: "binance-peg-avalanche",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1C6bc8e962427dEb4106aE06A7fA2d715687395c",
    symbol: "Charge",
    name: "Charge",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1c6bc8e962427deb4106ae06a7fa2d715687395c/0f8719edea922fda1f909dad3bdbaa6a.png",
    coingeckoId: "chargedefi-charge",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4",
    symbol: "TRONPAD",
    name: "TRONPAD.network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1bf7aedec439d6bfe38f8f9b20cf3dc99e3571c4/378f0ba12506e13387e585e127c96dfe.png",
    coingeckoId: "tronpad",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1Bd5ddCD740F2D13b2255fc325c02509da35C4e4",
    symbol: "BABYCATS",
    name: "Baby Catcoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1bd5ddcd740f2d13b2255fc325c02509da35c4e4/b328e37af572094290812b8d6354fab7.png",
    coingeckoId: "baby-catcoin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb",
    symbol: "ZEC",
    name: "Zcash Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1Ab7E7DEdA201E5Ea820F6C02C65Fce7ec6bEd32",
    symbol: "BOT",
    name: "BOT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1ab7e7deda201e5ea820f6c02c65fce7ec6bed32/5e195ff6c24b8a2475ba526fe0ca1a04.png",
    coingeckoId: "bot-planet",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    symbol: "DAI",
    name: "Binance Pegged DAI",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3/logo.png",
    coingeckoId: "dai",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584",
    symbol: "KUN",
    name: "Qian Governance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584/logo.png",
    coingeckoId: "chemix-ecology-governance-token",
    listedIn: [
      "coingecko",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x19e6BfC1A6e4B042Fb20531244D47E252445df01",
    symbol: "TEM",
    name: "TemplarDAO",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x19e6bfc1a6e4b042fb20531244d47e252445df01/d7c541d6c20d3db89a7e36793281dd9f.png",
    coingeckoId: "templardao",
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x19A6Da6e382b85F827088092a3DBe864d9cCba73",
    symbol: "BISON",
    name: "Bison",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x19a6da6e382b85f827088092a3dbe864d9ccba73/4e9669177d2eec6bba18c4390a87e812.png",
    coingeckoId: "bishares",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x19A4866a85c652EB4a2ED44c42e4CB2863a62D51",
    symbol: "HOD",
    name: "Hodooi.comToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x19a4866a85c652eb4a2ed44c42e4cb2863a62d51/acc00cc06ebd35f6653d248ba6218e89.png",
    coingeckoId: "hodooi-com",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x198800aF50914004A9E9D19cA18C0b24587a50cf",
    symbol: "SUTEKU",
    name: "Suteku SOKU Reward Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x198800af50914004a9e9d19ca18c0b24587a50cf/ef4daebd5570aed03cfad61272582fb5.png",
    coingeckoId: "suteku",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454",
    symbol: "BDO",
    name: "bDollar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454/logo.png",
    coingeckoId: "bdollar",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x18b426813731C144108c6D7FAf5EdE71a258fD9A",
    symbol: "OLYMPUS",
    name: "OLYMPUS",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x18b426813731c144108c6d7faf5ede71a258fd9a/8fe0f831645b11da19c607bab670a485.png",
    coingeckoId: "olympus-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x18aCf236eB40c0d4824Fb8f2582EBbEcD325Ef6a",
    symbol: "OKS",
    name: "Oikos Network Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x18acf236eb40c0d4824fb8f2582ebbecd325ef6a/00f5d141497b63d74e554d4e0786c3f0.png",
    coingeckoId: "oikos",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x18E37F96628dB3037d633FE4D469Fb1933a63C5B",
    symbol: "MBL",
    name: "MovieBloc",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x18E37F96628dB3037d633FE4D469Fb1933a63C5B.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x183B2c147fE11788b73BC0D6A7E0186aA5464BfA",
    symbol: "FFT",
    name: "Futura Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x183b2c147fe11788b73bc0d6a7e0186aa5464bfa/1789fe83cc26df19925a22ee42186553.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x182c763a4b2Fbd18C9B5f2D18102a0dDd9D5DF26",
    symbol: "HOGL",
    name: "HOGL Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26/08ce421e150bc42759a67dbf9d26201a.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x1828e38E7271c44e4BeC6810dC5279223cCd14ff",
    symbol: "MAIR",
    name: "MetaAir",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: "metaair",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x181dE8C57C4F25eBA9Fd27757BBd11Cc66a55d31",
    symbol: "BELUGA",
    name: "BelugaToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x181de8c57c4f25eba9fd27757bbd11cc66a55d31/1cb7657591d8555f67ab9cfeac6d160e.png",
    coingeckoId: "beluga-fi",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x17D749D3E2ac204a07e19D8096d9a05c423ea3af",
    symbol: "LTRBT",
    name: "LITTLE RABBIT",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x17d749d3e2ac204a07e19d8096d9a05c423ea3af/f3c24274b451465cd7bc3e51a29795c8.png",
    coingeckoId: "little-rabbit",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x17B7163cf1Dbd286E262ddc68b553D899B93f526",
    symbol: "QBT",
    name: "Qubit Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x17b7163cf1dbd286e262ddc68b553d899b93f526/a1c664c7543dee3f0e5e8c9072438e46.png",
    coingeckoId: "qubit",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2",
    symbol: "PMON",
    name: "Polkamon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1796ae0b0fa4862485106a0de9b654eFE301D0b2/logo.png",
    coingeckoId: "polychain-monsters",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1785113910847770290F5F840b4c74fc46451201",
    symbol: "WELT",
    name: "FABWELT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1785113910847770290f5f840b4c74fc46451201/d1116912c6ecba52a44385374862fb1d.png",
    coingeckoId: "fabwelt",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x170DC35C5B58AEdEdC81960691eb4b389Eba9E95",
    symbol: "IMRTL",
    name: "Immortl",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x170dc35c5b58aededc81960691eb4b389eba9e95/fe0ad18caa47941e9a9edb6a91a41b82.png",
    coingeckoId: "immortl",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x16dCc0eC78E91e868DCa64bE86aeC62bf7C61037",
    symbol: "EverETH",
    name: "EverETH",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x16dcc0ec78e91e868dca64be86aec62bf7c61037/3d8b3659bd4a1f0ad7a30cc791fcaf5a.png",
    coingeckoId: "evereth",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x16939ef78684453bfDFb47825F8a5F714f12623a",
    symbol: "XTZ",
    name: "Tezos Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x16939ef78684453bfDFb47825F8a5F714f12623a/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "sushiswap", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1633b7157e7638C4d6593436111Bf125Ee74703F",
    symbol: "SPS",
    name: "Splintershards",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1633b7157e7638C4d6593436111Bf125Ee74703F/logo.png",
    coingeckoId: "splinterlands",
    listedIn: ["coingecko", "pancake", "rubic", "lifinance", "elkfinance"],
  },
  {
    address: "0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2",
    symbol: "CGG",
    name: "ChainGuardians",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2/logo.png",
    coingeckoId: "chain-guardians",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x1610bc33319e9398de5f57B33a5b184c806aD217",
    symbol: "vDOT",
    name: "Venus DOT",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1610bc33319e9398de5f57b33a5b184c806ad217/bbfa8a21625ff6c81a4dfbc826d1de9b.png",
    coingeckoId: "venus-dot",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x15669CF161946C09a8B207650BfBB00e3d8A2E3E",
    symbol: "JASMY",
    name: "JasmyCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.pancakeswap.finance/images/0x15669CF161946C09a8B207650BfBB00e3d8A2E3E.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x1562c99ad7179b7D1d862fF4E8BfF6CC016a97ee",
    symbol: "BRDG",
    name: "Bridge Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1562c99ad7179b7d1d862ff4e8bff6cc016a97ee/38719688d7ef86987b7608458a849d74.png",
    coingeckoId: "bridge-network",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409",
    symbol: "REVO",
    name: "REVO",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409/f409ef2ab52a4c44429cfd9a5af9783c.png",
    coingeckoId: "revomon",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab",
    symbol: "SKILL",
    name: "CryptoBlades Skill Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab/logo.png",
    coingeckoId: "cryptoblades",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D",
    symbol: "vXVS",
    name: "Venus XVS",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d/01fa089d3f5785598dc82e184cee0c74.png",
    coingeckoId: "venus-xvs",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x14c358b573a4cE45364a3DBD84BBb4Dae87af034",
    symbol: "DND",
    name: "DungeonSwap Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x14c358b573a4ce45364a3dbd84bbb4dae87af034/78446decc2ae11f2ef879d5ea2c7ce3a.png",
    coingeckoId: "dungeonswap",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x14b1166aB53A237c8cEaeE2BBc4BbCa200cb7da8",
    symbol: "bSRK",
    name: "SparkPoint",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x14b1166ab53a237c8ceaee2bbc4bbca200cb7da8.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x14A9A94E555FDd54C21d7f7E328e61D7eBEce54b",
    symbol: "LOOT",
    name: "LOOT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/22895/small/loot.png?1642850037",
    coingeckoId: "loot",
    listedIn: ["coingecko", "lifinance", "xyfinance"],
  },
  {
    address: "0x145812dd822Cca602161e9E1ea96963be290b549",
    symbol: "CTNT",
    name: "Cryptonite",
    decimals: 4,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x145812dd822cca602161e9e1ea96963be290b549/794c95c5c8bf07a4dc15606075a55afa.png",
    coingeckoId: "cryptonite-token",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x14357D294fBabbE0fbF59503370c772d563b35b6",
    symbol: "HMNG",
    name: "HummingBird Finance",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x14357d294fbabbe0fbf59503370c772d563b35b6/9514da308189e09132d84e0761974318.png",
    coingeckoId: "hummingbird-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x14016E85a25aeb13065688cAFB43044C2ef86784",
    symbol: "TUSD",
    name: "True USD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x14016E85a25aeb13065688cAFB43044C2ef86784/logo.png",
    coingeckoId: "true-usd",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "xyfinance",
      "elkfinance",
    ],
  },
  {
    address: "0x13958e1eb63dFB8540Eaf6ed7DcbBc1A60FD52AF",
    symbol: "FREN",
    name: "Frenchie",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x13958e1eb63dfb8540eaf6ed7dcbbc1a60fd52af/fe41dc57c6e02d8a6871535ca0ea1851.png",
    coingeckoId: "frenchie",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x1311b352467d2B5c296881BaDEA82850bcd8f886",
    symbol: "TOOLS",
    name: "TOOLS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1311b352467d2b5c296881badea82850bcd8f886/ef89e06b00517b55ed8b575c4736237b.png",
    coingeckoId: "tools",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
  },
  {
    address: "0x12e34cDf6A031a10FE241864c32fB03a4FDaD739",
    symbol: "FREE",
    name: "FREE coin BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x12e34cdf6a031a10fe241864c32fb03a4fdad739/9b46fea58ff0582c00c8d5a9b8d51277.png",
    coingeckoId: "freedom-coin",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x12BB890508c125661E03b09EC06E404bc9289040",
    symbol: "RACA",
    name: "RACA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/17841/small/ez44_BSs_400x400.jpg?1629464170",
    coingeckoId: "radio-caca",
    listedIn: [
      "coingecko",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x1215Ed20aa507578ca352E195016F289e7A17f3A",
    symbol: "PID",
    name: "PIDAOToken",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x1215ed20aa507578ca352e195016f289e7a17f3a/d948a61cbafa81526c5773f3bede6b79.png",
    coingeckoId: "pidao",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x1203355742e76875154C0D13eB81DCD7711dC7d9",
    symbol: "USDX",
    name: "USDX",
    decimals: 6,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x1203355742e76875154C0D13eB81DCD7711dC7d9/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0x11fD9ED04f1Eb43eF9df6425a6990609F2468895",
    symbol: "SMTF",
    name: "SmartFi",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x11fd9ed04f1eb43ef9df6425a6990609f2468895/5e38ffff4d1d83177ec1ee4e13f57666.png",
    coingeckoId: "smartfi",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x114597F4260CAf4cDe1eeB0B9D9865B0B7b9a46a",
    symbol: "AGF",
    name: "Augmented Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x114597f4260caf4cde1eeb0b9d9865b0b7b9a46a/8f8209746f7dbcb0636403ea83385df9.png",
    coingeckoId: "augmented-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x111111111117dC0aa78b770fA6A738034120C302",
    symbol: "1INCH",
    name: "1INCH",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/13469/small/1inch-token.png?1608803028",
    coingeckoId: "1inch",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x10f6f2b97F3aB29583D9D38BaBF2994dF7220C21",
    symbol: "TEDDY",
    name: "TeddyDoge",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x10f6f2b97f3ab29583d9d38babf2994df7220c21/eeaae3cce2fece6db9ea8f0be4075d69.png",
    coingeckoId: "teddy-doge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x10b9dd394467f2CFbc769e07E88dC7e2c41B0965",
    symbol: "RET",
    name: "Renewable Energy Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x10b9dd394467f2cfbc769e07e88dc7e2c41b0965/caf43336066dcc17f9c723478692f396.png",
    coingeckoId: "renewable-energy",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x10A12969cB08a8d88D4BFB5d1FA317d41e0fdab3",
    symbol: "MGOD",
    name: "MetaGods",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x10a12969cb08a8d88d4bfb5d1fa317d41e0fdab3/031447d74df27c6b75cd20bff0a27cc8.png",
    coingeckoId: "metagods",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x101d82428437127bF1608F699CD651e6Abf9766E",
    symbol: "BAT",
    name: "Basic Attention Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x101d82428437127bF1608F699CD651e6Abf9766E/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x10051147418C42218986CeDD0aDC266441F8a14f",
    symbol: "DYOR",
    name: "DYOR Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x10051147418c42218986cedd0adc266441f8a14f/7aafa99abdb035c46c93307f0fb9dabb.png",
    coingeckoId: "dyor",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc",
    symbol: "Warden",
    name: "WardenSwap Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0feadcc3824e7f3c12f40e324a60c23ca51627fc/6eec6237b46dbe5523845e73bc86fa0f.png",
    coingeckoId: "warden",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x0f76142D83ddEF359753ccA33647Cc4dCEe1C6d1",
    symbol: "EarnX",
    name: "EarnX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0f76142d83ddef359753cca33647cc4dcee1c6d1/7525cf85612594eed3f9684241af273d.png",
    coingeckoId: "earnx-v2",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x0ecAF010fC192E2d5cBeB4dFb1FeE20fbd733aa1",
    symbol: "SPG",
    name: "Space Crypto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0ecaf010fc192e2d5cbeb4dfb1fee20fbd733aa1/c382ed264c85fb009ea3bebd49708a9c.png",
    coingeckoId: "space-crypto",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x0ebd9537A25f56713E34c45b38F421A1e7191469",
    symbol: "MOOV",
    name: "dotmoovs",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0ebd9537a25f56713e34c45b38f421a1e7191469/c2d15ea137df2aeff93988ab48f2bdb1.png",
    coingeckoId: "dotmoovs",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0e4B5Ea0259eB3D66E6FCB7Cc8785817F8490a53",
    symbol: "SOKU",
    name: "Soku",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0e4b5ea0259eb3d66e6fcb7cc8785817f8490a53/530210247f330295aba6513cae9c634a.png",
    coingeckoId: "sokuswap",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0e0e877894a101Ad8711AE3A0194Fa44Ca837a79",
    symbol: "MOONMOON",
    name: "MoonMoon",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x0e0e877894a101ad8711ae3a0194fa44ca837a79.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x0dDef12012eD645f12AEb1B845Cb5ad61C7423F5",
    symbol: "AEQ",
    name: "Aequinox",
    decimals: 18,
    chainId: "56",
    logoURI: null,
    coingeckoId: "aequinox",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0d9319565be7f53CeFE84Ad201Be3f40feAE2740",
    symbol: "sBDO",
    name: "bDollar Share",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0d9319565be7f53CeFE84Ad201Be3f40feAE2740/logo.png",
    coingeckoId: "bdollar-share",
    listedIn: ["coingecko", "1inch", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x0d3843F92D622468BA67DF5A6a4149B484a75af3",
    symbol: "DINGER",
    name: "Dinger Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0d3843f92d622468ba67df5a6a4149b484a75af3/638658bb6effa1eb7b746e97dc4bdb54.png",
    coingeckoId: "dinger-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE",
    symbol: "BATH",
    name: "Battle Hero",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0bc89aa98ad94e6798ec822d0814d934ccd0c0ce/2dbf69a61a2dc4462f4bdad36d2e3260.png",
    coingeckoId: "battle-hero",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x0b3f42481C228F70756DbFA0309d3ddC2a5e0F6a",
    symbol: "ULTRA",
    name: "UltraSafe",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0b3f42481c228f70756dbfa0309d3ddc2a5e0f6a/f3f5b79e232976f0c489027da52cbaef.png",
    coingeckoId: "ultrasafe",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x0b33542240d6fA323c796749F6D6869fdB7F13cA",
    symbol: "ETHM",
    name: "Ethereum Meta",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0b33542240d6fa323c796749f6d6869fdb7f13ca/5d0aa2350554a7b0dbf3e2cb3b5931bd.png",
    coingeckoId: "ethereum-meta",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89",
    symbol: "IDIA",
    name: "Impossible Decentralized Incubator Access Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89/7e446fa0408d6a38faf8f32db89d6984.png",
    coingeckoId: "idia",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x0b079B33B6e72311c6BE245F9f660CC385029fc3",
    symbol: "APE",
    name: "ApeCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/24383/large/apecoin.jpg",
    coingeckoId: null,
    listedIn: ["openocean", "lifinance"],
  },
  {
    address: "0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5",
    symbol: "RFOX",
    name: "RFOX",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5/logo.png",
    coingeckoId: "redfox-labs-2",
    listedIn: ["coingecko", "pancake", "rubic", "lifinance", "elkfinance"],
  },
  {
    address: "0x0Fc812b96De7e910878039121938f6d5471b73Dc",
    symbol: "TARAL",
    name: "Tarality",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0fc812b96de7e910878039121938f6d5471b73dc/8174503d51c5516ca89321d4e9267c58.png",
    coingeckoId: "tarality",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0FD98b8C58560167A236f1D0553A9c2a42342ccf",
    symbol: "DEVO",
    name: "DeVolution",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0fd98b8c58560167a236f1d0553a9c2a42342ccf/feab679e23e923a0c33eb542e3e0f9b1.png",
    coingeckoId: "devolution",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x0F9E4D49f25de22c2202aF916B681FBB3790497B",
    symbol: "PERL",
    name: "Perlin X",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0F9E4D49f25de22c2202aF916B681FBB3790497B/logo.png",
    coingeckoId: "perlin",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x0F5D54b27bDb556823F96f2536496550f8816dC5",
    symbol: "RITE",
    name: "RITE Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0f5d54b27bdb556823f96f2536496550f8816dc5/50fdaf528d6c4643059c8b6592fc4536.png",
    coingeckoId: "ritestream",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
    symbol: "ATOM",
    name: "Cosmos Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0Eb3a705fc54725037CC9e008bDede697f62F335/logo.png",
    coingeckoId: "cosmos",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x0E8D5504bF54D9E44260f8d153EcD5412130CaBb",
    symbol: "UNCL",
    name: "UNCL on xDai on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0e8d5504bf54d9e44260f8d153ecd5412130cabb/9757dd823f405e8892dbabc564478405.png",
    coingeckoId: "uncl",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0E7BeEc376099429b85639Eb3abE7cF22694ed49",
    symbol: "BUNI",
    name: "Bunicorn",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0e7beec376099429b85639eb3abe7cf22694ed49/541e9de5b625804089463197931bcd54.png",
    coingeckoId: "bunicorn",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x0E5f989ce525acC4ee45506AF91964F7f4C9f2e9",
    symbol: "RYOSHI",
    name: "RYOSHI TOKEN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0e5f989ce525acc4ee45506af91964f7f4c9f2e9/fda4d110f8e6c769e1b52772c23a6a39.png",
    coingeckoId: "ryoshi-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    symbol: "CAKE",
    name: "CAKE",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/12632/small/pancakeswap-cake-logo_%281%29.png?1629359065",
    coingeckoId: "pancakeswap-token",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "rubic",
      "lifinance",
      "xyfinance",
    ],
  },
  {
    address: "0x0DeA5Ac2898E6Aa637Ece0db750Fd6D35e460392",
    symbol: "nuBTC",
    name: "Unvaxxed Sperm",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0dea5ac2898e6aa637ece0db750fd6d35e460392/2d28a6af3c658d2fa289c4af29378377.png",
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4",
    symbol: "STAX",
    name: "StableXSwap",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4/logo.png",
    coingeckoId: null,
    listedIn: ["sushiswap", "openocean", "lifinance", "elkfinance"],
  },
  {
    address: "0x0DFCb45EAE071B3b846E220560Bbcdd958414d78",
    symbol: "LIBERO",
    name: "Libero Financial Freedom",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0dfcb45eae071b3b846e220560bbcdd958414d78/4e571985ad66e99d13833fc28d25bd5c.png",
    coingeckoId: "libero-financial",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
    symbol: "FIL",
    name: "Filecoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153/logo.png",
    coingeckoId: "binance-peg-filecoin",
    listedIn: [
      "coingecko",
      "1inch",
      "sushiswap",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x0C9A6e9173f1345e911C275801327dfdc706937B",
    symbol: "DOGENFT",
    name: "DogeNFTcollector.com",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x0A356f512f6fCE740111ee04Ab1699017a908680",
    symbol: "UFARM",
    name: "UNIFARM Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0a356f512f6fce740111ee04ab1699017a908680/2508d44484bd1ecb6ec0eef72e6bc0cc.png",
    coingeckoId: "unifarm",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x09a6c44c3947B69E2B45F4D51b67E6a39ACfB506",
    symbol: "UNCX",
    name: "UniCrypt on xDai on BSC",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506/8466837ada2fcdf0ab19e0d60bf5d056.png",
    coingeckoId: "unicrypt-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x09607078980CbB0665ABa9c6D1B84b8eAD246aA0",
    symbol: "PETG",
    name: "PET GAMES",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x09607078980cbb0665aba9c6d1b84b8ead246aa0/f2d7f1dbff280d8085c31b301ed5fe0b.png",
    coingeckoId: "pet-games",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x08bd7F9849f8EEC12fd78c9fED6ba4e47269e3d5",
    symbol: "bDOT",
    name: "BabyDot",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x08bd7f9849f8eec12fd78c9fed6ba4e47269e3d5/a83cd416cb1ebe3868e5c3a94b083048.png",
    coingeckoId: "babydot",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x08ba0619b1e7A582E0BCe5BBE9843322C954C340",
    symbol: "BMON",
    name: "Binamon",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x08ba0619b1e7A582E0BCe5BBE9843322C954C340/logo.png",
    coingeckoId: "binamon",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x0864c156b3C5F69824564dEC60c629aE6401bf2a",
    symbol: "DATA",
    name: "Streamr",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0864c156b3c5f69824564dec60c629ae6401bf2a/92d81b24f22a41ecae216001a0896690.png",
    coingeckoId: "streamr",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x0829d2d5cC09d3d341E813c821B0cfAE272D9fb2",
    symbol: "ROCKS",
    name: "ROCKS",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2/0a50953a7f227fc855475615c724045f.png",
    coingeckoId: "social-rocket",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x08037036451C768465369431Da5C671ad9B37dBc",
    symbol: "NFTS",
    name: "NFT STARS COIN",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x08037036451c768465369431da5c671ad9b37dbc/logo.png",
    coingeckoId: "nft-stars",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x07f5cEdeD6B3dba557B3663EDC8941FB37b63945",
    symbol: "LAB v2",
    name: "Little Angry Bunny v2",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x07f5ceded6b3dba557b3663edc8941fb37b63945/1c112f9c3b6486e6b3f91031c2c69197.png",
    coingeckoId: "little-angry-bunny-v2",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x07af67b392B7A202fAD8E0FBc64C34F33102165B",
    symbol: "AQUAGOAT",
    name: "Aquagoat",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x07af67b392b7a202fad8e0fbc64c34f33102165b/1344e05eb4a30861e1e9206856be6ea2.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x07EC61Ae90860641972E9B41A706325a1E928BF8",
    symbol: "VOLT",
    name: "Volt Inu",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x07ec61ae90860641972e9b41a706325a1e928bf8/e4b5c0f4242972530978e93707134a42.png",
    coingeckoId: "volt-inu",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2",
    symbol: "QSD",
    name: "QIAN second generation dollar",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2/logo.png",
    coingeckoId: "qian-second-generation-dollar",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x076DDcE096C93dcF5D51FE346062bF0Ba9523493",
    symbol: "PARA",
    name: "Paralink Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x076ddce096c93dcf5d51fe346062bf0ba9523493/b049f6dd55a8b6b640e060a7fd99b9b8.png",
    coingeckoId: "paralink-network",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x07663837218A003e66310a01596af4bf4e44623D",
    symbol: "rUSD",
    name: "rUSD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x07663837218a003e66310a01596af4bf4e44623d/87477def21bdac3566562a65f7ce9d47.png",
    coingeckoId: "rusd",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4",
    symbol: "SOTA",
    name: "SOTA",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4/logo.png",
    coingeckoId: "sota-finance",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x0725384edD4637f7C26De9CD3C086C14071688D5",
    symbol: "FREEMOON",
    name: "Freemoon",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0725384edd4637f7c26de9cd3c086c14071688d5/e4b23a65f555024af7204960f1f1ec69.png",
    coingeckoId: "freemoon-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0712bc03807EBacC3a457a7149E7Ab3bCD2e8009",
    symbol: "GOHO",
    name: "GooseHonk",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x06ebc9c542357e2129D16717CD02c02FBC835d33",
    symbol: "CAGE",
    name: "Coinage",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x06ebc9c542357e2129d16717cd02c02fbc835d33/a72270252b459f3e364000a9faae4f53.png",
    coingeckoId: "coinage-finance",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0611686A2558de495617685b3Da12448657170FE",
    symbol: "CATpay",
    name: "Catpay",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0611686a2558de495617685b3da12448657170fe/6cf67a45724fad7a9b565ca046eb673a.png",
    coingeckoId: "catpay",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
    symbol: "TINC",
    name: "Tiny Coin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x05ad6e30a855be07afa57e08a4f30d00810a402e/3c743db66cd18e301f0dcfb93e1ec8c5.png",
    coingeckoId: "tiny-coin",
    listedIn: ["coingecko", "pancake", "lifinance"],
  },
  {
    address: "0x05B339B0A346bF01f851ddE47a5d485c34FE220c",
    symbol: "NAUT",
    name: "Astronaut",
    decimals: 8,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x05b339b0a346bf01f851dde47a5d485c34fe220c/d5f713d50b35f90b3cfadc0353bdfbe9.png",
    coingeckoId: "astronaut",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x058a7Af19BdB63411d0a84e79E3312610D7fa90c",
    symbol: "JED",
    name: "JEDSTAR",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x058a7af19bdb63411d0a84e79e3312610d7fa90c/logo.png",
    coingeckoId: "jedstar",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x057AFf3E314e1ca15BED75510df81A20098cE456",
    symbol: "PTT",
    name: "POTENT",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x057aff3e314e1ca15bed75510df81a20098ce456/639fb04634822b53338a878a3678336f.png",
    coingeckoId: "potent-coin",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0565805CA3A4105FAEE51983b0bD8ffB5ce1455C",
    symbol: "GUILD",
    name: "GUILD",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://assets.coingecko.com/coins/images/21271/small/BednjMw.png?1638857799",
    coingeckoId: "blockchainspace",
    listedIn: ["coingecko", "xyfinance"],
  },
  {
    address: "0x050787DE0cF5Da03D9387b344334D51cAE5DD0Fd",
    symbol: "PEKC",
    name: "PEACOCKCOIN",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x050787de0cf5da03d9387b344334d51cae5dd0fd/c1d7f80e03034d914ea2f71d20248883.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x04F73A09e2eb410205BE256054794fB452f0D245",
    symbol: "SALE",
    name: "DxSale.Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x04f73a09e2eb410205be256054794fb452f0d245/adba4309a0a0cbf58afc0b6dcd18760b.png",
    coingeckoId: "dxsale-network",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x04C747b40Be4D535fC83D09939fb0f626F32800B",
    symbol: "ITAM",
    name: "Itam",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x04C747b40Be4D535fC83D09939fb0f626F32800B/logo.png",
    coingeckoId: "itam-games",
    listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
  },
  {
    address: "0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0",
    symbol: "ONE",
    name: "BigOne Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0/logo.png",
    coingeckoId: "one",
    listedIn: ["coingecko", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x047a02a57501a813975b2D347278Fdd3dF671E86",
    symbol: "2022M",
    name: "2022MOON",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x047a02a57501a813975b2d347278fdd3df671e86/138bb5ad989a2f0c592307749e1f5a45.png",
    coingeckoId: "2022moon",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x045c4324039dA91c52C55DF5D785385Aab073DcF",
    symbol: "bCFX",
    name: "Conflux",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x045c4324039dA91c52C55DF5D785385Aab073DcF/logo.png",
    coingeckoId: null,
    listedIn: ["1inch", "pancake", "lifinance", "elkfinance"],
  },
  {
    address: "0x04260673729c5F2b9894A467736f3D85F8d34fC8",
    symbol: "CPAN",
    name: "CryptoPlanes",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x04260673729c5f2b9894a467736f3d85f8d34fc8/260f2f9e19d2538b9a81275fca72aa62.png",
    coingeckoId: "cryptoplanes",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x0409633A72D846fc5BBe2f98D88564D35987904D",
    symbol: "PHB",
    name: "Phoenix Global",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0409633a72d846fc5bbe2f98d88564d35987904d/5602b94e20704799a71a0b7a489c18ef.png",
    coingeckoId: "phoenix-global",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x03fF0ff224f904be3118461335064bB48Df47938",
    symbol: "ONE",
    name: "Harmony One",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x03fF0ff224f904be3118461335064bB48Df47938/logo.png",
    coingeckoId: "wrapped-one",
    listedIn: ["coingecko", "pancake", "rubic", "lifinance", "elkfinance"],
  },
  {
    address: "0x039cD22cb49084142d55FCD4B6096A4F51ffb3B4",
    symbol: "MOVEZ",
    name: "MOVEZ.me",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x039cd22cb49084142d55fcd4b6096a4f51ffb3b4/ee9d62dbb98b079b7f5c9dba1404f7bf.png",
    coingeckoId: "movez",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x039cB485212f996A9DBb85A9a75d898F94d38dA6",
    symbol: "DEXE",
    name: "DeXe",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x039cB485212f996A9DBb85A9a75d898F94d38dA6/logo.png",
    coingeckoId: "dexe",
    listedIn: [
      "coingecko",
      "1inch",
      "openocean",
      "pancake",
      "lifinance",
      "elkfinance",
    ],
  },
  {
    address: "0x039471b9e8a86B977aaeb5eC4182Cf3866f436B0",
    symbol: "TREE",
    name: "OakTree Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://tokens.1inch.io/0x039471b9e8a86b977aaeb5ec4182cf3866f436b0.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
  {
    address: "0x0391bE54E72F7e001f6BBc331777710b4f2999Ef",
    symbol: "TRAVA",
    name: "TravaFinance Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0391be54e72f7e001f6bbc331777710b4f2999ef/e8cd398846e06f5452f8238e90d6edd2.png",
    coingeckoId: "trava-finance",
    listedIn: ["coingecko", "dfyn", "lifinance"],
  },
  {
    address: "0x037838b556d9c9d654148a284682C55bB5f56eF4",
    symbol: "LIGHT",
    name: "Lightning",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x037838b556d9c9d654148a284682c55bb5f56ef4/6154c1d941b5c67ab0bb12029bf2e8cc.png",
    coingeckoId: "lightning-protocol",
    listedIn: ["coingecko", "openocean", "pancake", "lifinance"],
  },
  {
    address: "0x0328A69b363a16f66810B23cB0b8d32Abadb203D",
    symbol: "KANA",
    name: "Kanaloa Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x0328A69b363a16f66810B23cB0b8d32Abadb203D/logo.png",
    coingeckoId: "kanaloa-network",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x030708208dC29B1688b212081F31cDB59097A67D",
    symbol: "KBN",
    name: "Koban",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x030708208dC29B1688b212081F31cDB59097A67D/logo.png",
    coingeckoId: "kbn",
    listedIn: ["coingecko", "elkfinance"],
  },
  {
    address: "0x02fF5065692783374947393723dbA9599e59F591",
    symbol: "YOOSHI",
    name: "YooShi",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x02ff5065692783374947393723dba9599e59f591/f90bd4a8fe74318a5305728c94f8a050.png",
    coingeckoId: "yooshi",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x02b439484227EC3A8798B1777B3076f29Ba2e387",
    symbol: "NLCR",
    name: "Nolian Credits",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x02b439484227ec3a8798b1777b3076f29ba2e387/a230218c6958a72eaf4decedf1a6e876.png",
    coingeckoId: "nolian-credits",
    listedIn: ["coingecko", "rubic", "lifinance"],
  },
  {
    address: "0x02A40C048eE2607B5f5606e445CFc3633Fb20b58",
    symbol: "KABY",
    name: "Kaby Arena",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x02a40c048ee2607b5f5606e445cfc3633fb20b58/bd3f056ffa2a87b3e5c639ed877c0736.png",
    coingeckoId: "kaby-arena",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x02891418ca1C6c9B31c351b3E9A023aD63a1EBe7",
    symbol: "PeaceDoge",
    name: "Peace Doge",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x02891418ca1c6c9b31c351b3e9a023ad63a1ebe7/b4f00a2478c3e6672620d7add6c0d8d3.png",
    coingeckoId: "peace-doge",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07",
    symbol: "ZEFI",
    name: "ZCore Finance",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07/d68f3ed89a554462707dc0b28977e7a4.png",
    coingeckoId: "zcore-finance",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x0255af6c9f86F6B0543357baCefA262A2664f80F",
    symbol: "DARA",
    name: "Immutable",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0255af6c9f86f6b0543357bacefa262a2664f80f/7eef24f51062b64fa3cc61ecdb9af077.png",
    coingeckoId: "immutable",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x0231f91e02DebD20345Ae8AB7D71A41f8E140cE7",
    symbol: "bwJUP",
    name: "BSC Wrapped Jupiter",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0231f91e02debd20345ae8ab7d71a41f8e140ce7/c0fbdc5d05a731e23ba35c2005ce8556.png",
    coingeckoId: "jupiter",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x01E0d17a533E5930A349C2BB71304F04F20AB12B",
    symbol: "RPG",
    name: "",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://api.rubic.exchange/assets/binance-smart-chain/0x01e0d17a533e5930a349c2bb71304f04f20ab12b/logo.png",
    coingeckoId: "revolve-games",
    listedIn: ["coingecko", "rubic"],
  },
  {
    address: "0x016c8dA9D916905a00eF26a2e7dC2EE67B6020cF",
    symbol: "PRNTR",
    name: "PRNTR",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x016c8da9d916905a00ef26a2e7dc2ee67b6020cf/3d991cb7d88cefb23fa2b3d3779ee6d2.png",
    coingeckoId: "prntr",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x016CF83732f1468150D87dCC5BdF67730B3934D3",
    symbol: "AIRT",
    name: "AirNFT Token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x016cf83732f1468150d87dcc5bdf67730b3934d3/a47fbada42cf2387d2c5f4f179f0c2f7.png",
    coingeckoId: "airnft-token",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x0112e557d400474717056C4e6D40eDD846F38351",
    symbol: "PHA",
    name: "Phala Network",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0112e557d400474717056c4e6d40edd846f38351/e227795cf891eccd4bbcc9e25226895f.png",
    coingeckoId: null,
    listedIn: ["pancake", "lifinance"],
  },
  {
    address: "0x0110fF9e7E4028a5337F07841437B92d5bf53762",
    symbol: "BUTTER",
    name: "Butter",
    decimals: 9,
    chainId: "56",
    logoURI: null,
    coingeckoId: null,
    listedIn: ["rubic", "lifinance"],
  },
  {
    address: "0x00f97C17f4Dc4F3BFD2DD9cE5E67f3A339A8a261",
    symbol: "SHIELD",
    name: "Shield Protocol",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x00f97c17f4dc4f3bfd2dd9ce5e67f3a339a8a261/a94c4274dacf3724a97a372b07dab17b.png",
    coingeckoId: "shield-protocol-2",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x00e1656e45f18ec6747F5a8496Fd39B50b38396D",
    symbol: "BCOIN",
    name: "Bomb Crypto",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x00e1656e45f18ec6747f5a8496fd39b50b38396d/7c4757335433aa2c2e74ad577f396413.png",
    coingeckoId: "bomber-coin",
    listedIn: ["coingecko", "openocean", "pancake", "rubic", "lifinance"],
  },
  {
    address: "0x00A0De196A68Ec968792A831EC79E9736Ab24396",
    symbol: "EXC",
    name: "Excursion Token",
    decimals: 9,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x00a0de196a68ec968792a831ec79e9736ab24396/b5472a8165f2f9cd86288e51d3c75995.png",
    coingeckoId: "excursion-token",
    listedIn: ["coingecko", "lifinance"],
  },
  {
    address: "0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56",
    symbol: "CAN",
    name: "CanYaCoin",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://elk.finance/tokens/logos/bsc/0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56/logo.png",
    coingeckoId: "canyacoin",
    listedIn: ["coingecko", "sushiswap", "lifinance", "elkfinance"],
  },
  {
    address: "0x0047A0DEaDAfb7ee6B1A0d219E70Fb6767057D93",
    symbol: "xYSL",
    name: "xYSL token",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0047a0deadafb7ee6b1a0d219e70fb6767057d93/7d068a4ebd8c6388c7edae9391e1071e.png",
    coingeckoId: "xysl",
    listedIn: ["coingecko", "1inch", "lifinance"],
  },
  {
    address: "0x002D8563759f5e1EAf8784181F3973288F6856e4",
    symbol: "DMOD",
    name: "DemodyfiToken",
    decimals: 18,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x002d8563759f5e1eaf8784181f3973288f6856e4/a2eb1d602bfe12cffef9ed776a5572d7.png",
    coingeckoId: "demodyfi",
    listedIn: ["coingecko", "openocean", "lifinance"],
  },
  {
    address: "0x0000000000004946c0e9F43F4Dee607b0eF1fA1c",
    symbol: "CHI",
    name: "Chi Gastoken by 1inch",
    decimals: 0,
    chainId: "56",
    logoURI:
      "https://static.debank.com/image/bsc_token/logo_url/0x0000000000004946c0e9f43f4dee607b0ef1fa1c/f1605dfc42261e19ed2b95e944679a74.png",
    coingeckoId: null,
    listedIn: ["1inch", "lifinance"],
  },
];
