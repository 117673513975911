export const polygon = [
    {
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/matic/6f5a6b6f0732a7a235131bd7804d357c.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xff898089e368eCc47Fc37Fd0D905Fbcf0D70E820",
      symbol: "B20",
      name: "B.20 (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xff88434E29d1E2333aD6baa08D358b436196da6b",
      symbol: "BORING",
      name: "BoringDAO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xff88434e29d1e2333ad6baa08d358b436196da6b/b80532fa6f9f93b04b6db3fe56909c10.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xfe712251173A2cd5F5bE2B46Bb528328EA3565E1",
      symbol: "MVI",
      name: "Metaverse Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfe712251173a2cd5f5be2b46bb528328ea3565e1/f69dde18b24f33b281e8b004d8026be0.png",
      coingeckoId: "metaverse-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9",
      symbol: "cxETH",
      name: "CelsiusX Wrapped ETH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9/17e9d31f5bf16ffae4572d7b3015d53a.png",
      coingeckoId: "celsiusx-wrapped-eth",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xfd4959c06FbCc02250952DAEbf8e0Fb38cF9FD8C",
      symbol: "ZEE",
      name: "ZeroSwapToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfd4959c06fbcc02250952daebf8e0fb38cf9fd8c/7b08bd3f7b9ee31340ffadc3abab00af.png",
      coingeckoId: "zeroswap",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xfd0cbdDec28a93bB86B9db4A62258F5EF25fEfdE",
      symbol: "BITT",
      name: "BITTOKEN (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfd0cbddec28a93bb86b9db4a62258f5ef25fefde/d975e245158b1ac10a9397566fd0ed7c.png",
      coingeckoId: "bittoken",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xfc39742Fe9420a7Af23757Fc7E78D1c3AE4A9474",
      symbol: "eUSDT",
      name: "Easy USDT",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xfc39742Fe9420a7Af23757Fc7E78D1c3AE4A9474/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xfb65ef42F7c8A70ff73F627DB6E9ba2Aff1F20fa",
      symbol: "NDR",
      name: "NodeRunners",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xfb65ef42F7c8A70ff73F627DB6E9ba2Aff1F20fa/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xfad70FD116559914240faB82b0078c4E82a6a1B8",
      symbol: "CPIE",
      name: "CremePieSwap Token",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xfa68FB4628DFF1028CFEc22b4162FCcd0d45efb6",
      symbol: "MaticX",
      name: "Liquid Staking Matic (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfa68fb4628dff1028cfec22b4162fccd0d45efb6/151d92446b0b2098c7766d4081921ec0.png",
      coingeckoId: "stader-maticx",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5",
      symbol: "rUSD",
      name: "rUSD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xfc40a4f89b410a1b855b5e205064a38fc29f5eb5/87477def21bdac3566562a65f7ce9d47.png",
      coingeckoId: "rusd",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xfBdb45075Fb73ca4CC8b83FD6fdB4F9B696B1BA1",
      symbol: "sdeursCRV",
      name: "Stake DAO EUR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xfBdb45075Fb73ca4CC8b83FD6fdB4F9B696B1BA1/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xfA15611AA3775FBfE1375Fb56cd3027F5D3DD2Db",
      symbol: "ADD",
      name: "ADD.XYZ (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
      symbol: "SYN",
      name: "Synapse",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf8f9efc0db77d8881500bb06ff5d6abc3070e695/90e387bcd6f85a586e3ad7427576149b.png",
      coingeckoId: "synapse-2",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0xf89887862538cE8eFBdbAdD5d483AE4A09dD9688",
      symbol: "$TRDL",
      name: "Strudel Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xf89887862538cE8eFBdbAdD5d483AE4A09dD9688/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xf7d9e281c5Cb4C6796284C5b663b3593D2037aF2",
      symbol: "NFTP",
      name: "NFT Platform Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xf7d9e281c5Cb4C6796284C5b663b3593D2037aF2/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xf7058856f405542cd660e8ce4751248F2d037f2B",
      symbol: "YAYO",
      name: "YAYO Coin",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf7058856f405542cd660e8ce4751248f2d037f2b/74ac73573001aff335d97399b192e5fa.png",
      coingeckoId: "yayo-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19",
      symbol: "SPADE",
      name: "PolygonFarm Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf5ea626334037a2cf0155d49ea6462fddc6eff19/d87fed4d66d537c6209d462d21a0779e.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xf50D05A1402d0adAfA880D36050736f9f6ee7dee",
      symbol: "INST",
      name: "Instadapp",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf50d05a1402d0adafa880d36050736f9f6ee7dee/376bab6e695ee40bf7de593c204e3aa3.png",
      coingeckoId: "instadapp",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xf4C83080E80AE530d6f8180572cBbf1Ac9D5d435",
      symbol: "BLANK",
      name: "GoBlank Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435/08859de83ae37a50ea2f08945e2bc279.png",
      coingeckoId: "blank",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xf2b5a8c37278bcdD50727D5CA879f8e5A4642e2e",
      symbol: "MEME",
      name: "MEME",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xf2b5a8c37278bcdD50727D5CA879f8e5A4642e2e/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xf2ae0038696774d65E67892c9D301C5f2CbbDa58",
      symbol: "CXO",
      name: "CargoX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xf2ae0038696774d65E67892c9D301C5f2CbbDa58/logo.png",
      coingeckoId: "cargox",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0xf2D8124b8F9267DaD61351c7aD252362880C6638",
      symbol: "WCRO",
      name: "Wrapped CRO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/7310/small/cypto.png?1547043960",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xf244E91A46A9cdd48da295cA5d0B27894f8032B1",
      symbol: "UART",
      name: "UniArts Network Token (PoS)",
      decimals: 12,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf244e91a46a9cdd48da295ca5d0b27894f8032b1/3f96bd5e87278ccaddc21507ea78a60a.png",
      coingeckoId: "uniarts",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xf153EfF70DC0bf3b085134928daeEA248d9B30d0",
      symbol: "xMARK",
      name: "Standard",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xf153EfF70DC0bf3b085134928daeEA248d9B30d0/logo.png",
      coingeckoId: "xmark",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0xf1428850f92B87e629c6f3A3B75BffBC496F7Ba6",
      symbol: "GEO$",
      name: "GEOPOLY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6/7c8d006bba5de09f07eb4cab9ce3816c.png",
      coingeckoId: "geopoly",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xf0f9D895aCa5c8678f706FB8216fa22957685A13",
      symbol: "RVLT",
      name: "Revolt 2 Earn",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf0f9d895aca5c8678f706fb8216fa22957685a13/af0cff48e5912ad9747e16ebd1e6410a.png",
      coingeckoId: "revolt-2-earn",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xf0059CC2b3E980065A906940fbce5f9Db7ae40A7",
      symbol: "ULT",
      name: "Shardus (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf0059cc2b3e980065a906940fbce5f9db7ae40a7/6873e633e222e01892329e6fcf6e5b22.png",
      coingeckoId: "shardus",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xefFCa2dBB2D9F3816ceE07aE2134574FA9210dcb",
      symbol: "RBAG",
      name: "RebalanceGovernance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xefFCa2dBB2D9F3816ceE07aE2134574FA9210dcb/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xef938b6da8576a896f6E0321ef80996F4890f9c4",
      symbol: "DG",
      name: "Decentral Games (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xef938b6da8576a896f6e0321ef80996f4890f9c4/92d7908366830c111f6f979e83e59945.png",
      coingeckoId: "decentral-games",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xee7666aACAEFaa6efeeF62ea40176d3eB21953B9",
      symbol: "MCHC",
      name: "MCHCoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xee7666aacaefaa6efeef62ea40176d3eb21953b9/548b3ad5616cba1058277ea3fbdebc6e.png",
      coingeckoId: "mch-coin",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xeb45921FEDaDF41dF0BfCF5c33453aCedDA32441",
      symbol: "pZUG",
      name: "pZUG",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD",
      symbol: "PZAP",
      name: "PolyZap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0xeb2778f74e5ee038e67aa6c77f0f0451abd748fd/logo.png",
      coingeckoId: "polyzap",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
      symbol: "PolyMoon",
      name: "PolyMoon",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xefb3009ddac87e8144803d78e235e7fb4cd36e61/55dbe0a379b056daf3722a66399593a9.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
      symbol: "ELK",
      name: "Elk",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png",
      coingeckoId: "elk-finance",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0xeEe3371B89FC43Ea970E908536Fcddd975135D8a",
      symbol: "DOG",
      name: "The Doge NFT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xeee3371b89fc43ea970e908536fcddd975135d8a/15b6ff9dcb0f15586af7c4a2698e94ca.png",
      coingeckoId: "the-doge-nft",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      symbol: "MAHA",
      name: "MahaDAO (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xedd6ca8a4202d4a36611e2fff109648c4863ae19/99948ee1cfc4ab1e5a88c50090e36206.png",
      coingeckoId: "mahadao",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xeCf8f2FA183b1C4d2A269BF98A54fCe86C812d3e",
      symbol: "CFI",
      name: "CyberFi Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xeCf8f2FA183b1C4d2A269BF98A54fCe86C812d3e/logo.png",
      coingeckoId: "cyberfi",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xeCDCB5B88F8e3C15f95c720C51c71c9E2080525d",
      symbol: "WBNB",
      name: "Wrapped BNB (Wormhole)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xecdcb5b88f8e3c15f95c720c51c71c9e2080525d/1177dec1b9b7f58a799c3f22d93b04e1.png",
      coingeckoId: "binance-coin-wormhole",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xe94845Ac6782A2E71C407ABe4D5201445C26a62B",
      symbol: "BUILD",
      name: "Build Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe94845ac6782a2e71c407abe4d5201445c26a62b/c579cd2cdf2a4548d4e3e24b6287c0ce.png",
      coingeckoId: "build",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe86E8beb7340659DDDCE61727E500e3A5aD75a90",
      symbol: "ZUT",
      name: "ZeroUtility",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xe86E8beb7340659DDDCE61727E500e3A5aD75a90/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xe8377A076adAbb3F9838afB77Bee96Eac101ffB1",
      symbol: "MSU",
      name: "MetaSoccer Universe",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe8377a076adabb3f9838afb77bee96eac101ffb1/ce7d8f1b92322872c90906d62ecc1ddb.png",
      coingeckoId: "metasoccer",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xe82808eaA78339b06a691fd92E1Be79671cAd8D3",
      symbol: "PLOT",
      name: "PLOT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xe82808eaA78339b06a691fd92E1Be79671cAd8D3/logo.png",
      coingeckoId: "plotx",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xe7f960685aba7327ADA3b161Ea252703C87be0bF",
      symbol: "BLUE",
      name: "Ethereum Blue (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE",
      symbol: "IGG",
      name: "IG Gold",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE/logo.png",
      coingeckoId: "ig-gold",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
      symbol: "aPolWETH",
      name: "Aave Polygon WETH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8/a27c1def8d782e2f73f2cad6b752406f.png",
      coingeckoId: "aave-weth",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe4Bf2864ebeC7B7fDf6Eeca9BaCAe7cDfDAffe78",
      symbol: "DODO",
      name: "DODO bird (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe4bf2864ebec7b7fdf6eeca9bacae7cdfdaffe78/9a00cd11289d652c7ecc095b460cc328.png",
      coingeckoId: "dodo",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe26cda27c13f4f87cFFc2F437C5900b27eBb5bbB",
      symbol: "RBLS",
      name: "Rebel Bots Token",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb/c057f3c9f7772591aadb559c1f833d45.png",
      coingeckoId: "rebel-bots",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xe212f92E5Af85268B33D2Aa587B51f49C3B945Be",
      symbol: "sdcrvRenWSBTC",
      name: "Stake DAO BTC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xe212f92E5Af85268B33D2Aa587B51f49C3B945Be/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613",
      symbol: "maYFI",
      name: "Matic Aave interest bearing YFI",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xe20D2Df5041f8Ed06976846470f727295cDd4D23",
      symbol: "BIOS",
      name: "BIOS",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe20d2df5041f8ed06976846470f727295cdd4d23/71cdef61eee44c25a933bf14629dcbe4.png",
      coingeckoId: "bios",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034",
      symbol: "wBAN",
      name: "Wrapped Banano",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034/ebce77f5bfef64cf5b252c77749bd8ee.png",
      coingeckoId: "banano",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xe0Ce60AF0850bF54072635e66E79Df17082A1109",
      symbol: "Propel",
      name: "Propel",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe0ce60af0850bf54072635e66e79df17082a1109/a3c1145f0c03e212e20624a766904b85.png",
      coingeckoId: "payrue",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xdef1fac7Bf08f173D286BbBDcBeeADe695129840",
      symbol: "CERBY",
      name: "Cerby Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdef1fac7bf08f173d286bbbdcbeeade695129840/1ef478564ddbbd0440ef43af79437666.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xdc0f3C12dF89593357985491bdAcc5Fd789cA4B2",
      symbol: "mWETH",
      name: "Mushrooming WETH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xdc0f3C12dF89593357985491bdAcc5Fd789cA4B2/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xdb725f82818De83e99F1dAc22A9b5B51d3d04DD4",
      symbol: "GET",
      name: "Guaranteed Entrance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4/97fdc91a07578cf676fec193033a7fd8.png",
      coingeckoId: "get-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xdae89dA41a96956e9e70320Ac9c0dd077070D3a5",
      symbol: "CNTR",
      name: "Centaur Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdae89da41a96956e9e70320ac9c0dd077070d3a5/a15e20bee91297a584ef2fdedfe9ce87.png",
      coingeckoId: "centaur",
      listedIn: ["coingecko", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0xdaB35042e63E93Cc8556c9bAE482E5415B5Ac4B1",
      symbol: "IRIS",
      name: "Iris",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdab35042e63e93cc8556c9bae482e5415b5ac4b1/fd8bc946eb88db62899213bd8f8a97a4.png",
      coingeckoId: "iris-token-2",
      listedIn: ["coingecko", "1inch", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
      symbol: "TEL",
      name: "Telcoin",
      decimals: 2,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32/logo.png",
      coingeckoId: "telcoin",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xdDa40cdfe4A0090f42Ff49f264A831402ADB801A",
      symbol: "DOGIRA",
      name: "Dogira",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdda40cdfe4a0090f42ff49f264a831402adb801a/c469f64b6f176a873a4ce80aedbd9a30.png",
      coingeckoId: "dogira",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xdD2AF2E723547088D3846841fbDcC6A8093313d6",
      symbol: "GOGO",
      name: "GOGOcoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdd2af2e723547088d3846841fbdcc6a8093313d6/9f4bea8f8c452ff6cb7616927d71bdfe.png",
      coingeckoId: "gogocoin",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038",
      symbol: "POLAR",
      name: "Polaris Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
      symbol: "BUSD",
      name: "(PoS) Binance USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7/c67d96935d377f350fac55aed5c458de.png",
      coingeckoId: "binance-usd",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd9E838dd60c8ea1e7dD4E670913323bB87DB112c",
      symbol: "EWT",
      name: "Ecowatt Token",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd9e838dd60c8ea1e7dd4e670913323bb87db112c/5e4752c17209633accc86a628529ba05.png",
      coingeckoId: "ecowatt",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd99baFe5031cC8B345cb2e8c80135991F12D7130",
      symbol: "FRM",
      name: "Ferrum Network Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd99bafe5031cc8b345cb2e8c80135991f12d7130/f610cb2893844f8b6c22656021e9d134.png",
      coingeckoId: "ferrum-network",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0xd93f7E271cB87c23AaA73edC008A79646d1F9912",
      symbol: "SOL",
      name: "Wrapped SOL (Wormhole)",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd93f7e271cb87c23aaa73edc008a79646d1f9912/7c5db5c2eae571da837b65f5b9ae1a5c.png",
      coingeckoId: "sol-wormhole",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd93c61D4418d77A537b6B57478C108E193362f0C",
      symbol: "mBONK",
      name: "MegaBONK",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xd93c61D4418d77A537b6B57478C108E193362f0C/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xd8cA34fd379d9ca3C6Ee3b3905678320F5b45195",
      symbol: "gOHM",
      name: "Governance Ohm",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195/704f31840239bfc0215c1dbeb0bf4160.png",
      coingeckoId: "governance-ohm",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947",
      symbol: "pBTC",
      name: "pBTC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947/logo.png",
      coingeckoId: "ptokens-btc-2",
      listedIn: ["coingecko", "quickswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xd78C475133731CD54daDCb430F7aAE4F03C1E660",
      symbol: "HOPE",
      name: "Firebird.Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd78c475133731cd54dadcb430f7aae4f03c1e660/1aac076825f2bfd732da3fb71673f91f.png",
      coingeckoId: "firebird-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xd6A5aB46ead26f49b03bBB1F9EB1Ad5c1767974a",
      symbol: "EMON",
      name: "EthermonToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd6a5ab46ead26f49b03bbb1f9eb1ad5c1767974a/32c8743816ef84835b613845cd4756f2.png",
      coingeckoId: "ethermon",
      listedIn: ["coingecko", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0xd5fa77A860FeA9cFf31DA91BbF9e0FaEa9538290",
      symbol: "COLLAR",
      name: "DOG COLLAR (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd5fa77a860fea9cff31da91bbf9e0faea9538290/c5ab18e7ce9ebe9e07c5a2bb329df203.png",
      coingeckoId: "dog-collar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd5d84e75f48E75f01fb2EB6dFD8eA148eE3d0FEb",
      symbol: "PGOV",
      name: "bZX PGOV Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd5d84e75f48e75f01fb2eb6dfd8ea148ee3d0feb/56ace07474b53693a954e73f940e70ad.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509",
      symbol: "CHAIN",
      name: "Chain Games",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd55fce7cdab84d84f2ef3f99816d765a2a94a509/8808f7ea54292fa1ac3b3add1e336b2f.png",
      coingeckoId: "chain-games",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd2A2a353D28e4833FAFfC882f6649c9c884a7D8f",
      symbol: "ESW",
      name: "EmiSwap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd2a2a353d28e4833faffc882f6649c9c884a7d8f/13425399202b8965a11bfccb1e3b3f7f.png",
      coingeckoId: "emiswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd28449BB9bB659725aCcAd52947677ccE3719fD7",
      symbol: "DMT",
      name: "Dark Matter Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xd28449BB9bB659725aCcAd52947677ccE3719fD7/logo.png",
      coingeckoId: "dark-matter",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xd1790c5435b9fB7C9444c749ceFE53D40D751eaC",
      symbol: "MIMIR",
      name: "Mimir Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd1790c5435b9fb7c9444c749cefe53d40d751eac/ac12ad4b2addfca328c41ead7ec624f5.png",
      coingeckoId: "mimir-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xd13C829Bf9bf2a097B1E185E897c78448Ed750e6",
      symbol: "CGT",
      name: "CryptoGamesToken (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891",
      symbol: "YELD",
      name: "PolyYeld Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd0f3121a190d85de0ab6131f2bcecdbfcfb38891/6aff061533ce710dba1f091c7a258941.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xd0e9c8f5Fae381459cf07Ec506C1d2896E8b5df6",
      symbol: "IOEN",
      name: "Internet of Energy Network",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd0e9c8f5fae381459cf07ec506c1d2896e8b5df6/c25c617f988a4a6d3054e3915cc10228.png",
      coingeckoId: "internet-of-energy-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xd0258a3fD00f38aa8090dfee343f10A9D4d30D3F",
      symbol: "VOXEL",
      name: "VOXEL",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/21260/small/voxies.png?1638789903",
      coingeckoId: "voxies",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xcf32822ff397Ef82425153a9dcb726E5fF61DCA7",
      symbol: "GMEE",
      name: "GAMEE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcf32822ff397ef82425153a9dcb726e5ff61dca7/061189ca4afb01f352b243daf05ecae2.png",
      coingeckoId: "gamee",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xce6bF09e5c7A3E65B84F88DcC6475c88d38BA5eF",
      symbol: "OPCT",
      name: "Opacity (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xce6bf09e5c7a3e65b84f88dcc6475c88d38ba5ef/87039f689f4cb15148403d4b5e1797be.png",
      coingeckoId: "opacity",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xcd7361ac3307D1C5a46b63086a90742Ff44c63B3",
      symbol: "RAIDER",
      name: "Crypto Raiders",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcd7361ac3307d1c5a46b63086a90742ff44c63b3/0018735da8fdf4d9825d1aaff1d2ab92.png",
      coingeckoId: "crypto-raiders",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xcaF5191fc480F43e4DF80106c7695ECA56E48B18",
      symbol: "DNXC",
      name: "DinoX Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcaf5191fc480f43e4df80106c7695eca56e48b18/bb7eab89d68015d4584c0ce79e18084c.png",
      coingeckoId: "dinox",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xcFb54a6D2dA14ABeCD231174FC5735B4436965D8",
      symbol: "CYC",
      name: "Cyclone Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcfb54a6d2da14abecd231174fc5735b4436965d8/7b4d99e40185466c6be0f48cc4cd24d1.png",
      coingeckoId: "cyclone-protocol",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xcE899f26928a2B21c6a2Fddd393EF37c61dbA918",
      symbol: "MOCA",
      name: "Museum of Crypto Art",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xce899f26928a2b21c6a2fddd393ef37c61dba918/fbca8d128c567e9b4010b10c64e2b200.png",
      coingeckoId: "museum-of-crypto-art",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xcE829A89d4A55a63418bcC43F00145adef0eDB8E",
      symbol: "renDOGE",
      name: "renDOGE",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xcE829A89d4A55a63418bcC43F00145adef0eDB8E/logo.png",
      coingeckoId: "rendoge",
      listedIn: ["coingecko", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xcD86152047e800d67BDf00A4c635A8B6C0e5C4c2",
      symbol: "NACHO",
      name: "NACHO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2/26761b9388777fdf280b9014480b3491.png",
      coingeckoId: "nacho-finance",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xcD0D64C971AF8B477042130c5e6cD2A6f7842869",
      symbol: "BZN",
      name: "Benzene (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc",
      symbol: "VIS",
      name: "Vigorus",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcc1b9517460d8ae86fe576f614d091fca65a28fc/8bf6a676622b5acb026d74b4d8562f39.png",
      coingeckoId: "vigorus",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xcBce9f77921C2E90522d438dF4C5582F4c617768",
      symbol: "AGAcr",
      name: "AGAcr",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xcBce9f77921C2E90522d438dF4C5582F4c617768/logo.png",
      coingeckoId: "aga-carbon-rewards",
      listedIn: ["coingecko", "dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xc75ea0c71023C14952F3C7B9101ECbbAa14aA27A",
      symbol: "NFTI",
      name: "Scalara NFT Index (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc75ea0c71023c14952f3c7b9101ecbbaa14aa27a/1b9471fb022b55053db4954502441596.png",
      coingeckoId: "scalara-nft-index",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc748B2A084F8eFc47E086ccdDD9b7e67aEb571BF",
      symbol: "HMT",
      name: "Human Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc748b2a084f8efc47e086ccddd9b7e67aeb571bf/7d63c0dfe3b3fd78cce72c8f219a7205.png",
      coingeckoId: "human-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xc6C855AD634dCDAd23e64DA71Ba85b8C51E5aD7c",
      symbol: "ICE_2",
      name: "Decentral Games ICE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c/ba6d67f164d7380b3ed7f1a8701af69b.png",
      coingeckoId: "decentral-games-ice",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xc6480Da81151B2277761024599E8Db2Ad4C388C8",
      symbol: "xDG",
      name: "Decentral Games Governance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc6480da81151b2277761024599e8db2ad4c388c8/ed35428915d7a8a18be1bd7ba073e0ab.png",
      coingeckoId: "decentral-games-governance",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xc58158c14D4757EF36Ce25e493758F2fcEEDec5D",
      symbol: "D11",
      name: "DeFi11",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc58158c14d4757ef36ce25e493758f2fceedec5d/2b9ee2fdfa69506163d58870530c30a2.png",
      coingeckoId: "defi11",
      listedIn: ["coingecko", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0xc56d17dD519e5eB43a19C9759b5D5372115220BD",
      symbol: "MOON",
      name: "Polywolf",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc56d17dd519e5eb43a19c9759b5d5372115220bd/95bdf11bfbbc6d70ec36e47e4d8b918a.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xc4Ace9278e7E01755B670C0838c3106367639962",
      symbol: "renFIL",
      name: "renFIL",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xc4Ace9278e7E01755B670C0838c3106367639962/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xc48F61a288A08F1B80c2edd74652e1276B6A168c",
      symbol: "GYSR",
      name: "Geyser",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc48f61a288a08f1b80c2edd74652e1276b6a168c/60784991e0217c4fe2b64cfbe341e43b.png",
      coingeckoId: "geyser",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xc3cFFDAf8F3fdF07da6D5e3A89B8723D5E385ff8",
      symbol: "RBC",
      name: "Rubic (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc3cffdaf8f3fdf07da6d5e3a89b8723d5e385ff8/b1e9f2c1070c21234bb3fbd8bec7f01d.png",
      coingeckoId: "rubic",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
      symbol: "ADDY",
      name: "Adamant",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539/logo.png",
      coingeckoId: "adamant",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xc2dA68Cd8B7Fc32CF2fcECC35Ca33471cC91C481",
      symbol: "EarnX",
      name: "EarnX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc2da68cd8b7fc32cf2fcecc35ca33471cc91c481/7525cf85612594eed3f9684241af273d.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xc2A45FE7d40bCAc8369371B08419DDAFd3131b4a",
      symbol: "LCD",
      name: "Lucidao",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc2a45fe7d40bcac8369371b08419ddafd3131b4a/963a8d1ad7e23a74f6d2e0a19824aaa3.png",
      coingeckoId: "lucidao",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xc26D47d5c33aC71AC5CF9F776D63Ba292a4F7842",
      symbol: "BNT",
      name: "Bancor Network Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0xc26d47d5c33ac71ac5cf9f776d63ba292a4f7842/logo.png",
      coingeckoId: null,
      listedIn: ["uniswap", "rubic"],
    },
    {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/logo.png",
      coingeckoId: "tether",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xc1c93D475dc82Fe72DBC7074d55f5a734F8cEEAE",
      symbol: "PGX",
      name: "Pegaxy Stone",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae/b5e81b033b93c84fbc18c992d7ac6996.png",
      coingeckoId: "pegaxy-stone",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xc10358f062663448a3489fC258139944534592ac",
      symbol: "BCMC",
      name: "Blockchain Monster Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc10358f062663448a3489fc258139944534592ac/37f9af3762afccfdaae3c86b38d8bb5b.png",
      coingeckoId: "blockchain-monster-hunt",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xc0f14C88250E680eCd70224B7fBa82b7C6560d12",
      symbol: "wPTG",
      name: "wrapped Prestige",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xc0f14C88250E680eCd70224B7fBa82b7C6560d12/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xc059a4777279E30C5238Da786384700cD14d8f47",
      symbol: "CHONK",
      name: "CHONKER FINANCE (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xbc7cB585346f4F59d07121Bb9Ed7358076243539",
      symbol: "SILVER",
      name: "Silver",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xbc7cb585346f4f59d07121bb9ed7358076243539/558ec1e4c51ef6eeadf403d56b8123be.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xba47320eF6B1039F19F3C4096F0f3B3e8Eb8d1F8",
      symbol: "NEBULA",
      name: "NEBULA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/Palette-Finance/images/main/nebula.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xbE5cf150E1fF59ca7F2499Eaa13BFC40AAE70e78",
      symbol: "GLCH",
      name: "Glitch",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xbe5cf150e1ff59ca7f2499eaa13bfc40aae70e78/d30f98df9dc5df7b1643ee810de0988b.png",
      coingeckoId: "glitch-protocol",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0xbD1463F02f61676d53fd183C2B19282BFF93D099",
      symbol: "jCHF",
      name: "Jarvis Synthetic Swiss Franc",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xbd1463f02f61676d53fd183c2b19282bff93d099/22c2a4c156203b9278c78037fe4fc3ae.png",
      coingeckoId: "jarvis-synthetic-swiss-franc",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xbC91347e80886453F3f8bBd6d7aC07C122D87735",
      symbol: "BANANA",
      name: "Banana",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xbc91347e80886453f3f8bbd6d7ac07c122d87735/1b4e984e3b1051f159bd4ded66beaf78.png",
      coingeckoId: "banana",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xbAe28251B2a4E621aA7e20538c06DEe010Bc06DE",
      symbol: "dUSD",
      name: "dHEDGE Stablecoin Yield",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xbae28251b2a4e621aa7e20538c06dee010bc06de/24209209fe1958888910fda1cd716908.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xbA3CB8329D442E6F9Eb70fafe1E214251df3D275",
      symbol: "SWASH",
      name: "Swash Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xba3cb8329d442e6f9eb70fafe1e214251df3d275/8725b5f407815fff037068d8d66759ab.png",
      coingeckoId: "swash",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xb8cb8a7F4C2885C03e57E973C74827909Fdc2032",
      symbol: "YFI",
      name: "yearn.finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/yfi.jpg",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xb763F1177E9B2Fb66FBe0d50372E3e2575c043e5",
      symbol: "Knot",
      name: "Karmaverse Knot",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb763f1177e9b2fb66fbe0d50372e3e2575c043e5/f66232ea533998269e2ad7b61ba5539a.png",
      coingeckoId: "karmaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb75bBd79985a8092B05224f62D7fED25924B075d",
      symbol: "DAM",
      name: "Datamine (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0xb75bbd79985a8092b05224f62d7fed25924b075d/logo.png",
      coingeckoId: "datamine",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0xb4228798fF437ecD8fa43429664e9992256fe6Ac",
      symbol: "KITTY",
      name: "Kitty Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb4228798ff437ecd8fa43429664e9992256fe6ac/a8280eb1271c606abab9f1dfb91a300a.png",
      coingeckoId: "kittycoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xb3b9c016AD1E9f7EFdAE451b04EF696e05658b32",
      symbol: "XPRT",
      name: "Persistence",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb3b9c016ad1e9f7efdae451b04ef696e05658b32/70cf0a1b7383b9aa2143f2a8f7cc9c5b.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xb3B681dEE0435eCc0a508e40B02b3C9068D618cd",
      symbol: "YAM",
      name: "YAM",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xb3B681dEE0435eCc0a508e40B02b3C9068D618cd/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xb371248Dd0f9E4061ccf8850E9223Ca48Aa7CA4b",
      symbol: "HNY",
      name: "HNY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xb371248Dd0f9E4061ccf8850E9223Ca48Aa7CA4b/logo.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
      symbol: "UNI",
      name: "Uniswap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xb33EaAd8d922B1083446DC23f610c2567fB5180f/logo.png",
      coingeckoId: "uniswap",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xaf9444bc9D6117121Aa108fC6c45835Be60E7d07",
      symbol: "AAH",
      name: "AAH (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xadBe0eac80F955363f4Ff47B0f70189093908c04",
      symbol: "XMT",
      name: "MetalSwap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xadbe0eac80f955363f4ff47b0f70189093908c04/177bda1722a14409755695b054075261.png",
      coingeckoId: "metalswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xad684e79CE4b6D464f2Ff7c3FD51646892e24b96",
      symbol: "NIOX",
      name: "Autonio Foundation",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xad684e79ce4b6d464f2ff7c3fd51646892e24b96/628e56b20a73908b2bec1c8c7de95bd4.png",
      coingeckoId: "autonio",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0xad5dc12E88C6534Eea8cFe2265851D9d4A1472AD",
      symbol: "FSW",
      name: "FalconSwap Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xad5dc12e88c6534eea8cfe2265851d9d4a1472ad/05598d683ece9be20a716c9e4ede3e7f.png",
      coingeckoId: "fsw-token",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xad01DFfe604CDc172D8237566eE3a3ab6524d4C6",
      symbol: "C3",
      name: "C3 Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xad01dffe604cdc172d8237566ee3a3ab6524d4c6/28d6565445f7d18a295fa76d9a05f588.png",
      coingeckoId: "c3",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xac51C4c48Dc3116487eD4BC16542e27B5694Da1b",
      symbol: "ATOM",
      name: "Cosmos",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xac51c4c48dc3116487ed4bc16542e27b5694da1b/b91685db7e70217cf1c7f1bb7993ab40.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xab3D689C22a2Bb821f50A4Ff0F21A7980dCB8591",
      symbol: "PRXY",
      name: "Proxy",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591/89bc01cd9e0ea7886b0c30c992777c17.png",
      coingeckoId: "proxy",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xab0b2ddB9C7e440fAc8E140A89c0dbCBf2d7Bbff",
      symbol: "iFARM",
      name: "iFARM",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xab0b2ddB9C7e440fAc8E140A89c0dbCBf2d7Bbff/logo.png",
      coingeckoId: "ifarm",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2",
      symbol: "RAMP",
      name: "RAMP (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xaecebfcf604ad245eaf0d5bd68459c3a7a6399c2/8bd04cbe668c41ca2d15b5473ce04cd1.png",
      coingeckoId: "ramp",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xaE29AC47A9E3b0a52840E547aDf74B912999F7fc",
      symbol: "EVE",
      name: "EVE Exchange (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xae29ac47a9e3b0a52840e547adf74b912999f7fc/f513bdc4ee56665058016259840fd615.png",
      coingeckoId: "eve-exchange",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xaBc6790673a60b8A7f588450f59D2d256b1aeF7F",
      symbol: "OAI",
      name: "OMNI Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xabc6790673a60b8a7f588450f59d2d256b1aef7f/d3e503a53bbb3f7cb0806e0c72ad56d3.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A",
      symbol: "TITAN",
      name: "IRON Titanium Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/logo.png",
      coingeckoId: "iron-titanium-token",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "dfyn",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xa926db7a4CC0cb1736D5ac60495ca8Eb7214B503",
      symbol: "jSGD",
      name: "Jarvis Synthetic Singapore Dollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503/8e51d27ec37601c77b4017ca7f1fa4ff.png",
      coingeckoId: "jarvis-synthetic-singapore-dollar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa8fcEe762642f156b5D757b6FabC36E06b6d4A1A",
      symbol: "ALN",
      name: "Aluna (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa8fcee762642f156b5d757b6fabc36e06b6d4a1a/6dbfcefd91657ec5d6603859550cc894.png",
      coingeckoId: "aluna",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa7051C5a22d963b81D71C2BA64D46a877fBc1821",
      symbol: "EROWAN",
      name: "SifChain",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821/5a341408b560f555e3d13e902964d963.png",
      coingeckoId: "sifchain",
      listedIn: ["coingecko", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0xa6b37fC85d870711C56FbcB8afe2f8dB049AE774",
      symbol: "PLR",
      name: "PILLAR (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa6b37fc85d870711c56fbcb8afe2f8db049ae774/c78d39ed72b44fdf49c972682e415574.png",
      coingeckoId: "pillar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa69d14d6369E414a32a5C7E729B7afbAfd285965",
      symbol: "GCR",
      name: "Global Coin Research (PoS)",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa69d14d6369e414a32a5c7e729b7afbafd285965/d82e4eee42b0b0dcdd39e53f6119214e.png",
      coingeckoId: "global-coin-research",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xa6083abe845fbB8649d98B8586cBF50b7f233612",
      symbol: "PCR",
      name: "Paycer",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa6083abe845fbb8649d98b8586cbf50b7f233612/5dc03da1eaeca06e7ebf1fb3c8d62be6.png",
      coingeckoId: "paycer-protocol",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa5Eb60CA85898f8b26e18fF7c7E43623ccbA772C",
      symbol: "COSMIC",
      name: "CosmicSwap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa5eb60ca85898f8b26e18ff7c7e43623ccba772c/9a1146b73408e3b73542e103be38f97a.png",
      coingeckoId: "cosmicswap",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xa3eD22EEE92a3872709823a6970069e12A4540Eb",
      symbol: "FRONT",
      name: "Frontier",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa3ed22eee92a3872709823a6970069e12a4540eb/db06e972a552e01d975d02089e161c03.png",
      coingeckoId: "frontier-token",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1",
      symbol: "MAI",
      name: "miMatic",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/logo.png",
      coingeckoId: "mimatic",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "RouterProtocol",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xa353dEb6Fb81dF3844D8bd614D33d040fDBb8188",
      symbol: "MST",
      name: "MagicStone",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa353deb6fb81df3844d8bd614d33d040fdbb8188/df2d894c45532cd87624b1b66a8150db.png",
      coingeckoId: "idle-mystic",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xa2CA40DBe72028D3Ac78B5250a8CB8c404e7Fb8C",
      symbol: "FEAR",
      name: "Fear NFTs",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa2ca40dbe72028d3ac78b5250a8cb8c404e7fb8c/78dba9cce97ba14ee9692dfb22400a04.png",
      coingeckoId: "fear",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xa25610a77077390A75aD9072A084c5FbC7d43A0d",
      symbol: "MCASH",
      name: "Monsoon Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa25610a77077390a75ad9072a084c5fbc7d43a0d/b6a2c3c445e63eec0af1d001de7a9ec8.png",
      coingeckoId: "monsoon-finance",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xa1C09C8F4f5D03fcC27b456475d53d988e98D7C5",
      symbol: "eDAI",
      name: "Easy DAI",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xa1C09C8F4f5D03fcC27b456475d53d988e98D7C5/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xa1428174F516F527fafdD146b883bB4428682737",
      symbol: "SUPER",
      name: "SuperFarm",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xa1428174F516F527fafdD146b883bB4428682737/logo.png",
      coingeckoId: "superfarm",
      listedIn: ["1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639",
      symbol: "DSLA",
      name: "DSLA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639/logo.png",
      coingeckoId: "stacktical",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xFeD16c746CB5BFeD009730f9E3e6A673006105c7",
      symbol: "DRC",
      name: "Digital Reserve Currency",
      decimals: 0,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xFeD16c746CB5BFeD009730f9E3e6A673006105c7/logo.png",
      coingeckoId: "digital-reserve-currency",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xFdc26CDA2d2440d0E83CD1DeE8E8bE48405806DC",
      symbol: "BTU",
      name: "BTU Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xFdc26CDA2d2440d0E83CD1DeE8E8bE48405806DC/logo.png",
      coingeckoId: "btu-protocol",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xFbe49330E7B9F58a822788F86c1be38Ab902Bab1",
      symbol: "PAPR",
      name: "PAPR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0xfbe49330e7b9f58a822788f86c1be38ab902bab1.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xFbdd194376de19a88118e84E279b977f165d01b8",
      symbol: "BIFI",
      name: "Beefy",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xFbdd194376de19a88118e84E279b977f165d01b8/logo.png",
      coingeckoId: "beefy-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "quickswap",
        "dfyn",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xFaFA220145DfA5C3Ec85B6FA8a75aEE2451CDe5E",
      symbol: "ROOBEE",
      name: "ROOBEE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/8791/large/Group_11.png",
      coingeckoId: "roobee",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xFFA4D863C96e743A2e1513824EA006B8D0353C57",
      symbol: "USDD",
      name: "Decentralized USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/25380/small/UUSD.jpg?1651823371",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xFF2382Bd52efaceF02Cc895bcBFc4618608AA56F",
      symbol: "ORARE",
      name: "One Rare Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xff2382bd52efacef02cc895bcbfc4618608aa56f/cd80e67b7386a72937d25d8204a9a06b.png",
      coingeckoId: "onerare",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xFDde616084427f0A231D0664a985E1F820E34693",
      symbol: "bDIGG",
      name: "Badger Sett Digg",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xFDde616084427f0A231D0664a985E1F820E34693/logo.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xFCf1E2fC0A398925ddb09222842b376E3A7D573B",
      symbol: "ACPT",
      name: "Crypto Accept (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xF9a4BBAa7fA1DD2352F1A47d6d3fcfF259A6D05F",
      symbol: "LEPA",
      name: "Lepasa (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf9a4bbaa7fa1dd2352f1a47d6d3fcff259a6d05f/9119fabfd1293b21f68951cd70f1161b.png",
      coingeckoId: "lepasa",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF915fDDa4c882731C0456a4214548Cd13A822886",
      symbol: "FUSE",
      name: "Fuse",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf915fdda4c882731c0456a4214548cd13a822886/3de52382085198f737973c1346dc0d94.png",
      coingeckoId: "fuse-network-token",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xF88332547c680F755481Bf489D890426248BB275",
      symbol: "SURE",
      name: "inSure (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf88332547c680f755481bf489d890426248bb275/569569611ded849a5dfe189a67ba1f8a.png",
      coingeckoId: "insure",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xF868939Ee81F04f463010BC52EAb91c0839eF08c",
      symbol: "ATK",
      name: "Attack",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf868939ee81f04f463010bc52eab91c0839ef08c/5cce598f3fa6f1e19ee80245a6ffb30b.png",
      coingeckoId: "attack-wagon",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
      symbol: "AGAr",
      name: "AGA Rewards",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xF84BD51eab957c2e7B7D646A3427C5A50848281D/logo.png",
      coingeckoId: "aga-rewards-2",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xF6F85b3f9fd581C2eE717c404F7684486F057F95",
      symbol: "NORD",
      name: "NORD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf6f85b3f9fd581c2ee717c404f7684486f057f95/e386edc6f99d3988116f1eb7b5125917.png",
      coingeckoId: "nord-finance",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xF521D590Fb1e0b432fD0e020cdbD6c6397d652C2",
      symbol: "PAR",
      name: "Parachute",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xF521D590Fb1e0b432fD0e020cdbD6c6397d652C2/logo.png",
      coingeckoId: "parachute",
      listedIn: ["coingecko", "elkfinance"],
    },
    {
      address: "0xF501dd45a1198C2E1b5aEF5314A68B9006D842E0",
      symbol: "MTA",
      name: "mStable Governance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xf501dd45a1198c2e1b5aef5314a68b9006d842e0/bce0f1b12aa06a7ca9ddde3b75eec2a9.png",
      coingeckoId: "meta",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9",
      symbol: "maTUSD",
      name: "Matic Aave interest bearing TUSD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
      symbol: "GAJ",
      name: "PolyGaj",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xF4B0903774532AEe5ee567C02aaB681a81539e92/logo.png",
      coingeckoId: "gaj",
      listedIn: [
        "coingecko",
        "sushiswap",
        "openocean",
        "dfyn",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xF239E69ce434c7Fb408b05a0Da416b14917d934e",
      symbol: "SHI3LD",
      name: "PolyShield",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xF239E69ce434c7Fb408b05a0Da416b14917d934e/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xEe9A352F6aAc4aF1A5B9f467F6a93E0ffBe9Dd35",
      symbol: "MASQ",
      name: "MASQ (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35/c90bd8b53b4a2d2af19e8af20257ebe0.png",
      coingeckoId: "masq",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xEe9801669C6138E84bD50dEB500827b776777d28",
      symbol: "O3",
      name: "O3 Swap Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xee9801669c6138e84bd50deb500827b776777d28/98e28b4dbf26999a2c8ec90401eff245.png",
      coingeckoId: "o3-swap",
      listedIn: ["coingecko", "1inch", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F",
      symbol: "wFIL",
      name: "Wrapped Filecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xede1b77c0ccc45bfa949636757cd2ca7ef30137f/b5df8fb9729e06e574986295172b2ae9.png",
      coingeckoId: "wrapped-filecoin",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xEc482De9569a5EA3Dd9779039b79e53F15791fDE",
      symbol: "REGEN",
      name: "Regen Network",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xec482de9569a5ea3dd9779039b79e53f15791fde/9fa2c4e3d641b7112b289207c1c2a282.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xEb94A5e2C643403E29fa1d7197e7E0708B09aD84",
      symbol: "ONX",
      name: "OnX.finance (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xeb94a5e2c643403e29fa1d7197e7e0708b09ad84/4dc411e2fbd383d4212e12423243ad18.png",
      coingeckoId: "onx-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xEFeE2de82343BE622Dcb4E545f75a3b9f50c272D",
      symbol: "TRY",
      name: "TryHards",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xefee2de82343be622dcb4e545f75a3b9f50c272d/4f2cc5eebb941e8f63def04d0d12eb0e.png",
      coingeckoId: "tryhards",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xEE800B277A96B0f490a1A732e1D6395FAD960A26",
      symbol: "ARPA",
      name: "ARPA Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xee800b277a96b0f490a1a732e1d6395fad960a26/5ceb3585629a4635a59738c873976fc3.png",
      coingeckoId: "arpa-chain",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xED0bb69f9F96E8C5FffdF16c468eceC385134eA5",
      symbol: "ELIXIR",
      name: "Starchi Liquid Luck",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xed0bb69f9f96e8c5fffdf16c468ecec385134ea5/347edf274cf971b4c33c9c8e224b918b.png",
      coingeckoId: "starchi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE8d17b127BA8b9899a160D9a07b69bCa8E08bfc6",
      symbol: "NSDX",
      name: "NASDEX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe8d17b127ba8b9899a160d9a07b69bca8e08bfc6/71764bf8c36db2022c97925528769835.png",
      coingeckoId: "nasdex-token",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xE8A51D0dD1b4525189ddA2187F90ddF0932b5482",
      symbol: "LCX",
      name: "LCX (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xE8A05E85883F9663b18a38d7aa89853deaba56e3",
      symbol: "VOLT",
      name: "VOLTAGE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/18515/small/volt.png?1632219924",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xE79feAAA457ad7899357E8E2065a3267aC9eE601",
      symbol: "WCHI",
      name: "Wrapped CHI (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe79feaaa457ad7899357e8e2065a3267ac9ee601/a5dc1fc4b76813cc619195af72d975a4.png",
      coingeckoId: "chimaera",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
      symbol: "DFX",
      name: "DFX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe7804d91dfcde7f776c90043e03eaa6df87e6395/eaad30c2bb118c5cfee13eac513aa1a8.png",
      coingeckoId: "dfx-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xE6B9d092223f39013656702A40dbE6B7DeCc5746",
      symbol: "FEVR",
      name: "FevrToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe6b9d092223f39013656702a40dbe6b7decc5746/721007c30b8af05be16d7700fa5e2fd1.png",
      coingeckoId: "realfevr",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59",
      symbol: "UST",
      name: "UST",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/21150/small/UST_wh_small.png?1644223065",
      coingeckoId: "terrausd-wormhole",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0xE631FfAA2cF4d91aac3E9589a5d5B390C82A032E",
      symbol: "oWBTC",
      name: "Orbit Bridge Polygon Wrapped BTC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0xe631ffaa2cf4d91aac3e9589a5d5b390c82a032e.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xE613a914bbb433855378183c3aB13003285da40A",
      symbol: "B2M",
      name: "Bit2Me",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe613a914bbb433855378183c3ab13003285da40a/85cf78a2b7bffeb79a876683dd7e0504.png",
      coingeckoId: "bit2me",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xE5417Af564e4bFDA1c483642db72007871397896",
      symbol: "GNS",
      name: "Gains Network",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe5417af564e4bfda1c483642db72007871397896/9dc1bb50d224f58fb2e52c58785f1d59.png",
      coingeckoId: "gains-network",
      listedIn: ["coingecko", "1inch", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0xE52509181FEb30EB4979E29EC70D50FD5C44D590",
      symbol: "ARTH",
      name: "ARTH Valuecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe52509181feb30eb4979e29ec70d50fd5c44d590/84a8456bc6522a69f80c2d7d245a5994.png",
      coingeckoId: "arth",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xE40d881Cf66e1F6D19979A5fF6c830C6Af65D278",
      symbol: "GM",
      name: "Guild Member Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://ipfs.io/ipfs/QmY1uqAaLvQ2cnax4P6ETQwHJmsigS6tLUQkomd4eZJMpM",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xE2Aa7db6dA1dAE97C5f5C6914d285fBfCC32A128",
      symbol: "PAR",
      name: "PAR Stablecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128/fff89c0b17d5946d0e1b421f22568e3d.png",
      coingeckoId: "par-stablecoin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xE111178A87A3BFf0c8d18DECBa5798827539Ae99",
      symbol: "EURS",
      name: "STASIS EURS Token (PoS)",
      decimals: 2,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe111178a87a3bff0c8d18decba5798827539ae99/ce17cec4be00b080e51cec3e2c7d0a0a.png",
      coingeckoId: "stasis-eurs",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xE0b22E0037B130A9F56bBb537684E6fA18192341",
      symbol: "maDAI",
      name: "Matic Aave interest bearing DAI",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xE0b22E0037B130A9F56bBb537684E6fA18192341/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4",
      symbol: "agEUR",
      name: "agEUR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4/950fda44a9f4598d2a7a6e9df24b7332.png",
      coingeckoId: "ageur",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xE06Bd4F5aAc8D0aA337D13eC88dB6defC6eAEefE",
      symbol: "IXT",
      name: "PlanetIX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe/33415280a45da84c2d8c84c5c81cea48.png",
      coingeckoId: "ix-token",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF",
      symbol: "ELON",
      name: "Dogelon (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xe0339c80ffde91f3e20494df88d4206d86024cdf/d90f70a0da65834eab14a70f7423db19.png",
      coingeckoId: "dogelon-mars",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xDfCe1e99A31C4597a3f8A8945cBfa9037655e335",
      symbol: "ASTRAFER",
      name: "Astrafer",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdfce1e99a31c4597a3f8a8945cbfa9037655e335/f7d053b02f293e0fcd21bdb8cd30f46e.png",
      coingeckoId: "astrafer",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xDd24aA9166dCDf0B37D0Fca17D6Ea87748771dCC",
      symbol: "PLAY",
      name: "PLAY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xDd24aA9166dCDf0B37D0Fca17D6Ea87748771dCC/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xDb3b3b147A030F032633f6C4BEBf9a2fB5a882B5",
      symbol: "EASY",
      name: "EASY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xDb3b3b147A030F032633f6C4BEBf9a2fB5a882B5/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0xDa5949544aAf6124D06F398BFdE4C86Cc33B0Ee7",
      symbol: "CYFM",
      name: "CyberFM Radio",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xda5949544aaf6124d06f398bfde4c86cc33b0ee7/26f86bf0855dd128d8991ab276a5d802.png",
      coingeckoId: "cyberfm",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDC0E17eAE3B9651875030244b971fa0223a1764f",
      symbol: "PERI",
      name: "PeriFinanceToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdc0e17eae3b9651875030244b971fa0223a1764f/12e5c9a0873ae69651b4ba7a89edca68.png",
      coingeckoId: "peri-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDBf31dF14B66535aF65AaC99C32e9eA844e14501",
      symbol: "renBTC",
      name: "Ren BTC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdbf31df14b66535af65aac99c32e9ea844e14501/7ec502c1a24ea6d4cee9f31595acf6d7.png",
      coingeckoId: "renbtc",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xDB7Cb471dd0b49b29CAB4a1C14d070f27216a0Ab",
      symbol: "BANK",
      name: "Bankless Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab/65548d47f97a234fd98885f0bc89d4ff.png",
      coingeckoId: "bankless-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xDAE5F1590db13E3B40423B5b5c5fbf175515910b",
      symbol: "maUSDT",
      name: "Matic Aave interest bearing USDT",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xDAE5F1590db13E3B40423B5b5c5fbf175515910b/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xDA6f726E2088F129D3Ecb2257206AdF7D8537Ba5",
      symbol: "NCR",
      name: "Neos Credits",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0xDA6f726E2088F129D3Ecb2257206AdF7D8537Ba5.jpg",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xDA537104D6A5edd53c6fBba9A898708E465260b6",
      symbol: "YFI",
      name: "yearn.finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xDA537104D6A5edd53c6fBba9A898708E465260b6/logo.png",
      coingeckoId: "yearn-finance",
      listedIn: ["coingecko", "uniswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xD921F8318cfd786baB1ea7492673F053c518Ac04",
      symbol: "xSDT",
      name: "Staked SDT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xD921F8318cfd786baB1ea7492673F053c518Ac04/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xD86b5923F3AD7b585eD81B448170ae026c65ae9a",
      symbol: "IRON",
      name: "IRON Stablecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd86b5923f3ad7b585ed81b448170ae026c65ae9a/6929282297e16b9fc7af85f86c156d6c.png",
      coingeckoId: "iron-stablecoin",
      listedIn: ["coingecko", "1inch", "sushiswap", "dfyn", "lifinance"],
    },
    {
      address: "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
      symbol: "CEL",
      name: "Celsius",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6/logo.png",
      coingeckoId: "celsius-degree-token",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xD838290e877E0188a4A44700463419ED96c16107",
      symbol: "NCT",
      name: "Nature Carbon Tonne",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd838290e877e0188a4a44700463419ed96c16107/d5e6568ed2c33f964ca92a38994f4b6e.png",
      coingeckoId: "toucan-protocol-nature-carbon-tonne",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xD81F558b71A5323e433729009D55159955F8A7f9",
      symbol: "UNB",
      name: "Unbound",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd81f558b71a5323e433729009d55159955f8a7f9/bbada11622ba88955a639bbd9caafe72.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
      symbol: "WAAVE",
      name: "Wrapped Aave",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/logo.png",
      coingeckoId: "aave",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
      symbol: "WRLD",
      name: "NFT Worlds",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9/7e8c2ceca870a77f8910d33d85163f0d.png",
      coingeckoId: "nft-worlds",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xD52f6CA48882Be8fbaa98ce390db18e1dbe1062d",
      symbol: "ORE",
      name: "pTokens ORE (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d.png",
      coingeckoId: "ptokens-ore",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD4945a3D0De9923035521687D4bf18cC9B0c7c2A",
      symbol: "LUXY",
      name: "LUXY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a/5314e62c0a71e237d37b101895f86449.png",
      coingeckoId: "luxy",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
      symbol: "1ART",
      name: "ArtWallet",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd3c325848d7c6e29b574cb0789998b2ff901f17e/136963cf3a4bf10b92efe37bfb99ab8e.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0xD3b71117E6C1558c1553305b44988cd944e97300",
      symbol: "YEL",
      name: "YEL Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd3b71117e6c1558c1553305b44988cd944e97300/bdd34d4d180d012589ecde14100031c5.png",
      coingeckoId: "yel-finance",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xD30DC92b8ec0fa8de625768d208f51A93C10aFF2",
      symbol: "cxBTC",
      name: "CelsiusX Wrapped BTC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd30dc92b8ec0fa8de625768d208f51a93c10aff2/498a547f7ee9e167c035751f61941706.png",
      coingeckoId: "celsiusx-wrapped-btc",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xD1f9c58e33933a993A3891F8acFe05a68E1afC05",
      symbol: "SFL",
      name: "Sunflower Land",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd1f9c58e33933a993a3891f8acfe05a68e1afc05/a462625fab0f41aeb11256217db4adc7.png",
      coingeckoId: "sunflower-land",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
      symbol: "CNT",
      name: "Cryption Network Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd1e6354fb05bf72a8909266203dab80947dceccf/9e9f6f8f7eaf106c983ca7f64c516f11.png",
      coingeckoId: "cryption-network",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xD1A5f2A049343fc4D5F8d478f734eBa51B22375E",
      symbol: "KEYFI",
      name: "KeyFi Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e/3a58685637e0e5bbfe84c791b13fff2c.png",
      coingeckoId: "keyfi",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xD12DC5319808Bb31ba95AE5764def2627d5966CE",
      symbol: "BOOTY",
      name: "Pirate Booty",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/16130/small/boty.PNG?1623052148",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xD125443F38A69d776177c2B9c041f462936F8218",
      symbol: "FBX",
      name: "FireBotToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd125443f38a69d776177c2b9c041f462936f8218/401aa550ebb9925ab60c3f3e2714d58f.png",
      coingeckoId: "firebot",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xD0660cD418a64a1d44E9214ad8e459324D8157f1",
      symbol: "WOOFY",
      name: "Woofy",
      decimals: 12,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd0660cd418a64a1d44e9214ad8e459324d8157f1/67278ae4e5deaedf87c5a21bbfdd90b0.png",
      coingeckoId: "woofy",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xD0252fB67606Ed74d0cACD17B2eb38446e4466c9",
      symbol: "ARGO",
      name: "ArGo Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xd0252fb67606ed74d0cacd17b2eb38446e4466c9/0fe5b54f1d1ae976e5c7d7fc91998db8.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xCd150B1F528F326f5194c012f32Eb30135C7C2c9",
      symbol: "OOKI",
      name: "Ooki",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcd150b1f528f326f5194c012f32eb30135c7c2c9/47c4962f28500ec44247e7e1c6df1f58.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xCc2a9051E904916047c26C90f41c000D4f273456",
      symbol: "oXRP",
      name: "Orbit Bridge Polygon XRP Token",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0xcc2a9051e904916047c26c90f41c000d4f273456.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0xCa7BF3C514d412AC12D10Eff302301A81153F557",
      symbol: "LFI",
      name: "LunaFi",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xca7bf3c514d412ac12d10eff302301a81153f557/18c2b17e7360791e02a347487277b1f5.png",
      coingeckoId: "lunafi",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xCB898b0eFb084Df14dd8E018dA37B4d0f06aB26D",
      symbol: "SING",
      name: "Sing Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xcb898b0efb084df14dd8e018da37b4d0f06ab26d/c173dd70443acfdc8072a75d4dfee414.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xCB7F1Ef7246D1497b985f7FC45A1A31F04346133",
      symbol: "jAUD",
      name: "Jarvis Synthetic Australian Dollar",
      decimals: 18,
      chainId: "137",
      logoURI: "ipfs://QmW4pR69muQ3Ceqni5ThLvhtd7MrUbQwgjcm4NrJt8ZMjS",
      coingeckoId: "jarvis-synthetic-australian-dollar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC9c1c1c20B3658F8787CC2FD702267791f224Ce1",
      symbol: "FTM",
      name: "Fantom Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xC91c06DB0f7bFFba61e2A5645CC15686F0a8c828",
      symbol: "RAZOR",
      name: "Razor",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xC91c06DB0f7bFFba61e2A5645CC15686F0a8c828/logo.png",
      coingeckoId: "razor-network",
      listedIn: ["coingecko", "dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xC68e83a305b0FaD69E264A1769a0A070F190D2d6",
      symbol: "ROLL",
      name: "Polyroll Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc68e83a305b0fad69e264a1769a0a070f190d2d6/52079f2f4d4cb61186f9ba1ac1c02977.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0xC5B57e9a1E7914FDA753A88f24E5703e617Ee50c",
      symbol: "POP",
      name: "Popcorn",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc5b57e9a1e7914fda753a88f24e5703e617ee50c/25ee4d45df594959425cbb7c6cbeb35b.png",
      coingeckoId: "popcorn",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xC46A37fBBe433EF24bc7b9388C8728DDcf3cA87c",
      symbol: "MFTU",
      name: "Mainstream For The Underground",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc46a37fbbe433ef24bc7b9388c8728ddcf3ca87c/0fa0c126f8fba0834fa1ce3ccc619a04.png",
      coingeckoId: "mainstream-for-the-underground",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea",
      symbol: "OM",
      name: "OM",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea/logo.png",
      coingeckoId: "mantra-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0xC3C7d422809852031b44ab29EEC9F1EfF2A58756",
      symbol: "LDO",
      name: "Lido DAO Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc3c7d422809852031b44ab29eec9f1eff2a58756/ca70e712bf4b68bd979e825960a63577.png",
      coingeckoId: "lido-dao",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xC25351811983818c9Fe6D8c580531819c8ADe90f",
      symbol: "IDLE",
      name: "Idle",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc25351811983818c9fe6d8c580531819c8ade90f/a611f79214d38d424c3cab44f47af9df.png",
      coingeckoId: "idle",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0xC250e9987A032ACAC293d838726C511E6E1C029d",
      symbol: "CLAM",
      name: "Otter Clam",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc250e9987a032acac293d838726c511e6e1c029d/127e79f8b6b85ce0a813884f95bfdf78.png",
      coingeckoId: "otterclam",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xC17c30e98541188614dF99239cABD40280810cA3",
      symbol: "RISE",
      name: "EverRise",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc17c30e98541188614df99239cabd40280810ca3/38aa3e0824e40514a68317f4ccbc1adf.png",
      coingeckoId: "everrise",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97",
      symbol: "DFYN",
      name: "DFYN Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97/b64c678e56437a3e7299af0ea7e804d4.png",
      coingeckoId: "dfyn-network",
      listedIn: ["coingecko", "1inch", "openocean", "dfyn", "lifinance"],
    },
    {
      address: "0xC004e2318722EA2b15499D6375905d75Ee5390B8",
      symbol: "KOM",
      name: "Kommunitas",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xc004e2318722ea2b15499d6375905d75ee5390b8/492dfc2546dcf5bf95b8eb2c3cb34a01.png",
      coingeckoId: "kommunitas",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0xBe319A08eBE5da37C5A8ce8a0D8463ff6750e53B",
      symbol: "ARDU",
      name: "Circolo Arduino Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0xBbbe128568222623D21299f019a978C8587B33dC",
      symbol: "SWISE",
      name: "StakeWise",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xBbbe128568222623D21299f019a978C8587B33dC/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683",
      symbol: "SAND",
      name: "SAND",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/12129/small/sandbox_logo.jpg?1597397942",
      coingeckoId: "the-sandbox",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0xBa4c54Ea2d66b904C82847A7d2357d22B857E812",
      symbol: "UGT",
      name: "Unreal Governance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xba4c54ea2d66b904c82847a7d2357d22b857e812/8e23830eb07c127fa9c9756b38735bdd.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xBA25B552C8A098AFdf276324C32C71fE28e0Ad40",
      symbol: "MCRN",
      name: "MacaronSwap Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xba25b552c8a098afdf276324c32c71fe28e0ad40/abaee09fc8bbbdf34ce15ceaa4696dca.png",
      coingeckoId: "macaronswap",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB9f9e37c2CdbaFF928C3Da730b02F06fE09aE70E",
      symbol: "REI",
      name: "Zerogoki Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb9f9e37c2cdbaff928c3da730b02f06fe09ae70e/f829802ef8363751ac4f57aea5b58d3f.png",
      coingeckoId: "zerogoki",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB9638272aD6998708de56BBC0A290a1dE534a578",
      symbol: "IQ",
      name: "Everipedia IQ",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb9638272ad6998708de56bbc0a290a1de534a578/cb50421c7138c92edd68501625d9a074.png",
      coingeckoId: "everipedia",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance"],
    },
    {
      address: "0xB8bDB98AF8C987336B9476Fd8a98BdF190b6f10E",
      symbol: "LMT",
      name: "LuckyMeta Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb8bdb98af8c987336b9476fd8a98bdf190b6f10e/bf436f40f5e1ff0092e113cd9a28303f.png",
      coingeckoId: "luckymeta",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5",
      symbol: "FTM",
      name: "Fantom Token",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a",
      symbol: "WISE",
      name: "Wise Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a/logo.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance", "elkfinance"],
    },
    {
      address: "0xB6bcae6468760bc0CDFb9C8ef4Ee75C9dd23e1Ed",
      symbol: "PNT",
      name: "pTokens PNT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/11659/small/pNetwork.png?1592411134",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xB5C064F955D8e7F38fE0460C556a72987494eE17",
      symbol: "QUICK(NEW)",
      name: "QuickSwap(NEW)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb5c064f955d8e7f38fe0460c556a72987494ee17/841c0b0cfbdf9a82c770fad1aea9022c.png",
      coingeckoId: "quickswap",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xB53Ec4aCe420a62Cfb75aFdEba600D284777cd65",
      symbol: "SPACE",
      name: "SpaceToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb53ec4ace420a62cfb75afdeba600d284777cd65/934201c14d045663ba04b55929590888.png",
      coingeckoId: "space-token-bsc",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB382C1cfA622795a534e5bd56Fac93d59BAc8B0D",
      symbol: "KIRO",
      name: "Kirobo",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb382c1cfa622795a534e5bd56fac93d59bac8b0d/7aa53d546e212092081ad225d4453ef9.png",
      coingeckoId: "kirobo",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xB35fcBCF1fD489fCe02Ee146599e893FDCdC60e6",
      symbol: "DERC",
      name: "DeRace Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6/0c33875205b5722809a5891614a9abd5.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xB2C63830D4478cB331142FAc075A39671a5541dC",
      symbol: "BOMB",
      name: "Bombcrypto Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb2c63830d4478cb331142fac075a39671a5541dc/497b4adc365afff8ea540d5a2e28af88.png",
      coingeckoId: "bombcrypto-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xB25e20De2F2eBb4CfFD4D16a55C7B395e8a94762",
      symbol: "REQ",
      name: "Request",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762/332d9168c95953276e7db6d4b2032c15.png",
      coingeckoId: "request-network",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xB1CB4aC6b1685DcbE691b9246406748805aa6FA8",
      symbol: "POLYSAFE",
      name: "PolySafe",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/BlockTimeWorld/crawler/main/icons/polysafe_32.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0xB140665ddE25c644c6B418e417C930dE8A8a6Ac9",
      symbol: "ATRI",
      name: "Atari (PoS)",
      decimals: 0,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xb140665dde25c644c6b418e417c930de8a8a6ac9/8a239ffa3c0a89fe22c08b521b65bff2.png",
      coingeckoId: "atari",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0",
      symbol: "ELE",
      name: "Eleven Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xAa9654BECca45B5BDFA5ac646c939C62b527D394",
      symbol: "DINO",
      name: "DinoSwap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xaa9654becca45b5bdfa5ac646c939c62b527d394/b6aa77b6cffb6391bb48b8e5a28a4a40.png",
      coingeckoId: "dinoswap",
      listedIn: ["coingecko", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0xAa7DbD1598251f856C12f63557A4C4397c253Cea",
      symbol: "MCO2",
      name: "Moss Carbon Credit (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xaa7dbd1598251f856c12f63557a4c4397c253cea/36fb8eb55a51b589742e97612c35558e.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xAF24765F631C8830B5528B57002241eE7eef1C14",
      symbol: "IOI",
      name: "IOI Token",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xaf24765f631c8830b5528b57002241ee7eef1c14/3b3547d922797db9f68f82294e82a0c6.png",
      coingeckoId: "ioi-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0xADAC33f543267c4D59a8c299cF804c303BC3e4aC",
      symbol: "MIMO",
      name: "MIMO Parallel Governance Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xadac33f543267c4d59a8c299cf804c303bc3e4ac/d33494e8c7969112a847f9f87a156a97.png",
      coingeckoId: "mimo-parallel-governance-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xAC63686230f64BDEAF086Fe6764085453ab3023F",
      symbol: "USV",
      name: "Universal Store of Value",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xac63686230f64bdeaf086fe6764085453ab3023f/b79b4f5fff5665519190e8f3e44dba1f.png",
      coingeckoId: "atlas-usv",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0xA96D47c621a8316d4F9539E3B38180C7067e84CA",
      symbol: "AWS",
      name: "AurusSILVER",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa96d47c621a8316d4f9539e3b38180c7067e84ca/b3fe8f9bf1b591dd7e43911fd7183c30.png",
      coingeckoId: "aurus-silver",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xA9536B9c75A9E0faE3B56a96AC8EdF76AbC91978",
      symbol: "PECO",
      name: "Polygon Ecosystem Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978/7a0a77cacf56b715c71cdec7f7371e90.png",
      coingeckoId: "polygon-ecosystem-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0xA936e1f747d14fC30d08272D065c8aeF4ab7f810",
      symbol: "WLD",
      name: "wLitiDAO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0xa936e1f747d14fc30d08272d065c8aef4ab7f810.png",
      coingeckoId: "wlitidao",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xA8b1E0764f85f53dfe21760e8AfE5446D82606ac",
      symbol: "BAND",
      name: "BandToken (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "rubic", "lifinance"],
    },
    {
      address: "0xA863246658DEA34111C3C1DceDb2cfd5d6067334",
      symbol: "WMETIS",
      name: "Wrapped METIS",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/15595/small/metis.PNG?1621298076",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0xA79E0Bfc579c709819f4A0E95d4597f03093B011",
      symbol: "STR",
      name: "Stater",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xA79E0Bfc579c709819f4A0E95d4597f03093B011/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0xA7305Ae84519fF8Be02484CdA45834C4E7D13Dd6",
      symbol: "UFARM",
      name: "Unifarm Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa7305ae84519ff8be02484cda45834c4e7d13dd6/2508d44484bd1ecb6ec0eef72e6bc0cc.png",
      coingeckoId: "unifarm",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F",
      symbol: "WBNB",
      name: "Wrapped Binance Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F/logo.png",
      coingeckoId: "wbnb",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0xA55870278d6389ec5B524553D03C04F5677c061E",
      symbol: "XCAD",
      name: "XCAD Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa55870278d6389ec5b524553d03c04f5677c061e/9b4466426dd0d8db02f9b419292b8d86.png",
      coingeckoId: "xcad-network",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xA4Ce4a467E51aEfEc683a649C3F14427f104667f",
      symbol: "ONSTON",
      name: "Onston",
      decimals: 18,
      chainId: "137",
      logoURI: "https://get.celer.app/cbridge-icons/ONSTON.png",
      coingeckoId: "onston",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA486c6BC102f409180cCB8a94ba045D39f8fc7cB",
      symbol: "NEX",
      name: "Nash Exchange Token (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb/c34674f11dc6064e831e0cb4124c9407.png",
      coingeckoId: "neon-exchange",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0xA3c322Ad15218fBFAEd26bA7f616249f7705D945",
      symbol: "MV",
      name: "Metaverse (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa3c322ad15218fbfaed26ba7f616249f7705d945/61b449b2779c2e90e03d351c1e842c9e.png",
      coingeckoId: "gensokishis-metaverse",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
      symbol: "MANA",
      name: "Decentraland MANA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4/logo.png",
      coingeckoId: "decentraland",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0xA1388e73c51B37383B1Ab9dCe8317eF5A0349CC5",
      symbol: "VERSE",
      name: "Shibaverse",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa1388e73c51b37383b1ab9dce8317ef5a0349cc5/ae6a5f7e10521cc374849536024d4412.png",
      coingeckoId: "shibaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0xA0dF47432d9d88bcc040E9ee66dDC7E17A882715",
      symbol: "pMATIC",
      name: "pMATIC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa0df47432d9d88bcc040e9ee66ddc7e17a882715/87262d2b2068159d30aec3406756426b.png",
      coingeckoId: "ripae-pmatic",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0xA041544fe2BE56CCe31Ebb69102B965E06aacE80",
      symbol: "BOND",
      name: "BarnBridge Governance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0xa041544fe2be56cce31ebb69102b965e06aace80/6a44b59c9f2830d9e70e7d82b6e39392.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x9f5755D47fB80100E7ee65Bf7e136FCA85Dd9334",
      symbol: "OM",
      name: "OM",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x9f5755D47fB80100E7ee65Bf7e136FCA85Dd9334/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x9e01e0A928588aE6E669b8d1F0f1fa4AB976f617",
      symbol: "SHO",
      name: "Showcase",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x9e01e0A928588aE6E669b8d1F0f1fa4AB976f617/logo.png",
      coingeckoId: "showcase-token",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x9d600c84f5C9cCfe36c03Cb34D63Ea834CC335D8",
      symbol: "AKT",
      name: "akash.network (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x9d5565dA88e596730522CbC5a918d2A89dbC16d9",
      symbol: "OOE",
      name: "OpenOcean",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9d5565da88e596730522cbc5a918d2a89dbc16d9/82124d68da604f900e236dd424e54343.png",
      coingeckoId: "openocean",
      listedIn: ["coingecko", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0x9ccdf331f97449A84D100918e1e233B097A3E13F",
      symbol: "KYL",
      name: "Kylin Network (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x9c891326Fd8b1a713974f73bb604677E1E63396D",
      symbol: "ISLAMI",
      name: "ISLAMICOIN",
      decimals: 7,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9c891326fd8b1a713974f73bb604677e1e63396d/71f481376c40c1a80b773d74affb64c2.png",
      coingeckoId: "islamicoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
      symbol: "CC10",
      name: "Cryptocurrency Top Tokens Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e/logo.png",
      coingeckoId: null,
      listedIn: ["openocean", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f",
      symbol: "1INCH",
      name: "1Inch (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0x9c2c5fd7b07e95ee044ddeba0e97a665f142394f/logo.png",
      coingeckoId: "1inch",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72",
      symbol: "PUSD",
      name: "PUSD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9af3b7dc29d3c4b1a5731408b6a9656fa7ac3b72/557284a92d9914207b6d7a8a688cfdc9.png",
      coingeckoId: "pusd",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3",
      symbol: "BAL",
      name: "BAL",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/11683/small/Balancer.png?1592792958",
      coingeckoId: "balancer",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x9a05D1FF699ea187Dc8523E333eD63503f0d82db",
      symbol: "BABYQUICK",
      name: "BABYQUICK",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9a05d1ff699ea187dc8523e333ed63503f0d82db/8cf12013bd52c4f68d3fb31adabfbe9d.png",
      coingeckoId: "babyquick",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3",
      symbol: "MULTI",
      name: "Multichain",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3/bf6b4c0d299a4588f5af3a398b0a5171.png",
      coingeckoId: "multichain",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9CD552551EC130b50c1421649C8d11E76aC821e1",
      symbol: "CVOL",
      name: "Crypto Volatility Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/24008/small/govi-dao.03ef3083.png?1646029661",
      coingeckoId: "crypto-volatility-token",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
      symbol: "MUST",
      name: "Must",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/logo.png",
      coingeckoId: "must",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x9C6BfEdc14b5C23E3900889436Edca7805170f01",
      symbol: "PHX",
      name: "Phoenix Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9c6bfedc14b5c23e3900889436edca7805170f01/2955644a3e64393700057f24f86cccaf.png",
      coingeckoId: "phoenix-token",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x9Bd9aD490dD3a52f096D229af4483b94D63BE618",
      symbol: "cxDOGE",
      name: "CelsiusX Wrapped DOGE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9bd9ad490dd3a52f096d229af4483b94d63be618/39aff1f6557259a5a21b5b9c60f9a3d5.png",
      coingeckoId: "celsiusx-wrapped-doge",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9A06Db14D639796B25A6ceC6A1bf614fd98815EC",
      symbol: "$ZKP",
      name: "$ZKP Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9a06db14d639796b25a6cec6a1bf614fd98815ec/ed9ce36c133326cb4af842c8342e03c2.png",
      coingeckoId: "panther",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x99dA82C5464C49962Cdda44fe30d352Bc5Da0580",
      symbol: "QuickChart",
      name: "QuickChart",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x99da82c5464c49962cdda44fe30d352bc5da0580/fce825792b3063f0f27788ce3706b99e.png",
      coingeckoId: "quickchart",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x98ea609569bD25119707451eF982b90E3eb719cD",
      symbol: "maLINK",
      name: "Matic Aave interest bearing LINK",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x98ea609569bD25119707451eF982b90E3eb719cD/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x980111ae1B84E50222C8843e3A7a038F36Fecd2b",
      symbol: "STACK",
      name: "StackOS",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x980111ae1b84e50222c8843e3a7a038f36fecd2b/2457b1e67f592ef87edcaf09b41d0b1d.png",
      coingeckoId: "stackos",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
      symbol: "maUSDC",
      name: "Matic Aave interest bearing USDC",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x9719d867A500Ef117cC201206B8ab51e794d3F82/logo.png",
      coingeckoId: "matic-aave-usdc",
      listedIn: ["coingecko", "openocean", "lifinance", "elkfinance"],
    },
    {
      address: "0x968F6f898a6Df937fC1859b323aC2F14643e3fED",
      symbol: "NWC",
      name: "Newscrypto",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/9805/small/Tu1_NI3s_%281%29.png?1614745476",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89",
      symbol: "ALCX",
      name: "Alchemix",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89/logo.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x957da9EbbCdC97DC4a8C274dD762EC2aB665E15F",
      symbol: "oUSDT",
      name: "Orbit Bridge Polygon Tether USD",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x957da9ebbcdc97dc4a8c274dd762ec2ab665e15f.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8",
      symbol: "Guard",
      name: "Helmet.insure on Polygon",
      decimals: 18,
      chainId: "137",
      logoURI: "https://i.imgur.com/VtDIzy7.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x948D0a28b600BDBd77AF4ea30E6F338167034181",
      symbol: "NSHARE",
      name: "NSHARE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x948d0a28b600bdbd77af4ea30e6f338167034181/e0f56b51071b7815137209605a13d5e4.png",
      coingeckoId: "nshare",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x94788309D420ad9f9f16d79fC13Ab74de83f85F7",
      symbol: "SROCKET",
      name: "Stable One Rocket",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x94788309d420ad9f9f16d79fc13ab74de83f85f7/4658af68a6a7259a1b98f819b049ab91.png",
      coingeckoId: "stable-one-rocket",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x930A7Dc10ae084FBbddC6537D7df7d4c65a40944",
      symbol: "UNLOCK",
      name: "Unlock Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x930a7dc10ae084fbbddc6537d7df7d4c65a40944/65be3130635cd50873fcf62fc2506d01.png",
      coingeckoId: "unlock",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x92A9C92C215092720C731c96D4Ff508c831a714f",
      symbol: "RAILPOLY",
      name: "RAIL Polygon",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x92a9c92c215092720c731c96d4ff508c831a714f/4443918ae04f7f51fc714a3ee3c56c6a.png",
      coingeckoId: "rail-polygon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x92868A5255C628dA08F550a858A802f5351C5223",
      symbol: "BRIDGE",
      name: "Cross-Chain Bridge Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x92868a5255c628da08f550a858a802f5351c5223/55544ad7e956d9ca49533801c001fb23.png",
      coingeckoId: "cross-chain-bridge",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x9246a5F10A79a5a939b0C2a75A3AD196aAfDB43b",
      symbol: "BETS",
      name: "BetSwirl Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x9246a5f10a79a5a939b0c2a75a3ad196aafdb43b/acfb6fa23b55720388e5b59526672f22.png",
      coingeckoId: "betswirl",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x91cA694d2B293f70Fe722fbA7d8A5259188959c3",
      symbol: "MFT",
      name: "Hifi Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/3293/small/hifi_logo.png?1613102742",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x91c89A94567980f0e9723b487b0beD586eE96aa7",
      symbol: "BICO",
      name: "Biconomy Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x91c89a94567980f0e9723b487b0bed586ee96aa7/8195c32c73bd5891a32294ffdbae587e.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x90F3edc7D5298918F7BB51694134b07356F7d0C7",
      symbol: "DDAO",
      name: "DEFI HUNTERS DAO Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x90f3edc7d5298918f7bb51694134b07356f7d0c7/760f40c08822d20d28677e81d9764d70.png",
      coingeckoId: "defi-hunters-dao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x904371845Bc56dCbBcf0225ef84a669b2fD6bd0d",
      symbol: "RELAY",
      name: "Relay Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x904371845bc56dcbbcf0225ef84a669b2fd6bd0d/1a9dc3f79456bc90286d785a6cf50d11.png",
      coingeckoId: "relay-token",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x8f9E8e833A69Aa467E42c46cCA640da84DD4585f",
      symbol: "CHAMP",
      name: "NFT Champions",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8f9e8e833a69aa467e42c46cca640da84dd4585f/7a7f93fc284c50eda344e18785620a63.png",
      coingeckoId: "nft-champions",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x8f6196901a4a153d8eE8F3fa779A042F6092D908",
      symbol: "SALE",
      name: "DxSale.Network (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8f6196901a4a153d8ee8f3fa779a042f6092d908/adba4309a0a0cbf58afc0b6dcd18760b.png",
      coingeckoId: "dxsale-network",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      symbol: "DAI",
      name: "Dai Stablecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/logo.png",
      coingeckoId: "dai",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16",
      symbol: "WOLF",
      name: "moonwolf.io",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16/logo.png",
      coingeckoId: "moonwolf-io",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19",
      symbol: "NSFW",
      name: "Pleasure Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19/d8583ca7f71e6725ac3af0c77f0f233c.png",
      coingeckoId: "pleasure-coin",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41",
      symbol: "TXL",
      name: "Tixl Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41/579e5904f61e6c942455dad79802aaaa.png",
      coingeckoId: "autobahn-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4",
      symbol: "amWMATIC",
      name: "Aave Matic Market WMATIC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4/logo.png",
      coingeckoId: "aave-polygon-wmatic",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x8d520c8E66091cfD6743fe37Fbe3A09505616C4b",
      symbol: "COT",
      name: "CosplayToken (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8d520c8e66091cfd6743fe37fbe3a09505616c4b/d7367a30467256cc8765dd634b1136a4.png",
      coingeckoId: "cosplay-token-2",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x8d1566569d5b695d44a9a234540f68D393cDC40D",
      symbol: "GAME",
      name: "GAME Credits",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8d1566569d5b695d44a9a234540f68D393cDC40D/logo.png",
      coingeckoId: "gamecredits",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x8ca194A3b22077359b5732DE53373D4afC11DeE3",
      symbol: "jCAD",
      name: "Jarvis Synthetic Canadian Dollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8ca194a3b22077359b5732de53373d4afc11dee3/8a140e27c8634f10c616926cfe8d660d.png",
      coingeckoId: "jarvis-synthetic-canadian-dollar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498",
      symbol: "maUNI",
      name: "Matic Aave interest bearing UNI",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
      symbol: "DEGEN",
      name: "DEGEN Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "openocean", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x8a0e8b4b0903929f47C3ea30973940D4a9702067",
      symbol: "INSUR",
      name: "InsurAce (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8a0e8b4b0903929f47c3ea30973940d4a9702067/f4e8cd10b00b967bbb82d80ca9f53bb1.png",
      coingeckoId: "insurace",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8Ece0a50A025A7E13398212a5BEd2ded11959949",
      symbol: "oDAI",
      name: "Orbit Bridge Polygon Dai",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x8ece0a50a025a7e13398212a5bed2ded11959949.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x8EA89Abb7236aE4880EA8FDb42B98768d8EeD505",
      symbol: "ENCTR",
      name: "Encountr",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8ea89abb7236ae4880ea8fdb42b98768d8eed505/988b344c71b4ef7468492bab14e72420.png",
      coingeckoId: "encountr",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8DF26a1BD9bD98e2eC506fc9d8009954716A05DC",
      symbol: "COLLAR",
      name: "PolyPup Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8df26a1bd9bd98e2ec506fc9d8009954716a05dc/9f11f8d44beb64b87dc913612bc6d658.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x8D546026012bF75073d8A586f24A5d5ff75b9716",
      symbol: "SPHERE",
      name: "Sphere Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x8d546026012bf75073d8a586f24a5d5ff75b9716.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x8Cf745561791A43d70F75e85FbC6e3752395C5f0",
      symbol: "XFUN",
      name: "XFUNToken",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x8C92e38eCA8210f4fcBf17F0951b198Dd7668292",
      symbol: "DHT",
      name: "dHedge DAO Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8c92e38eca8210f4fcbf17f0951b198dd7668292/0f08e26a6166f8b39b59f64ed9fef096.png",
      coingeckoId: "dhedge-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x8C4476DFEC8e7EEdf2DE3e9E9461B7c14C828d46",
      symbol: "UNIX",
      name: "UniX Gaming (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46/4b94d4bbf7bffa11be80d35f6391a219.png",
      coingeckoId: "unix",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
      symbol: "POLYDOGE",
      name: "PolyDoge",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8A953CfE442c5E8855cc6c61b1293FA648BAE472/logo.png",
      coingeckoId: "polydoge",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x8A4001fD666BE272605c56BB956d11A46200Db81",
      symbol: "GREEN",
      name: "Green Token (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x89eF0900b0A6b5548ab2FF58EF588F9433b5fCf5",
      symbol: "CRBN",
      name: "Carbon (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x89ef0900b0a6b5548ab2ff58ef588f9433b5fcf5/b1cae7cf6ea2ee95a5ffb61890928e38.png",
      coingeckoId: "carbon",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x89C90E480A39fBE3886bB5bD53ba5B1Acc69D4Fb",
      symbol: "aCRV",
      name: "Aladdin cvxCRV",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x89c90e480a39fbe3886bb5bd53ba5b1acc69d4fb/ed74ca08b3410cc9bc9ac868505bbcc7.png",
      coingeckoId: "aladdin-cvxcrv",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x89C296Be2F904F3e99a6125815b4B78F5388d2dd",
      symbol: "RCN",
      name: "RipioCreditNetwork",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/rcn.jpg",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x88C949b4eB85a90071f2C0beF861BDDEe1a7479D",
      symbol: "mSHEESHA",
      name: "SHEESHA POLYGON",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x88c949b4eb85a90071f2c0bef861bddee1a7479d/8804f6169390b8f0342d932158c693d9.png",
      coingeckoId: "sheesha-finance-polygon",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x8839e639F210B80ffea73AedF51baed8DAc04499",
      symbol: "DWEB",
      name: "DecentraWeb (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8839e639f210b80ffea73aedf51baed8dac04499/0f6cab14f670c15d2bcb4fb94f844d04.png",
      coingeckoId: "decentraweb",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79",
      symbol: "YAMP",
      name: "YAMP.FINANCE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x87f654c4b347230c60cad8d7ea9cf0d7238bcc79/a702222484d527ca703576e3cc5dafa0.png",
      coingeckoId: "yamp-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x87f0BFEE4435CE2B8643b221D0C1CAd21F5328B4",
      symbol: "sd3Crv",
      name: "Stake DAO USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x87f0BFEE4435CE2B8643b221D0C1CAd21F5328B4/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
      symbol: "PLA",
      name: "PlayDapp Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8765f05adce126d70bcdf1b0a48db573316662eb/84adb7b35416aa9229ec472c7ba42251.png",
      coingeckoId: "playdapp",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x86A298581388bc199e61BFeCDcA8eA22Cf6C0Da3",
      symbol: "DELOT",
      name: "DELOT.IO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/25561/large/Logo_200.png",
      coingeckoId: "delot-io",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x8623e66Bea0DCe41B6d47f9C44e806A115baBae0",
      symbol: "NFTY",
      name: "NFTY Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8623e66bea0dce41b6d47f9c44e806a115babae0/afa8729e81a7e9e2c401d3e8a8d39506.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369",
      symbol: "DPI",
      name: "DefiPulse Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x85955046df4668e1dd369d2de9f3aeb98dd2a369/cac4b8786465385d6d4c4b7620db33f7.png",
      coingeckoId: "defipulse-index",
      listedIn: ["coingecko", "sushiswap", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x855D4248672a1fCE482165e8DBE1207b94b1968a",
      symbol: "WOW",
      name: "WOWSwap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x855d4248672a1fce482165e8dbe1207b94b1968a/a35da1e28ab13f40d29c009cc3137921.png",
      coingeckoId: "wowswap",
      listedIn: ["coingecko", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c",
      symbol: "COMP",
      name: "Compound",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c/logo.png",
      coingeckoId: "compound-governance-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x84e1670F61347CDaeD56dcc736FB990fBB47ddC1",
      symbol: "LRC",
      name: "LoopringCoin V2 (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "lifinance"],
    },
    {
      address: "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
      symbol: "SX",
      name: "SportX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79/logo.png",
      coingeckoId: "sx-network",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x839F1a22A59eAAf26c85958712aB32F80FEA23d9",
      symbol: "AXN",
      name: "Axion",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x839f1a22a59eaaf26c85958712ab32f80fea23d9/a06a151876c0030507209d1aa562bbc9.png",
      coingeckoId: "axion",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x8346Ab8d5EA7A9Db0209aEd2d1806AFA0E2c4C21",
      symbol: "MOD",
      name: "MODEFI (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21/44b44df34234bc3aac8f0bc5e6e69ff1.png",
      coingeckoId: "modefi",
      listedIn: ["coingecko", "1inch", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x8343091F2499FD4b6174A46D067A920a3b851FF9",
      symbol: "jJPY",
      name: "Jarvis Synthetic Japanese Yen",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x8343091f2499fd4b6174a46d067a920a3b851ff9/d992db3aa3d9b353a368d84ca3f04fde.png",
      coingeckoId: "jarvis-synthetic-japanese-yen",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
      symbol: "QUICK",
      name: "Quickswap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/logo.png",
      coingeckoId: "quick",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x82a0E6c02b91eC9f6ff943C0A933c03dBaa19689",
      symbol: "WNT",
      name: "Wicrypt Network Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689/4f2f562edf46bbf63af64d5471bb076b.png",
      coingeckoId: "wicrypt",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x82B6205002ecd05e97642D38D61e2cFeaC0E18cE",
      symbol: "mDEF",
      name: "Matic Deflect Protocol",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x82B6205002ecd05e97642D38D61e2cFeaC0E18cE/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x823CD4264C1b951C9209aD0DeAea9988fE8429bF",
      symbol: "maAAVE",
      name: "Matic Aave interest bearing AAVE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x823CD4264C1b951C9209aD0DeAea9988fE8429bF/logo.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance", "elkfinance"],
    },
    {
      address: "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
      symbol: "MESH",
      name: "Meshswap Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x82362ec182db3cf7829014bc61e9be8a2e82868a/e4e55d487af620a14e2c0be3e7d89a24.png",
      coingeckoId: "meshswap-protocol",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x8192759Bf7f247cC92F74E39B3A4225516624fC1",
      symbol: "BOOK",
      name: "Bookie",
      decimals: 8,
      chainId: "137",
      logoURI: "https://bookie.farm/logoether/bookie200.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x80c0CBDB8d0B190238795d376f0bD57fd40525F2",
      symbol: "WONE",
      name: "Wrapped ONE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x8063037ea50E4a066bF1430EA1E3e609CD5cEf6B",
      symbol: "PAE",
      name: "Ripae",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/23420/small/logo192_%282%29.png?1644194954",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x800eB319e3F0E962d3ca8D625C871B8F1bdf2bC8",
      symbol: "AGI",
      name: "SingularityNET Token (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x7ff2FC33E161E3b1C6511B934F0209D304267857",
      symbol: "OPU",
      name: "Opu Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7ff2FC33E161E3b1C6511B934F0209D304267857/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x7f67639Ffc8C93dD558d452b8920b28815638c44",
      symbol: "LIME",
      name: "iMe Lab",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7f67639ffc8c93dd558d452b8920b28815638c44/66c6d4ff4ad2c7b98a87da123569824e.png",
      coingeckoId: "ime-lab",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf",
      symbol: "pAUTO",
      name: "Autofarm",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf/logo.png",
      coingeckoId: "auto",
      listedIn: ["coingecko", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x7f280daC515121DcdA3EaC69eB4C13a52392CACE",
      symbol: "FNC",
      name: "Fancy Games",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x7f280dac515121dcda3eac69eb4c13a52392cace.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      symbol: "WETH",
      name: "Wrapped Ether",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/logo.png",
      coingeckoId: "weth",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x7cb810ecBFd6125E65f451cFBd8Ae657dFFDd6b4",
      symbol: "SOFI",
      name: "Rai.Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/14686/small/sofi.png?1640249595",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x7cC15fEf543f205Bf21018F038F591C6BaDa941c",
      symbol: "POLYCUB",
      name: "PolyCub",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7cc15fef543f205bf21018f038f591c6bada941c/51a12fc5fe84fee64a38cfb88155e5dc.png",
      coingeckoId: "polycub",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7c28F627eA3aEc8B882b51eb1935f66e5b875714",
      symbol: "PAINT",
      name: "Paint",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7c28f627ea3aec8b882b51eb1935f66e5b875714/cece0b06cb9faf290936ea1365dd91a3.png",
      coingeckoId: "paint",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x7FBc10850caE055B27039aF31bD258430e714c62",
      symbol: "UBT",
      name: "Unibright",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7FBc10850caE055B27039aF31bD258430e714c62/logo.png",
      coingeckoId: "unibright",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x7E7fF932FAb08A0af569f93Ce65e7b8b23698Ad8",
      symbol: "Yf-DAI",
      name: "YfDAI.finance (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7e7ff932fab08a0af569f93ce65e7b8b23698ad8/79659c8818426e848ba06936b4c4622d.png",
      coingeckoId: "yfdai-finance",
      listedIn: ["coingecko", "1inch", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x7E4c577ca35913af564ee2a24d882a4946Ec492B",
      symbol: "MOONED",
      name: "MoonEdge",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7e4c577ca35913af564ee2a24d882a4946ec492b/563c062e3fcb6563d76ac969a5af7524.png",
      coingeckoId: "moonedge",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4",
      symbol: "SOL",
      name: "SOL",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4/9aa4990a19587f9bc17f1a514e0afed7.png",
      coingeckoId: null,
      listedIn: ["quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x7CdC0421469398e0F3aA8890693d86c840Ac8931",
      symbol: "AZUKI",
      name: "DokiDokiAzuki",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/logo.png",
      coingeckoId: "azuki",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x79637D860380bd28dF5a07329749654790FAc1Df",
      symbol: "PLATO",
      name: "Plato Game",
      decimals: 18,
      chainId: "137",
      logoURI: "https://plato-farm.s3.ap-northeast-2.amazonaws.com/PLATO.png",
      coingeckoId: "plato-game",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x76e63a3E7Ba1e2E61D3DA86a87479f983dE89a7E",
      symbol: "OMEN",
      name: "Augury Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e/58dec79838d5fa9e67d0b0107b301dba.png",
      coingeckoId: "augury-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64",
      symbol: "CRYSTL",
      name: "CrystalToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64/d4eac4fc51034b2084cdbe14a98daa28.png",
      coingeckoId: "crystl-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x769434dcA303597C8fc4997Bf3DAB233e961Eda2",
      symbol: "XSGD",
      name: "XSGD (PoS)",
      decimals: 6,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x767058F11800FBA6A682E73A6e79ec5eB74Fac8c",
      symbol: "jGBP",
      name: "Jarvis Synthetic British Pound",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x767058f11800fba6a682e73a6e79ec5eb74fac8c/65a8ec523d827d3aec41d332b0b24f7d.png",
      coingeckoId: "jarvis-synthetic-british-pound",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x75455c3DE45dD32CBE9a5aD5E518D3D50823c976",
      symbol: "vDFYN",
      name: "vDFYN",
      decimals: 18,
      chainId: "137",
      logoURI: "https://raw.githubusercontent.com/dfyn/assets/main/vDFYN.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed/40684716e4565ab36a959d59cc4e4a4b.png",
      coingeckoId: "axlusdc",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x72d6066F486bd0052eefB9114B66ae40e0A6031a",
      symbol: "WRX",
      name: "WazirX",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x72d6066F486bd0052eefB9114B66ae40e0A6031a/logo.png",
      coingeckoId: "wazirx",
      listedIn: ["coingecko", "quickswap", "dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0x72bd80445b0db58ebe3E8dB056529D4C5FAF6F2f",
      symbol: "NEAR",
      name: "NEAR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/10365/small/near_icon.png?1601359077",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x723B17718289A91AF252D616DE2C77944962d122",
      symbol: "GAIA",
      name: "GAIA Everworld",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x723b17718289a91af252d616de2c77944962d122/6fcc8e67c86261940248158fb2993e2c.png",
      coingeckoId: "gaia-everworld",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x71B821aa52a49F32EEd535fCA6Eb5aa130085978",
      symbol: "0xBTC",
      name: "0xBitcoin Token",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x71B821aa52a49F32EEd535fCA6Eb5aa130085978/logo.png",
      coingeckoId: "oxbitcoin",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x70c006878a5A50Ed185ac4C87d837633923De296",
      symbol: "REVV",
      name: "REVV",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x70c006878a5a50ed185ac4c87d837633923de296/71d7e56f8365d1d365ca7c3289cdc8be.png",
      coingeckoId: "revv",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x709A4B6217584188DDb93c82f5d716D969accE1C",
      symbol: "HANU",
      name: "Hanu Yokia (PoS)",
      decimals: 12,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x709a4b6217584188ddb93c82f5d716d969acce1c/e9ca33ca1545543c506c624d6d19904a.png",
      coingeckoId: "hanu-yokia",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x7075cAB6bCCA06613e2d071bd918D1a0241379E2",
      symbol: "GFARM2",
      name: "Gains V2",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x7075cAB6bCCA06613e2d071bd918D1a0241379E2/logo.png",
      coingeckoId: "gains-farm",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
      symbol: "SHIB",
      name: "SHIBA INU (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x6f7C932e7684666C9fd1d44527765433e01fF61d",
      symbol: "MKR",
      name: "Maker",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/mkr.jpg",
      coingeckoId: null,
      listedIn: ["uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x6d5f5317308C6fE7D6CE16930353a8Dfd92Ba4D7",
      symbol: "ABI",
      name: "Abachi",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6d5f5317308c6fe7d6ce16930353a8dfd92ba4d7/c3e2c74e71f48ea6fbc88def39d44c61.png",
      coingeckoId: "abachi",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x6bb45cEAC714c52342Ef73ec663479da35934bf7",
      symbol: "BONE",
      name: "BONE Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6bb45ceac714c52342ef73ec663479da35934bf7/a9f33e3b79a852a1a6aa6aff1d701142.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x6b7a87899490EcE95443e979cA9485CBE7E71522",
      symbol: "ONE",
      name: "ONE",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x6acA77CF3BaB0C4E8210A09B57B07854a995289a",
      symbol: "MEED",
      name: "Meeds DAO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6aca77cf3bab0c4e8210a09b57b07854a995289a/d0ad834009e5cc0042a79ddfb8e6d1df.png",
      coingeckoId: "meeds-dao",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8",
      symbol: "ANY",
      name: "Anyswap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/logo.png",
      coingeckoId: "anyswap",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "dfyn",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2",
      symbol: "ALPHA",
      name: "Aavegotchi ALPHA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/24738/small/alpha.png?1648769768",
      coingeckoId: "aavegotchi-alpha",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x6F3Cc27E17a0f2e52D8e7693FF0d892Cea1854bF",
      symbol: "GOO",
      name: "Goo",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6f3cc27e17a0f2e52d8e7693ff0d892cea1854bf/1ae45d1b20565568b39ac7d6c615e527.png",
      coingeckoId: "gooeys",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9",
      symbol: "SHACK",
      name: "Shack Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9/logo.png",
      coingeckoId: "shack",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x6DdB31002abC64e1479Fc439692F7eA061e78165",
      symbol: "COMBO",
      name: "Furucombo (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6ddb31002abc64e1479fc439692f7ea061e78165/9b150dbc5020218e0da4da99990087eb.png",
      coingeckoId: "furucombo",
      listedIn: ["coingecko", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x6D969cEA201e427d2875724Fd4E8044833fBc7f4",
      symbol: "pHBD",
      name: "Polygon HBD",
      decimals: 3,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6d969cea201e427d2875724fd4e8044833fbc7f4/f7996424f8cf5471c1f5d52e1b39cf17.png",
      coingeckoId: "polygon-hbd",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6Ccf12B480A99C54b23647c995f4525D544A7E72",
      symbol: "START",
      name: "BSCstarter",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6ccf12b480a99c54b23647c995f4525d544a7e72/b96ac4728bd2e2ad0b2ff622cba676fa.png",
      coingeckoId: "bscstarter",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6C0AB120dBd11BA701AFF6748568311668F63FE0",
      symbol: "APW",
      name: "APWine",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6C0AB120dBd11BA701AFF6748568311668F63FE0/logo.png",
      coingeckoId: "apwine",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x6AE7Dfc73E0dDE2aa99ac063DcF7e8A63265108c",
      symbol: "JPYC",
      name: "JPY Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6ae7dfc73e0dde2aa99ac063dcf7e8a63265108c/ace37007349b9cecb59c3c7bb0fbbc68.png",
      coingeckoId: "jpyc",
      listedIn: ["coingecko", "sushiswap", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd",
      symbol: "gSAT",
      name: "GrumpySat",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5",
      symbol: "MONA",
      name: "Monavale",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5/logo.png",
      coingeckoId: "monavale",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x695FC8B80F344411F34bDbCb4E621aA69AdA384b",
      symbol: "NITRO",
      name: "Nitro (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x695fc8b80f344411f34bdbcb4e621aa69ada384b/f2592d73a169185a8f05c0ad4027dd4b.png",
      coingeckoId: "nitro-league",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x692C44990E4F408ba0917f5c78A83160c1557237",
      symbol: "THALES",
      name: "Thales",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x692c44990e4f408ba0917f5c78a83160c1557237/274d0c313fd4cf480ff9f98ce1355a7f.png",
      coingeckoId: "thales",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x692AC1e363ae34b6B489148152b12e2785a3d8d6",
      symbol: "TRADE",
      name: "Polytrade",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x692ac1e363ae34b6b489148152b12e2785a3d8d6/e311f810ef3f0643cee4c42fe6e8c9b2.png",
      coingeckoId: "polytrade",
      listedIn: ["coingecko", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x692597b009d13C4049a947CAB2239b7d6517875F",
      symbol: "UST",
      name: "Wrapped UST Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x692597b009d13c4049a947cab2239b7d6517875f/57ae3a082518049e3dcf26e142c0d699.png",
      coingeckoId: "wrapped-ust",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d",
      symbol: "SNP",
      name: "SynapseNetwork",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d/87e25353b695d48688301786713ec17c.png",
      coingeckoId: "synapse-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x689f8e5913C158fFB5Ac5aeb83b3C875F5d20309",
      symbol: "SNK",
      name: "Snook",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309/a37190530c6b84729b825f109f08480b.png",
      coingeckoId: "snook",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x6863BD30C9e313B264657B107352bA246F8Af8e0",
      symbol: "BPT",
      name: "Blackpool",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x6863BD30C9e313B264657B107352bA246F8Af8e0/logo.png",
      coingeckoId: "blackpool-token",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x6811079E3c63ED96Eb005384d7E7ec8810E3D521",
      symbol: "xSUSHI",
      name: "SushiBar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/icons/master/token/xsushi.jpg",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6",
      symbol: "GIDDY",
      name: "Giddy Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6/f8306f32ce10c3df947831e7b66ae652.png",
      coingeckoId: "giddy",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6749441Fdc8650b5b5a854ed255C82EF361f1596",
      symbol: "LUCHA",
      name: "Lucha",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6749441fdc8650b5b5a854ed255c82ef361f1596/c79c35d481e723830df3d6c4d41075b1.png",
      coingeckoId: "lucha",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x67480287CB3715D1d9429B38772c71d6E94C16da",
      symbol: "UNN",
      name: "Union",
      decimals: 18,
      chainId: "137",
      logoURI: "https://etherscan.io/token/images/unionprotocol_32.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x66e8617d1Df7ab523a316a6c01D16Aa5beD93681",
      symbol: "SPICE",
      name: "Spice",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x66e8617d1df7ab523a316a6c01d16aa5bed93681/e7d4e56dc3306e9d0f56566c5390ca90.png",
      coingeckoId: "spice-trade",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x66d7FDCc7403f18cAE9b0e2e8385649D2AcBC12A",
      symbol: "ETH2x-FLI",
      name: "ETH 2x Flexible Leverage Index PoS",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x66d7FDCc7403f18cAE9b0e2e8385649D2AcBC12A/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x66F31345Cb9477B427A1036D43f923a557C432A4",
      symbol: "iUSDS",
      name: "Inflation Adjusted Spice USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x66f31345cb9477b427a1036d43f923a557c432a4/89c3457b0b3d7bd755d5ea338d2170e3.png",
      coingeckoId: "inflation-adjusted-usds",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x66C59Dded4EF01a3412a8B019B6e41D4a8C49A35",
      symbol: "SDO",
      name: "SafeDollar.Fi Dollar 2.0",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x66c59dded4ef01a3412a8b019b6e41d4a8c49a35/5975b1039b70be45ac98e62df7f63203.png",
      coingeckoId: "safedollar",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x669ddc70273084Ea30e6cd4f28CA6e2C70735065",
      symbol: "AGAc",
      name: "AGAc",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x669ddc70273084Ea30e6cd4f28CA6e2C70735065/logo.png",
      coingeckoId: "aga-carbon-credit",
      listedIn: ["coingecko", "dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0x66768ad00746aC4d68ded9f64886d55d5243f5Ec",
      symbol: "mRBAL",
      name: "Matic Rebalance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x66768ad00746aC4d68ded9f64886d55d5243f5Ec/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x65A05DB8322701724c197AF82C9CaE41195B0aA8",
      symbol: "FOX",
      name: "ShapeShift FOX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x65a05db8322701724c197af82c9cae41195b0aa8/a3ba70c14f1dfe0cd7bff6c759686b33.png",
      coingeckoId: "shapeshift-fox-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x652E36ecFA0E4F7e435F6D38688D68a3bE65639c",
      symbol: "mWBTC",
      name: "Mushrooming WBTC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x652E36ecFA0E4F7e435F6D38688D68a3bE65639c/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x64ca1571d1476b7a21C5aaf9f1a750A193A103C0",
      symbol: "BONDLY",
      name: "Bondly (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0/124b42b5e361e907c1c3f354df578f6e.png",
      coingeckoId: "bondly",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x64875Aaa68d1d5521666C67d692Ee0B926b08b2F",
      symbol: "cxADA",
      name: "CelsiusX Wrapped ADA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x64875aaa68d1d5521666c67d692ee0b926b08b2f/2f40378bfd3bfede92949be1b50e2439.png",
      coingeckoId: "celsiusx-wrapped-ada",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x64060aB139Feaae7f06Ca4E63189D86aDEb51691",
      symbol: "UNIM",
      name: "Unicorn Milk",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x64060ab139feaae7f06ca4e63189d86adeb51691/07c302121eea0493e38cf8f3b6ff3db6.png",
      coingeckoId: "unicorn-milk",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x638Df98Ad8069a15569dA5a6b01181804c47e34c",
      symbol: "DAFI",
      name: "DAFI Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x638df98ad8069a15569da5a6b01181804c47e34c/daa22d65d50156087ba8cbee56e4f685.png",
      coingeckoId: "dafi-protocol",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x62F594339830b90AE4C084aE7D223fFAFd9658A7",
      symbol: "SPHERE",
      name: "Sphere Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x62f594339830b90ae4c084ae7d223ffafd9658a7/a07f70a95972affee40171c2f02554f4.png",
      coingeckoId: "sphere-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x6286A9e6f7e745A6D884561D88F94542d6715698",
      symbol: "TECH",
      name: "Cryptomeda",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6286a9e6f7e745a6d884561d88f94542d6715698/71626b9911c320afe88ddce9b6402d5c.png",
      coingeckoId: "cryptomeda",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x623a1350f6B749575F92Ea54D0F745f9F07D3665",
      symbol: "Serum",
      name: "Karmaverse Zombie Serum",
      decimals: 0,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x623a1350f6b749575f92ea54d0f745f9f07d3665/cef00f9e8a27d967636d4d281a1a6647.png",
      coingeckoId: "karmaverse-zombie-serum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x61f95bd637e3034133335C1baA0148E518D438ad",
      symbol: "MHUNT",
      name: "MetaShooter",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x61f95bd637e3034133335c1baa0148e518d438ad/9984d8a66ebbce79ac1c5f84166c272d.png",
      coingeckoId: "metashooter",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7",
      symbol: "DMAGIC",
      name: "Dark Magic",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x61daecab65ee2a1d5b6032df030f3faa3d116aa7/000be495f3be3ca4bbdf6129b5ae9099.png",
      coingeckoId: "dark-magic",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x61E9c2F3501889f6167921087Bd6EA306002904a",
      symbol: "PIXEL",
      name: "Pixel",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x61E9c2F3501889f6167921087Bd6EA306002904a/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b",
      symbol: "AXS",
      name: "Axie Infinity Shard (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x614389EaAE0A6821DC49062D56BDA3d9d45Fa2ff",
      symbol: "ORBS",
      name: "Orbs (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x614389eaae0a6821dc49062d56bda3d9d45fa2ff/fa20bb1cf2854aec5097fbc25f5c3c7b.png",
      coingeckoId: "orbs",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x613a489785C95afEB3b404CC41565cCff107B6E0",
      symbol: "RADIO",
      name: "Radio Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x613a489785C95afEB3b404CC41565cCff107B6E0/logo.png",
      coingeckoId: "radioshack",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x61299774020dA444Af134c82fa83E3810b309991",
      symbol: "RNDR",
      name: "Render Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x61299774020da444af134c82fa83e3810b309991/4b87d5a2c2012e350a19b6996ae4eac0.png",
      coingeckoId: "render-token",
      listedIn: ["coingecko", "openocean", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x6109cB051c5C64093830121ed76272aB04bBDD7C",
      symbol: "PROS",
      name: "Prosper",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6109cb051c5c64093830121ed76272ab04bbdd7c/7bc739c1d729a13444069cf0232d1592.png",
      coingeckoId: "prosper",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x60caA53091da042196Cb9B5f98aAA9552AeF8c82",
      symbol: "CUBAN",
      name: "TITANDAO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://drive.google.com/file/d/1yuahTv9deIVo5hRT01rTBApmmtftCYts/view",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x60bB3D364B765C497C8cE50AE0Ae3f0882c5bD05",
      symbol: "IMX",
      name: "Impermax",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x60bb3d364b765c497c8ce50ae0ae3f0882c5bd05/3ce65bd522f6b93f3381795bd6d421ed.png",
      coingeckoId: "impermax",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x60D55F02A771d515e077c9C2403a1ef324885CeC",
      symbol: "amUSDT",
      name: "Aave Matic Market USDT",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x60D55F02A771d515e077c9C2403a1ef324885CeC/logo.png",
      coingeckoId: "aave-polygon-usdt",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x60AC2E84078Ce30CBC68e3d7b18bCF613271ce6B",
      symbol: "ALOHA",
      name: "ALOHA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x60ac2e84078ce30cbc68e3d7b18bcf613271ce6b/2bb4c3de0c07b85961de8cda0a480348.png",
      coingeckoId: "aloha",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x6002410dDA2Fb88b4D0dc3c1D562F7761191eA80",
      symbol: "WORK",
      name: "The Employment Commons Work Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x6002410dda2fb88b4d0dc3c1d562f7761191ea80/0bf96eaf72281ded70096d58159a2543.png",
      coingeckoId: "the-employment-commons-work-token",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x5fe2B58c013d7601147DcdD68C143A77499f5531",
      symbol: "GRT",
      name: "GRT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/13397/small/Graph_Token.png?1608145566",
      coingeckoId: "the-graph",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26",
      symbol: "DHV",
      name: "DeHive.finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5fcb9de282af6122ce3518cde28b7089c9f97b26/3dc5afb23cd358bfd56d33d7e7033c36.png",
      coingeckoId: "dehive",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b",
      symbol: "TSA",
      name: "TEAsWAP.ART",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5f99acf13caff815dd9cb4a415c0fb34e9f4545b/a6de8fb2be3bd4cf7c575f4de1a92777.png",
      coingeckoId: "teaswap-art",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5f1657896B38c4761dbc5484473c7A7C845910b6",
      symbol: "pSWAMP",
      name: "pSwampy",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://ethapi.openocean.finance/logos/polygon/0x5f1657896b38c4761dbc5484473c7a7c845910b6.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x5f084F3EE7EA09E4C01CEE3cDF1B5620A3344fd0",
      symbol: "KIF",
      name: "KIF",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x5f084F3EE7EA09E4C01CEE3cDF1B5620A3344fd0/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x5f0197Ba06860DaC7e31258BdF749F92b6a636d4",
      symbol: "1FLR",
      name: "Flare Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5f0197ba06860dac7e31258bdf749f92b6a636d4/051ee183887b4918b3362c71e04fd423.png",
      coingeckoId: "flare-token",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5eC03C1f7fA7FF05EC476d19e34A22eDDb48ACdc",
      symbol: "ZED",
      name: "ZED RUN",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5ec03c1f7fa7ff05ec476d19e34a22eddb48acdc/b034f97a71152a87de9837f7246edc2d.png",
      coingeckoId: "zed-run",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5eB8D998371971D01954205c7AFE90A7AF6a95AC",
      symbol: "AUDIO",
      name: "Audius",
      decimals: 18,
      chainId: "137",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7455.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x5de4005155933c0e1612Ce808f12B4cd8DAabc82",
      symbol: "ARMOR",
      name: "Armor (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x5dD175A4242afE19e5c1051d8cd13Fc8979f2329",
      symbol: "RVRS",
      name: "Reverse",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5dd175a4242afe19e5c1051d8cd13fc8979f2329/3b819c5c0675e85f0551027faf00a21c.png",
      coingeckoId: "reverse",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x5d5530eb3147152FE78d5C4bFEeDe054c8d1442A",
      symbol: "WNOW",
      name: "WalletNow",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5d5530eb3147152fe78d5c4bfeede054c8d1442a/e551e069c4b5deb87370429a94edc52a.png",
      coingeckoId: "walletnow",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5d47bAbA0d66083C52009271faF3F50DCc01023C",
      symbol: "BANANA",
      name: "ApeSwapFinance Banana",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5d47baba0d66083c52009271faf3f50dcc01023c/4f099f1c7676d31fbdc7a3642a0c9667.png",
      coingeckoId: "apeswap-finance",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x5d146d8B1dACb1EBBA5cb005ae1059DA8a1FbF57",
      symbol: "CADC",
      name: "CAD Coin (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5d146d8b1dacb1ebba5cb005ae1059da8a1fbf57/79895da3fbe54658346973e3e5de7278.png",
      coingeckoId: "cad-coin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5d0915f929FC090fd9c843a53e7e30335dD199bc",
      symbol: "pTREAT",
      name: "pTREAT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x5d0915f929FC090fd9c843a53e7e30335dD199bc/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x5c947eB80D096A5e332bF79bfDc9feb3D0a201d7",
      symbol: "SPIT",
      name: "Spit Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5c947eb80d096a5e332bf79bfdc9feb3d0a201d7/6c4d548929c2146c0283a327f525533e.png",
      coingeckoId: "llamaverse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F",
      symbol: "BNB",
      name: "Binance Token",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x5c2ed810328349100A66B82b78a1791B101C9D61",
      symbol: "amWBTC",
      name: "Aave Matic Market WBTC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x5c2ed810328349100A66B82b78a1791B101C9D61/logo.png",
      coingeckoId: "aave-polygon-wbtc",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x5bEF2617eCCA9a39924c09017c5F1E25Efbb3bA8",
      symbol: "oUSDC",
      name: "Orbit Bridge Polygon USD Coin",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x5bef2617ecca9a39924c09017c5f1e25efbb3ba8.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x5a3064CbDCCF428ae907796cF6aD5a664CD7F3d8",
      symbol: "PYQ",
      name: "PYQ",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5a3064cbdccf428ae907796cf6ad5a664cd7f3d8/1925716b283379951ec9a6dce3e3facc.png",
      coingeckoId: "polyquity",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x5FFD62D3C3eE2E81C00A7b9079FB248e7dF024A8",
      symbol: "GNO",
      name: "Gnosis Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0x5ffd62d3c3ee2e81c00a7b9079fb248e7df024a8/logo.png",
      coingeckoId: null,
      listedIn: ["uniswap", "rubic"],
    },
    {
      address: "0x5FBccc4Acc0B9339D7CDC5d6336A5c88D89e5327",
      symbol: "SOLAR",
      name: "SOLAR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/Palette-Finance/images/main/solar.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x5EEE4d55961705Eb55D12Cb6FA70EB93c4063216",
      symbol: "BSGG",
      name: "Betswap.gg",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/22496/large/betswap.jpg",
      coingeckoId: null,
      listedIn: ["RouterProtocol", "lifinance"],
    },
    {
      address: "0x5E430F88D1BE82EB3eF92b6fF06125168fD5DCf2",
      symbol: "MODA",
      name: "moda",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2/4693ebb12fc98fec9a572ff02a845fda.png",
      coingeckoId: "moda-dao",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x5E0294Af1732498C77F8dB015a2d52a76298542B",
      symbol: "ORION",
      name: "Orion Money Token (Wormhole)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5e0294af1732498c77f8db015a2d52a76298542b/7e461ed64fc4ad5fe20ad2789bb44294.png",
      coingeckoId: "orion-money",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x5CEeBB0947d58Fabde2fc026Ffe4B33ccFE1bA8B",
      symbol: "4INT",
      name: "4INT",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5ceebb0947d58fabde2fc026ffe4b33ccfe1ba8b/c774b9bde490036a47fcb5666b28796a.png",
      coingeckoId: "4int",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C",
      symbol: "DOKI",
      name: "DokiDokiFinance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C/logo.png",
      coingeckoId: "doki-doki-finance",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x5B4CF2C120A9702225814E18543ee658c5f8631e",
      symbol: "UFT",
      name: "UniLend Finance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5b4cf2c120a9702225814e18543ee658c5f8631e/ab271551c45c135244b049cdc12b6e5f.png",
      coingeckoId: "unlend-finance",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x59E9261255644c411AfDd00bD89162d09D862e38",
      symbol: "ETHA",
      name: "ETHA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x59e9261255644c411afdd00bd89162d09d862e38/bb31fdeb9db8d6f9620c4ae4099e2480.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x59B5654a17Ac44F3068b3882F298881433bB07Ef",
      symbol: "CHP",
      name: "CoinPoker Chips (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x59b5654a17ac44f3068b3882f298881433bb07ef/7aec12c66c8450a5d4bd6320627e562a.png",
      coingeckoId: "coinpoker",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x598e49f01bEfeB1753737934a5b11fea9119C796",
      symbol: "ADS",
      name: "Adshares",
      decimals: 11,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x598e49f01befeb1753737934a5b11fea9119c796/ac7f437f47b961c42b3aca2701a17631.png",
      coingeckoId: "adshares",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x596eBE76e2DB4470966ea395B0d063aC6197A8C5",
      symbol: "JRT",
      name: "Jarvis Reward Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x596ebe76e2db4470966ea395b0d063ac6197a8c5/daf3e9c8675f61832bff1059e5116de6.png",
      coingeckoId: "jarvis-reward-token",
      listedIn: ["coingecko", "sushiswap", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
      symbol: "QiDAO",
      name: "QiDAO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x580A84C73811E1839F75d86d75d88cCa0c241fF4/logo.png",
      coingeckoId: "qi-dao",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "quickswap",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x578360AdF0BbB2F10ec9cEC7EF89Ef495511ED5f",
      symbol: "KIWI",
      name: "KIWI Token",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0x578360adf0bbb2f10ec9cec7ef89ef495511ed5f/logo.png",
      coingeckoId: "kiwi-token",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x577aa684B89578628941D648f1Fbd6dDE338F059",
      symbol: "APOLLO",
      name: "Apollo",
      decimals: 18,
      chainId: "137",
      logoURI: "https://i.ibb.co/YLYTVJ7/APOLLO-PNG2.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x576c990A8a3E7217122e9973b2230A3be9678E94",
      symbol: "FIN",
      name: "DeFiner",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x576c990a8a3e7217122e9973b2230a3be9678e94/f4096d8aab1b3f0110665e6e3052e278.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x56A0eFEFC9F1FBb54FBd25629Ac2aA764F1b56F7",
      symbol: "AWX",
      name: "AurusDeFi",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x56a0efefc9f1fbb54fbd25629ac2aa764f1b56f7/2f7fb32675698ffe0d838f643d64434a.png",
      coingeckoId: "auruscoin",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x5647Fe4281F8F6F01E84BCE775AD4b828A7b8927",
      symbol: "MM",
      name: "Million",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5647fe4281f8f6f01e84bce775ad4b828a7b8927/52cb0f908d9449c8b2fda33887c9e7f7.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x5617604BA0a30E0ff1d2163aB94E50d8b6D0B0Df",
      symbol: "AX",
      name: "AthleteX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x5617604ba0a30e0ff1d2163ab94e50d8b6d0b0df/51d94cc8c7a1963e033ef5dcf13beec5.png",
      coingeckoId: "athletex",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x556f501CF8a43216Df5bc9cC57Eb04D4FFAA9e6D",
      symbol: "DUST",
      name: "Distant Universe Stardust Token",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x556f501cf8a43216df5bc9cc57eb04d4ffaa9e6d/852bddae11c8ac68e7e2340f80bef7d7.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x5559Edb74751A0edE9DeA4DC23aeE72cCA6bE3D5",
      symbol: "ZRX",
      name: "0x Protocol Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "lifinance"],
    },
    {
      address: "0x55555555A687343C6Ce28C8E1F6641dc71659fAd",
      symbol: "XY",
      name: "XY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622",
      coingeckoId: "xy-finance",
      listedIn: ["coingecko", "xyfinance"],
    },
    {
      address: "0x554f074d9cCda8F483d1812d4874cBebD682644E",
      symbol: "$ANRX",
      name: "AnRKey X (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x554f074d9ccda8f483d1812d4874cbebd682644e/c2f2b0daff01ac4d3aadf2499e685b9f.png",
      coingeckoId: "anrkey-x",
      listedIn: ["coingecko", "1inch", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x553d3D295e0f695B9228246232eDF400ed3560B5",
      symbol: "PAXG",
      name: "Paxos Gold",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/9519/small/paxg.PNG?1568542565",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x54cFe73f2c7d0c4b62Ab869B473F5512Dc0944D2",
      symbol: "BZRX",
      name: "BZRX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x54cfe73f2c7d0c4b62ab869b473f5512dc0944d2/de8708b026fb8f3f787c15b2346df189.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
      symbol: "WLINK",
      name: "Wrapped ChainLink Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39/logo.png",
      coingeckoId: "chainlink",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x5314bA045a459f63906Aa7C76d9F337DcB7d6995",
      symbol: "FODL",
      name: "FODL",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/19040/small/new.jpg?1634559024",
      coingeckoId: "fodl-finance",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance", "xyfinance"],
    },
    {
      address: "0x52eDe6bbA83B7b4Ba1D738Df0dF713D6A2036B71",
      symbol: "0xMR",
      name: "0xMonero (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: "0xmonero",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC",
      symbol: "HH",
      name: "Holyheld",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC/logo.png",
      coingeckoId: "holyheld-2",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x51dE72b17c7bD12E9E6d69eb506a669EB6b5249E",
      symbol: "EGG",
      name: "Waves Ducks",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x51de72b17c7bd12e9e6d69eb506a669eb6b5249e/2b7164da1ddb6657ca4ae28a7083c5ab.png",
      coingeckoId: "waves-ducks",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x51b5619F5180e333d18b6310C8D540AEa43a0371",
      symbol: "VHC",
      name: "Vault Hill City (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x51b5619f5180e333d18b6310c8d540aea43a0371/b4693de470351118a189dc0838580b28.png",
      coingeckoId: "vault-hill-city",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x51869836681BcE74a514625c856aFb697a013797",
      symbol: "GENESIS",
      name: "Genesis",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x51869836681bce74a514625c856afb697a013797/7098668bbe05e9719656cf41dc8532f8.png",
      coingeckoId: "genesis-worlds",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x50B728D8D964fd00C2d0AAD81718b71311feF68a",
      symbol: "SNX",
      name: "SNX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/3406/small/SNX.png?1598631139",
      coingeckoId: "havven",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x503836c8c3A453c57f58CC99B070F2E78Ec14fC0",
      symbol: "SPORT",
      name: "Sport",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x503836c8c3a453c57f58cc99b070f2e78ec14fc0/3f03be9d1e0638b2d1404a159690ca4c.png",
      coingeckoId: "sport",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4fdCE518fE527439Fe76883e6b51A1c522B61b7C",
      symbol: "COR",
      name: "Coreto",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4fdce518fe527439fe76883e6b51a1c522b61b7c/3b9fa8371c80889823ed03d11153d9cb.png",
      coingeckoId: "coreto",
      listedIn: ["coingecko", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x4eBdE54ba404bE158262EDe801744b92b9878c61",
      symbol: "eUSDC",
      name: "Easy USDC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x4eBdE54ba404bE158262EDe801744b92b9878c61/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0x4e830F67Ec499E69930867f9017AEb5B3f629c73",
      symbol: "ODDZ",
      name: "OddzToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4e830f67ec499e69930867f9017aeb5b3f629c73/95f0fb13572d34e83c6a57986dffec46.png",
      coingeckoId: "oddz",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x4e78011Ce80ee02d2c3e649Fb657E45898257815",
      symbol: "KLIMA",
      name: "Klima DAO",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4e78011ce80ee02d2c3e649fb657e45898257815/197079d025a14e33e533c512e6e158c6.png",
      coingeckoId: "klima-dao",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x4e3Decbb3645551B8A19f0eA1678079FCB33fB4c",
      symbol: "jEUR",
      name: "Jarvis Synthetic Euro",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c/1e153fa6bb41ac7181ec0204b24932d7.png",
      coingeckoId: "jarvis-synthetic-euro",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4e1581f01046eFDd7a1a2CDB0F82cdd7F71F2E59",
      symbol: "ICE",
      name: "IceToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59/a965bb280881f7f53a15da73debbc751.png",
      coingeckoId: "ice-token",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x4d8181f051E617642e233Be09Cea71Cc3308ffD4",
      symbol: "DIGI",
      name: "Digi",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4d8181f051e617642e233be09cea71cc3308ffd4/2ebd0f72cea8a4988c324e98193616c8.png",
      coingeckoId: "digible",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x4c4BF319237D98a30A929A96112EfFa8DA3510EB",
      symbol: "WEXPOLY",
      name: "POLYWEX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x4c4BF319237D98a30A929A96112EfFa8DA3510EB/logo.png",
      coingeckoId: "waultswap-polygon",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x4c28f48448720e9000907BC2611F73022fdcE1fA",
      symbol: "wMatic",
      name: "wMatic",
      decimals: 18,
      chainId: "137",
      logoURI: "https://cryptologos.cc/logos/matic-network-matic-logo.svg?v=006",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x4b54Bc363f5F9c6E0fcd82EaC6919aE213464Cc6",
      symbol: "BTC2x-FLI",
      name: "BTC 2x Flexible Leverage Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x4b54Bc363f5F9c6E0fcd82EaC6919aE213464Cc6/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x4FF0b68ABc2b9E4e1401E9b691DBa7d66B264AC8",
      symbol: "RIOT",
      name: "RIOT (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8/49d64b4590afe22200356f94b59796c0.png",
      coingeckoId: "riot-racers",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x4F6cbacA3151f7746273004Fd7295933a9b70E69",
      symbol: "WHIRL",
      name: "OmniWhirl",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4f6cbaca3151f7746273004fd7295933a9b70e69/5113a9e4838eb826b09e15d3e30c6ffa.png",
      coingeckoId: "omniwhirl",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4EaC4c4e9050464067D673102F8E24b2FccEB350",
      symbol: "ibBTC",
      name: "Interest-Bearing BTC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x4EaC4c4e9050464067D673102F8E24b2FccEB350/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x4E8dc2149EaC3f3dEf36b1c281EA466338249371",
      symbol: "INJ",
      name: "Injective Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x4DE7FEA447b837d7E77848a4B6C0662a64A84E14",
      symbol: "WAVE",
      name: "polyWAVE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x4DE7FEA447b837d7E77848a4B6C0662a64A84E14/logo.png",
      coingeckoId: "polywave",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x4D0Def42Cf57D6f27CD4983042a55dce1C9F853c",
      symbol: "KIT",
      name: "DexKit (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0x4d0def42cf57d6f27cd4983042a55dce1c9f853c/logo.png",
      coingeckoId: "dexkit",
      listedIn: ["coingecko", "rubic"],
    },
    {
      address: "0x4C9f66B2806538cf00ef596E09FB05bcb0D17Dc8",
      symbol: "MNTO",
      name: "Minato (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4c9f66b2806538cf00ef596e09fb05bcb0d17dc8/82b312bf0e8c42c03148b2631ef8bf86.png",
      coingeckoId: "minato",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a",
      symbol: "polyBUNNY",
      name: "Polygon Bunny Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4c16f69302ccb511c5fac682c7626b9ef0dc126a/056f62ee3cd445b988b6bfe802f42153.png",
      coingeckoId: "bunny-token-polygon",
      listedIn: ["coingecko", "sushiswap", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0x4AF5ff1A60a6eF6C7c8f9C4E304cD9051fCa3Ec0",
      symbol: "RGP",
      name: "RIGEL PROTOCOL",
      decimals: "18",
      chainId: "137",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png",
      coingeckoId: "rigel-protocol",
      listedIn: ["coingecko", "RouterProtocol"],
    },
    {
      address: "0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef",
      symbol: "ICE",
      name: "Iron Finance ICE Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef/32984ad401e1ecbe7753ede31276a2e1.png",
      coingeckoId: "iron-finance",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0x4A7b9A4589A88A06ca29F99556dB08234078D727",
      symbol: "GEM",
      name: "NFTmall GEM Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png",
      coingeckoId: "nftmall",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x49e6A20f1BBdfEeC2a8222E052000BbB14EE6007",
      symbol: "TNGBL",
      name: "Tangible",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007/2f07fea3de4943f1b4b662a55dcfe084.png",
      coingeckoId: "tangible",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x49a0400587A7F65072c87c4910449fDcC5c47242",
      symbol: "MIM",
      name: "Magic Internet Money",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x49a0400587a7f65072c87c4910449fdcc5c47242/95c3c6e088933acaf8edf11631c2253a.png",
      coingeckoId: "magic-internet-money",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x49Fc111e5Ddd5580f48D6fDc4314540cb3A5CC4B",
      symbol: "1337",
      name: "1337 (PoS)",
      decimals: 4,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x49690541E3f6E933A9aa3cFFEe6010a7BB5B72d7",
      symbol: "AXIA",
      name: "Axia (axiaprotocol.io)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x49690541e3f6e933a9aa3cffee6010a7bb5b72d7/47a81379fd588ead00405e6ab169b791.png",
      coingeckoId: "axia",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA",
      symbol: "SENT",
      name: "Sentinel",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x48C97cf0A3837106Cb58009D308DF4DfAbe441C7",
      symbol: "VERSE",
      name: "Verse",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x48c97cf0a3837106cb58009d308df4dfabe441c7/006c6f2ef7fda2a4c3b3ca4dd4c79697.png",
      coingeckoId: "verse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x47536F17F4fF30e64A96a7555826b8f9e66ec468",
      symbol: "BELUGA",
      name: "BelugaToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x47536f17f4ff30e64a96a7555826b8f9e66ec468/82fa60713d377fcd95a5d8afc1d04039.png",
      coingeckoId: "beluga-fi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x46F48FbdedAa6F5500993BEDE9539ef85F4BeE8e",
      symbol: "ARIA20",
      name: "ARIANEE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x46F48FbdedAa6F5500993BEDE9539ef85F4BeE8e/logo.png",
      coingeckoId: "arianee",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F",
      symbol: "WSPP",
      name: "WolfSafePoorPeople",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f/8ae7822f4deb3645f1a53aeb4b556634.png",
      coingeckoId: "wolfsafepoorpeople-polygon",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x462D8d82C2B2D2DDabf7f8a93928De09d47A5807",
      symbol: "BZB",
      name: "BlazarBits",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://flowimage.flow.page/resize?img=https%3A%2F%2Fcdn.flow.page%2Fimages%2Fe5e655f1-421f-4948-be2e-c679ea1cdc2d-profile-picture%3Fm%3D1610401591&w=320",
      coingeckoId: null,
      listedIn: ["dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
      symbol: "FRAX",
      name: "FRAX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
      coingeckoId: "frax",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x4597c8A59Ab28B36840B82B3A674994A279593D0",
      symbol: "COVAL",
      name: "Circuits of Value V2",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4597c8a59ab28b36840b82b3a674994a279593d0/2548c6df7124ad6f77ec6f3fb0718c69.png",
      coingeckoId: "circuits-of-value",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x454E1Ec2F58ee542B75d2AB713Ed53Cd33Ba4308",
      symbol: "300",
      name: "Spartan (PoS)",
      decimals: 7,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x4535E52cdF3AB787B379b7b72B5990767E6747E4",
      symbol: "MYT",
      name: "Mytrade Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4535e52cdf3ab787b379b7b72b5990767e6747e4/7a92e3e5e34ee21a80667612b8a2847d.png",
      coingeckoId: "mytrade",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x44d09156c7b4ACf0C64459Fbcced7613F5519918",
      symbol: "$KMC",
      name: "$KMC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x44d09156c7b4acf0c64459fbcced7613f5519918/6e72f17c67daad3818fb641cc15ac3da.png",
      coingeckoId: "kitsumon",
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x44A6e0BE76e1D9620A7F76588e4509fE4fa8E8C8",
      symbol: "FOMO",
      name: "Aavegotchi FOMO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/24737/small/fomo.png?1648769659",
      coingeckoId: "aavegotchi-fomo",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D",
      symbol: "KNIGHT",
      name: "Forest Knight",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4455ef8b4b4a007a93daa12de63a47eeac700d9d/2362c15ffe9b4f72b5bfb66eb6e284cb.png",
      coingeckoId: "forest-knight",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x43E4B063F96c33F0433863A927F5bad34bb4B03d",
      symbol: "EWTB",
      name: "Energy Web Token Bridged (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x43Df9c0a1156c96cEa98737b511ac89D0e2A1F46",
      symbol: "GOVI",
      name: "CVI Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x43df9c0a1156c96cea98737b511ac89d0e2a1f46/ed702729f956fb703ef496ab343f2c79.png",
      coingeckoId: "govi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
      symbol: "JPYC",
      name: "JPY Coin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x431d5dff03120afa4bdf332c61a6e1766ef37bdb/e1760f74cc0ab137b7fae09edd40fb3a.png",
      coingeckoId: "jpy-coin",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x431CD3C9AC9Fc73644BF68bF5691f4B83F9E104f",
      symbol: "RBW",
      name: "RBW",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/24498/small/icon_rbw.png?1648019306",
      coingeckoId: "rainbow-token-2",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x430EF9263E76DAE63c84292C3409D61c598E9682",
      symbol: "PYR",
      name: "PYR Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x430ef9263e76dae63c84292c3409d61c598e9682/c16bac72715fbe4a89b879ed7637fca7.png",
      coingeckoId: "vulcan-forged",
      listedIn: ["coingecko", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0x42f37A1296b2981F7C3cAcEd84c5096b2Eb0C72C",
      symbol: "KEEP",
      name: "KEEP Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://api.rubic.exchange/assets/polygon/0x42f37a1296b2981f7c3caced84c5096b2eb0c72c/logo.png",
      coingeckoId: null,
      listedIn: ["uniswap", "rubic"],
    },
    {
      address: "0x42dbBd5ae373FEA2FC320F62d44C058522Bb3758",
      symbol: "MEM",
      name: "Memecoin",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x42dbbd5ae373fea2fc320f62d44c058522bb3758/2c1d382718595713bd8a6c8d16a11c4e.png",
      coingeckoId: "memecoin",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x42d61D766B85431666B39B89C43011f24451bFf6",
      symbol: "PSP",
      name: "ParaSwap (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x42d61d766b85431666b39b89c43011f24451bff6/833851caf6f1933eed9f7ec528effdbe.png",
      coingeckoId: "paraswap",
      listedIn: ["coingecko", "1inch", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0x42E5E06EF5b90Fe15F853F59299Fc96259209c5C",
      symbol: "KEK",
      name: "Aavegotchi KEK",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x42e5e06ef5b90fe15f853f59299fc96259209c5c/4f2a4b19f6724aa0b444551f29e933c5.png",
      coingeckoId: "aavegotchi-kek",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x4287F07CBE6954f9F0DecD91d0705C926d8d03A4",
      symbol: "TRACE",
      name: "Trace Network",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x4287f07cbe6954f9f0decd91d0705c926d8d03a4/cab30ce74ff076bb0cbf117b57a52878.png",
      coingeckoId: "trace-network-labs",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x428360b02C1269bc1c79fbC399ad31d58C1E8fdA",
      symbol: "DEFIT",
      name: "Digital Fitness",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x428360b02c1269bc1c79fbc399ad31d58c1e8fda/4e58f4997424c993788edebcf7b59464.png",
      coingeckoId: "defit",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
      symbol: "DEFI5",
      name: "DEFI Top 5 Tokens Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x42435F467D33e5C4146a4E8893976ef12BBCE762/logo.png",
      coingeckoId: null,
      listedIn: ["openocean", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x422E0Cba8e57Acc799fC14DAdf2b72DfbA281c5F",
      symbol: "FBIRD",
      name: "Firebird DAO (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x42006Ab57701251B580bDFc24778C43c9ff589A1",
      symbol: "EVO",
      name: "EVO",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135",
      coingeckoId: null,
      listedIn: ["dfyn", "RouterProtocol", "lifinance"],
    },
    {
      address: "0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E",
      symbol: "NEXO",
      name: "Nexo (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x41b3966b4ff7b427969ddf5da3627d6aeae9a48e/0fed72ccc71d9596a9c90536c8da2b95.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x40DB6d7812b8288eCA452F912ca9F262b186f278",
      symbol: "GREEN",
      name: "Greenhouse",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x40db6d7812b8288eca452f912ca9f262b186f278/e7d815156c047efe41acf327b3b7cf7b.png",
      coingeckoId: "greenhouse",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x40CCD55B789fdEE8D434915dC2Aa6Bd938506A92",
      symbol: "RAGE",
      name: "Rage",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x40ccd55b789fdee8d434915dc2aa6bd938506a92/b5b2b96c4b70f961a1dd85d81fc12d8e.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x403E967b044d4Be25170310157cB1A4Bf10bdD0f",
      symbol: "FUD",
      name: "Aavegotchi FUD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x403e967b044d4be25170310157cb1a4bf10bdd0f/9ee3711b83317d404722ab0fa5a59f03.png",
      coingeckoId: "aavegotchi-fud",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x3fB256CFefedb6A54De7465c0EE86dC574AE464D",
      symbol: "EMT",
      name: "Emanate",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3fb256cfefedb6a54de7465c0ee86dc574ae464d/ef54daafb1ddf0db9e5ff4a7c54c21d5.png",
      coingeckoId: "emanate",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3f717919deF69f81d17b80839bf8af35697ccbFa",
      symbol: "DTX",
      name: "Data Exchange Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x3f717919deF69f81d17b80839bf8af35697ccbFa/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47",
      symbol: "GAMER",
      name: "GameStation",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3f6b3595ecf70735d3f48d69b09c4e4506db3f47/12c54c730d76265645c7eab683f330d5.png",
      coingeckoId: "gamestation",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x3eCdeB8fC5023839B92b0c293D049D61069e02b1",
      symbol: "GENX",
      name: "GenX Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3ecdeb8fc5023839b92b0c293d049d61069e02b1/33a2d7f470b09fcaf71c845770f7c874.png",
      coingeckoId: "genx",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
      symbol: "AUMI",
      name: "AutoMatic Network",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3eb177a6693ec81d1e170136f8ad02fffbe172a7/695eb265219cb31acf255f8e5bd69e7c.png",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x3e121107F6F22DA4911079845a470757aF4e1A1b",
      symbol: "FXS",
      name: "Frax Share",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x3e121107F6F22DA4911079845a470757aF4e1A1b/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x3dc6052a693E4a2fc28Eb2Ea12fe0CfD3BD221D1",
      symbol: "IRIS",
      name: "IRISnet",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3dc6052a693e4a2fc28eb2ea12fe0cfd3bd221d1/9cfec1426ab055549808a92a3f167f29.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x3c5A5885F6EE4aCC2597069FE3C19F49c6EfBA96",
      symbol: "KRIDA",
      name: "Krida Fans",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3c5a5885f6ee4acc2597069fe3c19f49c6efba96/17ba632c591a33c78716d3d74d101974.png",
      coingeckoId: "krida-fans",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x3c205C8B3e02421Da82064646788c82f7bd753B9",
      symbol: "UFI",
      name: "PureFi Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3c205c8b3e02421da82064646788c82f7bd753b9/9783286b1f474c3dd154ca1664575c46.png",
      coingeckoId: "purefi",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x3a9A81d576d83FF21f26f325066054540720fC34",
      symbol: "DATA",
      name: "Streamr",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3a9a81d576d83ff21f26f325066054540720fc34/92d81b24f22a41ecae216001a0896690.png",
      coingeckoId: "streamr",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
      symbol: "FISH",
      name: "FISH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x3a3Df212b7AA91Aa0402B9035b098891d276572B/logo.png",
      coingeckoId: "polycat-finance",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x3F46a70adB395cddb81FF9bFE3B62aDae1B44816",
      symbol: "WARP",
      name: "Warp Token",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3f46a70adb395cddb81ff9bfe3b62adae1b44816/7345d0845b29254452441e849106cffa.png",
      coingeckoId: "warp-bond",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3Dc7B06dD0B1f08ef9AcBbD2564f8605b4868EEA",
      symbol: "XDO",
      name: "xDollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3dc7b06dd0b1f08ef9acbbd2564f8605b4868eea/f0e7b1fc1223183e3b1362291498e27f.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x3D3B92Fe0B4c26b74F8fF13A32dD764F4DFD8b51",
      symbol: "oKSP",
      name: "Orbit Bridge Polygon KlaySwap Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x3d3b92fe0b4c26b74f8ff13a32dd764f4dfd8b51.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x3D1D2aFd191b165D140e3E8329E634665fFB0E5e",
      symbol: "DERI",
      name: "Deri Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x3D1D2aFd191b165D140e3E8329E634665fFB0E5e/logo.png",
      coingeckoId: "deri-protocol",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x3Cef98bb43d732E2F285eE605a8158cDE967D219",
      symbol: "BAT",
      name: "Basic Attention Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3cef98bb43d732e2f285ee605a8158cde967d219/3fb78bff44c7aecb72a81e7da2c1b17e.png",
      coingeckoId: "basic-attention-token",
      listedIn: ["coingecko", "sushiswap", "rubic", "lifinance"],
    },
    {
      address: "0x3C720206bFaCB2d16fA3ac0ed87D2048Dbc401Fc",
      symbol: "UCO",
      name: "UnirisToken (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc/e7ca6756759c5a00a3a3e548b99ceb01.png",
      coingeckoId: "archethic",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x3C1BB39bb696B443a1D80BB2b3a3d950Ba9DEE87",
      symbol: "WSG",
      name: "Wall Street Games",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3c1bb39bb696b443a1d80bb2b3a3d950ba9dee87/26e2833b2e69c3974c67c08b35ba5b18.png",
      coingeckoId: "wall-street-games",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x3BfcE6D6F0d3D3f1326d86abdbe2845b4740Dc2E",
      symbol: "MATPAD",
      name: "MATICPAD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3bfce6d6f0d3d3f1326d86abdbe2845b4740dc2e/253b7de48e74ffeaef1f6b82ac2d0b7b.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x3B56a704C01D650147ADE2b8cEE594066b3F9421",
      symbol: "FYN",
      name: "Affyn",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3b56a704c01d650147ade2b8cee594066b3f9421/53722ee080838010c6b98886d2cac8cc.png",
      coingeckoId: "affyn",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3B1A0c9252ee7403093fF55b4a5886d49a3d837a",
      symbol: "UM",
      name: "Continuum",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a/234cd3c22f358f69eb49b1b666e3fcfd.png",
      coingeckoId: "continuum-world",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x3Ad707dA309f3845cd602059901E39C4dcd66473",
      symbol: "ETH2x-FLI-P",
      name: "ETH 2x Flexible Leverage Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3ad707da309f3845cd602059901e39c4dcd66473/fe22e308af61588e713c2d19a7859713.png",
      coingeckoId: "index-coop-eth-2x-flexible-leverage-index",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4",
      symbol: "stMATIC",
      name: "Staked MATIC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4/2d5ff76341cf16ae829fcf7711dedd6d.png",
      coingeckoId: "lido-staked-matic",
      listedIn: ["coingecko", "openocean", "quickswap", "lifinance"],
    },
    {
      address: "0x3A4BeD49Ab592A227bAe8F41eFd0d38E6e06d96c",
      symbol: "CARD",
      name: "Cardstack (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x3A3e7650f8B9f667dA98F236010fBf44Ee4B2975",
      symbol: "xUSD",
      name: "xDollar Stablecoin",
      decimals: 18,
      chainId: "137",
      logoURI: "https://app.fluidy.fi/logo-xusd_origin.png",
      coingeckoId: "xdollar-stablecoin",
      listedIn: ["coingecko", "1inch", "dfyn", "lifinance"],
    },
    {
      address: "0x39aB6574c289c3Ae4d88500eEc792AB5B947A5Eb",
      symbol: "DYST",
      name: "Dystopia token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x39ab6574c289c3ae4d88500eec792ab5b947a5eb/4c6b65513e67ef3c4ff80eaf8bfa0a20.png",
      coingeckoId: "dystopia",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance"],
    },
    {
      address: "0x388D819724dD6d71760A38F00dc01D310d879771",
      symbol: "JM",
      name: "JustMoney",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x388d819724dd6d71760a38f00dc01d310d879771/097c4b8e1700f6bd9e212242f8540e05.png",
      coingeckoId: "justmoney-2",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
      symbol: "GHST",
      name: "GHST Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7/logo.png",
      coingeckoId: "aavegotchi",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x38332D8671961aE13d0BDe040d536eB336495eEA",
      symbol: "mRTK",
      name: "Matic Ruletka",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/11102/small/rtk-logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x381d168DE3991c7413d46e3459b48A5221E3dfE4",
      symbol: "CUBO",
      name: "CUBO token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x381d168de3991c7413d46e3459b48a5221e3dfe4/abea4f6c3ac14437b04b9029e658550a.png",
      coingeckoId: "cubo",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F",
      symbol: "SWAP",
      name: "TrustSwap Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F/logo.png",
      coingeckoId: "trustswap",
      listedIn: ["coingecko", "quickswap", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x3801C3B3B5c98F88a9c9005966AA96aa440B9Afc",
      symbol: "GLTR",
      name: "GAX Liquidity Token Reward",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3801c3b3b5c98f88a9c9005966aa96aa440b9afc/76c03278ec5681b8e8d5cf78ec53c72e.png",
      coingeckoId: "gax-liquidity-token-reward",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x37D1EbC3Af809b8fADB45DCE7077eFc629b2B5BB",
      symbol: "PCOMB",
      name: "Pcomb",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x37D1EbC3Af809b8fADB45DCE7077eFc629b2B5BB/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x361A5a4993493cE00f61C32d4EcCA5512b82CE90",
      symbol: "SDT",
      name: "Stake DAO Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x361A5a4993493cE00f61C32d4EcCA5512b82CE90/logo.png",
      coingeckoId: "stake-dao",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x3553f861dEc0257baDA9F8Ed268bf0D74e45E89C",
      symbol: "USDT",
      name: "USDT (Wormhole)",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x3553f861dec0257bada9f8ed268bf0d74e45e89c/8d38b2fe4d7cf49ebcc94effcba9fc75.png",
      coingeckoId: "tether-usd-wormhole",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x34f380a4e3389e99C0369264453523Bbe5aF7faB",
      symbol: "KANGAL",
      name: "Kangal",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x34f380a4e3389e99c0369264453523bbe5af7fab/ef953ae1c14c180e122d6d660067ed01.png",
      coingeckoId: "kangal",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23",
      symbol: "AURUM",
      name: "Raider Aurum",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x34d4ab47bee066f361fa52d792e69ac7bd05ee23/a29393fe6a3dc1a85426fa063b7a22bc.png",
      coingeckoId: "raider-aurum",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance"],
    },
    {
      address: "0x34C1b299A74588D6Abdc1b85A53345A48428a521",
      symbol: "EZ",
      name: "EASY V2",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x34c1b299a74588d6abdc1b85a53345a48428a521/6987c99761ebf8f85f87e4c44e616832.png",
      coingeckoId: "easyfi",
      listedIn: ["coingecko", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x348e62131fce2F4e0d5ead3Fe1719Bc039B380A9",
      symbol: "PYR",
      name: "PYR Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x348e62131fce2f4e0d5ead3fe1719bc039b380a9/c16bac72715fbe4a89b879ed7637fca7.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x34667ED7C36cBBbF2d5d5c5c8d6Eb76a094EDb9F",
      symbol: "GENE",
      name: "GenomesDAO (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f/d84fd097e0389badd73414d6c2bc60fe.png",
      coingeckoId: "genomesdao",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x346404079b3792a6c548B072B9C4DDdFb92948d5",
      symbol: "IUX",
      name: "GeniuX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x346404079b3792a6c548b072b9c4dddfb92948d5/9f79471be474f9d321d90a579e3b7690.png",
      coingeckoId: "geniux",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x334D7Ae7F1D21CeB74537391558Ce57bbF3cCf73",
      symbol: "AXS",
      name: "Axie Infinity Shard (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x32Cd1BCB75473845b5d1dB6ecE60AEC6E41d8518",
      symbol: "PS1",
      name: "PolysportsToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x32cd1bcb75473845b5d1db6ece60aec6e41d8518/0fa2c3793fd3efd56648baded43b19b7.png",
      coingeckoId: "polysports",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x32934CB16DA43fd661116468c1B225Fc26CF9A8c",
      symbol: "SNE",
      name: "StrongNodeEdge Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x32934cb16da43fd661116468c1b225fc26cf9a8c/640d766318282899282b8cedff102566.png",
      coingeckoId: "strongnode",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x313d009888329C9d1cf4f75CA3f32566335bd604",
      symbol: "LEND",
      name: "EthLend Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x313d009888329C9d1cf4f75CA3f32566335bd604/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x311434160D7537be358930def317AfB606C0D737",
      symbol: "NAKA",
      name: "Nakamoto.Games",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x311434160d7537be358930def317afb606c0d737/e31d7ff21e39edd44ceece1a1c4b97ee.png",
      coingeckoId: "nakamoto-games",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x31042A4E66eDa0d12143ffc8cC1552D611dA4cbA",
      symbol: "MITx",
      name: "Morpheus Infrastructure Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x31042a4e66eda0d12143ffc8cc1552d611da4cba/541ffccc1febff84bab6ffc9d6e004fc.png",
      coingeckoId: "morpheus-labs",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x30DE46509Dbc3a491128F97be0aAf70dc7Ff33cB",
      symbol: "XZAR",
      name: "South African Tether (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x30de46509dbc3a491128f97be0aaf70dc7ff33cb.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x30A8e1c256143AD440faa9042722929B0BC0Fc7D",
      symbol: "bat",
      name: "bat token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x3068382885602FC0089aeC774944b5ad6123ae60",
      symbol: "PDSHARE",
      name: "PolyDoge Share",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x3068382885602FC0089aeC774944b5ad6123ae60/logo.png",
      coingeckoId: "pdshare",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x3066818837c5e6eD6601bd5a91B0762877A6B731",
      symbol: "UMA",
      name: "UMA Voting Token v1 (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "sushiswap", "lifinance"],
    },
    {
      address: "0x300211Def2a644b036A9bdd3e58159bb2074d388",
      symbol: "CIOTX",
      name: "Crosschain IOTX",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x300211def2a644b036a9bdd3e58159bb2074d388/b03f2cd8fd7ce6e218f58564a3819de2.png",
      coingeckoId: "crosschain-iotx",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x2fe8733dcb25BFbbA79292294347415417510067",
      symbol: "XED",
      name: "Exeedme",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2fe8733dcb25bfbba79292294347415417510067/6ee00e70a89705db9eb091af40912b3a.png",
      coingeckoId: "exeedme",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x2fD4D793c1905D82018d75e3b09d3035856890a1",
      symbol: "SPHRI",
      name: "Spherium Token",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: "spherium",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2f1b1662A895C6Ba01a99DcAf56778E7d77e5609",
      symbol: "USDS",
      name: "Spice USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2f1b1662a895c6ba01a99dcaf56778e7d77e5609/86863a4bb111157ad56dd0f4941e2a14.png",
      coingeckoId: "spiceusd",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2e2DDe47952b9c7deFDE7424d00dD2341AD927Ca",
      symbol: "CHUM",
      name: "ChumHum",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2e2dde47952b9c7defde7424d00dd2341ad927ca/570a26222ef8e6fcf3358e8a08de007a.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756",
      symbol: "TUSD",
      name: "TUSD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/3449/small/tusd.png?1618395665",
      coingeckoId: "true-usd",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "quickswap",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x2df54842cD85C60f21b4871E09bcc6047b2dcC4d",
      symbol: "IMRTL",
      name: "Immortl",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2df54842cd85c60f21b4871e09bcc6047b2dcc4d/fe0ad18caa47941e9a9edb6a91a41b82.png",
      coingeckoId: "immortl",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2db0Db271a10661e7090b6758350E18F6798a49D",
      symbol: "MOT",
      name: "Mobius Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2db0db271a10661e7090b6758350e18f6798a49d/d4de3fa1efc1baba0392fa55d4141e15.png",
      coingeckoId: "mobius-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2d72A97a31Dc920dB03330780d30074626e39C8A",
      symbol: "POLP",
      name: "PolkaParty",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2d72a97a31dc920db03330780d30074626e39c8a/da0736c19d748944bcc076161a78b573.png",
      coingeckoId: "polkaparty",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2c92a8A41f4b806a6f6F1F7C9D9DEc78DCd8c18e",
      symbol: "STZ",
      name: "99Starz",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2c92a8a41f4b806a6f6f1f7c9d9dec78dcd8c18e/b7d096480aa475d546d92447ff1577f3.png",
      coingeckoId: "99starz",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c",
      symbol: "FSN",
      name: "Fusion",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c/logo.png",
      coingeckoId: "fsn",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x2bC07124D8dAc638E290f401046Ad584546BC47b",
      symbol: "TOWER",
      name: "TOWER",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2bc07124d8dac638e290f401046ad584546bc47b/ef1f53cfc0238001c099cdc939f671e6.png",
      coingeckoId: "tower",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x2b88aD57897A8b496595925F43048301C37615Da",
      symbol: "PICKLE",
      name: "PickleToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2b88aD57897A8b496595925F43048301C37615Da/logo.png",
      coingeckoId: "pickle-finance",
      listedIn: ["coingecko", "1inch", "sushiswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x2a93172c8DCCbfBC60a39d56183B7279a2F647b4",
      symbol: "$DG",
      name: "decentral.games",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2a93172c8DCCbfBC60a39d56183B7279a2F647b4/logo.png",
      coingeckoId: "decentral-games-old",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x2a82437475A60BebD53e33997636fadE77604fc2",
      symbol: "CIRUS",
      name: "Cirus",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2a82437475a60bebd53e33997636fade77604fc2/38ed57305cc12e7a4964d135de9f4134.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x2F800Db0fdb5223b3C3f354886d907A671414A7F",
      symbol: "BCT",
      name: "Toucan Protocol: Base Carbon Tonne",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2f800db0fdb5223b3c3f354886d907a671414a7f/39e33ccc5ac7b538127accffb55d1e88.png",
      coingeckoId: "toucan-protocol-base-carbon-tonne",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590",
      symbol: "STG",
      name: "StargateToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590/55886c6280173254776780fd8340cca7.png",
      coingeckoId: "stargate-finance",
      listedIn: ["coingecko", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x2EBD50AE084e71eed419cB6C620B3BbD3927bF7e",
      symbol: "AAA",
      name: "MoonRabbit",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2ebd50ae084e71eed419cb6c620b3bbd3927bf7e/3c3d6cc2396762434b38d880ca6717e4.png",
      coingeckoId: "moon-rabbit",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x2E220744f9aC1BF3045B0588D339F5fD3bB8623A",
      symbol: "MM",
      name: "MM Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2E220744f9aC1BF3045B0588D339F5fD3bB8623A/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x2CE13E4199443FDfFF531ABb30c9B6594446bbC7",
      symbol: "RVF",
      name: "Rocket Vault",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2ce13e4199443fdfff531abb30c9b6594446bbc7/4cca51c0f827a9a057b3722cc312abc5.png",
      coingeckoId: "rocketx",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
      symbol: "AVAX",
      name: "Avalanche Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b/8da43cd8fbb77a5dd325a3c4f40e082b.png",
      coingeckoId: "avalanche-2",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7",
      symbol: "MASK",
      name: "Mask Network (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7/baddad0cec04d9361bd6b4c594ccf704.png",
      coingeckoId: "mask-network",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x2Ab4f9aC80F33071211729e45Cfc346C1f8446d5",
      symbol: "CGG",
      name: "ChainGuardians Governance Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2ab4f9ac80f33071211729e45cfc346c1f8446d5/29057aa371159fdf85f3a4905d7d2114.png",
      coingeckoId: "chain-guardians",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x29F1e986FCa02B7E54138c04C4F503DdDD250558",
      symbol: "VSQ",
      name: "VSQ",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x29f1e986fca02b7e54138c04c4f503dddd250558/9ea45f64015af3e94adb517a7ca1f64e.png",
      coingeckoId: "vesq",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x2934b36ca9A4B31E633C5BE670C8C8b28b6aA015",
      symbol: "THX",
      name: "THX Network (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015/0939518c154bd60a34da6785c93c412d.png",
      coingeckoId: "thx-network",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x28accA4ed2F6186c3D93e20e29e6e6a9Af656341",
      symbol: "ERP",
      name: "ERP",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/19633/small/erp.PNG?1635715161",
      coingeckoId: "entropyfi",
      listedIn: ["coingecko", "lifinance", "xyfinance"],
    },
    {
      address: "0x28424507fefb6f7f8E9D3860F56504E4e5f5f390",
      symbol: "amWETH",
      name: "Aave Matic Market WETH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x28424507fefb6f7f8E9D3860F56504E4e5f5f390/logo.png",
      coingeckoId: "aave-polygon-weth",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x282d8efCe846A88B159800bd4130ad77443Fa1A1",
      symbol: "mOCEAN",
      name: "Ocean Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x282d8efCe846A88B159800bd4130ad77443Fa1A1/logo.png",
      coingeckoId: null,
      listedIn: ["1inch", "quickswap", "lifinance", "elkfinance"],
    },
    {
      address: "0x2808edb7398b25E0f6C41cD98fD114d924008C87",
      symbol: "FLUX",
      name: "FLUX (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x27F8D03b3a2196956ED754baDc28D73be8830A6e",
      symbol: "amDAI",
      name: "Aave Matic Market DAI",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x27F8D03b3a2196956ED754baDc28D73be8830A6e/logo.png",
      coingeckoId: "aave-polygon-dai",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      symbol: "USDC",
      name: "USD Coin",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/logo.png",
      coingeckoId: "usd-coin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B",
      symbol: "CTSI",
      name: "Cartesi Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B/logo.png",
      coingeckoId: "cartesi",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x26d326B1fc702260baeB62334d7c1Da6f1a2C386",
      symbol: "GTPS",
      name: "Gtps.Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x26d326B1fc702260baeB62334d7c1Da6f1a2C386/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x268Ad27C28601D28b79c792C14e95BD2b7A030F8",
      symbol: "JULIEN",
      name: "Julien",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x268Ad27C28601D28b79c792C14e95BD2b7A030F8/logo.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x263026E7e53DBFDce5ae55Ade22493f828922965",
      symbol: "RIC",
      name: "Ricochet",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x263026e7e53dbfdce5ae55ade22493f828922965/322aa7d2d694f9455b0207d4f806f379.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x2628D301b161DB70E3dBbAc88d9D900cA426fF02",
      symbol: "bBADGER",
      name: "Badger Sett Badger",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x2628D301b161DB70E3dBbAc88d9D900cA426fF02/logo.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x2628568509E87c4429fBb5c664Ed11391BE1BD29",
      symbol: "renDGB",
      name: "renDGB",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x2628568509E87c4429fBb5c664Ed11391BE1BD29/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x25788a1a171ec66Da6502f9975a15B609fF54CF6",
      symbol: "POOL",
      name: "PoolTogether",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x25788a1a171ec66da6502f9975a15b609ff54cf6/08ee9e620a08515d854673b13bdecb07.png",
      coingeckoId: "pooltogether",
      listedIn: ["coingecko", "sushiswap", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x255707B70BF90aa112006E1b07B9AeA6De021424",
      symbol: "TETU",
      name: "TETU Reward Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x255707b70bf90aa112006e1b07b9aea6de021424/fec6ade5dff80728a4eeeb56a79d9634.png",
      coingeckoId: "tetu",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x24834BBEc7E39ef42f4a75EAF8E5B6486d3F0e57",
      symbol: "LUNA",
      name: "Wrapped LUNA Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x24834bbec7e39ef42f4a75eaf8e5b6486d3f0e57/b33049dfcb0a2382ffb36284f4c29814.png",
      coingeckoId: "wrapped-terra",
      listedIn: ["coingecko", "1inch", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x23fE1Ee2f536427B7e8aC02FB037A7f867037Fe8",
      symbol: "TORN",
      name: "Tornado Cash",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x23fE1Ee2f536427B7e8aC02FB037A7f867037Fe8/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5",
      symbol: "CDS",
      name: "Crypto Development Services",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/21304/large/JhUZ3Rk.png",
      coingeckoId: "crypto-development-services",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x23c70Dd93d4ecaC7fb93631488C5412E02f4A57c",
      symbol: "REIN",
      name: "Reyna Ventures",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x23c70dd93d4ecac7fb93631488c5412e02f4a57c/6a7936ecd748013c5c1e1473bdaf84e5.png",
      coingeckoId: "rein",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x23E8B6A3f6891254988B84Da3738D2bfe5E703b9",
      symbol: "WELT",
      name: "FABWELT",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x23e8b6a3f6891254988b84da3738d2bfe5e703b9/d1116912c6ecba52a44385374862fb1d.png",
      coingeckoId: "fabwelt",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C",
      symbol: "HEX",
      name: "HEX",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C/logo.png",
      coingeckoId: "hex",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x236eeC6359fb44CCe8f97E99387aa7F8cd5cdE1f",
      symbol: "USD+",
      name: "USD+",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f/58363c9095ed5998f48688b3c52c78c7.png",
      coingeckoId: "usd",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x235737dBb56e8517391473f7c964DB31fA6ef280",
      symbol: "KASTA",
      name: "KastaToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x235737dbb56e8517391473f7c964db31fa6ef280/99e17ec515efcfcf9434e564a89f4e66.png",
      coingeckoId: "kasta",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x232eaB56c4fB3f84c6Fb0a50c087c74b7B43c6Ad",
      symbol: "ZUZ",
      name: "Zeus",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x232eaB56c4fB3f84c6Fb0a50c087c74b7B43c6Ad/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x22e3f02f86Bc8eA0D73718A2AE8851854e62adc5",
      symbol: "FLAME",
      name: "FireStarter",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x22e3f02f86bc8ea0d73718a2ae8851854e62adc5/ba55aa683803086e509874c995b38323.png",
      coingeckoId: "firestarter",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x22a31bD4cB694433B6de19e0aCC2899E553e9481",
      symbol: "MMF",
      name: "Mad Meerkat Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x22a31bd4cb694433b6de19e0acc2899e553e9481/bf202e0c7ff79120dbc70c983358e57e.png",
      coingeckoId: "mmfinance-polygon",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x229b1b6C23ff8953D663C4cBB519717e323a0a84",
      symbol: "BLOK",
      name: "BLOK",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x229b1b6c23ff8953d663c4cbb519717e323a0a84/551e02193001e1ac8112e0f53649a415.png",
      coingeckoId: "bloktopia",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x228b5C21ac00155cf62c57bcc704c0dA8187950b",
      symbol: "NXD",
      name: "Nexus Dubai",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x228b5c21ac00155cf62c57bcc704c0da8187950b/0d9c06606d9d22a06147a9bdd8d30083.png",
      coingeckoId: "nexus-dubai",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x221743dc9E954bE4f86844649Bf19B43D6F8366d",
      symbol: "OBOT",
      name: "OBORTECH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x221743dc9e954be4f86844649bf19b43d6f8366d/ed4dace7d1dfbb6f84e0568d43ffd689.png",
      coingeckoId: "obortech",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x220ED61d1f1CC754cB71978585D69e07Be576315",
      symbol: "LADZ",
      name: "LADZ",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x220ED61d1f1CC754cB71978585D69e07Be576315/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x211F4e76fcB811ed2B310A232a24B3445d95E3bC",
      symbol: "MATRIX",
      name: "Matrixswap",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x211f4e76fcb811ed2b310a232a24b3445d95e3bc/5e06e7557699784cbd9915c5ca09aa8b.png",
      coingeckoId: "matrixswap",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142",
      symbol: "maWETH",
      name: "Matic Aave interest bearing WETH",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142/logo.png",
      coingeckoId: "matic-aave-weth",
      listedIn: ["coingecko", "lifinance", "elkfinance"],
    },
    {
      address: "0x2052344CeAce7f140da3E819c7729fEF72F97851",
      symbol: "1MT",
      name: "1Million Token (PoS)",
      decimals: 7,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x1eBA4B44C4F8cc2695347C6a78F0B7a002d26413",
      symbol: "UND",
      name: "Unbound Dollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1eba4b44c4f8cc2695347c6a78f0b7a002d26413/d56e441c31e5df1b76bca226053187c5.png",
      coingeckoId: null,
      listedIn: ["quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
      symbol: "deUSDC",
      name: "deBridge USD Coin",
      decimals: 6,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["1inch", "openocean", "lifinance"],
    },
    {
      address: "0x1d734A02eF1e1f5886e66b0673b71Af5B53ffA94",
      symbol: "SD",
      name: "Stader",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1d734a02ef1e1f5886e66b0673b71af5b53ffa94/e859ba9852bbe4aa65f62fa12bb55201.png",
      coingeckoId: "stader",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
      symbol: "AMAAVE",
      name: "Aave Matic Market AAVE",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360/logo.png",
      coingeckoId: "aave-polygon-aave",
      listedIn: ["coingecko", "1inch", "openocean", "lifinance", "elkfinance"],
    },
    {
      address: "0x1a3acf6D19267E2d3e7f898f42803e90C9219062",
      symbol: "FXS",
      name: "FXS",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/13423/small/frax_share.png?1608478989",
      coingeckoId: "frax-share",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x1a13F4Ca1d028320A707D99520AbFefca3998b7F",
      symbol: "amUSDC",
      name: "Aave Matic Market USDC",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x1a13F4Ca1d028320A707D99520AbFefca3998b7F/logo.png",
      coingeckoId: "aave-polygon-usdc",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x1FcbE5937B0cc2adf69772D228fA4205aCF4D9b2",
      symbol: "BADGER",
      name: "Badger (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1fcbe5937b0cc2adf69772d228fa4205acf4d9b2/d64a4f518273102c3dc98a502f5d09dc.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x1FA2F83BA2DF61c3d370071d61B17Be01e224f3a",
      symbol: "HNY",
      name: "HONEY",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1fa2f83ba2df61c3d370071d61b17be01e224f3a/a7ca216337625ea20eafdff44d2d8e31.png",
      coingeckoId: "hive-investments-honey",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1E42EDbe5376e717C1B22904C59e406426E8173F",
      symbol: "SURF",
      name: "SURF",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x1E42EDbe5376e717C1B22904C59e406426E8173F/logo.png",
      coingeckoId: "surf-finance",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x1D607Faa0A51518a7728580C238d912747e71F7a",
      symbol: "DATA",
      name: "DATA Economy Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1d607faa0a51518a7728580c238d912747e71f7a/22fa380a833d8259f63716e627449a0f.png",
      coingeckoId: "data-economy-index",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x1D545D7ff917e08D7Bf26320343b16BdF51FdBb6",
      symbol: "CELL",
      name: "Cellframe Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C",
      symbol: "KNC",
      name: "Kyber Network Crystal v2 (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c/1d25e188deb06e642ea6f4f4f8eb0a0c.png",
      coingeckoId: "kyber-network-crystal",
      listedIn: ["coingecko", "1inch", "sushiswap", "openocean", "lifinance"],
    },
    {
      address: "0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b",
      symbol: "DRAX",
      name: "Drax",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1ba3510a9ceeb72e5cdba8bcdde9647e1f20fb4b/955a5084de5b394a674fd37454e4811d.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      symbol: "WBTC",
      name: "Wrapped BTC",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/logo.png",
      coingeckoId: "wrapped-bitcoin",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x1BA17C639BdaeCd8DC4AAc37df062d17ee43a1b8",
      symbol: "WIXS",
      name: "Wrapped Ixs Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8/4420cb8e241eba6cddf9928849f770a8.png",
      coingeckoId: "ix-swap",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603",
      symbol: "WOO",
      name: "WOO Network",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603/c68741e50aeee41d87b4f0cf50df859d.png",
      coingeckoId: "woo-network",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "quickswap",
        "lifinance",
      ],
    },
    {
      address: "0x1B43b97094Aa3c6Cc678eDb9e28Ac67dAaa7Cc64",
      symbol: "LICP",
      name: "Liquid ICP",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1b43b97094aa3c6cc678edb9e28ac67daaa7cc64/acfd84b28d1600508b110b849dfd6ebe.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x1B2a3C1417C69DA16bb9193268d0AAbFe54b3b39",
      symbol: "ROSN",
      name: "Roseon token",
      decimals: 18,
      chainId: "137",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/9783.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x1A47E3316282d57DF146F87c58A1FB4b26992Bbf",
      symbol: "BMI",
      name: "Bridge Mutual (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x197E5d6CcfF265AC3E303a34Db360ee1429f5d1A",
      symbol: "jSEK",
      name: "Jarvis Synthetic Swedish Krona",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x197e5d6ccff265ac3e303a34db360ee1429f5d1a/80750a30b954db616e7bebfd9d8ff60f.png",
      coingeckoId: "jarvis-synthetic-swedish-krona",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x19782D3Dc4701cEeeDcD90f0993f0A9126ed89d0",
      symbol: "REN",
      name: "REN (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x187Ae45f2D361CbCE37c6A8622119c91148F261b",
      symbol: "POLX",
      name: "Polylastic",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x187ae45f2d361cbce37c6a8622119c91148f261b/3c2fc16172667070a2543297b711018f.png",
      coingeckoId: "polylastic",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x175Bdc4e52EECB675B86FC4C9A3Ea3f80adBb610",
      symbol: "HXN",
      name: "Havens Nook (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x175bdc4e52eecb675b86fc4c9a3ea3f80adbb610/ef5a0a11e1db4125b24edf83c199484b.png",
      coingeckoId: "havens-nook",
      listedIn: ["coingecko", "rubic", "lifinance"],
    },
    {
      address: "0x172370d5Cd63279eFa6d502DAB29171933a610AF",
      symbol: "CRV",
      name: "CRV",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/12124/small/Curve.png?1597369484",
      coingeckoId: "curve-dao-token",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
      ],
    },
    {
      address: "0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4",
      symbol: "ROUTE (PoS)",
      name: "Route",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4/58c3023b574e336308d3c988e367ad22.png",
      coingeckoId: "route",
      listedIn: ["coingecko", "1inch", "sushiswap", "dfyn", "rubic", "lifinance"],
    },
    {
      address: "0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd",
      symbol: "SMBR",
      name: "SOMBRA",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd/f3e98be73d37a4b308160a2c3a84f9aa.png",
      coingeckoId: "sombra-network",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1683767B3e3a40a61D9faa94CF86E5788F0a834a",
      symbol: "BRND",
      name: "Brand Tokens (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x1659fFb2d40DfB1671Ac226A0D9Dcc95A774521A",
      symbol: "DLYCOP",
      name: "Daily COP",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1659ffb2d40dfb1671ac226a0d9dcc95a774521a/8a4871757290653b6f8e3a2cc438953d.png",
      coingeckoId: "daily-cop",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x1646C835d70F76D9030DF6BaAeec8f65c250353d",
      symbol: "HBAR",
      name: "HBAR",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/3688/small/mqTDGK7Q.png?1566256777",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x1631244689EC1fEcbDD22fb5916E920dFC9b8D30",
      symbol: "OVR",
      name: "OVR (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1631244689ec1fecbdd22fb5916e920dfc9b8d30/84a37db9f8cdb97262e490dfd916eecc.png",
      coingeckoId: "ovr",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05",
      symbol: "GEL",
      name: "Gelato Network Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05/ef7fe2b990fe14e5bb3ea2c6db902f57.png",
      coingeckoId: "gelato",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1599fE55Cda767b1F631ee7D414b41F5d6dE393d",
      symbol: "MILK",
      name: "Milk",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1599fe55cda767b1f631ee7d414b41f5d6de393d/90af6c99b9bd7e8bcaf24029eda2c80e.png",
      coingeckoId: "milk",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x14Af1F2f02DCcB1e43402339099A05a5E363b83c",
      symbol: "KROM",
      name: "Kromatika",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x14af1f2f02dccb1e43402339099a05a5e363b83c/eeaad610e3d4b724fa8ba49b17283802.png",
      coingeckoId: "kromatika",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9",
      symbol: "vBTC",
      name: "Strudel BTC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x146e58D34EaB0bFf7e0a63cfe9332908d680c667",
      symbol: "PDDOLLAR",
      name: "PolyDoge Dollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x146e58D34EaB0bFf7e0a63cfe9332908d680c667/logo.png",
      coingeckoId: "pddollar",
      listedIn: ["coingecko", "1inch", "lifinance", "elkfinance"],
    },
    {
      address: "0x137Ee749f0F8c2eD34cA00dE33BB59E3dafA494A",
      symbol: "wCCX",
      name: "WrappedConceal",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x137Ee749f0F8c2eD34cA00dE33BB59E3dafA494A/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x1379E8886A944d2D9d440b3d88DF536Aea08d9F3",
      symbol: "MYST",
      name: "Mysterium (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x1379e8886a944d2d9d440b3d88df536aea08d9f3/a5888a02086ca3db8ce214d756e9d807.png",
      coingeckoId: "mysterium",
      listedIn: ["coingecko", "1inch", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x13748d548D95D78a3c83fe3F32604B4796CFfa23",
      symbol: "KOGECOIN",
      name: "kogecoin.io",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x13748d548d95d78a3c83fe3f32604b4796cffa23/baf8e09eaa3102a87b40f296f8ce8ce0.png",
      coingeckoId: "kogecoin",
      listedIn: ["coingecko", "1inch", "quickswap", "lifinance"],
    },
    {
      address: "0x12c9FFE6538f20A982FD4D17912f0ca00fA82D30",
      symbol: "oORC",
      name: "Orbit Bridge Polygon Orbit Chain",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x12c9ffe6538f20a982fd4d17912f0ca00fa82d30.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x12a34A6759c871C4C1E8A0A42CFc97e4D7Aaf68d",
      symbol: "TUT",
      name: "Tutellus Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x12a34a6759c871c4c1e8a0a42cfc97e4d7aaf68d/11567623821f66e7eeb76d7f6b458bcf.png",
      coingeckoId: "tutellus",
      listedIn: ["coingecko", "sushiswap", "lifinance"],
    },
    {
      address: "0x127984b5E6d5c59f81DACc9F1C8b3Bdc8494572e",
      symbol: "PPDEX",
      name: "Pepedex",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x127984b5E6d5c59f81DACc9F1C8b3Bdc8494572e/logo.png",
      coingeckoId: "pepedex",
      listedIn: ["coingecko", "1inch", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x123706cDD8e60324e610E9A2CC7012D0F45A5b8E",
      symbol: "QUIDD",
      name: "QUIDD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x123706cdd8e60324e610e9a2cc7012d0f45a5b8e/12a669d9b0709e455f0e38300fec4f70.png",
      coingeckoId: "quidd",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x12365293cb6477d4fc2686e46BB97E3Fb64f1550",
      symbol: "CIFI",
      name: "citizen finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x12365293cb6477d4fc2686e46bb97e3fb64f1550/e3e8d3bcfacfa66d568cf644ffa51277.png",
      coingeckoId: "citizen-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x12255b2DaDC7E18Fd34353DAa161974cC917C8dd",
      symbol: "DIGG",
      name: "Digg (PoS)",
      decimals: 9,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9",
      symbol: "AXMATIC",
      name: "axMatic",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x11a819Beb0AA3327E39f52F90d65Cc9bCA499F33",
      symbol: "SCA",
      name: "ScaleSwapToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x11a819beb0aa3327e39f52f90d65cc9bca499f33/1971261459e7f9fc0ffc012f43ae280e.png",
      coingeckoId: "scaleswap-token",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x11CD37bb86F65419713f30673A480EA33c826872",
      symbol: "WETH",
      name: "Wrapped Ether (Wormhole)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x11cd37bb86f65419713f30673a480ea33c826872/50343a575390e309187db84f5ad13aee.png",
      coingeckoId: "ethereum-wormhole",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x104592a158490a9228070E0A8e5343B499e125D0",
      symbol: "FRAX",
      name: "Frax",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x104592a158490a9228070E0A8e5343B499e125D0/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "dfyn", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
      symbol: "ANKR",
      name: "Ankr (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: "https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png",
      coingeckoId: "ankr",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x0f40D69Ff494a9e74b1F20A89EfF299B2A05918F",
      symbol: "BNTY",
      name: "Bounty0x Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0e98C977B943f06075b2D795794238fBfB9b9a34",
      symbol: "TOMB",
      name: "TOMB",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0e98c977b943f06075b2d795794238fbfb9b9a34/e78a2e1ce80312cf0c39d6ff77e22891.png",
      coingeckoId: "tomb",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x0e59D50adD2d90f5111aca875baE0a72D95B4762",
      symbol: "DB",
      name: "Dark.Build",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x0e59D50adD2d90f5111aca875baE0a72D95B4762/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x0df0f72EE0e5c9B7ca761ECec42754992B2Da5BF",
      symbol: "ATA",
      name: "Automata (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0df0f72ee0e5c9b7ca761ecec42754992b2da5bf/0e28b779e61c729984f4e62ed1eaeda5.png",
      coingeckoId: "automata",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0d962A1A2A27B402e4D84772dea65Ac8592Eb6bF",
      symbol: "GMS",
      name: "Gemstones",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0d962a1a2a27b402e4d84772dea65ac8592eb6bf/cdfc50cd93d13b98f3acaed15b378eda.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      symbol: "WMATIC",
      name: "Wrapped Matic",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png",
      coingeckoId: "wmatic",
      listedIn: [
        "coingecko",
        "1inch",
        "uniswap",
        "sushiswap",
        "openocean",
        "quickswap",
        "dfyn",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0d0B8488222F7f83B23E365320a4021b12eAD608",
      symbol: "NXTT",
      name: "NextEarthToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0d0b8488222f7f83b23e365320a4021b12ead608/2cb30f9b653fb3e5520b516e99112f16.png",
      coingeckoId: "next-earth",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x0c8C8Ae8bc3a69dC8482C01CEacfB588bb516B01",
      symbol: "AURORA",
      name: "AURORA",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0c8c8ae8bc3a69dc8482c01ceacfb588bb516b01/957da4c08106fbb902f98b4207e510a3.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x0c5EBeD5cB5BD838BDc43E1583D9d054f3d7Be6c",
      symbol: "VGTG",
      name: "VGTGToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x0c5EBeD5cB5BD838BDc43E1583D9d054f3d7Be6c/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x0bD820aD2d7Ab7305b5C9538ba824C9b9bEb0561",
      symbol: "ROYA",
      name: "Royale",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0bd820ad2d7ab7305b5c9538ba824c9b9beb0561/a8a49ec7bf0ac5d857363be72d0a7aab.png",
      coingeckoId: "royale",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41",
      symbol: "PYM",
      name: "Playermon",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0bd49815ea8e2682220bcb41524c0dd10ba71d41/7fc96c29481fcaf7e87a35ca62e9b3ed.png",
      coingeckoId: "playermon",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0b68782eFF3177f1F9240B64A7e2F8E0497e2454",
      symbol: "ATLX",
      name: "Atlantis",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0b68782eff3177f1f9240b64a7e2f8e0497e2454/1f6b3eb33463c08f431f97e252001808.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a",
      symbol: "WSUSHI",
      name: "WRapped SushiToken",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a/logo.png",
      coingeckoId: "sushi",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "rubic",
        "lifinance",
        "xyfinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
      symbol: "aMATICc",
      name: "Ankr MATIC Reward Bearing Certificate",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1653739525363_5982120772421002.png",
      coingeckoId: "ankr-reward-earning-matic",
      listedIn: ["coingecko", "openocean", "lifinance"],
    },
    {
      address: "0x0E50BEA95Fe001A370A4F1C220C49AEdCB982DeC",
      symbol: "ERN",
      name: "Ethernity Chain",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/14238/small/ethernity_logo.png?1615189750",
      coingeckoId: null,
      listedIn: ["quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x0D98EAe620491d8f6836a39Ac45E54b286FDd2d7",
      symbol: "SPROCKET",
      name: "Sprocket",
      decimals: 18,
      chainId: "137",
      logoURI: "https://i.ibb.co/6th7s4m/sprocket-symbol-200x200-col-trans.png",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x0D6ae2a429df13e44A07Cd2969E085e4833f64A0",
      symbol: "PBR",
      name: "PolkaBridge",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0d6ae2a429df13e44a07cd2969e085e4833f64a0/a6c988630ff31d379f7c231514323609.png",
      coingeckoId: "polkabridge",
      listedIn: ["coingecko", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x0CfC9a713A5C17Bc8a5fF0379467f6558bAcD0e0",
      symbol: "GLQ",
      name: "GraphLinq (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0cfc9a713a5c17bc8a5ff0379467f6558bacd0e0/93ceea3b80e0145240e69f54eb18204b.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0C9c7712C83B3C70e7c5E11100D33D9401BdF9dd",
      symbol: "WOMBAT",
      name: "Wombat",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x0C51f415cF478f8D08c246a6C6Ee180C5dC3A012",
      symbol: "HOT",
      name: "HOLO (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0B91B07bEb67333225A5bA0259D55AeE10E3A578",
      symbol: "MNEP",
      name: "Minereum Polygon",
      decimals: 8,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0b91b07beb67333225a5ba0259d55aee10e3a578/58c5f0b2d5cb2c4c985c8caea8b5a231.png",
      coingeckoId: "minereum",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0B6afe834dab840335F87d99b45C2a4bd81A93c7",
      symbol: "ANGEL",
      name: "Angel (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0b6afe834dab840335f87d99b45c2a4bd81a93c7/17d565cc55f5e5a88c9b72b1a0b35f8b.png",
      coingeckoId: "polylauncher",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0B048D6e01a6b9002C291060bF2179938fd8264c",
      symbol: "ALPHA",
      name: "PolyAlpha Finance",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0b048d6e01a6b9002c291060bf2179938fd8264c/c3ef7925884169266c3d80ce80374c97.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x0A5926027d407222F8fe20f24cB16e103f617046",
      symbol: "NFD",
      name: "Feisty Doge NFT (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x0a5926027d407222f8fe20f24cb16e103f617046.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      symbol: "iUSD",
      name: "iZUMi Bond USD",
      decimals: 18,
      chainId: "137",
      logoURI: "https://get.celer.app/cbridge-icons/iUSD.png",
      coingeckoId: "izumi-bond-usd",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0A307BD521701F9D70FB29BfA9E2e36DC998daDB",
      symbol: "CNW",
      name: "CoinWealth",
      decimals: 6,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0a307bd521701f9d70fb29bfa9e2e36dc998dadb/172023a4f34344dd0f5caab6374054cb.png",
      coingeckoId: "coinwealth",
      listedIn: ["coingecko", "dfyn", "lifinance"],
    },
    {
      address: "0x0A02D33031917d836Bd7Af02F9f7F6c74d67805F",
      symbol: "oKLAY",
      name: "Orbit Bridge Polygon Klay",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x0a02d33031917d836bd7af02f9f7f6c74d67805f.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x09C5a4BCA808bD1ba2b8E6B3aAF7442046B4ca5B",
      symbol: "VSP",
      name: "VesperToken (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x09c5a4bca808bd1ba2b8e6b3aaf7442046b4ca5b/3050418a803e9a188324a853e214b648.png",
      coingeckoId: "vesper-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2",
      symbol: "WATCH",
      name: "yieldwatch",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x09211dc67f9fe98fb7bbb91be0ef05f4a12fa2b2/0b4ef9afb6eb88061ccd49aa333d0c90.png",
      coingeckoId: "yieldwatch",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x08BE454de533509e8832B257116C5506E55b0b64",
      symbol: "STND",
      name: "Standard",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x08be454de533509e8832b257116c5506e55b0b64/d9e77db5a635b321a5d9fc51e2a63f53.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x083c56d87eAD73D6231C165Ec450C6E28f3399C9",
      symbol: "CHART",
      name: "ChartEx",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/12523/small/chartex.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4",
      symbol: "ELET",
      name: "Elementeum",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4/logo.png",
      coingeckoId: "ether-legends",
      listedIn: ["coingecko", "rubic", "lifinance", "elkfinance"],
    },
    {
      address: "0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C",
      symbol: "GFC",
      name: "GFC",
      decimals: 18,
      chainId: "137",
      logoURI: "https://polygonscan.com/token/images/galaxyfightclub_32.png",
      coingeckoId: "galaxy-fight-club",
      listedIn: ["coingecko", "1inch", "lifinance", "xyfinance"],
    },
    {
      address: "0x06ebc9c542357e2129D16717CD02c02FBC835d33",
      symbol: "CAGE",
      name: "Coinage",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/floshintoken/CoinageFinance/main/Coinage_v2_200x200_white.png",
      coingeckoId: "coinage-finance",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x0621d647cecbFb64b79E44302c1933cB4f27054d",
      symbol: "AMP",
      name: "Amp Token (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["uniswap", "rubic", "lifinance"],
    },
    {
      address: "0x057E0bd9B797f9Eeeb8307B35DbC8c12E534c41E",
      symbol: "GURU",
      name: "Guru",
      decimals: 9,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x057e0bd9b797f9eeeb8307b35dbc8c12e534c41e/3530dcf3c251d7cbcc4ddc7e4ab14ee4.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x0527c8C43250279D6Eb74dA1078193f5316fc9a0",
      symbol: "PYD",
      name: "PolyQuity Dollar",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0527c8c43250279d6eb74da1078193f5316fc9a0/4f927cf8efd2de8ee72528f878f01836.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
      symbol: "KRILL",
      name: "Krill",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b/logo.png",
      coingeckoId: "polywhale",
      listedIn: [
        "coingecko",
        "1inch",
        "sushiswap",
        "openocean",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x04f3C4cF2e806da6DF31e80E8A5D121F98eDD61d",
      symbol: "CREAM",
      name: "Cream",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x04f3C4cF2e806da6DF31e80E8A5D121F98eDD61d/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x04b33078Ea1aEf29bf3fB29c6aB7B200C58ea126",
      symbol: "SAFLE",
      name: "Safle",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126/aa0c94f9dd818b1bc03b6b6a09e30d1e.png",
      coingeckoId: "safle",
      listedIn: ["coingecko", "1inch", "quickswap", "dfyn", "lifinance"],
    },
    {
      address: "0x047fD3B3D2366F9babe105ade4598E263d6c699c",
      symbol: "CGS",
      name: "Cougar Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x047fd3b3d2366f9babe105ade4598e263d6c699c/014e7b303e135eb53667f4cd9e78751e.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x04429fbb948BBD09327763214b45e505A5293346",
      symbol: "ABR",
      name: "Allbridge",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x04429fbb948bbd09327763214b45e505a5293346/48f505b0f8bb2e119e9a6c5b6c508a93.png",
      coingeckoId: "allbridge",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x043A3Aa319B563aC25D4E342d32bFfb51298DB7b",
      symbol: "SWG",
      name: "Swirge",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x043A3Aa319B563aC25D4E342d32bFfb51298DB7b/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0x03CBA0CfF3BF727711eaE7eF11D152dCe3163901",
      symbol: "PRNTR",
      name: "PRNTR",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://tokens.1inch.io/0x03cba0cff3bf727711eae7ef11d152dce3163901.png",
      coingeckoId: null,
      listedIn: ["1inch", "lifinance"],
    },
    {
      address: "0x03A97594aA5ecE130E2E956fc0cEd2fea8ED8989",
      symbol: "aMATICb",
      name: "Ankr Reward Earning Staked MATIC",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://cloudstorage.openocean.finance/images/1650031618318_8256145980879306.jpg",
      coingeckoId: null,
      listedIn: ["openocean", "lifinance"],
    },
    {
      address: "0x03943C3ef00d92e130185CeBC0bcc435Def2cC94",
      symbol: "FM",
      name: "Follow Me",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0361BdEAB89DF6BBcc52c43589FABba5143d19dD",
      symbol: "dTOP",
      name: "dHEDGE Top Index",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0361BdEAB89DF6BBcc52c43589FABba5143d19dD/logo.png",
      coingeckoId: null,
      listedIn: ["sushiswap", "lifinance"],
    },
    {
      address: "0x034b2090b579228482520c589dbD397c53Fc51cC",
      symbol: "VISION",
      name: "Vision Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x034b2090b579228482520c589dbD397c53Fc51cC/logo.png",
      coingeckoId: "apy-vision",
      listedIn: [
        "coingecko",
        "1inch",
        "quickswap",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
      symbol: "AGA",
      name: "AGA Token",
      decimals: 4,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x033d942A6b495C4071083f4CDe1f17e986FE856c/logo.png",
      coingeckoId: "aga-token",
      listedIn: [
        "coingecko",
        "1inch",
        "openocean",
        "dfyn",
        "rubic",
        "lifinance",
        "elkfinance",
      ],
    },
    {
      address: "0x0335DeFC8A1977b99488e0b5f5c6bc3D44fAcdD4",
      symbol: "ATTR",
      name: "Attrace",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://raw.githubusercontent.com/attrace/assets/main/images/attrace_logo_v2_256.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance"],
    },
    {
      address: "0x032b3217241fe53fFaDB334a9d06aACd2A9153D9",
      symbol: "BND",
      name: "DokiDoki.Chainbinders",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x032b3217241fe53fFaDB334a9d06aACd2A9153D9/logo.png",
      coingeckoId: null,
      listedIn: ["lifinance", "elkfinance"],
    },
    {
      address: "0x032F85b8FbF8540a92B986d953e4C3A61C76d39E",
      symbol: "TCP",
      name: "The Crypto Prophecies",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://assets.coingecko.com/coins/images/15054/small/tcp.PNG?1619579195",
      coingeckoId: null,
      listedIn: ["quickswap", "lifinance"],
    },
    {
      address: "0x03247a4368A280bEc8133300cD930A3a61d604f6",
      symbol: "RBAL",
      name: "Rebalance Token",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://elk.finance/tokens/logos/matic/0x03247a4368A280bEc8133300cD930A3a61d604f6/logo.png",
      coingeckoId: null,
      listedIn: ["dfyn", "lifinance", "elkfinance"],
    },
    {
      address: "0x029C2bf9E5e7bf11328f045205308244e11Efc46",
      symbol: "POLR",
      name: "POLYSTARTER",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x029c2bf9e5e7bf11328f045205308244e11efc46/fdcecda917049e67c6d08f2d33357215.png",
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x02649C1Ff4296038De4b9bA8F491b42b940A8252",
      symbol: "XGEM",
      name: "Exchange Genesis Ethlas Medium",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x02649c1ff4296038de4b9ba8f491b42b940a8252/6602fab0528ba9808dff7b05a2d05e41.png",
      coingeckoId: "exchange-genesis-ethlas-medium",
      listedIn: ["coingecko", "1inch", "sushiswap", "quickswap", "lifinance"],
    },
    {
      address: "0x01fA5b3A5d77BcF705DD505bBcBb34bce310E7FE",
      symbol: "AXI",
      name: "Axioms (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
    {
      address: "0x0169eC1f8f639B32Eec6D923e24C2A2ff45B9DD6",
      symbol: "ALGB",
      name: "Algebra",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x0169ec1f8f639b32eec6d923e24c2a2ff45b9dd6/1e544877756a92d20b2fa616893f507b.png",
      coingeckoId: "algebra",
      listedIn: ["coingecko", "sushiswap", "quickswap", "rubic", "lifinance"],
    },
    {
      address: "0x013F9c3fAc3e2759d7e90AcA4F9540F75194A0d7",
      symbol: "USDN",
      name: "Neutrino USD",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x013f9c3fac3e2759d7e90aca4f9540f75194a0d7/8dd28b5a12a58a030bbd8e883a643e9a.png",
      coingeckoId: "neutrino",
      listedIn: ["coingecko", "lifinance"],
    },
    {
      address: "0x011734f6Ed20E8D011d85Cf7894814B897420acf",
      symbol: "ACRE",
      name: "Arable Protocol",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x011734f6ed20e8d011d85cf7894814b897420acf/188fc2e53e27e47710ee4f9a9c6a9fa5.png",
      coingeckoId: "arable-protocol",
      listedIn: ["coingecko", "quickswap", "lifinance"],
    },
    {
      address: "0x00e5646f60AC6Fb446f621d146B6E1886f002905",
      symbol: "RAI",
      name: "Rai Reflex Index (PoS)",
      decimals: 18,
      chainId: "137",
      logoURI:
        "https://static.debank.com/image/matic_token/logo_url/0x00e5646f60ac6fb446f621d146b6e1886f002905/57a7ea8eca0147dccd9c23577ff62a8a.png",
      coingeckoId: "rai",
      listedIn: ["coingecko", "1inch", "lifinance"],
    },
    {
      address: "0x000DE668684839F97d4845f32a43E913366ec08C",
      symbol: "BOND",
      name: "BOND (PoS)",
      decimals: 8,
      chainId: "137",
      logoURI: null,
      coingeckoId: null,
      listedIn: ["rubic", "lifinance"],
    },
  ];