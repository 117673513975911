import axios from "axios";

export const handleArbitSwap = async (
  actualKeyword,
  fromTokenAddress,
  toTokenAddress,
  fromTokenDecimals,
  fromTokenAmount,
  chainId,
  oonetwork,
  zeroxnetwork,
  slippage,
  usersAddress,
  toTokenDecimals,
  goodGas
) => {
  let returnData;
  try {
    if (String(actualKeyword) === "paraswap") {
      try {
        returnData = await axios.get(
          `https://apiv5.paraswap.io/prices/?srcToken=${fromTokenAddress}&destToken=${toTokenAddress}&srcDecimals=${fromTokenDecimals}&destDecimals=${toTokenDecimals}&amount=${
            fromTokenAmount * 10 ** fromTokenDecimals
          }&side=SELL&network=${chainId}`
        );
        return returnData;
      } catch (e) {}
    } else if (String(actualKeyword) === "openocean") {
      try {
        returnData = await axios.get(
          `https://open-api.openocean.finance/v3/${oonetwork}/swap_quote?inTokenAddress=${fromTokenAddress}&outTokenAddress=${toTokenAddress}&amount=${fromTokenAmount}&gasPrice=${goodGas}&slippage=${slippage}&account=${usersAddress}`
        );
        return returnData;
      } catch (e) {}
    } else if (String(actualKeyword === "0x")) {
      try {
        returnData = await axios.get(
          `https://${zeroxnetwork}api.0x.org/swap/v1/quote?sellToken=${fromTokenAddress}&buyToken=${toTokenAddress}&sellAmount=${
            fromTokenAmount * 10 ** fromTokenDecimals
          }`
        );
        return returnData;
      } catch (e) {}
    } else if (String(actualKeyword === "1inch")) {
      try {
        returnData = await axios.get(
          `https://api.1inch.io/v5.0/${chainId}/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${
            fromTokenAmount * 10 ** fromTokenDecimals
          }&fromAddress=${usersAddress}&slippage=${slippage}`
        );
        return returnData;
      } catch (e) {}
    }
  } catch (error) {}
};