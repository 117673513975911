import axios from "axios";

export const handleSwaps = async (
  fromToken,
  toToken,
  amount,
  fromDecimals,
  toDecimals,
  network,
  oonetwork,
  slippage,
  oxNetwork
) => {
  try {
    let prices = [];
    /*  try {
      const paraswapResponse = await axios.get(
        `https://apiv5.paraswap.io/prices/?srcToken=${fromToken}&destToken=${toToken}&srcDecimals=${fromDecimals}&destDecimals=${toDecimals}&amount=${
          amount * 10 ** fromDecimals
        }&side=SELL&network=${network}`
      );
      prices = prices.concat({
        exchange: "paraswap",
        price:
          paraswapResponse.data.priceRoute.destAmount /
          10 ** paraswapResponse.data.priceRoute.destDecimals,
        data: paraswapResponse.data,
      });
    } catch (e) {}
 */
    try {
      const openOceanResponse = await axios.get(
        `https://open-api.openocean.finance/v3/${oonetwork}/quote?inTokenAddress=${fromToken}&outTokenAddress=${toToken}&amount=${amount}&gasPrice=5&slippage=${
          slippage * 100
        }`
      );
      prices = prices.concat({
        exchange: "OpenOcean",
        price:
          openOceanResponse.data.data.outAmount /
          10 ** openOceanResponse.data.data.outToken.decimals,
        data: openOceanResponse.data,
      });
    } catch (e) {}

    try {
      const zeroXResponse = await axios.get(
        `https://${oxNetwork}api.0x.org/swap/v1/price?sellToken=${fromToken}&buyToken=${toToken}&sellAmount=${
          amount * 10 ** fromDecimals
        }`
      );
      prices = prices.concat({
        exchange: "0x",
        price: zeroXResponse.data.buyAmount / 10 ** toDecimals,
        data: zeroXResponse.data,
      });
    } catch (e) {}

    try {
      const oneInchResponse = await axios.get(
        `https://api.1inch.io/v5.0/${network}/quote?fromTokenAddress=${fromToken}&toTokenAddress=${toToken}&amount=${
          amount * 10 ** fromDecimals
        }`
      );
      prices = prices.concat({
        exchange: "1inch",
        price:
          oneInchResponse.data.toTokenAmount /
          10 ** oneInchResponse.data.toToken.decimals,
        data: oneInchResponse.data,
      });
    } catch (e) {}

    return prices;
  } catch (error) {
    /*  throw error; */
  }
};
